import {
  DriveFileRenameOutline,
  HighlightOffOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import EmptyHere from "src/components/EmptyHere";
import axiosInstance from "src/utils/axiosInstance";
import NewFieldDrawer from "./components/NewFieldDrawer";
import QuestionInputField from "../common/QuestionInputField";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function AddEditManualForm() {
  const { formType: committeeId } = useParams(); // committeeId=formType
  const [formInputs, setFormInputs] = useState({
    questions: [],
  });
  const [loading, setLoading] = useState(true);
  const [editDetails, setEditDetails] = useState(false);

  const getForm = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/manualforms/${committeeId}`);
      if (resp?.status === 200) {
        setFormInputs(resp.data?.data || {});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 404) {
        setEditDetails(true);
        return;
      }
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (!committeeId) {
      Notify.failure("Form ID/Committee name is required");
      return;
    }
    getForm();
  }, [committeeId]);

  const handleSubmit = async (reqData) => {
    try {
      if (formInputs?.premium && formInputs?.amount <= 0) {
        Notify.failure("Amount must be greater than 0");
        return;
      }

      reqData.type = "form";
      reqData.category = "committee";
      reqData.committeeId = committeeId;
      reqData.questions = JSON.stringify(reqData.questions || []);

      let resp;
      if (reqData?._id) {
        resp = await axiosInstance.put(`/manualforms/${reqData?._id}`, reqData);
      } else {
        resp = await axiosInstance.post("/manualforms", reqData);
      }

      if ([200, 201]?.includes(resp.status)) {
        const data = resp?.data?.data || {};
        setFormInputs(data);
      }
      return true;
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  const [hoveredOn, setHoveredOn] = useState(null);

  return (
    <Box my={3}>
      <Stack spacing={4}>
        {/* <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box flexGrow={1}></Box>{" "}
          <Button
            sx={{
              mt: { xs: 2, sm: 0 },
            }}
            startIcon={<ArrowBackTwoTone />}
            onClick={() => navigate(-1)}
          >
            Go back
          </Button>
        </Box> */}

        {loading ? (
          <SuspenseLoader />
        ) : (
          <>
            {editDetails ? (
              <Card sx={{ p: 3 }}>
                <Typography variant="h5" component="h5" gutterBottom>
                  Form details
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Stack>
                  <Stack spacing={2}>
                    {[
                      {
                        _id: "title",
                        label: "Form Title",
                        placeholder: "Enter form title",
                        name: "title",
                        type: "text",
                      },
                      {
                        _id: "description",
                        label: "Form Description",
                        placeholder: "Enter form description",
                        name: "description",
                        type: "textarea",
                      },
                    ]?.map((item, index) => (
                      <TextField
                        key={index}
                        variant={"outlined"}
                        fullWidth
                        label={item?.label}
                        placeholder={item.placeholder}
                        value={formInputs?.[item?.name]}
                        multiline={item?.type === "textarea"}
                        rows={item?.type === "textarea" ? 4 : 1}
                        onChange={(e) => {
                          setFormInputs({
                            ...formInputs,
                            [item?.name]: e.target.value,
                          });
                        }}
                      />
                    ))}
                    <Grid container>
                      <Grid item xs={6}>
                        <Stack>
                          {[
                            {
                              label: "Enable select company option",
                              value: formInputs?.organisationRequired,
                              name: "organisationRequired",
                            },
                            {
                              label: "Must have Career Protection plan",
                              value: formInputs?.careerProtectionRequired,
                              name: "careerProtectionRequired",
                            },
                            // {
                            //   label: "Enable comment section",
                            //   value: formInputs?.enableComments,
                            //   name: "enableComments",
                            // },
                            // {
                            //   label: "Enable change logs",
                            //   value: formInputs?.enableLogs,
                            //   name: "enableLogs",
                            // },
                          ]?.map((item, index) => {
                            if (
                              item?.name === "careerProtectionRequired" &&
                              !formInputs?.organisationRequired
                            ) {
                              return null;
                            }
                            return (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={item?.value}
                                    onChange={(e) => {
                                      if (
                                        item?.name === "organisationRequired" &&
                                        !e.target.checked
                                      ) {
                                        setFormInputs({
                                          ...formInputs,
                                          [item?.name]: e.target.checked,
                                          careerProtectionRequired: false,
                                        });
                                        return;
                                      }

                                      setFormInputs({
                                        ...formInputs,
                                        [item?.name]: e.target.checked,
                                      });
                                    }}
                                  />
                                }
                                label={item?.label}
                              />
                            );
                          })}
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <Stack direction="column" spacing={2}>
                        <FormControlLabel
                          checked={formInputs?.premium || false}
                          control={<Checkbox />}
                          label="Premium"
                          onChange={(e) => {
                            const _formInput = { ...formInputs };
                            if (!e.target.checked) {
                              delete _formInput?.amount;
                              _formInput.premium = false;
                            } else {
                              _formInput.premium = true;
                              _formInput.amount = 0;
                            }
                            setFormInputs(_formInput);
                          }}
                        />{" "}
                        <Box width={1}>
                          {formInputs?.premium && (
                            <TextField
                              label="Amount (in INR)"
                              name="amount"
                              type="number"
                              value={formInputs?.amount || ""}
                              onChange={(e) =>
                                setFormInputs({
                                  ...formInputs,
                                  amount: e.target.value,
                                })
                              }
                            />
                          )}
                        </Box>
                      </Stack> */}
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    onClick={async () => {
                      await handleSubmit(formInputs);
                      setEditDetails(false);
                    }}
                  >
                    <Button variant="contained">Save and Continue</Button>
                  </Stack>
                </Stack>
              </Card>
            ) : (
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography variant="h1" component="h1" gutterBottom>
                        {formInputs?.title || ""}
                      </Typography>
                      <Button
                        startIcon={<DriveFileRenameOutline />}
                        onClick={() => {
                          setEditDetails(true);
                        }}
                      >
                        Edit details
                      </Button>
                    </Stack>
                    <Typography
                      variant="h6"
                      gutterBottom
                      color={"textSecondary"}
                    >
                      {formInputs?.description || ""}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
            {formInputs?._id && (
              <Card sx={{ p: 2 }}>
                <Typography variant="h5" component="h5" gutterBottom>
                  Form fields
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Stack spacing={2}>
                  {formInputs?.questions?.length > 0 ? (
                    formInputs?.questions?.map((question, index) => (
                      <Card
                        key={question?._id}
                        elevation={0}
                        variant={
                          hoveredOn === index + "q" ? "outlined" : "elevation"
                        }
                        onMouseOver={() => setHoveredOn(index + "q")}
                        onMouseOut={() => setHoveredOn(null)}
                      >
                        <CardHeader
                          title={
                            <Typography
                              variant="h5"
                              component="div"
                              minHeight={26}
                            >
                              {question?.title || ""}
                            </Typography>
                          }
                          action={
                            <Stack
                              direction="row"
                              sx={{
                                display:
                                  hoveredOn === index + "q" ? "flex" : "none",
                                gap: 1,
                              }}
                            >
                              <NewFieldDrawer
                                formData={question}
                                handleSubmit={handleSubmit}
                                formInputs={formInputs}
                              />
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => {
                                  Confirm.show(
                                    "Confirmation",
                                    "Are you sure you want to remove this field?",
                                    "Confirm",
                                    "Cancel",
                                    () => {
                                      const _tags = formInputs?.questions || [];
                                      _tags.splice(index, 1);
                                      setFormInputs({
                                        ...formInputs,
                                        questions: _tags,
                                      });
                                      handleSubmit(formInputs);
                                    },
                                    () => {},
                                    {
                                      width: "500px",
                                      messageMaxLength: 1000,
                                    }
                                  );
                                }}
                              >
                                <HighlightOffOutlined fontSize="small" />
                              </IconButton>
                            </Stack>
                          }
                        />
                        <CardContent sx={{ py: 0 }}>
                          <QuestionInputField
                            question={question}
                            handleFormInput={() => {}}
                            formInput={{}}
                            setFormInput={() => {}}
                          />
                        </CardContent>
                      </Card>
                    ))
                  ) : (
                    <EmptyHere
                      title="No fields added yet"
                      subtitle={`Click on the "Add new field" button to add a new field`}
                    />
                  )}
                </Stack>

                <NewFieldDrawer
                  formInputs={formInputs}
                  setFormInputs={setFormInputs}
                  handleSubmit={handleSubmit}
                />
              </Card>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
}
