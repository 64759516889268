import React, { useContext } from "react";
import { useTheme } from "@mui/material";
import GaugeChart from "react-gauge-chart";
import { ProfileContext } from "src/contexts/ProfileContext";
import { MAX_SCORE } from "src/utils/constants";

export default function ScoreCircularBar({ userProfile }) {
  const theme = useTheme();
  const { profile } = useContext(ProfileContext);

  return (
    <GaugeChart
      arcPadding={0.03}
      cornerRadius={2}
      textColor={theme.colors.alpha.black[100]}
      needleColor={theme.colors.alpha.black[50]}
      needleBaseColor={theme.colors.alpha.black[100]}
      colors={[theme.colors.error.main, theme.colors.success.main]}
      percent={
        (userProfile?.score ||
          userProfile?.employeeScore ||
          profile?.employeeScore) / MAX_SCORE || 0
      }
      animDelay={1}
      formatTextValue={() => ``}
      nrOfLevels={4}
      arcWidth={0.65}
    />
  );
}
