import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import Logo from "src/components/Logo";

export default function Header() {
  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        px={2}
        mt={1}
      >
        <Logo />
        <Stack
          sx={{
            textAlign: "right",
          }}
        >
          <Typography variant="h3">
            <span className="link-color">Profile Summary Report</span>
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 2 }} />
    </Stack>
  );
}
