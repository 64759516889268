import {
  Typography,
  Box,
  styled,
  Avatar,
  lighten,
  alpha,
  Button,
  useTheme,
  CardHeader,
  Card,
} from "@mui/material";
import PieChartTwoToneIcon from "@mui/icons-material/PieChartTwoTone";
import { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Link } from "react-router-dom";
import { ArrowForwardIos, AttachMoney } from "@mui/icons-material";
import { currencyFormatter } from "src/utils/helpers";

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${
        theme.palette.mode === "dark"
          ? theme.colors.alpha.trueWhite[10]
          : theme.colors.alpha.white[50]
      };
      box-shadow: ${
        theme.palette.mode === "dark"
          ? `0 1px 0 ${alpha(
              lighten(theme.colors.primary.main, 0.8),
              0.2
            )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)`
          : `0px 2px 4px -3px ${alpha(
              theme.colors.alpha.black[100],
              0.4
            )}, 0px 5px 16px -4px ${alpha(theme.colors.alpha.black[100], 0.2)}`
      };
`
);

function HeaderStats({ stats }) {
  const theme = useTheme();
  const { currentUser } = useContext(GlobalContext);

  return (
    <Box
      display="flex"
      alignItems={{ xs: "stretch", md: "center" }}
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      my={3}
    >
      <Box display="flex" alignItems="center">
        <AvatarPageTitle variant="rounded">
          <PieChartTwoToneIcon fontSize="large" />
        </AvatarPageTitle>
        <Box>
          <Typography variant="h3" component="h3" gutterBottom>
            Hi, {currentUser?.firstName || "-"} {currentUser?.lastName || ""}
          </Typography>
          <Typography variant="subtitle2">
            Welcome to your dashboard, here are the Stats, Insights and
            Analytics for current month (
            <b>
              {new Date().toLocaleString("default", {
                month: "long",
              }) +
                " " +
                new Date().getFullYear()}
            </b>
            )
          </Typography>
        </Box>
      </Box>
      <Box>
        {currentUser?.orgId && (
          <Button
            color="primary"
            component={Link}
            to="/pricing"
            endIcon={<ArrowForwardIos />}
          >
            View Premium Plans
          </Button>
        )}
        {currentUser?.accountType === "admin" && (
          <Card
            sx={{
              background: `${theme.colors.gradients.blue4}`,
              p: 1,
              px: 3,
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  sx={{
                    width: `${theme.spacing(5.5)}`,
                    height: `${theme.spacing(5.5)}`,
                    background: `${theme.colors.success.main}`,
                    color: `${theme.palette.getContrastText(
                      theme.colors.success.dark
                    )}`,
                  }}
                >
                  <AttachMoney />
                </Avatar>
              }
              title={
                <Typography
                  sx={{
                    color: `${theme.colors.alpha.trueWhite[70]}`,
                  }}
                  gutterBottom
                  component="div"
                  variant="caption"
                >
                  Total Revenue (This Month)
                </Typography>
              }
              subheader={
                <Typography
                  sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`,
                  }}
                  variant="h2"
                >
                  {currencyFormatter(stats?.orders?.amountPaid || 0)}
                </Typography>
              }
            />
          </Card>
        )}
      </Box>
    </Box>
  );
}

export default HeaderStats;
