import { Typography, useTheme } from "@mui/material";
import React from "react";

export default function SubHeader({ children, sx = {} }) {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        fontSize: 10,
        fontWeight: "500",
        color: "#000",
        backgroundColor: `${theme.palette.grey[800]}16`,
        border: `1px solid`,
        borderColor: `${theme.palette.grey[800]}24`,
        px: 2,
        height: 22,
        display: "flex",
        alignItems: "center",
        borderRadius: 4,
        textTransform: "uppercase",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}
