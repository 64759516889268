import {
  CreditScoreOutlined,
  ExpandLess,
  ExpandMore,
  FingerprintOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  Stack,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useState } from "react";
import Label from "src/components/Label";
import ListCustom from "src/components/List";
import { ProfileContext } from "src/contexts/ProfileContext";

export default function VertificationDetails({
  docType,
  cleanExitVerifications,
}) {
  const {
    profile,
    verificationDetails,
    handleGetVerificationDetails,
    viewAsPublic,
  } = useContext(ProfileContext);
  const [show, setShow] = useState(false);
  const [isWait, setIsWait] = useState(false);

  const handleShowDetails = async () => {
    if (!profile?._id) {
      Notify.failure("Profile ID missing");
    }

    if (!show) {
      setIsWait(true);
      await handleGetVerificationDetails(profile?._id);
      setIsWait(false);
    }
    setShow(!show);
  };
  return (
    <Card
      sx={{
        width: viewAsPublic ? 200 : 400,
      }}
    >
      {docType === "aadhaar" && (
        <>
          <CardHeader
            title={<Typography variant="h4">Aadhaar Card</Typography>}
            avatar={<FingerprintOutlined />}
            subheader={
              <Box mt={1}>
                {cleanExitVerifications?.aadhaar ? (
                  <Stack direction="row" spacing={1} alignContent={"center"}>
                    <Label color="success">Verified</Label>
                    {!viewAsPublic && (
                      <LoadingButton
                        size="small"
                        startIcon={show ? <ExpandLess /> : <ExpandMore />}
                        onClick={handleShowDetails}
                        loading={isWait}
                      >
                        <small>
                          {show ? "Hide " : "Show "}
                          details
                        </small>
                      </LoadingButton>
                    )}
                  </Stack>
                ) : (
                  <Label color="error">Not Verified</Label>
                )}
              </Box>
            }
          />

          {show && (
            <CardContent>
              <List>
                {[
                  {
                    label: "Name",
                    value: verificationDetails?.aadhaarDocs?.name || "-",
                  },
                  {
                    label: "DOB",
                    value:
                      verificationDetails?.aadhaarDocs?.date_of_birth || "-",
                  },
                  {
                    label: "Gender",
                    value: verificationDetails?.aadhaarDocs?.gender || "-",
                  },
                  {
                    label: "Care Of",
                    value: verificationDetails?.aadhaarDocs?.care_of || "-",
                  },
                  {
                    label: "Street",
                    value: verificationDetails?.aadhaarDocs?.street || "-",
                  },
                  {
                    label: "District",
                    value: verificationDetails?.aadhaarDocs?.district || "-",
                  },
                  {
                    label: "Sub District",
                    value:
                      verificationDetails?.aadhaarDocs?.sub_district || "-",
                  },
                  {
                    label: "Landmark",
                    value: verificationDetails?.aadhaarDocs?.landmark || "-",
                  },
                  {
                    label: "Post Office",
                    value:
                      verificationDetails?.aadhaarDocs?.post_office_name || "-",
                  },
                  {
                    label: "State",
                    value: verificationDetails?.aadhaarDocs?.state || "-",
                  },
                  {
                    label: "Pincode",
                    value: verificationDetails?.aadhaarDocs?.pincode || "-",
                  },
                  {
                    label: "Country",
                    value: verificationDetails?.aadhaarDocs?.country || "-",
                  },
                  {
                    label: "VTC Name",
                    value: verificationDetails?.aadhaarDocs?.vtc_name || "-",
                  },
                ].map((item, index) => (
                  <ListCustom
                    key={index}
                    item={item}
                    rightStyle={{
                      fontWeight: "bold",
                    }}
                    leftStyle={{
                      className: "link-color",
                      width: 120,
                    }}
                  />
                ))}
              </List>
            </CardContent>
          )}
        </>
      )}
      <Divider />

      {docType === "pan" && (
        <>
          <CardHeader
            title={<Typography variant="h4">PAN Card</Typography>}
            avatar={<CreditScoreOutlined />}
            subheader={
              <Box mt={1}>
                {cleanExitVerifications?.pan ? (
                  <Stack direction="row" spacing={1} alignContent={"center"}>
                    <Label color="success">Verified</Label>
                    {!viewAsPublic && (
                      <LoadingButton
                        size="small"
                        startIcon={show ? <ExpandLess /> : <ExpandMore />}
                        onClick={handleShowDetails}
                        loading={isWait}
                      >
                        <small>
                          {show ? "Hide " : "Show "}
                          details
                        </small>
                      </LoadingButton>
                    )}
                  </Stack>
                ) : (
                  <Label color="error">Not Verified</Label>
                )}
              </Box>
            }
          />
          {show && (
            <List>
              {[
                {
                  label: "Name",
                  value: verificationDetails?.panDocs?.name,
                },
                {
                  label: "PAN",
                  value: verificationDetails?.panDocs?.pan_id || "-",
                },
                {
                  label: "DOB",
                  value: verificationDetails?.panDocs?.date_of_birth || "-",
                },
              ].map((item, index) => (
                <ListCustom
                  key={index}
                  item={item}
                  rightStyle={{
                    fontWeight: "bold",
                  }}
                  leftStyle={{
                    className: "link-color",
                    width: 70,
                  }}
                />
              ))}
            </List>
          )}
        </>
      )}
    </Card>
  );
}
