import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useState } from "react";

export default function TagsAutocomplete({
  totalItems,
  formInput,
  setFormInput,
  newTags,
  setNewTags,
  ...rest
}) {
  const [newTagInput, setNewTagInput] = useState("");

  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        disablePortal
        size="small"
        value={formInput?.tags || []}
        options={totalItems || []}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label={rest?.label || "Add Tags"}
            fullWidth={false}
            sx={{ minWidth: "100%" }}
          />
        )}
        onInputChange={(e, v) => {
          setNewTagInput(v);
        }}
        noOptionsText={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              <b>{capitalize(newTagInput || "")}</b> not found
            </Typography>
            <Button
              onClick={() => {
                // check empty input
                if (!newTagInput) {
                  return;
                }
                // check duplicate
                if (formInput?.tags?.includes(newTagInput)) {
                  Notify.warning(newTagInput + " already exists");
                  return;
                }

                setFormInput((prev) => ({
                  ...prev,
                  tags: [...prev.tags, newTagInput],
                }));

                setNewTags([...newTags, newTagInput]);
                setNewTagInput("");
              }}
              sx={{ ml: 1 }}
            >
              Add
            </Button>
          </Box>
        }
        onChange={(e, v) => {
          console.log(v);
          setFormInput((prev) => ({
            ...prev,
            tags: v,
          }));
        }}
      />
    </div>
  );
}
