import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { DO_SPACES_FOLDERS, PAGE_LIMIT } from "src/utils/constants";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ArrowForwardIos, CloudUploadOutlined } from "@mui/icons-material";
import { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";

export default function NewRequest() {
  const navigate = useNavigate();
  const { currentUser, handleCheckPayment, getTagsByType } =
    useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const claimedParam = searchParams.get("claimed");
  const [filterObj, setFilterObj] = React.useState({
    isClaimed: claimedParam === "false" ? undefined : true,
  });
  const [loading, setLoading] = React.useState(false);

  const [sDocument, setsDocument] = useState(null);
  const [organisations, setOrganisations] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = React.useState("");
  const [formInput, setFormInput] = useState({
    employeeId: "",
    relation: "participant",
    supportingDocumentUrl: "",
  });

  const [open, setOpen] = useState(false);
  const [isWait, setIsWait] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value + 1);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value + 1,
    });
  };

  const [customerRelations, setCustomerRelations] = useState([]);
  useEffect(() => {
    if (open) {
      (async () => {
        const crs = await getTagsByType("customerRelations");
        setCustomerRelations(crs);
      })();
    }
  }, [open]);

  useEffect(() => {
    if (searchParams) {
      setPage(parseInt(searchParams.get("page")) || 1);
    }
  }, [searchParams]);

  useEffect(() => {
    if (filterObj?.search) {
      handleGetOrganisations();
    } else {
      setOrganisations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterObj]);

  const handleGetOrganisations = async () => {
    try {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }
      const resp = await axiosInstance.get("/invitations/new", reqParams);
      if (resp?.status === 200) {
        setOrganisations(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const [organisationId, setOrganisationId] = useState(null);

  const handleClickOpen = async (orgId) => {
    setIsWait(true);
    setOrganisationId(orgId);
    const hasPaid = await handleCheckPayment(
      {
        item: "joinOrganisations",
        itemId: orgId,
      },
      {
        redirectLink: `/dashboard/requests`,
        itemId: orgId,
        item: "joinOrganisations",
        joinOrgObj: {
          user: currentUser?.userId,
          organisation: orgId,
          type: "request",
          meta: formInput,
          showInputs: true,
          disableInputs: false,
          createInvitation: true,
        },
      }
    );
    setIsWait(false);
    if (!hasPaid) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormInput({});
    setOrganisationId(null);
  };

  const handleUploadSupportingDocument = async () => {
    try {
      if (!sDocument) return null;
      const formData = new FormData();
      formData.append("files", sDocument);
      formData.append("fileCategory", DO_SPACES_FOLDERS.DOCUMENTS);
      const resp = await axiosInstance.post("/users/upload-media", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (resp?.status === 200) {
        return resp?.data?.[0];
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  const handleSendRequest = async (_orgId) => {
    try {
      if (!formInput?.relation) {
        Notify.failure("Please select your relation");
        return;
      }

      setLoading(true);
      const _sDocument = await handleUploadSupportingDocument();
      if (_sDocument) {
        setFormInput({
          ...formInput,
          supportingDocumentUrl: _sDocument,
        });
      }

      const resp = await axiosInstance.post(`/invitations/`, {
        user: currentUser?.userId,
        organisation: _orgId,
        type: "request",
        meta: {
          employeeId: formInput?.employeeId,
          relation: formInput?.relation,
          supportingDocumentUrl: _sDocument || formInput?.supportingDocumentUrl,
        },
      });
      if (resp?.status === 201) {
        handleClose();
        setsDocument(null);
        Notify.success("Request sent successfully");
        navigate("/dashboard/requests");
        setSearchString("");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box fullWidth>
          <Stack direction="row" spacing={2}>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here to join organisation..."
                inputProps={{ "aria-label": "search here" }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (e.target.value.length) {
                      setFilterObj({ ...filterObj, search: searchString });
                    } else {
                      delete filterObj.search;
                      setFilterObj({ ...filterObj, search: null });
                    }
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      page: 1,
                    });
                  }
                }}
                onChange={(e) => {
                  if (e.target.value?.length > 0) {
                    setSearchString(e.target.value);
                  } else {
                    if (filterObj?.search) {
                      setFilterObj({
                        ...filterObj,
                        search: null,
                      });
                    }
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{
                  p: "10px",
                  m: 0.2,
                  bgcolor: "primary.main",
                  color: "white",
                }}
                onClick={() => {
                  setFilterObj({
                    ...filterObj,
                    search: searchString,
                  });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>{" "}
            <Button
              size="sm"
              component={Link}
              to={`/dashboard/requests`}
              endIcon={<ArrowForwardIos fontSize="small" />}
            >
              Previous Requests
            </Button>
          </Stack>
        </Box>
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Organisation Name</TableCell>
                <TableCell>Organisation Website</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organisations?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        row?.avatar
                          ? process.env.REACT_APP_DO_SPACE_URL +
                            row?.avatar +
                            `?${Math.random()}`
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell>{row?.title}</TableCell>
                  <TableCell>{row?.companyWebsite || "-"}</TableCell>
                  <TableCell align="right">
                    <LoadingButton
                      size="small"
                      variant="contained"
                      color="warning"
                      onClick={() => handleClickOpen(row?._id)}
                      loading={isWait}
                    >
                      Send Request to Join
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}

              {organisations?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Search for organisation by name or website
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {organisations?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalPages}
              rowsPerPage={PAGE_LIMIT}
              page={page - 1}
              onPageChange={handlePageChange}
            />
          )}
        </TableContainer>
      </Box>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>CPEW Certification Verify Request</DialogTitle>
          <DialogContent>
            <Autocomplete
              sx={{ mt: 1 }}
              fullWidth
              options={["employee", ...customerRelations]}
              value={formInput?.relation || ""}
              getOptionLabel={(option) => capitalize(option || "")}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  relation: value || "",
                });
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Select Relation"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                  <FormHelperText>
                    Send request to join organisation as a?
                  </FormHelperText>
                </>
              )}
            />
            <TextField
              sx={{ mt: 2 }}
              name="employeeId"
              value={formInput?.employeeId || ""}
              onChange={(e) =>
                setFormInput({ ...formInput, employeeId: e.target.value })
              }
              label="Employee ID"
              fullWidth
            />
            <Button
              sx={{ mt: 2 }}
              startIcon={<CloudUploadOutlined />}
              size="small"
              variant="outlined"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.onchange = async (e) => {
                  setsDocument(e.target.files[0]);
                };
                document.body.appendChild(input);
                input.click();
                document.body.removeChild(input);
              }}
            >
              Upload supporting document
            </Button>
            <>
              {sDocument && sDocument?.name && (
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  {sDocument.name}
                </Typography>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              loading={isWait}
              onClick={() => handleSendRequest(organisationId)}
              variant="contained"
              color="success"
            >
              Send Request
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
