import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

export default function ListCustom({ item, ...rest }) {
  return (
    <ListItem disableGutters>
      {item?.icon && (
        <ListItemIcon {...rest?.iconStyle}>{item?.icon}</ListItemIcon>
      )}
      <ListItemText
        sx={{
          flex: "initial",
          ...rest?.leftStyle,
        }}
        primary={
          <Typography {...rest?.leftStyle}>{item?.label || ""}</Typography>
        }
      />
      {item?.value && (
        <ListItemText
          sx={{
            flex: 1,
          }}
          primary={
            <Typography
              component={item?.link ? "a" : "span"}
              href={item?.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              {...rest?.rightStyle}
            >
              {item?.value || ""}
            </Typography>
          }
        />
      )}
    </ListItem>
  );
}
