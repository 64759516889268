import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import EditProjectDialog from "../addEdit/EditProject";
import { ProfileContext } from "src/contexts/ProfileContext";
import { OpenInNewOutlined } from "@mui/icons-material";
import { MonthNames } from "src/utils/commonConstants";
import Label from "src/components/Label";

export default function UserProjects() {
  const { currentUser } = useContext(GlobalContext);
  const { profile, portfolio, viewAsPublic } = useContext(ProfileContext);
  const [hoverOn, setHoverOn] = useState("");

  return (
    <Stack spacing={3}>
      <Card elevation={0}>
        <CardHeader
          title={
            <Typography variant="h3" component="h3">
              Projects
            </Typography>
          }
          action={
            currentUser?.userId === profile?._id &&
            !viewAsPublic && <EditProjectDialog />
          }
        />
        <CardContent>
          <Stack spacing={2}>
            {portfolio?.filter((item) => item?.category === "projects")
              ?.length ? (
              portfolio
                ?.filter((item) => item?.category === "projects")
                ?.sort((a, b) => {
                  const a_end =
                    a?.end_year + MonthNames.indexOf(a?.end_month) / 12;
                  const b_end =
                    b?.end_year + MonthNames.indexOf(b?.end_month) / 12;
                  return b_end - a_end || b?.start_year - a?.start_year;
                })
                ?.map((item, idx) => (
                  <Card
                    key={item?._id}
                    sx={{
                      border: "1px solid #e0e0e0",
                    }}
                    onMouseOver={() => setHoverOn("pro-" + idx)}
                    onMouseLeave={() => setHoverOn("")}
                  >
                    <CardHeader
                      title={
                        <Typography variant="h4" component="h4">
                          {item?.title}
                        </Typography>
                      }
                      subheader={
                        <Typography variant="subtitle2" component="p">
                          {item?.start_month} {item?.start_year} -{" "}
                          {!item?.end_month ? (
                            <Label color="success">Present</Label>
                          ) : (
                            <>
                              {item?.end_month} {item?.end_year}
                            </>
                          )}
                        </Typography>
                      }
                      action={
                        <Stack direction="row" spacing={1}>
                          {item?.project_link && (
                            <IconButton
                              component="a"
                              href={
                                item?.project_link?.includes("http")
                                  ? item?.project_link
                                  : `https://${item?.project_link}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              color="primary"
                            >
                              <OpenInNewOutlined />
                            </IconButton>
                          )}

                          {hoverOn === "pro-" + idx &&
                            (currentUser?.accountType === "admin" ||
                              currentUser?.userId === profile?._id) &&
                            !viewAsPublic && (
                              <EditProjectDialog portfolioItem={item} />
                            )}
                        </Stack>
                      }
                    />
                    <CardContent>
                      <Typography variant="subtitle2" component="p">
                        {item?.description}
                      </Typography>
                    </CardContent>
                  </Card>
                ))
            ) : (
              <EmptyHere py={1} title={"No projects added yet"} />
            )}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
