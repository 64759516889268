import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Avatar,
  Typography,
  useTheme,
  Chip,
  Stack,
  Grid,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import { useTranslation } from "react-i18next";
import { ArrowForwardIosOutlined, PlaceTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { EVENT_AUDIENCE, EVENT_STATUS } from "src/utils/constants";
import { format } from "date-fns";
import axiosInstance from "src/utils/axiosInstance";
import { LoadingButton } from "@mui/lab";
import Notiflix, { Notify } from "notiflix";

export default function EventTableCard({ event }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isWait, setIsWait] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(event);

  useEffect(() => {
    setCurrentEvent(event);
  }, [event]);

  const handleConfirmChangeEventStatus = async (status) => {
    try {
      if (currentEvent?.status === status) return;
      setIsWait(true);
      const resp = await axiosInstance.put(`/events/${currentEvent?._id}`, {
        status,
      });
      if (resp?.status === 200) {
        setCurrentEvent({
          ...currentEvent,
          status,
        });
        Notify.success("Successful");
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleChangeEventStatus = async (status) => {
    Notiflix.Confirm.show(
      "Confirmation",
      `Are you sure you want to ${status} this event?`,
      "Confirm",
      "Cancel",
      () => handleConfirmChangeEventStatus(status),
      () => {},
      {
        width: "500px",
        messageMaxLength: 1000,
      }
    );
  };

  return (
    <Card sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Stack spacing={1}>
            <Typography
              variant="h3"
              sx={{
                fontSize: "1.1rem",
              }}
              component={Link}
              to={`/dashboard/events/${currentEvent?._id}`}
              style={{ textDecoration: "none" }}
            >
              {currentEvent?.title}
            </Typography>
            <Stack direction="row" spacing={4} alignItems="center">
              <Stack spacing={1} direction={"row"} alignItems="center">
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 30,
                    height: 30,
                    boxShadow: "none",
                    borderWidth: 5,
                    background: `${theme.colors.alpha.black[90]}`,
                    border: `${theme.colors.alpha.white[100]} solid 3px`,
                  }}
                  src={
                    currentEvent?.organisation?.avatar
                      ? process.env.REACT_APP_DO_SPACE_URL +
                        (currentEvent?.organisation?.avatar +
                          "?" +
                          Math.random())
                      : ""
                  }
                />
                <Typography variant="h6">
                  {currentEvent?.organisation?.title || ""}
                </Typography>
              </Stack>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AccessTimeTwoToneIcon
                  fontSize="small"
                  color="success"
                  sx={{
                    opacity: 0.7,
                    mr: 0.5,
                  }}
                />
                {currentEvent?.startDateTime &&
                  format(new Date(currentEvent?.startDateTime), "dd MMM")}{" "}
                -{" "}
                {currentEvent?.endDateTime &&
                  format(new Date(currentEvent?.endDateTime), "dd MMM")}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PlaceTwoTone
                  color={"info"}
                  fontSize="small"
                  sx={{
                    opacity: 0.7,
                    mr: 0.5,
                  }}
                />
                {currentEvent?.isVirtual ? (
                  "Virtual"
                ) : (
                  <>
                    {currentEvent?.address?.city}
                    {!!currentEvent?.address?.city && ","}{" "}
                    {currentEvent?.address?.state ||
                      currentEvent?.address?.country ||
                      ""}
                  </>
                )}
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={3}>
          <Chip
            size="small"
            color={EVENT_STATUS[currentEvent?.status || "draft"]?.color}
            label={EVENT_STATUS[currentEvent?.status || "draft"]?.description}
            sx={{
              width: "max-content",
              fontWeight: "bold",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            mt={1}
          >
            {(() => {
              const audience = EVENT_AUDIENCE.find(
                (item) => item.value === currentEvent?.audience
              );
              if (!audience) return null;
              return (
                <>
                  <audience.icon
                    fontSize="small"
                    sx={{
                      opacity: 0.7,
                      mr: 0.5,
                    }}
                    color="primary"
                  />
                  {audience?.label}
                </>
              );
            })()}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Box sx={{ position: "relative", textAlign: "right" }}>
            <IconButton
              fullWidth
              variant="outlined"
              component={Link}
              to={`/dashboard/events/${currentEvent?._id}`}
            >
              <ArrowForwardIosOutlined />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {currentEvent?.status == "review" && (
        <Stack mt={2}>
          <Alert
            severity="warning"
            action={
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  loading={isWait}
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => handleChangeEventStatus("rejected")}
                >
                  {t("Reject")}
                </LoadingButton>
                <LoadingButton
                  loading={isWait}
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => handleChangeEventStatus("published")}
                >
                  {t("Approve")}
                </LoadingButton>
              </Stack>
            }
          >
            <AlertTitle>
              Owner of this event has requested for review and approval to
              Publish to the public.
            </AlertTitle>
          </Alert>
        </Stack>
      )}
    </Card>
  );
}
