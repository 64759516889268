import {
  AddModerator,
  AddTwoTone,
  EditTwoTone,
  OpenInNew,
  Refresh,
  SearchTwoTone,
  VerifiedUser,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import EmptyHere from "src/components/EmptyHere";
import Label from "src/components/Label";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import {
  INVITATION_STATUS,
  PAGE_LIMIT,
  invitationStatus,
} from "src/utils/constants";
import { fDate, fDateTimeSuffix } from "src/utils/date-fns";

export default function JoinRequests({ whichPage }) {
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [filterObj, setFilterObj] = useState({
    status: searchParams.get("status") || "all",
  });
  const [loading, setLoading] = useState(true);
  const [isWait, setIsWait] = useState(false);
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({ page: value });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: {
          limit: PAGE_LIMIT,
          skip: (page - 1) * PAGE_LIMIT,
        },
      };

      if (whichPage !== "career-protection") {
        reqParams.params = { ...reqParams.params, type: "request" };
      }

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }

      if (filterObj?.status === "all") {
        delete reqParams.params.status;
      }

      const resp = await axiosInstance.get("/invitations", reqParams);
      if (resp?.status === 200) {
        setRequests(resp.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
      }
      setLoading(false);
    })();
  }, [page, filterObj]);

  const [customerRelations, setCustomerRelations] = useState([]);
  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  const handleSendRequest = async (oldRequest) => {
    try {
      setIsWait(true);

      const resp = await axiosInstance.post(`/invitations/`, {
        user: oldRequest?.user?._id,
        organisation: oldRequest?.organisation?._id,
        type: "request",
        meta: oldRequest?.meta,
      });
      if (resp?.status === 201) {
        setRequests([resp?.data, ...requests]);
        Notify.success("Request sent successfully");
      }

      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const InviteActions = ({ invitation }) => {
    const [isWait, setIsWait] = useState(false);
    const [relation, setRelation] = useState(
      invitation?.meta?.relation || "employee"
    );
    const [isEdit, setIsEdit] = useState(false);

    const handleConfirmChangeUpdateStatus = async (invitation, status) => {
      try {
        setIsWait(true);
        const resp = await axiosInstance.put(
          `/invitations/${invitation?._id}`,
          {
            status,
            meta: {
              ...invitation?.meta,
              relation: relation || "employee",
            },
          }
        );
        if (resp?.status === 200) {
          const updatedRequests = requests.map((request) => {
            if (request._id === invitation?._id) {
              return { ...request, status, meta: resp?.data?.meta };
            }
            return request;
          });
          setRequests(updatedRequests);
        }
        setIsWait(false);
      } catch (error) {
        setIsWait(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    };

    const handleChangeUpdateStatus = async (invitation, status) => {
      Confirm.show(
        "Confirmation",
        "Are you sure you want to continue?",
        "Confirm",
        "Cancel",
        () => handleConfirmChangeUpdateStatus(invitation, status),
        () => {},
        {
          width: "500px",
          messageMaxLength: 1000,
        }
      );
    };

    return (
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        {invitation?.status === "pending" && (
          <>
            {!isEdit ? (
              <Typography
                variant="body2"
                component={"span"}
                sx={{
                  pr: 5,
                }}
              >
                Sender want to join as <br />
                <Typography>
                  <b>{capitalize(invitation?.meta?.relation || "N/A")}</b>
                  <sup>
                    <EditTwoTone
                      onClick={() => setIsEdit(true)}
                      color="warning"
                      fontSize="small"
                      sx={{ mx: 1, cursor: "pointer" }}
                    />
                  </sup>
                </Typography>
              </Typography>
            ) : (
              <Autocomplete
                sx={{ minWidth: 250 }}
                size="small"
                options={["employee", ...customerRelations]}
                value={relation}
                getOptionLabel={(option) => capitalize(option || "")}
                onChange={(e, value) => {
                  setRelation(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Change Relation"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </>
        )}
        <LoadingButton
          color="error"
          variant="outlined"
          size="small"
          onClick={() => handleChangeUpdateStatus(invitation, "rejected")}
          loading={isWait}
        >
          Reject
        </LoadingButton>
        <LoadingButton
          color="success"
          size="small"
          variant="contained"
          onClick={() => handleChangeUpdateStatus(invitation, "accepted")}
          loading={isWait}
        >
          Accept
        </LoadingButton>
      </Stack>
    );
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box my={3}>
      {whichPage == "career-protection" ? (
        <>
          {requests?.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h3" mb={2}>
                Buy Career Protection Plan for your internship / job
              </Typography>
            </>
          )}
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 2 }}
        >
          <Stack direction="row" spacing={2}>
            {currentUser?.role !== "user" && (
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 500,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here..."
                  inputProps={{ "aria-label": "search here" }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      if (e.target.value.length) {
                        setFilterObj({ ...filterObj, search: searchString });
                      } else {
                        setFilterObj({ ...filterObj, search: null });
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value?.length > 0) {
                      setSearchString(e.target.value);
                    } else {
                      setFilterObj({ ...filterObj, search: null });
                      setSearchString("");
                    }
                  }}
                />
                <IconButton
                  type="button"
                  sx={{
                    p: "10px",
                    m: 0.2,
                    bgcolor: "primary.main",
                    color: "white",
                  }}
                  onClick={() => {
                    setFilterObj({
                      ...filterObj,
                      search: searchString,
                    });
                  }}
                >
                  <SearchTwoTone />
                </IconButton>
              </Paper>
            )}
            {currentUser?.role === "user" && (
              <Button
                size="sm"
                component={Link}
                variant="outlined"
                to={`/dashboard/requests/new`}
                startIcon={<AddTwoTone fontSize="small" />}
              >
                Join new organisation
              </Button>
            )}
          </Stack>
          <Stack direction={"row"} spacing={2}>
            {currentUser?.accountType !== "user" && (
              <Button
                size="sm"
                component={Link}
                variant="outlined"
                to={`/dashboard/invitations/new`}
                startIcon={<AddTwoTone fontSize="small" />}
              >
                Invite user
              </Button>
            )}
            <Select value={filterObj?.status || "all"} size="small">
              {INVITATION_STATUS.map((item) => (
                <MenuItem
                  value={item.value}
                  key={item.value}
                  onClick={() => {
                    setFilterObj({
                      ...filterObj,
                      status: item.value,
                    });
                    setSearchParams({ status: item.value });
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      )}
      {requests?.length > 0 ? (
        <List sx={{ bgcolor: "background.paper" }}>
          {requests?.map((item, idx) => (
            <Fragment key={idx}>
              <ListItem
                alignItems="flex-start"
                secondaryAction={
                  currentUser?.orgId && item?.status === "pending" ? (
                    <InviteActions invitation={item} />
                  ) : (
                    <Stack
                      spacing={1}
                      sx={{
                        alignItems: "flex-end",
                      }}
                    >
                      <Label color={invitationStatus[item?.status]?.color}>
                        <b>{invitationStatus[item?.status]?.label}</b>
                      </Label>
                      {currentUser?.role === "user" &&
                        !requests?.some(
                          (r) =>
                            r?.status === "pending" &&
                            r?.organisation?._id === item?.organisation?._id
                        ) &&
                        !["pending", "accepted"]?.includes(item?.status) && (
                          <LoadingButton
                            size="small"
                            variant="outlined"
                            color="warning"
                            startIcon={<Refresh />}
                            onClick={() => handleSendRequest(item)}
                            loading={isWait}
                          >
                            Send Request again
                          </LoadingButton>
                        )}
                    </Stack>
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar
                    alt=""
                    src={
                      currentUser?.orgId
                        ? item?.user?.profileImage
                          ? process.env.REACT_APP_DO_SPACE_URL +
                            item?.user?.profileImage +
                            `?${Math.random()}`
                          : ""
                        : item?.organisation?.avatar
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          item?.organisation?.avatar +
                          `?${Math.random()}`
                        : ""
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    currentUser?.role === "user" ? (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="h4"
                          color="text.primary"
                        >
                          {item?.organisation?.title}{" "}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="h4"
                          color="text.primary"
                        >
                          <Link
                            to={`/dashboard/users/${item?.user?._id}`}
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            {item?.user?.firstName} {item?.user?.lastName}{" "}
                          </Link>
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          ({item?.user?.email})
                        </Typography>
                      </Stack>
                    )
                  }
                  secondary={
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      mt={1}
                    >
                      {item?.meta?.relation && (
                        <>
                          <Typography>
                            <b>{capitalize(item?.meta?.relation || "N/A")}</b>
                          </Typography>
                          <Typography>•</Typography>
                        </>
                      )}
                      <Typography>
                        ID: <b>{item?.meta?.employeeId || "N/A"}</b>
                      </Typography>
                      <Typography>•</Typography>
                      {!currentUser?.accountType === "admin" && (
                        <>
                          <Typography variant="body2" component={"span"}>
                            <b>{item?.organisation?.title}</b>{" "}
                          </Typography>
                          <Typography>•</Typography>
                        </>
                      )}

                      {item?.meta?.supportingDocumentUrl && (
                        <>
                          <Typography>
                            <small>
                              <a
                                href={
                                  process.env.REACT_APP_DO_SPACE_URL +
                                  item?.meta?.supportingDocumentUrl +
                                  `?${Math.random()}`
                                }
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                View Document
                              </a>
                            </small>
                          </Typography>
                          <Typography>•</Typography>
                        </>
                      )}

                      {!item?.exitAt ? (
                        <>
                          <Typography>
                            {fDateTimeSuffix(item?.createdAt)}
                          </Typography>
                          <Typography>•</Typography>
                        </>
                      ) : (
                        item?.exitAt && (
                          <>
                            <Label color="error">
                              Left organisation on {fDate(item?.exitAt)}
                            </Label>
                          </>
                        )
                      )}
                    </Stack>
                  }
                />
              </ListItem>
              {item?.hasCareerProtection && (
                <Alert
                  severity={
                    new Date(item?.expiresAt) > new Date() ? "success" : "error"
                  }
                  sx={{ mt: 2, ml: 8 }}
                  icon={
                    <VerifiedUser
                      color={
                        new Date(item?.expiresAt) > new Date()
                          ? "success"
                          : "error"
                      }
                    />
                  }
                  action={
                    item?.certificate?.url && (
                      <Button
                        component={"a"}
                        href={item?.certificate?.url || "#"}
                        target="_blank"
                        rel="noreferrer"
                        endIcon={<OpenInNew />}
                      >
                        View certificate
                      </Button>
                    )
                  }
                >
                  <Typography variant="h6">
                    {item?.status === "pending"
                      ? "Request sent to join with Career Protection"
                      : "Career Protection enabled."}{" "}
                    {new Date(item?.expiresAt) < new Date() ? (
                      <small style={{ color: "red" }}>
                        {" - Expired on " + fDate(item?.expiresAt)}
                      </small>
                    ) : (
                      <small style={{ color: "green" }}>
                        {" - valid till " + fDate(item?.expiresAt)}
                      </small>
                    )}
                  </Typography>
                </Alert>
              )}
              {currentUser?.role === "user" &&
                !item?.hasCareerProtection &&
                currentUser?.userId === item?.user?._id &&
                item?.status === "accepted" && (
                  <Alert
                    severity={"warning"}
                    sx={{ mt: 2, ml: 8 }}
                    icon={<AddModerator />}
                    action={
                      <Button
                        startIcon={<AddTwoTone />}
                        color="warning"
                        variant="outlined"
                        size="small"
                        // onClick={() => handleAddCareerProtection(item)}

                        component={Link}
                        to={`/dashboard/career-protection/${
                          item?.organisation?._id
                        }?meta=${JSON.stringify(item?.meta)}`}
                      >
                        Add Career Protection
                      </Button>
                    }
                  >
                    <Typography variant="h6">
                      Add Career Protection benefits and get Certificate.
                    </Typography>
                  </Alert>
                )}
              {idx !== requests?.length - 1 && (
                <Divider variant="inset" component="li" sx={{ my: 2 }} />
              )}
            </Fragment>
          ))}
        </List>
      ) : (
        whichPage !== "career-protection" && (
          <EmptyHere
            title="No Join Requests/invitations"
            subtitle={
              searchString?.length > 0
                ? "No requests found for your search"
                : "You have no requests/invitations at the moment"
            }
          />
        )
      )}

      {!loading && requests?.length > 0 && (
        <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
