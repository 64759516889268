import { Card, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { calculateTimeRemain } from "src/utils/helpers";

export default function ExamOngoing({
  duration,
  time,
  setTime,
  handleFormSubmit,
}) {
  const [maxDuration, setMaxDuration] = useState(duration);
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    if (isTimeUp) return;
    setMaxDuration(duration * 60);

    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    if (time === maxDuration) {
      setIsTimeUp(true);
      clearInterval(interval);
      // submit form
      setTimeout(() => {
        handleFormSubmit();
      }, 2000); // wait for 2 seconds
    }
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave?";
      return event.returnValue;
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  return (
    <div>
      <Card
        sx={{
          width: "200px",
          height: "100px",
          textAlign: "center",
          ml: 2,
        }}
      >
        <Typography
          sx={{
            position: "relative",
            height: "70%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={"1.3rem"}
            component={"span"}
            color={isTimeUp ? "error.main" : "text.primary"}
          >
            <b>
              {isTimeUp
                ? "Time's up!"
                : calculateTimeRemain(maxDuration - time)}
            </b>
          </Typography>
        </Typography>
        <Divider />
        <Typography variant="subtitle1">
          <small>{isTimeUp ? "Thank You!" : `Time remain`}</small>
        </Typography>
      </Card>
    </div>
  );
}
