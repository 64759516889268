import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Stack,
  Button,
  Typography,
  CardMedia,
  TextField,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Divider,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import {
  AddCircleOutlineOutlined,
  AddTwoTone,
  CloseOutlined,
  ContentCopy,
  DeleteOutlined,
  DeleteTwoTone,
} from "@mui/icons-material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { LoadingButton } from "@mui/lab";
import { Confirm, Notify } from "notiflix";

import axiosInstance from "src/utils/axiosInstance";
import {
  DO_SPACES_FOLDERS,
  InputTypes,
  InputTypesObject,
} from "src/utils/constants";
import { generateRandomString } from "src/utils/helpers";
import { FormContext } from "src/contexts/FormContext";

export default function CreateUpdateQuestion({
  selectedContextualForm,
  setSelectedContextualForm,
  totalWeightage,
  handler,
  mode = "create",
}) {
  const {
    form,
    setForm,
    handleGetForm,
    selectedFormGroup,
    setSelectedFormGroup,
    selectedQuestionGroup,
    setSelectedQuestionGroup,
    selectedQuestion,
    setSelectedQuestion,
    setCurrentSelectedItemType,
    setSelectedOption,
    setAddOpen,
  } = useContext(FormContext);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({
    min: 0,
    max: 0,
    title: "",
    placeholder: "",
    description: "",
    required: true,
    type: InputTypes[0]?.value,
    options: [],
  });
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    if (mode && mode === "update") {
      setFormInput({
        ...selectedContextualForm,
      });
      setOpen(true);
      if (
        selectedContextualForm?.description &&
        selectedContextualForm.description
      ) {
        setDescriptionVisible(true);
      }
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFormInput({
      title: "",
      placeholder: "",
      description: "",
      required: true,
      type: InputTypes[0]?.value,
      options: [],
    });
    setIsWait(false);
    setAddOpen(null);
    setSelectedContextualForm(null);
  };

  const handleSubmit = async () => {
    try {
      if (!formInput?.title) throw new Error("Question title is required");
      if (!formInput?.type) throw new Error("Question input type is required");
      if (!formInput?.min) throw new Error("Minimum value is required");
      if (!formInput?.max) throw new Error("Maximum value is required");
      if (formInput?.min < 0)
        throw new Error("Minimum value cannot be less than zero");

      if (formInput?.max > totalWeightage)
        throw new Error(
          "Maximum value cannot be greater than " +
            totalWeightage +
            " (total weightage)"
        );

      if (formInput?.min > formInput?.max)
        throw new Error("Minimum value cannot be greater than maximum value");

      setIsWait(true);
      // generate form input name
      let resp = {};
      formInput.name = await generateRandomString(8);
      resp = await axiosInstance.post(
        `/forms/contextual/${selectedFormGroup?._id}`,
        formInput
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);

        setIsWait(false);
        Notify.success("Successfully");
        handleClose();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleUpdateQuestion = async () => {
    try {
      if (!formInput?.title) throw new Error("Question title is required");
      if (!formInput?.type) throw new Error("Question input type is required");
      if (!formInput?.min) throw new Error("Minimum value is required");
      if (!formInput?.max) throw new Error("Maximum value is required");
      if (formInput?.min < 0)
        throw new Error("Minimum value cannot be less than zero");

      if (formInput?.max > totalWeightage)
        throw new Error(
          "Maximum value cannot be greater than " +
            totalWeightage +
            " (total weightage)"
        );

      if (formInput?.min > formInput?.max)
        throw new Error("Minimum value cannot be greater than maximum value");

      setIsWait(true);
      // generate form input name
      let resp = {};
      resp = await axiosInstance.put(
        `/forms/contextual/${selectedFormGroup?._id}/${selectedContextualForm?._id}`,
        formInput
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);

        setIsWait(false);
        Notify.success("Successfully");
        // handleClose();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleCopyQuestion = async () => {
    try {
      setIsWait(true);
      formInput.name = await generateRandomString(8);
      delete formInput?._id;
      const resp = await axiosInstance.post(
        `/forms/contextual/${selectedFormGroup?._id}`,
        formInput
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);

        setIsWait(false);
        Notify.success("Successfully");
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleConfirmDeleteQuestion = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(
        `/forms/contextual/${selectedFormGroup?._id}/${selectedContextualForm?._id}`,
        formInput
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);

        setIsWait(false);
        Notify.success("Successfully");
        handleClose();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleDeleteQuestion = async () => {
    Confirm.show(
      "Confirmation",
      "Are you sure you want to delete?",
      "Confirm",
      "Cancel",
      () => handleConfirmDeleteQuestion(),
      () => {},
      {
        width: "500px",
        messageMaxLength: 1000,
      }
    );
  };

  if (!open && mode === "new") {
    return (
      <div>
        <Button
          variant="text"
          startIcon={<AddTwoTone fontSize="small" />}
          onClick={handleClickOpen}
        >
          Add Contextual Form
        </Button>
      </div>
    );
  }
  return (
    <Stack spacing={2} direction={"row"}>
      <Card sx={{ border: "1px solid #eee", width: "100%" }}>
        {mode === "update" && handler}
        <CardContent>
          <Stack
            direction={"row"}
            sx={{ px: 1, my: 1 }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>Trigger when Weightage is</Typography>
            <Stack spacing={2} alignItems={"center"} direction={"row"}>
              <TextField
                fullWidth
                name="min"
                placeholder={"Minimum"}
                variant="filled"
                size="small"
                InputProps={{
                  sx: {
                    backgroundColor: "transparent",
                    borderRadius: 0,
                    borderColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                sx={{
                  maxWidth: 100,
                  ".MuiFilledInput-underline": {
                    "&:before": {
                      borderColor: "transparent",
                    },
                  },
                  ".MuiFilledInput-input": {
                    paddingTop: "8px",
                    paddingLeft: 0,
                  },
                }}
                value={formInput?.min || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    min: parseInt(e.target.value),
                  });
                }}
              />
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {"<="}{" "}
                <Typography sx={{ mx: 2 }} component={"span"}>
                  ?
                </Typography>
                {"<="}
              </Typography>
              <TextField
                fullWidth
                name="max"
                placeholder={"Maximum"}
                variant="filled"
                size="small"
                InputProps={{
                  sx: {
                    backgroundColor: "transparent",
                    borderRadius: 0,
                    borderColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                sx={{
                  maxWidth: 100,
                  ".MuiFilledInput-underline": {
                    "&:before": {
                      borderColor: "transparent",
                    },
                  },
                  ".MuiFilledInput-input": {
                    paddingTop: "8px",
                    paddingLeft: 0,
                  },
                }}
                value={formInput?.max || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    max: parseInt(e.target.value),
                  });
                }}
              />
            </Stack>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  name="title"
                  multiline
                  minRows={1}
                  hiddenLabel
                  autoFocus
                  InputProps={{
                    sx: {
                      backgroundColor: "rgba(240, 235, 248, 0.4)",
                      borderRadius: 0,
                      "&:hover": {
                        backgroundColor: "rgba(240, 235, 248, 0.6)",
                      },
                    },
                  }}
                  sx={{ backgroundColor: "transparent", borderRadius: 0 }}
                  placeholder={"Write your Question here"}
                  variant="filled"
                  value={formInput.title}
                  onChange={handleChange}
                />
                {/* <TextField
          fullWidth
          hiddenLabel
          name="placeholder"
          placeholder={"Write a placeholder here"}
          variant="filled"
          value={formInput.placeholder}
          onChange={handleChange}
        /> */}
                {descriptionVisible && (
                  <Box my={1}>
                    <TextField
                      fullWidth
                      hiddenLabel
                      name="description"
                      placeholder={
                        "Write a description/subtitle/clarification here (optional)"
                      }
                      InputProps={{
                        sx: {
                          backgroundColor: "transparent",
                          borderRadius: 0,
                          borderColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      sx={{ backgroundColor: "transparent", borderRadius: 0 }}
                      multiline
                      minRows={1}
                      variant="filled"
                      value={formInput.description}
                      onChange={handleChange}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>

            {/* <Grid item xs={4}>
        <Typography variant="caption">
          Select Input Types of question
        </Typography>
        <Grid container spacing={1} sx={{ mt: 0 }}>
          {InputTypes.map((item) => (
            <Grid item xs={6} key={item.value}>
              <Button
                onClick={() => {
                  setFormInput({ ...formInput, type: item.value });
                }}
                variant={
                  formInput.type === item.value ? "contained" : "outlined"
                }
                fullWidth
                startIcon={<item.icon fontSize="small" />}
                size="small"
              >
                {item.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid> */}
          </Grid>

          <Box sx={{ mt: 2, mb: 1 }}>
            <Grid container>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="el-type-label">Select Input Type</InputLabel>
                  <Select
                    labelId="el-type-label"
                    id="el-type"
                    label="Select Input Type"
                    value={formInput.type}
                    onChange={(e) =>
                      setFormInput({ ...formInput, type: e.target.value })
                    }
                  >
                    {InputTypes &&
                      InputTypes.map((el, key) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box>
            {["radio", "checkbox", "select", "rating"].includes(
              formInput?.type
            ) && (
              <>
                <Divider sx={{ my: 2 }} />
                {formInput?.options?.map((item, index) => (
                  <Stack key={index} spacing={1}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{ mt: 1 }}
                    >
                      {InputTypesObject[formInput?.type].icon}
                      <Grid container>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            name="label"
                            size="small"
                            sx={{
                              ".MuiFilledInput-underline": {
                                "&:before": {
                                  borderColor: "transparent",
                                },
                              },
                              ".MuiFilledInput-input": {
                                paddingTop: "8px",
                                paddingLeft: 0,
                              },
                            }}
                            InputProps={{
                              sx: {
                                backgroundColor: "transparent",
                                borderRadius: 0,
                                borderColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                            placeholder={"Option"}
                            variant="filled"
                            value={item?.label}
                            onChange={(e) => {
                              const newOptions = [...formInput?.options];
                              newOptions[index].label = e.target.value;
                              setFormInput({
                                ...formInput,
                                options: newOptions,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}>
                          <TextField
                            fullWidth
                            name="label"
                            size="small"
                            placeholder={"Weightage"}
                            variant="filled"
                            InputProps={{
                              sx: {
                                backgroundColor: "transparent",
                                borderRadius: 0,
                                borderColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                            sx={{
                              ".MuiFilledInput-underline": {
                                "&:before": {
                                  borderColor: "transparent",
                                },
                              },
                              ".MuiFilledInput-input": {
                                paddingTop: "8px",
                                paddingLeft: 0,
                              },
                            }}
                            value={item?.weightage}
                            onChange={(e) => {
                              const newOptions = [...formInput?.options];
                              newOptions[index].weightage = e.target.value;
                              setFormInput({
                                ...formInput,
                                options: newOptions,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <IconButton
                        onClick={() => {
                          const newOptions = [...formInput?.options];
                          newOptions.splice(index, 1);
                          setFormInput({
                            ...formInput,
                            options: newOptions,
                          });
                        }}
                      >
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
                <Box sx={{ mt: 1 }}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    {InputTypesObject[formInput?.type].icon}
                    <Button
                      sx={{
                        px: 0,
                        borderRadius: 0,
                        borderBottom: "1px solid transparent",
                        "&:hover": {
                          background: "transparent",
                          borderBottom: "1px solid grey",
                        },
                      }}
                      size="small"
                      // startIcon={<AddTwoTone fontSize="small" />}
                      onClick={async () => {
                        const newOptions = [...formInput?.options];
                        newOptions.push({
                          label: "Option " + (newOptions.length + 1),
                          value: await generateRandomString(8),
                          weightage: formInput?.type === "rating" ? 1 : 0,
                        });
                        setFormInput({
                          ...formInput,
                          options: newOptions,
                        });
                      }}
                    >
                      Add Option
                    </Button>
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <IconButton
            size="small"
            mx={2}
            disabled={isWait}
            onClick={handleCopyQuestion}
          >
            <ContentCopy />
          </IconButton>

          <IconButton
            size="small"
            mx={2}
            disabled={isWait}
            onClick={handleDeleteQuestion}
          >
            <DeleteOutlined />
          </IconButton>
          <Box
            sx={{
              width: "1px",
              height: "30px",
              background: "grey",
              mx: 2,
            }}
          ></Box>
          <FormControlLabel
            control={
              <Switch
                name="required"
                checked={descriptionVisible}
                onChange={(e) => {
                  setDescriptionVisible(!descriptionVisible);
                }}
              />
            }
            label="Description"
          />
          <Box sx={{ marginLeft: "auto!important" }}>
            {
              mode !== "update" ? (
                <Button onClick={handleClose}>Cancel</Button>
              ) : null
              //    <LoadingButton
              //    onClick={handleDeleteQuestion}
              //    variant="contained"
              //    disableElevation
              //    loading={isWait}
              //  >
              //    Delete
              //  </LoadingButton>
            }
            {mode !== "update" ? (
              <LoadingButton
                onClick={handleSubmit}
                variant="outlined"
                color="secondary"
                disableElevation
                size="small"
                sx={{ borderRadius: "4px" }}
                loading={isWait}
              >
                Submit
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={handleUpdateQuestion}
                variant="outlined"
                color="secondary"
                disableElevation
                size="small"
                sx={{ borderRadius: "4px" }}
                loading={isWait}
              >
                Save
              </LoadingButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <Card sx={{ border: "1px solid #eee", maxHeight: "160px" }}>
        <CardContent sx={{ p: "10px" }}>
          <Box my={1}>
            <IconButton
              size="small"
              disabled={isWait}
              onClick={() => setAddOpen("contextual")}
            >
              <AddCircleOutlineOutlined />
            </IconButton>
          </Box>
          <Box my={1}>
            <IconButton
              size="small"
              disabled={isWait}
              onClick={handleCopyQuestion}
            >
              <ContentCopy />
            </IconButton>
          </Box>
          <Box my={1}>
            <IconButton
              size="small"
              disabled={isWait}
              onClick={handleDeleteQuestion}
            >
              <DeleteOutlined />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}
