import { Add, ArrowBack, Delete, HelpTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import {
  FIXED_PRICING_CATEGORIES,
  PLAN_PRICING_CATEGORIES,
} from "src/utils/constants";
import CareerProtectionPlan from "./components/CareerProtectionPlan";

export default function AddEditPricing() {
  const { priceId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    setSelectedItems(formInput?.items || []);
  }, [formInput?.items]);

  useEffect(() => {
    if (priceId) {
      handleGetPrice();
    } else {
      setFormInput({
        type: "plan",
      });
    }
  }, [priceId, currentUser]);

  const handleGetPrice = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/pricing/${priceId}`);
      if (resp?.status === 200) {
        setFormInput(resp?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      if (!formInput?.title) {
        return Notify.failure("Title is required");
      }
      if (!formInput?.amount) {
        return Notify.failure("Amount is required");
      }
      if (!formInput?.type) {
        return Notify.failure("Plan Type is required");
      }
      if (formInput?.type === "plan" && !formInput?.duration) {
        return Notify.failure("Plan Duration is required");
      }
      if (formInput?.type === "plan" && !formInput?.services?.length) {
        return Notify.failure("Atleast a Service(s) in Plan is required");
      }
      if (formInput?.type === "fixed" && !formInput?.category) {
        return Notify.failure("Plan Category is required");
      }
      if (
        formInput?.category === "joinOrganisations" &&
        !formInput?.certificate?.certificateId
      ) {
        return Notify.failure("Certificate is required");
      }

      if (formInput?.amount) {
        formInput.amount = Number(formInput?.amount);
      }

      // features: remove empty features
      if (formInput?.features?.length) {
        formInput.features = formInput?.features?.filter((feature) => feature);
      }

      setIsWait(true);

      const _formInput = { ...formInput };
      const _items = selectedItems?.map((item) => item?._id) || [];
      _formInput.items = _items;
      if (formInput?._id) {
        const resp = await axiosInstance.put(
          `/pricing/${formInput?._id}`,
          _formInput
        );
        if (resp?.status === 200) {
          Notify.success("Updated");
          setFormInput(resp?.data || {});
        }
      } else {
        const resp = await axiosInstance.post(`/pricing`, _formInput);
        if (resp?.status === 201) {
          Notify.success("Successful");
          setTimeout(() => {
            navigate(`/dashboard/pricing/${resp?.data?._id}`);
          }, 600);
        }
      }

      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.delete(`/pricing/${formInput?._id}`);
      if (resp?.status === 200) {
        Notify.success("Deleted");
        navigate(`/dashboard/pricing`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <Card sx={{ p: 2 }}>
          <Stack spacing={2}>
            <TextField
              label="Title *"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />
            <TextField
              label="Subtitle"
              fullWidth
              name="subtitle"
              value={formInput?.subtitle || ""}
              onChange={handleInputChange}
            />{" "}
            <Tabs
              sx={{ width: "100%" }}
              value={formInput?.type}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  type: value,
                  category: "",
                  duration: value === "plan" ? "lifetime" : "",
                  services: [],
                });
              }}
            >
              {[
                { label: "Fixed Amount", value: "fixed" },
                { label: "Pricing Plan", value: "plan" },
              ]?.map((d, i) => (
                <Tab
                  label={d?.label}
                  key={i}
                  value={d?.value}
                  sx={{ width: "100%", border: "1px solid #ccc" }}
                />
              ))}
            </Tabs>
            {formInput?.type && (
              <>
                <Stack direction="row" spacing={2}>
                  {formInput?.type === "plan" ? (
                    <>
                      <FormControl fullWidth>
                        <InputLabel>Plan Duration *</InputLabel>
                        <Select
                          label="Plan Duration *"
                          name="duration"
                          value={formInput?.duration}
                          onChange={handleInputChange}
                        >
                          {[
                            // {
                            //   label: "Monthly",
                            //   value: "monthly",
                            // },
                            // {
                            //   label: "Yearly",
                            //   value: "yearly",
                            // },
                            {
                              label: "One Time Payment",
                              value: "lifetime",
                            },
                          ]?.map((d, i) => (
                            <MenuItem key={i} value={d?.value}>
                              <b>{d?.label}</b>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel>Services in Plan *</InputLabel>
                        <Select
                          multiple
                          label="Services in Plan *"
                          name="category"
                          value={formInput?.services || []}
                          onChange={(e) => {
                            const {
                              target: { value },
                            } = e;
                            setFormInput({
                              ...formInput,
                              services:
                                typeof value === "string"
                                  ? value.split(",")
                                  : value,
                            });
                          }}
                          input={<OutlinedInput label="Services in Plan *" />}
                          renderValue={(selected) => selected?.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {Object.entries(PLAN_PRICING_CATEGORIES)?.map(
                            ([key, value], i) => (
                              <MenuItem key={i} value={key}>
                                <Checkbox
                                  checked={
                                    formInput?.services?.indexOf(key) > -1
                                  }
                                />
                                <ListItemText primary={value?.label} />
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel>Select Category *</InputLabel>
                      <Select
                        label="Plan Category *"
                        name="category"
                        value={formInput?.category}
                        onChange={handleInputChange}
                      >
                        {Object.entries(FIXED_PRICING_CATEGORIES)?.map(
                          ([key, value], i) => (
                            <MenuItem key={i} value={key}>
                              <b>{value?.label}</b>
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  {formInput?.type === "plan" && (
                    <>
                      {" "}
                      {formInput?.services?.includes("users") && (
                        <TextField
                          label="Users' Profiles Quota"
                          placeholder={"How many Users' profiles can be viewed"}
                          name="users"
                          fullWidth
                          type="number"
                          value={formInput?.quota?.users || ""}
                          onChange={(e) => {
                            setFormInput({
                              ...formInput,
                              quota: {
                                ...formInput?.quota,
                                users: e.target.value,
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Number of Users' profiles that Organisation can view">
                                  <HelpTwoTone
                                    sx={{ mx: 1 }}
                                    fontSize="small"
                                    color="info"
                                  />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      {formInput?.services?.includes("forms") && (
                        <TextField
                          label="Buyer will decide Submissions quota (Number of Rating) at Checkout"
                          fullWidth
                          disabled
                        />
                      )}
                    </>
                  )}
                </Stack>
              </>
            )}
            <TextField
              label="Amount (INR)"
              name="amount"
              sx={{ width: "50%" }}
              value={formInput?.amount || ""}
              onChange={handleInputChange}
              type="number"
              InputProps={
                formInput?.duration && {
                  endAdornment: (
                    <InputAdornment position="end">
                      per user
                      {formInput?.duration === "lifetime"
                        ? ""
                        : formInput?.duration === "monthly"
                        ? "/month"
                        : "/year"}
                    </InputAdornment>
                  ),
                }
              }
            />
          </Stack>
        </Card>

        {/* {formInput?.services?.includes("forms") &&
          formInput?.type === "plan" && (
            <Card sx={{ p: 2, mt: 2 }}>
              <CardHeader
                title="Forms"
                subheader="Add forms that will be available to Rate in this plan"
                action={
                  <FormsDialog
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                }
              />
              {selectedItems?.length > 0 ? (
                <List>
                  {selectedItems?.map((item, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            let items = [...selectedItems];
                            items.splice(index, 1);
                            setSelectedItems(items);
                          }}
                        >
                          <DeleteTwoTone color="error" />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>{index + 1}.</ListItemAvatar>
                      <ListItemText primary={<b>{item?.title}</b>} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <EmptyHere
                  title={"No Forms Added"}
                  subtitle={"Add Forms to Rate"}
                />
              )}
            </Card>
          )} */}

        {formInput?.category === "joinOrganisations" && (
          <CareerProtectionPlan
            formInput={formInput}
            setFormInput={setFormInput}
          />
        )}

        <Card sx={{ p: 2, mt: 2 }}>
          <CardHeader title="Add Features (optional)" />
          <Stack spacing={2} sx={{ mt: 2 }}>
            {formInput?.features?.map((feature, index) => (
              <TextField
                label={`Feature ${index + 1}`}
                value={feature || ""}
                onChange={(e) => {
                  let features = [...formInput?.features];
                  features[index] = e.target.value;
                  setFormInput({
                    ...formInput,
                    features,
                  });
                }}
                fullWidth
                key={index}
              />
            ))}
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={() => {
                let features = [...(formInput?.features || [])];
                features.push("");
                setFormInput({
                  ...formInput,
                  features,
                });
              }}
            >
              Add Feature
            </Button>
          </Stack>
        </Card>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            color="secondary"
            startIcon={<ArrowBack />}
            component={Link}
            to="/dashboard/pricing"
          >
            Go Back
          </Button>{" "}
          {formInput?._id && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<Delete />}
              onClick={() => {
                Confirm.show(
                  "Confirmation",
                  "Are you sure you want to delete?",
                  "Confirm",
                  "Cancel",
                  () => handleDelete(),
                  () => {},
                  {
                    width: "500px",
                    messageMaxLength: 1000,
                  }
                );
              }}
            >
              Delete
            </Button>
          )}
        </Stack>

        <LoadingButton
          onClick={handleSubmit}
          loading={isWait}
          variant="contained"
          color="primary"
        >
          {formInput?._id ? "Update" : "Submit"}
        </LoadingButton>
      </CardActions>
    </Card>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
