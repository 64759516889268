import { CheckCircleTwoTone, EventTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import InputFieldsSwitch from "../../common/InputFieldsSwitch";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";

export default function JoinEventDialog({ event, registration }) {
  const { currentUser } = useContext(GlobalContext);
  const [customFields, setCustomFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({
    meta: {},
  });
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    (async () => {
      const resp = await axiosInstance.get("/tags/type", {
        params: {
          type: "participant",
        },
      });
      if (resp?.status === 200) {
        setCustomFields(resp?.data?.inputs || []);
      }
    })();
  }, []);

  const handleJoinEvent = async () => {
    try {
      // check if all required fields are filled for custom fields
      if (formInput?.meta) {
        for (const item of customFields) {
          if (!formInput?.meta[item?.name]) {
            Notify.failure(`Please fill in ${item?.label}`);
            return;
          }
        }
      }

      setIsWait(true);

      // map metas (file, text etc)
      const _meta = {};
      if (formInput?.meta) {
        const mappedFormInputMeta = Object.entries(formInput?.meta)?.map(
          async ([key, value]) => {
            let _value = value;
            // if value is file
            if (value instanceof File) {
              try {
                const _fileName = key + "." + value?.name?.split(".")?.pop();
                const modifiedFile = new File([value], _fileName, {
                  type: value.type,
                });
                const formData = new FormData();
                formData.append("files", modifiedFile);
                const resp = await axiosInstance.post(
                  "/users/upload-media",
                  formData,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                );
                if (resp?.status === 200 && resp?.data) {
                  _value = resp?.data?.[0];
                }
              } catch (error) {
                console.log(error);
                throw new Error(error);
              }
            }

            let isCustomeField = customFields?.find(
              (item) => item?.name === key
            );
            if (isCustomeField) {
              // if it is custom field
              if (typeof _value === "object") {
                // old value
                _meta[key] = _value;
              } else {
                // new value
                _meta[key] = {
                  ...isCustomeField,
                  value: _value,
                };
              }
            } else {
              // if it is simple meta key:value
              _meta[key] = _value;
            }
          }
        );
        // Wait for all promises to resolve
        await Promise.all(mappedFormInputMeta);
      }
      /// attach meta to formInput
      setFormInput((prev) => ({ ...prev, meta: _meta }));

      const resp = await axiosInstance.post(`/customer-relations`, {
        organisationId: event?.organisation?._id,
        user: currentUser?.userId,
        event: event?._id,
        relation: "participant",
        type: "checkin",
        meta: formInput?.meta,
      });
      if (resp?.status === 200) {
        Notify.success("Registered successfully");
        setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      setIsWait(false);
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <>
      <Card sx={{ p: 3 }}>
        {!registration &&
          new Date(event?.endDateTime) > new Date() &&
          new Date(event?.startDateTime) < new Date() && (
            <LoadingButton
              variant="contained"
              color={"success"}
              fullWidth
              size="large"
              startIcon={<EventTwoTone />}
              onClick={() => setOpen(true)}
              loading={isWait}
            >
              Join Event{" "}
            </LoadingButton>
          )}{" "}
        {registration && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 0%",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "rgb(88, 96, 105)",
                textTransform: "uppercase",
                fontSize: "14px",
                lineHeight: "18px",
                alignItems: "center",
                display: "flex",
              }}
            >
              Registered{" "}
              <CheckCircleTwoTone
                sx={{ mx: 1 }}
                fontSize="small"
                color="success"
              />
            </Typography>
            <Divider sx={{ my: 2 }} />
            {[
              {
                label: "Name",
                value:
                  registration?.user?.firstName +
                  " " +
                  (registration?.user?.lastName || ""),
              },
              {
                label: "Email",
                value: registration?.user?.email || "-",
              },
              {
                label: "Phone Number",
                value: registration?.user?.phoneNumber || "-",
              },
            ].map((item, idx) => (
              <Stack
                direction="row"
                spacing={3}
                alignItems="center"
                key={idx}
                my={1}
              >
                <Typography>{item.label}</Typography>
                <Typography variant="h5">{item.value}</Typography>
              </Stack>
            ))}
          </Box>
        )}
      </Card>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Join Event Confirmation</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              {[
                {
                  label: "Register as",
                  value:
                    currentUser?.firstName +
                    " " +
                    (currentUser?.lastName || ""),
                },
                {
                  label: "Email",
                  value: currentUser?.email || "-",
                },
              ].map((item, idx) => (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  key={idx}
                >
                  <Typography>{item.label}</Typography>
                  <Typography variant="h5">{item.value}</Typography>
                </Stack>
              ))}
            </Stack>

            {customFields?.length > 0 && (
              <>
                <Divider textAlign="center" sx={{ my: 4 }}>
                  Please fill in the following information
                </Divider>
                <Grid container spacing={2}>
                  {customFields?.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      md={item?.type === "file" ? 12 : 6}
                      key={index}
                    >
                      <InputFieldsSwitch
                        question={item}
                        formInput={formInput?.meta || {}}
                        handleInputChange={({ key, value }) => {
                          setFormInput({
                            ...formInput,
                            meta: {
                              ...(formInput?.meta || {}),
                              [key]: value,
                            },
                          });
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="success"
              onClick={handleJoinEvent}
            >
              Join Event
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
