export const validateNewEditForm = async (formInput, currentUser) => {
  if (!formInput?.title) throw new Error("Title is required");
  if (!formInput?.industry) throw new Error("Industry is required");

  if (formInput?.access?.length === 0)
    throw new Error("Accessibility is required");

  switch (formInput?.type) {
    case "rating":
      if (!formInput?.access || formInput?.receivers?.length === 0) {
        throw new Error("Submitter and Receivers are required");
      }
      break;

    case "exam":
      if (!formInput?.passingPercentage)
        throw new Error("Passing Percentage is required");
      if (
        formInput?.passingPercentage < 0 ||
        formInput?.passingPercentage > 100
      ) {
        throw new Error("Passing Percentage must be between 0 and 100");
      }
      break;

    default:
      break;
  }

  if (formInput?.premium) {
    if (!formInput?.amount || formInput?.amount < 0)
      throw new Error("Amount is required & must be greater than 0");
    // if (formInput?.quota < 0)
    //   throw new Error("Rating Quota cannot be less than 0");
  }

  return true;
};
