import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  alpha,
  capitalize,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";
import { fDate, fDateTimeSuffix } from "src/utils/date-fns";
import {
  AddTwoTone,
  EditTwoTone,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  PeopleAlt,
} from "@mui/icons-material";
import { GlobalContext } from "src/contexts/GlobalContext";
import Label from "src/components/Label";

export default function CustomerRelations({ organisationId }) {
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const typeParam = searchParams.get("type");
  const [filterObj, setFilterObj] = useState({});
  const [loading, setLoading] = useState(false);

  const [organisation, setOrganisation] = useState(null);
  const [applications, setApplications] = useState([]);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [customerRelations, setCustomerRelations] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    setOrganisation(organisationId || currentUser?.orgId);
  }, [currentUser, organisationId]);

  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
          checkin: "false",
        };
      }

      if (organisationId || organisation) {
        reqParams.params.organisation = organisationId || organisation;
      }

      const resp = await axiosInstance.get("/customer-relations", reqParams);
      if (resp?.status === 200) {
        setApplications(resp?.data);
      }
      setLoading(false);
    })();
  }, [page, filterObj, typeParam]);

  const CollapsibleTableRow = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell>
            <Box
              display={"flex"}
              alignItems={"center"}
              component={Link}
              to={`/dashboard/users/${row?.user?._id}`}
              style={{ textDecoration: "none" }}
            >
              <Avatar
                src={
                  row?.user?.profileImage
                    ? process.env.REACT_APP_DO_SPACE_URL +
                      (row?.user?.profileImage + "?" + Math.random())
                    : null
                }
                sx={{ width: "36px", mr: 1, height: "36px" }}
              >
                {row?.user?.first_name?.toString()?.slice(0, 1)}
              </Avatar>
              <Box>
                <Typography
                  component="h5"
                  sx={{
                    color: "rgb(38, 38, 38)",
                    margin: "0px",
                    wordBreak: "break-word",
                    fontSize: "14px",
                    lineHeight: "18px",
                    textDecoration: !row?.isActive ? "line-through" : "none",
                    color: !row?.isActive ? "red" : "inherit",
                  }}
                >
                  {row?.user?.firstName || "-"} {row?.user?.lastName || ""}
                </Typography>
                <Typography
                  component="h6"
                  sx={{
                    color: "rgb(88, 96, 105)",
                    margin: "0px",
                    wordBreak: "break-word",
                    cursor: "pointer",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {row?.user?.email}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            {" "}
            <Box
              display={"flex"}
              alignItems={"center"}
              component={Link}
              to={`/dashboard/organisations/${row?.organisation?._id}`}
              style={{ textDecoration: "none" }}
            >
              <Avatar
                src={
                  row?.organisation?.avatar
                    ? process.env.REACT_APP_DO_SPACE_URL +
                      (row?.organisation?.avatar + "?" + Math.random())
                    : null
                }
                sx={{ width: "36px", mr: 1, height: "36px" }}
              >
                {row?.organisation?.title?.toString()?.slice(0, 1)}
              </Avatar>
              <Box>
                <Typography
                  component="h5"
                  sx={{
                    color: "rgb(38, 38, 38)",
                    margin: "0px",
                    wordBreak: "break-word",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "inherit",
                  }}
                >
                  {row?.organisation?.title || "-"}{" "}
                </Typography>
                <Typography
                  component="h6"
                  sx={{
                    color: "rgb(88, 96, 105)",
                    margin: "0px",
                    wordBreak: "break-word",
                    cursor: "pointer",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {row?.organisation?.companyEmail || "-"}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell
            sx={{
              textDecoration: !row?.isActive ? "line-through" : "none",
              color: !row?.isActive ? "red" : "inherit",
            }}
          >
            {capitalize(row?.relation || "-")}
            {row?.status !== "accepted" && (
              <Typography>
                <small>
                  <Label
                    color={row?.status === "pending" ? "warning" : "error"}
                  >
                    {row?.status === "pending"
                      ? "Waiting for response"
                      : "User rejected the request"}
                  </Label>
                </small>
              </Typography>
            )}
          </TableCell>
          <TableCell align="right">{fDateTimeSuffix(row?.createdAt)}</TableCell>
          <TableCell align="right">
            {(currentUser?.accountType === "admin" ||
              currentUser?.orgId === row?.organisation?._id) &&
              row?.status !== "rejected" && (
                <IconButton
                  size="small"
                  component={Link}
                  color="warning"
                  to={`/dashboard/customer-relations/${row?._id}/edit`}
                >
                  <EditTwoTone fontSize="small" />
                </IconButton>
              )}
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Stack sx={{ margin: 1 }}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Card
                      elevation={0}
                      sx={{
                        background: (theme) =>
                          alpha(theme.colors.alpha.black[100], 0.05),
                        width: "100%",
                      }}
                    >
                      <CardHeader
                        title="User Details"
                        action={
                          <Button
                            size="small"
                            endIcon={<OpenInNew />}
                            component={Link}
                            to={`/dashboard/users/${row?.user?._id}`}
                          >
                            View Profile
                          </Button>
                        }
                      />
                      <Divider />
                      <List dense>
                        {[
                          {
                            label: "Full Name",
                            value:
                              row?.user?.firstName +
                              " " +
                              (row?.user?.lastName || ""),
                          },
                          {
                            label: "Email",
                            value: row?.user?.email,
                          },
                          {
                            label: "Contact",
                            value: row?.user?.phoneNumber,
                          },
                          {
                            label: "Location",
                            value:
                              [
                                row?.address?.city,
                                row?.address?.state,
                                row?.address?.country,
                                row?.address?.pincode,
                              ]
                                .filter((item) => item)
                                .join(", ") || "-",
                          },
                          {
                            label: "Complete Address",
                            value: row?.address?.address1 || "-",
                          },
                        ].map((item, idx) => (
                          <ListItem key={idx}>
                            <ListItemText
                              primaryTypographyProps={{
                                variant: "h5",
                              }}
                              primary={item?.label}
                            />
                            <Typography variant="subtitle1">
                              {item?.value}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  </Grid>
                  {Object.keys(row?.meta || {}).length > 0 && (
                    <Grid item xs={12} md={6}>
                      <Card
                        elevation={0}
                        sx={{
                          background: (theme) =>
                            alpha(theme.colors.alpha.black[100], 0.05),
                          width: "100%",
                        }}
                      >
                        <CardHeader
                          title={`${capitalize(
                            row?.relation || "Other"
                          )} Details`}
                        />
                        <Divider />
                        <List dense>
                          {Object.entries(row?.meta || {})?.map(
                            ([key, value], idx) => (
                              <ListItem key={idx}>
                                <ListItemText
                                  primaryTypographyProps={{
                                    variant: "h5",
                                  }}
                                  primary={`${capitalize(
                                    key?.label || key || ""
                                  )}:`}
                                />
                                <Typography variant="subtitle1">
                                  {typeof value === "string"
                                    ? value
                                    : {
                                        text: value?.value || "-",
                                        date: fDate(value?.value || new Date()),
                                        file: (
                                          <Button
                                            size="small"
                                            component={"a"}
                                            sx={{ px: 0 }}
                                            href={
                                              process.env
                                                .REACT_APP_DO_SPACE_URL +
                                              value?.value
                                            }
                                            target="_blank"
                                          >
                                            {value?.value?.split("/")?.pop()}
                                          </Button>
                                        ),
                                      }[value?.type]}
                                </Typography>
                              </ListItem>
                            )
                          )}
                        </List>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (e.target.value.length) {
                  setFilterObj({ ...filterObj, search: searchString });
                } else {
                  setFilterObj({ ...filterObj, search: null });
                }
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              if (e.target.value?.length > 0) {
                setSearchString(e.target.value);
              } else {
                setFilterObj({
                  ...filterObj,
                  search: null,
                });
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              p: "10px",
              m: 0.2,
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              setFilterObj({
                ...filterObj,
                search: searchString,
              });
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        {(currentUser?.accountType === "admin" || currentUser?.orgId) && (
          <Stack direction="row" spacing={2}>
            <Autocomplete
              fullWidth
              size="small"
              sx={{ width: 200 }}
              options={customerRelations || []}
              value={filterObj?.relation || ""}
              onChange={(e, value) => {
                setFilterObj({
                  ...filterObj,
                  relation: value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Relation Type"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {(organisation || currentUser?.orgId) && (
              <>
                <Button
                  size="small"
                  startIcon={<PeopleAlt />}
                  variant="outlined"
                  component={Link}
                  to={`/dashboard/customer-relations/bulk${
                    currentUser.accountType === "admin"
                      ? `?organisation=${organisation}`
                      : ""
                  }`}
                  color="info"
                >
                  Bulk Upload
                </Button>
                <Button
                  size="small"
                  startIcon={<AddTwoTone />}
                  variant="outlined"
                  component={Link}
                  to={`/dashboard/customer-relations/create${
                    currentUser.accountType === "admin"
                      ? `?organisation=${organisation}`
                      : ""
                  }`}
                >
                  Add New
                </Button>
              </>
            )}
          </Stack>
        )}
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Relation</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications?.data?.map((row, idx) => (
                <CollapsibleTableRow row={row} key={idx} />
              ))}

              {applications?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    It's empty here
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && applications?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={applications?.totalPages || 1}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
