import { OpenInNew } from "@mui/icons-material";
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TableCell,
  TableRow,
  Typography,
  alpha,
  capitalize,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { fDate } from "src/utils/date-fns";

export default function CustomerRelationMeta({ row, open }) {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack sx={{ margin: 1 }}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Card
                  elevation={0}
                  sx={{
                    background: (theme) =>
                      alpha(theme.colors.alpha.black[100], 0.05),
                    width: "100%",
                  }}
                >
                  <CardHeader
                    title="User Details"
                    action={
                      <Button
                        size="small"
                        endIcon={<OpenInNew />}
                        component={Link}
                        to={`/dashboard/users/${row?.user?._id}`}
                      >
                        View Profile
                      </Button>
                    }
                  />
                  <Divider />
                  <List dense>
                    {[
                      {
                        label: "Full Name",
                        value:
                          row?.user?.firstName +
                          " " +
                          (row?.user?.lastName || ""),
                      },
                      {
                        label: "Email",
                        value: row?.user?.email,
                      },
                      {
                        label: "Contact",
                        value: row?.user?.phoneNumber,
                      },
                      {
                        label: "Location",
                        value:
                          [
                            row?.address?.city,
                            row?.address?.state,
                            row?.address?.country,
                            row?.address?.pincode,
                          ]
                            .filter((item) => item)
                            .join(", ") || "-",
                      },
                      {
                        label: "Complete Address",
                        value: row?.address?.address1 || "-",
                      },
                    ].map((item, idx) => (
                      <ListItem key={idx}>
                        <ListItemText
                          primaryTypographyProps={{
                            variant: "h5",
                          }}
                          primary={item?.label}
                        />
                        <Typography variant="subtitle1">
                          {item?.value}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Card>
              </Grid>
              {Object.keys(row?.meta || {}).length > 0 && (
                <Grid item xs={12} md={6}>
                  <Card
                    elevation={0}
                    sx={{
                      background: (theme) =>
                        alpha(theme.colors.alpha.black[100], 0.05),
                      width: "100%",
                    }}
                  >
                    <CardHeader
                      title={`${capitalize(row?.relation || "Other")} Details`}
                    />
                    <Divider />
                    <List dense>
                      {Object.entries(row?.meta || {})?.map(
                        ([key, value], idx) => (
                          <ListItem key={idx}>
                            <ListItemText
                              primaryTypographyProps={{
                                variant: "h5",
                              }}
                              primary={`${capitalize(
                                key?.label || key || ""
                              )}:`}
                            />
                            <Typography variant="subtitle1">
                              {typeof value === "string"
                                ? value
                                : {
                                    text: value?.value || "-",
                                    date: fDate(value?.value || new Date()),
                                    file: (
                                      <Button
                                        size="small"
                                        component={"a"}
                                        sx={{ px: 0 }}
                                        href={
                                          process.env.REACT_APP_DO_SPACE_URL +
                                          value?.value
                                        }
                                        target="_blank"
                                      >
                                        {value?.value?.split("/")?.pop()}
                                      </Button>
                                    ),
                                  }[value?.type]}
                            </Typography>
                          </ListItem>
                        )
                      )}
                    </List>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
