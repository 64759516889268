import { Notify } from "notiflix";
import { createContext, useContext } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { GlobalContext } from "./GlobalContext";
import { useSearchParams } from "react-router-dom";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { openLoginDialog } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  const login = async (obj) => {
    try {
      const resp = await axiosInstance.post("/auth/login", obj);
      if (resp.status === 200) {
        localStorage.setItem("user", JSON.stringify(resp.data?.user));
        localStorage.setItem(
          "accessToken",
          JSON.stringify(resp.data?.accessToken)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(resp.data?.refreshToken)
        );

        if (resp?.data?.requiresPasswordReset) {
          localStorage.setItem(
            "requiresPasswordReset",
            JSON.stringify(resp.data?.requiresPasswordReset)
          );
          Notify.info("Please change your password");
        } else Notify.success("Logged in successfully");

        if (openLoginDialog) {
          window.location.reload();
          return;
        }

        if (
          !resp?.data?.user?.phoneNumber ||
          !resp?.data?.user?.phoneVerified
        ) {
          let _url = "/auth/login?verify=phone";
          if (resp?.data?.user?.phoneNumber) {
            _url += "&phone=" + resp?.data?.user?.phoneNumber;
          }
          window.location.replace(_url);
          return;
        }

        setTimeout(() => {
          window.location.replace(
            searchParams?.get("redirect_url")
              ? searchParams?.get("redirect_url")
              : resp?.data?.requiresPasswordReset
              ? "/dashboard/account/change-password?q=verify"
              : "/"
          );
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const register = async (obj) => {
    try {
      const resp = await axiosInstance.post("/auth/register", obj);
      if (resp.status === 201) {
        return resp;
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  const registerOrganisation = async (obj) => {
    try {
      const resp = await axiosInstance.post("/organisations", obj);
      return resp;
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        // methods
        login,
        register,
        registerOrganisation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
