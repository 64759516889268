import { AttachMoney, StarHalf } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { currencyFormatter } from "src/utils/helpers";

export default function OrderStats({ stats, whichPage }) {
  const theme = useTheme();

  return (
    <>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              flexGrow: 1,
              height: "100%",
              position: "relative",
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  sx={{
                    width: `${theme.spacing(6)}`,
                    height: `${theme.spacing(6)}`,
                    background: `${theme.colors.info.lighter}`,
                    color: `${theme.colors.info.main}`,
                  }}
                >
                  <StarHalf fontSize="large" />
                </Avatar>
              }
              title={
                <Typography variant="h4" component="div">
                  Premium Seats purchased (
                  {whichPage === "dashboard" ? "This Month" : "All Time"})
                </Typography>
              }
              subheader={
                <Typography variant="subtitle2">
                  Total number of premium seats purchased in{" "}
                  {whichPage === "dashboard"
                    ? new Date().toLocaleString("default", {
                        month: "long",
                      }) +
                      " " +
                      new Date().getFullYear()
                    : "all time"}
                </Typography>
              }
              action={
                <Box
                  sx={{
                    textAlign: "right",
                    mr: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: `${theme.colors.alpha.black[100]}`,
                    }}
                    variant="h2"
                  >
                    {stats?.seatsTotal
                      ? stats?.seatsTotal < 9
                        ? `0${stats?.seatsTotal}`
                        : stats?.seatsTotal
                      : 0}
                  </Typography>
                </Box>
              }
            />

            <Box mb={2}> </Box>
            <LinearProgress color={"info"} variant="determinate" value={100} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              background: `${theme.colors.gradients.blue4}`,
              pr: 14,
              height: "100%",
              flexGrow: 1,
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  sx={{
                    width: `${theme.spacing(6)}`,
                    height: `${theme.spacing(6)}`,
                    background: `${theme.colors.success.main}`,
                    color: `${theme.palette.getContrastText(
                      theme.colors.success.dark
                    )}`,
                  }}
                >
                  <AttachMoney />
                </Avatar>
              }
              title={
                <Typography
                  sx={{
                    color: `${theme.colors.alpha.trueWhite[70]}`,
                  }}
                  gutterBottom
                  component="div"
                  variant="subtitle2"
                >
                  <b style={{ marginRight: "10px" }}>Total Paid Amount</b>(
                  {whichPage === "dashboard" ? "This Month" : "All Time"})
                </Typography>
              }
              subheader={
                <Typography
                  sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`,
                  }}
                  variant="h2"
                >
                  {currencyFormatter(stats?.amountPaid || 0)}
                </Typography>
              }
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
