import {
  AddTwoTone,
  DriveFileRenameOutlineRounded,
  SecurityTwoTone,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormHelperText,
  Stack,
  Pagination,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import { fDateTimeSuffix } from "src/utils/date-fns";
import {
  FORM_CATEGORIES,
  PAGE_LIMIT,
  POSH_COMPLAINT_STATUS,
} from "src/utils/constants";
import { GlobalContext } from "src/contexts/GlobalContext";
import EmptyHere from "src/components/EmptyHere";

export default function ManualForms() {
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);
  const { committeeId } = useParams();
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [committee, setCommittee] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleGetSubmissions = async () => {
    try {
      setLoading(true);
      const reqParams = {
        params: {
          limit: PAGE_LIMIT,
          skip: (page - 1) * PAGE_LIMIT,
          committeeId: committeeId || FORM_CATEGORIES.POSH,
        },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
        };
      }

      const resp = await axiosInstance.get("/submissions", reqParams);
      if (resp?.status === 200) {
        setSubmissions(resp?.data || []);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const getCommittees = async () => {
    try {
      setLoading(true);
      const reqParams = {
        params: {
          type: "committee",
        },
      };

      const resp = await axiosInstance.get("/tags/type", reqParams);
      if (resp?.status === 200) {
        const _committees = resp?.data || [];
        setCommittee(
          _committees?.inputs?.find((c) => c?.name === committeeId) || {}
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    getCommittees();

    handleGetSubmissions();
  }, [page, filterObj]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box my={2.5}>
      <Stack spacing={2} mb={4}>
        <Typography variant="h1" component="h1" gutterBottom>
          {committee?.title || ""}
        </Typography>
        <Typography variant="h6" gutterBottom color={"textSecondary"}>
          {committee?.description || ""}
        </Typography>
      </Stack>

      <Box my={3}>
        <Card variant="outlined" sx={{ p: 4, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2" component="h2" gutterBottom mb={3}>
              Complaints Dashboard
            </Typography>
            {currentUser?.accountType === "admin" && (
              <Button
                startIcon={<DriveFileRenameOutlineRounded />}
                component={Link}
                to={`/dashboard/manualforms/${committeeId}`}
              >
                Edit submission form
              </Button>
            )}
          </Stack>
          <Divider />
          {currentUser?.role === "user" && submissions?.data?.length > 0 && (
            <Button
              startIcon={<AddTwoTone />}
              component={Link}
              variant="contained"
              color="primary"
              to={`/dashboard/committees/${committeeId}/submit`}
              sx={{ my: 2, borderRadius: 0.5 }}
            >
              File New Complaint
            </Button>
          )}

          {submissions?.data?.length > 0 ? (
            <TableContainer elevation={0} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date Filed</TableCell>
                    {currentUser?.accountType !== "user" && (
                      <TableCell>Full Name</TableCell>
                    )}
                    {!currentUser?.orgId && <TableCell>Company</TableCell>}
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submissions?.data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          width: "20%",
                        }}
                      >
                        {fDateTimeSuffix(item?.createdAt)}
                      </TableCell>
                      {currentUser?.accountType !== "user" && (
                        <TableCell>
                          <Typography variant="h4">
                            {item?.user?.firstName || "-"}{" "}
                            {item?.user?.lastName || ""}{" "}
                          </Typography>
                        </TableCell>
                      )}
                      {!currentUser?.orgId && (
                        <TableCell sx={{ fontWeight: "600" }}>
                          {item?.organisation?.title ||
                            item?.organisationName ||
                            "-"}
                        </TableCell>
                      )}
                      <TableCell>
                        {POSH_COMPLAINT_STATUS[item?.status]?.icon || ""}{" "}
                        {POSH_COMPLAINT_STATUS[item?.status]?.label || ""}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          {currentUser?.userId === item?.user?._id &&
                            item?.careerProtection?.required &&
                            !item?.careerProtection?.orderId && (
                              <Button
                                component={Link}
                                to={
                                  item.organisation?._id
                                    ? `/dashboard/career-protection/${
                                        item.organisation?._id
                                      }?redirectLink=${`/dashboard/committees/${committeeId}/${item._id}`}`
                                    : `/dashboard/career-protection/open?meta=${JSON.stringify(
                                        {
                                          organisationName:
                                            item?.organisationName,
                                        }
                                      )}&redirectLink=${`/dashboard/committees/${committeeId}/${item._id}`}`
                                }
                                variant="contained"
                                size="small"
                                startIcon={<SecurityTwoTone />}
                              >
                                Buy Career Protection
                              </Button>
                            )}

                          <IconButton
                            component={Link}
                            to={`/dashboard/committees/${committeeId}/${item?._id}`}
                            color="primary"
                          >
                            <Visibility fontSize="small" />
                          </IconButton>
                        </Stack>
                        {currentUser?.userId === item?.user?._id &&
                          item?.careerProtection?.required &&
                          !item?.careerProtection?.orderId && (
                            <FormHelperText>
                              Please note you need to buy career protection to
                              <br />
                              get your complaint reviewed.
                            </FormHelperText>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : currentUser?.role === "user" ? (
            <Card
              sx={{
                minHeight: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px dashed",
                borderColor: (theme) => theme.palette.primary.main,
              }}
              component={Link}
              to={`/dashboard/committees/${committeeId}/submit`}
              style={{ textDecoration: "none" }}
              elevation={0}
              variant="outlined"
            >
              <Stack spacing={3} alignItems="center">
                <Button startIcon={<AddTwoTone />} sx={{ fontSize: "1.2rem" }}>
                  File New Complaint
                </Button>
                <FormHelperText>
                  No submissions found. Click here to file a new complaint.
                </FormHelperText>
              </Stack>
            </Card>
          ) : (
            <EmptyHere
              title={"No submissions has been filed yet."}
              subtitle={" "}
            />
          )}
          {!loading && submissions?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={submissions?.totalPages || 1}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </Card>
      </Box>

      <FormHelperText sx={{ px: 1, my: 3 }}>
        Requires a valid career protection plan. Please select the company name
        and provide the details in the form. The form will be reviewed by the
        committee and the status will be updated accordingly.
        <br />
        Please read the terms and conditions before lodging a complaint.
      </FormHelperText>
    </Box>
  );
}
