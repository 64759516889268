import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Container,
  Card,
  Typography,
  CardContent,
  Avatar,
} from "@mui/material";
import { ProfileContext } from "src/contexts/ProfileContext";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import GeneralSection from "./components/GeneralSection";
import CareerSection from "./components/CareerSection";
import Header from "./components/Header";
import { fDate } from "src/utils/date-fns";
import ScoreSection from "./components/ScoreSection";
import ExperienceSection from "./components/ExperienceSection";
import EducationSection from "./components/EducationSection";
import ProjectSection from "./components/ProjectSection";
import { useSearchParams } from "react-router-dom";
import Footer from "./components/Footer";
import EmptyHere from "src/components/EmptyHere";
import SuspenseLoader from "src/components/SuspenseLoader";
import RatingSection from "./components/RatingSection";

export default function ProfileReport() {
  const [searchParam] = useSearchParams();
  const { profile, loading, handleGetUserRatings } = useContext(ProfileContext);
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    (async () => {
      if (
        searchParam.get("print") === "true" &&
        !loading &&
        profile &&
        profile?.cleanExitUserId
      ) {
        setIsWait(true);
        if (profile && profile?._id) {
          await handleGetUserRatings(profile, {
            status: "active",
          });
        }
        setTimeout(() => {
          window.print();
          setIsWait(false);
        }, 1000);
      }
    })();
  }, [profile, loading]);

  const handlePrint = () => {
    setIsWait(true);
    setTimeout(() => {
      window.print();
      setIsWait(false);
    }, 1000);
  };

  return loading ? (
    <SuspenseLoader />
  ) : !profile ? (
    <EmptyHere title="Profile Not Found" />
  ) : (
    <Container sx={{ my: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box></Box>
        <Stack spacing={1} direction="row" alignItems="center">
          <LoadingButton
            variant="contained"
            color="success"
            startIcon={<CloudDownloadOutlined />}
            onClick={handlePrint}
            loading={isWait}
          >
            Download
          </LoadingButton>
        </Stack>
      </Box>
      <Box sx={{ my: 2 }}>
        <Card elevation={0} id="profile-section-print">
          <Header />
          <CardContent>
            <Stack spacing={3}>
              <Stack direction={"row"} justifyContent="space-between">
                <Box>
                  {profile?.profileImage && (
                    <Avatar
                      sx={{
                        m: 0.5,
                        width: 100,
                        height: 100,
                        border: (theme) =>
                          `${theme.colors.alpha.white[100]} solid 2px`,
                        boxShadow: (theme) =>
                          `0 0 0 2px ${theme.colors.primary.main}`,
                      }}
                      variant="rounded"
                      src={
                        process.env.REACT_APP_DO_SPACE_URL +
                        profile?.profileImage +
                        `?${Math.random()}`
                      }
                    />
                  )}
                </Box>
                <Stack
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    <span className="link-color">EthixFirst ID:</span>{" "}
                    {profile?.cleanExitUserId}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    <span className="link-color">Created On:</span>{" "}
                    {fDate(new Date())}
                  </Typography>
                </Stack>
              </Stack>
              <GeneralSection />
              <ScoreSection />
              <div className="pagebreak"></div>
              <RatingSection />
              <CareerSection />
              <ExperienceSection />
              <EducationSection />
              <ProjectSection />
            </Stack>
          </CardContent>
          <Footer />
        </Card>
      </Box>
    </Container>
  );
}
