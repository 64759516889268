import {
  InsertEmoticonOutlined,
  MoodBadOutlined,
  SentimentDissatisfied,
  SentimentDissatisfiedOutlined,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfiedOutlined,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { MAX_SCORE } from "src/utils/constants";

export default function ScoreBar({ score }) {
  const coloRange = () => {
    const _score = (score / MAX_SCORE) * 100;
    if (_score <= 10)
      return {
        score: _score,
        bgColor: "#f74f4f",
        color: "#fff",
        emoji: (
          <SentimentVeryDissatisfiedOutlined
            sx={{
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ),
      };
    else if (_score <= 25)
      return {
        score: _score,
        bgColor: "#f8760c",
        color: "#fff",
        emoji: (
          <SentimentDissatisfiedOutlined
            sx={{
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ),
      };
    else if (_score <= 40)
      return {
        score: _score,
        bgColor: "#ff7700",
        color: "#fff",
        emoji: (
          <MoodBadOutlined
            sx={{
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ),
      };
    else if (_score <= 55)
      return {
        score: _score,
        bgColor: "#ffde21",
        color: "#555",
        emoji: (
          <SentimentDissatisfied
            sx={{
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ),
      };
    else if (_score <= 70)
      return {
        score: _score,
        bgColor: "#13fa7b",
        color: "#555",
        emoji: (
          <SentimentSatisfied
            sx={{
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ),
      };
    else if (_score <= 85)
      return {
        score: _score,
        bgColor: "#00d35f",
        color: "#444",
        emoji: (
          <SentimentSatisfiedAlt
            sx={{
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ),
      };
    else
      return {
        score: _score,
        bgColor: "#00c20d",
        color: "#222",
        emoji: (
          <InsertEmoticonOutlined
            sx={{
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ),
      };
  };

  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <div className="scorebar">
          <div className="scorebar-wrapper">
            <div className="scorebar-progressbar">
              <div className="scorebar-stylization"></div>
            </div>
          </div>
        </div>

        <Typography
          sx={{
            position: "absolute",
            top: "50%",
            left: `${(score / MAX_SCORE) * 100}%`,
            transform: "translate(-50%, -50%)",
            color: coloRange()?.color,
            backgroundColor: coloRange()?.bgColor,
            fontSize: "1.5rem",
            fontWeight: "bold",
            zIndex: 11,
            borderRadius: "50%",
            width: "2.5rem",
            height: "2.5rem",
            borderLeft: "1px solid #fff",
          }}
        >
          {(() => {
            const emoji = coloRange()?.emoji;
            return (
              emoji || (
                <InsertEmoticonOutlined
                  sx={{
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                />
              )
            );
          })()}
        </Typography>
      </Box>

      <Stack
        direction="row"
        spacing={1}
        sx={{
          m: 0.4,
        }}
        justifyContent={"space-between"}
      >
        <Typography
          color={"secondary"}
          sx={{
            top: "25%",
            left: "110%",
            borderRadius: "2px",
            my: "0.1rem",
            fontSize: "0.9rem",
          }}
        >
          {"Poor (0)"}
        </Typography>
        <Typography
          color={"secondary"}
          sx={{
            top: "25%",
            left: "110%",
            borderRadius: "2px",
            my: "0.1rem",
            fontSize: "0.9rem",
          }}
        >
          {"Excellent "}({MAX_SCORE})
        </Typography>
      </Stack>
    </Box>
  );
}
