import { CheckTwoTone, Close } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
export default function VerifyAccount() {
  const { t } = useTranslation();
  const { token } = useParams();
  const [isWait, setIsWait] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!token) {
      setData(null);
      setIsWait(false);
    } else {
      verifyToken(token);
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      setIsWait(true);
      if (!token) {
        window.location.replace("/404");
      }
      const resp = await axiosInstance.post(`/auth/verify`, {
        token,
      });
      if (resp.status === 200) {
        setData({
          status: true,
          title: "Welcome onboard!",
          subtitle: "Email verified successfully.",
        });
      } else {
        setData({
          status: false,
          title: "Account verification failed!",
          subtitle:
            "Either Link is invalid or expired. Please contact support.",
        });
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      setData({
        status: false,
        title: "Account verification failed!",
        subtitle: "Either Link is  invalid or expired.",
      });
    }
  };

  return isWait ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <DialogWrapper open={true} maxWidth="sm" fullWidth keepMounted>
      <Box
        sx={{
          px: 4,
          pb: 4,
          pt: 10,
        }}
      >
        <AvatarSuccess
          sx={{
            backgroundColor: data?.status ? "success.main" : "error.main",
            color: data?.status ? "success.contrastText" : "error.contrastText",
          }}
        >
          {data?.status ? <CheckTwoTone /> : <Close />}
        </AvatarSuccess>

        <Alert
          severity={data?.status ? "success" : "error"}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {data?.title || "Account verification failed!"}
        </Alert>

        <Typography
          align="center"
          sx={{
            py: 4,
          }}
          variant="h3"
        >
          {data?.subtitle || "Either Link is invalid or expired."}
        </Typography>

        <Button fullWidth size="large" variant="contained" href="/auth/login">
          {t("Continue to login")}
        </Button>
      </Box>
    </DialogWrapper>
  );
}
