import {
  ChevronRightTwoTone,
  CloseTwoTone,
  CreditScoreOutlined,
  DoneTwoTone,
  FingerprintOutlined,
  RemoveModeratorOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import CleanExitVerifyDialog from "./CleanExitVerifyDialog";
import VertificationDetails from "./VertificationDetails";
import CustomTooltip from "src/components/CustomTooltip/CustomTooltip";
import { GlobalContext } from "src/contexts/GlobalContext";

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      height: ${theme.spacing(4)};
      width: ${theme.spacing(4)};
`
);

export default function CleanExitVerification() {
  const theme = useTheme();
  const { handleCheckPayment, currentUser } = useContext(GlobalContext);
  const { profile, viewAsPublic } = useContext(ProfileContext);
  const [open, setOpen] = useState(null);

  const toggleDialog = (type) => {
    setOpen(type);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={"1.1rem"} fontWeight={"medium"}>
            KYC Verification
          </Typography>
        }
        avatar={
          <AvatarWrapper
            src={"/static/images/logo/kyc.jpeg"}
            variant="rounded"
          />
        }
      />
      <CardContent>
        <Stack spacing={2}>
          {[
            // TODO: Undo Aadhaar Verification

            // {
            //   id: "aadhaar",
            //   name: "Aadhaar Card",
            //   verified: profile?.cleanExitVerifications?.aadhaar,
            //   icon: <FingerprintOutlined />,
            // },
            {
              id: "pan",
              name: "PAN Card",
              verified: profile?.cleanExitVerifications?.pan,
              icon: <CreditScoreOutlined />,
            },
          ]?.map((item, index) => {
            const color = item.verified ? "success" : "error";
            return (
              <CustomTooltip
                key={index}
                placement="bottom-start"
                title={
                  item?.verified ? (
                    <VertificationDetails
                      docType={item.id}
                      cleanExitVerifications={
                        profile?.cleanExitVerifications || {}
                      }
                    />
                  ) : (
                    `${item?.name} not verified yet`
                  )
                }
              >
                <Card
                  sx={{ p: 1, position: "relative", overflow: "visible" }}
                  variant="outlined"
                  onClick={async () => {
                    if (
                      !profile?.cleanExitVerifications?.[item.id] &&
                      profile?._id === currentUser?.userId
                    ) {
                      // check has paid
                      const hasPaid = await handleCheckPayment(
                        { item: "verifications" },
                        { redirectLink: "/", item: "verifications" }
                      );
                      if (hasPaid) {
                        toggleDialog(item.id);
                      }
                    }
                  }}
                >
                  <DividerVertial
                    sx={{
                      background: theme.colors[color].main,
                    }}
                  />
                  <CardActionAreaWrapper
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: `${theme.spacing(4)}`,
                          height: `${theme.spacing(4)}`,
                          background: alpha(theme.colors[color].main, 0.2),
                          color: theme.colors[color].main,
                        }}
                      >
                        {item.icon}
                      </Avatar>
                      <Box ml={2}>
                        <Typography variant="h4">{item.name}</Typography>
                        {item?.verified ? (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "green",
                            }}
                            fontSize={"0.8rem"}
                          >
                            Verified
                          </Typography>
                        ) : currentUser?.userId === profile?._id ? (
                          <Button
                            color="primary"
                            size="small"
                            sx={{
                              px: 0,
                              py: 0.3,
                            }}
                          >
                            <small>click to verify now</small>
                          </Button>
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "orange",
                            }}
                            fontSize={"0.8rem"}
                          >
                            Not Verified
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    {item?.verified ? (
                      <VerifiedUser
                        sx={{ color: theme.colors.success.main }}
                        fontSize="small"
                      />
                    ) : (
                      <RemoveModeratorOutlined
                        sx={{ color: theme.colors.error.main }}
                        fontSize="small"
                      />
                    )}
                  </CardActionAreaWrapper>
                </Card>
              </CustomTooltip>
            );
          })}
        </Stack>
      </CardContent>
      {!viewAsPublic && (
        <CleanExitVerifyDialog open={open} handleClose={toggleDialog} />
      )}
    </Card>
  );
}

const DividerVertial = styled(Box)(
  ({ theme }) => `
    height: 60%;
    width: 5px;
    left: -4px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `

        .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: ${theme.colors.primary.main};
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: .03;
          }
        }
  `
);
