import SubmitExam from "src/content/dashboards/exams/SubmitExam";

const examRoutes = [
  {
    path: ":formId",
    element: (
        <SubmitExam formType={"exam"} />
    ),
  },
];

export default examRoutes;
