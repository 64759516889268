import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Link, useParams, useSearchParams } from "react-router-dom";
import EmptyHere from "src/components/EmptyHere";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import OrgInfo from "./components/OrgInfo";
import OrgStats from "./components/OrgStats";
import UsersList from "../users";
import Ratings from "../ratings";
import Orders from "../Orders";
import CleanExits from "../CleanExit";
import OrgCheckins from "../Checkins";
import { ArrowForwardIos, OpenInNew } from "@mui/icons-material";
import Events from "../Events";
import BulkActions from "./components/BulkActions";
import CustomerRelations from "../CustomerRelations";
import Campaigns from "../Campaigns";
import ScoreCircularBar from "../common/ScoreCircularBar";
import { MAX_SCORE } from "src/utils/constants";

function OrgProfile() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tagTypeParam = searchParams.get("type");
  let { organisationId } = useParams();
  const { currentUser } = useContext(GlobalContext);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewType, setViewType] = useState(tagTypeParam);

  const handleTabsChange = (newValue) => {
    setViewType(newValue);
    const _searchParams = searchParams;
    if (currentUser?.accountType === "admin") {
      if (newValue === "accounts") {
        _searchParams.set("page", 1);
        _searchParams.set("tab", "owner");
      } else {
        _searchParams.delete("page");
        _searchParams.delete("tab");
      }
    }
    setSearchParams(_searchParams);
  };

  useEffect(() => {
    const _orgId = organisationId || currentUser?.orgId;
    if (!_orgId) {
      Notify.failure("No organisation found");
      return;
    }

    if (
      ["admin", "triager", "misc"]?.includes(currentUser?.accountType) ||
      _orgId === currentUser?.orgId
    ) {
    } else {
      setLoading(false);
      return;
    }

    if (profile) {
      setLoading(false);
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/organisations/${_orgId}`);
        if (resp?.status === 200) {
          setProfile(resp?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    })();
  }, [organisationId, profile, currentUser]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box my={2}>
      {!profile ? (
        <Box sx={{ m: 2 }}>
          <EmptyHere title={t("No organisation found")} />
        </Box>
      ) : (
        <Stack spacing={2}>
          <Card sx={{ px: 3, py: 2 }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <Box>
                <Stack direction={{ xs: "column", md: "row" }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 120,
                      height: 120,
                    }}
                    src={
                      profile?.data?.avatar
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          (profile?.data?.avatar + "?" + Math.random())
                        : ""
                    }
                  />
                  <Box ml={2}>
                    <Typography variant="h3">{profile?.data?.title}</Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {profile?.data?.companyWebsite}
                    </Typography>
                    {(currentUser?.accountType === "admin" ||
                      profile?.data?._id === currentUser?.orgId) &&
                      profile?.data?.isLocked && (
                        <Alert
                          severity="error"
                          sx={{ mt: 1 }}
                          action={
                            <Link
                              to={`/dashboard/organisations/${profile?.data?._id}/edit`}
                            >
                              <Button size="small" color="success">
                                Activate Now
                              </Button>
                            </Link>
                          }
                        >
                          This organisation is Deactivated
                        </Alert>
                      )}
                    <Typography
                      variant="subtitle2"
                      sx={{ width: "90%", mt: 2 }}
                    >
                      {profile?.data?.description}
                    </Typography>
                    <Button
                      endIcon={<OpenInNew />}
                      component="a"
                      href={`/org/${profile?.data?._id}`}
                      target="_blank"
                      color="info"
                    >
                      View Public Profile
                    </Button>
                    {(currentUser?.accountType === "admin" ||
                      currentUser?.orgId) && (
                      <Button
                        size="small"
                        endIcon={<ArrowForwardIos />}
                        component={Link}
                        to={`/dashboard/organisations/${profile?.data?._id}/assets`}
                      >
                        Company Assets
                      </Button>
                    )}

                    {viewType && (
                      <Button
                        onClick={() => handleTabsChange(null)}
                        size="small"
                        endIcon={<ArrowForwardIos />}
                      >
                        View All details
                      </Button>
                    )}
                  </Box>
                </Stack>
                {(currentUser?.accountType === "admin" ||
                  currentUser?.orgId === profile?.data?._id) && (
                  <Stack direction={"row"} mt={2}>
                    <BulkActions
                      organisationId={profile?.data?._id}
                      profile={profile}
                      whichPage="organisation"
                    />
                  </Stack>
                )}
              </Box>
              <Stack direction={"column"}>
                <Box
                  sx={{
                    position: "relative",
                    width: "250px",
                  }}
                >
                  <ScoreCircularBar
                    userProfile={{
                      score: profile?.data?.companyScore,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography component={"span"}>
                    <Typography variant="h3" component={"span"}>
                      <b>{profile?.data?.companyScore || MAX_SCORE}</b>
                    </Typography>
                    {!(
                      profile?.data?.companyScore === MAX_SCORE ||
                      profile?.data?.companyScore === 0
                    ) && <sub>/{MAX_SCORE}</sub>}
                  </Typography>
                  <Typography>
                    <small>Company Ethics Score</small>
                  </Typography>
                </Box>{" "}
              </Stack>
            </Stack>
          </Card>
          <OrgStats
            profile={profile}
            handleTabsChange={handleTabsChange}
            viewType={viewType}
          />
          {!viewType && <OrgInfo profile={profile} />}

          <Box>
            {viewType === "checkIn" && profile?.data?.title && (
              <OrgCheckins
                title={profile?.data?.title}
                organisationId={profile?.data?._id}
              />
            )}
            {viewType === "accounts" && profile?.data?.title && (
              <UsersList
                title={profile?.data?.title}
                organisationId={profile?.data?._id}
                whichPage="orgprofile"
              />
            )}
            {viewType === "ratings" && profile?.data?.title && (
              <Ratings organisationId={profile?.data?._id} />
            )}
            {viewType === "orders" && profile?.data?.title && (
              <Orders organisationId={profile?.data?._id} />
            )}
            {viewType === "exit-interviews" && profile?.data?.title && (
              <CleanExits organisationId={profile?.data?._id} />
            )}
            {viewType === "events" && profile?.data?.title && (
              <Events
                organisationId={profile?.data?._id}
                whichPage={"organisation"}
              />
            )}
            {viewType === "customerRelations" && profile?.data?.title && (
              <CustomerRelations
                organisationId={profile?.data?._id}
                whichPage={"organisation"}
              />
            )}
            {viewType === "campaigns" && profile?.data?.title && (
              <Campaigns
                organisationId={profile?.data?._id}
                whichPage={"organisation"}
              />
            )}
          </Box>
        </Stack>
      )}
    </Box>
  );
}

export default OrgProfile;
