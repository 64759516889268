import { Notify } from "notiflix";
import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";

export const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const { formId } = useParams();
  const [loading, setLoading] = useState("all");
  const [form, setForm] = useState(null);

  const [isAddOpen, setAddOpen] = useState(false)
  const [selectedFormGroup, setSelectedFormGroup] = useState(null);
  const [selectedQuestionGroup, setSelectedQuestionGroup] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentSelectedItemType, setCurrentSelectedItemType] = useState(null);

  const handleGetForm = async (formId) => {
    try {
      const resp = await axiosInstance.get(`/forms/${formId}`);
      if (resp?.status === 200) {
        setForm(resp?.data);
        setLoading(null);

        // return resp?.data;
      }
    } catch (error) {
      setLoading(null);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );

      return null;
    }
  };

  useEffect(() => {
    if (formId) {
      (async () => {
        setLoading("all");
        await handleGetForm(formId);

        // if (data && data?.forms
      })();
    } else {
      setLoading(null);
      Notify.failure("Form not found");
    }
  }, [formId]);

  return (
    <FormContext.Provider
      value={{
        // state
        loading,
        form,
        selectedFormGroup,
        selectedQuestionGroup,
        selectedQuestion,
        selectedOption,
        currentSelectedItemType,

        // methods
        setForm,
        setSelectedFormGroup,
        setSelectedQuestionGroup,
        setSelectedQuestion,
        setSelectedOption,
        setCurrentSelectedItemType,

        // actions
        handleGetForm,
        isAddOpen, setAddOpen
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
