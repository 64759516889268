import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { SecurityTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CppImage from "../../../assets/cpp.jpeg";
import InviteRequests from "../Requests";

export default function CareerProtection() {
  return (
    <Box my={2}>
      <Stack direction={"row"} spacing={4}>
        <Box mt={1}>
          <img
            alt=""
            width={"100%"}
            src={CppImage}
            style={{ borderRadius: "20px" }}
          />
        </Box>
        <Stack justifyContent={"space-evenly"} height={"100%"} spacing={2}>
          <Stack spacing={2}>
            <Typography variant="h3">Career Protection Plan (CPP)</Typography>
            <Typography
              variant="body2"
              fontSize={"0.9rem"}
              color={"text.secondary"}
            >
              The Career Protection Plan (CPP) from Clean Exit is linked to your
              current job role in a company and shields you from various
              unethical practices and wrong rating. Do not join a company for an
              internship or a job without the Career Protection Plan!
            </Typography>

            <Typography variant="subtitle1" fontSize={"0.9rem"} mt={1}>
              Rate your managers, get protection from negative ratings and
              non-issuance of experience letter by companies.
            </Typography>
          </Stack>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 1,
                background: (theme) => theme.palette.primary.main,
              }}
              startIcon={<SecurityTwoTone />}
              fullWidth
              component={Link}
              to={`/dashboard/career-protection/open`}
            >
              Get Career Protection
            </Button>

            <Typography variant="subtitle1" fontSize={"0.7rem"} mt={1}>
              Buy the Career Protection Plan for your internship / job today.
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Box>
        <InviteRequests whichPage="career-protection" />
      </Box>
    </Box>
  );
}
