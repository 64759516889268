import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  capitalize,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import { Notify } from "notiflix";
import { GlobalContext } from "src/contexts/GlobalContext";
import {
  ArrowForwardIos,
  EditTwoTone,
  GroupAddTwoTone,
  PersonAddAltTwoTone,
} from "@mui/icons-material";
import Label from "src/components/Label";
import { fDate, fTime } from "src/utils/date-fns";
import CustomTooltip from "src/components/CustomTooltip/CustomTooltip";
import VertificationDetails from "../verification/VertificationDetails";
import BulkActions from "../organisations/components/BulkActions";

export default function UsersList({ title, organisationId, whichPage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const tabParam = searchParams.get("tab");
  const { currentUser } = useContext(GlobalContext);
  const [filterObj, setFilterObj] = useState({
    accountType: tabParam ? tabParam : "user",
  });
  const [loading, setLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const [tabValue, setTabValue] = useState(tabParam || "user");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    (async () => {
      if (
        organisationId ||
        currentUser?.accountType !== "admin" ||
        ["triager", "admin"].includes(tabParam)
      )
        return;
      const resp = await axiosInstance.get(`/organisations/all`);
      if (resp?.status === 200) {
        setOrganisations(resp?.data);
      }
    })();
  }, [tabParam]);

  useEffect(() => {
    if (currentUser?.orgId || tabValue === "owner" || title) {
      setFilterObj({
        ...filterObj,
        isActive: true,
      });
    } else {
      setFilterObj({
        ...filterObj,
        isActive: null,
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        let resp = {};

        // if looking for org user
        if (currentUser?.orgId || tabValue === "owner" || title) {
          if (title) {
            reqParams.params.title = title;
          }
          delete reqParams.params.accountType;
          resp = await axiosInstance.get("/organisations/users", reqParams);
        } else {
          // for all users irrespective of org

          resp = await axiosInstance.get("/users", reqParams);
        }
        if (resp?.status === 200) {
          setUsers(resp?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page, filterObj, tabValue, searchParams]);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (currentUser?.accountType === "admin" && !title) {
      setTabs([
        {
          label: "Users",
          value: "user",
        },
        {
          label: "Organisation's",
          value: "owner",
        },
        {
          label: "Triagers",
          value: "triager",
        },
        {
          label: "Administators",
          value: "admin",
        },
        {
          label: "Analysts",
          value: "misc",
        },
      ]);
    }
  }, [currentUser, title]);

  const handleTabsChange = (event, newValue) => {
    delete filterObj.title;
    setTabValue(newValue);
    setFilterObj({
      ...filterObj,
      accountType: newValue,
    });
    setPage(1);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: 1,
      tab: newValue,
    });
  };

  return (
    <Box my={whichPage === "dashboard" ? 2 : 0}>
      <Card>
        {whichPage === "dashboard" && (
          <CardHeader
            title={"Accounts"}
            action={
              <>
                {currentUser?.accountType === "admin" && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<PersonAddAltTwoTone />}
                      sx={{
                        minWidth: 100,
                      }}
                      component={Link}
                      to={`/dashboard/invitations/new`}
                    >
                      Invite User
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      startIcon={<PersonAddAltTwoTone />}
                      sx={{
                        minWidth: 100,
                      }}
                      component={Link}
                      to={`/dashboard/users/new?role=user`}
                    >
                      Add User
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      startIcon={<PersonAddAltTwoTone />}
                      sx={{
                        minWidth: 100,
                      }}
                      component={Link}
                      to={`/dashboard/users/bulk`}
                    >
                      Add Bulk Users
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      startIcon={<GroupAddTwoTone />}
                      sx={{
                        minWidth: 100,
                      }}
                      component={Link}
                      to={`/dashboard/users/new?role=triager`}
                    >
                      Add Triager
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      startIcon={<PersonAddAltTwoTone />}
                      sx={{
                        minWidth: 100,
                      }}
                      component={Link}
                      to={`/dashboard/users/new?role=misc`}
                    >
                      Add Analyst
                    </Button>
                  </Stack>
                )}
                {currentUser?.orgId && (
                  <BulkActions
                    organisationId={currentUser?.orgId}
                    profile={{
                      owner: true,
                    }}
                  />
                )}
              </>
            }
          />
        )}
      </Card>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 3 }}
      >
        <Box>
          <Box fullWidth display={"flex"}>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here..."
                inputProps={{ "aria-label": "search here" }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (e.target.value.length) {
                      setFilterObj({ ...filterObj, search: searchString });
                    } else {
                      delete filterObj.search;
                      setFilterObj({ ...filterObj, search: null });
                    }
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      page: 1,
                    });
                  }
                }}
                onChange={(e) => {
                  if (e.target.value?.length > 0) {
                    setSearchString(e.target.value);
                  } else {
                    if (filterObj?.search) {
                      setFilterObj({
                        ...filterObj,
                        search: null,
                      });
                    }
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{
                  m: 0.2,
                  bgcolor: "primary.main",
                  color: "white",
                }}
                onClick={() => {
                  setFilterObj({
                    ...filterObj,
                    search: searchString,
                  });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>

          {(currentUser?.orgId || tabValue === "owner" || title) && (
            <Button
              onClick={() => {
                setFilterObj({
                  ...filterObj,
                  isActive: !filterObj?.isActive,
                });
              }}
              size="small"
              endIcon={<ArrowForwardIos fontSize="1rem" />}
              color={filterObj?.isActive ? "secondary" : "primary"}
            >
              {!filterObj?.isActive
                ? "Show Current (Active) Employees"
                : "Show Previous (In-Active) Employees"}
            </Button>
          )}
        </Box>

        <Stack direction="row" alignItems="center" spacing={2}>
          {!(currentUser?.orgId || tabValue === "owner" || title) && (
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={[
                { title: "Active ", value: false },
                { title: "Deactivated ", value: true },
              ]}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => (
                <TextField {...params} label="Account Status" />
              )}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  isLocked: v?.value,
                });
                setPage(1);

                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ mb: 2 }}
      >
        {tabs?.length > 0 && (
          <TabsWrapper
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={tabValue || "admin"}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                disabled={title}
              />
            ))}
          </TabsWrapper>
        )}
        {!title &&
          currentUser?.accountType === "admin" &&
          tabValue === "owner" && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Autocomplete
                disablePortal
                size="small"
                id="org-box"
                options={organisations || []}
                getOptionLabel={(option) => option?.title}
                sx={{ width: 200, ml: 2 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Organisation" />
                )}
                onChange={(e, v) => {
                  setFilterObj({
                    ...filterObj,
                    title: v?.title,
                  });
                  setPage(1);

                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              />
              <Autocomplete
                disablePortal
                size="small"
                id="org-box"
                options={[
                  {
                    title: "Owners",
                    value: "owner",
                  },
                  {
                    title: "Moderators",
                    value: "moderator",
                  },
                  {
                    title: "Employees",
                    value: "user",
                  },
                ]}
                getOptionLabel={(option) => option?.title}
                sx={{ width: 200, ml: 2 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Role" />
                )}
                onChange={(e, v) => {
                  setFilterObj({
                    ...filterObj,
                    role: v?.value,
                  });
                  setPage(1);

                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              />
            </Stack>
          )}
      </Stack>
      <Box sx={{ mb: 4 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>EthixFirst ID</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Contact</TableCell>
                {!currentUser?.orgId && tabParam === "owner" && (
                  <TableCell>Organisation</TableCell>
                )}
                <TableCell>ID Verification</TableCell>
                <TableCell>Certification</TableCell>
                {["admin", "misc"]?.includes(currentUser?.accountType) && (
                  <TableCell>Platforms</TableCell>
                )}
                <TableCell align="right">Created At</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <LinearProgress sx={{ m: 3 }} />
              ) : (
                <>
                  {users?.data?.map((row) => (
                    <TableRow
                      sx={{
                        color: row?.isLocked && "red",
                        textDecoration: row?.isLocked && "line-through",
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      key={row?._id}
                    >
                      <TableCell>{row?.cleanExitUserId || "-"} </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={700}>
                          {row?.firstName + " " + row?.lastName || ""}
                        </Typography>

                        <small>
                          {currentUser?.orgId && row?.role !== "user" && (
                            <Label color="info">{row?.role}</Label>
                          )}
                        </small>
                      </TableCell>
                      <TableCell>
                        {row?.email}
                        <br />
                        <small>
                          <a
                            href={`tel:${row?.phoneNumber}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            {row?.phoneNumber}
                          </a>
                        </small>
                      </TableCell>
                      {!currentUser?.orgId && tabParam === "owner" && (
                        <TableCell>
                          {row?.title || "N/A"}
                          <br />
                          <Label
                            color={
                              row?.role === "owner"
                                ? "warning"
                                : row?.role === "moderator"
                                ? "primary"
                                : "info"
                            }
                          >
                            {row?.role === "user" ? "employee" : row?.role}
                          </Label>
                        </TableCell>
                      )}
                      <TableCell>
                        <CustomTooltip
                          title={
                            <VertificationDetails
                              docType={"pan"}
                              cleanExitVerifications={
                                row?.cleanExitVerifications || {}
                              }
                            />
                          }
                        >
                          <Box>
                            <Label
                              color={
                                // TODO: Undo Aadhaar Verification

                                // row?.cleanExitVerifications?.aadhaar &&
                                row?.cleanExitVerifications?.pan
                                  ? "success"
                                  : // : row?.cleanExitVerifications?.aadhaar ||
                                    //   row?.cleanExitVerifications?.pan
                                    // ? "warning"
                                    "error"
                              }
                            >
                              {
                                // row?.cleanExitVerifications?.aadhaar &&
                                row?.cleanExitVerifications?.pan
                                  ? "Verified"
                                  : // : row?.cleanExitVerifications?.aadhaar ||
                                    //   row?.cleanExitVerifications?.pan
                                    // ? "Partially Verified"
                                    "Not Verified"
                              }
                            </Label>
                          </Box>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            row?.crediblCertification?.certified
                              ? "CPEW Certified"
                              : "CPEW - not certified"
                          }
                        >
                          <Label
                            color={
                              !row?.crediblCertification?.certified
                                ? "error"
                                : "success"
                            }
                          >
                            <b>
                              {!row?.crediblCertification?.certified
                                ? "Not Certified"
                                : "Certified"}
                            </b>
                          </Label>
                        </Tooltip>
                      </TableCell>
                      {["admin", "misc"]?.includes(
                        currentUser?.accountType
                      ) && (
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            {row?.platforms?.map((platform, index) => (
                              <Tooltip
                                key={index}
                                title={`${capitalize(platform?.name || "")}: ${
                                  platform?.isLocked ? "Deactivated" : "Active"
                                }`}
                              >
                                <img
                                  src={`/static/images/logo/${platform?.name}.png`}
                                  alt={platform?.name}
                                  width={35}
                                  height={35}
                                  style={{
                                    borderRadius: "10px",
                                    border: `1px solid #0008`,
                                    backgroundColor:
                                      platform?.isLocked && "#f00",
                                    opacity: platform?.isLocked ? 0.5 : 1,
                                    padding: 2,
                                  }}
                                />
                              </Tooltip>
                            ))}
                          </Stack>
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <Typography>{fDate(row?.createdAt)}</Typography>
                        <small>{fTime(row?.createdAt)} </small>
                      </TableCell>
                      <TableCell align="right">
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent={"flex-end"}
                        >
                          {row?.isLocked && (
                            <Label color={"error"}>Deactivated</Label>
                          )}
                          {(["admin", "triager"]?.includes(
                            currentUser?.accountType
                          ) ||
                            (!(
                              currentUser?.role === "moderator" &&
                              row?.role === "owner"
                            ) &&
                              ["owner", "moderator"]?.includes(
                                currentUser?.role
                              ) &&
                              row?.isActive)) && (
                            <Link
                              to={`/dashboard/users/${row?._id}/edit?role=${
                                row?.role || row?.accountType
                              }${
                                currentUser?.accountType === "admin" &&
                                row?.organisation
                                  ? `&organisation=${row?.organisation}`
                                  : ""
                              }`}
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <IconButton
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{ mr: 1 }}
                              >
                                <EditTwoTone />
                              </IconButton>
                            </Link>
                          )}
                          {["user", "misc"]?.includes(row?.accountType) && (
                            <Link
                              to={`/dashboard/users/${row?._id}`}
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <IconButton
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{ mr: 1 }}
                              >
                                <ArrowForwardIos />
                              </IconButton>
                            </Link>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {users?.data?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No users found
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>

          {!loading && users?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={users?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);
