import React, { useContext } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EmptyHere from "src/components/EmptyHere";
import { ProfileContext } from "src/contexts/ProfileContext";
import { WorkOutline } from "@mui/icons-material";

export default function EducationSection() {
  const { portfolio } = useContext(ProfileContext);

  return (
    <Card
      elevation={0}
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: (theme) => theme.palette.info.dark,
          color: "white",
          p: 1,
        }}
        title={
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <WorkOutline />
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              Education Information
            </Typography>
          </Stack>
        }
      />
      <CardContent>
        <Typography color={"warning.main"} fontStyle={"italic"}>
          This section contains the education information of the user.
        </Typography>
      </CardContent>
      {portfolio?.length ? (
        <Table aria-label="simple table" sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ whiteSpace: "nowrap", width: "auto", color: "#289BCC" }}
              >
                Institute
              </TableCell>

              <TableCell
                sx={{ whiteSpace: "nowrap", width: "auto", color: "#289BCC" }}
              >
                Studied(ing)
              </TableCell>

              <TableCell
                sx={{ whiteSpace: "nowrap", width: "auto", color: "#289BCC" }}
              >
                Timeline
              </TableCell>

              <TableCell
                sx={{ whiteSpace: "nowrap", width: "auto", color: "#289BCC" }}
              >
                Grade
              </TableCell>
              <TableCell align="right" sx={{ color: "#289BCC" }}>
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          {portfolio
            ?.filter((item) => item?.category === "education")
            ?.map((item) => (
              <TableBody key={item?._id}>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap", width: "auto" }}
                  >
                    <b>{item?.title}</b>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ whiteSpace: "nowrap", width: "auto" }}
                  >
                    {item?.degree} in {item?.field_of_study}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", width: "auto" }}>
                    {item?.start_month} {item?.start_year} -{" "}
                    {item?.end_month || "Present"} {item?.end_year}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", width: "auto" }}>
                    {item?.grade || "-"}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "secondary.light",
                    }}
                  >
                    {item?.description || "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
      ) : (
        <EmptyHere py={1} title={"No education added yet"} />
      )}
    </Card>
  );
}
