import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Check, CheckCircleOutline } from "@mui/icons-material";
import { Grid, alpha } from "@mui/material";
import { currencyFormatter } from "src/utils/helpers";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { Notify } from "notiflix";
import { MAX_AMOUNT_LIMIT } from "src/utils/constants";

export default function PricingCard({ item, hasPlan, index, seats }) {
  const { title, subtitle, features, amount, duration } = item;
  const { currentUser, handlePurchase } = useContext(GlobalContext);
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser?.role === "user") {
      Notify.failure("You are not allowed to purchase a plan");
      setTimeout(() => {
        window.location.replace("/");
      }, 800);
    }
  }, []);

  const handleProceed = async () => {
    try {
      if (amount * (seats > 0 ? seats : 1) > MAX_AMOUNT_LIMIT) {
        Notify.failure(
          "Maximum amount limit is 5 Lakhs. Please contact support for higher amount."
        );
        return;
      }

      setIsWait(true);
      await handlePurchase(
        { priceId: item?._id || null, type: "plan", quantity: seats },
        { redirectLink: "/" }
      );
      setIsWait(false);
    } catch (error) {}
  };

  return (
    <Grid item md={6} sm={12}>
      <Stack
        spacing={5}
        sx={{
          p: 5,
          borderRadius: 2,
          boxShadow: (theme) =>
            hasPlan?.order && hasPlan?.order?.priceId?._id === item?._id
              ? `-5px 5px 5px 5px  ${alpha(theme.palette.success.main, 0.16)}`
              : `-40px 40px 80px 0px ${alpha(
                  theme.palette.common.black,
                  0.16
                )}`,
          border: (theme) =>
            hasPlan?.order && hasPlan?.order?.priceId?._id === item?._id
              ? `1px solid ${theme.palette.success.main}`
              : `0px`,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1}>
            <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
              {title}
            </Typography>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </Stack>
          <Stack alignItems={"flex-end"}>
            <Typography variant="h3" color={"text.secondary"}>
              {currencyFormatter(amount || 0)}
            </Typography>

            <Typography variant="subtitle1">
              per user{" "}
              {duration === "lifetime"
                ? ""
                : duration === "monthly"
                ? "/month"
                : "/year"}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row">
          <Typography
            variant="h1"
            sx={{
              fontSize: "2rem",
            }}
          >
            {currencyFormatter(amount * (seats > 0 ? seats : 1) || 0)}
          </Typography>
        </Stack>

        <Stack spacing={2}>
          {item?.services?.includes("forms") && (
            // item?.items?.length > 0 &&
            <Stack spacing={1} direction="row" alignItems="center">
              <CheckCircleOutline sx={{ mr: 1 }} color="success" />
              Get{" "}
              <b style={{ marginRight: 8 }}>
                {/* {item?.items?.length || 0} */}
                Premium Forms{" "}
              </b>{" "}
              to Rate.
              {/* <FormsList forms={item?.items} /> */}
            </Stack>
          )}
          {item?.services?.includes("forms") && (
            <Stack spacing={1} direction="row" alignItems="center">
              <CheckCircleOutline sx={{ mr: 1 }} color="success" />
              Submit ratings for{" "}
              <b style={{ marginRight: 2 }}> {seats || 0} premium users</b>
            </Stack>
          )}
          {/* {item?.services?.includes("users") && item?.quota?.users > 0 && (
            <Stack spacing={1} direction="row" alignItems="center">
              <CheckCircleOutline sx={{ mr: 1 }} color="success" />
              View{" "}
              <b style={{ marginRight: 8 }}>
                {item?.quota?.users || 0} user profiles.
              </b>{" "}
            </Stack>
          )} */}
          {features?.length > 0 && (
            <>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Box component="span" sx={{ typography: "overline" }}>
                Features
              </Box>

              {features?.map((feat) => (
                <Stack
                  key={feat}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{
                    typography: "body2",
                  }}
                >
                  <Check width={16} sx={{ mr: 1 }} />
                  {feat}
                </Stack>
              ))}
            </>
          )}
        </Stack>
        {!currentUser ? (
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              color: "text.secondary",
              fontStyle: "italic",
              textAlign: "center",
            }}
            component={Link}
            to={"/auth/login"}
          >
            Please login to purchase a plan.
          </Typography>
        ) : (
          currentUser?.orgId && (
            <>
              {hasPlan?.order && hasPlan?.order?.priceId?._id === item?._id ? (
                <LoadingButton
                  fullWidth
                  size="large"
                  variant={"contained"}
                  color={"success"}
                  loading={isWait}
                  startIcon={<Check />}
                >
                  Current Plan
                </LoadingButton>
              ) : (
                <LoadingButton
                  fullWidth
                  size="large"
                  variant={index % 2 !== 0 ? "contained" : "outlined"}
                  color={"success"}
                  onClick={handleProceed}
                  disabled={hasPlan?.isPaid}
                  loading={isWait}
                >
                  {"Proceed Now"}
                </LoadingButton>
              )}
            </>
          )
        )}
      </Stack>
    </Grid>
  );
}
