import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  ArticleOutlined,
  AttachMoney,
  BusinessCenterRounded,
  CampaignOutlined,
  CardMembershipTwoTone,
  DvrOutlined,
  EventTwoTone,
  ExitToApp,
  ExploreTwoTone,
  FolderTwoTone,
  GroupAddTwoTone,
  GroupsOutlined,
  GroupTwoTone,
  HowToRegOutlined,
  Logout,
  MyLocation,
  Payments,
  PaymentsTwoTone,
  PeopleAlt,
  PersonAddAltTwoTone,
  PersonSearch,
  ReceiptLong,
  SchoolTwoTone,
  SellTwoTone,
  Settings,
  Star,
  TextFieldsTwoTone,
} from "@mui/icons-material";

export const menuItems = {
  admin: [
    {
      heading: "Dashboard",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
        {
          name: "Ratings",
          icon: Star,
          link: "/dashboard/ratings",
        },
        {
          name: "Forms",
          icon: Star,
          link: "/dashboard/ratings",
          items: [
            {
              name: "All Forms",
              icon: AssignmentIndTwoToneIcon,
              link: "/dashboard/forms",
            },
            {
              name: "Exams",
              icon: SchoolTwoTone,
              link: "/dashboard/exams",
            },
          ],
        },
        {
          name: "Accounts",
          icon: PeopleAltIcon,
          link: "/dashboard/users",
          items: [
            {
              name: "Organisations",
              icon: CorporateFareIcon,
              link: "/dashboard/organisations",
            },
            {
              name: "Users",
              icon: PeopleAlt,
              link: "/dashboard/users",
            },
            {
              name: "Search People",
              icon: PersonSearch,
              link: "/dashboard/profile/search",
            },
            // {
            //   name: "Groups",
            //   icon: PeopleAlt,
            //   link: "/dashboard/groups",
            // },
          ],
        },

        {
          name: "Committee",
          icon: SchoolTwoTone,
          link: "/dashboard/committees",
          pro: true,
        },
        {
          name: "Payment",
          icon: Payments,
          items: [
            {
              name: "Orders",
              icon: Payments,
              link: "/dashboard/orders",
            },
            {
              name: "Pricing",
              icon: AttachMoney,
              link: "/dashboard/pricing",
            },
          ],
        },
        {
          name: "Services",
          icon: DvrOutlined,
          items: [
            {
              name: "Events",
              icon: EventTwoTone,
              link: "/dashboard/events",
            },
            {
              name: "User Relations",
              icon: GroupsOutlined,
              link: "/dashboard/customer-relations",
            },
            {
              name: "Checkins",
              icon: HowToRegOutlined,
              link: "/dashboard/checkins",
            },
            {
              name: "Bulk Emails",
              icon: CampaignOutlined,
              link: "/dashboard/campaigns",
            },
          ],
        },
        {
          name: "Exit Interviews",
          icon: ExitToApp,
          link: "/dashboard/cleanexits",
        },
        {
          name: "Management",
          icon: PeopleAltIcon,
          link: "/dashboard/management",
          items: [
            {
              name: "Documents",
              icon: FolderTwoTone,
              link: "/dashboard/documents",
            },
            {
              name: "Committees",
              icon: GroupTwoTone,
              link: "/dashboard/committees",
            },
          ],
        },
      ],
    },
    {
      heading: "Additionals",
      items: [
        {
          name: "Settings",
          icon: Settings,
          items: [
            {
              name: "Tag Manager",
              icon: SellTwoTone,
              link: "/dashboard/management?tab=tags",
            },
            {
              name: "Input Fields",
              icon: TextFieldsTwoTone,
              link: "/dashboard/management?tab=fields",
            },
            {
              name: "Zones by City",
              icon: MyLocation,
              link: "/dashboard/management?tab=zones",
            },
            {
              name: "Certificate Groups",
              icon: CardMembershipTwoTone,
              link: "/dashboard/management?tab=certificates",
            },
            {
              name: "Manage Accounts",
              icon: GroupTwoTone,
              link: "/dashboard/accounts",
            },
            {
              name: "Change Password",
              icon: VpnKeyTwoToneIcon,
              link: "/dashboard/account/change-password",
            },
          ],
        },
        {
          name: "Logout",
          icon: Logout,
          link: "/auth/logout",
        },
      ],
    },
  ],
  triager: [
    {
      heading: "",
      items: [
        {
          name: "Forms",
          icon: AssignmentIndTwoToneIcon,
          link: "/dashboard/forms",
        },
      ],
    },
    {
      heading: "Additionals",
      items: [
        {
          name: "Settings",
          icon: Settings,
          items: [
            {
              name: "General",
              icon: Settings,
              link: "/dashboard/account/settings",
            },
            {
              name: "Change Password",
              icon: VpnKeyTwoToneIcon,
              link: "/dashboard/account/change-password",
            },
          ],
        },
        {
          name: "Logout",
          icon: Logout,
          link: "/auth/logout",
        },
      ],
    },
  ],
  owner: [
    {
      heading: "",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
        {
          name: "Ratings",
          icon: Star,
          link: "/dashboard/ratings",
        },
        {
          name: "Forms",
          icon: Star,
          link: "/dashboard/ratings",
          items: [
            {
              name: "All Forms",
              icon: AssignmentIndTwoToneIcon,
              link: "/dashboard/forms",
            },
            {
              name: "Exams",
              icon: SchoolTwoTone,
              link: "/dashboard/exams",
            },
          ],
        },
        {
          name: "Committee",
          icon: SchoolTwoTone,
          link: "/dashboard/committees",
          pro: true,
        },
        {
          name: "Accounts",
          icon: PeopleAltIcon,
          link: "/dashboard/users",
          items: [
            {
              name: "Users",
              icon: PeopleAlt,
              link: "/dashboard/users",
            },
            {
              name: "Search People",
              icon: PersonSearch,
              link: "/dashboard/profile/search",
            },
            {
              name: "Requests",
              icon: GroupAddTwoTone,
              link: "/dashboard/requests",
            },
            {
              name: "Invitations",
              icon: PersonAddAltTwoTone,
              link: "/dashboard/invitations",
            },
            {
              name: "Memberships",
              icon: PeopleAlt,
              link: "/dashboard/memberships",
            },
          ],
        },
        {
          name: "Exit Interviews",
          icon: ExitToApp,
          link: "/dashboard/cleanexits",
        },
        {
          name: "Orders",
          icon: Payments,
          link: "/dashboard/orders",
        },
        {
          name: "Assets",
          icon: ArticleOutlined,
          link: "/dashboard/assets",
        },
        {
          name: "Management",
          icon: PeopleAltIcon,
          link: "/dashboard/management",
          items: [
            {
              name: "Documents",
              icon: FolderTwoTone,
              link: "/dashboard/documents",
            },
            {
              name: "Company Profile",
              icon: FolderTwoTone,
              link: "/dashboard/organisations/profile",
            },
          ],
        },
      ],
    },
    {
      heading: "Additionals",
      items: [
        {
          name: "Settings",
          icon: Settings,
          items: [
            {
              name: "Input Fields",
              icon: TextFieldsTwoTone,
              link: "/dashboard/management?tab=input-fields",
            },
            {
              name: "My Account",
              icon: Settings,
              link: "/dashboard/account/settings",
            },
            {
              name: "Change Password",
              icon: VpnKeyTwoToneIcon,
              link: "/dashboard/account/change-password",
            },
          ],
        },
        {
          name: "Logout",
          icon: Logout,
          link: "/auth/logout",
        },
      ],
    },
  ],
  moderator: [
    {
      heading: "",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
        {
          name: "Ratings",
          icon: Star,
          link: "/dashboard/ratings",
        },
        {
          name: "Forms",
          icon: Star,
          link: "/dashboard/ratings",
          items: [
            {
              name: "All Forms",
              icon: AssignmentIndTwoToneIcon,
              link: "/dashboard/forms",
            },
            {
              name: "Exams",
              icon: SchoolTwoTone,
              link: "/dashboard/exams",
            },
          ],
        },
        {
          name: "Committee",
          icon: SchoolTwoTone,
          link: "/dashboard/committees",
          pro: true,
        },
        {
          name: "Accounts",
          icon: PeopleAltIcon,
          link: "/dashboard/users",
          items: [
            {
              name: "Users",
              icon: PeopleAlt,
              link: "/dashboard/users",
            },
            {
              name: "Search People",
              icon: PersonSearch,
              link: "/dashboard/profile/search",
            },
            {
              name: "Requests",
              icon: GroupAddTwoTone,
              link: "/dashboard/requests",
            },
            {
              name: "Invitations",
              icon: PersonAddAltTwoTone,
              link: "/dashboard/invitations",
            },
            {
              name: "Memberships",
              icon: PeopleAlt,
              link: "/dashboard/memberships",
            },
          ],
        },
        {
          name: "Exit Interviews",
          icon: ExitToApp,
          link: "/dashboard/cleanexits",
        },
        {
          name: "Orders",
          icon: Payments,
          link: "/dashboard/orders",
        },
        {
          name: "Assets",
          icon: ArticleOutlined,
          link: "/dashboard/assets",
        },
        {
          name: "Management",
          icon: PeopleAltIcon,
          link: "/dashboard/management",
          items: [
            {
              name: "Documents",
              icon: FolderTwoTone,
              link: "/dashboard/documents",
            },
            {
              name: "Company Profile",
              icon: FolderTwoTone,
              link: "/dashboard/organisations/profile",
            },
          ],
        },
      ],
    },
    {
      heading: "Additionals",
      items: [
        {
          name: "Settings",
          icon: Settings,
          items: [
            {
              name: "Input Fields",
              icon: TextFieldsTwoTone,
              link: "/dashboard/management?tab=input-fields",
            },
            {
              name: "My Account",
              icon: Settings,
              link: "/dashboard/account/settings",
            },
            {
              name: "Change Password",
              icon: VpnKeyTwoToneIcon,
              link: "/dashboard/account/change-password",
            },
          ],
        },
        {
          name: "Logout",
          icon: Logout,
          link: "/auth/logout",
        },
      ],
    },
  ],
  user: [
    {
      heading: "",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
        {
          name: "Events",
          icon: ExploreTwoTone,
          link: "/dashboard/explore",
        },
        {
          name: "Ratings",
          icon: ReceiptLong,
          link: "/dashboard/forms",
        },
        {
          name: "Exams",
          icon: SchoolTwoTone,
          link: "/dashboard/exams",
        },
        {
          name: "Honest CV",
          icon: SchoolTwoTone,
          link: "/dashboard/honest-cv",
        },
        {
          name: "CPEW",
          icon: SchoolTwoTone,
          link: "/dashboard/cpew",
          pro: true,
        },
        {
          name: "Digital Internship",
          icon: SchoolTwoTone,
          link: "/dashboard/digital-internship",
          pro: true,
        },
        {
          name: "Career Protection",
          icon: SchoolTwoTone,
          link: "/dashboard/career-protection",
          pro: true,
        },
        {
          name: "POSH",
          icon: SchoolTwoTone,
          link: "/dashboard/committees/posh",
          pro: true,
        },
        {
          name: "Join Organisation",
          icon: BusinessCenterRounded,
          link: "/dashboard/requests/new",
        },
        {
          name: "Invitations",
          icon: BusinessCenterRounded,
          link: "/dashboard/invitations",
        },
        {
          name: "Assets",
          icon: ArticleOutlined,
          link: "/dashboard/assets",
        },
        {
          name: "Payments",
          icon: PaymentsTwoTone,
          link: "/dashboard/orders",
        },
      ],
    },
    {
      heading: "Additionals",
      items: [
        {
          name: "Settings",
          icon: Settings,
          items: [
            {
              name: "My Account",
              icon: Settings,
              link: "/dashboard/account/settings",
            },
            {
              name: "Checkins",
              icon: Settings,
              link: "/dashboard/checkins",
            },
            {
              name: "Change Password",
              icon: VpnKeyTwoToneIcon,
              link: "/dashboard/account/change-password",
            },
          ],
        },
        {
          name: "Logout",
          icon: Logout,
          link: "/auth/logout",
        },
      ],
    },
  ],
  misc: [
    {
      heading: "",
      items: [
        {
          name: "Dashboard",
          icon: AnalyticsTwoToneIcon,
          link: "/dashboard",
        },
      ],
    },
    {
      heading: "Additionals",
      items: [
        {
          name: "Settings",
          icon: Settings,
          items: [
            {
              name: "My Account",
              icon: Settings,
              link: "/dashboard/account/settings",
            },
            {
              name: "Change Password",
              icon: VpnKeyTwoToneIcon,
              link: "/dashboard/account/change-password",
            },
          ],
        },
        {
          name: "Logout",
          icon: Logout,
          link: "/auth/logout",
        },
      ],
    },
  ],
};
