import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  alpha,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import UnfoldMoreTwoToneIcon from "@mui/icons-material/UnfoldMoreTwoTone";
import { GlobalContext } from "src/contexts/GlobalContext";
import { LockOpenOutlined } from "@mui/icons-material";
import SwitchAccount from "src/content/dashboards/Account/SwitchAccount";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
    background-color: ${alpha(theme.colors.alpha.black[100], 0.08)};

    .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor, 0.6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`
);

function SidebarTopSection() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { logout, currentUser } = useContext(GlobalContext);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/auth/login");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton fullWidth color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="rounded"
          alt={currentUser?.firstName}
          src={
            currentUser?.profileImage
              ? process.env.REACT_APP_DO_SPACE_URL +
                currentUser?.profileImage +
                `?${Math.random()}`
              : "/static/images/avatars/6.png"
          }
        />
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {currentUser?.firstName + " " + currentUser?.lastName}
            </UserBoxLabel>
            {currentUser?.orgName ? (
              <UserBoxDescription variant="body2">
                {currentUser?.orgName}
              </UserBoxDescription>
            ) : (
              <UserBoxDescription variant="body2">
                {currentUser?.email}
              </UserBoxDescription>
            )}
          </UserBoxText>
          <UnfoldMoreTwoToneIcon fontSize="small" sx={{ ml: 1 }} />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuUserBox sx={{ minWidth: 250 }} display="flex">
          <Avatar
            variant="rounded"
            alt={currentUser?.firstName}
            src={
              currentUser?.profileImage
                ? process.env.REACT_APP_DO_SPACE_URL +
                  currentUser?.profileImage +
                  `?${Math.random()}`
                : "/static/images/avatars/6.png"
            }
          />
          <UserBoxText>
            <UserBoxLabel className="popoverTypo" variant="body1">
              {currentUser?.firstName + " " + currentUser?.lastName}
            </UserBoxLabel>
            <UserBoxDescription className="popoverTypo" variant="body2">
              {currentUser?.email}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />

        <Box>
          <SwitchAccount />
          <Divider />
          <Button
            color="primary"
            sx={{
              justifyContent: "flex-start",
            }}
            fullWidth
            onClick={handleLogout}
          >
            <LockOpenOutlined sx={{ mr: 1 }} />
            {t("Sign out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default SidebarTopSection;
