import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "@react-spring/web";
import { FormContext } from "src/contexts/FormContext";
import {
  Divider,
  Stack,
  Typography,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { InputTypesObject } from "src/utils/constants";
import { useContext, useEffect, useState } from "react";
import { ExpandMoreTwoTone } from "@mui/icons-material";
import EditableQuestion from "./question/EditableQuestion";
import CreateUpdateQuestion from "./question/CreateUpdateQuestion";
import EditableParameter from "./parameter/EditableParameter";
// import { useDrag } from 'react-dnd';
import Nestable from "react-nestable";
import Handler from "src/components/Handler";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import "react-nestable/dist/styles/index.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: 8 * 2,
  // margin: `0 0 ${8}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
  padding: 8, //grid,
  // width: 250
});

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

export default function QuestionsTreeView() {
  const {
    isAddOpen,
    form,
    handleGetForm,
    selectedFormGroup,
    setSelectedQuestionGroup,
    setSelectedQuestion,
    setSelectedOption,
    setCurrentSelectedItemType,
  } = useContext(FormContext);
  const [questionsGroup, setQuestionsGroup] = useState([]);

  useEffect(() => {
    if (form && selectedFormGroup) {
      const _group = form?.formGroups?.find(
        (group) => group?._id === selectedFormGroup?._id
      );
      setQuestionsGroup(_group?.questionsGroup || []);
    }
  }, [selectedFormGroup, form]);

  const handleUpdateQuestion = async (updatedQs) => {
    try {
      // Assume items is an array containing the modified formInput objects
      const updatePromises = updatedQs.map(async (item) => {
        const resp = await axiosInstance.put(
          `/forms/questions/${item._id}`,
          item
        );
        if (resp?.status !== 200) {
          throw new Error(
            resp?.data?.message ||
              resp?.statusText ||
              "Failed to update question"
          );
        }
      });

      await Promise.all(updatePromises);

      // Refresh the form data after updating all questions
      await handleGetForm(form?._id);

      Notify.success("Successfully");
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "An error occurred"
      );
    }
  };

  const handleOnChangeQuestions = ({ items }) => {
    const updatedQs = items.map((item, index) => {
      return {
        ...item,
        position: index,
      };
    });
    handleUpdateQuestion(updatedQs);
  };

  const handleParameterOrder = async (updatedOrder) => {
    try {
      // Assume items is an array containing the modified formInput objects
      const updatePromises = updatedOrder.map(async (item) => {
        const resp = await axiosInstance.put(
          `/forms/question-group/${item._id}`,
          item
        );
        if (resp?.status !== 200) {
          throw new Error(
            resp?.data?.message ||
              resp?.statusText ||
              "Failed to update question"
          );
        }
      });

      await Promise.all(updatePromises);
      await handleGetForm(form?._id);
      Notify.success("Successfully");
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      questionsGroup,
      result.source.index,
      result.destination.index
    );

    const updatedOrder = items.map((item, index) => {
      return {
        ...item,
        position: index,
      };
    });
    handleParameterOrder(updatedOrder);
  };

  return (
    <>
      {questionsGroup?.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {questionsGroup
                  ?.sort(function (a, b) {
                    return a.position - b.position;
                  })
                  ?.map((group, index) => (
                    <Draggable
                      key={group._id}
                      draggableId={group._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div>
                          <Accordion
                            aria-labelledby={"Accordion" + index}
                            id={"Accordion-id" + index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            onClick={() => {
                              setSelectedQuestionGroup(group);
                              setCurrentSelectedItemType("question-group");
                              setSelectedQuestion(null);
                              setSelectedOption(null);
                            }}
                          >
                            <AccordionSummary
                              aria-controls={"AccordionSummary" + index}
                              id={"AccordionSummary-id" + index}
                              {...{
                                ...provided.dragHandleProps,
                                style: { minHeight: "42px" },
                              }}
                              expandIcon={<ExpandMoreTwoTone />}
                              sx={{
                                ".Mui-expanded": {
                                  margin: 0,
                                },
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ width: "100%" }}
                                justifyContent={"space-between"}
                              >
                                {(() => {
                                  const pw = group?.questions
                                    ?.flatMap((question) => {
                                      if (
                                        question.type === "radio" ||
                                        question.type === "select" ||
                                        question.type === "rating"
                                      ) {
                                        return Math.max(
                                          ...question?.options?.map(
                                            (option) => option.weightage
                                          )
                                        );
                                      } else if (question.type === "checkbox") {
                                        return question?.options?.map(
                                          (option) => option.weightage
                                        );
                                      } else {
                                        return 0;
                                      }
                                    })
                                    .reduce((acc, curr) => acc + curr, 0);
                                  return (
                                    <EditableParameter
                                      isWeightVisible={pw !== 0}
                                      weightage={pw}
                                      parameter={group}
                                    />
                                  );
                                })()}
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Nestable
                                idProp="_id"
                                maxDepth={1}
                                items={group?.questions.sort(function (a, b) {
                                  return a.position - b.position;
                                })}
                                onChange={handleOnChangeQuestions}
                                handler={<Handler />}
                                renderItem={({ item, handler }) => (
                                  <EditableQuestion
                                    key={item._id}
                                    handler={handler}
                                    question={item}
                                    questionTypeIcon={
                                      InputTypesObject[item?.type]
                                        ? InputTypesObject[item?.type].icon
                                        : null
                                    }
                                    isWeightVisible={
                                      [
                                        "radio",
                                        "checkbox",
                                        "select",
                                        "rating",
                                      ].includes(item?.type) &&
                                      item?.options?.some(
                                        (c) => c?.weightage !== 0
                                      )
                                    }
                                    weightage={
                                      item.type === "radio" ||
                                      item.type === "select" ||
                                      item.type === "rating"
                                        ? Math.max(
                                            ...item?.options?.map(
                                              (option) => option.weightage
                                            )
                                          )
                                        : item.type === "checkbox"
                                        ? item?.options
                                            ?.map((option) => option.weightage)
                                            .reduce(
                                              (acc, curr) => acc + curr,
                                              0
                                            )
                                        : 0
                                    }
                                  />
                                )}
                              />
                            </AccordionDetails>
                          </Accordion>
                          {isAddOpen === "parameter" && (
                            <CreateUpdateQuestion questionGroup={group} />
                          )}
                          {group?.questions?.length === 0 && (
                            <CreateUpdateQuestion
                              mode={"new"}
                              questionGroup={group}
                            />
                          )}
                          {/* </StyledTreeItem> */}
                          <Divider />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="caption"
        >
          No Questions group Added yet
        </Typography>
      )}
    </>
  );
}
