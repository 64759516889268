import { EditTwoTone, PhotoCameraTwoTone } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import {
  DO_SPACES_FOLDERS,
  FORM_TYPES,
  FORM_TYPES_FOR_COMPANY,
} from "src/utils/constants";
import PricingSection from "./components/PricingSection";
import ExamSection from "./components/ExamSection";
import { LoadingButton } from "@mui/lab";
import DisputeSection from "./components/DisputeSection";
import { Notify } from "notiflix";
import { FormContext } from "src/contexts/FormContext";
import { validateNewEditForm } from "src/utils/validations";
import { useNavigate } from "react-router";
import AccessibilitySection from "./components/AccessibilitySection";

export default function NewFormDialog({ form, page, formType }) {
  const navigate = useNavigate();
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const { handleGetForm } = useContext(FormContext);
  const [open, setOpen] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [formInput, setFormInput] = useState({
    type: formType || "rating",
    industry: ["General"],
  });
  const [coverImage, setCoverImage] = useState(null);

  const [industries, setIndustries] = useState([]);
  const [org, setOrg] = useState(null);

  useEffect(() => {
    if (form) {
      setFormInput({
        title: form?.title,
        description: form?.description,
        tags: form?.tags?.join(", "),
        industry: form?.industry,
        access: form?.access,
        premium: form?.premium,
        amount: form?.amount,
        quota: form?.quota,
        type: form?.type,
        duration: form?.duration,
        attempts: form?.attempts,
        passingPercentage: form?.passingPercentage,
        includeScore: form?.includeScore || false,
        receivers: form?.receivers,
        certificate: form?.certificate,
        disputeForm: form?.disputeForm,
      });
    } else {
      setFormInput({
        type: formType || "rating",
        industry: ["General"],
        receivers: [],
        premium: false,
        amount: 0,
        quota: 0,
        includeScore: false,
      });
    }
  }, [form, formType]);

  useEffect(() => {
    (async () => {
      const _industries = await getTagsByType("industries");
      setIndustries(_industries);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser?.orgId) {
      (async () => {
        const resp = await axiosInstance.get(
          `/organisations/${currentUser?.orgId}`
        );
        if (resp?.status === 200) {
          setOrg(resp?.data);
        }
      })();
    }
  }, [currentUser]);

  const handleSubmit = async () => {
    try {
      await validateNewEditForm(formInput, currentUser);

      setIsWait(true);

      const reqObj = { ...formInput };
      reqObj.tags = !reqObj.tags
        ? ["general"]
        : reqObj.tags
            .split(",")
            .filter((d) => d.trim() !== "")
            .map((d) => d.trim())
            .map((d) => d.toLowerCase());

      if (coverImage) {
        const formData = new FormData();
        formData.append("files", coverImage);
        formData.append("fileCategory", DO_SPACES_FOLDERS.FORMS);
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp?.status === 200 && imageResp?.data) {
          setFormInput({
            ...formInput,
            coverImage: imageResp?.data?.[0],
          });
          reqObj.coverImage = imageResp?.data?.[0];
        }
      }

      if (reqObj?.amount) {
        reqObj.amount = Number(reqObj?.amount);
      }
      if (reqObj?.quota) {
        reqObj.quota = Number(reqObj?.quota);
      }
      if (reqObj?.type === "exam") {
        reqObj.access =
          currentUser?.accountType === "admin" ? "user" : "employee";
        reqObj.receivers = [];
      }

      if (form) {
        const resp = await axiosInstance.put(`/forms/${form._id}`, reqObj);
        if (resp?.status === 200) {
          await handleGetForm(form?._id);
          setIsWait(false);
          Notify.success("Form updated successfully");
          handleClose();
        }
      } else {
        const resp = await axiosInstance.post("/forms", reqObj);
        if (resp?.status === 201) {
          setFormInput({});
          setIsWait(false);
          Notify.success("Form created successfully");
          navigate(`/forms/${resp?.data?._id}`);
          handleClose();
        }
      }
      setCoverImage(null);
    } catch (error) {
      setIsWait(false);
      setCoverImage(null);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (!form)
      setFormInput({
        type: formType || "rating",
        industry: ["General"],
        receivers: [],
        premium: false,
        amount: 0,
        quota: 0,
        includeScore: false,
      });
  };

  return (
    <div>
      {form ? (
        <Button
          size="small"
          onClick={handleClickOpen}
          startIcon={<EditTwoTone />}
          variant="outlined"
        >
          Edit
        </Button>
      ) : (
        <Button
          sx={{
            my: page === "forms" ? 0 : 2,
            border: "1px dashed",
            borderRadius: "10px",
          }}
          fullWidth
          variant="outlined"
          size="sm"
          color="warning"
          onClick={handleClickOpen}
        >
          Create New {formType || "Form"}
        </Button>
      )}
      {open && (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
          <DialogContent>
            <TabsWrapper
              onChange={(e, value) =>
                setFormInput({
                  ...formInput,
                  type: value,
                  access: value === "exam" ? "users" : formInput?.access,
                })
              }
              scrollButtons="auto"
              textColor="secondary"
              value={formInput?.type || ""}
              variant="scrollable"
              sx={{ mb: 2 }}
            >
              {Object.entries(
                currentUser?.orgId ? FORM_TYPES_FOR_COMPANY : FORM_TYPES
              )?.map(([key, _tag]) => (
                <Tab key={key} value={_tag?.value} label={_tag?.label} />
              ))}
            </TabsWrapper>
            {/* <Alert severity="warning" sx={{ mb: 2 }}>
              {currentUser?.orgId
                ? FORM_TYPES_FOR_COMPANY[formInput?.type]?.text
                : FORM_TYPES[formInput?.type]?.text}
            </Alert> */}
            <Divider />
            <Stack sx={{ mt: 2 }} spacing={2}>
              {formInput?.type === "exam" && (
                <Stack direction="row" spacing={2}>
                  <Button
                    startIcon={<PhotoCameraTwoTone />}
                    variant="outlined"
                    onClick={() => {
                      const input = document.createElement("input");
                      input.type = "file";
                      input.accept = "image/*";
                      input.onchange = async (e) => {
                        setCoverImage(e.target.files[0]);
                      };
                      document.body.appendChild(input);
                      input.click();
                      document.body.removeChild(input);
                    }}
                    sx={{ width: "50%" }}
                  >
                    Upload Cover Image
                  </Button>
                  {coverImage && coverImage?.name && (
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      {coverImage?.name}
                    </Typography>
                  )}
                </Stack>
              )}
              <TextField
                autoFocus
                label="Title *"
                fullWidth
                name="title"
                value={formInput?.title || ""}
                onChange={handleInputChange}
              />
              <Stack direction="row" spacing={2}>
                <Autocomplete
                  fullWidth
                  multiple
                  options={
                    currentUser?.orgId
                      ? ["General", ...(org?.data?.industry || [])]
                      : ["General", ...(industries || [])]
                  }
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Industry Type(s) *"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(e, value) =>
                    setFormInput({ ...formInput, industry: value })
                  }
                  value={formInput?.industry || ""}
                />
              </Stack>
              <AccessibilitySection
                formInput={formInput}
                setFormInput={setFormInput}
                handleInputChange={handleInputChange}
              />
              {formInput?.type === "exam" && (
                <ExamSection
                  formInput={formInput}
                  setFormInput={setFormInput}
                  handleInputChange={handleInputChange}
                />
              )}
              <PricingSection
                formInput={formInput}
                setFormInput={setFormInput}
                handleInputChange={handleInputChange}
              />

              {currentUser?.accountType === "admin" &&
                ["rating"]?.includes(formInput?.type) && (
                  <DisputeSection
                    formInput={formInput}
                    setFormInput={setFormInput}
                  />
                )}
              <TextField
                label="Description (optional)"
                name="description"
                value={formInput?.description || ""}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={isWait}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSubmit}
              loading={isWait}
              variant="contained"
              color="primary"
            >
              {form ? "Update" : "Save"} & Continue
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);
