import React, { useState } from "react";
import ManageUsers from "./users";
import ManageOrganisations from "./organisations";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { CorporateFareOutlined, GroupOutlined } from "@mui/icons-material";

export default function ManageAccounts() {
  const navigate = useNavigate();
  const { accountType } = useParams();
  const [selectedAccountType, setSelectedAccountType] = useState(
    accountType || "users"
  );
  return (
    <div>
      <Box my={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1} mb={4}>
            <Typography variant="h1" component="h1">
              Manage {accountType === "users" ? "Users" : "Organisations"}
            </Typography>
            <Typography variant="h6" color={"textSecondary"}>
              Manage centralised user and organisation accounts.
            </Typography>
          </Stack>
          <Stack>
            {/* <ToggleButtonGroup
              value={selectedAccountType}
              exclusive
              onChange={(e, value) => {
                setSelectedAccountType(value || selectedAccountType);
                navigate(`/dashboard/accounts/${value}`);
              }}
            >
              <ToggleButton value="users">
                <Stack direction={"row"} spacing={2} mx={2}>
                  <GroupOutlined />{" "}
                  <Typography fontWeight={"bold"}>Users</Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value="organisations">
                <Stack direction={"row"} spacing={2} mx={2}>
                  <CorporateFareOutlined />{" "}
                  <Typography fontWeight={"bold"}>Organisations</Typography>
                </Stack>
              </ToggleButton>
            </ToggleButtonGroup> */}
          </Stack>
        </Stack>

        <Box>
          {
            {
              users: <ManageUsers />,
              organisations: <ManageOrganisations />,
            }[selectedAccountType]
          }
        </Box>
      </Box>
    </div>
  );
}
