import { AspectRatio } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography,
  alpha,
  darken,
  styled,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import UploadButton from "../../../Documents/components/UploadButton";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";

export default function PhotosGallery({ documents, handleGetDocuments }) {
  const theme = useTheme();
  const { currentUser } = useContext(GlobalContext);
  const { profile } = useContext(ProfileContext);

  return (
    <Card
      sx={{
        border: 1,
        borderColor: "grey.400",
      }}
    >
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            Photos Gallery
          </Typography>
          <Typography variant="subtitle2">
            Please be cautious when uploading photos, as you can't delete them
            later
          </Typography>
        </Box>
        <Box>
          {(currentUser?.accountType === "admin" ||
            profile?.employementHistory?.some((relation) =>
              relation?.relations?.some((r) => r?.isActive)
            ) ||
            currentUser?.userId === profile?._id) && (
            <UploadButton
              category="images"
              handleGetDocuments={handleGetDocuments}
            />
          )}
        </Box>
      </Box>
      <Divider />
      <CardContent>
        {documents?.data?.filter((item) => item?.category === "images")
          ?.length > 0 ? (
          <Grid container spacing={3}>
            {documents?.data
              ?.filter((item) => item.category === "images")
              ?.map((item, idx) => (
                <Grid item xs={6} sm={4} md={3} key={idx}>
                  <Card
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      transition: `${theme.transitions.create([
                        "box-shadow",
                        "transform",
                      ])}`,
                      transform: "translateY(0px)",

                      "&:hover": {
                        transform: `translateY(-${theme.spacing(0.5)})`,
                        boxShadow: `0 2rem 8rem 0 ${alpha(
                          theme.colors.alpha.black[100],
                          0.05
                        )}, 
                        0 0.6rem 1.6rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.15
                        )}, 
                        0 0.2rem 0.2rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.1
                        )}`,

                        "& .MuiBgComposed": {
                          opacity: 1,
                        },
                      },
                    }}
                  >
                    <BgComposed
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      className="MuiBgComposed"
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: `${theme.colors.alpha.trueWhite[70]}`,
                        }}
                      >
                        uploaded by
                      </Typography>
                      <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                          mb: 2,
                          color: `${theme.colors.alpha.trueWhite[100]}`,
                        }}
                      >
                        {item?.uploadedBy?.firstName || "-"}{" "}
                        {item?.uploadedBy?.lastName || ""}
                      </Typography>
                      <IconButton
                        sx={{
                          boxShadow: `${theme.colors.shadows.info}`,
                        }}
                        variant="contained"
                        color="info"
                        component="a"
                        href={
                          process.env.REACT_APP_DO_SPACE_URL +
                          item?.url +
                          `?${new Date().getTime()}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AspectRatio />
                      </IconButton>
                    </BgComposed>
                    <CardMedia
                      component="img"
                      height="260"
                      sx={{
                        borderRadius: "inherit",
                        position: "relative",
                        zIndex: 5,
                      }}
                      image={
                        process.env.REACT_APP_DO_SPACE_URL +
                          item?.url +
                          `?${new Date().getTime()}` || ""
                      }
                      alt=""
                    />
                  </Card>
                </Grid>
              ))}
          </Grid>
        ) : (
          <EmptyHere
            title={"No Photos Uploaded"}
            subtitle={"Upload photos to show them here"}
          />
        )}
      </CardContent>
    </Card>
  );
}

const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: ${theme.transitions.create(["opacity"])};
    background: ${darken(alpha(theme.colors.primary.main, 0.9), 0.8)};
    z-index: 6;
    opacity: 0;
    box-shadow: inset 0 0 2.3rem 0.5rem ${darken(
      theme.colors.primary.main,
      0.9
    )};
  `
);
