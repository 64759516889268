import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { MEMBERSHIPS, PAGE_LIMIT } from "src/utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import Notiflix, { Notify } from "notiflix";
import { GlobalContext } from "src/contexts/GlobalContext";
import {
  AddCircleOutline,
  ArrowForwardIos,
  HighlightOffOutlined,
  StarHalf,
} from "@mui/icons-material";
import Label from "src/components/Label";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function Memberships() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const membershipParam = searchParams.get("membership");
  const { currentUser } = useContext(GlobalContext);
  const [filterObj, setFilterObj] = useState({
    membership: membershipParam || "premium",
  });
  const [loading, setLoading] = useState(true);
  const [isWait, setIsWait] = useState(false);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    if (pageParam) {
      setPage(parseInt(pageParam));
    }
    if (membershipParam) {
      setFilterObj({ ...filterObj, membership: membershipParam || "premium" });
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        if (filterObj?.membership === "all" || filterObj?.search?.length > 0) {
          delete reqParams.params.membership;
        }

        // reqParams.params.isActive = "true";

        delete reqParams.params.accountType;
        const resp = await axiosInstance.get("/employees/members", reqParams);
        if (resp?.status === 200) {
          setUsers(resp?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page, filterObj, searchParams]);

  const handleConfirmChangeMembership = async (userId, action) => {
    try {
      if (!userId) {
        Notify.failure("Employee ID is missing");
        return;
      }
      setIsWait(true);
      const empResp = await axiosInstance.put(`/employees/members`, {
        membership: action,
        userId: userId,
      });
      if (empResp?.status === 200) {
        const _users = users?.data?.map((user) => {
          if (user?._id === userId) {
            // update all memberships in relations' array
            user.relations = user.relations.map((rel) => {
              rel.membership = action;
              return rel;
            });
          }
          return user;
        });

        let _stats = users?.stats;
        if (action === "premium") {
          _stats = {
            ...users?.stats,
            seatsLeft: users?.stats?.seatsLeft - 1,
            seatsConsumed: users?.stats?.seatsConsumed + 1,
          };
        }
        setUsers({ ...users, data: _users, stats: _stats });
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleChangeMembership = async (userId, action) => {
    const confirmMessage =
      action === "premium"
        ? "Please confirm if you want to add this user to Premium Membership?"
        : "Please note this action is irreversible and you might have to buy more seats to add this user again.\n Are you sure to continue?";
    Notiflix.Confirm.show(
      "Confirmation",
      confirmMessage,
      "Confirm",
      "Cancel",
      () => handleConfirmChangeMembership(userId, action),
      () => {},
      {
        width: "500px",
        messageMaxLength: 1000,
      }
    );
  };

  const memberships = ["all", "premium", "other"];

  return (
    <Stack my={2} spacing={2}>
      {users?.stats?.seatsTotal > 0 ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box fullWidth display={"flex"}>
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 500,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here..."
                  inputProps={{ "aria-label": "search here" }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      if (e.target.value.length) {
                        setFilterObj({ ...filterObj, search: searchString });
                      } else {
                        delete filterObj.search;
                        setFilterObj({ ...filterObj, search: null });
                      }
                      setPage(1);
                      setSearchParams({
                        ...Object.fromEntries(searchParams.entries()),
                        page: 1,
                      });
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value?.length > 0) {
                      setSearchString(e.target.value);
                    } else {
                      if (filterObj?.search) {
                        setFilterObj({
                          ...filterObj,
                          search: null,
                        });
                      }
                    }
                  }}
                />
                <IconButton
                  type="button"
                  sx={{
                    m: 0.2,
                    bgcolor: "primary.main",
                    color: "white",
                  }}
                  onClick={() => {
                    setFilterObj({
                      ...filterObj,
                      search: searchString,
                    });
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      page: 1,
                    });
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
            <Box>
              <Autocomplete
                sx={{ width: 300 }}
                disableClearable={true}
                options={memberships || []}
                value={filterObj?.membership || "premium"}
                getOptionLabel={(option) => capitalize(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Membership Type"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(e, value) => {
                  setFilterObj({ ...filterObj, membership: value });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    membership: value,
                    page: 1,
                  });
                }}
              />
            </Box>
          </Stack>
          <Card
            sx={{
              flexGrow: 1,
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  variant="rounded"
                  sx={{
                    background: (theme) => `${theme.colors.info.lighter}`,
                    color: (theme) => `${theme.colors.info.main}`,
                  }}
                >
                  <StarHalf fontSize="large" />
                </Avatar>
              }
              title={
                <Typography variant="h4" component="div">
                  Total Premium Seats Consumed
                </Typography>
              }
              subheader={
                <Typography color="text.primary" variant="h2">
                  {users?.stats?.seatsConsumed
                    ? users?.stats?.seatsConsumed < 9
                      ? `0${users?.stats?.seatsConsumed}`
                      : users?.stats?.seatsConsumed
                    : 0}
                </Typography>
              }
              action={
                <Box
                  sx={{
                    textAlign: "right",
                    mr: 1,
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    component={Link}
                    to="/pricing"
                    style={{
                      textDecoration: "none",
                    }}
                    mb={2}
                  >
                    <Typography variant="subtitle1" color="blue">
                      Buy more seats
                    </Typography>
                    <ArrowForwardIos fontSize="1rem" />
                  </Stack>
                  <Typography variant="h4">
                    <Typography variant="span" color="text.secondary">
                      Seats left to allocate:{" "}
                    </Typography>
                    <b>{users?.stats?.seatsLeft || 0}</b>
                  </Typography>
                </Box>
              }
            />

            <LinearProgress
              color={"info"}
              variant="determinate"
              value={
                users?.stats?.seatsLeft > 0
                  ? (users?.stats?.seatsLeft / users?.stats?.seatsTotal) * 100
                  : 0
              }
            />
          </Card>
          <Box sx={{ mb: 4 }}>
            {isWait && <LinearProgress />}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>EthixFirst ID</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Membership</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <LinearProgress sx={{ m: 3 }} />
                  ) : (
                    <>
                      {users?.data?.map((row) => (
                        <TableRow
                          sx={{
                            color: row?.isLocked && "red",
                            textDecoration: row?.isLocked && "line-through",
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={row?._id}
                        >
                          <TableCell>
                            <b>{row?.cleanExitUserId || "-"}</b>{" "}
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">
                              {row?.firstName + " " + row?.lastName || ""}
                            </Typography>
                            {/* <small>
                              {currentUser?.orgId && row?.role !== "user" && (
                                <Label color="info">{row?.role}</Label>
                              )}
                            </small> */}
                          </TableCell>
                          <TableCell>{row?.email}</TableCell>

                          <TableCell>
                            <Label
                              color={
                                MEMBERSHIPS[
                                  row?.relations?.[0]?.membership || "other"
                                ]?.color || "default"
                              }
                            >
                              {MEMBERSHIPS[
                                row?.relations?.[0]?.membership || "other"
                              ]?.label || "-"}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent={"flex-end"}
                            >
                              {row?.isLocked && (
                                <Label color={"error"}>Deactivated</Label>
                              )}
                              {!row?.relations?.some((rel) => rel.isActive) && (
                                <Label color={"error"}>InActive</Label>
                              )}
                              {row?.relations?.[0]?.organisation ===
                                currentUser?.orgId &&
                                (row?.relations?.[0]?.membership ===
                                "premium" ? (
                                  <Tooltip title="Remove from Premium membership">
                                    <IconButton
                                      color="error"
                                      size="small"
                                      sx={{ mr: 1 }}
                                      onClick={() =>
                                        handleChangeMembership(
                                          row?._id,
                                          "other"
                                        )
                                      }
                                    >
                                      <HighlightOffOutlined />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={
                                      row?.isLocked ||
                                      !row?.relations?.some(
                                        (rel) => rel.isActive
                                      )
                                        ? "You can't add this user to premium membership as the user is deactivated or In-active."
                                        : users?.stats?.seatsLeft === 0
                                        ? "No seats left to allocate. Please buy more seats."
                                        : "Add to Premium membership"
                                    }
                                  >
                                    <Box>
                                      <IconButton
                                        color="primary"
                                        size="small"
                                        sx={{ mr: 1 }}
                                        onClick={() =>
                                          handleChangeMembership(
                                            row?._id,
                                            "premium"
                                          )
                                        }
                                        disabled={
                                          users?.stats?.seatsLeft === 0 ||
                                          row?.isLocked ||
                                          !row?.relations?.some(
                                            (rel) => rel.isActive
                                          )
                                        }
                                      >
                                        <AddCircleOutline />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                ))}
                              <Link
                                to={`/dashboard/users/${row?._id}`}
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  sx={{ mr: 1 }}
                                >
                                  <ArrowForwardIos />
                                </IconButton>
                              </Link>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                      {users?.data?.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            No premium member added yet
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>

              {!loading && users?.data?.length > 0 && (
                <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
                  <Stack spacing={2}>
                    <Pagination
                      count={users?.totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>
                </Box>
              )}
            </TableContainer>
          </Box>
        </>
      ) : loading ? (
        <SuspenseLoader />
      ) : (
        <>
          <Container maxWidth="md">
            <Box
              textAlign="center"
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
              sx={{
                mt: 10,
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  my: 2,
                }}
              >
                You don't have any active plan.
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 4,
                }}
              >
                Please buy seats to add Users to Premium Membership
              </Typography>
            </Box>
            <Container maxWidth="sm">
              <Card
                sx={{
                  textAlign: "center",
                  mt: 2,
                  p: 2,
                }}
              >
                <Button
                  component={Link}
                  to={"/pricing"}
                  sx={{
                    textDecoration: "none",
                  }}
                  variant="outlined"
                  endIcon={<ArrowForwardIos />}
                >
                  View Plans
                </Button>
              </Card>
            </Container>
          </Container>
        </>
      )}
    </Stack>
  );
}
