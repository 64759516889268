import {
  SportsScoreRounded,
  InsightsRounded,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Typography,
  useTheme,
  alpha,
  Stack,
  CardActionArea,
  IconButton,
  Grid,
} from "@mui/material";
import React, { useContext } from "react";
import ScoreCircularBar from "src/content/dashboards/common/ScoreCircularBar";
import ScoreDottedBar from "src/content/dashboards/common/ScoreDottedBar";
import { ProfileContext } from "src/contexts/ProfileContext";
import { MAX_SCORE } from "src/utils/constants";

export default function RatingsNav({ tab, setTab }) {
  const theme = useTheme();
  const { profile } = useContext(ProfileContext);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card
            elevation={tab === "org-ratings" ? 1 : 0}
            sx={{
              borderTopRightRadius: "10px 0px 0px 10px",
              cursor: "pointer",
            }}
            onClick={() => setTab("org-ratings")}
          >
            <Stack
              direction="row"
              spacing={2}
              pt={2}
              ml={2}
              justifyContent={"space-evenly"}
            >
              <Stack
                spacing={1}
                direction={"column"}
                justifyContent={"space-evenly"}
              >
                <Typography variant="h4">Organisation Ratings</Typography>
                <Stack direction="row" alignItems={"center"} spacing={1}>
                  <IconButton
                    color="success"
                    sx={{
                      backgroundColor: alpha(theme.colors.success.main, 0.1),
                    }}
                  >
                    <SportsScoreRounded fontSize="large" />
                  </IconButton>
                  <Typography>
                    <Typography variant="h1" component={"span"}>
                      <b>{profile?.employeeScore || MAX_SCORE}</b>
                    </Typography>
                    {!(
                      profile?.employeeScore === MAX_SCORE ||
                      profile?.employeeScore === 0
                    ) && <sub>/{MAX_SCORE}</sub>}
                  </Typography>
                </Stack>
                <Typography variant="subtitle2" color={"text.secondary"}>
                  Rating Score accumulated as an Employee.
                </Typography>
              </Stack>
              <Box
                sx={{
                  mt: 3,
                  mb: 1,
                  mx: "auto",
                  maxWidth: "380px",
                }}
              >
                <ScoreCircularBar />
              </Box>
            </Stack>
            <CardActionArea
              sx={{ textAlign: "center" }}
              onClick={() => setTab("org-ratings")}
            >
              <KeyboardArrowDown
                sx={
                  tab === "org-ratings" ? {} : { transform: "rotate(180deg)" }
                }
              />
            </CardActionArea>
          </Card>
        </Grid>
        {/* {isMd && (
          <Divider orientation={"vertical"} flexItem sx={{ mx: "-1px" }} />
        )} */}
        <Grid
          item
          xs={12}
          md={6}
          // sx={{
          //   borderTop: isMd ? "none" : "1px solid #e0e0e0",
          // }}
        >
          <Card
            elevation={tab === "commulative-ratings" ? 1 : 0}
            sx={{
              borderTopRightRadius: "10px 0px 0px 10px",
              cursor: "pointer",
            }}
            onClick={() => setTab("commulative-ratings")}
          >
            <Stack
              direction="row"
              spacing={2}
              pt={2}
              ml={2}
              justifyContent={"space-evenly"}
            >
              <Stack
                spacing={1}
                direction={"column"}
                justifyContent={"space-evenly"}
              >
                <Typography variant="h4">Individual Ratings</Typography>
                <Stack direction="row" alignItems={"center"} spacing={1}>
                  <IconButton
                    color="success"
                    sx={{
                      backgroundColor: alpha(theme.colors.success.main, 0.1),
                    }}
                  >
                    <InsightsRounded fontSize="large" />
                  </IconButton>
                  <Typography>
                    <Typography variant="h1" component={"span"}>
                      <b>{profile?.individualScore || MAX_SCORE}</b>
                    </Typography>
                    {!(
                      profile?.individualScore === MAX_SCORE ||
                      profile?.individualScore === 0
                    ) && <sub>/{MAX_SCORE}</sub>}
                  </Typography>
                </Stack>
                <Typography variant="subtitle2" color={"text.secondary"}>
                  Score accumulated as an Individual.
                </Typography>
              </Stack>
              <Box
                sx={{
                  mt: 3,
                  mb: 1,
                  mx: "auto",
                  maxWidth: "380px",
                }}
              >
                <ScoreDottedBar />
              </Box>
            </Stack>
            <CardActionArea
              sx={{ textAlign: "center" }}
              onClick={() => setTab("commulative-ratings")}
            >
              <KeyboardArrowDown
                sx={
                  tab === "commulative-ratings"
                    ? {}
                    : { transform: "rotate(180deg)" }
                }
              />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
