import {
  Autocomplete,
  Card,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";

export default function CareerProtectionPlan({ formInput, setFormInput }) {
  const [loading, setLoading] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get("/metadata/certificates");
        if (resp?.status === 200) {
          setCertificates(resp?.data?.certificates || []);
          setEmailTemplates(resp?.data?.emailTemplates || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure("Failed to fetch certificates");
      }
    })();
  }, [formInput]);

  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);

  useEffect(() => {
    const _certificate = certificates?.find(
      (certificate) => certificate?.title === formInput?.certificate?.title
    );
    const _emailTemplate = emailTemplates?.find(
      (emailTemplate) =>
        emailTemplate?._id === formInput?.certificate?.emailTemplateId
    );
    setSelectedCertificate(_certificate);
    setSelectedEmailTemplate(_emailTemplate);
  }, [formInput, certificates, emailTemplates]);

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <CardHeader
        title="Career Protection Certificate"
        action={"Expire time: 1 year"}
      />
      <Stack direction="row" spacing={2}>
        <Autocomplete
          fullWidth
          options={certificates || []}
          getOptionLabel={(option) => option?.title || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Certificate *"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
          loading={loading}
          onChange={(e, value) =>
            setFormInput({
              ...formInput,
              certificate: {
                ...formInput?.certificate,
                title: value?.title,
                certificateId: value?._id,
              },
            })
          }
          value={selectedCertificate || ""}
        />
        <Autocomplete
          fullWidth
          options={emailTemplates || []}
          getOptionLabel={(option) => option?.name || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Email Template *"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
          loading={loading}
          onChange={(e, value) =>
            setFormInput({
              ...formInput,
              certificate: {
                ...formInput?.certificate,
                emailTemplateId: value?._id,
              },
            })
          }
          value={selectedEmailTemplate || ""}
        />
      </Stack>
    </Card>
  );
}
