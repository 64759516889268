import {
  AddTwoTone,
  ArrowForwardIos,
  DriveFileRenameOutline,
  WysiwygRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import SelectEmployee from "../common/SelectEmployee";
import { GlobalContext } from "src/contexts/GlobalContext";
import UserCard from "src/components/Common/UserCard";
import { getImageUrl } from "src/utils/helpers";
import NewCommitteeDialog from "./NewCommitteeDialog";

export default function CommitteeCard({
  item,
  inputs,
  committee,
  getCommitteeOfOrganisation,
  getCommittees,
}) {
  const [searchParams] = useSearchParams();
  const organisationId = searchParams.get("organisationId");
  const { currentUser } = useContext(GlobalContext);
  const [viewMembers, setViewMembers] = useState(false);
  const [addMember, setAddMember] = useState(false);
  const [members, setMembers] = useState([]);

  const handleSelectUser = async (user) => {
    try {
      if (members?.includes(user?._id)) {
        setMembers(members.filter((id) => id !== user?._id));
      } else {
        setMembers([...members, user?._id]);
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (committee?.members) {
      setMembers(committee?.members.map((member) => member?._id));
    }
  }, [committee]);

  const handleAddCollaborators = async () => {
    try {
      const resp = await axiosInstance.post(`/committees`, {
        committeeId: item?.name,
        members,
      });
      if (resp?.status === 200) {
        Notify.success("Members updated successfully");
        setAddMember(false);
        if (currentUser?.orgId || organisationId) {
          getCommitteeOfOrganisation();
        }
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Card mb={2}>
        <CardContent>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Box>
              <Typography variant="h2" mb={1}>
                {item.title || ""}
              </Typography>
              <Typography variant="h6">{item?.description || ""}</Typography>
            </Box>

            {currentUser?.accountType === "admin" && (
              <NewCommitteeDialog
                getCommittees={getCommittees}
                item={item}
                inputs={inputs}
              />
            )}
          </Stack>
          <Stack
            spacing={2}
            mt={2}
            justifyContent={"space-between"}
            direction={"row"}
          >
            {currentUser?.orgId || organisationId ? (
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Chip
                  avatar={<Avatar>{committee?.members?.length || 0}</Avatar>}
                  label={"Members"}
                  color="primary"
                  variant="outlined"
                />
                <Button
                  color="secondary"
                  startIcon={<WysiwygRounded />}
                  onClick={() => setViewMembers(true)}
                >
                  View Members
                </Button>
              </Stack>
            ) : (
              <Box flexGrow={1}></Box>
            )}

            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIos />}
              component={Link}
              to={`/dashboard/committees/${item?.name}`}
            >
              View complaints
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {viewMembers && (
        <Dialog
          open={true}
          maxWidth={"md"}
          onClose={() => setViewMembers(false)}
        >
          <Box
            p={2}
            sx={{
              minWidth: "400px",
              minHeight: "500px",
            }}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h2" mb={1}>
                Members
              </Typography>
              <Button
                onClick={() => {
                  setViewMembers(false);
                  setAddMember(true);
                }}
                startIcon={
                  committee?.members?.length > 0 ? (
                    <DriveFileRenameOutline />
                  ) : (
                    <AddTwoTone />
                  )
                }
              >
                {committee?.members?.length > 0 ? "Modify " : "Add "}
              </Button>
            </Stack>
            <Divider sx={{ my: 2 }} />
            {!committee?.members?.length ? (
              <Typography variant="h6" textAlign={"center"}>
                No members added yet
              </Typography>
            ) : (
              <Stack spacing={2}>
                {committee?.members?.map((member, index) => (
                  <Card
                    sx={{
                      p: 2,
                    }}
                    key={index}
                  >
                    <UserCard
                      avatar={getImageUrl(member?.profileImage)}
                      title={
                        (member?.firstName || "") +
                        " " +
                        (member?.lastName || "")
                      }
                      subtitle={member?.email}
                    />
                  </Card>
                ))}
              </Stack>
            )}
          </Box>
        </Dialog>
      )}

      {addMember && (
        <Dialog
          open={true}
          maxWidth={"xl"}
          onClose={() => {
            setAddMember(false);
            setViewMembers(false);
          }}
        >
          <DialogContent>
            <SelectEmployee
              orgId={item?.organisation?._id || currentUser?.orgId}
              handleSelectUser={handleSelectUser}
              selectedUsers={members}
            >
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Divider />
                <Button variant="contained" onClick={handleAddCollaborators}>
                  Done
                </Button>
              </Stack>
            </SelectEmployee>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
