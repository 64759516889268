/* eslint-disable jsx-a11y/accessible-emoji */
import { Box, Typography, Avatar, useTheme, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Text from "src/components/Text";
import Chart from "react-apexcharts";
import {
  MoodBadOutlined,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfiedOutlined,
} from "@mui/icons-material";

const BoxChartWrapperText = styled(Box)(
  ({ theme }) => `
    position: relative;
    width: 300px;
    height: 300px;
    
    .ChartText {
      color: ${theme.colors.alpha.black[100]};
      font-weight: bold;
      position: absolute;
      height: 100px;
      width: 100px;
      font-size: ${theme.typography.pxToRem(23)};
      top: 50%;
      left: 50%;
      margin: -65px 0 0 -50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
`
);

const AvatarBad = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      font-size: ${theme.typography.pxToRem(26)};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
      border: 1px solid ${theme.colors.secondary.main};
      color: ${theme.colors.secondary.main};
      margin-right: ${theme.spacing(2)};
`
);
const AvatarUnhappy = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      font-size: ${theme.typography.pxToRem(26)};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
      border: 1px solid ${theme.colors.error.main};
      color: ${theme.colors.error.main};
      margin-right: ${theme.spacing(2)};
`
);
const AvatarOk = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      font-size: ${theme.typography.pxToRem(26)};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
      border: 1px solid ${theme.colors.warning.main};
      color: ${theme.colors.warning.main};
      margin-right: ${theme.spacing(2)};
`
);
const AvatarHappy = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
      font-size: ${theme.typography.pxToRem(26)};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
      border: 1px solid ${theme.colors.success.main};
      color: ${theme.colors.success.main};
      margin-right: ${theme.spacing(2)};
`
);

export default function RatingStats({ stats }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
        },
      },
    },
    colors: [
      theme.palette.secondary.main,
      theme.palette.error.main,
      theme.palette.warning.main,
      theme.palette.success.main,
    ],
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val?.toFixed(0)}%`;
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: theme.colors.alpha.black[50],
        opacity: 0.5,
      },
    },
    fill: {
      opacity: 1,
    },
    labels: ["Exceptional", "Above Average", "Average", "Needs Improvement"],
    legend: {
      labels: {
        colors: theme.colors.alpha.trueWhite[100],
      },
      show: false,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  let chartSeries = [0, 0, 0, 100];
  if (
    stats === undefined ||
    stats === null ||
    Object.keys(stats).length === 0
  ) {
    chartSeries = [0, 0, 0, 100];
  } else if (
    stats.bad === 0 &&
    stats.low === 0 &&
    stats.medium === 0 &&
    stats.high === 0
  ) {
    chartSeries = [0, 0, 0, 100];
  } else {
    chartSeries = [stats.bad, stats.low, stats.medium, stats.high];
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t("Company Rating Statistics")}
      </Typography>
      <Typography variant="subtitle2">
        {t("See how employees are rated by Employer(s) this Month")}
      </Typography>

      <BoxChartWrapperText
        sx={{
          mt: 2,
          mb: 0,
        }}
      >
        <div className="ChartText">
          <span>{stats?.ratingsTotal}</span>
          <Typography variant="subtitle1" textAlign={"center"}>
            {t("Ratings")}
          </Typography>
        </div>
        <Chart
          height="100%"
          width="100%"
          options={chartOptions}
          series={chartSeries}
          type="donut"
        />
      </BoxChartWrapperText>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 1,
        }}
      >
        <AvatarHappy variant="rounded">
          <SentimentSatisfiedAlt fontSize="large" />
        </AvatarHappy>
        <Box>
          <Typography variant="h5">
            <Text color="success">{"Exceptional"}</Text>

            <Typography variant="subtitle1" color="text.secondary">
              {stats?.high || "No"} employees scored above 750
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 1,
        }}
      >
        <AvatarOk variant="rounded">
          <SentimentSatisfied fontSize="large" />
        </AvatarOk>
        <Box>
          <Typography variant="h5">
            <Text color="warning">{"Above Average"}</Text>
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {stats?.medium || "No"} employees scored between 500-750
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 1,
        }}
      >
        <AvatarUnhappy variant="rounded">
          <MoodBadOutlined fontSize="large" />
        </AvatarUnhappy>
        <Box>
          <Typography variant="h5">
            <Text color="error">{"Average"}</Text>
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {stats?.low || "No"} employees scored between 250-500
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        sx={{
          my: 1,
        }}
      >
        <AvatarBad variant="rounded">
          <SentimentVeryDissatisfiedOutlined fontSize="large" />
        </AvatarBad>
        <Box>
          <Typography variant="h5">
            <Text color="secondary">{"Needs Improvement"}</Text>
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {stats?.bad || "No"} employees scored below 250
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
