import * as Yup from "yup";

import { Formik } from "formik";
import { Link, useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import useRefMounted from "src/hooks/useRefMounted";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/contexts/AuthContext";

const LoginJWT = () => {
  const isMountedRef = useRefMounted();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        window.location.replace("/");
      }
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          terms: true,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("The email provided should be a valid email address"))
            .max(255)
            .required(t("The email field is required")),
          password: Yup.string()
            .max(255)
            .required(t("The password field is required")),
          terms: Yup.boolean().oneOf(
            [true],
            t("You must agree to our terms and conditions")
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await login(values);

            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              margin="normal"
              autoFocus
              helperText={touched.email && errors.email}
              label={t("Email address")}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              margin="normal"
              helperText={touched.password && errors.password}
              label={t("Password")}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleShowPassword}
                    sx={{
                      p: 0,
                    }}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                ),
              }}
            />
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.terms}
                    name="terms"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    <Typography variant="body2">
                      {t("I accept the")}{" "}
                      <Link
                        to="#"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        {t("terms and conditions")}
                      </Link>
                      .
                    </Typography>
                  </>
                }
              />
              <Link
                to="/auth/recover-password"
                style={{
                  textDecoration: "none",
                }}
              >
                <b>{t("Lost password?")}</b>
              </Link>
            </Box>

            {Boolean(touched.terms && errors.terms) && (
              <FormHelperText error>{errors.terms}</FormHelperText>
            )}

            <Button
              sx={{
                mt: 3,
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              {t("Sign in")}
            </Button>

            <Button
              sx={{
                mt: 3,
              }}
              color="primary"
              fullWidth
              size="large"
              variant="outlined"
              component={Link}
              to={`/auth/login?redirect_url=${
                searchParams?.get("redirect_url") || "/"
              }`}
            >
              Login via OTP
            </Button>
          </form>
        )}
      </Formik>
      {/* <Box my={4}>
        <Typography
          component="span"
          variant="subtitle2"
          color="text.primary"
          fontWeight="bold"
        >
          {t("Don’t have an account, yet?")}
        </Typography>{" "}
        <Link
          to="/auth/register"
          style={{
            textDecoration: "none",
          }}
        >
          <b>Sign up here</b>
        </Link>
      </Box> */}
    </>
  );
};

export default LoginJWT;
