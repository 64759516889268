import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axiosInstance from "src/utils/axiosInstance";
import Logo from "src/components/LogoSign";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [formInput, setFormInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (event) => {
    event.preventDefault();
    setFormInput({ ...formInput, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (token) {
      setFormInput({ token });
    }
  }, [token]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      // check length of password
      if (formInput.newPassword.length < 8)
        return Notify.warning("Password must be at least 8 characters");

      // check if passwords max length is 20
      if (formInput.newPassword.length > 255)
        return Notify.warning("Password  is too long");

      if (formInput.newPassword !== formInput.confirmNewPassword)
        return Notify.warning("Passwords do not match");

      setLoading(true);
      const response = await axiosInstance.post(
        "/auth/reset-password",
        formInput
      );
      if (response.status === 200) {
        setLoading(false);
        Notify.success("Password updated successfully");
        setFormInput(null);
        navigate("/auth/login");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Recover Password</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="sm">
          <Logo />
          <Card
            sx={{
              mt: 3,
              p: 4,
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                }}
              >
                {t("Reset Password")}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3,
                }}
              >
                {t("Enter new password to reset your password.")}
              </Typography>
            </Box>

            <Stack spacing={3} alignItems="flex-end">
              <TextField
                fullWidth
                autoComplete="on"
                type={showPassword ? "text" : "password"}
                label="New Password"
                name="newPassword"
                value={formInput?.newPassword || ""}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={handleShowPassword}
                      sx={{
                        p: 0,
                      }}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  ),
                }}
              />

              <TextField
                fullWidth
                autoComplete="on"
                type="text"
                label="Confirm New Password"
                name="confirmNewPassword"
                value={formInput?.confirmNewPassword || ""}
                onChange={handleChange}
              />

              <LoadingButton
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                loading={loading}
              >
                Save Changes
              </LoadingButton>
            </Stack>
            <Box mt={3} textAlign="right">
              <Typography
                component="span"
                variant="subtitle2"
                color="text.primary"
                fontWeight="bold"
              >
                {t("Want to try to sign in again?")}
              </Typography>{" "}
              <Link
                to="/auth/login"
                style={{
                  textDecoration: "none",
                }}
              >
                <b>Click here</b>
              </Link>
            </Box>
          </Card>
        </Container>
      </MainContent>
    </>
  );
}
