import {
  Box,
  Typography,
  ListItem,
  styled,
  IconButton,
  Stack,
  Alert,
  AlertTitle,
  Card,
  List,
  ListSubheader,
  Avatar,
  Divider,
  useTheme,
  alpha,
  Tooltip,
  // alpha,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ProfileContext } from "src/contexts/ProfileContext";
import { useContext, useEffect, useState } from "react";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { OpenInNewTwoTone, SportsScoreTwoTone } from "@mui/icons-material";
import { RATING_STATUS_COLOR } from "src/utils/constants";
import Label from "src/components/Label";
import { GlobalContext } from "src/contexts/GlobalContext";
import { calculateFinalScore } from "src/utils/helpers";
import EmptyHere from "src/components/EmptyHere";
import { useTranslation } from "react-i18next";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function CompanyRatingCard() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const [showAll, setShowAll] = useState(true);
  const [loading, setLoading] = useState(true);
  const { profile, ratings, handleGetUserRatings, viewAsPublic } =
    useContext(ProfileContext);
  const [showDisputeOption, setShowDisputeOption] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await handleGetUserRatings(profile, {
        type: "rating",
        includeScore: true,
        scoreType: "employee",
      });
      setLoading(false);
    })();
  }, [profile]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <>
      <Card elevation={0}>
        {ratings.length > 0 ? (
          ratings
            ?.sort((a, b) =>
              a?.organisationDetails?.title?.localeCompare(
                b?.organisationDetails?.title
              )
            )
            ?.map((item, idx) => (
              <List component="div" key={idx} disablePadding>
                <ListSubheader
                  component="div"
                  color="primary"
                  sx={{
                    background: `${theme.colors.alpha.white[100]}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 2,
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                      src={
                        process.env.REACT_APP_DO_SPACE_URL +
                        item?.organisationDetails?.avatar +
                        `?${Math.random()}`
                      }
                      sx={{
                        width: "3rem",
                        height: "3rem",
                      }}
                    />

                    <Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "1.4rem",
                        }}
                      >
                        {item?.organisationDetails?.title}
                      </Typography>
                      {item?.organisationDetails?.companyWebsite && (
                        <Typography
                          variant="h6"
                          component={"a"}
                          href={
                            "https://" +
                            item?.organisationDetails?.companyWebsite?.trim()
                          }
                          target="_blank"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          {item?.organisationDetails?.companyWebsite}
                        </Typography>
                      )}
                    </Typography>
                  </Stack>
                  {(() => {
                    const hasRatings = item?.ratings?.some(
                      (rating) => rating?.formId
                    );

                    if (!hasRatings) return <> </>;

                    const score =
                      item?.ratings
                        ?.filter((rating) => rating?.includeScore)
                        ?.reduce((acc, rating) => acc + rating?.score, 0)
                        ?.toFixed(0) || 0;

                    const weightage =
                      item?.ratings
                        ?.filter((rating) => rating?.includeScore)
                        ?.reduce((acc, rating) => acc + rating?.weightage, 0)
                        ?.toFixed(0) || 0;

                    const finaScore = calculateFinalScore({
                      score,
                      weightage,
                    })?.score;

                    return (
                      <Typography variant="subtitle2">
                        Got <b>{finaScore}</b> Score in{" "}
                        <b>{item?.ratings?.length || 0}</b> Feedback(s)
                      </Typography>
                    );
                  })()}
                </ListSubheader>
                <Divider />

                {item?.ratings
                  ?.sort(
                    (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
                  )
                  ?.filter((a) => (showAll ? true : a?.status === "active"))
                  ?.map((rating, index) =>
                    !rating?.formId ? (
                      <>
                        <Alert
                          severity="success"
                          sx={{ my: 2 }}
                          action={
                            <Typography variant="h3" mx={3}>
                              {
                                calculateFinalScore({
                                  score: rating?.score,
                                  weightage: rating?.weightage,
                                })?.score
                              }
                            </Typography>
                          }
                        >
                          <AlertTitle sx={{ fontWeight: "bold" }}>
                            {rating?.title}
                          </AlertTitle>
                          <Typography>
                            The Rating (Score) at the time when Employee left
                            the Organisation
                          </Typography>
                        </Alert>
                      </>
                    ) : (
                      <ListItem
                        component="div"
                        key={index}
                        sx={{
                          pt: 2,
                          pb: 0,
                        }}
                      >
                        <BoxItemWrapper
                          className={`wrapper-${
                            RATING_STATUS_COLOR[rating?.status || "secondary"]
                              ?.color
                          }`}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            <DotLegend
                              style={{
                                background: (theme) =>
                                  `${
                                    theme.colors[
                                      RATING_STATUS_COLOR[
                                        rating?.status || "secondary"
                                      ]?.color
                                    ]?.main
                                  }`,
                              }}
                            />
                            <Typography component={"span"}>
                              <Typography
                                component="span"
                                sx={{
                                  fontWeight: "bold",
                                  color:
                                    rating?.status === "active"
                                      ? "text.primary"
                                      : "grey",
                                }}
                              >
                                {rating?.title}
                                {rating?.includeScore && (
                                  <Tooltip title="This Rating's Score is included in User's Score.">
                                    <sup>
                                      <small>
                                        <SportsScoreTwoTone
                                          sx={{
                                            fontSize: "1rem",
                                          }}
                                        />
                                      </small>
                                    </sup>
                                  </Tooltip>
                                )}
                              </Typography>{" "}
                              {/* - by{" "}
                              <Link
                                to={`/dashboard/users/${rating?.submitBy?._id}`}
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                {rating?.submitBy?.firstName}{" "}
                                {rating?.submitBy?.lastName}
                              </Link> */}
                            </Typography>
                          </Typography>
                          <Stack
                            variant="h6"
                            sx={{
                              pl: 2.3,
                              py: 1,

                              color:
                                rating?.status === "active"
                                  ? "text.primary"
                                  : "grey",
                            }}
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <span>
                              Score -{" "}
                              <b>
                                {
                                  calculateFinalScore({
                                    score: rating?.score,
                                    weightage: rating?.weightage,
                                  })?.score
                                }
                              </b>
                            </span>
                            <Typography>•</Typography>
                            <small>{fDateTimeSuffix(rating?.createdAt)}</small>
                            {rating?.status === "deprecated" && (
                              <Label height={18}>- old rating</Label>
                            )}
                          </Stack>

                          {showDisputeOption && rating?.disputeForm && (
                            <Typography sx={{ pl: 2.3, cursor: "pointer" }}>
                              <Link
                                to={`/dashboard/forms/${rating?.disputeForm}`}
                              >
                                Click here{" "}
                              </Link>
                              to file dispute.
                            </Typography>
                          )}
                        </BoxItemWrapper>

                        <Box
                          sx={{
                            pl: 2.3,
                            width: 100,
                            textAlign: "right",
                          }}
                        >
                          {currentUser?.role !== "user" && (
                            <IconButton
                              size="small"
                              component={Link}
                              to={`/dashboard/ratings/${rating?._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <OpenInNewTwoTone fontSize="small" />
                            </IconButton>
                          )}
                        </Box>
                      </ListItem>
                    )
                  )}
                {!viewAsPublic &&
                  currentUser?.accountType !== "user" &&
                  item?.ratings?.filter((a) => a?.status !== "active")?.length >
                    0 && (
                    <Typography
                      sx={{
                        color: (theme) => theme.colors.primary.main,
                        mx: 3,
                        mt: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => setShowAll(!showAll)}
                    >
                      {showAll ? "Hide" : "Show"} Older Ratings
                    </Typography>
                  )}

                <Divider sx={{ mt: 2 }} />
              </List>
            ))
        ) : (
          <EmptyHere title={t("No Organisation Ratings")} />
        )}
      </Card>
      {ratings.flatMap((a) => a?.ratings || []).some((b) => !!b?.disputeForm) &&
        currentUser?.role === "user" && (
          <Box
            sx={{
              py: 4,
              width: "100%",
              textAlign: "center",
              background: alpha(theme.colors.alpha.black[100], 0.05),
            }}
          >
            <Typography variant="subtitle2">
              Did you find any Feedback Misleading or Disputed? <br />
              Please feel free to report it to us and{" "}
              <Typography
                sx={{
                  cursor: "pointer",
                  color: theme.colors.primary.main,
                }}
                variant="span"
                onClick={() => setShowDisputeOption(true)}
              >
                click here{" "}
              </Typography>
              to fill <b>Dispute Form</b>.
            </Typography>
          </Box>
        )}
    </>
  );
}

const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    display: inline-block;
    margin-right: ${theme.spacing(1)};
    margin-top: -${theme.spacing(0.1)};
`
);

const BoxItemWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    position: relative;
    padding: ${theme.spacing(2)};
    width: 100%;
    
    &::before {
      content: '.';
      background: ${theme.colors.success.main};
      color: ${theme.colors.success.main};
      border-radius: ${theme.general.borderRadius};
      position: absolute;
      text-align: center;
      width: 6px;
      left: 0;
      height: 100%;
      top: 0;
    }
    
    &.wrapper-info {
      &:before {
        background: ${theme.colors.info.main};
        color: ${theme.colors.info.main};
      }
    }
        
    &.wrapper-warning {
      &:before {
        background: ${theme.colors.warning.main};
        color: ${theme.colors.warning.main};
      }
    }

    &.wrapper-error {
      &:before {
        background: ${theme.colors.error.main};
        color: ${theme.colors.error.main};
      }
    }

    &.wrapper-secondary {
      &:before {
        background: ${theme.colors.secondary.main};
        color: ${theme.colors.secondary.main};
      }
    }
`
);
