import { Tooltip, styled, tooltipClasses } from "@mui/material";
import React from "react";

export default function CustomTooltip({ children, ...others }) {
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
      padding: "5px 7px",
    },
  });

  return (
    <CustomWidthTooltip arrow {...others}>
      {children}
    </CustomWidthTooltip>
  );
}
