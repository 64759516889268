import React from "react";
import { Stack, TextField, InputAdornment, Button } from "@mui/material";
import { Public, LinkedIn, GitHub, Twitter } from "@mui/icons-material";

export default function EditSocialLinks({
  formInput,
  setFormInput,
  editedFormInput,
  setEditedFormInput,
  profileId,
}) {
  return (
    <Stack spacing={2}>
      <TextField
        placeholder="Personal Website. e.g. https://www.site.domain"
        fullWidth
        name="website"
        value={formInput?.meta?.website || ""}
        onChange={(e) => {
          if (profileId) {
            setEditedFormInput({
              ...editedFormInput,
              meta: {
                ...formInput.meta,
                website: e.target.value,
              },
            });
          }

          setFormInput({
            ...formInput,
            meta: {
              ...formInput.meta,
              website: e.target.value,
            },
          });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Public />
            </InputAdornment>
          ),
        }}
      />{" "}
      <TextField
        placeholder="username"
        fullWidth
        name="linkedin"
        value={formInput?.meta?.linkedin || ""}
        onChange={(e) => {
          if (profileId) {
            setEditedFormInput({
              ...editedFormInput,
              meta: {
                ...formInput.meta,
                linkedin: e.target.value,
              },
            });
          }

          setFormInput({
            ...formInput,
            meta: {
              ...formInput.meta,
              linkedin: e.target.value,
            },
          });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button startIcon={<LinkedIn />}>http://linkedin.com/</Button>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder="username"
        fullWidth
        name="github"
        value={formInput?.meta?.github || ""}
        onChange={(e) => {
          if (profileId) {
            setEditedFormInput({
              ...editedFormInput,
              meta: {
                ...formInput.meta,
                github: e.target.value,
              },
            });
          }

          setFormInput({
            ...formInput,
            meta: {
              ...formInput.meta,
              github: e.target.value,
            },
          });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button startIcon={<GitHub />}>https://github.com/</Button>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder="username"
        fullWidth
        name="twitter"
        value={formInput?.meta?.twitter || ""}
        onChange={(e) => {
          if (profileId) {
            setEditedFormInput({
              ...editedFormInput,
              meta: {
                ...formInput.meta,
                twitter: e.target.value,
              },
            });
          }

          setFormInput({
            ...formInput,
            meta: {
              ...formInput.meta,
              twitter: e.target.value,
            },
          });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button startIcon={<Twitter />}>https://twitter.com/</Button>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
