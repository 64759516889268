import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

function ChartCard({ type, title, data, height = 228 }) {
  return (
    <Card sx={{ p: 2.5 }}>
      <Typography variant="h2" mb={2} fontSize={"large"}>
        {title}
      </Typography>
      <Box>
        <Chart
          height={height}
          options={data.chartOptions}
          series={data.chartSeries}
          type={type}
        />
      </Box>
    </Card>
  );
}

export default ChartCard;
