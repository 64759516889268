import { Dialog, DialogContent } from "@mui/material";
import React, { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import LoginJWT from "./LoginJWT";

export default function LoginDialog() {
  const { openLoginDialog, setOpenLoginDialog } = useContext(GlobalContext);

  return (
    openLoginDialog && (
      <Dialog open={openLoginDialog} onClose={() => setOpenLoginDialog(false)}>
        <DialogContent>
          <LoginJWT />
        </DialogContent>
      </Dialog>
    )
  );
}
