import { useContext } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  styled,
} from "@mui/material";
import Logo from "src/components/Logo";

import { GlobalContext } from "src/contexts/GlobalContext";
import { CloseTwoTone, MenuTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import HeaderUserbox from "src/layouts/BoxedSidebarLayout/Header/Userbox";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.header.textColor};
        padding: ${theme.spacing(1, 0)};
        position: relative;
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar, currentUser } =
    useContext(GlobalContext);

  return (
    <Paper>
      <Container>
        <HeaderWrapper display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box component="span">
              <Box sx={{ display: "flex" }}>
                <Logo />
              </Box>
            </Box>
          </Box>
          {currentUser ? (
            <Box display="flex" alignItems="center">
              <HeaderUserbox />
              <Box
                component="span"
                sx={{
                  display: { lg: "none", xs: "inline-block" },
                }}
              >
                <Tooltip arrow title="Toggle Menu">
                  <IconButton color="primary" onClick={toggleSidebar}>
                    {!sidebarToggle ? <MenuTwoTone /> : <CloseTwoTone />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            <Stack direction="row" spacing={1} sx={{ ml: 3 }}>
              <Button
                variant="contained"
                color="success"
                component={Link}
                to="/auth/login"
              >
                Login
              </Button>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/auth/register"
              >
                Sign up
              </Button>
            </Stack>
          )}
        </HeaderWrapper>
      </Container>
    </Paper>
  );
}

export default Header;
