import {
  Grid,
  Box,
  Card,
  Typography,
  Avatar,
  alpha,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import {
  AddReactionTwoTone,
  AutoGraph,
  StarTwoTone,
} from "@mui/icons-material";
import { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function SummaryStats({ stats }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentUser } = useContext(GlobalContext);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.black1}`,
          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {t("New Accounts")}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {stats?.newAccounts || 0}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${theme.colors.alpha.trueWhite[100]}`,
                color: `${theme.colors.success.main}`,
              }}
            >
              <AccountBoxTwoToneIcon fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              This Month (
              {new Date().toLocaleString("default", {
                month: "long",
              }) +
                " " +
                new Date().getFullYear()}
              )
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.blue4}`,
          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {currentUser?.accountType === "admin"
                  ? "New Organisations"
                  : "Joining Requests "}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {currentUser?.accountType === "admin"
                  ? stats?.newOrganisations || 0
                  : stats?.requests || 0}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${theme.colors.alpha.trueWhite[100]}`,
                color: `${theme.colors.warning.main}`,
              }}
            >
              <AddReactionTwoTone fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              {currentUser?.accountType === "admin" ? (
                <>
                  {`This Month ${
                    new Date().toLocaleString("default", {
                      month: "long",
                    }) +
                    " " +
                    new Date().getFullYear()
                  }`}
                </>
              ) : (
                "Users who requested to join this month"
              )}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.green2}`,
          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {t("Form submissions")}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {stats?.totalFormSubmissions || 0}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              <StarTwoTone fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              This Month (
              {new Date().toLocaleString("default", {
                month: "long",
              }) +
                " " +
                new Date().getFullYear()}
              )
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.orange1}`,
          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                Average Score (~1000)
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {stats?.totalScore || 0}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`,
              }}
            >
              <AutoGraph fontSize="large" />
            </Avatar>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: `${theme.colors.alpha.trueWhite[70]}`,
              }}
            >
              {" "}
              This Month (
              {new Date().toLocaleString("default", {
                month: "long",
              }) +
                " " +
                new Date().getFullYear()}
              )
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
