import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";

export default function PanVerification({ handleClose }) {
  const { currentUser } = useContext(GlobalContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const [formInputs, setFormInputs] = useState({});
  const [isWait, setIsWait] = useState("");
  const [consent, setConsent] = useState(false);
  const [errors, setErrors] = useState({});

  const handleVerify = async () => {
    try {
      if (!formInputs?.pan_id || formInputs?.pan_id?.length !== 10)
        throw new Error("Please enter valid PAN Number");
      if (!formInputs?.name) throw new Error("Please enter valid Name");
      if (!formInputs?.date_of_birth)
        throw new Error("Please enter valid Date of Birth");
      if (!consent) throw new Error("Please check the consent box to proceed");

      setIsWait("pan");
      const response = await axiosInstance.post(`/gridlines/pan`, {
        pan_id: formInputs?.pan_id,
        name: formInputs?.name,
        date_of_birth: formInputs?.date_of_birth,
        userId: ["admin", "triager"].includes(currentUser?.accountType)
          ? profile?._id
          : null,
      });
      if (response.status === 200) {
        if (response.data?.data?.data?.code === "1001") {
          Notify.success("PAN Verified Successfully");
          setProfile({
            ...profile,
            cleanExitVerifications: {
              ...profile.cleanExitVerifications,
              pan: true,
            },
          });

          setFormInputs({});
          setConsent(false);
          handleClose(null);
        }
        if (response.data?.data?.data?.code === "1002") {
          Notify.warning("Partial Details Matched.");
          setErrors({
            name:
              response.data?.data?.data?.pan_data?.name_match_status ===
              "NO_MATCH"
                ? "Invalid Name"
                : "",
            dob:
              response.data?.data?.data?.pan_data?.dob_match_status ===
              "NO_MATCH"
                ? "Invalid Date of Birth"
                : "",
          });
        }
      }
      setIsWait("");
    } catch (error) {
      console.log(error);
      setIsWait("");
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <Stack spacing={4} my={2}>
      <TextField
        autoFocus
        label={"PAN Card Number"}
        fullWidth
        placeholder={"eg. DQXXXXXXXX"}
        value={formInputs?.pan_id || ""}
        onChange={(e) => {
          let _value = e.target.value;
          if (_value.length > 10) {
            Notify.warning("PAN Number should be 10 digits");
            return;
          }
          setFormInputs({ ...formInputs, pan_id: _value?.toUpperCase() });
        }}
      />
      <TextField
        label={"Full Name as per PAN Card"}
        fullWidth
        value={formInputs?.name || ""}
        onChange={(e) => {
          let _value = e.target.value;
          setFormInputs({ ...formInputs, name: _value?.toUpperCase() });
        }}
        helperText={
          <Typography color={"error"}>{errors?.name || ""}</Typography>
        }
      />
      <TextField
        label={"Date of Birth (YYYY-MM-DD)"}
        fullWidth
        placeholder={"eg. 1999-12-30"}
        value={formInputs?.date_of_birth || ""}
        onChange={(e) => {
          let _value = e.target.value;
          setFormInputs({ ...formInputs, date_of_birth: _value });
        }}
        helperText={
          <Typography color={"error"}>{errors?.dob || ""}</Typography>
        }
      />

      <FormControlLabel
        sx={{ mt: 1 }}
        control={
          <Checkbox
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            size="small"
          />
        }
        label={
          <Typography variant="body2" sx={{ color: "text.secondary", ml: 0.5 }}>
            I hereby confirm that the above detail(s) is/are correct
          </Typography>
        }
      />
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(null);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          onClick={handleVerify}
          loading={isWait === "pan"}
        >
          Verify Now
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
}
