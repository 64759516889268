import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";

export default function EmployeeInputFields() {
  const [newTagInput, setNewTagInput] = useState("");
  const [orgTags, setOrgTags] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const resp = await axiosInstance.get("/tags");
      if (resp?.status === 200) {
        setOrgTags(resp?.data || {});
      }
    })();
  }, []);

  const handleEditTag = async (reqObj) => {
    try {
      // reqObj = {action: "add/remove", type, inputString, tag: "tag to be removed"}
      setLoading(true);
      let _tags = [];
      const tagType = reqObj?.type;
      const inputString = reqObj?.inputString;
      if (reqObj?.action === "add") {
        _tags = [...orgTags[tagType], inputString];
      }
      if (reqObj?.action === "remove") {
        _tags = orgTags[tagType].filter((item) => item !== reqObj?.tag);
      }

      const reqParams = {
        type: tagType,
      };
      const resp = await axiosInstance.put(`/tags`, {
        ...reqParams,
        tags: _tags,
      });
      if (resp.status === 200) {
        setOrgTags({ ...orgTags, [tagType]: _tags });
        setLoading(false);
        setNewTagInput("");
      }
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  return (
    <Card sx={{ my: 2 }}>
      <CardHeader title="User Input Fields " />
      <Divider />

      <CardContent>
        <Box sx={{ my: 2 }} display="flex">
          <TextField
            fullWidth
            label={"Type Field name here"}
            type="text"
            variant="outlined"
            size="small"
            value={newTagInput}
            onChange={(e) => {
              setNewTagInput(e.target.value);
            }}
          />
          <LoadingButton
            size="small"
            sx={{ ml: 2 }}
            color="error"
            variant="contained"
            onClick={async () => {
              if (orgTags["users"]?.includes(newTagInput)) {
                Notify.warning("This Field already exists");
                return;
              }
              const done = await handleEditTag({
                action: "add",
                type: "users",
                inputString: newTagInput,
              });
              if (done) {
                setNewTagInput("");
              }
            }}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          <b>Default Fields: </b>Employee ID, First Name, Last Name, Email,
          Phone Number, Address, Designation, Department
        </Typography>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {orgTags["users"]?.map((item, idx) => (
            <ListItem
              key={idx}
              disableGutters
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
              secondaryAction={
                <>
                  <IconButton
                    color="error"
                    onClick={() => {
                      Confirm.show(
                        "Confirmation",
                        "Are you sure you want to delete this tag?",
                        "Confirm",
                        "Cancel",
                        () =>
                          handleEditTag({
                            action: "remove",
                            type: "users",
                            tag: item,
                          }),
                        () => {},
                        {
                          width: "500px",
                          messageMaxLength: 1000,
                        }
                      );
                    }}
                  >
                    <Delete />
                  </IconButton>
                </>
              }
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
