import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import {
  FORM_ACCESS_OBJECT,
  FORM_TYPES,
  PAGE_LIMIT,
} from "src/utils/constants";
import {
  AddTwoTone,
  CheckTwoTone,
  Close,
  DeleteTwoTone,
  FilterList,
  OpenInNew,
  SaveTwoTone,
  SportsScoreTwoTone,
} from "@mui/icons-material";
import Label from "src/components/Label";
import { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";
import EmptyHere from "src/components/EmptyHere";
import { dateDifferenceFromNow } from "src/utils/date-fns";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Link } from "react-router-dom";

export default function AddRemoveFormInCampaign({ event, setEvent }) {
  const [filterObj, setFilterObj] = useState({
    status: null,
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [forms, setForms] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isWait, setIsWait] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value + 1);
  };

  useEffect(() => {
    if (!open) return;

    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
          type: ["rating", "community"], // not exam/internal forms
          status: "published",
          organisation: "null",
        };
      }

      const resp = await axiosInstance.get("/forms", reqParams);
      if (resp?.status === 200) {
        setForms(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
        setDepartments(resp?.data?.departments || []);
        setIndustries(resp?.data?.industries || []);
      }
      setLoading(false);
    })();
  }, [open, page, filterObj]);

  const handleSaveForms = async (action, form) => {
    try {
      setIsWait(true);

      const resp = await axiosInstance.post(`/events/${event?._id}/forms`, {
        action,
        ...form,
      });
      if (resp?.status === 200) {
        setEvent(resp?.data?.data || {});
        handleClose();
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <Card sx={{ my: 2 }} elevation={0}>
      <Box>
        <CardHeader
          title="Rating Form"
          subheader="Form available to submit in this email campaign"
          action={
            event?.forms?.length === 0 && (
              <Button
                startIcon={<AddTwoTone />}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Add Form
              </Button>
            )
          }
        />
        <Divider />
        <Table>
          <TableBody>
            {event?.forms?.map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  component={Link}
                  to={`/dashboard/forms/${item?.formId?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="h4">{item?.formId?.title}</Typography>
                </TableCell>
                <TableCell align="right">
                  {item?.relations?.join(", ") || "-"}
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Button
                      component={Link}
                      to={`/dashboard/ratings?eventId=${event?._id}`}
                      startIcon={<OpenInNew />}
                    >
                      View Responses
                    </Button>
                    <IconButton
                      color="error"
                      onClick={() => {
                        handleSaveForms("remove", {
                          formId: item?.formId?._id,
                        });
                      }}
                    >
                      <DeleteTwoTone />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {event?.forms?.length === 0 && (
          <EmptyHere
            title="No Form found"
            subtitle="Add Form to this email campaign to send it to the recipients"
          />
        )}
      </Box>
      {open && (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>Select Forms</DialogTitle>
          <Box mx={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ pt: 3, pb: 2 }}
            >
              <Stack direction="row" spacing={2}>
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 500,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search here..."
                    inputProps={{ "aria-label": "search here" }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        if (e.target.value.length) {
                          setFilterObj({ ...filterObj, search: searchString });
                        } else {
                          delete filterObj.search;
                          setFilterObj({ ...filterObj, search: null });
                        }
                        setPage(1);
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value?.length > 0) {
                        setSearchString(e.target.value);
                      } else {
                        if (filterObj?.search) {
                          setFilterObj({
                            ...filterObj,
                            search: null,
                          });
                        }
                      }
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{
                      p: "10px",
                      m: 0.2,
                      bgcolor: "primary.main",
                      color: "white",
                    }}
                    onClick={() => {
                      setFilterObj({
                        ...filterObj,
                        search: searchString,
                      });
                      setPage(1);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  color="secondary"
                  startIcon={showFilter ? <Close /> : <FilterList />}
                  onClick={() => setShowFilter(!showFilter)}
                  size="small"
                >
                  {showFilter ? "Hide" : "Filter"}
                </Button>
              </Stack>
            </Stack>
            {showFilter && (
              <Stack direction={"row"} spacing={2}>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={industries || []}
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter by Industry" />
                  )}
                  onChange={(e, v) => {
                    setFilterObj({
                      ...filterObj,
                      industry: v,
                    });
                    setPage(1);
                  }}
                />
                <Autocomplete
                  disablePortal
                  size="small"
                  options={departments || []}
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter by Department" />
                  )}
                  onChange={(e, v) => {
                    setFilterObj({
                      ...filterObj,
                      department: v,
                    });
                    setPage(1);
                  }}
                />
                <FormControlLabel
                  checked={filterObj?.premium || false}
                  control={<Switch color="success" />}
                  label="Premium "
                  labelPlacement="start"
                  onChange={(e) => {
                    const _filterObj = { ...filterObj };
                    if (e.target.checked) {
                      _filterObj.premium = true;
                    } else {
                      delete _filterObj.premium;
                    }
                    setFilterObj(_filterObj);
                  }}
                />
              </Stack>
            )}
            <Box sx={{ my: 2 }}>
              {loading && <LinearProgress />}

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Premium</TableCell>
                      <TableCell>
                        Industry
                        <Typography>
                          <small>Department</small>
                        </Typography>
                      </TableCell>
                      <TableCell title="Who can submit this form!">
                        Permission (to submit)
                        <Typography>
                          <small>Form Type</small>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {forms?.map((row, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ maxWidth: "300px" }}>
                          <Stack direction="row">
                            <Chip
                              label={<b>{row?.title}</b>}
                              sx={{
                                border: 0,
                              }}
                              variant="outlined"
                            />
                            {row?.includeScore && (
                              <Tooltip title="This Form's Score will be included in User's Score.">
                                <sup>
                                  <small>
                                    <SportsScoreTwoTone
                                      sx={{
                                        fontSize: "1rem",
                                      }}
                                    />
                                  </small>
                                </sup>
                              </Tooltip>
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Label color={row?.premium ? "warning" : "success"}>
                            {row?.premium ? (
                              <b
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Premium
                              </b>
                            ) : (
                              "FREE"
                            )}
                          </Label>
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 200,
                            // ellipsis: true,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          title={row?.industry}
                        >
                          {row?.industry || "-"}
                          {row?.department && (
                            <Typography variant="caption" component={"p"}>
                              <small>{row?.department || ""}</small>
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Label
                            color={
                              FORM_ACCESS_OBJECT[row?.access]?.color || "info"
                            }
                          >
                            {row?.access?.toUpperCase()}
                          </Label>
                          <Typography variant="caption" mx={1} component={"p"}>
                            <small>{FORM_TYPES[row?.type]?.label || "-"}</small>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <LoadingButton
                            size="small"
                            onClick={() => {
                              handleSaveForms("add", {
                                formId: row?._id,
                              });
                            }}
                            startIcon={<AddTwoTone />}
                            loading={isWait}
                          >
                            Add
                          </LoadingButton>
                        </TableCell>
                      </TableRow>
                    ))}

                    {forms?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No Form found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {forms?.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalPages}
                    rowsPerPage={PAGE_LIMIT}
                    page={page - 1}
                    onPageChange={handlePageChange}
                  />
                )}
              </TableContainer>
            </Box>
          </Box>
        </Dialog>
      )}
    </Card>
  );
}
