/* eslint-disable jsx-a11y/accessible-emoji */
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  useTheme,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
      flex-grow: 1;
      height: 10px;
      
      &.MuiLinearProgress-root {
        background-color: ${theme.colors.alpha.black[10]};
      }
      
      .MuiLinearProgress-bar {
        border-radius: ${theme.general.borderRadiusXl};
      }
`
);

export default function AccountsStats({ stats }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Stack spacing={5}>
        <Typography variant="h3">
          Accounts Verification{" "}
          <Typography variant="subtitle2">
            New accounts ({stats?.newAccounts || 0}) vs Verified accounts (This
            Month)
          </Typography>
        </Typography>

        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              {t("Email Vertifications")}{" "}
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="span"
              >
                ({stats?.verification?.email || 0})
              </Typography>
            </Typography>
            <Typography variant="h5">
              {Math.ceil(
                ((stats?.verification?.email || 0) / stats?.newAccounts) * 100
              ) || 0}
              %
            </Typography>
          </Box>
          <LinearProgressWrapper
            value={
              Math.ceil(
                ((stats?.verification?.email || 0) / stats?.newAccounts) * 100
              ) || 0
            }
            color="primary"
            variant="determinate"
          />
        </Box>
        {/* // TODO: Undo Aadhaar Verification */}

        {/* <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              {t("Aadhaar Card")}{" "}
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="span"
              >
                ({stats?.verification?.aadhaar || 0})
              </Typography>
            </Typography>
            <Typography variant="h5">
              {Math.ceil(
                ((stats?.verification?.aadhaar || 0) / stats?.newAccounts) * 100
              ) || 0}
              %
            </Typography>
          </Box>
          <LinearProgressWrapper
            value={
              Math.ceil(
                ((stats?.verification?.aadhaar || 0) / stats?.newAccounts) * 100
              ) || 0
            }
            color="primary"
            variant="determinate"
          />
        </Box> */}
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              {t("PAN Card")}{" "}
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="span"
              >
                ({stats?.verification?.pan || 0})
              </Typography>
            </Typography>
            <Typography variant="h5">
              {Math.ceil(
                ((stats?.verification?.pan || 0) / stats?.newAccounts) * 100
              ) || 0}
              %
            </Typography>
          </Box>
          <LinearProgressWrapper
            value={
              Math.ceil(
                ((stats?.verification?.pan || 0) / stats?.newAccounts) * 100
              ) || 0
            }
            color="primary"
            variant="determinate"
          />
        </Box>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              {t("EthixFirst Certifications")}{" "}
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="span"
              >
                ({stats?.verification?.certified || 0})
              </Typography>
            </Typography>
            <Typography variant="h5">
              {Math.ceil(
                ((stats?.verification?.certified || 0) / stats?.newAccounts) *
                  100
              ) || 0}
              %
            </Typography>
          </Box>
          <LinearProgressWrapper
            value={
              Math.ceil(
                ((stats?.verification?.certified || 0) / stats?.newAccounts) *
                  100
              ) || 0
            }
            color="primary"
            variant="determinate"
          />
        </Box>
      </Stack>
    </Box>
  );
}
