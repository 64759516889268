import React, { useState } from "react";
import {
  EditTwoTone,
  ManageAccountsTwoTone,
  OpenInNew,
  PersonAddOutlined,
  PublishedWithChanges,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";

export default function EventBulkActions({ event, setEvent }) {
  const [isWait, setIsWait] = useState(false);

  const handleChangeEventStatus = async (status) => {
    try {
      if (event?.status === status) return;
      setIsWait(true);
      const resp = await axiosInstance.put(`/events/${event?._id}`, {
        status,
      });
      if (resp?.status === 200) {
        setEvent({
          ...event,
          status,
        });
        Notify.success("Status updated successfully");
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Card>
        {event?.audience === "internal" && (
          <CardHeader
            title="Actions"
            action={
              <Select
                size="small"
                disabled={isWait}
                value={event?.status}
                onChange={(e) => handleChangeEventStatus(e.target.value)}
              >
                <MenuItem value="published">Publish</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="completed">Complete</MenuItem>
                <MenuItem value="cancelled">Cancel</MenuItem>
              </Select>
            }
          />
        )}
        {event?.audience === "public" && event?.status !== "review" && (
          <CardHeader
            title="Actions"
            action={
              {
                draft: (
                  <Button
                    variant="outlined"
                    startIcon={<PublishedWithChanges />}
                    size="small"
                    onClick={() => handleChangeEventStatus("review")}
                  >
                    Send request to publish
                  </Button>
                ),
                published: (
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      onClick={() => handleChangeEventStatus("completed")}
                    >
                      Mark as completed
                    </Button>
                    <Button
                      color="error"
                      size="small"
                      onClick={() => handleChangeEventStatus("cancelled")}
                    >
                      Cancel event
                    </Button>
                  </Stack>
                ),
                completed: (
                  <Button
                    variant="outlined"
                    startIcon={<PublishedWithChanges />}
                    size="small"
                    onClick={() => handleChangeEventStatus("review")}
                  >
                    Send request to re-publish
                  </Button>
                ),
                cancelled: (
                  <Button
                    variant="outlined"
                    startIcon={<PublishedWithChanges />}
                    size="small"
                    onClick={() => handleChangeEventStatus("review")}
                  >
                    Send request to re-publish
                  </Button>
                ),
                rejected: (
                  <Button
                    variant="outlined"
                    startIcon={<PublishedWithChanges />}
                    size="small"
                    onClick={() => handleChangeEventStatus("review")}
                  >
                    Send request to review & publish
                  </Button>
                ),
              }[event?.status]
            }
          />
        )}
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Button
              fullWidth
              color="warning"
              variant="contained"
              endIcon={<OpenInNew />}
              component={Link}
              to={`/dashboard/events/${event?._id}/registrations`}
            >
              Registrations
            </Button>
            <Stack direction="row" spacing={2}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={<EditTwoTone />}
                component={Link}
                to={`/dashboard/events/${event?._id}/edit`}
              >
                Edit Event
              </Button>

              <Button
                fullWidth
                variant="outlined"
                component={Link}
                color="warning"
                to={`/dashboard/events/${event?._id}/forms`}
                startIcon={<ManageAccountsTwoTone />}
              >
                {event?.forms?.length > 0 ? "Manage" : "Add"} Forms
              </Button>
            </Stack>
            <Button
              fullWidth
              variant="outlined"
              color="info"
              component={Link}
              to={`/dashboard/events/${event?._id}/add-participant`}
              startIcon={<PersonAddOutlined />}
            >
              Add Participant(s)
            </Button>
            {/* <Button
              fullWidth
              variant="outlined"
              color="warning"
              startIcon={<GroupAddOutlined />}
            >
              Bulk Upload Participants
            </Button> */}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
