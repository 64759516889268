import { Check, GitHub, LinkedIn, Public, Twitter } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Label from "src/components/Label";
import SuspenseLoader from "src/components/SuspenseLoader";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";
import { MonthNames } from "src/utils/commonConstants";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const steps = ["General", "Other", "Socials"];

const StepSecondInput = ({
  eduInputs,
  handleEduInput,
  expInputs,
  setExpInputs,
  handleExpInput,
}) => {
  const [organisations, setOrganisations] = useState([]);

  return (
    <Stack spacing={6}>
      <Divider>
        <Label>
          <Typography variant="body2">Current Employment</Typography>
        </Label>
      </Divider>

      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
      >
        <TextField
          label="Title"
          placeholder="e.g. Software Engineer"
          fullWidth
          name="title"
          value={expInputs?.title || ""}
          onChange={handleExpInput}
        />
        <Autocomplete
          disablePortal
          freeSolo
          options={organisations || []}
          getOptionLabel={(option) => option?.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Organisation Name"
              onChange={async (e) => {
                // if (organisations?.length > 0) return;
                const reqParams = { params: { search: e.target.value } };
                const resp = await axiosInstance.get(
                  `/organisations/all`,
                  reqParams
                );
                if (resp?.status === 200) {
                  setOrganisations(resp?.data);
                }
              }}
            />
          )}
          noOptionsText="No organisations found"
          onChange={(e, value) => {
            setExpInputs({
              ...expInputs,
              organisation: value?.title,
            });
          }}
          inputValue={expInputs?.organisation || ""}
          onInputChange={(event, newInputValue) => {
            setExpInputs({
              ...expInputs,
              organisation: newInputValue,
            });
          }}
        />
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel>Employment Type</InputLabel>
            <Select
              value={expInputs?.employment_type || ""}
              label="Employment Type"
              name="employment_type"
              onChange={handleExpInput}
            >
              {[
                "Full Time",
                "Part Time",
                "Internship",
                "Freelance",
                "Self Employed",
                "Other",
              ].map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Location"
            placeholder="e.g. Hyderabad, India"
            fullWidth
            name="location"
            value={expInputs?.location || ""}
            onChange={handleExpInput}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel>Start Month</InputLabel>
            <Select
              value={expInputs?.start_month || ""}
              label="Start Month"
              name="start_month"
              onChange={handleExpInput}
            >
              {MonthNames.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Start Year</InputLabel>
            <Select
              value={expInputs?.start_year || ""}
              label="Start Year"
              name="start_year"
              onChange={handleExpInput}
            >
              {Array.from(
                { length: 70 },
                (_, i) => new Date().getFullYear() - i
              ).map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={expInputs?.currently_working}
              onChange={(e) =>
                setExpInputs({
                  ...expInputs,
                  currently_working: e.target.checked,
                })
              }
            />
          }
          label="I am currently working in this role"
        />
        {!expInputs?.currently_working && (
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <InputLabel>End Month</InputLabel>
              <Select
                value={expInputs?.end_month || ""}
                label="End Month"
                name="end_month"
                onChange={handleExpInput}
              >
                {MonthNames.map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>End Year</InputLabel>
              <Select
                value={expInputs?.end_year || ""}
                label="End Year"
                name="end_year"
                onChange={handleExpInput}
              >
                {Array.from(
                  { length: 70 },
                  (_, i) => new Date().getFullYear() - i
                ).map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>
      <Divider>
        <Label>
          <Typography variant="body2">Education</Typography>
        </Label>
      </Divider>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
      >
        <TextField
          label="Institution"
          placeholder="e.g. IIT Kharagpur"
          fullWidth
          name="title"
          value={eduInputs?.title || ""}
          onChange={handleEduInput}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            label="Degree"
            placeholder="e.g. B.Tech"
            fullWidth
            name="degree"
            value={eduInputs?.degree || ""}
            onChange={handleEduInput}
          />
          <TextField
            label="Field of Study"
            placeholder="e.g. Computer Science"
            fullWidth
            name="field_of_study"
            value={eduInputs?.field_of_study || ""}
            onChange={handleEduInput}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel>Start Month</InputLabel>
            <Select
              value={eduInputs?.start_month || ""}
              label="Start Month"
              name="start_month"
              onChange={handleEduInput}
            >
              {MonthNames.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Start Year</InputLabel>
            <Select
              value={eduInputs?.start_year || ""}
              label="Start Year"
              name="start_year"
              onChange={handleEduInput}
            >
              {Array.from(
                { length: 70 },
                (_, i) => new Date().getFullYear() - i
              ).map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel>End Month</InputLabel>
            <Select
              value={eduInputs?.end_month || ""}
              label="End Month"
              name="end_month"
              onChange={handleEduInput}
            >
              {MonthNames.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>End Year (or expected)</InputLabel>
            <Select
              value={eduInputs?.end_year || ""}
              label="End Year"
              name="end_year"
              onChange={handleEduInput}
            >
              {Array.from(
                { length: 70 },
                (_, i) => new Date().getFullYear() - i
              ).map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <TextField
          label="Grade / GPA / Percentage"
          fullWidth
          name="grade"
          value={eduInputs?.grade || ""}
          onChange={handleEduInput}
        />
      </Stack>
    </Stack>
  );
};

export default function EditProfileStepper() {
  const navigate = useNavigate();
  const [isWait, setIsWait] = useState(false);
  const { profile, loading, setProfile, portfolio, handleSavePortfolio } =
    useContext(ProfileContext);
  const [activeStep, setActiveStep] = useState(0);

  const [formInput, setFormInput] = useState({
    meta: {},
  });
  const [expInputs, setExpInputs] = useState({});
  const [eduInputs, setEduInputs] = useState({});
  const handleChangeInput = (e) => {
    setFormInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleExpInput = (e) => {
    setExpInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEduInput = (e) => {
    setEduInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (portfolio && portfolio?.length > 0) {
      const exp = portfolio.find(
        (item) => item.category === "experience" && item.highlighted
      );
      const edu = portfolio.find(
        (item) => item.category === "education" && item.highlighted
      );
      const _exp = { ...exp };
      if (_exp?.organisation) {
        _exp.organisation = _exp?.organisation?.title;
      }
      setExpInputs(_exp);
      setEduInputs(edu);
    }
  }, [portfolio]);

  useEffect(() => {
    if (profile) {
      setFormInput(profile);
    }
  }, [profile]);

  const handleSaveUser = async () => {
    try {
      if (!formInput?.firstName || !formInput?.lastName) {
        Notify.failure("Please fill all required fields");
        return;
      }

      setIsWait(true);

      delete formInput?.email;
      const resp = await axiosInstance.post("/users/profile", formInput);
      if (resp?.status === 200) {
        setProfile({
          ...profile,
          ...formInput,
        });
        if (activeStep === 0) setActiveStep((prev) => prev + 1);
        if (activeStep === 2) {
          navigate("/dashboard");
        }
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleSaveStep2 = async () => {
    try {
      if (!expInputs?.title && !eduInputs?.title) {
        setActiveStep((prev) => prev + 1);
        return;
      }
      setIsWait(true);
      expInputs.highlighted = true;
      expInputs.category = "experience";
      const done1 = await handleSavePortfolio(expInputs);
      eduInputs.highlighted = true;
      eduInputs.category = "education";
      const done2 = await handleSavePortfolio(eduInputs);
      setIsWait(false);
      if (done1 && done2) {
        setActiveStep((prev) => prev + 1);
      }
    } catch (error) {
    } finally {
      setIsWait(false);
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      handleSaveUser();
    }
    if (activeStep === 1) {
      handleSaveStep2();
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box my={2}>
      <Card>
        <CardContent>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <Stack spacing={{ xs: 2, md: 3 }}>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="First Name*"
                  name="firstName"
                  value={formInput?.firstName || ""}
                  onChange={handleChangeInput}
                />
                <TextField
                  fullWidth
                  label="Last Name*"
                  name="lastName"
                  value={formInput?.lastName || ""}
                  onChange={handleChangeInput}
                />
              </Stack>

              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  value={formInput?.phoneNumber || ""}
                  onChange={handleChangeInput}
                />
                <TextField
                  fullWidth
                  label="Location"
                  name="location"
                  placeholder="Enter City, Country"
                  value={formInput?.meta?.location || ""}
                  onChange={(e) => {
                    setFormInput((prev) => ({
                      ...prev,
                      meta: {
                        ...prev.meta,
                        location: e.target.value,
                      },
                    }));
                  }}
                />
              </Stack>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  placeholder="What defines you best? (e.g. Software Engineer, IT, Sales Manager, Chartered Accountant, Chef, etc.)"
                  value={formInput?.meta?.headline || ""}
                  onChange={(e) => {
                    setFormInput((prev) => ({
                      ...prev,
                      meta: {
                        ...prev.meta,
                        headline: e.target.value,
                      },
                    }));
                  }}
                />
                <TextField
                  fullWidth
                  label="Bio (About)"
                  name="about"
                  value={formInput?.meta?.about || ""}
                  onChange={(e) => {
                    if (e.target.value.length > 500) {
                      Notify.warning(
                        "Bio should not be more than 200 characters"
                      );
                      return;
                    }
                    setFormInput((prev) => ({
                      ...prev,
                      meta: {
                        ...prev.meta,
                        about: e.target.value,
                      },
                    }));
                  }}
                  multiline
                  rows={4}
                />
              </Stack>
            </Stack>
          )}
          {activeStep === 1 && (
            <StepSecondInput
              expInputs={expInputs}
              setExpInputs={setExpInputs}
              handleExpInput={handleExpInput}
              eduInputs={eduInputs}
              setEduInputs={setEduInputs}
              handleEduInput={handleEduInput}
            />
          )}
          {activeStep === 2 && (
            <Stack
              sx={{
                mt: 2,
                width: "70%",
              }}
              spacing={2}
            >
              <TextField
                placeholder="Personal Website. e.g. https://www.site.domain"
                fullWidth
                name="website"
                value={formInput?.meta?.website || ""}
                onChange={(e) => {
                  setFormInput((prev) => ({
                    ...prev,
                    meta: {
                      ...prev.meta,
                      website: e.target.value,
                    },
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Public />
                    </InputAdornment>
                  ),
                }}
              />{" "}
              <TextField
                placeholder="username"
                fullWidth
                name="linkedin"
                value={formInput?.meta?.linkedin || ""}
                onChange={(e) => {
                  setFormInput((prev) => ({
                    ...prev,
                    meta: {
                      ...prev.meta,
                      linkedin: e.target.value,
                    },
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button startIcon={<LinkedIn />}>
                        http://linkedin.com/
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                placeholder="username"
                fullWidth
                name="github"
                value={formInput?.meta?.github || ""}
                onChange={(e) => {
                  setFormInput((prev) => ({
                    ...prev,
                    meta: {
                      ...prev.meta,
                      github: e.target.value,
                    },
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button startIcon={<GitHub />}>
                        https://github.com/
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                placeholder="username"
                fullWidth
                name="twitter"
                value={formInput?.meta?.twitter || ""}
                onChange={(e) => {
                  setFormInput((prev) => ({
                    ...prev,
                    meta: {
                      ...prev.meta,
                      twitter: e.target.value,
                    },
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button startIcon={<Twitter />}>
                        https://twitter.com/
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
          <div>
            <Fragment>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0 || isWait}
                  onClick={() => setActiveStep((prev) => prev - 1)}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === steps?.length - 1 ? (
                  <Button disabled={isWait} onClick={handleSaveUser}>
                    Finish & Save
                  </Button>
                ) : (
                  <Button onClick={handleNext} sx={{ mr: 1 }} disabled={isWait}>
                    Next
                  </Button>
                )}
              </Box>
            </Fragment>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}
