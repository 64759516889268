import {
  Box,
  Card,
  Avatar,
  Typography,
  styled,
  useTheme,
  Container,
  CardContent,
  Divider,
  ListItemButton,
  alpha,
  Button,
  Grid,
  CardActionArea,
  Stack,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import Label from "src/components/Label";
import { GitHub, LinkedIn, Public, Twitter } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import QrCodeLinker from "../common/QrCodeLinker";
import { GlobalContext } from "src/contexts/GlobalContext";

function OrgPublicProfile() {
  const theme = useTheme();
  const { organisationId } = useParams();
  const { currentUser } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();

  const getOrgProfile = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/organisations/${organisationId}`);
      if (resp?.status === 200) {
        setProfile(resp?.data);
      }
      setLoading(false);
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    getOrgProfile();
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Container maxWidth="md">
      <Card
        sx={{
          position: "relative",
          m: 3,
        }}
        variant="outlined"
      >
        <CardContent
          sx={{
            background: (theme) => `${alpha(theme.colors.secondary.main, 0.1)}`,
            p: 5,
            pb: 12,
          }}
        >
          <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
            <Avatar
              variant="rounded"
              sx={{
                width: 80,
                height: 80,
              }}
              src={
                profile?.data?.avatar
                  ? process.env.REACT_APP_DO_SPACE_URL +
                    (profile?.data?.avatar + "?" + Math.random())
                  : ""
              }
            />
            <Box>
              <Typography variant="h3">{profile?.data?.title}</Typography>
              <Typography gutterBottom variant="subtitle2">
                {profile?.data?.companyWebsite}
              </Typography>
            </Box>
          </Stack>

          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            {profile?.data?.description}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <List disablePadding sx={{ my: 2 }}>
            <ListItem disableGutters>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={"Organisation Email: "}
              />
              <Typography variant="subtitle1">
                {profile?.data?.companyEmail}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={"Location: "}
              />
              <Typography variant="subtitle1">
                {[
                  profile?.data?.address?.city,
                  profile?.data?.address?.state,
                  profile?.data?.address?.country,
                  profile?.data?.address?.pincode,
                ]
                  .filter((item) => item)
                  .join(", ") || "-"}
              </Typography>
            </ListItem>{" "}
            <ListItem disableGutters>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={"Industry: "}
              />
              <Typography variant="subtitle1" textAlign={"right"}>
                {profile?.data?.industry?.join(", ") || "-"}
              </Typography>
            </ListItem>
          </List>
        </CardContent>
        <Box sx={{ position: "relative" }}>
          <Card
            sx={{
              width: currentUser?.orgId === organisationId ? "400px" : "210px",
              height: "210px",
              position: "absolute",
              left: "50%",
              top: "-105px",
              transform: "translateX(-50%)",
            }}
          >
            <QrCodeLinker
              accountType={"org"}
              accountId={profile?.data?._id}
              whichPage={"org-public-profile"}
            />
          </Card>
        </Box>
        <Box sx={{ height: "105px" }}></Box>
        <CardContent>
          <Box p={3}>
            <Grid container spacing={3}>
              {[
                {
                  label: "Website",
                  value: profile?.data?.meta?.website,
                  icon: <Public fontSize="large" />,
                  link: "",
                  color: "blue3",
                },
                {
                  label: "LinkedIn",
                  value: profile?.data?.meta?.linkedin,
                  icon: <LinkedIn fontSize="large" />,
                  link: "https://linkedin.com/",
                  color: "blue4",
                },
                {
                  label: "GitHub",
                  value: profile?.data?.meta?.github,
                  icon: <GitHub fontSize="large" />,
                  link: "https://github.com/",
                  color: "purple3",
                },
                {
                  label: "Twitter",
                  value: profile?.data?.meta?.twitter,
                  icon: <Twitter fontSize="large" />,
                  link: "https://twitter.com/",
                  color: "black2",
                },
              ]?.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card
                    variant="outlined"
                    sx={{
                      background: `${theme.colors.gradients[item.color]}`,
                      color: `${
                        theme.colors.alpha.trueWhite[item.value ? 100 : 70]
                      }`,
                    }}
                  >
                    <CardActionAreaWrapper
                      sx={{ p: 2 }}
                      component={item?.value ? "a" : "span"}
                      href={item?.link + item?.value}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {item?.icon}
                      <Typography gutterBottom variant="h4">
                        {item?.value || "-"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: `${theme.colors.alpha.trueWhite[70]}`,
                        }}
                        noWrap
                      >
                        {item?.label}
                      </Typography>
                    </CardActionAreaWrapper>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default OrgPublicProfile;

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
         .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: ${theme.colors.primary.main};
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: .05;
          }
        }
  `
);
