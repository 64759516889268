import { LoadingButton } from "@mui/lab";
import { Alert, Card, Stack } from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";

// just below user's profile summary (UserSummary.js)
export default function NotifyPlaceholder1() {
  const { globalMetadata, handleGetGlobalMetadata } = useContext(GlobalContext);
  const [notifyPlaceholder1, setNotifyPlaceholder1] = useState([]);

  useEffect(() => {
    if (!globalMetadata) return;
    if (!globalMetadata?.notifyPlaceholder1) return;
    setNotifyPlaceholder1(globalMetadata?.notifyPlaceholder1 || []);
  }, [globalMetadata]);

  const [isWait, setIsWait] = useState(false);

  const handleConfirmChangeUpdateStatus = async (customerRelation, status) => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.put(
        `/customer-relations/${customerRelation?._id}`,
        {
          status,
        }
      );
      if (resp?.status === 200) {
        await handleGetGlobalMetadata();
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleChangeUpdateStatus = async (customerRelation, status) => {
    Confirm.show(
      "Confirmation",
      "Are you sure you want to change the status?",
      "Confirm",
      "Cancel",
      () => handleConfirmChangeUpdateStatus(customerRelation, status),
      () => {},
      {
        width: "500px",
        messageMaxLength: 1000,
      }
    );
  };

  return (
    notifyPlaceholder1?.length > 0 && (
      <Card elevation={0}>
        <Stack direction="column" m={1} spacing={1}>
          {notifyPlaceholder1?.map((item, idx) => (
            <Alert
              key={idx}
              severity="warning"
              action={
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <LoadingButton
                    color="error"
                    variant="outlined"
                    size="small"
                    onClick={() => handleChangeUpdateStatus(item, "rejected")}
                    loading={isWait}
                  >
                    Reject
                  </LoadingButton>
                  <LoadingButton
                    color="success"
                    size="small"
                    variant="contained"
                    onClick={() => handleChangeUpdateStatus(item, "accepted")}
                    loading={isWait}
                  >
                    Accept
                  </LoadingButton>
                </Stack>
              }
            >
              <b>{item?.organisation?.title}</b> has requested you to join their
              organisation as <b>{item?.relation?.toUpperCase() || ""}</b>
            </Alert>
          ))}
        </Stack>
      </Card>
    )
  );
}
