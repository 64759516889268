import { Search } from "@mui/icons-material";
import {
  Box,
  Pagination,
  Typography,
  Stack,
  Paper,
  InputBase,
  IconButton,
  CardContent,
  Divider,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Checkbox,
  TableHead,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { Fragment, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";

export default function SearchAndSelectUser({
  selectedUsers,
  handleSelectUser,
  children,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useContext(GlobalContext);
  const pageParam = searchParams.get("page");
  const [users, setUsers] = useState({
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  const handleSearch = async () => {
    try {
      if (searchString && searchString?.length < 3) {
        return;
      }

      setLoading(true);
      const reqParams = {
        params: {
          limit: PAGE_LIMIT,
          skip: (page - 1) * PAGE_LIMIT,
          search: searchString,
        },
      };

      const resp = await axiosInstance.get(`/users/managed`, reqParams);
      if (resp?.status === 200) {
        setUsers(resp?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <Box>
      <CardContent>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by EthixFirst ID, email, name, phone number, aadhaar, pan, address"
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (searchString?.length < 3) {
                  return;
                }
                handleSearch();
                setPage(1);
              }
            }}
            onChange={(e) => {
              if (e.target.value?.length > 0) {
                setSearchString(e.target.value);
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              m: 0.2,
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              if (searchString?.length < 3) return;
              handleSearch();

              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          >
            <Search />
          </IconButton>
        </Paper>
      </CardContent>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>Aadhaar </TableCell>
            <TableCell>PAN </TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.data?.filter((item) => item?._id !== currentUser?.userId)
            ?.length > 0 &&
            users?.data
              ?.filter((item) => item?._id !== currentUser?.userId)
              ?.map((row, index) => (
                <Fragment key={index}>
                  <TableRow
                    sx={{
                      color: row?.isLocked && "red",
                      textDecoration: row?.isLocked && "line-through",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell sx={{ width: "5px" }}>
                      <Checkbox
                        checked={selectedUsers?.includes(row?._id)}
                        onChange={() => handleSelectUser(row?._id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={700}>
                        {row?.firstName + " " + row?.lastName || ""}
                      </Typography>
                      <Typography>
                        <small>{row?.cleanExitUserId || "-"}</small>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row?.email}
                      <br />
                      +91-{row?.phoneNumber}
                    </TableCell>
                    <TableCell>{row?.aadhaarNumber || "-"}</TableCell>
                    <TableCell>{row?.panNumber || "-"}</TableCell>
                    <TableCell>
                      {(() => {
                        const address = [
                          row?.address?.city,
                          row?.address?.state,
                        ]?.filter((a) => a);

                        return address?.length > 0 ? address.join(", ") : "-";
                      })()}
                      {row?.address?.pincode && (
                        <Typography>
                          <small>{row?.address?.pincode}</small>
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
        </TableBody>
      </Table>
      {users?.data?.length === 0 && (
        <EmptyHere title={"No users found"} subtitle={" "} />
      )}

      {!loading &&
        users?.data?.filter((item) => item?._id !== currentUser?.userId)
          ?.length > 0 && (
          <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
            <Stack spacing={2}>
              <Pagination
                count={users?.totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </Box>
        )}
      {children}
    </Box>
  );
}
