import { Container } from "@mui/material";
import React from "react";
import UserProfile from "src/content/dashboards/profile/UserProfile";

export default function UserPublicProfile() {
  return (
    <Container maxWidth="lg">
      <UserProfile />
    </Container>
  );
}
