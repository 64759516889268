import {
  Button,
  TextField,
  Card,
  Box,
  Stack,
  styled,
  Divider,
  Typography,
  CardHeader,
  Chip,
  Alert,
  Autocomplete,
  capitalize,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBackIos, SaveTwoTone } from "@mui/icons-material";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Link, useSearchParams } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import QuillTextarea from "src/components/ReactQuill";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function AddEditCampaign({ event }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const organisationParam = searchParams.get("organisation");
  const navigator = useNavigate();
  let { campaignId } = useParams();
  const { getTagsByType } = useContext(GlobalContext);
  const [formInput, setFormInput] = useState({
    address: {},
  });
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [customerRelations, setCustomerRelations] = useState([]);

  const handleInputChange = (e) => {
    setFormInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  useEffect(() => {
    if (event) {
      setFormInput(event);
    } else {
      setFormInput({
        address: {},
        description: `<p>Hi there, </p><p><br></p><p>I hope this email finds you well.</p><p><br></p><p>We would like to inform you that you have been granted access to<strong> submit a form</strong>. Your prompt attention to this matter is appreciated.</p><p>Please take a moment to access the form using the provided link: <strong>link_here</strong></p><p>Your feedback is valuable to us, and we look forward to receiving your submission.</p><p><br></p><p>If you have any questions or encounter any issues during the submission process, feel free to reach out to our support team.</p><p>Thank you for your cooperation.</p><p><br></p><p>Best regards,</p><p><strong>EthixFirst</strong></p>`,
      });
    }
    setLoading(false);
  }, [event]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formInput?.title) return Notify.warning("Title  is required");
      if (!formInput?.contactEmail)
        return Notify.warning("Contact email is required");
      if (!formInput?.contactPhone)
        return Notify.warning("Contact phone is required");
      if (!formInput?.description)
        return Notify.warning("Email subject is required");
      if (!formInput?.relations?.length)
        return Notify.warning("Relations are required");

      setIsSubmitting(true);

      formInput.type = "campaign";
      if (campaignId) {
        const resp = await axiosInstance.put(`/events/${campaignId}`, {
          ...formInput,
        });
        if (resp?.status === 200) {
          Notify.success(resp?.data?.message || "success");
        }
      } else {
        if (organisationParam) {
          formInput.organisation = organisationParam;
        }

        const resp = await axiosInstance.post(`/events`, formInput);
        if (resp?.status === 201) {
          Notify.success("Created successfully");
          setFormInput({});
          navigator(`/dashboard/campaigns/${resp?.data?.data?._id}`);
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card sx={{ my: 2 }}>
      <CardHeader
        sx={{
          p: 3,
        }}
        title={
          <Typography variant="h4" gutterBottom>
            {t("Manage Bulk Email")}
          </Typography>
        }
      />
      <Divider />
      <Stack sx={{ p: 2 }} spacing={2}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label={"Title (Email Subject)*"}
            name="title"
            onChange={handleInputChange}
            value={formInput?.title || ""}
          />
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label={t("Contact Email*")}
              name="contactEmail"
              onChange={handleInputChange}
              value={formInput?.contactEmail || ""}
            />

            <TextField
              fullWidth
              label={t("Contact Phone*")}
              name="contactPhone"
              onChange={handleInputChange}
              value={formInput?.contactPhone || ""}
            />
          </Stack>
        </Stack>
        <Autocomplete
          fullWidth
          multiple
          options={customerRelations || []}
          getOptionLabel={(option) => capitalize(option + "")}
          value={formInput?.relations || []}
          onChange={(e, value) => {
            setFormInput({
              ...formInput,
              relations: value,
            });
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label="Select Relations"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
              <FormHelperText>
                Select the relations that will be included in this campaign
              </FormHelperText>
            </>
          )}
        />

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            sx={{ mb: 2, width: "25%" }}
            placeholder="Add and press enter to add tags"
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                setFormInput({
                  ...formInput,
                  tags: [...(formInput?.tags || []), newTag],
                });
                setNewTag("");
              }
            }}
            value={newTag}
          />
          <Box sx={{ width: "75%" }}>
            {formInput?.tags?.length > 0 ? (
              formInput?.tags?.map((tag) => (
                <ChipWrapper
                  key={tag}
                  label={tag}
                  onDelete={() => {
                    setFormInput({
                      ...formInput,
                      tags: formInput?.tags?.filter((item) => item !== tag),
                    });
                  }}
                />
              ))
            ) : (
              <Typography variant="body2" color="text.secondary" gutterBottom>
                No tags added yet
              </Typography>
            )}
          </Box>
        </Stack>
        <FormHelperText variant="h6">Email Body</FormHelperText>
        <QuillTextarea
          placeholder="Email Body. write your email body here... It'll be used to send email to the recipients"
          onChange={(value) => {
            setFormInput({
              ...formInput,
              description: value,
            });
          }}
          value={formInput?.description || ""}
        />
        <Alert severity="warning">
          Please note: You've to write "<b>link_here</b>" in the email body
          where you want to insert the link to the Form.
        </Alert>

        {!formInput?.description?.includes("link_here") && (
          <Alert severity="error">
            Warning: You email body does not contain the "<b>link_here</b>" tag.
            Please note if you don't write "<b>link_here</b>" in the email body,
            the receiver won't be able to get and submit the form.
          </Alert>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box>
            {formInput?._id && (
              <Button
                startIcon={<ArrowBackIos />}
                variant="text"
                component={Link}
                to={`/dashboard/campaigns/${campaignId}`}
              >
                {t("go back")}
              </Button>
            )}
          </Box>
          <LoadingButton
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={<SaveTwoTone />}
            disabled={
              isSubmitting || !formInput?.description?.includes("link_here")
            }
            type="submit"
            size="large"
            variant="contained"
            onClick={handleFormSubmit}
          >
            {t(formInput?._id ? "Update " : "Create ")}
          </LoadingButton>
        </Box>
      </Stack>
    </Card>
  );
}

const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      font-weight: bold;
      margin: ${theme.spacing(0.5)};
`
);
