import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import {
  CheckCircleOutline,
  FmdGood,
  GitHub,
  LinkedIn,
  Public,
  Twitter,
} from "@mui/icons-material";
import { ProfileContext } from "src/contexts/ProfileContext";
import ListCustom from "src/components/List";
import Label from "src/components/Label";

export default function GeneralSection() {
  const { profile } = useContext(ProfileContext);

  return (
    <Card
      elevation={0}
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: (theme) => theme.palette.info.dark,
          color: "white",
          p: 1,
        }}
        title={
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <CheckCircleOutline />
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              General Information
            </Typography>
          </Stack>
        }
      />
      <CardContent>
        {" "}
        <Typography color={"warning.main"} fontStyle={"italic"}>
          This section contains the general information of the user.
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <List>
              {[
                {
                  label: "Full Name",
                  value:
                    (profile?.firstName || "-") +
                    " " +
                    (profile?.lastName || ""),
                },
                {
                  label: "Email Address",
                  value: profile?.email || "-",
                },
                {
                  label: "Headline",
                  value: profile?.meta?.headline || "-",
                },
                {
                  label: "Phone Number",
                  value: profile?.phone || "-",
                },
                {
                  label: "Address",
                  value: profile?.address?.address1
                    ? profile?.address?.address1
                    : [
                        profile?.address?.city,
                        profile?.address?.state,
                        profile?.address?.country,
                        profile?.address?.pincode,
                      ]
                        .filter((item) => item)
                        .join(", ") || "-",
                },
              ].map((item, index) => (
                <ListCustom
                  key={index}
                  item={item}
                  rightStyle={{
                    fontWeight: "bold",
                  }}
                  leftStyle={{
                    className: "link-color",
                    width: 130,
                  }}
                />
              ))}
            </List>
          </Grid>
          <Grid item xs={5}>
            <List>
              {[
                {
                  label: "Account Status",
                  value: (
                    <Label color={profile?.isLocked ? "error" : "success"}>
                      <b>{profile?.isLocked ? "Deactivated" : "Active"}</b>
                    </Label>
                  ),
                },
                {
                  label: "Email Verification",
                  value: (
                    <Label color={profile?.isVerified ? "success" : "error"}>
                      <b>{profile?.isVerified ? "Verified" : "Unverified"}</b>
                    </Label>
                  ),
                },
                {
                  label: "EthixFirst Certification",
                  value: (
                    <Label
                      color={
                        profile?.crediblCertification?.certified
                          ? "success"
                          : "error"
                      }
                    >
                      <b>
                        {profile?.crediblCertification?.certified
                          ? "Certified"
                          : "Not Certified Yet"}
                      </b>
                    </Label>
                  ),
                },
                // TODO: Undo Aadhaar Verification

                // {
                //   label: "Aadhaar Verification",
                //   value: (
                //     <Label
                //       color={
                //         profile?.cleanExitVerifications?.aadhaar
                //           ? "success"
                //           : "warning"
                //       }
                //     >
                //       <b>
                //         {profile?.cleanExitVerifications?.aadhaar
                //           ? "Verified"
                //           : "Verification Pending"}
                //       </b>
                //     </Label>
                //   ),
                // },
                {
                  label: "PAN Verification",
                  value: (
                    <Label
                      color={
                        profile?.cleanExitVerifications?.pan
                          ? "success"
                          : "warning"
                      }
                    >
                      <b>
                        {profile?.cleanExitVerifications?.pan
                          ? "Verified"
                          : "Verification Pending"}
                      </b>
                    </Label>
                  ),
                },
              ].map((item, index) => (
                <ListCustom
                  key={index}
                  item={item}
                  rightStyle={{
                    fontWeight: "bold",
                    width: 180,
                  }}
                  leftStyle={{
                    className: "link-color",
                    width: 160,
                  }}
                />
              ))}
            </List>
          </Grid>
          <Grid item xs={3}>
            <List>
              {[
                {
                  label: "",
                  value: `${profile?.address?.city || "-"}${
                    profile?.address?.state ? "," : ""
                  } ${profile?.address?.state || ""}`,
                  icon: <FmdGood />,
                  link: "https://www.google.com/maps/place/",
                },
                {
                  label: "",
                  value: profile?.meta?.website || "-",
                  icon: <Public />,
                  link: profile?.meta?.website || "-",
                },
                {
                  label: "",
                  value: profile?.meta?.linkedin || "-",
                  icon: <LinkedIn />,
                  link: "https://linkedin.com/",
                },
                {
                  label: "",
                  value: profile?.meta?.github || "-",
                  icon: <GitHub />,
                  link: "https://github.com/",
                },
                {
                  label: "",
                  value: profile?.meta?.twitter || "-",
                  icon: <Twitter />,
                  link: "https://twitter.com/",
                },
              ].map(
                (item, index) =>
                  item?.value && (
                    <ListCustom
                      item={{
                        label: item.label,
                        value: item?.value?.replace(/(^\w+:|^)\/\//, ""),
                        icon: item.icon,
                        link: item.link,
                      }}
                      key={index}
                      width={80}
                      leftStyle={{
                        className: "link-color",
                        width: 0,
                      }}
                      iconStyle={{
                        sx: {
                          width: 10,
                          minWidth: 30,
                          color: "#289BCC",
                        },
                      }}
                    />
                  )
              )}
            </List>
          </Grid>
        </Grid>
        <Typography mt={2} color="secondary.main" variant="subtitle2">
          <span className="link-color">Bio - </span>{" "}
          {profile?.meta?.about || "N/A"}
        </Typography>
      </CardContent>
    </Card>
  );
}
