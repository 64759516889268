import {
  Card,
  Box,
  Typography,
  Divider,
  ListSubheader,
  List,
  styled,
  Stack,
  alpha,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "src/contexts/ProfileContext";
import { useContext } from "react";
import EmptyHere from "src/components/EmptyHere";
import { fDate } from "src/utils/date-fns";
import { StarRateOutlined } from "@mui/icons-material";
import { calculateFinalScore } from "src/utils/helpers";

const BoxItemWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    position: relative;
    padding: ${theme.spacing(2)};
    width: 100%;
    
    &::before {
      content: '.';
      background: ${theme.colors.success.main};
      color: ${theme.colors.success.main};
      border-radius: ${theme.general.borderRadius};
      position: absolute;
      text-align: center;
      width: 6px;
      left: 0;
      height: 100%;
      top: 0;
    }
    
    &.wrapper-info {
      &:before {
        background: ${theme.colors.info.main};
        color: ${theme.colors.info.main};
      }
    }
        
    &.wrapper-warning {
      &:before {
        background: ${theme.colors.warning.main};
        color: ${theme.colors.warning.main};
      }
    }

    &.wrapper-error {
      &:before {
        background: ${theme.colors.error.main};
        color: ${theme.colors.error.main};
      }
    }

    &.wrapper-secondary {
      &:before {
        background: ${theme.colors.secondary.main};
        color: ${theme.colors.secondary.main};
      }
    }
`
);

export default function RatingSection() {
  const { t } = useTranslation();
  const { ratings } = useContext(ProfileContext);

  return (
    <Card
      elevation={0}
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: (theme) => theme.palette.info.dark,
          color: "white",
          p: 1,
        }}
        title={
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <StarRateOutlined />
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              Rating(s) added by Organisations
            </Typography>
          </Stack>
        }
      />
      <CardContent>
        <Box mb={3}>
          <Typography color={"warning.main"} fontStyle={"italic"}>
            This section contains the ratings added by Organisations and color
            coded based on the score.
          </Typography>
          <Grid container sx={{ mt: 1 }}>
            {[
              { color: "success", label: "Exceptional (751 - 1000)" },
              { color: "warning", label: "Above Average  (501 - 750)" },
              { color: "error", label: "Average  (251 - 500)" },
              { color: "secondary", label: "Needs Improvement  (0 - 250)" },
            ]?.map((item, idx) => (
              <Grid
                item
                key={idx}
                xs={3}
                display={"flex"}
                alignItems={"center"}
              >
                <Stack
                  color={item.color}
                  sx={{
                    width: 15,
                    height: 15,
                    backgroundColor: (theme) => theme.palette[item.color].main,
                    borderRadius: 1,
                    mr: 2,
                  }}
                />
                <Typography variant="caption">{item.label}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack spacing={2}>
          {ratings.length > 0 ? (
            ratings
              ?.sort((a, b) =>
                a?.organisationDetails?.title?.localeCompare(
                  b?.organisationDetails?.title
                )
              )
              ?.map((item, idx) => {
                const score =
                  item?.ratings
                    ?.reduce((acc, rating) => acc + rating?.score, 0)
                    ?.toFixed(0) || 0;

                const weightage =
                  item?.ratings
                    ?.reduce((acc, rating) => acc + rating?.weightage, 0)
                    ?.toFixed(0) || 0;

                const finaScore = calculateFinalScore({
                  score,
                  weightage,
                });

                return (
                  <List
                    component={Card}
                    elevation={0}
                    key={idx}
                    disablePadding
                    sx={{
                      position: "relative",
                      width: "100%",
                      border: (theme) =>
                        `1px solid ${alpha(
                          theme.palette[finaScore?.color || "success"]?.dark,
                          0.4
                        )}`,
                    }}
                  >
                    <ListSubheader
                      component="div"
                      color="primary"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 1,
                        backgroundColor: (theme) =>
                          alpha(
                            theme.palette[finaScore?.color || "success"]?.dark,
                            0.2
                          ),
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography>
                          <Typography
                            variant="h3"
                            sx={{
                              fontSize: "1.4rem",
                            }}
                          >
                            {item?.organisationDetails?.title}
                          </Typography>
                          {item?.organisationDetails?.companyWebsite && (
                            <Typography
                              variant="h6"
                              component={"a"}
                              href={
                                "https://" +
                                item?.organisationDetails?.companyWebsite?.trim()
                              }
                              target="_blank"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              {item?.organisationDetails?.companyWebsite}
                            </Typography>
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        sx={{
                          textAlign: "right",
                        }}
                      >
                        <Typography variant="subtitle2">
                          <b>Grand Score: {finaScore?.score}</b> in{" "}
                          <b>{item?.ratings?.length || 0}</b> Feedback(s)
                        </Typography>
                      </Stack>
                    </ListSubheader>
                    <Divider />
                    {item?.ratings
                      ?.sort(
                        (a, b) =>
                          new Date(b?.createdAt) - new Date(a?.createdAt)
                      )
                      ?.map((rating, index) => (
                        <Stack key={index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={"space-between"}
                            px={2}
                            mt={1}
                          >
                            <BoxItemWrapper
                              className={`wrapper-${
                                calculateFinalScore({
                                  score: rating?.score,
                                  weightage: rating?.weightage,
                                })?.color || "success"
                              }`}
                            >
                              <Typography variant="h6">
                                <b>{rating?.title} </b> - by{" "}
                                {rating?.submitBy?.firstName || "-"}{" "}
                                {rating?.submitBy?.lastName || ""}
                              </Typography>
                              <small>On {fDate(rating?.createdAt)}</small>
                            </BoxItemWrapper>

                            <Stack>
                              <Typography
                                sx={{ pl: 2.3, py: 1 }}
                                whiteSpace={"nowrap"}
                                textAlign={"right"}
                              >
                                <span>
                                  Score -{" "}
                                  <b>
                                    {
                                      calculateFinalScore({
                                        score: rating?.score,
                                        weightage: rating?.weightage,
                                      })?.score
                                    }
                                  </b>
                                </span>
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      ))}

                    <Divider sx={{ mt: 2 }} />
                  </List>
                );
              })
          ) : (
            <EmptyHere title={t("No Organisation Ratings yet")} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
