import React, { useContext, useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
import SaveTwoTone from "@mui/icons-material/SaveTwoTone";
import { Confirm, Notify } from "notiflix";
import { FormContext } from "src/contexts/FormContext";
import axiosInstance from "src/utils/axiosInstance";

import { Box, Tooltip, Typography } from "@mui/material";

function EditableParameter({ isWeightVisible, parameter, weightage }) {
  const {
    form,
    handleGetForm,
    selectedQuestionGroup,
    currentSelectedItemType,
    setCurrentSelectedItemType,
    setSelectedQuestionGroup,
    selectedFormGroup,
    setSelectedQuestion,
    setSelectedOption,
  } = useContext(FormContext);
  const [isWait, setIsWait] = useState(false);
  const [editableTitle, setEditableTitle] = useState(parameter?.title || "");
  const [isSaveBtnVisible, setSaveBtnVisible] = useState(false);

  const handleTitleChange = (e) => {
    setEditableTitle(e.target.innerText);
    e.target.blur();
    e.target.focus();
  };

  useEffect(() => {
    if (editableTitle !== parameter?.title) {
      setSaveBtnVisible(true);
    } else {
      if (isSaveBtnVisible) {
        setSaveBtnVisible(false);
      }
    }
  }, [editableTitle]);

  const moveCursorToEnd = (el) => {
    if (el.innerText && document.createRange) {
      window.setTimeout(() => {
        let selection = document.getSelection();
        let range = document.createRange();

        range.setStart(el.childNodes[0], el.innerText.length);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }, 1);
    }
  };

  const handleUpdateParameter = async (e) => {
    e.stopPropagation();
    try {
      if (!editableTitle) throw new Error("Parameter title is required");
      setIsWait(true);

      let formInput = {
        ...selectedQuestionGroup,
        title: editableTitle,
      };

      const resp = await axiosInstance.put(
        `/forms/question-group/${formInput._id}`,
        formInput
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);
        setIsWait(false);
        Notify.success("Successfully");
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleDeleteParameter = async (e) => {
    e.stopPropagation();
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(
        `/forms/${form?._id}/${selectedFormGroup?._id}/${selectedQuestionGroup?._id}`
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);
        setSelectedQuestionGroup(null);
        setSelectedQuestion(null);
        setSelectedOption(null);
        setCurrentSelectedItemType(null);
        setIsWait(false);
        Notify.success("Successfully");
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const isEditable =
    currentSelectedItemType === "question-group" &&
    selectedQuestionGroup._id === parameter._id;

  return (
    <Box
      display={"flex"}
      width={"100%"}
      alignItems="center"
      justifyContent={"space-between"}
    >
      <Typography
        component={"span"}
        px={1}
        variant="body2"
        suppressContentEditableWarning={isEditable}
        contentEditable={isEditable}
        onInput={handleTitleChange}
        onFocus={(e) => moveCursorToEnd(e.target)}
      >
        {editableTitle}
      </Typography>
      <Box>
        {isWeightVisible && (
          <Tooltip
            title={"Parameter Weightage: " + weightage}
            placement="right"
          >
            <Typography component={"span"} variant="body2">
              w: {weightage}
            </Typography>
          </Tooltip>
        )}

        {isSaveBtnVisible && (
          <IconButton
            size="small"
            mx={2}
            disabled={isWait}
            color="primary"
            onClick={handleUpdateParameter}
          >
            <SaveTwoTone fontSize="small" />
          </IconButton>
        )}

        <IconButton
          size="small"
          mx={2}
          disabled={isWait}
          color="error"
          onClick={() => {
            Confirm.show(
              "Confirmation",
              "Are you sure you want to delete?",
              "Confirm",
              "Cancel",
              () => handleDeleteParameter(),
              () => {},
              {
                width: "500px",
                messageMaxLength: 1000,
              }
            );
          }}
        >
          <DeleteTwoTone fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default EditableParameter;
