import { UnfoldLessRounded, UnfoldMoreRounded } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Stack,
} from "@mui/material";
import React from "react";
import UserCard from "src/components/Common/UserCard";
import QuestionInputFieldNew from "src/content/dashboards/common/QuestionInputFieldNew";
import { fDateTimeSuffix, fToNow } from "src/utils/date-fns";
import { getImageUrl } from "src/utils/helpers";

export default function ManualFormSubmissions({
  actions,
  data,
  formInput,
  form,
  expanded,
  setExpanded,
}) {
  return (
    <>
      <CardHeader
        title={<h1>{form?.title || "POSH complaint form"} </h1>}
        subheader={<small>{form?.description || ""}</small>}
        action={
          <FormHelperText sx={{ p: 2 }}>
            created at: {fDateTimeSuffix(data?.createdAt)} |{" "}
            {fToNow(data?.createdAt)}
          </FormHelperText>
        }
        sx={{ px: 3, py: 0 }}
      />
      <Divider />
      <CardContent>
        <Card elevation={0}>
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack direction={"row"} spacing={6}>
                <UserCard
                  title={
                    <b>
                      {(data?.user?.firstName || "-") +
                        " " +
                        (data?.user?.lastName || "")}
                    </b>
                  }
                  subtitle={<small>Submitted By</small>}
                  avatar={getImageUrl(data?.user?.profileImage)}
                />{" "}
                <UserCard
                  title={
                    <b>
                      {data?.organisation?.title ||
                        data?.organisationName ||
                        "-"}
                    </b>
                  }
                  subtitle={<small>Organisation</small>}
                  avatar={getImageUrl(data?.user?.profileImage)}
                />
              </Stack>

              <Stack direction={"row"} spacing={3} alignItems={"center"}>
                {actions}
                <Button
                  startIcon={
                    expanded ? <UnfoldLessRounded /> : <UnfoldMoreRounded />
                  }
                  onClick={() => setExpanded(!expanded)}
                  color="secondary"
                >
                  {!expanded ? "expand" : "collapse"}
                </Button>
              </Stack>
            </Stack>

            {expanded && (
              <Stack spacing={4} my={2}>
                <Divider sx={{ mb: 2 }} />
                {form?.questions?.map((question, index) => (
                  <QuestionInputFieldNew
                    key={index}
                    question={question}
                    formInput={formInput}
                    setFormInput={() => {}}
                    handleFormInput={() => {}}
                    disabled={true}
                  />
                ))}
              </Stack>
            )}
          </CardContent>
        </Card>
      </CardContent>
    </>
  );
}
