import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import EmptyHere from "src/components/EmptyHere";
import SuspenseLoader from "src/components/SuspenseLoader";
import CrediblCertification from "../verification/CrediblCertification";
import CleanExitVerification from "../verification/CleanExitVerification";
import CompanyRatings from "./rating/Ratings";
import { ProfileContext } from "src/contexts/ProfileContext";
import { GlobalContext } from "src/contexts/GlobalContext";
import About from "./about/About";
import CleanExitIdCard from "../common/CleanExitIdCard";
import { UserSummaryActions } from "./overview/UserSummary";
import Forms from "../forms";
import NewCleanExit from "../CleanExit/components/NewCleanExit";
import ProfileStregth from "./components/ProfileStregth";
import ProfileCover from "./components/ProfileCover";
import { AboutSection, SocialSection } from "./components/ProfileSections";
import Certificates from "./certificates/Certificates";
import EmploymentHistory from "./overview/EmploymentHistory";
import {
  EditTwoTone,
  MoreVertOutlined,
  OpenInNew,
  WarningAmber,
} from "@mui/icons-material";
import UserProfilePreview from "../SearchPeople/components/UserProfilePreview";
import NotifyPlaceholder1 from "../Notifications/NotifyPlaceholder1";
import AdBanner1 from "src/components/Ads/AdBanner1";
import FutureSkillsCard from "./components/FutureSkillsCard";
import PortfolioTags from "./overview/PortofilioTags";

function UserProfile() {
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");
  const { currentUser } = useContext(GlobalContext);
  const { loading, profile, viewAsPublic, ratings, handleGetUserRatings } =
    useContext(ProfileContext);
  const [currentTab, setCurrentTab] = useState(tabParam || "overview");

  useEffect(() => {
    setCurrentTab(tabParam || "overview");
  }, [tabParam]);

  useEffect(() => {
    (async () => {
      if (profile?._id && !ratings?.length) {
        await handleGetUserRatings(profile, {
          includeScore: true,
        });
      }
    })();
  }, [profile]);

  return loading ? (
    <SuspenseLoader />
  ) : profile ? (
    <Stack sx={{ my: 2 }}>
      {/* {!profile?.viewAccount ? (
        <UserProfilePreview profile={profile} />
      ) : ( */}
      <>
        <ProfileCover />
        <Box sx={{ mb: 2 }} />

        {currentTab === "overview" && (
          <Stack spacing={2} sx={{ mb: 10 }}>
            <Stack spacing={2} direction={"row"} alignItems={"center"}>
              {!viewAsPublic &&
                !profile?.cleanExitProcess &&
                !["admin"]?.includes(currentUser?.role) &&
                //  &&
                // profile?.employementHistory?.some((relation) =>
                //   relation?.relations?.some((r) => r?.isActive)
                // )
                currentUser?.userId !== profile?._id && (
                  <>
                    <Box>
                      {/*TODO: {!profile?.ratingConsented ? ( */}
                      {false ? (
                        <Button
                          variant="outlined"
                          color="error"
                          size="large"
                          startIcon={<WarningAmber />}
                          component={Link}
                          to={`/dashboard/users/${profile?._id}?tab=about&about_tab=consent`}
                        >
                          Rating consent not completed
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          component={Link}
                          size="large"
                          sx={{
                            minWidth: 200,
                            position: "relative",
                          }}
                          to={`/dashboard/forms?receiverType=user&receiverId=${
                            profile?._id
                          }${
                            currentUser?.orgId
                              ? `&orgId=${currentUser?.orgId}`
                              : ""
                          }`}
                        >
                          Add Rating
                        </Button>
                      )}
                    </Box>
                    {profile?.roles?.find(
                      (role) => role?.organisation?._id === currentUser?.orgId
                    )?.isActive &&
                      !profile?.cleanExitProcess && (
                        <NewCleanExit user={profile} />
                      )}
                  </>
                )}

              {!viewAsPublic &&
                !!profile?.cleanExitProcess &&
                currentUser?.orgId ===
                  profile?.cleanExitProcess?.organisation && (
                  <Alert
                    variant="standard"
                    severity="warning"
                    action={
                      <IconButton
                        component={Link}
                        to={`/dashboard/cleanexits/${profile?.cleanExitProcess?.cleanExitId}`}
                        size="small"
                      >
                        <OpenInNew fontSize="1rem" />
                      </IconButton>
                    }
                  >
                    Employee is in clean exit process
                  </Alert>
                )}
            </Stack>

            {/* <AboutSection /> */}
            {/* <Card sx={{ pt: 1 }}>
                      <Box width={1} sx={{ textAlign: "center" }}>
                        <QRCode
                          value={
                            process.env.REACT_APP_BASE_URL +
                            `/dashboard/forms?receiverId=${profile?._id}${
                              currentUser?.orgId
                                ? `&orgId=${currentUser?.orgId}`
                                : ``
                            }`
                          }
                          style={{
                            width: 160,
                            height: 160,
                            position: "relative",
                          }}
                        />{" "}
                        <Typography>
                          <small>Scan to give Rating</small>
                        </Typography>
                      </Box>
                    </Card> */}
            {/* <SocialSection /> */}

            {/* {((viewAsPublic && profile?.crediblCertification?.certified) ||
                  !viewAsPublic) && <CrediblCertification />} */}
            {/* <FutureSkillsCard /> */}
            {/* {!viewAsPublic && <CleanExitVerification />} */}
            {/* {currentUser?.userId === profile?._id && !viewAsPublic && (
                  <ProfileStregth />
                )} */}
            <Stack spacing={2}>
              {/* <UserSummary /> */}

              {currentUser?.userId === profile?._id && !viewAsPublic && (
                <Stack>
                  <NotifyPlaceholder1 />
                </Stack>
              )}

              <AdBanner1>
                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  pb={2}
                >
                  <Typography variant="h3" component="h3">
                    Get Started
                  </Typography>
                  {!viewAsPublic &&
                    !profile?.cleanExitProcess &&
                    ((["owner", "moderator"]?.includes(currentUser?.role) &&
                      profile?.roles?.find(
                        (role) => role?.organisation?._id === currentUser?.orgId
                      )?.isActive) ||
                      currentUser?.userId === profile?._id ||
                      ["admin", "triager"]?.includes(
                        currentUser?.accountType
                      )) && (
                      <>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<EditTwoTone />}
                            component={Link}
                            size="small"
                            to={
                              currentUser?.userId === profile?._id
                                ? `/dashboard/account/profile/edit`
                                : `/dashboard/users/${profile?._id}/edit`
                            }
                          >
                            Edit Profile
                          </Button>
                          {!viewAsPublic && (
                            <UserSummaryActions profile={profile}>
                              <IconButton color="primary" size="small">
                                <MoreVertOutlined />
                              </IconButton>
                            </UserSummaryActions>
                          )}
                        </Stack>
                        {/* <CleanExitIdCard idCard={profile?.idCard} /> */}
                      </>
                    )}
                </Stack>
              </AdBanner1>

              {profile?.employementHistory?.length > 0 && (
                <Card>
                  <EmploymentHistory />
                </Card>
              )}

              <PortfolioTags />
            </Stack>
          </Stack>
        )}

        {currentTab === "ratings" && <CompanyRatings />}
        {currentTab === "about" && !viewAsPublic && <About />}
        {currentTab === "certificates" && <Certificates />}
        {currentTab === "forms" && <Forms user={profile?._id} />}
      </>
      {/* )} */}
    </Stack>
  ) : (
    <EmptyHere />
  );
}

export default UserProfile;
