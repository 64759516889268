import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  capitalize,
  styled,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { TAGS_TYPE } from "src/utils/constants";

export default function TagManager() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tagTypeParam = searchParams.get("type");
  const [loading, setLoading] = useState(false);
  const [newTagInput, setNewTagInput] = useState("");
  const [type, setTagType] = useState(tagTypeParam || "industries");
  const [allTags, setAllTags] = useState({});

  useEffect(() => {
    (async () => {
      const resp = await axiosInstance.get("/tags");
      if (resp?.status === 200) {
        setAllTags(resp?.data || {});
      }
    })();
  }, []);

  const handleEditTag = async (reqObj) => {
    try {
      // reqObj = {action: "add/remove", type, inputString, tag: "tag to be removed"}
      setLoading(true);
      let _tags = [];
      const tagType = reqObj?.type;
      const inputString = reqObj?.inputString;
      if (reqObj?.action === "add") {
        _tags = [...(allTags[tagType] || []), inputString];
      }
      if (reqObj?.action === "remove") {
        _tags = allTags[tagType].filter((item) => item !== reqObj?.tag);
      }

      const reqParams = {
        type: tagType,
      };
      const resp = await axiosInstance.put(`/tags`, {
        ...reqParams,
        tags: _tags,
      });
      if (resp.status === 200) {
        setAllTags({ ...allTags, [tagType]: _tags });
        setLoading(false);
        setNewTagInput("");
      }
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  const handleTabsChange = (event, newValue) => {
    setTagType(newValue);

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      type: newValue,
    });
  };

  return (
    <Stack spacing={3} my={2}>
      <Card>
        <CardHeader title="Tag Manager" />
        <Divider />

        <CardContent>
          <TabsWrapper
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={type || ""}
            variant="scrollable"
            sx={{ mb: 2 }}
          >
            {TAGS_TYPE.map((_tag) => (
              <Tab key={_tag?.value} value={_tag?.value} label={_tag?.label} />
            ))}
          </TabsWrapper>
          <Box sx={{ my: 2 }} display="flex">
            <TextField
              fullWidth
              label={"Type Tag name here..."}
              type="text"
              variant="outlined"
              size="small"
              value={newTagInput}
              onChange={(e) => {
                const _value = e.target.value;
                setNewTagInput(_value.toLowerCase());
              }}
            />
            <LoadingButton
              size="small"
              sx={{ ml: 2 }}
              color="error"
              variant="contained"
              onClick={() => {
                if (allTags[type]?.includes(newTagInput)) {
                  Notify.warning("Tag already exists");
                  return;
                }
                handleEditTag({
                  action: "add",
                  type,
                  inputString: newTagInput,
                });
              }}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Alert severity="warning" sx={{ mb: 2 }}>
            {{
              industries:
                "These tags are used to filter forms for Organisations/Users based on their industry",
              departments: "Used in Employee/User profile",
              designations: "Used in Employee/User profile",
              receivers:
                "These tags are used to determine the receiver (To whom or what a user/organisation is rating for). like: a Book, a Cusine, some service, etc. And User, Employee and Organisation tags are fixed.",
              customerRelations:
                "These tags are helpful to identify the type of relation of customer with the organisation",
            }[type] ||
              `These tags are used in ${capitalize(
                TAGS_TYPE.find((item) => item.value === type)?.label
              )} section`}
          </Alert>

          <Stack sx={{ minHeight: 166 }}>
            <Grid container spacing={2} direction={"row"}>
              {type === "receivers" &&
                ["user", "employee", "organisation"].map((item, idx) => (
                  <Grid item xs={3} key={idx}>
                    <Chip size="small" label={item} variant="outlined" />
                  </Grid>
                ))}
              {allTags[type]?.length > 0
                ? allTags[type]?.map((item, idx) => (
                    <Grid item key={idx} xs={3}>
                      <Chip
                        size="small"
                        label={item}
                        variant="outlined"
                        sx={{
                          overflow: "hidden",
                          maxWidth: "300px",
                        }}
                        onDelete={() => {
                          Confirm.show(
                            "Confirmation",
                            "Are you sure you want to delete this tag?",
                            "Confirm",
                            "Cancel",
                            () =>
                              handleEditTag({
                                action: "remove",
                                type,
                                tag: item,
                              }),
                            () => {},
                            {
                              width: "500px",
                              messageMaxLength: 1000,
                            }
                          );
                        }}
                      />
                    </Grid>
                  ))
                : type !== "receivers" && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                      m={2}
                    >
                      {`No ${type} tags found`}
                    </Typography>
                  )}
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);
