import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import ThemeSettings from "src/components/ThemeSettings";
import { Box } from "@mui/material";
import Header from "./Header";

const BaseLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Box
        sx={{
          flex: 1,
          height: "100%",
        }}
      >
        {children || <Outlet />}
      </Box>
      <ThemeSettings />
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
