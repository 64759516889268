import { AddTwoTone, DriveFileRenameOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { generateRandomString } from "src/utils/helpers";

export default function NewCommitteeDialog({ inputs, item, getCommittees }) {
  const [open, setOpen] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [formInput, setFormInput] = useState({
    name: "",
    title: "",
    description: "",
  });

  useEffect(() => {
    if (item) {
      setFormInput({
        name: item.name,
        title: item.title,
        description: item.description,
      });
    }
  }, [item]);

  const handleSubmit = async () => {
    try {
      if (!formInput.title || !formInput.description) {
        Notify.failure("Please fill all the fields");
        return;
      }

      setIsWait(true);
      const _inputs = [...(inputs || [])];
      if (item) {
        _inputs.forEach((input) => {
          if (input.name === item.name) {
            input.title = formInput.title;
            input.description = formInput.description;
          }
        });
      } else {
        const _committeeName = await generateRandomString(10);
        _inputs.push({
          ...formInput,
          name: _committeeName,
        });
      }

      const resp = await axiosInstance.put("/tags", {
        type: "committee",
        inputs: _inputs,
        isPublic: true,
      });
      if (resp?.status === 200) {
        setOpen(false);
        getCommittees();
      }
      setIsWait(false);
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Stack direction={"row"} justifyContent={"flex-end"} mb={2}>
        <Button
          variant={item ? "" : "outlined"}
          color="primary"
          startIcon={item ? <DriveFileRenameOutline /> : <AddTwoTone />}
          onClick={() => setOpen(true)}
          size="small"
        >
          {item ? "Edit " : "Add Committee"}
        </Button>
      </Stack>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"md"}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogContent>
            <Typography variant="h4" mb={2}>
              Add/Edit Committee
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Stack spacing={4}>
              <TextField
                label="Committee Name"
                variant="outlined"
                fullWidth
                value={formInput.title}
                onChange={(e) =>
                  setFormInput({ ...formInput, title: e.target.value })
                }
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={formInput.description}
                onChange={(e) =>
                  setFormInput({ ...formInput, description: e.target.value })
                }
              />
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    Confirm.show(
                      "Confirmation",
                      "Make sure you have filled all the fields correctly, as you'll not be able to delete this committee once created.",
                      "Confirm",
                      "Cancel",
                      () => handleSubmit(),
                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      }
                    );
                  }}
                  loading={isWait}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
