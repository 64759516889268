import {
  AddTwoTone,
  HighlightOffOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchAndSelectUser from "../../common/SearchAndSelectUser";
import { Confirm, Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { LoadingButton } from "@mui/lab";

const UserDetailRow = ({ row, whichPage, children }) => {
  return (
    <TableRow
      sx={{
        color: row?.isLocked && "red",
        textDecoration: row?.isLocked && "line-through",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell>
        <Typography variant="h6" fontWeight={700}>
          {row?.firstName + " " + row?.lastName || ""}
        </Typography>
        <Stack direction={"row"}>
          {/* {row?.isMasterAccount && <MasterAccountIcon width={20} height={20} />} */}
          <Typography>
            <small>{row?.cleanExitUserId || "-"}</small>
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {row?.email}
        {row?.phoneNumber && (
          <>
            <br />
            +91-{row?.phoneNumber}
          </>
        )}
      </TableCell>
      <TableCell>{row?.aadhaarNumber || "-"}</TableCell>
      <TableCell>{row?.panNumber || "-"}</TableCell>
      <TableCell>
        {(() => {
          const address = [row?.address?.city, row?.address?.state]?.filter(
            (a) => a
          );

          return address?.length > 0 ? address.join(", ") : "-";
        })()}
        {row?.address?.pincode && (
          <Typography>
            <small>{row?.address?.pincode}</small>
          </Typography>
        )}
      </TableCell>
      {whichPage === "master-accounts" && (
        <TableCell align="right">
          {row?.linkedAccounts?.length > 0 ? (
            <b>
              {row?.linkedAccounts?.length < 9 ? "0" : ""}
              {row?.linkedAccounts?.length}{" "}
            </b>
          ) : (
            "-"
          )}
        </TableCell>
      )}
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};

export default function UserAccounts({ row, handleUpdateList }) {
  const [open, setOpen] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [attachAccount, setAttachAccount] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [possibleDuplicateUsers, setPossibleDuplicateUsers] = useState([]);
  const [searchPossibleUsers, setSearchPossibleUsers] = useState(false);

  useEffect(() => {
    if (row?.linkedAccounts?.length > 0) {
      setSelectedUsers(row?.linkedAccounts?.map((account) => account._id));
    }
  }, [row]);

  const handleSelectUser = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleLinkUser = async (_selectedUsers) => {
    try {
      const resp = await axiosInstance.post(`/users/managed/${row?._id}`, {
        linkedAccounts: _selectedUsers,
      });

      if (resp?.status === 200) {
        Notify.success("Accounts linked successfully");
        handleUpdateList(resp?.data?.data);

        setPossibleDuplicateUsers(
          possibleDuplicateUsers.filter(
            (user) => !_selectedUsers.includes(user._id)
          )
        );

        setLoading(false);
        setAttachAccount(false);
      }

      setIsWait(false);
    } catch (error) {
      setIsWait(false);

      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleSearchPosibleDuplicateUsers = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/users/managed/${row?._id}`);

      if (resp?.status === 200) {
        setPossibleDuplicateUsers(resp?.data?.data || []);
      }
      setLoading(false);
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <React.Fragment>
      <UserDetailRow row={row} whichPage="master-accounts">
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            setOpen(!open);
            setLoading(false);
            setIsWait(false);
            setAttachAccount(false);
            setPossibleDuplicateUsers([]);
          }}
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </UserDetailRow>

      <TableRow
        sx={{
          background: (theme) => theme.palette.background.default,
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mb: 2, mt: 1 }}>
              <Card variant="outlined" sx={{ p: 1 }}>
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <FormHelperText sx={{ mb: 2 }}>
                    {row?.linkedAccounts?.length || "No "} linked accounts found
                  </FormHelperText>
                  {searchPossibleUsers ? (
                    <Typography variant="h6" color="secondary">
                      {possibleDuplicateUsers?.length || "No "} possible
                      duplicate accounts found
                    </Typography>
                  ) : (
                    <Stack spacing={1} direction={"row"}>
                      <Typography
                        variant="h6"
                        fontWeight={500}
                        color={"primary"}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleSearchPosibleDuplicateUsers();
                          setSearchPossibleUsers(true);
                        }}
                      >
                        {loading ? "Please wait.." : "Click here "}
                      </Typography>
                      <Typography variant="h6" color="secondary">
                        to check
                        {row?.linkedAccounts?.length > 0 ? " more" : ""}{" "}
                        possible duplicate accounts
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Divider sx={{ my: 2 }} />
                {(possibleDuplicateUsers?.length > 0 ||
                  row?.linkedAccounts?.length > 0) && (
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell>Aadhaar </TableCell>
                        <TableCell>PAN </TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {possibleDuplicateUsers?.map((historyRow) => (
                        <UserDetailRow row={historyRow} key={historyRow._id}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AddTwoTone />}
                            onClick={() => {
                              Confirm.show(
                                "Confirmation",
                                "Are you sure you want to remove this link account?",
                                "Confirm",
                                "Cancel",
                                () => {
                                  const _selectedUsers = [
                                    ...selectedUsers,
                                    historyRow._id,
                                  ];
                                  handleLinkUser(_selectedUsers);
                                },
                                () => {},
                                {
                                  width: "500px",
                                  messageMaxLength: 1000,
                                }
                              );
                            }}
                          >
                            Link Account
                          </Button>
                        </UserDetailRow>
                      ))}
                      {row?.linkedAccounts?.map((historyRow) => (
                        <UserDetailRow row={historyRow} key={historyRow._id}>
                          <IconButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={() => {
                              Confirm.show(
                                "Confirmation",
                                "Are you sure you want to remove this link account?",
                                "Confirm",
                                "Cancel",
                                () => {
                                  const _selectedUsers = selectedUsers.filter(
                                    (id) => id !== historyRow._id
                                  );
                                  handleLinkUser(_selectedUsers);
                                },
                                () => {},
                                {
                                  width: "500px",
                                  messageMaxLength: 1000,
                                }
                              );
                            }}
                          >
                            <HighlightOffOutlined fontSize="small" />
                          </IconButton>
                        </UserDetailRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                <Button
                  startIcon={<AddTwoTone />}
                  onClick={() => {
                    setAttachAccount(true);
                  }}
                  sx={{ mt: 2 }}
                >
                  Search and Link Account
                </Button>
              </Card>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {attachAccount && (
        <Dialog
          open={true}
          maxWidth={"lg"}
          fullWidth
          onClose={() => {
            setAttachAccount(false);
          }}
        >
          <DialogContent>
            <SearchAndSelectUser
              handleSelectUser={handleSelectUser}
              selectedUsers={selectedUsers || []}
            >
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Divider />
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    Confirm.show(
                      "Confirmation",
                      "Are you sure you want to link these accounts?",
                      "Confirm",
                      "Cancel",
                      () => handleLinkUser(selectedUsers),
                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      }
                    );
                  }}
                  loading={isWait}
                >
                  Done
                </LoadingButton>
              </Stack>
            </SearchAndSelectUser>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
