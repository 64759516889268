import { Avatar, CardHeader } from "@mui/material";
import React from "react";

export default function UserCard({ avatar, title, subtitle, action }) {
  return (
    <CardHeader
      avatar={
        avatar && (
          <Avatar
            sx={{
              width: subtitle ? 40 : 30,
              height: subtitle ? 40 : 30,
            }}
            variant="circular"
            alt={title}
            src={avatar}
          />
        )
      }
      title={title}
      subheader={subtitle || ""}
      sx={{
        py: 0,
        pl: 0,
      }}
      titleTypographyProps={{
        variant: "h6",
      }}
      action={action}
    />
  );
}
