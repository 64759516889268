import FormBuilder from "src/content/dashboards/FormBuilder";
import { FormProvider } from "src/contexts/FormContext";

// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'

const formRoutes = [
  {
    path: ":formId",
    element: (
      <FormProvider>
        {/* <DndProvider backend={HTML5Backend}> */}
        <FormBuilder />
        {/* </DndProvider> */}
      </FormProvider>
    ),
  },
];

export default formRoutes;
