import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tab,
  Tabs,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
  Stack,
} from "@mui/material";
import {
  Add,
  Search,
  Business,
  DirectionsCar,
  Computer,
  DriveFileRenameOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import AddEditAssets from "./components/AddEditAssets";
import { GlobalContext } from "src/contexts/GlobalContext";
import { useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { ORG_ASSET_TYPES, PAGE_LIMIT, RuppeeSign } from "src/utils/constants";
import { Confirm, Notify } from "notiflix";
import { fDate } from "src/utils/date-fns";

const OrganisationAssets = () => {
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { organisationId } = useParams();
  const [addEdit, setAddEdit] = useState(false);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [editFormInput, setEditFormInput] = useState(null);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    handleGetAssets();
  }, [page, filterObj]);

  const handleGetAssets = async () => {
    try {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }

      if (currentUser?.orgId || organisationId) {
        reqParams.params = {
          ...reqParams.params,
          organisation: currentUser?.orgId || organisationId,
          assetType: filterObj?.assetType || ORG_ASSET_TYPES.property?.value,
        };
      }

      const resp = await axiosInstance.get(
        `/organisations/${currentUser?.orgId || organisationId}/assets`,
        reqParams
      );
      if (resp?.status === 200) {
        setAssets(resp?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure("Failed to fetch users");
    }
  };

  const handleRemoveAsset = async (assetId) => {
    try {
      const resp = await axiosInstance.delete(
        `/organisations/assets/${assetId}`
      );
      if (resp?.status === 200) {
        Notify.success("Removed successfully");
        setAssets(assets?.data?.filter((asset) => asset._id !== assetId));
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom mb={3}>
        Organizational Assets{" "}
        {currentUser?.role === "user" ? "property" : "Management"}
      </Typography>

      {currentUser?.orgId && (
        <Button
          variant={addEdit ? "contained" : "outlined"}
          color="primary"
          startIcon={<Add />}
          sx={{ mb: 3, borderRadius: 0.5 }}
          onClick={() => {
            setAddEdit(!addEdit);
          }}
        >
          Add New Asset
        </Button>
      )}

      {addEdit && (
        <AddEditAssets
          setAddEdit={setAddEdit}
          handleGetAssets={handleGetAssets}
          editFormInput={editFormInput}
          setEditFormInput={setEditFormInput}
        />
      )}

      <Paper variant="outlined" sx={{ mb: 3, overflow: "hidden" }}>
        <Tabs
          value={filterObj?.assetType || ORG_ASSET_TYPES?.property?.value}
          onChange={(e, value) => {
            setFilterObj({ ...filterObj, assetType: value });
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{ boxShadow: "0 1px 0 0 #ccc", borderRadius: 0 }}
          TabIndicatorProps={{
            sx: {
              borderRadius: 0,
            },
          }}
        >
          {Object.entries(ORG_ASSET_TYPES).map(([key, value], idx) => (
            <Tab
              iconPosition="start"
              label={value?.value}
              value={key}
              icon={<Business />}
            />
          ))}
        </Tabs>

        <Box sx={{ p: 2, pb: 4 }}>
          {/* <TextField
            fullWidth
            placeholder="Search assets..."
            variant="outlined"
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              sx: {
                height: 40,
                fontWeight: "600",
                fontSize: 15,
              },
            }}
          /> */}

          <TableContainer elevation={0} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  {currentUser?.role !== "user" && (
                    <TableCell>Assigned To</TableCell>
                  )}
                  {!currentUser?.orgId && <TableCell>Organisation</TableCell>}
                  <TableCell>Assignment Date</TableCell>
                  <TableCell>Value</TableCell>
                  {currentUser?.orgId && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {assets?.data?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: 16 }}>{item.title}</TableCell>
                    <TableCell sx={{ fontSize: 16 }}>
                      {item.description}
                    </TableCell>
                    {currentUser?.role !== "user" && (
                      <TableCell sx={{ fontSize: 16 }}>
                        {item.assignedTo?.firstName ||
                          item?.user?.firstName ||
                          "-"}{" "}
                        {item.assignedTo?.lastName ||
                          item?.user?.lastName ||
                          ""}
                        {item?.user && (
                          <Stack spacing={1}>
                            <Typography variant="body2" color="textSecondary">
                              <small>E: {item?.user?.email || ""}</small>
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <small>P: {item?.user?.phoneNumber || ""}</small>
                            </Typography>
                          </Stack>
                        )}
                      </TableCell>
                    )}
                    {!currentUser?.orgId && (
                      <TableCell sx={{ fontSize: 16 }}>
                        {item.organisation?.title || "-"}
                      </TableCell>
                    )}
                    <TableCell sx={{ fontSize: 16 }}>
                      {fDate(item.assignedAt) || "-"}
                    </TableCell>
                    <TableCell sx={{ fontSize: 16 }}>
                      {item?.amount > 0 ? (
                        <>
                          {RuppeeSign}
                          {item?.amount || "0"}
                        </>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    {currentUser?.orgId && (
                      <TableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setEditFormInput(item);
                            setAddEdit(true);
                          }}
                        >
                          <DriveFileRenameOutline />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            Confirm.show(
                              "Confirmation",
                              "Are you sure you want to remove?",
                              "Confirm",
                              "Cancel",
                              () => handleRemoveAsset(item._id),
                              () => {},
                              {
                                width: "500px",
                                messageMaxLength: 1000,
                              }
                            );
                          }}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {assets?.data?.length > 0 ? (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={assets?.totalPages || 1}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          ) : (
            <Box textAlign={"center"} my={2}>
              <Typography>No assets found</Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default OrganisationAssets;
