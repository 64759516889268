import React, { useContext } from "react";
import {
  Box,
  Card,
  Avatar,
  CardMedia,
  Typography,
  Button,
  styled,
  useTheme,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import { useTranslation } from "react-i18next";
import { ArrowForwardIosOutlined, PlaceTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { EVENT_STATUS } from "src/utils/constants";
import { format } from "date-fns";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function EventCard({ event }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentUser } = useContext(GlobalContext);

  return (
    <Card>
      <Box
        sx={{
          position: "relative",
          p: 1,
        }}
      >
        <CardMedia
          component="img"
          height="200"
          image={
            event?.coverImage
              ? process.env.REACT_APP_DO_SPACE_URL +
                (event?.coverImage + "?" + Math.random())
              : "/static/images/placeholders/illustrations/wireframe.svg"
          }
          alt=""
          sx={{
            borderRadius: `${theme.general.borderRadiusLg}`,
            background: "#9AC977",
          }}
        />
        <CardActions
          sx={{
            bottom: "auto",
            top: `${theme.spacing(2)}`,
            right: "auto",
            left: `${theme.spacing(2)}`,
          }}
        >
          <Stack spacing={1}>
            <LabelWrapper
              sx={{
                background: `${theme.colors.alpha.trueWhite[100]}`,
                color: `${theme.colors.alpha.black[100]}`,
              }}
            >
              {event?.startDateTime
                ? format(new Date(event?.startDateTime), "hh:mm a")
                : ""}{" "}
              -{" "}
              {event?.endDateTime
                ? format(new Date(event?.endDateTime), "hh:mm a")
                : ""}
            </LabelWrapper>
            {currentUser?.role !== "user" && (
              <Chip
                size="small"
                color={EVENT_STATUS[event?.status || "draft"]?.color}
                label={EVENT_STATUS[event?.status || "draft"]?.description}
                sx={{
                  width: "max-content",
                  fontWeight: "bold",
                }}
              />
            )}
          </Stack>
        </CardActions>
      </Box>
      <Stack sx={{ px: 3 }} spacing={2}>
        <Stack direction="row" pt={2} spacing={2} alignItems="center">
          <Typography
            variant="subtitle1"
            sx={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <AccessTimeTwoToneIcon
              fontSize="small"
              color="success"
              sx={{
                opacity: 0.7,
                mr: 0.5,
              }}
            />
            {event?.startDateTime &&
              format(new Date(event?.startDateTime), "dd MMM")}{" "}
            -{" "}
            {event?.endDateTime &&
              format(new Date(event?.endDateTime), "dd MMM")}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PlaceTwoTone
              color={"info"}
              fontSize="small"
              sx={{
                opacity: 0.7,
                mr: 0.5,
              }}
            />
            {event?.isVirtual ? (
              "Virtual"
            ) : (
              <>
                {event?.address?.city}
                {!!event?.address?.city && ","}{" "}
                {event?.address?.state || event?.address?.country || ""}
              </>
            )}
          </Typography>
        </Stack>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.3rem",
          }}
          component={Link}
          to={`/dashboard/events/${event?._id}`}
          style={{ textDecoration: "none" }}
        >
          {event?.title}
        </Typography>

        <Divider />
        <Stack spacing={1} direction={"row"}>
          <Avatar
            variant="rounded"
            sx={{
              width: 50,
              height: 50,
              boxShadow: "none",
              borderWidth: 5,
              background: `${theme.colors.alpha.black[90]}`,
              border: `${theme.colors.alpha.white[100]} solid 3px`,
            }}
            src={
              event?.organisation?.avatar
                ? process.env.REACT_APP_DO_SPACE_URL +
                  (event?.organisation?.avatar + "?" + Math.random())
                : ""
            }
          />
          <Box>
            <Typography variant="h4">
              {event?.organisation?.title || ""}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                mt: 0.5,
              }}
            >
              {event?.organisation?.companyWebsite || ""}
            </Typography>
          </Box>
        </Stack>

        <Divider />
        <Box pb={2}>
          <Button
            fullWidth
            variant="outlined"
            endIcon={<ArrowForwardIosOutlined />}
            component={Link}
            to={`/dashboard/events/${event?._id}`}
          >
            {t("View details")}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(12)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      margin: ${theme.spacing(0.5)};
      height: 20px;
      line-height: 28px;
      font-weight: bold;
`
);

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
    display: flex;
  `
);
