// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import EmptyHere from "src/components/EmptyHere";
import PricingCard from "./components/PriceCard";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import { AddTwoTone, InfoTwoTone, RemoveTwoTone } from "@mui/icons-material";

export default function PricingPlans() {
  const [searchParams, setSearchParams] = useSearchParams();
  const durationParam = searchParams.get("duration");
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasPlan, setHasPlan] = useState(false);
  const [seats, setSeats] = useState(10);

  const [filterObj, setFilterObj] = useState({
    type: "plan",
    duration: durationParam || "lifetime",
  });

  useEffect(() => {
    (async () => {
      try {
        const resp = await axiosInstance.post("/orders/check", {
          type: "plan",
        });
        if (resp?.status === 200) setHasPlan(resp?.data);
      } catch (error) {
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  useEffect(() => {
    const getPrices = async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: {},
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        const response = await axiosInstance.get("/pricing", reqParams);
        if (response.status === 200) {
          setPrices(response.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    };
    getPrices();
  }, [durationParam, filterObj]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Container maxWidth="lg">
      <Stack>
        <CardContent>
          <Stack sx={{ mt: 4, mb: 10 }}>
            <Typography variant="h1" sx={{ fontSize: "2.3rem" }} align="center">
              Choose the plan that’s right for you.
            </Typography>

            <Typography
              align="center"
              variant="h5"
              sx={{ mt: 2, color: "text.secondary" }}
            >
              An introduction to the plans you offer and the differences between
              them
            </Typography>
          </Stack>

          <Card sx={{ my: 4 }} elevation={0}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
                  How many seats do you want to include?
                  <Tooltip title="This is the number of users that you will be able to add to premium membership">
                    <InfoTwoTone sx={{ mx: 1 }} fontSize="small" color="info" />
                  </Tooltip>
                </Typography>
                <ButtonGroup
                  variant="contained"
                  size="small"
                  sx={{
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="secondary"
                    onClick={() => {
                      if (seats === 1) return;
                      setSeats(seats - 1);
                    }}
                  >
                    <RemoveTwoTone />
                  </Button>{" "}
                  <TextField
                    name="amount"
                    variant="standard"
                    sx={{
                      width: "25%",
                      "& input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        padding: "0.2rem",
                      },
                      borderRadius: 0,
                      border: "1px solid #ccc",
                    }}
                    value={seats}
                    onChange={(e) => {
                      setSeats(
                        Number(e.target.value) > 0 ? Number(e.target.value) : 1
                      );
                    }}
                  />
                  <Button
                    color="secondary"
                    onClick={() => {
                      setSeats(seats + 1);
                    }}
                  >
                    <AddTwoTone />
                  </Button>{" "}
                </ButtonGroup>
              </Stack>
            </CardContent>
          </Card>
          {prices?.length === 0 ? (
            <EmptyHere
              title={`No ${filterObj?.duration} plans yet`}
              subtitle={"We will add them soon."}
            />
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {prices?.length > 0 &&
                prices?.map((card, index) => (
                  <PricingCard
                    hasPlan={hasPlan}
                    key={index}
                    index={index}
                    item={card}
                    seats={seats}
                  />
                ))}
            </Grid>
          )}
        </CardContent>
      </Stack>
    </Container>
  );
}
