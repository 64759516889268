import React, { useContext, useState } from "react";
import {
  Typography,
  CardContent,
  Card,
  Box,
  Stack,
  ListItem,
  ListItemText,
  List,
  alpha,
  useTheme,
  CardHeader,
  IconButton,
  capitalize,
  Button,
  Tooltip,
  Avatar,
  Paper,
  Collapse,
} from "@mui/material";
import Label from "src/components/Label";
import { ProfileContext } from "src/contexts/ProfileContext";
import { calculateFinalScore, splitCamelCaseToWords } from "src/utils/helpers";
import { GlobalContext } from "src/contexts/GlobalContext";
import {
  AddTwoTone,
  EditTwoTone,
  ExpandLess,
  ExpandMore,
  GppBadTwoTone,
  SecurityTwoTone,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { fDate } from "src/utils/date-fns";
import { Notify } from "notiflix";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";

export const ListOfEmployementHistory = ({ whichPage }) => {
  const theme = useTheme();
  const { profile, viewAsPublic, ratings } = useContext(ProfileContext);
  const { currentUser, handleCheckPayment } = useContext(GlobalContext);
  const [isWait, setIsWait] = useState(false);
  const [hoverOn, setHoverOn] = useState("");

  const handleAddCareerProtection = async (org) => {
    try {
      setIsWait(true);
      const orgId = org?._id;
      const hasPaid = await handleCheckPayment(
        {
          item: "joinOrganisations",
          itemId: orgId,
        },
        {
          redirectLink: `/dashboard`,
          itemId: orgId,
          item: "joinOrganisations",
          joinOrgObj: {
            showInputs: false,
            disableInputs: true,
            createInvitation: false,
          },
        }
      );
      setIsWait(false);
      if (!hasPaid) return;
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const RelationDetailsAccordion = ({ companyWebsite, item }) => {
    const [open, setOpen] = useState(false);
    return (
      <Stack sx={{ width: "75%", mt: 1 }}>
        <Stack spacing={1} direction={"row"} mr={1} alignItems="center">
          {companyWebsite && (
            <>
              <Typography
                variant="subtitle2"
                component={"a"}
                href={
                  "http://" + companyWebsite?.replace(/(^\w+:|^)\/\//, "") || ""
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                whiteSpace="nowrap"
              >
                {companyWebsite?.replace(/(^\w+:|^)\/\//, "") || ""}
              </Typography>
              <Typography>•</Typography>
            </>
          )}
          <Typography variant="subtitle2" whiteSpace="nowrap">
            ID: <b>{item?.employeeId || item?.meta?.employeeId || "N/A"}</b>
          </Typography>
          {item?.isActive ? (
            <>
              <Typography>•</Typography>
              <Typography variant="subtitle2" whiteSpace="nowrap">
                Joined on {fDate(item?.createdAt)}
              </Typography>
            </>
          ) : (
            <>
              <Typography>•</Typography>
              <Label color="error">
                <Typography whiteSpace="nowrap">
                  Employee has left on {fDate(item?.exitAt)}
                </Typography>
              </Label>
            </>
          )}
          {whichPage === "about" &&
            (item?.type === "customer-relation"
              ? Object.entries(item?.meta || {}).length > 0
              : true) && (
              <Button
                endIcon={open ? <ExpandLess /> : <ExpandMore />}
                onClick={() => setOpen(!open)}
                sx={{ width: "max-content" }}
                size="small"
                color="secondary"
              >
                view details
              </Button>
            )}
        </Stack>
        {whichPage === "about" && (
          <Collapse in={open}>
            {item?.type === "role" && (
              <List dense component={Paper} sx={{ mt: 2 }}>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ variant: "h6" }}
                    primary={"Organisation Email"}
                  />
                  <Typography>
                    <b>{item?.companyEmail || "N/A"}</b>
                  </Typography>
                </ListItem>
                {Object.entries(item || {}).map(([key, value]) => {
                  const notShow = [
                    "_id",
                    "__v",
                    "user",
                    "permissions",
                    "organisation",
                    "createdAt",
                    "role",
                    "employeeId",
                    "companyEmail",
                    "membership",
                    "action",
                    "type",
                  ];
                  if (notShow.includes(key)) return null;

                  if (!value) return null;
                  if (typeof value !== "string") return null;
                  return (
                    <ListItem key={key}>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "h6",
                        }}
                        primary={splitCamelCaseToWords(key || "")}
                      />
                      <Typography>
                        <b>{value || "-"}</b>
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            )}
            {item?.type === "customer-relation" && (
              <List dense component={Paper} sx={{ mt: 2 }}>
                {Object.entries(item?.meta || {}).map(([key, value]) => {
                  if (!value) return null;
                  return (
                    <ListItem key={key}>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "h6",
                        }}
                        primary={`${capitalize(key?.label || key || "")}`}
                      />
                      <Typography>
                        <b>
                          {typeof value === "string"
                            ? value
                            : {
                                text: value?.value || "-",
                                date: fDate(value?.value || new Date()),
                                file: (
                                  <Button
                                    size="small"
                                    component={"a"}
                                    sx={{ px: 0 }}
                                    href={
                                      process.env.REACT_APP_DO_SPACE_URL +
                                      value?.value
                                    }
                                    target="_blank"
                                  >
                                    {value?.value?.split("/")?.pop()}
                                  </Button>
                                ),
                              }[value?.type]}
                        </b>
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Collapse>
        )}
      </Stack>
    );
  };

  return (
    <Stack spacing={2}>
      {profile?.employementHistory?.map((org, index) => (
        <Card elevation={whichPage === "about" ? 1 : 0} key={index}>
          <CardHeader
            avatar={
              <Link
                to={`/links/${org?._id}/org`}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Avatar
                  src={
                    org?.avatar
                      ? process.env.REACT_APP_DO_SPACE_URL +
                        (org?.avatar + "?" + Math.random())
                      : null
                  }
                  variant="rounded"
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: 48,
                    height: 48,
                  }}
                >
                  {org?.title?.toString()?.slice(0, 1)}
                </Avatar>
              </Link>
            }
            title={
              <Link
                to={`/links/${org?._id}/org`}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography variant="h3" fontSize={20}>
                  {org?.title || ""}
                </Typography>
              </Link>
            }
            action={
              <Stack direction="row" alignItems="center" spacing={1}>
                {org?.relations?.length === 1 && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {!viewAsPublic &&
                      org?.relations[0]?.isActive &&
                      (currentUser?.accountType === "admin" ||
                        currentUser?.orgId === org?._id) && (
                        <IconButton
                          size="small"
                          color="secondary"
                          component={Link}
                          to={
                            org?.relations[0]?.type === "role"
                              ? `${
                                  currentUser?.userId === profile?._id
                                    ? "/dashboard/account/profile/edit"
                                    : `/dashboard/users/${profile?._id}/edit`
                                }?role=${org?.relations[0]?.role}${
                                  currentUser?.accountType === "admin" &&
                                  org?._id
                                    ? `&organisation=${org?._id}`
                                    : ""
                                }`
                              : `${`/dashboard/customer-relations/${org?.relations[0]?._id}/edit`}`
                          }
                        >
                          <EditTwoTone fontSize="1rem" />
                        </IconButton>
                      )}
                    <Label color="primary">
                      <b>
                        {capitalize(
                          org?.relations[0]?.relation ||
                            org?.relations[0]?.role ||
                            ""
                        )}
                      </b>
                    </Label>
                  </Stack>
                )}
                {org?.hasCareerProtection && (
                  <Tooltip
                    title={
                      `Career Protection Enabled 
                      ${
                        org?.careerProtectionExpiresAt
                          ? `(valid till ${fDate(
                              org?.careerProtectionExpiresAt
                            )})`
                          : ``
                      }
                      . ` +
                      (org?.certificate?.url && !viewAsPublic
                        ? " Click to view certificate"
                        : "")
                    }
                  >
                    <IconButton
                      color="success"
                      component={!viewAsPublic ? "a" : "span"}
                      href={org?.certificate?.url || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SecurityTwoTone />
                    </IconButton>
                  </Tooltip>
                )}
                {!org?.hasCareerProtection && (
                  <>
                    {currentUser?.userId === profile?._id ? (
                      <LoadingButton
                        loading={isWait}
                        color="warning"
                        startIcon={<AddTwoTone />}
                        size="small"
                        variant="outlined"
                        // onClick={() => handleAddCareerProtection(org)}

                        component={Link}
                        to={`/dashboard/career-protection/${org?._id}`}
                      >
                        Add Career Protection
                      </LoadingButton>
                    ) : (
                      <Tooltip title={"No career protection"}>
                        <IconButton color="warning">
                          <GppBadTwoTone fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </Stack>
            }
            subheader={
              <Box>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {org?.relations?.length === 1 && (
                    <>
                      <RelationDetailsAccordion
                        companyWebsite={org?.companyWebsite}
                        item={org?.relations[0]}
                      />
                    </>
                  )}
                </Stack>
              </Box>
            }
          />

          {(() => {
            const _ratings = ratings?.find(
              (rating) => rating?.organisation === org?._id
            )?.ratings;

            if (!_ratings || _ratings?.length === 0) return <> </>;

            const score =
              _ratings
                ?.filter((rating) => rating?.includeScore)
                ?.reduce((acc, rating) => acc + rating?.score, 0)
                ?.toFixed(0) || 0;

            const weightage =
              _ratings
                ?.filter((rating) => rating?.includeScore)
                ?.reduce((acc, rating) => acc + rating?.weightage, 0)
                ?.toFixed(0) || 0;

            const finaScore = calculateFinalScore({
              score,
              weightage,
            })?.score;

            return (
              <Typography variant="subtitle2" ml={9} mb={2}>
                Got <b>{finaScore}</b> Score in <b>{_ratings?.length || 0}</b>{" "}
                feedback(s)
              </Typography>
            );
          })()}
          {org?.relations?.length > 1 && (
            <Timeline>
              {org?.relations
                ?.sort((a, b) => {
                  const a_end = a?.createdAt;
                  const b_end = b?.createdAt;
                  return b_end - a_end;
                })
                ?.map((item, idx) => (
                  <TimelineItem
                    key={idx}
                    sx={{ p: 0 }}
                    onMouseOver={() => setHoverOn("edu-" + item?._id)}
                    onMouseLeave={() => setHoverOn("")}
                  >
                    <TimelineOppositeContent
                      sx={{
                        width: "10px",
                        flex: "none",
                        whiteSpace: "nowrap",
                      }}
                      color="text.secondary"
                    ></TimelineOppositeContent>
                    <TimelineSeparator
                      sx={{
                        position: "relative",
                        mx: 2,
                      }}
                    >
                      <TimelineDot
                        sx={{
                          marginTop: 0,
                          top: (theme) => theme.spacing(3),
                        }}
                        variant="outlined"
                        color="primary"
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        pb: 1,
                      }}
                    >
                      <Card
                        elevation={hoverOn !== "edu-" + item?._id && 0}
                        sx={{
                          background: "transparent",
                        }}
                      >
                        <CardContent>
                          <Stack
                            spacing={2}
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="h4">
                              <Label
                                color="primary"
                                sx={{
                                  fontSize: 16,
                                }}
                              >
                                {capitalize(
                                  item?.relation || item?.role || "-"
                                )}
                              </Label>
                            </Typography>
                            {hoverOn === "edu-" + item?._id &&
                              !viewAsPublic &&
                              item?.isActive &&
                              (currentUser?.accountType === "admin" ||
                                currentUser?.orgId === org?._id) && (
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  component={Link}
                                  to={
                                    item?.type === "role"
                                      ? `${
                                          currentUser?.userId === profile?._id
                                            ? "/dashboard/account/profile/edit"
                                            : `/dashboard/users/${profile?._id}/edit`
                                        }?role=${item?.role}${
                                          currentUser?.accountType ===
                                            "admin" && org?._id
                                            ? `&organisation=${org?._id}`
                                            : ""
                                        }`
                                      : `${`/dashboard/customer-relations/${item?._id}/edit`}`
                                  }
                                >
                                  <EditTwoTone fontSize="1rem" />
                                </IconButton>
                              )}
                          </Stack>

                          <RelationDetailsAccordion item={item} />
                        </CardContent>
                      </Card>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          )}
        </Card>
      ))}
    </Stack>
  );
};

export default function EmploymentHistory() {
  const { profile, loading } = useContext(ProfileContext);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box>
      {profile?.employementHistory?.length > 0 && (
        <Card elevation={0}>
          <CardHeader
            title={
              <Typography variant="h3" component="h3">
                Connected Organisations
              </Typography>
            }
          />
          <CardContent>
            <Stack spacing={2}>
              <ListOfEmployementHistory />
            </Stack>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}
