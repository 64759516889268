import {
  Autocomplete,
  Box,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  capitalize,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT, CLEAN_EXIT_STATUS } from "src/utils/constants";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ArrowForwardIosTwoTone } from "@mui/icons-material";
import Label from "src/components/Label";
import { fDateTimeSuffix } from "src/utils/date-fns";

export default function CleanExits({ organisationId }) {
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const statusParam = searchParams.get("status");
  const typeParam = searchParams.get("type");
  const [filterObj, setFilterObj] = useState({});
  const [loading, setLoading] = useState(false);

  const [applications, setApplications] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [organisations, setOrganisations] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    (async () => {
      if (organisationId || currentUser?.accountType !== "admin") return;
      const resp = await axiosInstance.get(`/organisations/all`, {
        params: {
          isClaimed: true,
        },
      });
      if (resp?.status === 200) {
        setOrganisations(resp?.data);
      }
    })();
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }

      if (organisationId) {
        reqParams.params.organisation = organisationId;
      }

      const resp = await axiosInstance.get("/cleanexits", reqParams);
      if (resp?.status === 200) {
        setApplications(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
      }
      setLoading(false);
    })();
  }, [page, filterObj, typeParam, statusParam]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (e.target.value.length) {
                  setFilterObj({ ...filterObj, search: searchString });
                } else {
                  setFilterObj({ ...filterObj, search: null });
                }
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              if (e.target.value?.length > 0) {
                setSearchString(e.target.value);
              } else {
                setFilterObj({
                  ...filterObj,
                  search: null,
                });
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              p: "10px",
              m: 0.2,
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              setFilterObj({
                ...filterObj,
                search: searchString,
              });
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <Stack direction="row" alignItems="center" spacing={2}>
          {!organisationId && currentUser?.accountType === "admin" && (
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={organisations || []}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Organisation" />
              )}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  organisation: v?._id,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            />
          )}
          <Autocomplete
            disablePortal
            size="small"
            id="org-box"
            options={Object.keys(CLEAN_EXIT_STATUS) || []}
            getOptionLabel={(option) => capitalize(option || "")}
            sx={{ width: 250, mr: 2 }}
            value={filterObj?.status || ""}
            renderInput={(params) => <TextField {...params} label="Status" />}
            onChange={(e, v) => {
              setFilterObj({ ...filterObj, status: v });
              if (v) {
                searchParams.set("status", v);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("status");
                setSearchParams(searchParams);
              }
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          />
        </Stack>
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Application ID</TableCell>
                <TableCell>Employee</TableCell>
                {currentUser?.accountType === "admin" && (
                  <TableCell>Organisation</TableCell>
                )}
                <TableCell>Submitted By</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      maxWidth: "300px",
                    }}
                  >
                    {row?.cleanExitId || "-"}
                  </TableCell>
                  <TableCell>
                    {row?.user?.firstName || "-"} {row?.user?.lastName || ""}
                  </TableCell>
                  {currentUser?.accountType === "admin" && (
                    <TableCell>{row?.organisation?.title || "-"}</TableCell>
                  )}
                  <TableCell>
                    {row?.submitBy?.firstName || "-"}{" "}
                    {row?.submitBy?.lastName || ""}
                  </TableCell>
                  <TableCell>
                    <Label color={CLEAN_EXIT_STATUS[row?.status]?.color}>
                      <b>{CLEAN_EXIT_STATUS[row?.status]?.label}</b>
                    </Label>
                  </TableCell>
                  <TableCell>{fDateTimeSuffix(row?.createdAt)}</TableCell>
                  <TableCell align="right">
                    <Box display={"flex"} justifyContent="flex-end">
                      <IconButton
                        size="small"
                        component={Link}
                        to={`/dashboard/cleanexits/${row?.cleanExitId}`}
                        color="warning"
                      >
                        <ArrowForwardIosTwoTone />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

              {applications?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Applications Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && applications?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
