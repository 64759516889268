import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import QuestionInputField from "../../common/QuestionInputField";
import { checkIfAllQuestionsAnsweredInFormGroup } from "src/utils/helpers";

export default function SingleFormGroup({
  group,
  handleFormInput,
  formInput,
  setFormInput,
  disabled = false,
}) {
  const [contextualForms, setContextualForms] = useState([]);

  useEffect(() => {
    const _isAllAnswered = checkIfAllQuestionsAnsweredInFormGroup(
      group,
      formInput
    );
    if (_isAllAnswered) {
      // calculate weightage
      let _finalScore = 0;

      let _group = JSON.parse(JSON.stringify(group));
      const answers = Object.entries(formInput).map(([key, value]) => ({
        key,
        value,
      }));

      if (_group?.questionsGroup?.length > 0) {
        _group?.questionsGroup?.forEach((question) => {
          if (question?.questions?.length > 0) {
            question?.questions?.forEach((item) => {
              item.answer = answers?.find(
                (pair) => pair.key === item.name
              )?.value;

              if (item?.options && item?.options?.length > 0) {
                switch (item?.type) {
                  case "checkbox":
                    item?.options?.map((option) => {
                      if (
                        item?.answer?.includes(option?.label) ||
                        item?.answer?.includes(option?.value)
                      ) {
                        _finalScore = _finalScore + option?.weightage;
                      }
                    });
                    break;
                  case "radio":
                  case "select":
                    item?.options?.map((option) => {
                      if (
                        option?.label === item?.answer ||
                        option?.value === item?.answer
                      ) {
                        _finalScore = _finalScore + option?.weightage;
                      }
                    });
                    break;
                  case "rating":
                    const answerIndex = item?.answer;
                    if (answerIndex || answerIndex >= 0) {
                      _finalScore = _finalScore + answerIndex;
                    }
                    break;

                  default:
                }
              }
            });
          }
        });
      }

      _group?.contextualForms?.forEach((cForm) => {
        if (cForm?.min <= _finalScore && _finalScore <= cForm?.max) {
          // add to contextual forms if not already added
          if (!contextualForms?.find((form) => form?._id === cForm?._id)) {
            setContextualForms((prevState) => [...prevState, cForm]);
          }
        } else {
          // remove from contextual forms if already added
          if (contextualForms?.find((form) => form?._id === cForm?._id)) {
            setContextualForms((prevState) =>
              prevState.filter((form) => form?._id !== cForm?._id)
            );
            // remove answers from formInput if removed from contextual forms
            setFormInput((prevState) => {
              const newState = { ...prevState };
              delete newState[cForm?.name];
              return newState;
            });
          }
        }
      });
    }
  }, [formInput]);

  return (
    <Card key={group?._id}>
      <CardHeader
        title={
          <Typography variant="h3" component="div">
            {group?.title || ""}
          </Typography>
        }
        subheader={group?.description || ""}
      />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          {group?.questionsGroup?.map((questionGroup) => (
            <Card key={questionGroup?._id}>
              <CardHeader
                title={
                  <Typography variant="h4" component="div">
                    {questionGroup?.title || ""}
                  </Typography>
                }
                subheader={questionGroup?.description || ""}
              />
              <Divider />
              <CardContent>
                <Stack spacing={2}>
                  {questionGroup?.questions?.map((question, index) => (
                    <Card key={question?._id} elevation={0}>
                      <CardHeader
                        title={
                          <Typography variant="h5" component="div">
                            {index + 1}. {question?.title || ""}
                            {question?.required && (
                              <span
                                style={{
                                  color: "red",
                                  marginInline: 3,
                                }}
                              >
                                *
                              </span>
                            )}
                          </Typography>
                        }
                        subheader={
                          <Typography variant="subtitle1" sx={{ mx: 1.5 }}>
                            {question?.description || ""}
                          </Typography>
                        }
                      />
                      <CardContent sx={{ py: 0, mx: 1.5 }}>
                        <QuestionInputField
                          question={question}
                          handleFormInput={handleFormInput}
                          formInput={formInput}
                          setFormInput={setFormInput}
                          disabled={disabled || false}
                        />
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </CardContent>
      {contextualForms?.length > 0 && (
        <Fragment>
          <Divider sx={{ mt: 3 }}>Contextual Form</Divider>
          <Stack
            spacing={2}
            sx={{
              background: "#f6f6f6",
              p: 2,
              px: 3,
            }}
          >
            {contextualForms?.map((cForm, index) => (
              <Card
                key={cForm?._id}
                elevation={0}
                sx={{
                  background: "#f6f6f6",
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h5" component="div">
                      {index + 1}. {cForm?.title || ""}
                      {cForm?.required && (
                        <span
                          style={{
                            color: "red",
                            marginInline: 3,
                          }}
                        >
                          *
                        </span>
                      )}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="subtitle1" sx={{ mx: 1.5 }}>
                      {cForm?.description || ""}
                    </Typography>
                  }
                />
                <CardContent sx={{ py: 0, mx: 1.5 }}>
                  <QuestionInputField
                    question={cForm}
                    handleFormInput={handleFormInput}
                    formInput={formInput}
                    setFormInput={setFormInput}
                    disabled={disabled || false}
                  />
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Fragment>
      )}
    </Card>
  );
}
