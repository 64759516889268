import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Tab,
  Tabs,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import IndustryInputFields from "./IndustryInputFields";
import CustomerRelationFields from "./CustomerRelationFields";
import { INPUT_FIELDS_TYPE } from "src/utils/constants";

export default function InputFields() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tagTypeParam = searchParams.get("type");
  const [inputFieldType, setInputFieldType] = useState(
    tagTypeParam || "industries"
  );

  const handleTabsChange = (event, newValue) => {
    setInputFieldType(newValue);

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      type: newValue,
    });
  };

  return (
    <Stack spacing={3} my={2}>
      <Card>
        <CardContent>
          <TabsWrapper
            variant={"fullWidth"}
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={inputFieldType || ""}
            sx={{ mb: 2 }}
          >
            {INPUT_FIELDS_TYPE.map((_tag) => (
              <Tab key={_tag?.value} value={_tag?.value} label={_tag?.label} />
            ))}
          </TabsWrapper>
          <CardHeader
            title={`${
              INPUT_FIELDS_TYPE.find((i) => i.value === inputFieldType)?.label
            }
            Input Fields`}
          />
          <Divider />
          {
            {
              industries: <IndustryInputFields />,
              customerRelations: <CustomerRelationFields />,
            }[inputFieldType]
          }
        </CardContent>
      </Card>
    </Stack>
  );
}

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);
