import { Box, Container, Divider, Typography } from "@mui/material";
import React from "react";

export default function ConsentText() {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          style={{
            width: "max-content",
          }}
        >
          <img
            src={"/static/images/logo/logo.png"}
            alt={""}
            style={{ width: "52px" }}
          />
          <Typography variant="h2" ml={1} color={"black"}>
            EthixFirst
          </Typography>
        </Box>
        <Box textAlign={"right"}>
          <Typography variant="h3" color={"black"}>
            Rating Consent Form
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h4">
        Undertaking and Consent for EthixFirst Ethics at Workplace Rating
        Service
      </Typography>
      <br />
      <br />
      <Typography paragraph sx={{ fontSize: 16 }}>
        I as an applicant for EthixFirst rating service, authorize Clean Exit to
        collect any data relating to me compliance / non-compliance of any
        rules, policies, byelaws, processes, laws and otherwise, during the
        period of study or atier the completion of my study, including
        internship / probation and during the entire course of my employment or
        service (current or future), as applicable, (or for a period which makes
        such noncompliance reasonably recordable) (the “Data”).
      </Typography>
      <br />
      <Typography paragraph sx={{ fontSize: 16 }}>
        Such Data is being recorded and processed by EthixFirst and its
        partners, including external third- party agencies. I shall abide by any
        rules and regulations set by EthixFirst in this behalf. I hereby
        voluntarily authorize EthixFirst to conduct any background checks /
        investigations and also authorize the use of any Data with respect to my
        working at any Organization, EthixFirst and any third-party agency in
        collecting, analyzing, assimilating, recording and sharing this
        information with any third party.
      </Typography>
      <br />
      <Typography paragraph sx={{ fontSize: 16 }}>
        I agree and understand that recording or processing of any Data or
        record (negative or otherwise) of my compliance or non-compliance and
        dealing with any data pertaining to it (including sharing the Data with
        any third party and / or publishing by EthixFirst and third-party
        agency) is strictly in the spirit of rating the workforce for their
        ethical conduct in the industry / industries and does not amount to
        libel. I agree that I shall not bring any suit or cause of action
        against EthixFirst or the concerned third-party agency and indemnify
        EthixFirst and any third-party agencies from and against any and all
        claims, demands, damages, injuries, expenses and liability arising from
        my acts or omissions pertaining to the Data.
      </Typography>
      <br />
      <Typography paragraph sx={{ fontSize: 16 }}>
        I agree that EthixFirst will hold and continue to maintain the
        previously collected Data, in the event of termination of my
        registration / account. I also agree to follow the laws, rules,
        regulations, guidelines set up by the EthixFirst and in case of any
        breach of such laws, rules, regulations, and guidelines, I shall
        indemnify and hold EthixFirst harmless from any damages or claims.
      </Typography>
      <br />
      <Typography paragraph sx={{ fontSize: 16 }}>
        The information provided in this consent and undertaking, and any
        attachments thereto is true and complete, to the best of my knowledge. I
        understand that any falsification or omission of information may atiract
        appropriate action / rating by EthixFirst.
      </Typography>
    </Container>
  );
}
