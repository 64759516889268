import { Helmet } from "react-helmet-async";
import {
  Alert,
  Box,
  Card,
  Container,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { WarningTwoTone, ExpandMoreTwoTone } from "@mui/icons-material";

import FormHeader from "./form/FormHeader";
import { Fragment, useContext, useEffect, useState } from "react";
import { FormContext } from "src/contexts/FormContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import EmptyHere from "src/components/EmptyHere";
import GroupInfo from "./dimension/GroupInfo";
import ContextualForm from "./contextual/ContextualForm";
import QuestionsTreeView from "./QuestionsTreeView";
import NewQuestionGroupDialog from "./parameter/NewQuestionGroupDialog";
import MisconductForm from "./misconduct/MisconductForm";
import Sidebar from "src/layouts/FormBuilderLayout/Sidebar";
import NewFormGroupDialog from "./dimension/NewFormGroupDialog";
import { InfoOutlined } from "@mui/icons-material";
import ScoringExamples from "../common/ScoringExamples";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Notify } from "notiflix";
import { handleLogout } from "src/utils/axiosInstance";
import { useTranslation } from "react-i18next";

function FormBuilder() {
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const { loading, form, selectedFormGroup } = useContext(FormContext);
  const [currentTab, setCurrentTab] = useState("questions");
  const [formType, setFormType] = useState("Form");

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };
  const handleFormTypeChange = (_event, value) => {
    setFormType(value);
  };

  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalWeightage, setTotalWeightage] = useState(0);

  useEffect(() => {
    if (form) {
      if (
        currentUser?.accountType === "admin" ||
        currentUser?.orgId === form?.organisation?._id
      ) {
      } else {
        Notify.failure("You are not authorised");
        handleLogout();
      }

      const _totalQuestions = form?.formGroups?.reduce((total, group) => {
        const questionsInGroup = group.questionsGroup?.reduce(
          (groupTotal, qGroup) => groupTotal + qGroup.questions.length,
          0
        );
        return total + questionsInGroup;
      }, 0);
      setTotalQuestions(_totalQuestions || 0);

      const grandTotalWeightage = form?.formGroups
        ?.flatMap((group) =>
          group.questionsGroup?.flatMap((qGroup) =>
            qGroup.questions?.flatMap((question) => {
              // if question type is in radio, select, or rating, the get greatest weightage of all options
              if (
                question.type === "radio" ||
                question.type === "select" ||
                question.type === "rating"
              ) {
                return Math.max(
                  ...question.options?.map((option) => option.weightage)
                );
              }
              // if question type is in checkbox, then get sum of all weightages
              else if (question.type === "checkbox") {
                return question.options?.reduce(
                  (totalWeightage, option) => totalWeightage + option.weightage,
                  0
                );
              } else {
                return question.weightage || 0;
              }
            })
          )
        )
        .reduce((total, weightage) => total + weightage, 0);

      setTotalWeightage(grandTotalWeightage || 0);
    }
  }, [form]);

  return loading === "all" ? (
    <SuspenseLoader />
  ) : form === null ? (
    <EmptyHere />
  ) : (
    <>
      <Helmet>
        <title>Form Builder</title>
      </Helmet>

      <Card
        sx={{
          overflow: "visible",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={0}
          sx={{
            px: 3,
          }}
        >
          <Sidebar />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              m: 2,
            }}
          />
          <FormHeader />
        </Stack>
      </Card>

      <Container maxWidth={"xl"}>
        <Stack spacing={3} sx={{ mt: 3 }}>
          {form?.includeScore && (
            <Alert
              severity="success"
              icon={<InfoOutlined />}
              action={<ScoringExamples />}
            >
              <Typography color={"GrayText"}>
                There are total{" "}
                <Typography sx={{ mx: 1 }} component={"span"} fontWeight={900}>
                  {totalQuestions || 0}
                </Typography>
                Questions with{" "}
                <Typography sx={{ mx: 1 }} component={"span"} fontWeight={900}>
                  {totalWeightage || 0}
                </Typography>
                Weightage in Total. We will calculate Total Score out of{" "}
                <Typography sx={{ mx: 1 }} component={"span"} fontWeight={900}>
                  1000
                </Typography>
                accordingly.
              </Typography>
            </Alert>
          )}
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              spacing={0}
              justifyContent={"space-between"}
            >
              <Box ml={2}>
                <Tabs
                  onChange={handleFormTypeChange}
                  value={formType}
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTabs-indicator": {
                      borderRadius: "5px 5px 0 0",
                    },
                  }}
                >
                  {["Form", "Misconduct Form"].map((tab) => (
                    <Tab key={tab} label={tab} value={tab} />
                  ))}
                </Tabs>
              </Box>
            </Stack>

            {formType === "Misconduct Form" && <MisconductForm />}
            {formType === "Form" && (
              <Fragment>
                <Card>
                  <GroupInfo
                    currentTab={currentTab}
                    handleTabsChange={handleTabsChange}
                  >
                    <div>
                      <QuestionsTreeView />
                      <NewQuestionGroupDialog />
                    </div>
                    {form?.includeScore && (
                      <>
                        <Accordion
                          aria-labelledby={"Accordion-context"}
                          id={"Accordion-id-context"}
                          sx={{ backgroundColor: "rgb(240, 235, 248)" }}
                        >
                          <AccordionSummary
                            aria-controls={"AccordionSummary-context"}
                            id={"AccordionSummary-id-context"}
                            expandIcon={<ExpandMoreTwoTone />}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <WarningTwoTone />
                              <Typography variant="h4">
                                {t("Contextual Form")}
                              </Typography>
                              <Typography variant="subtitle2">
                                Total Questions:{" "}
                                {
                                  form?.formGroups?.find(
                                    (group) =>
                                      group._id === selectedFormGroup?._id
                                  )?.contextualForms.length
                                }
                              </Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.background.default,
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <ContextualForm />
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                  </GroupInfo>
                </Card>
                <Box sx={{ my: 2 }}>
                  <NewFormGroupDialog />
                </Box>
              </Fragment>
            )}
          </Box>
        </Stack>
      </Container>
    </>
  );
}

export default FormBuilder;
