import {
  Grid,
  Box,
  CardActionArea,
  Card,
  Avatar,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import Text from "src/components/Text";
import {
  ArrowForwardIos,
  BrowserNotSupportedOutlined,
  DoneOutlineTwoTone,
  FileCopyTwoTone,
  PermMediaTwoTone,
} from "@mui/icons-material";
import { ProfileContext } from "src/contexts/ProfileContext";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
    padding: ${theme.spacing(2.5)};
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiTouchRipple-root {
        opacity: .15;
    }

    &:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: .02;
        }
    }
  `
);

function AboutNavigation({ selectedTab, setSelectedTab, documents }) {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { profile } = useContext(ProfileContext);

  return (
    <Grid container spacing={4}>
      {[
        {
          label: "About",
          icon: <PersonTwoToneIcon />,
          value: "overview",
          link: "details",
          color: "primary",
        },
        {
          label: "Acc Verification",
          icon: profile?.verified ? (
            <DoneOutlineTwoTone />
          ) : (
            <BrowserNotSupportedOutlined />
          ),
          value: profile?.verified ? "Verified" : "Not verified yet",
          link: "consent",
          color: profile?.verified ? "success" : "error",
        },
        {
          label: "Photos Gallery",
          icon: <PermMediaTwoTone />,
          value: `${
            documents?.data?.filter((item) => item?.category === "images")
              ?.length || 0
          } photos`,

          link: "photos",
          color: "warning",
        },
        {
          label: "Documents ",
          icon: <FileCopyTwoTone />,
          value: `${
            documents?.data?.filter((item) => item?.category === "documents")
              ?.length || 0
          } documents`,
          link: "documents",
          color: "info",
        },
      ].map((item, idx) => (
        <Grid item xs={3} key={idx}>
          <Card
            onClick={() => {
              if (selectedTab === item.link) return;
              setSelectedTab(item.link);
              searchParams.set("about_tab", item.link);
              setSearchParams(searchParams);
            }}
            sx={{
              backgroundColor: `${theme.colors[item.color].lighter} !important`,
            }}
          >
            <CardActionAreaWrapper>
              <Box display="flex" alignItems="center">
                <Avatar
                  sx={{
                    width: 64,
                    height: 64,
                    background: `${theme.colors[item.color].main}`,
                    color: `${
                      theme.palette[item.color].contrastText
                    } !important`,
                  }}
                >
                  {item.icon}
                </Avatar>
                <Box ml={1.5}>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    sx={{
                      fontSize: `${theme.typography.pxToRem(16)}`,
                      fontWeight: "fontWeightBold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: `${theme.typography.pxToRem(14)}`,
                    }}
                  >
                    <Text color={item.color}>{item.value}</Text>
                  </Typography>
                </Box>
              </Box>
              <Typography
                component="span"
                color="text.secondary"
                sx={{
                  opacity: 0.7,
                  display: "flex",
                  transform: selectedTab === item.link && "rotate(90deg)",
                  transition: "transform 0.2s ease-in-out",
                }}
              >
                <ArrowForwardIos />
              </Typography>
            </CardActionAreaWrapper>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default AboutNavigation;
