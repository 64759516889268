import {
  AddBoxOutlined,
  CancelPresentationOutlined,
  EditTwoTone,
  IndeterminateCheckBoxOutlined,
} from "@mui/icons-material";
import { TreeItem, TreeView, treeItemClasses } from "@mui/lab";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "src/contexts/FormContext";
import { useSpring, animated } from "@react-spring/web";
import { InputTypesObject, MAX_SCORE } from "src/utils/constants";
import MisconductFormDialog from "./MisconductDialog";
import CreateUpdateQuestion from "./CreateUpdateQuestion";
import EditableMisconduct from "./EditableMisconduct";
import Handler from "src/components/Handler";
import Nestable from 'react-nestable';
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  margin: 8,
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function MisconductForm() {
  const { form, handleGetForm, isAddOpen } = useContext(FormContext);

  const [misconductForms, setMisconductForms] = useState([]);
  const [selectedMisconductForm, setSelectedMisconductForm] = useState(null);

  useEffect(() => {
    if (form) {
      const cfForms = form?.misconductForms;
      setMisconductForms(cfForms || []);
    }
  }, [form]);

  const handleUpdateQuestion = async (updatedQs) => {
    try {
      
  
      // Assume items is an array containing the modified formInput objects
      const updatePromises = updatedQs.map(async (item) => {
        const resp = await axiosInstance.put(`/forms/misconduct/${form?._id}/${item._id}`, item);
        if (resp?.status !== 200) {
          throw new Error(
            resp?.data?.message ||
              resp?.statusText ||
              "Failed to update question"
          );
        }
      });
  
      await Promise.all(updatePromises);
  
      // Refresh the form data after updating all questions
      await handleGetForm(form?._id);
  
      Notify.success("Successfully");
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "An error occurred"
      );
    }
  };

  const handleOnChangeQuestions = ({items}) => {
    console.log(items);
    const updatedQs = items.map((item, index) => {
      return {
        ...item,
        position: index
      };
    });
    handleUpdateQuestion(updatedQs)
  }

  return (
    <Box component={Paper} sx={{ p: 2 }}>
      {/* <Typography color={"GrayText"}>
        Cyber Misconduct Form's triggering is based on Score. So, You can set
        the triggering value of each Contextual Form in{" "}
        <b>Range of 0 to {MAX_SCORE}.</b>
      </Typography> */}
      <Typography color={"GrayText"}>
        Cyber Misconduct Questions are used by Organisation to submit
        proof/report/evendence of any misconduct by the Employee.
      </Typography>
      {isAddOpen==="misconduct" && <CreateUpdateQuestion selectedMisconductForm={selectedMisconductForm}
        setSelectedMisconductForm={setSelectedMisconductForm} />}
        {misconductForms.length===0 && <CreateUpdateQuestion mode="new" selectedMisconductForm={selectedMisconductForm}
        setSelectedMisconductForm={setSelectedMisconductForm} />}
      {/* <MisconductFormDialog
        selectedMisconductForm={selectedMisconductForm}
        setSelectedMisconductForm={setSelectedMisconductForm}
      /> */}
      <Divider sx={{ my: 2 }} />
      {/* <TreeView
        defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
        defaultExpandIcon={<AddBoxOutlined />}
        defaultEndIcon={<CancelPresentationOutlined color="disabled" />}
      > */}
      <Nestable
              idProp="_id"
              maxDepth={1}
              items={misconductForms?.sort(function(a,b){
                return a.position - b.position;
              })}
              onChange={handleOnChangeQuestions}
              handler={<Handler />}
              renderItem={({item, handler})=><EditableMisconduct
              key={item._id}
            
            handler={handler}
                  question={item}
                  questionTypeIcon={InputTypesObject[item?.type]
                    ? InputTypesObject[item?.type].icon
                    : null}
                    selectedMisconductForm={selectedMisconductForm}
                    setSelectedMisconductForm={setSelectedMisconductForm}
                  />}
            />
        {misconductForms?.length > 0 ? (
          // misconductForms?.map((item, index) => (
          //   <StyledTreeItem
          //     nodeId={item?._id}
          //     key={item?._id}
          //     onClick={() => setSelectedMisconductForm(item)}
          //     label={
                
          //     }
          //   >
          //     {["radio", "checkbox", "select", "rating"].includes(
          //       item?.type
          //     ) && (
          //       <>
          //         {item?.options?.length > 0 ? (
          //           item?.options?.map((i) => (
          //             <StyledTreeItem
          //               nodeId={i?._id}
          //               key={i?._id}
          //               label={i?.label || "No Label"}
          //             />
          //           ))
          //         ) : (
          //           <Typography
          //             sx={{
          //               textAlign: "center",
          //             }}
          //             variant="caption"
          //           >
          //             No Options Added Yet
          //           </Typography>
          //         )}
          //       </>
          //     )}
          //   </StyledTreeItem>
          // ))
          null
        ) : (
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="caption"
          >
            No Contextual Form Added Yet
          </Typography>
        )}
      {/* </TreeView> */}
    </Box>
  );
}
