import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import CountriesWithCities from "src/utils/countries/cities.json";

export default function ZonesByCity() {
  const [newTagInput, setNewTagInput] = useState("");
  const [orgTags, setOrgTags] = useState({
    type: "",
    tags: [],
  });
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    if (!selectedCity) return;
    (async () => {
      const resp = await axiosInstance.get("/tags/type", {
        params: {
          type: selectedCity,
        },
      });
      if (resp?.status === 200) {
        setOrgTags(
          resp?.data || {
            type: selectedCity,
            tags: [],
          }
        );
      }
    })();
  }, [selectedCity]);

  const handleEditTag = async (reqObj) => {
    try {
      // reqObj = {action: "add/remove", type, inputString, tag: "tag to be removed"}
      setLoading(true);
      let _tags = [];
      const tagType = reqObj?.type;
      const inputString = reqObj?.inputString;
      if (reqObj?.action === "add") {
        _tags = [...orgTags?.tags, inputString];
      }
      if (reqObj?.action === "remove") {
        _tags = orgTags?.tags?.filter((item) => item !== reqObj?.tag);
      }

      const reqParams = {
        type: tagType,
      };
      const resp = await axiosInstance.put(`/tags`, {
        ...reqParams,
        tags: _tags,
      });
      if (resp.status === 200) {
        setOrgTags({ ...orgTags, tags: _tags });
        setLoading(false);
        setNewTagInput("");
      }
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };
  return (
    <Card sx={{ my: 2 }}>
      <Stack spacing={2} sx={{ p: 2 }} direction={"row"}>
        <Autocomplete
          autoHighlight
          sx={{ width: "30%" }}
          options={CountriesWithCities?.map((item) => item?.country)}
          value={selectedCountry || "India"}
          onChange={(e, value) => {
            setSelectedCountry(value);
            setSelectedCity("");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Autocomplete
          fullWidth
          autoHighlight
          sx={{ width: "30%" }}
          options={
            CountriesWithCities?.find(
              (item) => item?.country === selectedCountry
            )?.cities || []
          }
          value={selectedCity}
          onChange={(e, value) => {
            setSelectedCity(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select City"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Stack>
      <Divider />

      {selectedCity && (
        <CardContent>
          <Box sx={{ my: 2 }} display="flex">
            <TextField
              fullWidth
              label={"Type Field name here"}
              type="text"
              variant="outlined"
              size="small"
              value={newTagInput}
              onChange={(e) => {
                setNewTagInput(e.target.value);
              }}
            />
            <LoadingButton
              size="small"
              sx={{ ml: 2 }}
              color="error"
              variant="contained"
              onClick={async () => {
                if (orgTags["users"]?.includes(newTagInput)) {
                  Notify.warning("This Field already exists");
                  return;
                }
                const done = await handleEditTag({
                  action: "add",
                  type: selectedCity,
                  inputString: newTagInput,
                });
                if (done) {
                  setNewTagInput("");
                }
              }}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Box>

          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {orgTags?.tags?.length > 0 ? (
              orgTags?.tags?.map((item, idx) => (
                <ListItem
                  key={idx}
                  disableGutters
                  sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  secondaryAction={
                    <>
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleEditTag({
                            action: "remove",
                            type: selectedCity,
                            tag: item,
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText primary={item} />
                </ListItem>
              ))
            ) : (
              <ListItem
                disableGutters
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  textAlign: "center",
                }}
              >
                <ListItemText primary="No Fields added yet" />
              </ListItem>
            )}
          </List>
        </CardContent>
      )}
    </Card>
  );
}
