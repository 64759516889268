import { AddCircleOutlineTwoTone, EditTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";
import { MonthNames } from "src/utils/commonConstants";

export default function EditEducationDialog({ portfolioItem }) {
  const { portfolio, setPortfolio, handleSavePortfolio } =
    useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    setFormInput(portfolioItem || {});
  }, [portfolioItem]);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsWait(false);
    if (!portfolioItem) setFormInput({});
  };

  const handleSubmit = async () => {
    setIsWait(true);
    formInput.category = "education";
    const resp = await handleSavePortfolio(formInput);
    if (resp) {
      setFormInput({});
      handleClose();
      Notify.success("Successful");
    }
    setIsWait(false);
  };

  const handleDelete = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(
        `/portfolios/${portfolioItem._id}`
      );
      if (resp.status === 200) {
        setFormInput({});
        handleClose();
        const newPortfolio = portfolio.filter(
          (item) => item._id !== portfolioItem._id
        );
        setPortfolio(newPortfolio);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        sx={{
          p: 0,
          m: 0,
        }}
      >
        {portfolioItem?._id ? (
          <EditTwoTone fontSize="small" />
        ) : (
          <AddCircleOutlineTwoTone />
        )}
      </IconButton>

      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Fill out the form below to {formInput?._id ? "edit" : "add"} education
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <TextField
              label="Institution *"
              placeholder="e.g. IIT Kharagpur"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />
            <TextField
              label="Degree *"
              placeholder="e.g. B.Tech"
              fullWidth
              name="degree"
              value={formInput?.degree || ""}
              onChange={handleInputChange}
            />
            <TextField
              label="Field of Study *"
              placeholder="e.g. Computer Science"
              fullWidth
              name="field_of_study"
              value={formInput?.field_of_study || ""}
              onChange={handleInputChange}
            />
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Start Month *</InputLabel>
                <Select
                  value={formInput?.start_month || ""}
                  label="Start Month"
                  name="start_month"
                  onChange={handleInputChange}
                >
                  {MonthNames.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Start Year *</InputLabel>
                <Select
                  value={formInput?.start_year || ""}
                  label="Start Year"
                  name="start_year"
                  onChange={handleInputChange}
                >
                  {Array.from(
                    { length: 70 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel>End Month</InputLabel>
                <Select
                  value={formInput?.end_month || ""}
                  label="End Month"
                  name="end_month"
                  onChange={handleInputChange}
                >
                  {MonthNames.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>End Year</InputLabel>
                <Select
                  value={formInput?.end_year || ""}
                  label="End Year"
                  name="end_year"
                  onChange={handleInputChange}
                >
                  {Array.from(
                    { length: 70 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <TextField
              label="Grade"
              fullWidth
              name="grade"
              value={formInput?.grade || ""}
              onChange={handleInputChange}
            />
            <TextField
              placeholder="e.g. I completed my B.Tech in Computer Science from IIT Kharagpur in 2019"
              fullWidth
              name="description"
              value={formInput?.description || ""}
              onChange={handleInputChange}
              multiline
              rows={4}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput?.highlighted}
                  onChange={(e) =>
                    setFormInput({
                      ...formInput,
                      highlighted: e.target.checked,
                    })
                  }
                />
              }
              label="Highlight this Education"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
            }}
            justifyContent="space-between"
          >
            <Box>
              {portfolioItem?._id && (
                <Button
                  color="error"
                  onClick={() => {
                    Confirm.show(
                      "Confirmation",
                      "Are you sure you want to delete?",
                      "Confirm",
                      "Cancel",
                      () => handleDelete(),
                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      }
                    );
                  }}
                  disabled={isWait}
                >
                  Delete
                </Button>
              )}
            </Box>

            <Box>
              <Button onClick={handleClose} disabled={isWait}>
                Cancel
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                loading={isWait}
                variant="contained"
                color="primary"
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}
