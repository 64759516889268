import { Box } from "@mui/material";
import HeaderStats from "./components/HeaderStats";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import Block2 from "./misc/Block2";
import FindOrg from "./misc/FindOrg";

function MiscAnalytics() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axiosInstance.get(`/dashboard`);
        if (response.status === 200) {
          setStats(response.data || null);
        }
      } catch (error) {
        console.log(error);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  return (
    <Box my={2}>
      <HeaderStats stats={stats} />

      <Block2 stats={stats} />

      <FindOrg stats={stats} />
    </Box>
  );
}

export default MiscAnalytics;
