import { Avatar, Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        align-items: center;
`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(25)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      height: ${theme.spacing(5)};
      width: ${theme.spacing(5)};
      margin-right: ${theme.spacing(1)};
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <AvatarWrapper src={"/static/images/logo/logo.png"} variant="rounded" />
      <Box
        component="span"
        sx={{
          display: { xs: "none", sm: "inline-block" },
        }}
      >
        <LogoText>EthixFirst</LogoText>
      </Box>
    </LogoWrapper>
  );
}

export default Logo;
