import { ErrorOutlineOutlined } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React, { useContext } from "react";
import ProfilePublish from "../addEdit/ProfilePublish";
import { ProfileContext } from "src/contexts/ProfileContext";

export function ProfileStregthList({ inCompleteFields, sx }) {
  return (
    <List
      sx={{
        ...sx,
        cursor: "pointer",
      }}
    >
      {inCompleteFields
        ?.filter((item) => item?.length > 0)
        ?.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ErrorOutlineOutlined color="warning" />
            </ListItemIcon>
            <ListItemText>{item}</ListItemText>
          </ListItem>
        ))}
    </List>
  );
}

export default function ProfileStregth({}) {
  const { percentComplete, inCompleteFields } = useContext(ProfileContext);
  return percentComplete < 100 ? (
    <Card
      sx={{
        background: (theme) => `${theme.colors.gradients.blue4}`,
        color: (theme) => `${theme.colors.alpha.trueWhite[70]}`,
      }}
    >
      {percentComplete < 75 ? (
        <CardContent>
          <Typography color="error" variant="h4">
            Your profile needs to be minimum 75% complete to make profile public
          </Typography>
        </CardContent>
      ) : (
        <>
          <ProfilePublish />
        </>
      )}
      <CardHeader
        title={
          <Typography variant="h2" color={"white"}>
            {`${percentComplete}%`}
          </Typography>
        }
        subheader={
          <Typography variant="h4" color={"white"}>
            Profile Strength
          </Typography>
        }
      />
      <CardContent>
        <BorderLinearProgress
          color={
            percentComplete < 50
              ? "error"
              : percentComplete < 80
              ? "warning"
              : "success"
          }
          variant="determinate"
          value={percentComplete}
        />
      </CardContent>
      <ProfileStregthList inCompleteFields={inCompleteFields} />
    </Card>
  ) : (
    <></>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));
