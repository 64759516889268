import React, { Fragment, useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  Divider,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Box,
  styled,
  alpha,
  ListItemButton,
  Button,
} from "@mui/material";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";
import { CardMembershipOutlined } from "@mui/icons-material";
import EditCertificateDialog from "../addEdit/EditCertificate";
import { fDate } from "src/utils/date-fns";

export default function UserCertificates() {
  const { currentUser } = useContext(GlobalContext);
  const { profile, portfolio, viewAsPublic } = useContext(ProfileContext);
  const [hoverOn, setHoverOn] = useState("");

  return (
    <Stack spacing={3}>
      <Card elevation={0}>
        <CardHeader
          title={
            <Typography variant="h3" component="h3">
              Certificates
            </Typography>
          }
          action={
            currentUser?.userId === profile?._id &&
            !viewAsPublic && <EditCertificateDialog />
          }
        />
        <CardContent>
          <Stack spacing={2} mx={3}>
            {portfolio?.filter((item) => item?.category === "certificates")
              ?.length ? (
              portfolio
                ?.filter((item) => item?.category === "certificates")
                ?.sort((a, b) => {
                  // sort by issue date (newest first)
                  if (a?.issue_date && b?.issue_date) {
                    const aDate = new Date(a?.issue_date);
                    const bDate = new Date(b?.issue_date);
                    return bDate - aDate;
                  } else {
                    return 0;
                  }
                })
                ?.map((item, idx) => (
                  <Fragment key={idx}>
                    <Divider />
                    <ListItemButtonWrapper
                      sx={{
                        display: { xs: "block", sm: "flex" },
                        py: 2,
                        px: 2.5,
                      }}
                      onMouseOver={() => setHoverOn("pro-" + idx)}
                      // onMouseLeave={() => setHoverOn("")}
                    >
                      <ListItemAvatar
                        sx={{
                          minWidth: "auto",
                          mr: 2,
                          mb: { xs: 2, sm: 0 },
                        }}
                      >
                        {item?.issuer_website ? (
                          <Avatar
                            variant="square"
                            sx={{
                              width: 42,
                              height: 42,
                            }}
                            alt={""}
                            src={
                              `https://logo.clearbit.com/` +
                              item?.issuer_website
                            }
                          />
                        ) : (
                          <Avatar
                            sx={{
                              background: "transparent",
                              color: (theme) => theme.colors.primary.main,
                              width: 42,
                              height: 42,
                            }}
                            variant="square"
                          >
                            <CardMembershipOutlined sx={{ fontSize: 42 }} />
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography color="text.primary" variant="h5">
                            {item?.title}
                          </Typography>
                        }
                        secondary={
                          <Stack direction="row" spacing={1}>
                            <Typography
                              noWrap
                              variant="subtitle2"
                              component={item?.issuer_website ? "a" : "span"}
                              href={`//:${item?.issuer_website}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              Issued by {item?.issued_by}
                            </Typography>
                            <Typography>•</Typography>
                            <Typography noWrap variant="subtitle2">
                              {fDate(item?.issue_date)}
                            </Typography>
                          </Stack>
                        }
                      />
                      <Box component="span">
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="outlined"
                            size="small"
                            component="a"
                            href={
                              item?.certificate_link
                                ? item?.certificate_link
                                : process.env.REACT_APP_DO_SPACE_URL +
                                  item?.certificate_url +
                                  `?${Math.random()}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </Button>
                          {hoverOn === "pro-" + idx &&
                            (currentUser?.accountType === "admin" ||
                              currentUser?.userId === profile?._id) &&
                            !viewAsPublic && (
                              <EditCertificateDialog portfolioItem={item} />
                            )}
                        </Stack>
                      </Box>
                    </ListItemButtonWrapper>
                  </Fragment>
                ))
            ) : (
              <EmptyHere py={1} title={"No certificates added yet"} />
            )}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

const ListItemButtonWrapper = styled(ListItemButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(["transform", "box-shadow"])};
    transform: scale(1);
    background: ${theme.colors.alpha.white[100]};
    position: relative;
    z-index: 5;

    &:hover {
        border-radius: ${theme.general.borderRadius};
        background: ${theme.colors.alpha.white[100]};
        z-index: 6;
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        transform: scale(1.08);
    }
  `
);
