import {
  Box,
  Card,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  styled,
  Stack,
  LinearProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  CardHeader,
  Alert,
  AlertTitle,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";

import { useTranslation } from "react-i18next";
import Notiflix, { Notify } from "notiflix";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";

import { PAGE_LIMIT } from "src/utils/constants";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  AddTwoTone,
  EditTwoTone,
  OpenInNew,
  RemoveOutlined,
} from "@mui/icons-material";
import EmptyHere from "src/components/EmptyHere";
import { LoadingButton } from "@mui/lab";
import { GlobalContext } from "src/contexts/GlobalContext";

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`
);

const ButtonSearch = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`
);

export default function SendCampaign({ event }) {
  const navigator = useNavigate();
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("search");
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState(searchParam || "");
  const [filterObj, setFilterObj] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [registeredUsers, setRegisteredUsers] = useState({});
  const [newCustomerRelationUsers, setNewCustomerRelationUsers] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    if (searchParam?.length > 0) {
      setSearchString(searchParam);
      setFilterObj({
        ...filterObj,
        search: searchParam,
      });
      getRegisteredUsers({
        params: { search: searchParam },
      });
    } else {
      setSearchString("");
      setFilterObj({
        ...filterObj,
        search: null,
      });
    }
  }, [searchParam]);

  useEffect(() => {
    (async () => {
      try {
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        if (searchString) {
          getRegisteredUsers(reqParams);
        }
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page]);

  const getRegisteredUsers = async (reqParams) => {
    try {
      const resp = await axiosInstance.get(`/customer-relations`, reqParams);
      if (resp?.status === 200) {
        setRegisteredUsers(resp?.data || {});
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleSendCampaign = async () => {
    try {
      setIsWait(true);

      const customerRelations = newCustomerRelationUsers?.map((item) => {
        return {
          id: item?._id,
          status: "pending",
          user: item?.user?._id,
        };
      });
      const emailBody = event?.description;

      const resp = await axiosInstance.post(`/campaigns/send`, {
        event: event?._id,
        customerRelations,
        emailBody,
      });
      if (resp?.status === 201) {
        Notify.success("Request Received");
        navigator(`/dashboard/campaigns/${event?._id}`);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Stack spacing={2} my={2}>
      <Card elevation={0}>
        <CardHeader
          title={t("Start Sending Emails")}
          subheader={"Add Receivers and send Email"}
        />
      </Card>
      {searchString?.length > 0 && registeredUsers?.data?.length === 0 && (
        <Alert
          severity="warning"
          action={
            <Button
              color="warning"
              size="small"
              variant="contained"
              component={Link}
              to={`/dashboard/customer-relations/create${
                currentUser?.accountType === "admin"
                  ? `?organisation=${event?.organisation?._id}`
                  : ""
              }`}
              endIcon={<OpenInNew />}
              target="_blank"
              rel="noopener noreferrer"
            >
              add new User Relation
            </Button>
          }
        >
          <AlertTitle>
            If you didn't find user you're looking for, you can{" "}
            <b>add new User (or user relation)</b>. Click on "add new User
            Relations" button to add new user relation.
          </AlertTitle>
        </Alert>
      )}
      {newCustomerRelationUsers?.filter((item) => {
        return !event?.relations?.includes(item?.relation);
      })?.length > 0 && (
        <Alert
          severity="warning"
          action={
            <Button
              color="info"
              size="small"
              component={Link}
              to={`/dashboard/campaigns/${event?._id}/edit`}
              startIcon={<EditTwoTone />}
            >
              Edit
            </Button>
          }
          title=""
        >
          <AlertTitle>
            You've selected user(s) with relation which is not included in this
            bulk email program. Please, remove them or change relation. You may
            also skip this warning and continue.
          </AlertTitle>
          Available relation {event?.relations?.length > 1 ? "are" : "is"}{" "}
          <strong>{event?.relations?.join(", ")}.</strong> But you've selected
          user(s) with relation:{" "}
          <strong>
            {newCustomerRelationUsers
              ?.filter((item) => {
                return !event?.relations?.includes(item?.relation);
              })
              ?.map((item) => item?.relation)
              ?.join(", ")}
            .
          </strong>
        </Alert>
      )}
      <FormControl variant="outlined" fullWidth>
        <OutlinedInputWrapper
          type="text"
          placeholder={t(`Search receivers by name, email or phone`)}
          value={searchString || ""}
          onChange={(e) => {
            setSearchString(e.target.value);
            if (e.target.value?.length === 0) {
              delete filterObj.search;
              setFilterObj(filterObj);
            }
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              setSearchParams({
                search: searchString,
              });
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <ButtonSearch
                variant="contained"
                size="small"
                onClick={() => {
                  setSearchParams({
                    search: searchString,
                  });
                }}
              >
                {t("Search")}
              </ButtonSearch>
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment position="start">
              <SearchTwoToneIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      {loading ? (
        <LinearProgress sx={{ my: 2 }} />
      ) : (
        <>
          {registeredUsers?.data?.length > 0 && (
            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Relation</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registeredUsers?.data?.map((row) => (
                    <TableRow key={row?._id}>
                      <TableCell>
                        <Typography variant="h6">
                          {row?.user?.firstName + " " + row?.user?.lastName ||
                            ""}
                        </Typography>
                      </TableCell>
                      <TableCell>{row?.user?.email}</TableCell>
                      <TableCell>{row?.relation || "-"}</TableCell>
                      <TableCell align="right">
                        {newCustomerRelationUsers?.some(
                          (user) => user?._id === row?._id
                        ) ? (
                          <Button
                            color="error"
                            size="small"
                            startIcon={<RemoveOutlined />}
                            onClick={() => {
                              setNewCustomerRelationUsers(
                                newCustomerRelationUsers.filter(
                                  (user) => user?._id !== row?._id
                                )
                              );
                            }}
                          >
                            Remove
                          </Button>
                        ) : (
                          <LoadingButton
                            loading={isWait}
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => {
                              setNewCustomerRelationUsers([
                                ...newCustomerRelationUsers,
                                row,
                              ]);
                            }}
                          >
                            <AddTwoTone /> {t("Add")}
                          </LoadingButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {!loading && registeredUsers?.data?.length > 0 && (
                <Box
                  sx={{
                    m: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Stack spacing={2}>
                    <Pagination
                      count={registeredUsers?.totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>
                </Box>
              )}
            </TableContainer>
          )}
          {registeredUsers?.data?.length === 0 && (
            <EmptyHere title={t("No user found")} />
          )}
          {newCustomerRelationUsers?.length > 0 && (
            <Stack
              spacing={2}
              my={2}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6">
                {t("Selected Users Count: ")}{" "}
                {newCustomerRelationUsers?.length || 0}
              </Typography>
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={() => {
                  Notiflix.Confirm.show(
                    "Confirmation",
                    "Are you sure you want to send email?",
                    "Confirm",
                    "Cancel",
                    () => handleSendCampaign(),
                    () => {},
                    {
                      width: "500px",
                      messageMaxLength: 1000,
                    }
                  );
                }}
              >
                {t("Save and Send Email")}
              </Button>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
