import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";

const PERMISSIONS_TYPES = ["ACCESS", "CREATE", "UPDATE", "DELETE"];

export default function GroupPermissions({ groupInfo, groupId }) {
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (!groupId) return;

    if (groupInfo) {
      setSelectedPermissions(groupInfo?.permissions || []);
    }

    (async () => {
      if (permissions) return;
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/metadata/permissions`);
        if (resp?.status === 200) {
          setPermissions(resp?.data || null);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [groupId, groupInfo, permissions]);

  const handleTogglePermission = async (permission) => {
    try {
      if (!groupId) return;

      let action = null;
      if (selectedPermissions.includes(permission)) {
        setSelectedPermissions(
          selectedPermissions.filter((p) => p !== permission)
        );
        action = "remove";
      } else {
        setSelectedPermissions([...selectedPermissions, permission]);
        action = "add";
      }

      await axiosInstance.put(`/groups/${groupId}/permissions`, {
        permission,
        action,
      });
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Access</TableCell>
              <TableCell>Create</TableCell>
              <TableCell>Update</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(permissions || {}).map(([title, perm], idx) => (
              <TableRow
                key={idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{ minWidth: "25%" }}>
                  <b>{title}</b>
                </TableCell>
                {PERMISSIONS_TYPES.map((type, index) => (
                  <TableCell key={index}>
                    <FormGroup>
                      {perm[type]
                        ? Object.entries(perm[type] || {}).map(
                            ([key, value]) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      selectedPermissions.includes(
                                        `${title}_${type}_${key}`
                                      ) || false
                                    }
                                    onChange={() => {
                                      handleTogglePermission(
                                        `${title}_${type}_${key}`
                                      );
                                    }}
                                  />
                                }
                                label={value}
                                key={key}
                              />
                            )
                          )
                        : "-"}
                    </FormGroup>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
