import {
  AddBoxOutlined,
  CancelPresentationOutlined,
  EditTwoTone,
  IndeterminateCheckBoxOutlined,
  InfoOutlined,
  InfoTwoTone,
} from "@mui/icons-material";
import { TreeItem, TreeView, treeItemClasses } from "@mui/lab";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "src/contexts/FormContext";
import ContextualFormDialog from "./ContextualFormDialog";
import { useSpring, animated } from "@react-spring/web";
import { InputTypesObject } from "src/utils/constants";
import CreateUpdateQuestion from "./CreateUpdateContextQ";
import EditableContextual from "./EditableContextualQ";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import Handler from "src/components/Handler";
import Nestable from "react-nestable";

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  margin: 8,
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function ContextualForm() {
  const { form, isAddOpen, handleGetForm, selectedFormGroup } =
    useContext(FormContext);

  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalWeightage, setTotalWeightage] = useState(0);

  const [contextualForms, setContextualForms] = useState([]);
  const [selectedContextualForm, setSelectedContextualForm] = useState(null);

  useEffect(() => {
    if (form && selectedFormGroup) {
      const cfForms = form?.formGroups?.find(
        (group) => group._id === selectedFormGroup?._id
      )?.contextualForms;
      setContextualForms(cfForms || []);

      const totalQuestions = form?.formGroups
        ?.filter((group) => group._id === selectedFormGroup?._id)
        ?.reduce((total, group) => {
          const questionsInGroup = group.questionsGroup?.reduce(
            (groupTotal, qGroup) => groupTotal + qGroup.questions.length,
            0
          );
          return total + questionsInGroup;
        }, 0);
      setTotalQuestions(totalQuestions || 0);

      const grandTotalWeightage = form?.formGroups
        ?.filter((group) => group._id === selectedFormGroup?._id)
        ?.flatMap((group) =>
          group.questionsGroup?.flatMap((qGroup) =>
            qGroup.questions?.flatMap((question) => {
              // if question type is in radio, select, or rating, the get greatest weightage of all options
              if (
                question.type === "radio" ||
                question.type === "select" ||
                question.type === "rating"
              ) {
                return Math.max(
                  ...question.options?.map((option) => option.weightage)
                );
              }
              // if question type is in checkbox, then get sum of all weightages
              else if (question.type === "checkbox") {
                return question.options?.reduce(
                  (totalWeightage, option) => totalWeightage + option.weightage,
                  0
                );
              } else {
                return question.weightage || 0;
              }
            })
          )
        )
        ?.reduce((total, weightage) => total + weightage, 0);

      setTotalWeightage(grandTotalWeightage || 0);
    }
  }, [selectedFormGroup, form]);

  const handleUpdateQuestion = async (updatedQs) => {
    try {
      // Assume items is an array containing the modified formInput objects
      const updatePromises = updatedQs.map(async (item) => {
        const resp = await axiosInstance.put(
          `/forms/contextual/${selectedFormGroup?._id}/${item._id}`,
          item
        );
        if (resp?.status !== 200) {
          throw new Error(
            resp?.data?.message ||
              resp?.statusText ||
              "Failed to update question"
          );
        }
      });

      await Promise.all(updatePromises);

      // Refresh the form data after updating all questions
      await handleGetForm(form?._id);

      Notify.success("Successfully");
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "An error occurred"
      );
    }
  };

  const handleOnChangeQuestions = ({ items }) => {
    console.log(items);
    const updatedQs = items.map((item, index) => {
      return {
        ...item,
        position: index,
      };
    });
    handleUpdateQuestion(updatedQs);
  };

  return (
    <Box component={Paper} sx={{ p: 2 }}>
      {form?.includeScore && (
        <>
          {totalWeightage > 0 ? (
            <>
              <Typography color={"GrayText"}>
                NOTE: There are total{" "}
                <Typography sx={{ mx: 1 }} component={"span"} fontWeight={900}>
                  {totalQuestions || 0}
                </Typography>
                Questions with{" "}
                <Typography sx={{ mx: 1 }} component={"span"} fontWeight={900}>
                  {totalWeightage || 0}
                </Typography>
                Weightage in Total. Contextual Forms triggering is based on the
                Weightage count. So, You can set the weightage of each
                Contextual Form in Range
                <sup>
                  <Tooltip title="The Range of Score (Weightage) which will trigger Questions in this Contextual Form.">
                    <InfoOutlined fontSize={"0.5rem"} color="info" />
                  </Tooltip>
                </sup>{" "}
                of{" "}
                <Typography sx={{ mx: 1 }} component={"span"} fontWeight={900}>
                  [0 - {totalWeightage || "N/A"}]
                </Typography>
                .
              </Typography>
              <Divider sx={{ my: 2 }} />
              {isAddOpen === "contextual" && (
                <CreateUpdateQuestion
                  selectedContextualForm={selectedContextualForm}
                  setSelectedContextualForm={setSelectedContextualForm}
                  totalWeightage={totalWeightage}
                />
              )}
              {contextualForms.length === 0 && (
                <CreateUpdateQuestion
                  mode="new"
                  selectedContextualForm={selectedContextualForm}
                  setSelectedContextualForm={setSelectedContextualForm}
                  totalWeightage={totalWeightage}
                />
              )}
            </>
          ) : (
            <Typography color={"GrayText"} sx={{ mb: 2 }}>
              NOTE: There are no Questions with Weightage in this Form. Please
              add Questions with Weightage to use Contextual Forms.
            </Typography>
          )}
        </>
      )}
      {/* <TreeView
        defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
        defaultExpandIcon={<AddBoxOutlined />}
        // defaultEndIcon={<CancelPresentationOutlined color="disabled" />}
      > */}
      <Nestable
        idProp="_id"
        maxDepth={1}
        items={contextualForms?.sort(function (a, b) {
          return a.position - b.position;
        })}
        onChange={handleOnChangeQuestions}
        handler={<Handler />}
        renderItem={({ item, handler }) => (
          <EditableContextual
            key={item._id}
            handler={handler}
            question={item}
            questionTypeIcon={
              InputTypesObject[item?.type]
                ? InputTypesObject[item?.type].icon
                : null
            }
            selectedContextualForm={selectedContextualForm}
            setSelectedContextualForm={setSelectedContextualForm}
          />
        )}
      />
      {contextualForms?.length > 0 ? //     nodeId={item?._id} //   <StyledTreeItem // contextualForms?.map((item, index) => (
      //     key={item?._id}
      //     onClick={() => setSelectedContextualForm(item)}
      //     label={
      //       <EditableContextual
      //           question={item}
      //           questionTypeIcon={InputTypesObject[item?.type]
      //             ? InputTypesObject[item?.type].icon
      //             : null}
      //             selectedContextualForm={selectedContextualForm}
      //             setSelectedContextualForm={setSelectedContextualForm}
      //           />
      //     }
      //   >
      //     {["radio", "checkbox", "select", "rating"].includes(
      //       item?.type
      //     ) && (
      //       <>
      //         {item?.options?.length > 0 ? (
      //           item?.options?.map((i, indi) => (
      //             <StyledTreeItem
      //               nodeId={i?._id}
      //               key={i?._id}
      //               label={
      //                 i?.label ? `${indi + 1}. ${i?.label}` : "No Label"
      //               }
      //             />
      //           ))
      //         ) : (
      //           <Typography
      //             sx={{
      //               textAlign: "center",
      //             }}
      //             variant="caption"
      //           >
      //             No Options Added Yet
      //           </Typography>
      //         )}
      //       </>
      //     )}
      //   </StyledTreeItem>
      // ))
      null : (
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="caption"
        >
          No Contextual Form Added Yet
        </Typography>
      )}
      {/* </TreeView> */}
    </Box>
  );
}
