import { Box, Card, Stack, Typography } from "@mui/material";
import { AccessTimeOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { calculateTimeRemain } from "src/utils/helpers";

export default function NewExamOngoing({
  duration,
  setTime,
  handleFormSubmit,
}) {
  const [time, setThisTime] = useState(0);
  const [maxDuration, setMaxDuration] = useState(duration);
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    if (isTimeUp) return;
    setMaxDuration(duration * 60);

    const interval = setInterval(() => {
      setThisTime((prevTime) => prevTime + 1);
    }, 1000);

    if (time === maxDuration) {
      setIsTimeUp(true);
      clearInterval(interval);
      // submit form
      setTimeout(() => {
        handleFormSubmit();
      }, 2000); // wait for 2 seconds
    }
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  useEffect(()=>{
    setTime(time)
  },[time])

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave?";
      return event.returnValue;
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  return (
    <div className="red">
      <Card
        sx={{
          padding: 1,
          paddingRight: 4,
        }}
        elevation={0}
      >
        
          <Stack direction={'row'} alignItems={'center'}  spacing={1}>
              <AccessTimeOutlined fontSize="large" color={isTimeUp? 'error' : 'inherit'} />
              {isTimeUp?
          <Typography
          color={"error.main"}
          align='center'
        >
          <b>
              Time's up!
          </b>
        </Typography>  
        :
            <Box >
              <Typography variant="subtitle1">
                <small>Time remaining</small>
              </Typography>
              <Typography
                align='left'
                color={"text.primary"}
              >
                <b>
                  {calculateTimeRemain(maxDuration - time)}
                </b>
              </Typography>
            </Box>
        }
          </Stack>
      </Card>
    </div>
  );
}
