import { alpha, Button, Typography, useTheme } from "@mui/material";
import React from "react";

export default function ProIcon() {
  const theme = useTheme();
  return (
    // <img
    //   src={"/static/images/placeholders/illustrations/pro.svg"}
    //   alt="pro"
    //   style={{ width: "20px", height: "20px" }}
    // />
    <Typography
      size="small"
      sx={{
        borderRadius: "4px",
        background: theme.palette.warning.main,
        color: "white",
        px: 1,
        py: 0,
      }}
    >
      <b>
        <small>PRO</small>
      </b>
    </Typography>
  );
}
