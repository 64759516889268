import { CardHeader } from "@mui/material";
import React from "react";

export default function TitleSubtitleCard({ avatar, title, subtitle }) {
  return (
    <CardHeader
      avatar={avatar}
      title={<small>{title}</small>}
      subheader={subtitle}
      titleTypographyProps={{ variant: "body1" }}
      subheaderTypographyProps={{ variant: "h6", color: "inherit" }}
    />
  );
}
