import { Notify } from "notiflix";
import { useState, createContext, useEffect } from "react";
import axiosInstance from "src/utils/axiosInstance";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  // ----------------------------------

  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [globalLoading, setGlobalLoading] = useState("");
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [payDialogLoading, setPayDialogLoading] = useState(false);
  const [pricingData, setPricingData] = useState(null);
  const [globalMetadata, setGlobalMetadata] = useState({});

  useEffect(() => {
    handleCurrentUser();
  }, []);
  useEffect(() => {
    handleGetGlobalMetadata();
  }, [currentUser]);

  const handleGetGlobalMetadata = async () => {
    try {
      if (!currentUser) return;
      const resp = await axiosInstance.get("/metadata");
      if (resp?.status === 200) {
        setGlobalMetadata(resp?.data);
      }
      return resp?.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const handleCurrentUser = () => {
    if (localStorage.getItem("user")) {
      setCurrentUser(JSON.parse(localStorage.getItem("user")));
    } else {
      setCurrentUser(null);
    }
  };

  const handleCheckPayment = async (reqObj, options) => {
    try {
      setPayDialogLoading(true);
      const resp = await axiosInstance.post("/orders/check", reqObj);
      // {isPaid: true}
      if (resp?.status === 200 && resp?.data?.isPaid) {
        return resp?.data;
      }
      if (!resp?.data?.isPaid && resp?.data?.pricing) {
        setOpenPayDialog(true);
      }
      setPayDialogLoading(false);
      setPricingData({
        ...(resp?.data?.pricing || null),
        ...options,
      });
      return false;
    } catch (error) {
      console.log(error);
      setPayDialogLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  const handleCheckPaymentOnly = async (reqObj, options) => {
    try {
      const resp = await axiosInstance.post("/orders/check", reqObj);
      if (resp?.status === 200 && resp?.data?.isPaid) {
        return resp?.data;
      }
      if (!resp?.data?.pricing) {
        Notify.failure("Pricing not found");
        return false;
      }

      setPricingData({
        ...(resp?.data?.pricing || null),
        ...options,
      });

      return false;
    } catch (error) {
      console.log(error);
      setPayDialogLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  const handlePurchase = async (reqObj, options) => {
    try {
      const resp = await axiosInstance.post("/orders", {
        ...reqObj,
        meta: options?.meta || options?.joinOrgObj?.meta || {},
      });
      if (resp?.status !== 201 && resp?.status !== 200) {
        Notify.failure(
          resp?.data?.message || resp?.statusText || "an error occured"
        );
        return null;
      }

      if (resp?.data && resp?.data?.orderId) {
        const order = resp?.data;

        var rzpyOptions = {
          // key: "rzp_test_6NM1wahMDUx9W6", // test
          key: "rzp_live_waryML0yJkemLS", // live
          amount: Number(order?.amountToPay * 100),
          currency: "INR",
          name: "EthixFirst",
          description: "",
          order_id: order?.orderId,
          handler: async function (response) {
            try {
              response = {
                ...response,
                ...{
                  priceId: reqObj?._id,
                  item: reqObj?.item,
                  itemId: reqObj?.itemId,
                  quantity: reqObj?.quantity || 1,
                },
              };
              const respV = await axiosInstance.put(
                `/orders/${order?._id}/verify`,
                response
              );

              if (respV.status === 200) {
                Notify.success("Payment Successful.");
                if (reqObj?.item === "joinOrganisations") {
                  const { joinOrgObj } = options;
                  if (!joinOrgObj || !joinOrgObj?.createInvitation) {
                    // do nothing
                  } else {
                    const resp = await axiosInstance.post(`/invitations/`, {
                      user: joinOrgObj?.user,
                      organisation: joinOrgObj?.organisation,
                      type: joinOrgObj?.type,
                      meta: joinOrgObj?.meta,
                    });
                    if (resp?.status === 201) {
                      Notify.success("Invitation sent successfully");
                    }
                  }
                }

                setTimeout(() => {
                  window.location.replace(
                    options?.redirectLink
                      ? `${options?.redirectLink}?invoiceId=${respV?.data?.invoiceId}`
                      : `/dashboard/orders/invoices/${respV?.data?.invoiceId}`
                  );
                }, 1000);
              }
            } catch (error) {
              Notify.failure(
                error?.response?.data?.message ||
                  "This is not you, this is us. We're unable to save payment. Please wait, we'll react out you soon."
              );
            }
          },
          prefill: {
            name: currentUser?.firstName + " " + currentUser?.lastName,
            email: currentUser?.email || "",
            contact: currentUser?.phoneNumber || "",
          },
          theme: {
            color: "#F37254",
          },
        };
        let rzp1 = new window.Razorpay(rzpyOptions);
        rzp1.on("payment.failed", async function (response) {
          try {
            const resp = await axiosInstance.put(`/orders/${order?._id}`, {
              status: "failed",
              paymentStatus: "failed",
              failureReason: response?.error?.description,
            });
            if (resp.status === 200) {
              Notify.failure("Payment Failed. Please try again!");
            }
          } catch (error) {}
        });

        rzp1.on("payment.cancelled", async function (response) {
          try {
            const resp = await axiosInstance.put(`/orders/${order?._id}`, {
              status: "cancelled",
              failureReason: response?.error?.description,
            });
            if (resp.status === 200) {
              Notify.failure("Payment Cancelled. Please try again!");
            }
          } catch (error) {}
        });

        rzp1.open();
      } else {
        Notify.failure(
          "Something went wrong. Please try again or contact support."
        );
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  const logout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("requiresPasswordReset");
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  // ----------------------------------

  const [allTags, setAllTags] = useState({});

  const handleGetAllTags = async () => {
    try {
      const resp = await axiosInstance.get("/tags/all");
      if (resp?.status === 200) {
        setAllTags(resp?.data);
      }
      return resp?.data;
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return [];
    }
  };

  const getTagsByType = async (type) => {
    if (!type) return [];
    let _allTags = [];
    if (!allTags[type]) {
      _allTags = await handleGetAllTags();
    } else {
      _allTags = allTags;
    }
    return _allTags[type] || [];
  };

  return (
    <GlobalContext.Provider
      value={{
        // state
        sidebarToggle,
        currentUser,
        globalLoading,
        openPayDialog,
        payDialogLoading,
        pricingData,
        openLoginDialog,
        globalMetadata,

        setOpenPayDialog,
        setOpenLoginDialog,
        setPricingData,

        // methods
        toggleSidebar,
        closeSidebar,
        handleCurrentUser,
        logout,
        setGlobalLoading,
        handleCheckPayment,
        handlePurchase,
        getTagsByType,
        handleGetGlobalMetadata,
        handleCheckPaymentOnly,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
