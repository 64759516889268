import { ChevronRightTwoTone } from "@mui/icons-material";
import {
  Divider,
  Box,
  Card,
  Typography,
  useTheme,
  Grid,
  styled,
  alpha,
  CardActionArea,
} from "@mui/material";
import { Link } from "react-router-dom";

const CustomDivider = styled(Divider)(
  () => `
      width: 100px;
      height: 4px;
      border-radius: 50px;
  `
);

const CardWrapper = styled(Card)(
  ({ theme }) => `
    transition: ${theme.transitions.create(["transform", "box-shadow"])};
    transform: scale(1);
    position: relative;
    z-index: 5;

    &:hover {
        z-index: 6;
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        transform: scale(1.01);
    }
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `

        .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: transparent;
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: 0;
          }
        }
  `
);

export default function RevenueStats({ stats }) {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      {[
        {
          title: "Pricing Plans",
          value: stats?.pricingPlans,
          color: "success",
          param: "plan",
        },
        {
          title: "Rating Forms",
          value: stats?.ratingForms,
          color: "warning",
          param: "forms",
        },
        {
          title: "Exams",
          value: stats?.exams,
          color: "info",
          param: "exams",
        },
        {
          title: "Acc. Verifications",
          value: stats?.verifications,
          color: "error",
          param: "verifications",
        },
        {
          title: "Career Plan",
          value: stats?.careerPlan,
          color: "primary",
          param: "joinOrganisations",
        },
      ].map((item, idx) => (
        <Grid item xs={12} md={4} lg={2.4} key={idx}>
          <CardWrapper>
            <CardActionAreaWrapper
              sx={{
                p: 2,
                pl: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              component={Link}
              to={`/dashboard/orders?item=${item?.param}`}
              style={{
                textDecoration: "none",
              }}
            >
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: `${theme.typography.pxToRem(40)}`,
                  }}
                >
                  {item?.value || 0}
                </Typography>
                <CustomDivider
                  sx={{
                    background: (theme) => theme.colors[item?.color]?.main,
                    my: 1,
                  }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: `${theme.typography.pxToRem(14)}`,
                    textTransform: "uppercase",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item?.title}
                </Typography>
              </Box>
              <ChevronRightTwoTone fontSize="large" color={item?.color} />
            </CardActionAreaWrapper>
          </CardWrapper>
        </Grid>
      ))}
    </Grid>
  );
}
