import React, { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import UserProfile from "./profile/UserProfile";
import { ProfileProvider } from "src/contexts/ProfileContext";
import DashboardAnalytics from "./Analytics";
import MiscAnalytics from "./Analytics/MiscAnalytics";
import AccountVerificationWizard from "./common/AccountVerification";

export default function Dashboard() {
  const { currentUser } = useContext(GlobalContext);

  let dashboardContent = null;

  switch (currentUser?.role) {
    case "user":
      dashboardContent = (
        <ProfileProvider>
          <AccountVerificationWizard />
          <UserProfile />
        </ProfileProvider>
      );
      break;
    case "misc":
      dashboardContent = <MiscAnalytics />;
      break;
    default:
      dashboardContent = <DashboardAnalytics />;
  }

  return <div>{dashboardContent}</div>;
}
