import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Typography,
  styled,
  useTheme,
  lighten,
  alpha,
  Paper,
} from "@mui/material";
import { PAGE_LIMIT_15 } from "src/utils/constants";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import EmptyHere from "src/components/EmptyHere";
import EventCard from "../../Events/components/EventCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import {
  ArrowForwardIos,
  EventTwoTone,
  PieChartTwoTone,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function ExploreEvents({ organisationId }) {
  const theme = useTheme();
  const { currentUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState({});
  const [organisation, setOrganisation] = useState(null);

  useEffect(() => {
    setOrganisation(organisationId || currentUser?.orgId);
  }, [currentUser, organisationId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT_15, skip: 0, type: "event" },
      };

      if (organisation) {
        reqParams.params.organisation = organisation;
      }

      const resp = await axiosInstance.get("/events", reqParams);
      if (resp?.status === 200) {
        setEvents(resp?.data || {});
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Box>
      {loading ? (
        <SuspenseLoader />
      ) : (
        <>
          <Box
            display="flex"
            alignItems={{ xs: "stretch", md: "center" }}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            mt={2}
            p={2}
            component={Paper}
            elevation={0}
          >
            <Box display="flex" alignItems="center">
              <AvatarPageTitle variant="rounded">
                <EventTwoTone fontSize="large" />
              </AvatarPageTitle>
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  Discover Events
                </Typography>
                <Typography variant="subtitle2">
                  Explore events happening around you and join them.
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                endIcon={<ArrowForwardIos />}
                component={Link}
                to="/dashboard/events"
              >
                View all
              </Button>
            </Box>
          </Box>
          <Swiper
            spaceBetween={50}
            slidesPerView={2.5}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            style={{
              paddingBlock: 50,
            }}
          >
            {events?.data?.map((item, i) => (
              <SwiperSlide key={i}>
                <EventCard event={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          {events?.data?.length === 0 && <EmptyHere title={"No Event found"} />}
        </>
      )}
    </Box>
  );
}

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${
        theme.palette.mode === "dark"
          ? theme.colors.alpha.trueWhite[10]
          : theme.colors.alpha.white[50]
      };
      box-shadow: ${
        theme.palette.mode === "dark"
          ? `0 1px 0 ${alpha(
              lighten(theme.colors.primary.main, 0.8),
              0.2
            )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)`
          : `0px 2px 4px -3px ${alpha(
              theme.colors.alpha.black[100],
              0.4
            )}, 0px 5px 16px -4px ${alpha(theme.colors.alpha.black[100], 0.2)}`
      };
`
);
