import React from "react";
import { Button, Stack, TextField, Typography, styled } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function InputFieldsSwitch({
  question,
  formInput,
  handleInputChange,
  disabled,
}) {
  switch (question?.type) {
    case "text":
      return (
        <TextField
          fullWidth
          disabled={disabled}
          variant="outlined"
          placeholder={question?.placeholder || ""}
          name={question?.name}
          label={question?.label || ""}
          required={question?.required}
          onChange={(e) => {
            handleInputChange({
              key: question?.name,
              value: e.target.value,
            });
          }}
          value={
            formInput[question?.name]?.value ||
            formInput[question?.name] ||
            question?.value ||
            ""
          }
        />
      );
    // case "textarea":
    //   return (
    //     <TextField
    //       fullWidth
    //       multiline
    //       rows={4}
    //       disabled={disabled}
    //       variant="outlined"
    //       label={question?.label || ""}
    //       placeholder={question?.placeholder || ""}
    //       name={question?.name}
    //       required={question?.required}
    //       onChange={(e) => {
    //         handleInputChange({
    //           key: question?.name,
    //           value: e.target.value,
    //         });
    //       }}
    //       value={formInput[question?.name] || question?.value || ""}
    //     />
    //   );
    case "file":
      return (
        <>
          {/* QUESTION FILE/IMAGES  */}
          <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {question?.label || ""}:
            </Typography>
            <Button
              component="label"
              sx={{
                whiteSpace: "nowrap",
              }}
              variant="contained"
              size="small"
              startIcon={<Upload />}
            >
              Upload File
              <VisuallyHiddenInput
                type="file"
                accept="*"
                onChange={(e) => {
                  e.preventDefault();
                  const file = e.target.files[0];
                  handleInputChange({
                    key: question?.name,
                    value: file,
                  });
                }}
              />
            </Button>
            {(() => {
              const _file =
                formInput[question?.name]?.value ||
                formInput[question?.name] ||
                question?.value;

              if (!_file) return null;
              return (
                <Button
                  size="small"
                  component={"a"}
                  href={
                    typeof _file === "object"
                      ? URL.createObjectURL(_file)
                      : process.env.REACT_APP_DO_SPACE_URL + _file
                  }
                  target="_blank"
                >
                  {typeof _file === "object"
                    ? _file.name
                    : _file.split("/").pop()}
                </Button>
              );
            })()}
          </Stack>
        </>
      );
    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            sx={{
              width: "100%",
            }}
            name={question?.name}
            required={question?.required}
            label={question?.label || ""}
            disabled={disabled}
            value={
              new Date(
                formInput[question?.name]?.value ||
                  formInput[question?.name] ||
                  question?.value
              ) || new Date()
            }
            onChange={(newValue) => {
              handleInputChange({
                key: question?.name,
                value: newValue,
              });
            }}
            format="dd/MM/yyyy"
          />
        </LocalizationProvider>
      );
    default:
      return null;
  }
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
