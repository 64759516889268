import React from "react";
import {
  AddTwoTone,
  EditTwoTone,
  GroupAddTwoTone,
  PersonAddAltTwoTone,
  PersonAddTwoTone,
} from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export default function BulkActions({ organisationId, profile, whichPage }) {
  return (
    <Stack spacing={2} direction="row">
      {!!profile && whichPage === "organisation" && (
        <Button
          size="small"
          fullWidth
          variant="outlined"
          color="secondary"
          startIcon={<EditTwoTone />}
          component={Link}
          to={`/dashboard/organisations/${organisationId}/edit`}
        >
          Edit Profile
        </Button>
      )}
      {!profile?.data?.isLocked && (
        <>
          {" "}
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<PersonAddTwoTone />}
            component={Link}
            to={`/dashboard/invitations/new`}
            sx={{ whiteSpace: "nowrap" }}
          >
            Invite User
          </Button>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<GroupAddTwoTone />}
            component={Link}
            to={`/dashboard/users/bulk?organisation=${organisationId}`}
            sx={{ whiteSpace: "nowrap" }}
          >
            Add Bulk Users
          </Button>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<PersonAddAltTwoTone />}
            component={Link}
            to={`/dashboard/users/new?role=user&organisation=${organisationId}`}
            sx={{ whiteSpace: "nowrap" }}
          >
            Add Employee
          </Button>{" "}
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<PersonAddAltTwoTone />}
            component={Link}
            to={`/dashboard/users/new?role=moderator&organisation=${organisationId}`}
            sx={{ whiteSpace: "nowrap" }}
          >
            Add Moderator
          </Button>
          {!profile?.owner && (
            <Button
              size="small"
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<AddTwoTone />}
              component={Link}
              to={`/dashboard/users/new?organisation=${organisationId}&role=owner`}
              sx={{ whiteSpace: "nowrap" }}
            >
              Add Owner
            </Button>
          )}
        </>
      )}
    </Stack>
  );
}
