import {
  Button,
  Card,
  Typography,
  CardHeader,
  CardActions,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Avatar,
  styled,
  IconButton,
  Pagination,
  LinearProgress,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { Link, useSearchParams } from "react-router-dom";
import {
  AssessmentTwoTone,
  BusinessTwoTone,
  ManageAccountsTwoTone,
  OpenInNewTwoTone,
} from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { PAGE_LIMIT } from "src/utils/constants";

const AvatarWrapperInfo = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.info.lighter};
      color:  ${theme.colors.info.main};
`
);

const AvatarWrapperSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color:  ${theme.colors.success.main};
`
);

const AvatarWrapperWarning = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.warning.lighter};
      color:  ${theme.colors.warning.main};
`
);

function ActivityLogs({ whichPage }) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        let reqParams = {
          params: {
            limit: PAGE_LIMIT,
            skip: (page - 1) * PAGE_LIMIT,
          },
        };

        const resp = await axiosInstance.get(`/dashboard/logs`, reqParams);
        if (resp?.status === 200) {
          setLogs(resp?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, [page]);

  return (
    <Card
      sx={{
        height: "auto",
        my: whichPage === "dashboard" ? 0 : 2,
      }}
    >
      <CardHeader title={t("Activity Logs")} />
      <List disablePadding>
        {loading ? (
          <LinearProgress sx={{ m: 2 }} />
        ) : (
          <>
            {logs?.data?.length === 0 ? (
              <ListItem
                sx={{
                  py: 2,
                  textAlign: "center",
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="body1" color="text.secondary">
                      {t("No logs found")}
                    </Typography>
                  }
                />
              </ListItem>
            ) : (
              logs?.data?.map((log, index) => (
                <Fragment key={index}>
                  <ListItem
                    sx={{
                      py: 2,
                    }}
                  >
                    <ListItemAvatar>
                      {log?.logType === "user" ? (
                        <AvatarWrapperSuccess>
                          <ManageAccountsTwoTone />
                        </AvatarWrapperSuccess>
                      ) : log?.logType === "report" ? (
                        <AvatarWrapperInfo>
                          <AssessmentTwoTone />
                        </AvatarWrapperInfo>
                      ) : (
                        <AvatarWrapperWarning>
                          <BusinessTwoTone />
                        </AvatarWrapperWarning>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div
                          dangerouslySetInnerHTML={{ __html: log?.title }}
                        ></div>
                      }
                      secondary={
                        <Stack direction="row" spacing={1} alignItems="center">
                          {log?.user && (
                            <small>
                              By:{" "}
                              {log?.user?.accountType === "admin" ? (
                                <>
                                  {log?.user?.firstName} {log?.user?.lastName}
                                </>
                              ) : (
                                <Link
                                  to={`/dashboard/users/${log?.user?._id}`}
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  {log?.user?.firstName} {log?.user?.lastName}
                                </Link>
                              )}
                            </small>
                          )}
                          {log?.organisation?.title && (
                            <>
                              <small>
                                in{" "}
                                <Link
                                  to={`/dashboard/organisations/${log?.organisation?._id}`}
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  {log?.organisation?.title}
                                </Link>
                              </small>

                              <Typography variant="body2" sx={{ mx: 1 }}>
                                •
                              </Typography>
                            </>
                          )}
                          <small>
                            {fDateTimeSuffix(log?.createdAt || new Date())}
                          </small>
                        </Stack>
                      }
                      primaryTypographyProps={{
                        variant: "body1",
                        color: "textPrimary",
                        noWrap: true,
                      }}
                    />
                    <Box>
                      {log?.link && (
                        <Typography variant="body2" fontWeight="bold">
                          <IconButton
                            size="small"
                            color="primary"
                            component={Link}
                            to={log?.link}
                          >
                            <OpenInNewTwoTone />
                          </IconButton>
                        </Typography>
                      )}
                    </Box>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))
            )}
          </>
        )}
      </List>
      {logs?.data?.length !== 0 && (
        <CardActions
          sx={{
            pt: 0,
            py: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {whichPage === "dashboard" ? (
            <Button
              size="small"
              variant="outlined"
              component={Link}
              to="/dashboard/logs"
            >
              {t("View all")}
            </Button>
          ) : (
            !loading && (
              <Pagination
                count={logs?.totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            )
          )}
        </CardActions>
      )}
    </Card>
  );
}

export default ActivityLogs;
