import { AddCircleOutlineTwoTone, EditTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";
import { MonthNames } from "src/utils/commonConstants";

export default function EditProjectDialog({ portfolioItem }) {
  const { handleGetPortfolio } = useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    setFormInput(portfolioItem || {});
  }, [portfolioItem]);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsWait(false);
    if (!portfolioItem) setFormInput({});
  };

  const handleSubmit = async () => {
    try {
      if (!formInput?.title) throw new Error("Project title is required");
      if (!formInput?.start_month) throw new Error("Start Month is required");
      if (!formInput?.start_year) throw new Error("Start Year is required");
      if (
        formInput?.end_year &&
        formInput?.start_year &&
        formInput?.end_year < formInput?.start_year
      )
        throw new Error("End year cannot be less than start year");
      if (
        !formInput?.currently_working &&
        (!formInput?.end_month || !formInput?.end_year)
      ) {
        throw new Error(
          "Are you currently working here? If not, please add end month and year"
        );
      }
      if (!formInput?.description)
        throw new Error("Project description is required");

      setIsWait(true);

      formInput.category = "projects";
      if (formInput?._id) {
        const resp = await axiosInstance.put(
          `/portfolios/${formInput?._id}`,
          formInput
        );
        if (resp?.status === 200) {
          await handleGetPortfolio(resp?.data?.user);
        }
      } else {
        const resp = await axiosInstance.post("/portfolios", formInput);
        if (resp?.status === 201) {
          setFormInput({});
          await handleGetPortfolio({
            _id: resp?.data?.user,
          });
        }
      }

      setIsWait(false);
      Notify.success("Successful");
      handleClose();
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        {portfolioItem?._id ? <EditTwoTone /> : <AddCircleOutlineTwoTone />}
      </IconButton>

      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Fill out the form below to {formInput?._id ? "edit" : "add"} project
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <TextField
              label="Title *"
              placeholder="e.g. My Project"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Start Month *</InputLabel>
                <Select
                  value={formInput?.start_month || ""}
                  label="Start Month"
                  name="start_month"
                  onChange={handleInputChange}
                >
                  {MonthNames.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Start Year *</InputLabel>
                <Select
                  value={formInput?.start_year || ""}
                  label="Start Year"
                  name="start_year"
                  onChange={handleInputChange}
                >
                  {Array.from(
                    { length: 70 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput?.currently_working}
                  onChange={(e) =>
                    setFormInput({
                      ...formInput,
                      currently_working: e.target.checked,
                    })
                  }
                />
              }
              label="I am currently working on it"
            />
            {!formInput?.currently_working && (
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel>End Month</InputLabel>
                  <Select
                    value={formInput?.end_month || ""}
                    label="End Month"
                    name="end_month"
                    onChange={handleInputChange}
                  >
                    {MonthNames.map((item, idx) => (
                      <MenuItem value={item} key={idx}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>End Year</InputLabel>
                  <Select
                    value={formInput?.end_year || ""}
                    label="End Year"
                    name="end_year"
                    onChange={handleInputChange}
                  >
                    {Array.from(
                      { length: 70 },
                      (_, i) => new Date().getFullYear() - i
                    ).map((item, idx) => (
                      <MenuItem value={item} key={idx}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )}

            <TextField
              label="Project Link"
              fullWidth
              name="project_link"
              value={formInput?.project_link || ""}
              onChange={handleInputChange}
            />
            <TextField
              label="Project Description *"
              placeholder="e.g. I worked on this project as a backend developer in a team of 5 people using Node.js, Express.js, MongoDB, and React.js."
              fullWidth
              name="description"
              value={formInput?.description || ""}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isWait}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={isWait}
            variant="contained"
            color="primary"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
