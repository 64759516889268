import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useState } from "react";
import { FormContext } from "src/contexts/FormContext";
import axiosInstance from "src/utils/axiosInstance";

export default function NewFormGroupDialog() {
  const { form, handleGetForm, setSelectedFormGroup } = useContext(FormContext);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormInput({});
  };

  const handleSubmit = async () => {
    try {
      if (!formInput?.title) throw new Error("Form title is required");
      if (!formInput?.description)
        throw new Error("Form description is required");
      setIsWait(true);

      const resp = await axiosInstance.post(`/forms/${form?._id}`, formInput);
      if (resp?.status === 200) {
        const data = await handleGetForm(form?._id);
        if (data && data?.formGroups?.length > 0) {
          const _lastGroup = {
            _id: data?.formGroups[data?.formGroups?.length - 1]?._id,
            title: data?.formGroups[data?.formGroups?.length - 1]?.title,
            description:
              data?.formGroups[data?.formGroups?.length - 1]?.description,
          };
          setSelectedFormGroup(_lastGroup);
        }
        setFormInput({});
        setIsWait(false);
        Notify.success("Form Group Added Successfully");
        handleClose();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Button fullWidth size="sm" variant="outlined" onClick={handleClickOpen} startIcon={<Add />}>
        Add New Dimension
      </Button>

      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Fill out the form below to create a new form dimension
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <TextField
              autoFocus
              label="Form Dimension Title"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="Form Dimension Subtitle/Description"
              name="description"
              value={formInput?.description || ""}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isWait}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            loading={isWait}
          >
            Add Dimension
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
