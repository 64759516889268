import {
  Autocomplete,
  Box,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import {
  FORM_TYPES,
  PAGE_LIMIT,
  RATING_STATUS_COLOR,
} from "src/utils/constants";
import { GlobalContext } from "src/contexts/GlobalContext";
import {
  ArrowForwardIosTwoTone,
  SportsScoreTwoTone,
} from "@mui/icons-material";
import Label from "src/components/Label";
import { calculateFinalScore } from "src/utils/helpers";
import { fDate, fTime } from "src/utils/date-fns";
import EmptyHere from "src/components/EmptyHere";

export default function Ratings({ formType, organisationId, orderId }) {
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const statusParam = searchParams.get("status");
  const typeParam = searchParams.get("type");
  const eventParam = searchParams.get("eventId");
  const [filterObj, setFilterObj] = useState({
    // status: typeParam === "misconduct" ? null : statusParam || "active",
  });
  const [loading, setLoading] = useState(false);

  const [forms, setForms] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [organisations, setOrganisations] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    (async () => {
      if (organisationId || currentUser?.accountType !== "admin") return;
      const resp = await axiosInstance.get(`/organisations/all`, {
        params: {
          isClaimed: true,
        },
      });
      if (resp?.status === 200) {
        setOrganisations(resp?.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
        };
      }
      if (formType) {
        reqParams.params.type = formType;
      }
      // if (!filterObj?.status) {
      //   reqParams.params.status =
      //     typeParam === "misconduct" ? null : statusParam || "active";
      // }

      if (organisationId) {
        reqParams.params.organisation = organisationId;
      }
      if (orderId) {
        reqParams.params.orderId = orderId;
      }
      if (eventParam) {
        reqParams.params.eventId = eventParam;
      }

      const resp = await axiosInstance.get("/ratings", reqParams);
      if (resp?.status === 200) {
        setForms(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterObj, typeParam, statusParam, formType]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Stack direction="row" spacing={2}>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here..."
              inputProps={{ "aria-label": "search here" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (e.target.value.length) {
                    setFilterObj({ ...filterObj, search: searchString });
                  } else {
                    setFilterObj({ ...filterObj, search: null });
                  }
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }
              }}
              onChange={(e) => {
                e.preventDefault();
                if (e.target.value?.length > 0) {
                  setSearchString(e.target.value);
                } else {
                  setFilterObj({
                    ...filterObj,
                    search: null,
                  });
                }
              }}
            />
            <IconButton
              type="button"
              sx={{
                p: "10px",
                m: 0.2,
                bgcolor: "primary.main",
                color: "white",
              }}
              onClick={() => {
                setFilterObj({
                  ...filterObj,
                  search: searchString,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {!organisationId && currentUser?.accountType === "admin" && (
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={[
                { _id: "null", title: "EthixFirst" },
                ...(organisations || []),
              ]}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Organisation" />
              )}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  organisation: v?._id,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            />
          )}
          <Autocomplete
            disablePortal
            size="small"
            id="org-box"
            options={Object.entries(RATING_STATUS_COLOR).map(
              ([key, value]) => ({
                value: key,
                label: value.label,
              })
            )}
            getOptionLabel={(option) => capitalize(option?.label || "")}
            sx={{ width: 250, mr: 2 }}
            value={filterObj?.status || "active"}
            renderInput={(params) => (
              <TextField {...params} label="filter by status" />
            )}
            onChange={(e, v) => {
              setFilterObj({ ...filterObj, status: v?.value });
              if (v?.value) {
                searchParams.set("status", v?.value);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("status");
                setSearchParams(searchParams);
              }
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          />
        </Stack>
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              {formType === "exam" ? (
                <TableRow>
                  <TableCell>Exam Title</TableCell>
                  <TableCell>Submit By</TableCell>
                  {currentUser?.accountType === "admin" && (
                    <TableCell>Organisation</TableCell>
                  )}
                  <TableCell>Percentage</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell>Rating Title</TableCell>
                  <TableCell>Receiver</TableCell>
                  {typeParam !== "misconduct" && (
                    <TableCell>Rated By</TableCell>
                  )}
                  {(currentUser?.accountType === "admin" ||
                    currentUser?.orgId) && <TableCell>Rating Type</TableCell>}
                  {!(currentUser?.role === "user" && formType !== "exam") && (
                    <TableCell>Score (~1000)</TableCell>
                  )}
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {forms?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      maxWidth: "300px",
                    }}
                  >
                    <Typography
                      fontWeight={row?.status === "active" ? 700 : 500}
                      color={row?.status === "active" ? "text.primary" : "grey"}
                    >
                      {row?.title}

                      {row?.includeScore && (
                        <Tooltip title="This Rating's Score is included in User's Score.">
                          <sup>
                            <small>
                              <SportsScoreTwoTone
                                sx={{
                                  fontSize: "1rem",
                                }}
                              />
                            </small>
                          </sup>
                        </Tooltip>
                      )}
                      {row?.status !== "active" && (
                        <>
                          <br />
                          <Label height={18}>
                            <small>- old rating</small>
                          </Label>
                        </>
                      )}
                    </Typography>
                  </TableCell>
                  {formType !== "exam" ? (
                    <TableCell>
                      {row?.user || row?.userDetails ? (
                        <>
                          {(row?.user || row?.userDetails) && (
                            <Typography variant="h6">
                              {row?.user?.firstName ||
                                row?.userDetails?.firstName ||
                                "-"}{" "}
                              {row?.user?.lastName ||
                                row?.userDetails?.lastName ||
                                ""}
                            </Typography>
                          )}
                          {row?.organisation && !row?.userDetails && (
                            <Typography>
                              <small>{row?.organisation?.title || "-"}</small>
                            </Typography>
                          )}
                        </>
                      ) : (
                        (row?.organisation || row?.organisationDetails) && (
                          <Typography variant="h6">
                            {row?.organisation?.title ||
                              row?.organisationDetails?.title ||
                              "-"}
                          </Typography>
                        )
                      )}
                    </TableCell>
                  ) : (
                    currentUser?.accountType === "admin" && (
                      <TableCell>
                        <Typography variant="h6">
                          {row?.organisation?.title || "-"}
                        </Typography>
                      </TableCell>
                    )
                  )}
                  <TableCell>
                    {row?.submitBy?.firstName || "-"}{" "}
                    {row?.submitBy?.lastName || ""}
                  </TableCell>
                  {(currentUser?.accountType === "admin" ||
                    currentUser?.orgId) &&
                    formType !== "exam" && (
                      <TableCell>
                        <small>{FORM_TYPES[row?.type]?.label || "-"}</small>
                      </TableCell>
                    )}
                  {(() => {
                    if (currentUser?.role === "user" && formType !== "exam")
                      return;
                    const result = calculateFinalScore(
                      {
                        score: row?.score,
                        weightage: row?.weightage,
                        passingPercentage: row?.passingPercentage,
                      },
                      formType
                    );
                    return (
                      <>
                        <TableCell>
                          {row?.weightage > 0 ? (
                            <Typography
                              variant={
                                row?.status === "active" ? "h6" : "subtitle1"
                              }
                              fontSize={
                                row?.status === "active" ? "1.1rem" : "0.9rem"
                              }
                              fontWeight={600}
                            >
                              {result?.score || 0}
                              {formType === "exam" && "%"}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </>
                    );
                  })()}
                  <TableCell>
                    <Typography>{fDate(row?.createdAt)}</Typography>
                    <small>{fTime(row?.createdAt)}</small>
                  </TableCell>
                  <TableCell align="right">
                    <Box display={"flex"} justifyContent="flex-end">
                      <IconButton
                        size="small"
                        component={Link}
                        to={
                          `/dashboard/ratings/${row?._id}` +
                          (typeParam === "misconduct" ? "/misconduct" : "")
                        }
                        color="warning"
                      >
                        <ArrowForwardIosTwoTone />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

              {forms?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <EmptyHere />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && forms?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
