import { HelpOutline, Refresh } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";

const CODE_EXPIRY_TIME = 5; // in minutes
export default function QrCodeLinker({ accountId, accountType, whichPage }) {
  // 5 min in seconds
  const { currentUser } = useContext(GlobalContext);
  const [progress, setProgress] = useState(CODE_EXPIRY_TIME * 60 * 1000);
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // get the time from local storage
    const codeExpiry = localStorage.getItem("codeExpiry");
    if (codeExpiry) {
      setProgress(parseInt(codeExpiry));
    }
  }, []);

  useEffect(() => {
    if (currentUser?.orgId !== accountId && currentUser?.userId !== accountId) {
      return;
    }
    // fetch the code from the backend
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1000);
      // save to local storage
      localStorage.setItem("codeExpiry", progress);
      if (progress === 0) {
        setProgress(CODE_EXPIRY_TIME * 60 * 1000);
        // fetch the code from the backend
        getVerificationCode();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  const getVerificationCode = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get("/metadata/codes");
      setCode(resp?.data?.code);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    (currentUser?.orgId === accountId || currentUser?.userId === accountId) &&
      getVerificationCode();
  }, []);

  return (
    <Card
      elevation={0}
      sx={{
        position: "relative",
        background: "transparent",
      }}
    >
      {(currentUser?.orgId === accountId ||
        currentUser?.userId === accountId) &&
        whichPage !== "org-public-profile" && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Button
              component={Link}
              to={`/links/${accountId}/${accountType}`}
              target="_blank"
              style={{ textDecoration: "none" }}
              size="small"
            >
              Edit Content
            </Button>{" "}
          </Box>
        )}
      <Box
        display="flex"
        alignItems="center"
        pt={2}
        justifyContent={"space-between"}
      >
        <Box
          width={1}
          sx={{
            textAlign: "center",
          }}
        >
          <QRCode
            value={
              process.env.REACT_APP_BASE_URL +
              `/links/${accountId}/${accountType}`
            }
            style={{
              width: 160,
              height: 160,
              position: "relative",
            }}
          />{" "}
        </Box>
        {(currentUser?.orgId === accountId ||
          currentUser?.userId === accountId) && (
          <Box width={1} textAlign={"center"}>
            {loading ? (
              <CircularProgress size={34} />
            ) : (
              <Typography
                color="text.primary"
                textAlign={"start"}
                variant="h1"
                alignItems={"center"}
                display={"flex"}
                justifyContent={"center"}
              >
                {code}
                <IconButton onClick={getVerificationCode}>
                  <Refresh fontSize="small" />
                </IconButton>
              </Typography>
            )}
            <Typography
              color="text.secondary"
              textAlign={"center"}
              variant="body2"
            >
              EthixFirst Code
              <Tooltip title="This is the code that will be used to verify QR code authenticity and Navigation">
                <HelpOutline fontSize="1rem" sx={{ mx: 1 }} />
              </Tooltip>
            </Typography>
          </Box>
        )}
      </Box>{" "}
      {currentUser?.orgId === accountId || currentUser?.userId === accountId ? (
        <Box>
          <Typography
            color="text.secondary"
            textAlign={"right"}
            px={1}
            variant="body1"
          >
            <small>
              The code will expire in{" "}
              {new Date(progress).toISOString().substring(14, 19)}
            </small>
          </Typography>
          <LinearProgress
            sx={{
              width: "100%",
            }}
            color="success"
            variant="determinate"
            value={(progress / (CODE_EXPIRY_TIME * 60 * 1000)) * 100}
          />
        </Box>
      ) : (
        <Typography textAlign={"center"}>
          <small>Scan to give rating</small>
        </Typography>
      )}
    </Card>
  );
}
