import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Fragment,
} from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { read, utils } from "xlsx";
import { LoadingButton } from "@mui/lab";
import Notiflix, { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function CustomerBulkUpload() {
  const navigate = useNavigate();
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const organisationParam = searchParams.get("organisation");
  const theme = useTheme();
  const inputFile = useRef(null);
  const dropArea = useRef(null);
  const [loading, setLoading] = useState(false);
  const [sheetColumns, setSheetColumns] = useState([]);
  const [sheetRows, setSheetRows] = useState([]);
  const [page, setPage] = useState(0);
  const [step, setStep] = useState(1);
  const [placeholderToColumn, setPlaceholderToColumn] = useState({});
  const [isDone, setIsDone] = useState(false);
  const [customerRelationType, setCustomerRelationType] = useState("");
  const [organisationId, setOrganisationId] = useState("");
  const [customerRelations, setCustomerRelations] = useState([]);
  const [userInputs, setUserInputs] = useState([]);

  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const _org = organisationParam || currentUser?.orgId;
    if (!_org) {
      Notify.failure("Organisation ID is missing");
      setTimeout(() => {
        window.location.href = "/dashboard/customer-relations";
      }, 1000);
      return;
    }
    setOrganisationId(_org);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, organisationParam]);

  useEffect(() => {
    if (!customerRelationType) {
      return;
    }
    (async () => {
      const resp = await axiosInstance.get("/tags/type", {
        params: {
          type: customerRelationType,
        },
      });
      if (resp?.status === 200) {
        setUserInputs(resp?.data?.inputs || []);
      }
    })();
  }, [customerRelationType]);

  const processFiles = async (e, files) => {
    e.preventDefault();

    dropArea.current.style.backgroundColor = "#fff";
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const [columns] = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 1,
          });
          setSheetColumns(columns || []);

          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setSheetRows(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    if (!customerRelationType) {
      Notify.failure("Please select user relation type");
      return;
    }
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async () => {
    try {
      if (!customerRelationType) {
        Notify.failure("Please select user relation type");
        return;
      }

      setIsDone(false);
      setLoading(true);
      const users = [];

      sheetRows.forEach((row) => {
        const user = {};
        Object.keys(placeholderToColumn).forEach((placeholder) => {
          let _value = row[placeholderToColumn[placeholder]];
          if (
            placeholder === "profileImage" &&
            typeof _value === "string" &&
            _value?.includes("https://drive.google.com/file/d/")
          ) {
            const _url = _value?.split("https://drive.google.com/file/d/")?.[1];
            const _id = _url?.split("/")?.[0];
            _value = `https://drive.google.com/uc?export=view&id=${_id}`;
          }
          user[placeholder] = _value;
        });

        user.relation = customerRelationType;
        user.organisation = organisationId;
        users.push(user);
      });
      // upload users to server
      if (!users.length) {
        Notify.failure("No users found in the sheet");
        setLoading(false);
        return;
      }
      const resp = await axiosInstance.post("/customer-relations/bulk", {
        users,
      });
      if (resp.status === 200) {
        Notify.success("Users added successfully");
        setLoading(false);
        setIsDone(true);
        navigate("/dashboard/customer-relations");
      }
    } catch (error) {
      setLoading(false);
      setIsDone(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };
  return (
    <Box sx={{ my: 2 }}>
      <div>
        <div className="file-upload">
          <div
            className="drop"
            ref={dropArea}
            onClick={() => inputFile.current.click()}
            onDrop={(e) => processFiles(e, e.dataTransfer.files)}
            onDragOver={(e) => {
              e.preventDefault();
              dropArea.current.style.backgroundColor = "#ddd";
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              dropArea.current.style.backgroundColor = "#fff";
            }}
            style={{
              cursor: "pointer",
              padding: sheetColumns?.length > 0 ? "30px" : "300px",
            }}
          >
            <div className="info">
              {"Drag & drop files or click to browse."}
              <br />
              {"CSV, XLS or XLSX only!"}
            </div>
          </div>

          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => processFiles(e, inputFile.current.files)}
            ref={inputFile}
            style={{ display: "none" }}
          />
        </div>
        <Alert
          severity="warning"
          sx={{ display: "flex", justifyContent: "center", my: 1 }}
        >
          <Typography variant="h5">
            Please make sure that the File must have First Name, Last Name, and
            Email columns.
          </Typography>
        </Alert>
        {step === 1 && sheetColumns?.length > 0 && (
          <Box sx={{ width: "100%", my: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {sheetColumns?.map((item, idx) => (
                        <TableCell key={idx}>{item} </TableCell>
                      ))}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sheetRows
                      ?.slice(page * 10, page * 10 + 10)
                      ?.map((row, idx) => {
                        return (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {sheetColumns?.map((item, index) => (
                              <TableCell key={index}>
                                {row[item] || "N/A"}
                              </TableCell>
                            ))}

                            <TableCell component="th" scope="row">
                              <DeleteIcon
                                color="error"
                                sx={{
                                  cursor: "pointer",
                                  top: 7,
                                  position: "relative",
                                }}
                                onClick={() => {
                                  Notiflix.Confirm.show(
                                    "Confirmation",
                                    "Are you sure you want to remove?",
                                    "Confirm",
                                    "Cancel",
                                    () => {
                                      const newSheetRows = sheetRows.filter(
                                        (item, index) => index !== idx
                                      );
                                      setSheetRows(newSheetRows);
                                    },
                                    () => {},
                                    {
                                      width: "500px",
                                      messageMaxLength: 1000,
                                    }
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                sx={{ my: 2 }}
                count={sheetRows?.length}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </Paper>
            <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
              <Autocomplete
                fullWidth
                size="small"
                sx={{ width: 300 }}
                options={customerRelations || []}
                value={customerRelationType}
                onChange={(e, value) => {
                  setCustomerRelationType(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Please Select User Relation Type"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Stack>
          </Box>
        )}
        {step === 2 && (
          <Card sx={{ p: 2, my: 2 }}>
            <Box sx={{ width: "100%", my: 2 }}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} justifyContent="flex-end">
                  <Box alignSelf="center">
                    <b>Employee / Any issued ID:</b>
                  </Box>
                </Grid>
                <Grid
                  sx={{ mb: `${theme.spacing(2)}` }}
                  item
                  xs={12}
                  sm={8}
                  md={6}
                >
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="employeeId">
                      Select Employee ID column
                    </InputLabel>
                    <Select
                      labelId="employeeId"
                      id="employeeId"
                      required
                      label="Select Employee ID column"
                      value={placeholderToColumn["employeeId"]}
                      onChange={(e) => {
                        setPlaceholderToColumn({
                          ...placeholderToColumn,
                          employeeId: e.target.value,
                        });
                      }}
                    >
                      {sheetColumns?.map((column, idx) => (
                        <MenuItem value={column} key={idx}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} justifyContent="flex-end">
                  <Box alignSelf="center">
                    <b>
                      First Name<sup>*</sup> :
                    </b>
                  </Box>
                </Grid>
                <Grid
                  sx={{ mb: `${theme.spacing(2)}` }}
                  item
                  xs={12}
                  sm={8}
                  md={6}
                >
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="firstName">Select First Name</InputLabel>
                    <Select
                      labelId="firstName"
                      id="firstName"
                      required
                      label="Select Full Name"
                      value={placeholderToColumn["firstName"]}
                      onChange={(e) => {
                        setPlaceholderToColumn({
                          ...placeholderToColumn,
                          firstName: e.target.value,
                        });
                      }}
                    >
                      {sheetColumns?.map((column, idx) => (
                        <MenuItem value={column} key={idx}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>{" "}
                <Grid item xs={12} sm={4} justifyContent="flex-end">
                  <Box alignSelf="center">
                    <b>
                      Last Name<sup>*</sup> :
                    </b>
                  </Box>
                </Grid>
                <Grid
                  sx={{ mb: `${theme.spacing(2)}` }}
                  item
                  xs={12}
                  sm={8}
                  md={6}
                >
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="lastName">Select Last Name</InputLabel>
                    <Select
                      labelId="lastName"
                      id="lastName"
                      required
                      label="Select Full Name"
                      value={placeholderToColumn["lastName"]}
                      onChange={(e) => {
                        setPlaceholderToColumn({
                          ...placeholderToColumn,
                          lastName: e.target.value,
                        });
                      }}
                    >
                      {sheetColumns?.map((column, idx) => (
                        <MenuItem value={column} key={idx}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} justifyContent="flex-end">
                  <Box alignSelf="center">
                    <b>
                      Email<sup>*</sup> :
                    </b>
                  </Box>
                </Grid>
                <Grid
                  sx={{ mb: `${theme.spacing(2)}` }}
                  item
                  xs={12}
                  sm={8}
                  md={6}
                >
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="email">Select Email Column</InputLabel>
                    <Select
                      labelId="email"
                      id="email"
                      required
                      label="Select Email Column"
                      value={placeholderToColumn["email"]}
                      onChange={(e) => {
                        setPlaceholderToColumn({
                          ...placeholderToColumn,
                          email: e.target.value,
                        });
                      }}
                    >
                      {sheetColumns?.map((column, idx) => (
                        <MenuItem value={column} key={idx}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} justifyContent="flex-end">
                  <Box alignSelf="center">
                    <b>Phone Number :</b>
                  </Box>
                </Grid>
                <Grid
                  sx={{ mb: `${theme.spacing(2)}` }}
                  item
                  xs={12}
                  sm={8}
                  md={6}
                >
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="phoneNumber">
                      Select Phone Column
                    </InputLabel>
                    <Select
                      labelId="phoneNumber"
                      id="phoneNumber"
                      required
                      label="Select Phone Column"
                      value={placeholderToColumn["phoneNumber"]}
                      onChange={(e) => {
                        setPlaceholderToColumn({
                          ...placeholderToColumn,
                          phoneNumber: e.target.value,
                        });
                      }}
                    >
                      {sheetColumns?.map((column, idx) => (
                        <MenuItem value={column} key={idx}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} justifyContent="flex-end">
                  <Box alignSelf="center">
                    <b>Profile Image (Optional) :</b>
                  </Box>
                </Grid>
                <Grid
                  sx={{ mb: `${theme.spacing(2)}` }}
                  item
                  xs={12}
                  sm={8}
                  md={6}
                >
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="image">Select Image Column</InputLabel>
                    <Select
                      labelId="image"
                      id="image"
                      required
                      label="Select Image Column"
                      value={placeholderToColumn["profileImage"]}
                      onChange={(e) => {
                        setPlaceholderToColumn({
                          ...placeholderToColumn,
                          profileImage: e.target.value,
                        });
                      }}
                    >
                      {sheetColumns?.map((column, idx) => (
                        <MenuItem value={column} key={idx}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {userInputs?.map((tag, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={4} justifyContent="flex-end">
                      <Box alignSelf="center">
                        <b>{tag?.label} :</b>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{ mb: `${theme.spacing(2)}` }}
                      item
                      xs={12}
                      sm={8}
                      md={6}
                    >
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="image">
                          Select {tag?.label} Column
                        </InputLabel>
                        <Select
                          labelId={tag?.name}
                          id={tag?.name}
                          label={`Select ${tag?.label} Column`}
                          value={placeholderToColumn[tag?.name]}
                          onChange={(e) => {
                            setPlaceholderToColumn({
                              ...placeholderToColumn,
                              [tag?.name]: e.target.value,
                            });
                          }}
                        >
                          {sheetColumns?.map((column, idx) => (
                            <MenuItem value={column} key={idx}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          </Card>
        )}
        {isDone && (
          <Alert
            severity="success"
            sx={{ display: "flex", justifyContent: "center", my: 1 }}
          >
            <Typography variant="h5">
              {sheetRows?.length} Users Added Successfully.
            </Typography>
          </Alert>
        )}
        {sheetColumns?.length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={step === 1}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <LoadingButton
              onClick={step === 2 ? handleSubmit : handleNext}
              variant="contained"
              loading={loading}
            >
              {step === 2 ? "Submit" : "Next"}
            </LoadingButton>
          </Box>
        )}
      </div>
    </Box>
  );
}
