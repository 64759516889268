import React, { useContext } from "react";
import { alpha, useTheme } from "@mui/material";
import GaugeChart from "react-gauge-chart";
import { ProfileContext } from "src/contexts/ProfileContext";
import { MAX_SCORE } from "src/utils/constants";

export default function ScoreDottedBar({ userProfile }) {
  const theme = useTheme();
  const { profile } = useContext(ProfileContext);

  return (
    <GaugeChart
      nrOfLevels={24}
      textColor={theme.colors.alpha.black[100]}
      needleColor={theme.colors.alpha.black[30]}
      needleBaseColor={theme.colors.alpha.black[100]}
      colors={[
        alpha(theme.colors.error.main, 0.7),
        alpha(theme.colors.success.main, 1),
      ]}
      formatTextValue={() => ``}
      arcWidth={0.4}
      percent={
        (userProfile?.individualScore || profile?.individualScore) /
          MAX_SCORE || 0
      }
    />
  );
}
