import { Notify } from "notiflix";
import React, { useContext, useState } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";
import CommitteeCard from "./CommitteeCard";
import { GlobalContext } from "src/contexts/GlobalContext";
import NewCommitteeDialog from "./NewCommitteeDialog";
import { Box, Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function Committees() {
  const [searchParams] = useSearchParams();
  const organisationId = searchParams.get("organisationId");
  const { currentUser } = useContext(GlobalContext);
  const [committees, setCommittees] = useState([]);
  const [orgCommittees, setOrgCommittees] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCommittees = async () => {
    try {
      setLoading(true);
      const reqParams = {
        params: {
          type: "committee",
        },
      };

      const resp = await axiosInstance.get("/tags/type", reqParams);
      if (resp?.status === 200) {
        setCommittees(resp?.data || []);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const getCommitteeOfOrganisation = async () => {
    try {
      setLoading(true);
      const reqParams = {
        params: {
          organisation: currentUser?.orgId || organisationId,
        },
      };

      const resp = await axiosInstance.get("/committees", reqParams);
      if (resp?.status === 200) {
        setOrgCommittees(resp?.data || []);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  React.useEffect(() => {
    getCommittees();
    if (currentUser?.orgId || organisationId) {
      getCommitteeOfOrganisation();
    } else {
      setLoading(false);
    }
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box my={2}>
      <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h2" mb={3}>
          <strong>Committees</strong>
        </Typography>
        {currentUser?.accountType === "admin" && (
          <NewCommitteeDialog
            inputs={committees?.inputs}
            getCommittees={getCommittees}
          />
        )}
      </Stack>

      <Stack spacing={2}>
        {committees?.inputs?.map((item, index) => (
          <CommitteeCard
            key={index}
            item={item}
            inputs={committees?.inputs}
            getCommitteeOfOrganisation={getCommitteeOfOrganisation}
            getCommittees={getCommittees}
            committee={
              orgCommittees?.data?.find(
                (committee) => committee?.committeeId === item?.name
              ) || {}
            }
          />
        ))}
      </Stack>
    </Box>
  );
}
