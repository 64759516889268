import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";
import { Notify } from "notiflix";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { RuppeeSign } from "src/utils/constants";

function Cpew() {
  const { currentUser, pricingData, handleCheckPaymentOnly, handlePurchase } =
    useContext(GlobalContext);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedForms, setSelectedForms] = useState([]);
  const [examsPaid, setExamsPaid] = useState([]);

  useEffect(() => {
    (async () => {
      let id = process.env.REACT_APP_CPEW;
      const hasPaid = await handleCheckPaymentOnly(
        {
          itemId: id,
          item: "exams",
        },
        {
          redirectLink: `/dashboard/exams/${id}?receiverId=${currentUser?.userId}`,
          itemId: id,
        }
      );
      if (hasPaid) {
        setExamsPaid([...examsPaid, id]);
      }
    })();
  }, []);

  const getForms = async () => {
    try {
      setLoading(true);
      let ids = process.env.REACT_APP_CPEW;
      ids = [ids];
      const reqParams = {
        params: {
          ids: ids,
        },
      };
      const resp = await axiosInstance.get("/forms/ids", reqParams);
      if (resp?.status === 200) {
        setForms(resp?.data || []);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    // fetch forms
    getForms();
  }, []);

  const handleBuyNow = async () => {
    await handlePurchase(
      {
        priceId: pricingData?._id || null,
        // in case of one item purchase
        item: pricingData?.item || null,
        itemId: pricingData?.itemId || null,
      },
      {
        redirectLink: pricingData?.redirectLink,
      }
    );
  };

  return (
    <Stack spacing={3} my={2}>
      <Typography variant="h1">CPEW Dashboard</Typography>
      {loading ? (
        <SuspenseLoader height="35vh" />
      ) : (
        <>
          <Card sx={{ p: 2.5 }}>
            <Typography variant="h2" mb={2} fontSize={"large"}>
              Available Certifications
            </Typography>

            <Stack spacing={1}>
              {forms?.data?.map((item, idx) => (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  key={idx}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "Medium",
                        fontWeight: "500",
                      }}
                    >
                      {item?.title}
                    </Typography>
                  </Box>
                  <Box>
                    {examsPaid.includes(item?._id) ? (
                      <Button
                        color="success"
                        variant="contained"
                        component={Link}
                        to={`/dashboard/exams/${item?._id}?receiverId=${currentUser?.userId}`}
                      >
                        Take Exam
                      </Button>
                    ) : (
                      <Button
                        sx={{ borderRadius: 0.5 }}
                        color={
                          selectedForms?.findIndex(
                            (each) => each?.id === item?._id
                          ) > -1
                            ? "success"
                            : "inherit"
                        }
                        variant="contained"
                        onClick={() => {
                          if (
                            selectedForms?.findIndex(
                              (each) => each?.id === item?.id
                            ) > -1
                          ) {
                            setSelectedForms(
                              selectedForms.filter(
                                (each) => each?.id !== item?.id
                              )
                            );
                          } else {
                            setSelectedForms([...selectedForms, item]);
                          }
                        }}
                      >
                        {selectedForms?.findIndex(
                          (each) => each?.id === item?._id
                        ) > -1
                          ? "Selected"
                          : "Select"}
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
            </Stack>
          </Card>
          <Card sx={{ p: 2.5 }}>
            <Typography variant="h2" mb={2} fontSize={"large"}>
              Your Selection
            </Typography>
            <Stack spacing={1}>
              {selectedForms?.length === 0 ? (
                <Typography
                  sx={{
                    fontSize: "Medium",
                    fontWeight: "500",
                  }}
                >
                  No certification selected yet.
                </Typography>
              ) : (
                selectedForms.map((item, idx) => (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    key={idx}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "Medium",
                          fontWeight: "500",
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "Medium",
                          fontWeight: "700",
                        }}
                      >
                        {RuppeeSign} {item?.amount}
                      </Typography>
                    </Box>
                  </Box>
                ))
              )}
            </Stack>
            <Divider sx={{ my: 1.5 }} />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "Medium",
                    fontWeight: "900",
                  }}
                >
                  Total Cost:
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "Medium",
                    fontWeight: "900",
                  }}
                >
                  {RuppeeSign}{" "}
                  {selectedForms.reduce((acc, item) => acc + item?.amount, 0) ||
                    "0"}
                </Typography>
              </Box>
            </Box>
            <Button
              sx={{ borderRadius: 0.5, width: "100%", mt: 1.5 }}
              color="success"
              variant="contained"
              disabled={
                selectedForms.length === 0 ||
                selectedForms.reduce((acc, item) => acc + item?.amount, 0) === 0
              }
              onClick={handleBuyNow}
            >
              Start Certifications
            </Button>
          </Card>
        </>
      )}

      <Card sx={{ p: 2.5 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Active Certifications
        </Typography>
        <Typography
          sx={{
            fontSize: "Medium",
            fontWeight: "500",
          }}
        >
          No active certifications at the moment.
        </Typography>
      </Card>
      <Card sx={{ p: 2.5 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Certifications Insights
        </Typography>
        <Box
          component={"ul"}
          sx={{
            px: 2,
          }}
        >
          {[
            "Gain practical experience in cutting-edge cybersecurity domains",
            "Flexible 30-90 day duration to fit your schedule",
            "Self-paced online training included with each internship",
            "Earn valuable certificates to boost your resume",
            "Opportunity to work on real-world projects",
          ].map((each) => (
            <li>
              <Typography
                sx={{
                  fontSize: "Medium",
                  fontWeight: "500",
                }}
              >
                {each}
              </Typography>
            </li>
          ))}
        </Box>
      </Card>
    </Stack>
  );
}

export default Cpew;
