import {
  Typography,
  Box,
  Avatar,
  Card,
  Grid,
  useTheme,
  styled,
  Stack,
  alpha,
  CardActionArea,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import {
  AccountBoxTwoTone,
  AddReactionTwoTone,
  ChevronRightTwoTone,
  PaymentsTwoTone,
  StarTwoTone,
} from "@mui/icons-material";
import { Fragment, useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { FEATURES_ACCESS } from "src/utils/constants";

function OrgStats({ profile, viewType, handleTabsChange }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentUser } = useContext(GlobalContext);

  return (
    <>
      <Card
        sx={{
          p: 3,
          background: `${theme.colors.gradients.blue4}`,
        }}
      >
        <Grid container spacing={3}>
          {[
            // {
            //   title: "Check-ins",
            //   icon: AccountBoxTwoTone,
            //   color: theme.colors.primary.main,
            //   value: profile?.stats?.checkins || 0,
            //   tab: "check-ins",
            //   nofor: [],
            // },
            {
              title: "Accounts",
              icon: AccountBoxTwoTone,
              color: theme.colors.warning.main,
              value: profile?.stats?.accounts || 0,
              tab: "accounts",
              nofor: [],
            },
            {
              title: "Ratings",
              icon: StarTwoTone,
              color: theme.colors.info.main,
              value: profile?.stats?.ratings || 0,
              tab: "ratings",
              nofor: [],
            },
            {
              title: "Orders",
              icon: PaymentsTwoTone,
              color: theme.colors.success.main,
              value: profile?.stats?.orders || 0,
              tab: "orders",
              nofor: ["misc"],
            },
            {
              title: "Exit Interviews",
              icon: AddReactionTwoTone,
              color: theme.colors.error.main,
              value: profile?.stats?.interviews || 0,
              tab: "exit-interviews",
              nofor: ["misc"],
            },
          ]?.map((item, index) => (
            <Fragment key={index}>
              {item?.nofor?.includes(currentUser?.role) ? (
                <></>
              ) : (
                <Grid item md={3} sm={6} xs={12}>
                  <CardBorderBottom
                    onClick={() => {
                      handleTabsChange(item.tab === viewType ? null : item.tab);
                    }}
                    sx={{
                      background:
                        viewType === item?.tab && `${alpha(item.color, 1)}`,
                      color: viewType === item?.tab && `white`,
                      borderBottomColor: `${item.color}`,
                    }}
                  >
                    <CardActionAreaWrapper
                      sx={{
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box display="flex">
                        <Avatar
                          variant="rounded"
                          sx={{
                            width: `${theme.spacing(5.5)}`,
                            height: `${theme.spacing(5.5)}`,
                            background:
                              viewType === item?.tab
                                ? `white`
                                : `${item.color}`,
                            color: viewType === item?.tab && `${item.color}`,
                          }}
                        >
                          <item.icon />
                        </Avatar>
                        <Box ml={2}>
                          <Typography
                            gutterBottom
                            component="div"
                            variant="caption"
                            sx={{
                              color: viewType === item?.tab && `white`,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {t(item.title)}
                          </Typography>
                          <Typography variant="h2">{item.value}</Typography>
                        </Box>
                      </Box>
                      <ChevronRightTwoTone
                        sx={{
                          transform: `rotate(${
                            viewType === item?.tab ? 90 : 0
                          }deg)`,
                        }}
                      />
                    </CardActionAreaWrapper>
                  </CardBorderBottom>
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      </Card>
      {(!viewType || Object.keys(FEATURES_ACCESS)?.includes(viewType)) && (
        <Stack spacing={2} direction={"row"}>
          {Object.values(FEATURES_ACCESS)?.map((item, index) => {
            if (!profile?.data?.featuresAccess?.[item.value]) return null;
            return (
              <Card
                key={index}
                sx={{
                  p: 2,
                  width: "100%",
                  overflow: "visible",
                  position: "relative",
                }}
                variant="outlined"
                onClick={() => {
                  handleTabsChange(item.value === viewType ? null : item.value);
                }}
              >
                <DividerVertial
                  sx={{
                    background: theme.colors[item.color].main,
                  }}
                />
                <CardActionAreaWrapper
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box display="flex">
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: `${theme.spacing(5.5)}`,
                        height: `${theme.spacing(5.5)}`,
                        background: alpha(
                          theme.colors[item.color].main,
                          viewType === item?.value ? 1 : 0.1
                        ),
                        color:
                          viewType === item?.value
                            ? `white`
                            : theme.colors[item.color].main,
                      }}
                    >
                      <item.icon />
                    </Avatar>
                    <Box ml={2}>
                      <Typography
                        gutterBottom
                        component="div"
                        variant="caption"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t(item.name)}
                      </Typography>
                      <Typography variant="h2">
                        {profile?.featureStats?.[item.value] || 0}
                      </Typography>
                    </Box>
                  </Box>
                  <ChevronRightTwoTone
                    sx={{
                      transform: `rotate(${
                        viewType === item?.value ? 90 : 0
                      }deg)`,
                    }}
                  />
                </CardActionAreaWrapper>
              </Card>
            );
          })}
        </Stack>
      )}
    </>
  );
}

export default OrgStats;

const DividerVertial = styled(Box)(
  ({ theme }) => `
    height: 60%;
    width: 6px;
    left: -3px;
    border-radius: 50px;
    position: absolute;
    top: 20%;
  `
);

const CardBorderBottom = styled(Card)(
  () => `
    border-bottom: transparent 5px solid;
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `

        .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: ${theme.colors.primary.main};
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: .03;
          }
        }
  `
);
