import { Box, Card, Typography } from "@mui/material";

const ProgressCard = ({ title, content, caption, Icon, button }) => {
  return (
    <Card sx={{ p: 2.5 }}>
      <Box
        mb={0.5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {Icon}

        {title && (
          <Typography
            component={"h3"}
            fontSize={"1.125rem"}
            fontWeight={"600"}
            sx={{ m: 0, mt: 0, mb: 0 }}
          >
            {title}
          </Typography>
        )}
        {button}
      </Box>
      <Typography
        component={"p"}
        sx={{ m: 0, mb: 0.25 }}
        fontWeight={"700"}
        fontSize={"1.875rem"}
      >
        {content}
      </Typography>
      <Typography component={"p"} fontSize={"small"}>
        {caption}
      </Typography>
    </Card>
  );
};

export default ProgressCard;
