import {
  AddCircleTwoTone,
  AddTwoTone,
  BlockTwoTone,
  DoneTwoTone,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { ISAC_PLATFORMS } from "src/utils/constants";

export default function AddToPlatform({ formInput, setFormInput }) {
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);
  const [isWait, setIsWait] = useState(false);

  const handleAddToPlatform = async (platform) => {
    try {
      if (!platform?.name) {
        Notify.warning("Platform is required");
        return;
      }

      setIsWait(true);

      const resp = await axiosInstance.post("/auth/platforms", {
        platform: {
          name: platform?.name,
          isLocked: false,
        },
        userId: formInput._id,
      });

      if (resp.status === 200) {
        setFormInput({
          ...formInput,
          platforms: [
            ...formInput.platforms,
            {
              name: platform?.name,
              isLocked: false,
            },
          ],
        });
        Notify.success("Platform added successfully");
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleActivateDeactivateOnPlatform = async (platform) => {
    try {
      if (!platform) {
        Notify.warning("Platform is required");
        return;
      }

      setIsWait(true);

      const resp = await axiosInstance.put(`/auth/platforms/${formInput._id}`, {
        platform: {
          name: platform.name,
          isLocked: !platform.isLocked,
        },
      });

      if (resp.status === 200) {
        const newPlatforms = formInput.platforms.map((p) =>
          p.name === platform.name ? { ...p, isLocked: !p.isLocked } : p
        );
        setFormInput({
          ...formInput,
          platforms: newPlatforms,
        });
        Notify.success("Updated successfully");
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleActivateDeactivateAccount = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(`/users/${formInput?._id}`);
      if (resp.status === 200) {
        Notify.success("Updated successfully");
        if (currentUser?.userId === formInput?._id) {
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.reload();
        } else {
          if (formInput?.isLocked) window.location.reload();
          else navigate("/dashboard/users");
        }
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleUpdateUserOnPlatform = (platform, action) => {
    if (action === "add") {
      Confirm.show(
        "This action is irreversible",
        `Are you sure you want to add ${(
          formInput?.firstName +
          " " +
          (formInput?.lastName || "")
        )?.toUpperCase()} to ${platform?.toUpperCase()}?`,
        "Confirm",
        "Cancel",
        () => handleAddToPlatform({ name: platform }),
        () => {},
        {
          width: "500px",
          messageMaxLength: 1000,
        }
      );
      return;
    }

    if (platform?.name === "ethixcards") {
      Confirm.show(
        "Are you sure?",
        `Deactivating account on EthixFirst, will also deactivate the account on all other platforms.`,
        "Confirm",
        "Cancel",
        () => handleActivateDeactivateAccount(),
        () => {},
        {
          width: "500px",
          messageMaxLength: 1000,
        }
      );
      return;
    }

    // if user is already in the platform, then change the status of isLocked
    Confirm.show(
      "Are you sure?",
      `Are you sure you want to ${
        platform?.isLocked ? "activate" : "deactivate"
      } ${(
        formInput?.firstName +
        " " +
        (formInput?.lastName || "")
      )?.toUpperCase()} on ${platform?.name?.toUpperCase()}?`,
      "Confirm",
      "Cancel",
      () => handleActivateDeactivateOnPlatform(platform),
      () => {},
      {
        width: "500px",
        messageMaxLength: 1000,
      }
    );
  };

  return (
    formInput?._id && (
      <Card>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h4">Connected Platforms</Typography>
            <Stack direction={"row"} spacing={2}>
              {ISAC_PLATFORMS?.map((platform, index) => {
                const _platform = formInput?.platforms?.find(
                  (p) => p.name === platform
                );
                if (!_platform) {
                  // if the platform is not in the user's platforms
                  return (
                    <Tooltip
                      key={index}
                      title={`Click to create account on ${capitalize(
                        platform
                      )}`}
                    >
                      <Chip
                        label={
                          <Typography variant="h4">
                            {capitalize(platform)}
                          </Typography>
                        }
                        avatar={
                          <img
                            src={`/static/images/logo/${platform}.png`}
                            alt={platform}
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        }
                        color={"secondary"}
                        variant={"outlined"}
                        sx={{ cursor: "pointer" }}
                        deleteIcon={
                          isWait ? (
                            <CircularProgress size={20} />
                          ) : (
                            <AddTwoTone style={{ color: "black" }} />
                          )
                        }
                        onDelete={() =>
                          handleUpdateUserOnPlatform(platform, "add")
                        }
                        onClick={() =>
                          handleUpdateUserOnPlatform(platform, "add")
                        }
                      />
                    </Tooltip>
                  );
                }
                return (
                  <Tooltip
                    key={index}
                    title={`Click to ${
                      _platform?.isLocked ? "activate" : "deactivate"
                    } on ${capitalize(platform)}`}
                  >
                    <Chip
                      label={
                        <Typography variant="h4">
                          {capitalize(platform)}
                        </Typography>
                      }
                      avatar={
                        <img
                          src={`/static/images/logo/${platform}.png`}
                          alt={platform}
                          style={{
                            borderRadius: "50%",
                          }}
                        />
                      }
                      color={_platform?.isLocked ? "error" : "success"}
                      variant={_platform?.isLocked ? "outlined" : "filled"}
                      sx={{ cursor: "pointer" }}
                      deleteIcon={
                        isWait ? (
                          <CircularProgress size={20} />
                        ) : _platform?.isLocked ? (
                          <BlockTwoTone style={{ color: "error" }} />
                        ) : (
                          <DoneTwoTone style={{ color: "white" }} />
                        )
                      }
                      onDelete={() => handleUpdateUserOnPlatform(_platform)}
                      onClick={() => handleUpdateUserOnPlatform(_platform)}
                    />
                  </Tooltip>
                );
              })}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    )
  );
}
