import React, { useContext } from "react";
import {
  Typography,
  CardContent,
  Card,
  Divider,
  Stack,
  List,
  alpha,
  CardHeader,
  Grid,
  capitalize,
  Button,
} from "@mui/material";
import { ProfileContext } from "src/contexts/ProfileContext";
import { splitCamelCaseToWords } from "src/utils/helpers";
import { HourglassBottom } from "@mui/icons-material";
import ListCustom from "src/components/List";
import EmptyHere from "src/components/EmptyHere";
import { fDate } from "src/utils/date-fns";

export default function CareerSection() {
  const { profile } = useContext(ProfileContext);

  return (
    <Card
      elevation={0}
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: (theme) => theme.palette.info.dark,
          color: "white",
          p: 1,
        }}
        title={
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <HourglassBottom />
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              Associated Companies and Organisations' Information
            </Typography>
          </Stack>
        }
      />
      <CardContent>
        <Stack
          spacing={2}
          sx={{
            border: (theme) => `1px solid ${theme.palette.grey[300]}`,
            borderRadius: 1,
          }}
        >
          {profile?.employementHistory?.length > 0 ? (
            profile?.employementHistory?.map((org, index) => (
              <Card elevation={0} key={index}>
                <CardHeader
                  title={
                    <Typography
                      variant="h3"
                      fontSize={18}
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {org?.title || ""}
                    </Typography>
                  }
                  subheader={
                    org?.companyWebsite && (
                      <Typography
                        variant="subtitle2"
                        component={"a"}
                        href={
                          "http://" +
                            org?.companyWebsite?.replace(/(^\w+:|^)\/\//, "") ||
                          ""
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {org?.companyWebsite?.replace(/(^\w+:|^)\/\//, "") ||
                          ""}
                      </Typography>
                    )
                  }
                  sx={{
                    px: 1,
                    py: 0.5,
                    backgroundColor: (theme) =>
                      alpha(theme.palette.info.dark, 0.1),
                  }}
                />
                <Divider />
                {org?.relations
                  ?.sort((a, b) => {
                    const a_end = a?.createdAt;
                    const b_end = b?.createdAt;
                    return b_end - a_end;
                  })
                  ?.map((item, index) => {
                    if (item?.type === "role") {
                      return (
                        <>
                          <List component={Grid} container key={index}>
                            <Grid item xs={4}>
                              <ListCustom
                                item={{
                                  label: "Role",
                                  value: (item?.role || "-")?.toUpperCase(),
                                }}
                                width={80}
                                rightStyle={{
                                  fontWeight: "bold",
                                }}
                                leftStyle={{
                                  className: "link-color",
                                  width: 140,
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <ListCustom
                                item={{
                                  label: "Employee ID",
                                  value: item?.employeeId || "-",
                                }}
                                width={80}
                                rightStyle={{
                                  fontWeight: "bold",
                                }}
                                leftStyle={{
                                  className: "link-color",
                                  width: 140,
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <ListCustom
                                item={{
                                  label: "Organisation Email",
                                  value: item?.companyEmail || "-",
                                }}
                                width={80}
                                rightStyle={{
                                  fontWeight: "bold",
                                }}
                                leftStyle={{
                                  className: "link-color",
                                  width: 140,
                                }}
                              />
                            </Grid>
                            {Object.entries(item || {}).map(([key, value]) => {
                              const notShow = [
                                "_id",
                                "__v",
                                "user",
                                "permissions",
                                "organisation",
                                "createdAt",
                                "employeeId",
                                "role",
                                "membership",
                                "type",
                              ];
                              if (notShow.includes(key)) return null;

                              if (typeof value !== "string") return null;

                              const _item = {
                                label: splitCamelCaseToWords(key),
                                value: splitCamelCaseToWords(value || "-"),
                              };

                              return (
                                <Grid item xs={4} key={key}>
                                  <ListCustom
                                    item={_item}
                                    width={80}
                                    leftStyle={{
                                      className: "link-color",
                                      width: 140,
                                    }}
                                  />
                                </Grid>
                              );
                            })}
                          </List>
                          <Divider style={{ marginTop: 25 }} />
                        </>
                      );
                    }
                    if (item?.type === "customer-relation") {
                      return (
                        <List component={Grid} container key={index}>
                          <Grid item xs={4}>
                            <ListCustom
                              item={{
                                label: "Relation",
                                value: (item?.relation || "-")?.toUpperCase(),
                              }}
                              width={80}
                              rightStyle={{
                                fontWeight: "bold",
                              }}
                              leftStyle={{
                                className: "link-color",
                                width: 140,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <ListCustom
                              item={{
                                label: "Employee ID",
                                value: item?.meta?.employeeId || "-",
                              }}
                              width={80}
                              rightStyle={{
                                fontWeight: "bold",
                              }}
                              leftStyle={{
                                className: "link-color",
                                width: 140,
                              }}
                            />
                          </Grid>
                          {Object.entries(item?.meta || {}).map(
                            ([key, value]) => {
                              if (!value) return null;

                              const _item = {
                                label: capitalize(key?.label || key || "-"),
                                value:
                                  typeof value === "string"
                                    ? value
                                    : {
                                        text: value?.value || "-",
                                        date: fDate(value?.value || new Date()),
                                        file: (
                                          <Button
                                            size="small"
                                            component={"a"}
                                            sx={{ px: 0 }}
                                            href={
                                              process.env
                                                .REACT_APP_DO_SPACE_URL +
                                              value?.value
                                            }
                                            target="_blank"
                                          >
                                            {value?.value?.split("/")?.pop()}
                                          </Button>
                                        ),
                                      }[value?.type] || "-",
                              };

                              return (
                                <Grid item xs={4} key={key}>
                                  <ListCustom
                                    item={_item}
                                    width={80}
                                    leftStyle={{
                                      className: "link-color",
                                      width: 140,
                                    }}
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </List>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </Card>
            ))
          ) : (
            <EmptyHere py={1} title={"No associated Organisation added yet"} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
