import axios from "axios";
import { Notify } from "notiflix";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const handleLogout = (params) => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  let _url = `/auth/login`;
  if (params) {
    _url = `${_url}?${params}`;
  }

  window.location.replace(_url);
};

const ISSERVER = typeof window === "undefined";
let headerAccessToken;
if (!ISSERVER) {
  headerAccessToken = localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : "";
}
axiosInstance.defaults.headers.common["authorization"] =
  "Bearer " + headerAccessToken;
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

(async () => {
  axiosInstance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([401].includes(response?.status)) {
        Notify.failure("Please Login");
        handleLogout();
        return;
      }
      if ([403].includes(response?.status)) {
        Notify.failure("Access Denied");
        handleLogout();
        return;
      }
      return response;
    },
    (error) => {
      if (window.location.pathname === "/auth/login")
        return Promise.reject(error);

      if (error.response?.status === 401) {
        Notify.failure("Please Login");
      }
      if (error.response?.status === 403) {
        Notify.failure("Access Denied");
      }

      if ([401, 403].includes(error.response?.status)) {
        handleLogout(
          error.response?.status === 401
            ? `redirect_url=${encodeURIComponent(window.location.pathname)}`
            : ""
        );
      }
      return Promise.reject(error);
    }
  );
})();

export default axiosInstance;
