import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function RateFormButton({ receiverId, item }) {
  const [searchParams] = useSearchParams();
  const { currentUser, handleCheckPayment } = useContext(GlobalContext);
  const [showRatingButton, setShowRatingButton] = useState(true);

  const receiverTypeParam = searchParams.get("receiverType");

  useEffect(() => {
    if (
      ["triager", "admin"].includes(currentUser?.accountType) ||
      (receiverId && receiverId === currentUser?.userId)
    ) {
      setShowRatingButton(false);
      return;
    }

    if (currentUser?.orgId && item?.access !== "organisation") {
      setShowRatingButton(false);
      return;
    }
  }, [currentUser, item, receiverId]);

  return (
    showRatingButton && (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={async () => {
          const redirectLink = `/dashboard/forms/${item?._id}?&receiverType=${
            receiverTypeParam || ""
          }&receiverId=${item?.type === "community" ? "" : receiverId || ""}${
            currentUser?.orgId ? `&orgId=${currentUser?.orgId}` : ""
          }`;

          if (item?.premium && item?.amount > 0) {
            const hasPaid = await handleCheckPayment(
              {
                itemId: item?._id,
                item: "forms",
                receiverId: receiverId,
              },
              {
                redirectLink: redirectLink,
                itemId: item?._id,
              }
            );
            if (hasPaid) {
              window.location.href = redirectLink;
            }
          } else {
            window.location.href = redirectLink;
          }
        }}
      >
        Select
      </Button>
    )
  );
}
