import {
  Box,
  Typography,
  Button,
  styled,
  Chip,
  Stack,
  Paper,
  InputBase,
  IconButton,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  capitalize,
} from "@mui/material";

import {
  AddTwoTone,
  ArrowForwardIosOutlined,
  Search,
} from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PAGE_LIMIT } from "src/utils/constants";
import axiosInstance from "src/utils/axiosInstance";
import { GlobalContext } from "src/contexts/GlobalContext";
import { fDateTimeSuffix } from "src/utils/date-fns";

export default function Campaigns({ organisationId }) {
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [loading, setLoading] = useState(true);
  const [filterObj, setFilterObj] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [events, setEvents] = useState({});
  const [organisation, setOrganisation] = useState(null);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    setOrganisation(organisationId || currentUser?.orgId);
  }, [currentUser, organisationId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
          type: "campaign",
        };
      }

      if (organisation || organisationId) {
        reqParams.params.organisation = organisation || organisationId;
      }

      const resp = await axiosInstance.get("/events", reqParams);
      if (resp?.status === 200) {
        setEvents(resp?.data || {});
      }
      setLoading(false);
    })();
  }, [page, filterObj]);

  return (
    <Box>
      <Stack
        direction="item"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Stack direction="item" spacing={2}>
          <Box fullWidth>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here..."
                inputProps={{ "aria-label": "search here" }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (e.target.value.length) {
                      setFilterObj({ ...filterObj, search: searchString });
                    } else {
                      setFilterObj({ ...filterObj, search: null });
                    }
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      page: 1,
                    });
                  }
                }}
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value?.length > 0) {
                    setSearchString(e.target.value);
                  } else {
                    setFilterObj({
                      ...filterObj,
                      search: null,
                    });
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{
                  p: "10px",
                  m: 0.2,
                  bgcolor: "primary.main",
                  color: "white",
                }}
                onClick={() => {
                  setFilterObj({
                    ...filterObj,
                    search: searchString,
                  });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              >
                <Search />
              </IconButton>
            </Paper>
          </Box>
        </Stack>
        {(currentUser?.orgId || organisation) && (
          <Button
            startIcon={<AddTwoTone />}
            component={Link}
            to={`/dashboard/campaigns/create${
              currentUser.accountType === "admin"
                ? `?organisation=${organisation}`
                : ""
            }`}
            variant="outlined"
            color="primary"
          >
            Create New
          </Button>
        )}
      </Stack>
      <Box sx={{ my: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Available to</TableCell>
                <TableCell align="right">Created At</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events?.data?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      component={Link}
                      to={`/dashboard/campaigns/${item?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="h4">{item?.title}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {item?.relations?.map((i) => capitalize(i))?.join(", ")}
                  </TableCell>
                  <TableCell align="right">
                    {fDateTimeSuffix(item?.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={Link}
                      to={`/dashboard/campaigns/${item?._id}`}
                    >
                      <ArrowForwardIosOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {events?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    It's empty here
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {!loading && events?.data?.length > 0 && (
        <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
          <Stack spacing={2}>
            <Pagination
              count={events?.totalPages || 0}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
    display: flex;
  `
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(12)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      margin: ${theme.spacing(0.5)};
      height: 20px;
      line-height: 28px;
      font-weight: bold;
`
);
