import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ManualFormSubmissions from "src/sections/ManualForms/ManualFormSumissions";
import { Box, capitalize, Card, Chip, Stack, useTheme } from "@mui/material";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import ManualFormSubmissionLogs from "./components/ManualFormSubmissionLogs";
import { useSearchParams } from "react-router-dom";

export default function SumittedPoshForm() {
  const theme = useTheme();
  const { submissionId } = useParams();
  const [searchParams] = useSearchParams();
  const invoiceIdParam = searchParams.get("invoiceId");
  const [expanded, setExpanded] = useState(true);
  const [submissionData, setSubmissionData] = useState({});
  const [form, setForm] = useState({});
  const [formInput, setFormInput] = useState({});
  const [loading, setLoading] = useState(false);

  const getInvoiceData = async (submissionData) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/orders/invoice/${invoiceIdParam}`);
      if (resp?.status === 200) {
        if (resp?.data?._id) {
          const updateResp = await axiosInstance.put(
            `/submissions/${submissionId}`,
            {
              status: "open",
              careerProtection: {
                ...(submissionData?.careerProtection || {}),
                orderId: resp.data._id,
              },
            }
          );
          if (updateResp?.status === 200) {
            setSubmissionData({
              ...submissionData,
              careerProtection: {
                ...(submissionData?.careerProtection || {}),
                orderId: resp.data._id,
              },
              status: "open",
            });
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const getSubmissions = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/submissions/${submissionId}`);
      if (resp?.status === 200) {
        const { manualform, answers, ..._data } = resp.data?.data || {};
        setForm(manualform || {});
        setFormInput(answers || {});
        setSubmissionData(_data);
        if (invoiceIdParam) {
          getInvoiceData(_data);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (!submissionId) {
      Notify.failure("Invalid form ID");
      return;
    }
    getSubmissions();
  }, [submissionId]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box my={2} mb={6}>
      <Card>
        <ManualFormSubmissions
          data={submissionData}
          form={form}
          formInput={formInput}
          expanded={expanded}
          setExpanded={setExpanded}
          actions={
            <Stack direction={"row"} spacing={3}>
              <Box>
                <Chip
                  label={capitalize(submissionData?.status || "")}
                  size="small"
                  sx={{
                    backgroundColor:
                      submissionData?.status === "closed"
                        ? theme.palette.success.main
                        : submissionData?.status === "failed"
                        ? theme.palette.error.main
                        : theme.palette.warning.main,
                    color: "white",
                  }}
                />
              </Box>
            </Stack>
          }
        />
        <ManualFormSubmissionLogs
          data={submissionData}
          setData={setSubmissionData}
          form={form}
          submissionId={submissionId}
          getSubmissions={() => {
            getSubmissions();
            setExpanded(true);
          }}
        />
      </Card>
    </Box>
  );
}
