import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import React from "react";
import { Box, styled } from "@mui/material";

export default function QuillTextarea({ onChange, value, placeholder }) {
  return (
    <EditorWrapper>
      <ReactQuill
        placeholder={placeholder || "Write something here..."}
        value={value || ""}
        onChange={onChange}
      />
    </EditorWrapper>
  );
}

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 300px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);
