import {
  Box,
  Card,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  styled,
  Stack,
  LinearProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Alert,
  AlertTitle,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";

import { useTranslation } from "react-i18next";
import { Notify } from "notiflix";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";

import { PAGE_LIMIT } from "src/utils/constants";
import { Link, useSearchParams } from "react-router-dom";
import { AddTwoTone, CheckTwoTone } from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";
import EmptyHere from "src/components/EmptyHere";
import { LoadingButton } from "@mui/lab";
import { GlobalContext } from "src/contexts/GlobalContext";

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`
);

const ButtonSearch = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`
);

export default function SearchAndAddParticipant({ event }) {
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("search");
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState(searchParam || "");
  const [users, setUsers] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [registeredUsers, setRegisteredUsers] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    if (searchParam?.length > 0) {
      setSearchString(searchParam);
      setFilterObj({
        ...filterObj,
        search: searchParam,
      });
      searchCustomerRelationUsers({
        params: { search: searchParam },
      });
    } else {
      setSearchString("");
      setFilterObj({
        ...filterObj,
        search: null,
      });
    }
  }, [searchParam]);

  useEffect(() => {
    (async () => {
      try {
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        if (searchString) {
          searchCustomerRelationUsers(reqParams);
        }
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page]);

  useEffect(() => {
    getRegisteredUsers();
  }, []);

  const getRegisteredUsers = async () => {
    try {
      const resp = await axiosInstance.get(`/customer-relations`, {
        params: {
          event: event?._id,
        },
      });
      if (resp?.status === 200) {
        setRegisteredUsers(resp?.data?.data || []);
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const searchCustomerRelationUsers = async (reqParams) => {
    try {
      reqParams.params = {
        ...reqParams.params,
        group: "users",
      };
      const resp = await axiosInstance.get(`/customer-relations`, reqParams);
      if (resp?.status === 200) {
        setUsers(resp?.data || {});
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleAddParticipant = async (userId) => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.post(`/customer-relations`, {
        user: userId,
        event: event?._id,
        relation: "participant",
        type: "manual",
      });
      if (resp?.status === 200) {
        Notify.success("Participant added successfully");
        getRegisteredUsers();
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Box m={2} my={6}>
      {searchString?.length > 0 && users?.data?.length === 0 && (
        <Alert
          severity="warning"
          sx={{ mb: 2 }}
          action={
            <Button
              color="warning"
              size="small"
              variant="contained"
              component={Link}
              to={`/dashboard/customer-relations/create${
                currentUser?.accountType === "admin"
                  ? `?organisation=${event?.organisation?._id}`
                  : ""
              }`}
              startIcon={<AddTwoTone />}
              target="_blank"
              rel="noopener noreferrer"
            >
              add new User Relation
            </Button>
          }
        >
          <AlertTitle>
            If you didn't find user you're looking for, you can{" "}
            <b>add new User (or user relation)</b> by clicking on "add new User
            Relations" button <br /> or just click on{" "}
            <b>Create New Participant</b> tab.
          </AlertTitle>
        </Alert>
      )}
      <Card sx={{ textAlign: "center", display: "flex" }} elevation={0}>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInputWrapper
            type="text"
            placeholder={t(`Search by EthixFirst ID, User Name, or Email`)}
            value={searchString || ""}
            onChange={(e) => {
              setSearchString(e.target.value);
              if (e.target.value?.length === 0) {
                delete filterObj.search;
                setFilterObj(filterObj);
                setUsers(null);
              }
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                setSearchParams({
                  search: searchString,
                });
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <ButtonSearch
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSearchParams({
                      search: searchString,
                    });
                  }}
                >
                  {t("Search")}
                </ButtonSearch>
              </InputAdornment>
            }
            startAdornment={
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </Card>
      {loading ? (
        <LinearProgress sx={{ my: 2 }} />
      ) : (
        <>
          {searchString && (
            <>
              {users?.data?.length > 0 && (
                <TableContainer component={Paper} elevation={0}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.data?.map((row) => (
                        <TableRow key={row?._id}>
                          <TableCell>
                            <Typography variant="h6">
                              {row?.user?.firstName +
                                " " +
                                row?.user?.lastName || ""}
                            </Typography>
                          </TableCell>
                          <TableCell>{row?.user?.email}</TableCell>
                          <TableCell>{row?.user?.phoneNumber || "-"}</TableCell>
                          <TableCell align="right">
                            {registeredUsers?.some(
                              (user) => user?.user?._id === row?.user?._id
                            ) ? (
                              <Button
                                color="success"
                                size="small"
                                startIcon={<CheckTwoTone />}
                              >
                                {t("Registered")}
                              </Button>
                            ) : (
                              <LoadingButton
                                loading={isWait}
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{ mr: 1 }}
                                onClick={() => {
                                  handleAddParticipant(row?.user?._id);
                                }}
                              >
                                <AddTwoTone /> {t("Add")}
                              </LoadingButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  {!loading && users?.data?.length > 0 && (
                    <Box
                      sx={{
                        m: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Stack spacing={2}>
                        <Pagination
                          count={users?.totalPages}
                          page={page}
                          onChange={handlePageChange}
                          color="primary"
                        />
                      </Stack>
                    </Box>
                  )}
                </TableContainer>
              )}
              {users?.data?.length === 0 && (
                <EmptyHere title={t("No user found")} />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
