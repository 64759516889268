import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export default function ScoringExamples() {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <div>
      <Button size="small" onClick={toggle}>
        Example
      </Button>

      <Drawer anchor={"right"} open={open} onClose={toggle}>
        <CardHeader
          title="Scoring Examples"
          action={
            <Button size="small" onClick={toggle}>
              Close
            </Button>
          }
        />
        <CardContent
          sx={{
            width: "35vw",
            position: "relative",
          }}
        >
          <Alert severity="info" icon={false} sx={{ mb: 3 }}>
            <Typography variant="h4">
              Formula = (Score / Weightage) * 1000
            </Typography>
          </Alert>
          <Card sx={{ my: 2 }}>
            <CardContent>
              <Typography variant="body2" color={"GrayText"}>
                Let There is a Form A with 10 Questions and 80 Total Weightage.
                <br />
                <br />
                Employee got 30 Score out of 80 in Form A. So, his score will be
                calculated as follows:
              </Typography>
              <br />
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Form Weightage = 80
                <br />
                Form Score = 30
                <br />
                <br />
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  <b>Employee Score = 375</b> (out of 1000)
                </Typography>
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="body2" color={"GrayText"}>
                Now let there is another form, Form B with 40 Questions and 2500
                Total Weightage.
                <br />
                <br />
                Employee got 1600 Score out of 2500 in Form A. So, his score
                will be calculated as follows:
              </Typography>
              <br />
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Form Weightage = 2500
                <br />
                Form Score = 1600
                <br />
                <br />
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  <b>Employee Score = 640</b> (out of 1000)
                </Typography>
              </Typography>
            </CardContent>
          </Card>

          <Card sx={{ my: 2 }}>
            <CardContent>
              <Typography variant="body2" color={"GrayText"}>
                Now <b>EMPLOYEE RATING</b> will be calculated by taking average
                of all Scores of all Forms.
                <br />
                <br />
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Form A Score = 375
                  <br />
                  Form B Score = 640
                  <br />
                  <br />
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  <b>Final Score = 507.5</b> (~508 out of 1000)
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </CardContent>
      </Drawer>
    </div>
  );
}
