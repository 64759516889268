import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Typography,
  styled,
  useTheme,
  lighten,
  alpha,
  CardHeader,
  Divider,
  Card,
} from "@mui/material";
import { PAGE_LIMIT_15 } from "src/utils/constants";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import EmptyHere from "src/components/EmptyHere";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowForwardIos } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function ExploreEvents() {
  const [loading, setLoading] = useState(true);
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get("/metadata/explore");
        if (resp?.status === 200) {
          setOrganisations(resp?.data?.organisations || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Box>
      {loading ? (
        <SuspenseLoader />
      ) : (
        <>
          <CardHeader
            title="Explore more"
            subheader="Find what happening around the communities you are part of"
            action={
              <Button
                endIcon={<ArrowForwardIos />}
                component={Link}
                to={`/dashboard/customer-relations`}
              >
                more
              </Button>
            }
          />
          <Divider />
          <Swiper
            spaceBetween={50}
            slidesPerView={3.5}
            pagination={{
              clickable: true,
            }}
            style={{
              paddingBlock: 80,
            }}
          >
            {organisations?.map((item, i) => (
              <SwiperSlide key={i}>
                <Card
                  sx={{
                    overflow: "visible",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 120,
                      height: 120,
                      mx: "auto",
                      position: "relative",
                      top: -40,
                      background: "white",
                      border: "1px solid #ccc",
                    }}
                    src={
                      item?.avatar
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          (item?.avatar + "?" + Math.random())
                        : ""
                    }
                  />
                  <Box
                    px={4}
                    textAlign={"center"}
                    component={Link}
                    to={`/links/${item?._id}/org`}
                    style={{ textDecoration: "none" }}
                    sx={{
                      top: -20,
                      position: "relative",
                    }}
                  >
                    <Typography variant="h3">{item?.title || ""}</Typography>
                  </Box>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
          {organisations?.length === 0 && (
            <EmptyHere title={"We didn't find any contributions  of yours"} />
          )}
        </>
      )}
    </Box>
  );
}
