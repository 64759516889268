import {
  Typography,
  Box,
  styled,
  Avatar,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { EmojiEvents, OpenInNew } from "@mui/icons-material";

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${theme.colors.alpha.white[50]};
`
);

export default function CertificationCard({ info }) {
  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <AvatarPageTitle variant="rounded">
              <EmojiEvents color="success" fontSize="large" />
            </AvatarPageTitle>
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {info?.certificate?.title || ""}
              </Typography>
              <Typography variant="subtitle2">
                Congratulations! You have earned a certificate.
              </Typography>
            </Box>
          </Box>
          <Box>
            {info?.certificate?.url && (
              <Button
                size="large"
                color="primary"
                endIcon={<OpenInNew />}
                component="a"
                href={info?.certificate?.url || "#"}
                target="_blank"
                rel="noopener"
              >
                View
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
