import { Box, Typography, alpha } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <Box
      sx={{
        mt: 10,
        p: 4,
        width: "100%",
        textAlign: "center",
        position: "relative",
        bottom: 0,
      }}
    >
      <Typography variant="caption">
        {"<---------- END OF REPORT ---------->"}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          fontStyle: "italic",
          textAlign: "justify",
          textJustify: "inter-word",
        }}
      >
        EthixFirst is an ethics and behavior rating platform for working
        professionals that reduces misconducts and frauds in the Organisations.
        An initiative of Information Sharing and Analysis Center (ISAC), India’s
        leading non-profit cyber security foundation and a public private
        partner with Government of India, EthixFirst was originally designed to
        rate cyber security professionals, ethical hackers under the National
        Security Database program and developers who work on sensitive
        assignments. Organisations who implement the ethics program are able to
        build teams that uphold values, maintain their integrity and conduct
        themselves ethically in most stressful environments.
      </Typography>
    </Box>
  );
}
