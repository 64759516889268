import {
  CloudUploadOutlined,
  NotificationsNoneOutlined,
  PeopleAltOutlined,
  ShieldOutlined,
  StarOutlineRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  Card,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { DO_SPACES_FOLDERS, RuppeeSign } from "src/utils/constants";

const data = [
  {
    title: "Timely Notifications",
    desc: "Receive alerts about your ratings and important career updates.",
    icon: <NotificationsNoneOutlined color="primary" sx={{ fontSize: 28 }} />,
  },
  {
    title: "HR Practices Protection",
    desc: "Stay informed about and protected from unfair HR practices.",
    icon: <ShieldOutlined color="success" sx={{ fontSize: 28 }} />,
  },
  {
    title: "Manager Ratings",
    desc: "Rate your managers and see aggregated feedback from others.",
    icon: <StarOutlineRounded color="warning" sx={{ fontSize: 28 }} />,
  },
  {
    title: "POSH Support",
    desc: "Access resources and support for Prevention of Sexual Harassment.",
    icon: <PeopleAltOutlined color="error" sx={{ fontSize: 28 }} />,
  },
];

function BuyCareerProtection() {
  const {
    currentUser,
    pricingData,
    getTagsByType,
    handlePurchase,
    handleCheckPaymentOnly,
    setPricingData,
  } = useContext(GlobalContext);
  const { organisationId } = useParams();
  const [searchParams] = useSearchParams();
  const invitationMeta = searchParams.get("meta");
  const redirectLink = searchParams.get("redirectLink");
  const [isWait, setIsWait] = useState(false);
  const [organisationData, setOrganisationData] = useState({});
  const [sDocument, setsDocument] = useState(null);
  const [formInput, setFormInput] = useState({});
  const [customerRelations, setCustomerRelations] = useState([]);
  const [actions, setActions] = useState({});

  useEffect(() => {
    if (invitationMeta) {
      const _meta = JSON.parse(invitationMeta || "{}");
      setFormInput({
        organisationName: _meta?.organisationName || "",
        employeeId: _meta?.employeeId || "",
        relation: _meta?.relation || "participant",
        supportingDocumentUrl: _meta?.supportingDocumentUrl || "",
        dateOfJoining: _meta?.dateOfJoining || "",
      });
    }
  }, [invitationMeta]);

  useEffect(() => {
    if (redirectLink) {
      setActions({
        redirectLink: redirectLink,
      });
    }
  }, [redirectLink]);

  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, [pricingData]);

  const handleUploadSupportingDocument = async () => {
    try {
      if (!sDocument) return null;
      const formData = new FormData();
      formData.append("files", sDocument);
      formData.append("fileCategory", DO_SPACES_FOLDERS.DOCUMENTS);
      const resp = await axiosInstance.post("/users/upload-media", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (resp?.status === 200) {
        return resp?.data?.[0];
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  const getOrganisationData = async (organisationId) => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.get(`/organisations/${organisationId}`, {
        params: {
          fields: "title",
        },
      });
      if (resp?.status === 200) {
        setOrganisationData(resp?.data);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleCheckPayment = async () => {
    try {
      setIsWait(true);
      const resp = await handleCheckPaymentOnly(
        {
          item: "joinOrganisations",
          itemId: organisationId || formInput?.organisationName,
        },
        {
          // redirectLink: `/dashboard/career-protection`,
          itemId: organisationId,
          item: "joinOrganisations",
          joinOrgObj: {
            user: currentUser?.userId,
            organisation: organisationId || formInput?.organisationName,
            meta: {
              organisationName: formInput?.organisationName,
              employeeId: formInput?.employeeId,
              dateOfJoining: formInput?.dateOfJoining,
              supportingDocumentUrl: formInput?.supportingDocumentUrl,
              source: "careerProtection",
            },
          },
        }
      );
      if (resp) {
        Notify.success("Payment already made");
        return;
      }
      setIsWait(false);
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleGetPricingData = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.get("/pricing/search", {
        params: {
          category: "joinOrganisations",
        },
      });
      if (resp?.status === 200) {
        setPricingData(resp?.data?.[0]);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (!organisationId || organisationId === "open") {
      handleGetPricingData();
      return;
    }

    getOrganisationData(organisationId);
    setTimeout(() => {
      handleCheckPayment();
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  const handleBuyNow = async () => {
    if (!formInput?.organisationName && !organisationData?.title) {
      Notify.failure("Company name is required");
      return;
    }
    if (pricingData?.category === "joinOrganisations" && !formInput?.relation) {
      Notify.failure("Please select relation");
      return;
    }

    setIsWait(true);
    const _sDocument = await handleUploadSupportingDocument();
    if (_sDocument) {
      setFormInput({
        ...formInput,
        supportingDocumentUrl: _sDocument,
      });
    }

    await handlePurchase(
      {
        // in case of plan
        priceId: pricingData?._id || null,
        // in case of one item purchase
        item: pricingData?.item || pricingData?.category || null,
        itemId: pricingData?.itemId || null,
      },
      {
        redirectLink: actions?.redirectLink || pricingData?.redirectLink,
        joinOrgObj: {
          ...pricingData?.joinOrgObj,
          meta: {
            ...pricingData?.joinOrgObj?.meta,
            organisationName: formInput?.organisationName || "",
            employeeId:
              formInput?.employeeId ||
              pricingData?.joinOrgObj?.meta?.employeeId,
            relation:
              formInput?.relation || pricingData?.joinOrgObj?.meta?.relation,
            supportingDocumentUrl:
              _sDocument ||
              formInput?.supportingDocumentUrl ||
              pricingData?.joinOrgObj?.meta?.supportingDocumentUrl,
            dateOfJoining:
              formInput?.dateOfJoining ||
              pricingData?.joinOrgObj?.meta?.dateOfJoining,
            source: "career-protection-plan",
          },
        },
      }
    );

    setIsWait(false);
  };

  return (
    <Stack spacing={3} my={2}>
      <Typography variant="h1">Career Protection Plan Dashboard</Typography>
      <Card sx={{ p: 2.5 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Your Career Protection Certificate
        </Typography>
        <Stack spacing={3}>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "700",
                mb: 0.5,
              }}
            >
              Company Name
            </Typography>
            <TextField
              value={
                formInput?.organisationName || organisationData?.title || ""
              }
              fullWidth
              size="small"
              setFormInput
              disabled={
                organisationData?.title ||
                JSON.parse(invitationMeta || "{}")?.organisationName
              }
              onChange={(e) =>
                setFormInput({
                  ...formInput,
                  organisationName: e.target.value,
                })
              }
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "700",
                mb: 0.5,
              }}
            >
              Select Relation
            </Typography>
            <Autocomplete
              fullWidth
              size="small"
              options={["employee", ...customerRelations]}
              value={formInput?.relation || ""}
              getOptionLabel={(option) => capitalize(option || "")}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  relation: value || "",
                });
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label=""
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                  <FormHelperText>
                    Send request to join organisation as a?
                  </FormHelperText>
                </>
              )}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "700",
                mb: 0.5,
              }}
            >
              Employee ID or any issued ID
            </Typography>
            <TextField
              value={formInput?.employeeId || ""}
              onChange={(e) =>
                setFormInput({
                  ...formInput,
                  employeeId: e.target.value,
                })
              }
              label=""
              fullWidth
              size="small"
              disabled={pricingData?.joinOrgObj?.disableInputs}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "700",
                mb: 0.5,
              }}
            >
              Joining Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{
                  width: "100%",
                }}
                value={
                  formInput?.dateOfJoining
                    ? new Date(formInput?.dateOfJoining)
                    : null
                }
                onChange={(newValue) => {
                  setFormInput({
                    ...formInput,
                    dateOfJoining: newValue,
                  });
                }}
                format="dd/MM/yyyy"
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "700",
                mb: 0.5,
              }}
            >
              Upload Offer Letter
            </Typography>
            <Button
              startIcon={<CloudUploadOutlined />}
              sx={{ borderRadius: 0.5 }}
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.onchange = async (e) => {
                  setsDocument(e.target.files[0]);
                };
                document.body.appendChild(input);
                input.click();
                document.body.removeChild(input);
              }}
            >
              Select Document
            </Button>
          </Box>
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={1}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography
              sx={{
                fontSize: "Medium",
                fontWeight: "700",
              }}
            >
              Plan Cost:{" "}
            </Typography>
            <Typography color={"green"} fontSize={"1.5rem"} fontWeight={"bold"}>
              {RuppeeSign} {pricingData?.amount || 0}
            </Typography>
          </Stack>
          <Box>
            <LoadingButton
              sx={{ borderRadius: 0.5 }}
              color="primary"
              variant="contained"
              loading={isWait}
              disabled={!pricingData?.amount}
              onClick={handleBuyNow}
            >
              Purchase Plan
            </LoadingButton>
          </Box>
        </Box>
      </Card>
      {data.map((each) => (
        <IconCard Icon={each.icon} title={each.title} desc={each.desc} />
      ))}
      <Card sx={{ p: 2.5 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Your Career Protection Certificate
        </Typography>
        <Typography
          sx={{
            fontSize: "Medium",
            fontWeight: "500",
          }}
        >
          Purchase the Career Protection Plan to receive your certificate.
        </Typography>
      </Card>
    </Stack>
  );
}

export default BuyCareerProtection;

export const IconCard = ({ Icon, title, desc }) => {
  return (
    <Card sx={{ p: 2.5 }}>
      <Box display={"flex"}>
        {Icon}
        <Box ml={1}>
          <Typography variant="h2" mb={1} fontSize={"large"}>
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "small",
              fontWeight: "500",
            }}
          >
            {desc}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
