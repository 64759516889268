import {
  AddTwoTone,
  Check,
  CloudUploadOutlined,
  EmojiEvents,
  RemoveTwoTone,
  Shield,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { DO_SPACES_FOLDERS, DURATION_IN_NUMBERS } from "src/utils/constants";
import { currencyFormatter } from "src/utils/helpers";

const durationArr = [
  { value: "monthly", label: "A Month (30 days)" },
  { value: "quarterly", label: "Quater (3 months)" },
  { value: "halfYearly", label: "Half Year (6 months)" },
  { value: "yearly", label: "A Year" },
];

export default function BuyProductDialog() {
  const navigate = useNavigate();
  const {
    currentUser,
    openPayDialog,
    pricingData,
    handlePurchase,
    payDialogLoading,
    setOpenPayDialog,
    getTagsByType,
  } = useContext(GlobalContext);

  const [isWait, setIsWait] = useState(false);
  const [duration, setDuration] = useState("monthly");
  const [quantity, setQuantity] = useState(1);
  const [sDocument, setsDocument] = useState(null);

  // only for joinOrganisations
  const [formInput, setFormInput] = useState({
    employeeId: pricingData?.joinOrgObj?.meta?.employeeId || "",
    relation: pricingData?.joinOrgObj?.meta?.relation || "participant",
    supportingDocumentUrl:
      pricingData?.joinOrgObj?.meta?.supportingDocumentUrl || "",
  });

  const [customerRelations, setCustomerRelations] = useState([]);
  useEffect(() => {
    if (pricingData?.category === "joinOrganisations") {
      (async () => {
        const crs = await getTagsByType("customerRelations");
        setCustomerRelations(crs);
      })();
      setFormInput({
        employeeId: pricingData?.joinOrgObj?.meta?.employeeId || "",
        relation: pricingData?.joinOrgObj?.meta?.relation || "participant",
        supportingDocumentUrl:
          pricingData?.joinOrgObj?.meta?.supportingDocumentUrl || "",
      });
    }
  }, [pricingData]);

  const handleUploadSupportingDocument = async () => {
    try {
      if (!sDocument) return null;
      const formData = new FormData();
      formData.append("files", sDocument);
      formData.append("fileCategory", DO_SPACES_FOLDERS.DOCUMENTS);
      const resp = await axiosInstance.post("/users/upload-media", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (resp?.status === 200) {
        return resp?.data?.[0];
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return null;
    }
  };

  const handleBuyNow = async () => {
    if (pricingData?.category === "joinOrganisations" && !formInput?.relation) {
      Notify.failure("Please select relation");
      return;
    }

    setIsWait(true);
    const _sDocument = await handleUploadSupportingDocument();
    if (_sDocument) {
      setFormInput({
        ...formInput,
        supportingDocumentUrl: _sDocument,
      });
    }

    await handlePurchase(
      {
        // in case of plan
        priceId: pricingData?._id || null,
        // in case of one item purchase
        item: pricingData?.item || null,
        itemId: pricingData?.itemId || null,
        duration: duration,
        quantity: Number(quantity || 1),
      },
      {
        redirectLink: pricingData?.redirectLink,
        joinOrgObj: {
          ...pricingData?.joinOrgObj,
          meta: {
            ...pricingData?.joinOrgObj?.meta,
            employeeId:
              formInput?.employeeId ||
              pricingData?.joinOrgObj?.meta?.employeeId,
            relation:
              formInput?.relation || pricingData?.joinOrgObj?.meta?.relation,
            supportingDocumentUrl:
              _sDocument ||
              formInput?.supportingDocumentUrl ||
              pricingData?.joinOrgObj?.meta?.supportingDocumentUrl,
          },
        },
      }
    );

    setIsWait(false);
  };

  const handleJoinOrganisationWithoutPayment = async () => {
    try {
      if (
        pricingData?.category === "joinOrganisations" &&
        !formInput?.relation
      ) {
        Notify.failure("Please select relation");
        return;
      }

      setIsWait(true);
      const _sDocument = await handleUploadSupportingDocument();
      if (_sDocument) {
        setFormInput({
          ...formInput,
          supportingDocumentUrl: _sDocument,
        });
      }

      const { joinOrgObj } = pricingData;
      joinOrgObj.meta = joinOrgObj?.meta || {};
      joinOrgObj.meta.employeeId =
        formInput?.employeeId || pricingData?.joinOrgObj?.meta?.employeeId;
      joinOrgObj.meta.relation =
        formInput?.relation || pricingData?.joinOrgObj?.meta?.relation;
      joinOrgObj.meta.supportingDocumentUrl =
        _sDocument ||
        formInput?.supportingDocumentUrl ||
        pricingData?.joinOrgObj?.meta?.supportingDocumentUrl;

      const resp = await axiosInstance.post(`/invitations/`, joinOrgObj);
      if (resp?.status === 201) {
        setFormInput({});
        setsDocument(null);
        Notify.success("Invitation sent successfully");
        setOpenPayDialog(false);
        navigate(pricingData?.redirectLink || "/dashboard/requests");
      }
      setIsWait(false);
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return !openPayDialog ? null : (
    <>
      <Backdrop open={payDialogLoading} sx={{ zIndex: 100 }}>
        <CircularProgress color="info" />
      </Backdrop>
      {openPayDialog && (
        <Dialog
          open={openPayDialog}
          onClose={() => {
            setOpenPayDialog(false);
            setFormInput({});
          }}
          maxWidth={pricingData?.type ? "md" : "sm"}
          fullWidth
        >
          <DialogContent>
            <Stack spacing={2.5}>
              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={1}>
                  <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
                    {pricingData?.title || ""}
                  </Typography>
                </Stack>
                {pricingData?.type === "forms" && (
                  <Stack alignItems={"flex-end"}>
                    <Typography variant="h3" color={"text.secondary"}>
                      {currencyFormatter(pricingData?.amount || 0)}
                    </Typography>

                    <Typography variant="subtitle1">
                      per user
                      {pricingData?.duration === "lifetime"
                        ? ""
                        : pricingData?.duration === "monthly"
                        ? "/month"
                        : "/year"}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Divider />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack direction="row" alignItems={"center"} spacing={2}>
                  {pricingData?.type === "forms" && (
                    <>
                      <ButtonGroup
                        variant="contained"
                        sx={{ width: 180 }}
                        size="small"
                      >
                        <Button
                          color="secondary"
                          onClick={() => {
                            if (quantity === 1) return;
                            setQuantity(quantity - 1);
                          }}
                        >
                          <RemoveTwoTone />
                        </Button>{" "}
                        <TextField
                          name="amount"
                          variant="standard"
                          sx={{
                            "& input": {
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "1.5rem",
                              padding: "0.2rem",
                            },
                            border: "1px solid #ccc",
                          }}
                          value={quantity}
                          onChange={(e) => {
                            setQuantity(
                              Number(e.target.value) > 0
                                ? Number(e.target.value)
                                : 1
                            );
                          }}
                        />
                        <Button
                          color="secondary"
                          onClick={() => {
                            setQuantity(quantity + 1);
                          }}
                        >
                          <AddTwoTone />
                        </Button>{" "}
                      </ButtonGroup>
                      <Typography variant="h6"> x </Typography>
                      <Select
                        sx={{ width: 220 }}
                        size="small"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                      >
                        {durationArr?.map((item) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}

                  {pricingData?.category === "joinOrganisations" &&
                    pricingData?.joinOrgObj?.showInputs && (
                      <Stack spacing={2}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          options={["employee", ...customerRelations]}
                          value={formInput?.relation || ""}
                          getOptionLabel={(option) => capitalize(option || "")}
                          onChange={(e, value) => {
                            setFormInput({
                              ...formInput,
                              relation: value || "",
                            });
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Select Relation"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                              <FormHelperText>
                                Send request to join organisation as a?
                              </FormHelperText>
                            </>
                          )}
                          disabled={pricingData?.joinOrgObj?.disableInputs}
                        />
                        <TextField
                          value={
                            formInput?.employeeId ||
                            pricingData?.joinOrgObj?.meta?.employeeId ||
                            ""
                          }
                          onChange={(e) =>
                            setFormInput({
                              ...formInput,
                              employeeId: e.target.value,
                            })
                          }
                          label="Employee ID or any issued ID"
                          fullWidth
                          size="small"
                          disabled={pricingData?.joinOrgObj?.disableInputs}
                        />
                        {/* {!pricingData?.joinOrgObj?.disableInputs && ( */}
                        <>
                          <Button
                            startIcon={<CloudUploadOutlined />}
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              const input = document.createElement("input");
                              input.type = "file";
                              input.onchange = async (e) => {
                                setsDocument(e.target.files[0]);
                              };
                              document.body.appendChild(input);
                              input.click();
                              document.body.removeChild(input);
                            }}
                          >
                            Upload supporting document
                          </Button>
                          {sDocument && sDocument?.name && (
                            <Typography variant="subtitle1" sx={{ mt: 1 }}>
                              {sDocument.name}
                            </Typography>
                          )}
                        </>
                        {/* )} */}
                      </Stack>
                    )}
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  color={"success.main"}
                  ml={2}
                >
                  <Typography variant="h2" whiteSpace={"nowrap"}>
                    {currencyFormatter(
                      pricingData?.amount *
                        quantity *
                        DURATION_IN_NUMBERS[duration]?.value || 0
                    )}
                  </Typography>
                </Stack>
              </Stack>
              {pricingData?.features?.length > 0 && (
                <Stack spacing={2} sx={{ pt: 3 }}>
                  {pricingData?.features?.map((feat) => (
                    <Stack
                      key={feat}
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      sx={{
                        typography: "body2",
                      }}
                    >
                      <Check width={16} color="success" sx={{ mr: 1 }} />
                      {feat}
                    </Stack>
                  ))}
                </Stack>
              )}
            </Stack>

            {pricingData?.category === "joinOrganisations" && (
              <Alert
                severity="primary"
                sx={{ my: 2 }}
                icon={<EmojiEvents color="success" fontSize="large" />}
              >
                <Typography variant="h6">
                  Get Career Protection certificate after request get accepted
                  by the Organisation.
                </Typography>
              </Alert>
            )}
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              sx={{ mt: 5, mb: 1 }}
              onClick={handleBuyNow}
              loading={isWait}
              color="success"
            >
              {pricingData?.category === "joinOrganisations"
                ? "Buy Protection Plan"
                : "Pay Now"}
            </LoadingButton>
            {pricingData?.category === "joinOrganisations" &&
              !pricingData?.joinOrgObj?.disableInputs &&
              !pricingData?.joinOrgObj?.hideFreeButton && (
                <LoadingButton
                  fullWidth
                  size="large"
                  variant={"outlined"}
                  color={"secondary"}
                  sx={{ my: 2 }}
                  loading={isWait}
                  onClick={() => {
                    handleJoinOrganisationWithoutPayment();
                  }}
                >
                  Continue free
                </LoadingButton>
              )}

            {currentUser?.role !== "user" && (
              <Button
                fullWidth
                size="large"
                color="warning"
                variant="outlined"
                sx={{ mb: 3 }}
                component="a"
                href={"/pricing"}
                target="_blank"
              >
                View Plans
              </Button>
            )}

            <Stack alignItems="center" spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Shield color="success" />
                <Typography variant="subtitle2">
                  Secure credit card payment
                </Typography>
              </Stack>

              <Typography
                variant="caption"
                sx={{ color: "text.disabled", textAlign: "center" }}
              >
                This is a secure 128-bit SSL encrypted payment
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
