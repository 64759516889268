import {
  Box,
  Button,
  Card,
  CardHeader,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { EditTwoTone } from "@mui/icons-material";
import { fDateTimeSuffix } from "src/utils/date-fns";
import GroupMembers from "./components/GroupMembers";
import GroupPermissions from "./components/GroupPermissions";
import GroupOrgs from "./components/GroupOrgs";

export default function GroupInfo() {
  const { groupId, type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");
  const [tab, setTab] = useState(tabParam || "permissions");
  const [groupInfo, setGroupInfo] = useState(null);

  useEffect(() => {
    if (tabParam) {
      setTab(tabParam || "permissions");
    }
  }, [tabParam]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await axiosInstance.get(`/groups/${groupId}`);
        if (resp?.status === 200) {
          setGroupInfo(resp?.data);
        }
      } catch (error) {}
    })();
  }, [groupId]);

  return (
    <Box my={2}>
      <Card>
        <CardHeader
          title={
            <h2
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              {groupInfo?.title}
            </h2>
          }
          subheader={
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" color="text.secondary">
                Group ID: {groupInfo?.groupId}
              </Typography>
              <Typography>•</Typography>
              {groupInfo?.type === "members" && (
                <>
                  <Typography variant="body2" color="text.secondary">
                    {groupInfo?.organisation?.title || "N/A"}
                  </Typography>
                  <Typography>•</Typography>
                </>
              )}
              <Typography variant="body2" color="text.secondary">
                {groupInfo?.createdBy
                  ? groupInfo?.createdBy?.firstName +
                    " " +
                    groupInfo?.createdBy?.lastName
                  : "N/A"}
              </Typography>
              <Typography>•</Typography>
              <Typography variant="body2" color="text.secondary">
                {fDateTimeSuffix(groupInfo?.createdAt)}
              </Typography>
            </Stack>
          }
          action={
            <Button
              variant="text"
              color="primary"
              startIcon={<EditTwoTone />}
              component={Link}
              to={`/dashboard/groups/g/${groupId}/edit`}
            >
              Edit
            </Button>
          }
        />
      </Card>

      <Box sx={{ mx: 2, mt: 4 }}>
        <Tabs
          aria-label="basic tabs example"
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue);
            setSearchParams({ tab: newValue, type: type });
          }}
        >
          <Tab label="Permissions" value={"permissions"} />
          {type === "members" && <Tab label="Members" value={"members"} />}
          {type === "organisations" && (
            <Tab label="Organisations" value={"organisations"} />
          )}
        </Tabs>
      </Box>
      <Card>
        {tab === "permissions" && (
          <GroupPermissions groupInfo={groupInfo} groupId={groupId} />
        )}
        {tab === "members" && (
          <GroupMembers groupInfo={groupInfo} setGroupInfo={setGroupInfo} />
        )}

        {tab === "organisations" && (
          <GroupOrgs groupInfo={groupInfo} setGroupInfo={setGroupInfo} />
        )}
      </Card>
    </Box>
  );
}
