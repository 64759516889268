import BaseLayout from "src/layouts/BaseLayout";
import authRoutes from "./auth";
import baseRoutes from "./base";
import dashboardsRoutes from "./dashboards";
import formRoutes from "./forms";
import BoxedSidebarLayout from "src/layouts/BoxedSidebarLayout";
import examRoutes from "./exam";

const router = [
  {
    path: "auth",
    children: authRoutes,
  },
  {
    path: "*",
    element: <BaseLayout />,
    children: baseRoutes,
  },
  {
    path: "dashboard",
    element: <BoxedSidebarLayout />,
    children: dashboardsRoutes,
  },
  {
    path: "forms",
    children: formRoutes,
  },
  {
    path: "exams",
    children: examRoutes,
  },
];

export default router;
