import { ExpandMoreTwoTone, QuizTwoTone } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useTranslation } from "react-i18next";
import EmptyHere from "src/components/EmptyHere";
import { FormContext } from "src/contexts/FormContext";
import UpdateFormGroupDialog from "./UpdateFormGroupDialog";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
  padding: 8, //grid,
  // width: 250
});

export default function GroupInfo({ children }) {
  const { t } = useTranslation();
  const {
    form,
    handleGetForm,
    selectedFormGroup,
    setSelectedFormGroup,
    setSelectedQuestionGroup,
    setSelectedQuestion,
    setSelectedOption,
    setCurrentSelectedItemType,
  } = useContext(FormContext);

  const [formGroups, setFormGroups] = useState([]);

  useEffect(() => {
    if (form) {
      setFormGroups(form.formGroups);
    }
  }, [form]);

  const handleDimensionOrder = async (updatedOrder) => {
    try {
      // Assume items is an array containing the modified formInput objects
      const updatePromises = updatedOrder.map(async (item) => {
        const resp = await axiosInstance.put(
          `/forms/${form?._id}/${item._id}`,
          item
        );
        if (resp?.status !== 200) {
          throw new Error(
            resp?.data?.message ||
              resp?.statusText ||
              "Failed to update question"
          );
        }
      });

      await Promise.all(updatePromises);
      await handleGetForm(form?._id);
      Notify.success("Successfully");
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formGroups,
      result.source.index,
      result.destination.index
    );

    const updatedOrder = items.map((item, index) => {
      return {
        ...item,
        position: index,
      };
    });
    handleDimensionOrder(updatedOrder);
  };

  return (
    <>
      {formGroups?.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {formGroups
                  ?.sort(function (a, b) {
                    return a.position - b.position;
                  })
                  ?.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div>
                          <Accordion
                            aria-labelledby={"Accordion" + index}
                            id={"Accordion-id" + index}
                            expanded={selectedFormGroup?._id === item._id}
                            onClick={() => {
                              if (selectedFormGroup?._id === item._id) return;
                              setSelectedFormGroup({
                                ...item,
                                itemType: "form-group",
                              });
                              setSelectedQuestionGroup(null);
                              setSelectedQuestion(null);
                              setSelectedOption(null);
                              setCurrentSelectedItemType(null);
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <AccordionSummary
                              aria-controls={"AccordionSummary" + index}
                              id={"AccordionSummary-id" + index}
                              {...provided.dragHandleProps}
                              expandIcon={
                                <ExpandMoreTwoTone
                                  onClick={() => {
                                    if (selectedFormGroup?._id === item._id) {
                                      setSelectedFormGroup(null);
                                    }
                                  }}
                                />
                              }
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                width="100%"
                              >
                                <Typography
                                  variant="h4"
                                  sx={{
                                    position: "relative",
                                    width: "60%",
                                  }}
                                >
                                  {item?.title}
                                </Typography>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    component={"span"}
                                    variant="subtitle"
                                    color="text.secondary"
                                  >
                                    Parameters:{" "}
                                    {item?.questionsGroup?.length || 0}
                                  </Typography>
                                  <Typography>•</Typography>
                                  <Typography
                                    component={"span"}
                                    variant="subtitle"
                                    color="text.secondary"
                                  >
                                    Total Questions:{" "}
                                    {item?.questionsGroup?.reduce(
                                      (acc, curr) =>
                                        acc + curr.questions.length,
                                      0
                                    ) || 0}
                                  </Typography>{" "}
                                  {(() => {
                                    const weightage =
                                      item?.questionsGroup
                                        ?.flatMap((qGroup) =>
                                          qGroup.questions?.flatMap(
                                            (question) => {
                                              if (
                                                question.type === "radio" ||
                                                question.type === "select" ||
                                                question.type === "rating"
                                              ) {
                                                return Math.max(
                                                  ...question.options?.map(
                                                    (option) => option.weightage
                                                  )
                                                );
                                              } else if (
                                                question.type === "checkbox"
                                              ) {
                                                return question.options?.reduce(
                                                  (totalWeightage, option) =>
                                                    totalWeightage +
                                                    option.weightage,
                                                  0
                                                );
                                              } else {
                                                return question.weightage || 0;
                                              }
                                            }
                                          )
                                        )
                                        .reduce(
                                          (total, weightage) =>
                                            total + weightage,
                                          0
                                        ) || 0;

                                    if (!weightage || weightage === 0)
                                      return null;
                                    return (
                                      <>
                                        <Typography>•</Typography>
                                        <Typography
                                          component={"span"}
                                          variant="subtitle"
                                          color="text.secondary"
                                        >
                                          Weightage: {weightage}
                                        </Typography>
                                      </>
                                    );
                                  })()}
                                </Stack>
                                <Box></Box>
                              </Stack>
                              <UpdateFormGroupDialog />
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                backgroundColor: (theme) =>
                                  theme.palette.background.paper,
                                padding: "0px",
                                margin: "0px",
                              }}
                            >
                              {/* <Accordion
              aria-labelledby={"Accordion-paramter"}
              id={"Accordion-id-paramter"}
              sx={{backgroundColor: 'rgb(240, 235, 248)'}}
            >
<AccordionSummary
                aria-controls={"AccordionSummary-paramter"}
                id={"AccordionSummary-id-paramter"}
                
                expandIcon={
                  <ExpandMoreTwoTone />
                }
              >
<Stack direction="row" alignItems="center" spacing={1}>
          <QuizTwoTone />
          <Typography variant="h4">{t("Parameters")}</Typography>
        </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  padding: "0px",
                  margin: "0px",
                }}
              > */}
                              <Divider />
                              {children}
                              {/* </AccordionDetails>
            </Accordion> */}
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <EmptyHere title={t("No Dimensions yet")} />
      )}
    </>
  );
}
