import {
  AddCircleOutlineTwoTone,
  CloudUploadOutlined,
  EditTwoTone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";
import { DO_SPACES_FOLDERS } from "src/utils/constants";

export default function EditCertificateDialog({ portfolioItem }) {
  const inputFile = useRef(null);
  const { handleGetPortfolio } = useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [certificateImage, setCertificateImage] = useState(null);

  useEffect(() => {
    setCertificateImage(null);
    if (portfolioItem) {
      portfolioItem.issue_date = new Date(portfolioItem.issue_date);
    }
    setFormInput(portfolioItem || {});
  }, [portfolioItem]);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsWait(false);
    if (!portfolioItem) setFormInput({});
  };

  const handleSubmit = async () => {
    try {
      if (!formInput?.title) throw new Error("Certificate title is required");
      if (!formInput?.issued_by)
        throw new Error("Issued by (Organisation Name) is required");
      if (!formInput?.issue_date) throw new Error("Issue date is required");
      if (formInput?.issue_date > new Date())
        throw new Error("Issue date cannot be in the future");
      if (
        !formInput?.certificate_link &&
        !certificateImage &&
        !formInput?.certificate_url
      )
        throw new Error(
          "Please provide either certificate url or certificate image"
        );
      setIsWait(true);

      if (certificateImage) {
        const formData = new FormData();
        formData.append("files", certificateImage);
        formData.append("fileCategory", DO_SPACES_FOLDERS.USERS);
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp.status === 200 && imageResp.data) {
          formInput.certificate_url = imageResp?.data?.[0];
          setFormInput((prev) => ({
            ...prev,
            certificate_url: imageResp?.data?.[0],
          }));
        }
      }

      formInput.category = "certificates";
      if (formInput?._id) {
        const resp = await axiosInstance.put(
          `/portfolios/${formInput?._id}`,
          formInput
        );
        if (resp?.status === 200) {
          await handleGetPortfolio(resp?.data?.user);
        }
      } else {
        const resp = await axiosInstance.post("/portfolios", formInput);
        if (resp?.status === 201) {
          setFormInput({});
          await handleGetPortfolio({
            _id: resp?.data?.user,
          });
        }
      }

      setIsWait(false);
      Notify.success("Successful");
      handleClose();
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        {portfolioItem?._id ? <EditTwoTone /> : <AddCircleOutlineTwoTone />}
      </IconButton>

      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
        <DialogTitle>
          Fill out the form below to {formInput?._id ? "edit" : "add"} project
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <TextField
              label="Title *"
              placeholder="Enter certificate title"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                label="Issued by *"
                placeholder="Enter organisation name"
                fullWidth
                name="issued_by"
                value={formInput?.issued_by || ""}
                onChange={handleInputChange}
              />
              <TextField
                label="Issuer's Website"
                placeholder="Enter issuer's website (if any)"
                fullWidth
                name="issuer_website"
                value={formInput?.issuer_website || ""}
                onChange={handleInputChange}
              />
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name={"issue_date"}
                label="Issue Date *"
                value={formInput?.issue_date}
                onChange={(newValue) => {
                  setFormInput({ ...formInput, issue_date: newValue });
                }}
                format="dd/MM/yyyy"
                maxDate={new Date()}
              />
            </LocalizationProvider>
            <TextField
              label=" Description"
              placeholder="e.g. I was awarded this certificate for..."
              fullWidth
              name="description"
              value={formInput?.description || ""}
              onChange={handleInputChange}
              multiline
              rows={4}
            />

            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
            >
              <Typography variant="subtitle2">
                <b>Upload Certificate</b>
              </Typography>
              <Typography variant="subtitle1">
                Supported formats: .jpg, .jpeg, .png or .pdf. Max file size 5MB
              </Typography>
            </Stack>
            <Button
              sx={{
                color: "primary.main",
                border: "1px dashed",
                borderColor: "primary.main",
                "&:hover": {
                  color: "primary.dark",
                  borderColor: "primary.dark",
                },
              }}
              onClick={() => inputFile.current.click()}
              startIcon={<CloudUploadOutlined />}
            >
              Upload Certificate
              <input
                type="file"
                onChange={(e) => {
                  setCertificateImage(e.target.files[0]);
                  setFormInput({
                    ...formInput,
                    certificate_link: null,
                    // certificate_url: null,
                  });
                }}
                multiple={false}
                style={{ display: "none" }}
                ref={inputFile}
                accept=".jpg,.jpeg,.png,.pdf"
              />
            </Button>

            {certificateImage?.name || formInput?.certificate_url ? (
              <Typography
                sx={{
                  ml: 1,
                }}
                variant="subtitle2"
              >
                {certificateImage?.name ||
                  formInput?.certificate_url?.split("/")?.pop() ||
                  ""}
              </Typography>
            ) : null}

            <Divider>Or</Divider>
            <TextField
              label="Certificate Link"
              placeholder="Enter link to certificate (if any)"
              fullWidth
              name="certificate_link"
              value={formInput?.certificate_link || ""}
              onChange={(e) => {
                setFormInput({
                  ...formInput,
                  certificate_url: null,
                  certificate_link: e.target.value,
                });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isWait}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={isWait}
            variant="contained"
            color="primary"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
