import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Collapse,
  IconButton,
  InputBase,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  capitalize,
  useTheme,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { ORDER_STATUS, PAGE_LIMIT } from "src/utils/constants";
import {
  ArrowForwardIos,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ReceiptOutlined,
  Search,
  VerifiedUser,
} from "@mui/icons-material";
import Label from "src/components/Label";
import { GlobalContext } from "src/contexts/GlobalContext";
import { fDate, fDateTimeSuffix } from "src/utils/date-fns";
import OrderStats from "./components/OrderStats";
import { splitCamelCaseToWords } from "src/utils/helpers";

export default function Orders({ organisationId }) {
  const theme = useTheme();
  const [orders, setOrders] = useState([]);
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const statusParam = searchParams.get("status");
  const itemTypeParam = searchParams.get("item");
  const [filterObj, setFilterObj] = useState({
    status: statusParam,
    item: itemTypeParam,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [organisations, setOrganisations] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    if (statusParam) {
      setFilterObj({ ...filterObj, status: statusParam });
    } else {
      // delete status from search params
      searchParams.delete("status");
      setSearchParams(searchParams);
      delete filterObj.status;
      setFilterObj({ ...filterObj });
    }

    if (itemTypeParam) {
      setFilterObj({ ...filterObj, item: itemTypeParam });
    } else {
      // delete item from search params
      searchParams.delete("item");
      setSearchParams(searchParams);
      delete filterObj.item;
      setFilterObj({ ...filterObj });
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      if (organisationId || currentUser?.accountType !== "admin") return;
      const resp = await axiosInstance.get(`/organisations/all`, {
        params: {
          isClaimed: true,
        },
      });
      if (resp?.status === 200) {
        setOrganisations(resp?.data);
      }
    })();
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }

      if (organisationId) {
        reqParams.params.organisation = organisationId;
      }

      if (filterObj?.item === "plan") {
        reqParams.params.type = "plan";
        delete reqParams.params.item;
      }

      const resp = await axiosInstance.get("/orders", reqParams);
      if (resp?.status === 200) {
        setOrders(resp?.data);
      }
      setLoading(false);
    })();
  }, [page, filterObj]);

  const orderTypeFilters = [
    {
      label: "Pricing Plan",
      value: "plan",
    },
    {
      label: "Rating Forms",
      value: "forms",
    },
    {
      label: "Exams",
      value: "exams",
    },
    {
      label: "Acc. Verifications",
      value: "verifications",
    },
    {
      label: "Career Plan",
      value: "joinOrganisations",
    },
  ];

  const OrderRow = ({ row }) => {
    const [open, setOpen] = useState(false);
    return (
      <Fragment>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell sx={{ maxWidth: "300px" }}>
            <b>{row?.title || "-"}</b>
            <Typography color="text.secondary">
              <small>{row?.invoiceId?.toUpperCase() || "-"}</small>
            </Typography>
          </TableCell>
          <TableCell>
            {row?.user?.firstName || "-"} {row?.user?.lastName || ""}
            <Typography color="text.secondary">
              <small>
                {row?.organisation?.title || row?.meta?.organisationName || "-"}
              </small>
            </Typography>
          </TableCell>
          <TableCell align="center">{row?.quantity || "-"}</TableCell>
          <TableCell whiteSpace={"nowrap"}>
            {row?.amountPaid > 0 && (
              <b style={{ color: "green" }}>Rs. {row?.amountPaid || "0"}.00</b>
            )}
            {row?.amountToPay !== row?.amountPaid && (
              <sub style={{ marginLeft: "5px" }}>
                Rs. {row?.amountToPay || "0"}.00
              </sub>
            )}
          </TableCell>
          <TableCell>
            {row?.duration === "lifetime"
              ? "-"
              : capitalize(row?.duration || "-")}
          </TableCell>
          <TableCell>
            <Label color={ORDER_STATUS[row?.status]?.color}>
              <b>{ORDER_STATUS[row?.status]?.label}</b>
            </Label>
          </TableCell>
          <TableCell>
            {fDateTimeSuffix(row?.createdAt)}
            <Typography color="text.secondary">
              <small>
                {row?.expiresAt ? fDateTimeSuffix(row?.expiresAt) : "-"}
              </small>
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Stack direction={"row"} spacing={2} justifyContent="flex-end">
              {row?.certificate?.url && (
                <Tooltip
                  title={
                    "view Career Protection certificate" +
                    (new Date(row?.expiresAt) > new Date() ? "" : " (Expired)")
                  }
                >
                  <IconButton
                    component={"a"}
                    href={row?.certificate?.url || "#"}
                    target="_blank"
                    rel="noreferrer"
                    color={
                      new Date(row?.expiresAt) > new Date()
                        ? "success"
                        : "error"
                    }
                  >
                    <VerifiedUser />
                  </IconButton>
                </Tooltip>
              )}
              <Button
                size="small"
                variant="outlined"
                component={Link}
                to={`/dashboard/orders/invoices/${row?.invoiceId}`}
                startIcon={<ReceiptOutlined />}
              >
                Invoice
              </Button>
              {currentUser?.role !== "user" &&
                row?.status === "completed" &&
                (row?.item === "forms" || row?.type === "plan") && (
                  <IconButton
                    component={Link}
                    to={`/dashboard/orders/${row?._id}`}
                  >
                    <ArrowForwardIos fontSize="small" />
                  </IconButton>
                )}
              {row?.item === "joinOrganisations" &&
                !row?.organisation &&
                Object.keys(row?.meta || {})?.length > 0 && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                )}
            </Stack>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ pb: 0, pt: 0 }} colSpan={8}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{
                m: 1,
              }}
            >
              {Object.keys(row?.meta || {})?.length > 0 && (
                <List
                  dense
                  component={Paper}
                  sx={{
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  {Object.entries(row?.meta || {}).map(([key, value]) => {
                    const itemsToShow = [
                      "employeeId",
                      "organisationName",
                      "dateOfJoining",
                      "supportingDocumentUrl",
                    ];
                    if (!itemsToShow.includes(key)) return null;

                    if (!value) return null;
                    if (typeof value !== "string") return null;
                    return (
                      <ListItem key={key}>
                        <ListItemText
                          primaryTypographyProps={{
                            variant: "h6",
                          }}
                          primary={splitCamelCaseToWords(key || "")}
                        />
                        <Typography>
                          <b>
                            {key === "supportingDocumentUrl" ? (
                              <a
                                href={
                                  process.env.REACT_APP_DO_SPACE_URL + value
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                View Document
                              </a>
                            ) : key === "dateOfJoining" ? (
                              fDate(value)
                            ) : (
                              value || "-"
                            )}
                          </b>
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (e.target.value.length) {
                  setFilterObj({ ...filterObj, search: searchString });
                } else {
                  setFilterObj({ ...filterObj, search: null });
                }
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              if (e.target.value?.length > 0) {
                setSearchString(e.target.value);
              } else {
                setFilterObj({
                  ...filterObj,
                  search: null,
                });
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              p: "10px",
              m: 0.2,
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              setFilterObj({
                ...filterObj,
                search: searchString,
              });
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          >
            <Search />
          </IconButton>
        </Paper>
        <Stack direction="row" alignItems="center" spacing={2}>
          {!organisationId && currentUser?.accountType === "admin" && (
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={organisations || []}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Organisation" />
              )}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  organisation: v?._id,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            />
          )}
          <Autocomplete
            disablePortal
            size="small"
            id="org-box"
            options={Object.keys(ORDER_STATUS) || []}
            getOptionLabel={(option) => capitalize(option || "")}
            sx={{ width: 250, mr: 2 }}
            value={filterObj?.status || ""}
            renderInput={(params) => <TextField {...params} label="Status" />}
            onChange={(e, v) => {
              setFilterObj({ ...filterObj, status: v });
              if (v) {
                searchParams.set("status", v);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("status");
                setSearchParams(searchParams);
              }
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          />
          <Autocomplete
            disablePortal
            size="small"
            options={orderTypeFilters || []}
            getOptionLabel={(option) => option?.label}
            sx={{ width: 250, mr: 2 }}
            value={
              orderTypeFilters.find((o) => o.value === filterObj?.item) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Order Type" />
            )}
            onChange={(e, v) => {
              setFilterObj({ ...filterObj, item: v?.value });
              if (v) {
                searchParams.set("item", v?.value);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("item");
                setSearchParams(searchParams);
              }
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          />
          {["owner", "moderator"].includes(currentUser?.role) && (
            <Button
              endIcon={<ArrowForwardIos />}
              component={Link}
              to="/pricing"
            >
              View Plans
            </Button>
          )}
        </Stack>
      </Stack>
      {currentUser?.role !== "user" && <OrderStats stats={orders?.stats} />}
      {currentUser?.accountType === "admin" && (
        <Alert severity="warning" sx={{ my: 2 }}>
          <Typography variant="body2">
            Note: All <b>PENDING</b> orders will be automatically marked as{" "}
            <b>CANCELLED</b> after 24 hours.
          </Typography>
        </Alert>
      )}
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Title
                  <Typography color="text.secondary">
                    <small>Order ID</small>
                  </Typography>
                </TableCell>
                <TableCell>
                  Customer
                  <Typography color="text.secondary">
                    <small>Organisation</small>
                  </Typography>
                </TableCell>
                <TableCell>Qty/Seats</TableCell>
                <TableCell>
                  Amount Paid{" "}
                  <sub>
                    <small>(To Pay)</small>
                  </sub>
                </TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>
                  Order Date
                  <Typography color="text.secondary">
                    <small>Expires On</small>
                  </Typography>
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders?.data?.map((row, idx) => (
                <OrderRow key={idx} row={row} />
              ))}

              {orders?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Order Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && orders?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={orders?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
