import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import {
  ApartmentTwoTone,
  FmdGood,
  GitHub,
  LinkedIn,
  Public,
  School,
  Twitter,
} from "@mui/icons-material";
import { ProfileContext } from "src/contexts/ProfileContext";
import { GlobalContext } from "src/contexts/GlobalContext";

export function AboutSection() {
  const { profile, portfolio, viewAsPublic } = useContext(ProfileContext);
  const { currentUser } = useContext(GlobalContext);
  return (
    <Card>
      <CardHeader title="About" />

      <CardContent>
        <Stack spacing={2}>
          <Box sx={{ typography: "body2" }}>
            {profile?.meta?.about
              ? profile?.meta?.about
              : "Write something about yourself, so that others can know you better..."}
          </Box>

          {(() => {
            const portfolioItem = portfolio?.find(
              (portfolioItem) =>
                portfolioItem?.category === "experience" &&
                portfolioItem?.highlighted
            );
            if (currentUser?.userId !== profile?._id && !portfolioItem)
              return null;
            return (
              <Stack direction={"row"} spacing={1}>
                <ApartmentTwoTone />
                {portfolioItem ? (
                  <Typography>
                    <b>{portfolioItem?.title}</b>
                    {portfolioItem && portfolioItem?.organisation?.title && (
                      <>
                        {" "}
                        at <b>{portfolioItem?.organisation?.title || ""}</b>
                      </>
                    )}
                  </Typography>
                ) : (
                  <Typography color="text.secondary">
                    Add Current Work Experience
                  </Typography>
                )}
              </Stack>
            );
          })()}

          {(() => {
            const portfolioItem = portfolio?.find(
              (portfolioItem) =>
                portfolioItem?.category === "education" &&
                portfolioItem?.highlighted
            );
            if (currentUser?.userId !== profile?._id && !portfolioItem)
              return null;
            return (
              <Stack direction={"row"} spacing={1}>
                <School />
                {portfolioItem ? (
                  <Typography>
                    Studied at <b>{portfolioItem?.title}</b>
                  </Typography>
                ) : (
                  <Typography color="text.secondary">
                    Add Your highest Education
                  </Typography>
                )}
              </Stack>
            );
          })()}
          {[
            {
              label: "City",
              value: `${profile?.address?.city || ""}`,
              icon: <FmdGood />,
              link: "https://www.google.com/maps/place/",
            },
          ]?.map((item, index) => {
            if (
              (!item?.value && viewAsPublic) ||
              (!viewAsPublic &&
                !item?.value &&
                currentUser?.userId !== profile?._id)
            )
              return null;
            return (
              <Stack direction={"row"} spacing={1} key={index}>
                {item?.icon}
                <Typography
                  component={item?.value ? "a" : "span"}
                  href={item?.link + item?.value}
                  variant="body2"
                  target="_blank"
                  rel="noopener noreferrer"
                  color={item?.value ? "inherit" : "text.secondary"}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {item?.value
                    ? item?.value?.replace(/(^\w+:|^)\/\//, "")
                    : "Add " + item?.label}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
}
export function SocialSection() {
  const { profile, viewAsPublic } = useContext(ProfileContext);
  const { currentUser } = useContext(GlobalContext);

  // if no social links are present, don't show this section
  // if (
  //   !profile?.meta?.website &&
  //   !profile?.meta?.linkedin &&
  //   !profile?.meta?.github &&
  //   !profile?.meta?.twitter
  // )
  //   return null;

  return (
    <Card>
      <CardHeader title="Social" />

      <CardContent>
        <Stack spacing={2}>
          {[
            {
              label: "Website",
              value: profile?.meta?.website,
              icon: <Public />,
              link: "",
            },
            {
              label: "LinkedIn",
              value: profile?.meta?.linkedin,
              icon: <LinkedIn />,
              link: "https://linkedin.com/",
            },
            {
              label: "GitHub",
              value: profile?.meta?.github,
              icon: <GitHub />,
              link: "https://github.com/",
            },
            {
              label: "Twitter",
              value: profile?.meta?.twitter,
              icon: <Twitter />,
              link: "https://twitter.com/",
            },
          ]?.map((item, index) => {
            if (
              (!item?.value && viewAsPublic) ||
              (!viewAsPublic &&
                !item?.value &&
                currentUser?.userId !== profile?._id)
            )
              return null;
            return (
              <Stack
                direction={"row"}
                spacing={1}
                key={index}
                alignItems={"center"}
              >
                {item?.icon}
                <Typography
                  component={item?.value ? "a" : "span"}
                  href={item?.link + item?.value}
                  variant="body2"
                  target="_blank"
                  rel="noopener noreferrer"
                  color={item?.value ? "inherit" : "text.secondary"}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {item?.value ? (
                    <>
                      {item?.link}
                      <b>{item?.value}</b>
                    </>
                  ) : (
                    "Add " + item?.label
                  )}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
}
