import { BadgeTwoTone, DangerousTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Chip,
  List,
  Tooltip,
  styled,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useState } from "react";
import ListCustom from "src/components/List";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      height: ${theme.spacing(6)};
      width: ${theme.spacing(6)};
`
);

export default function CrediblCertification() {
  const { profile, setProfile } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/users/verify-certificates`, {
        email: profile?.email,
      });
      if (response.status === 200) {
        Notify.success(
          "Your CPEW Certification has been verified successfully"
        );
        setProfile({
          ...profile,
          crediblCertification: {
            ...profile?.crediblCertification,
            certified: true,
            certificate: response?.data?.data,
          },
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Card>
      <CardHeader
        title="CPEW Certification"
        subheader={
          <Tooltip
            placement="right-end"
            title={
              !profile?.crediblCertification?.certified ? (
                "Not Certified"
              ) : (
                <Box>
                  <List>
                    {[
                      {
                        label: "Name",
                        value: `${
                          profile?.crediblCertification?.certificate?.userId
                            ?.firstName || "-"
                        } ${
                          profile?.crediblCertification?.certificate?.userId
                            ?.lastName || ""
                        }`,
                      },
                      {
                        label: "Email",
                        value:
                          profile?.crediblCertification?.certificate?.userId
                            ?.email || "-",
                      },
                      {
                        label: "Certificate ID",
                        value:
                          profile?.crediblCertification?.certificate?.certId ||
                          "-",
                      },
                      {
                        label: "Certificate URL",
                        value:
                          profile?.crediblCertification?.certificate?.url ||
                          "-",
                        link: profile?.crediblCertification?.certificate?.url,
                      },
                    ].map((item, index) => (
                      <ListCustom
                        key={index}
                        item={item}
                        rightStyle={{
                          fontWeight: "bold",
                        }}
                        leftStyle={{
                          className: "link-color",
                          width: 120,
                        }}
                      />
                    ))}
                  </List>
                </Box>
              )
            }
          >
            <Chip
              icon={
                !profile?.crediblCertification?.certified ? (
                  <DangerousTwoTone />
                ) : (
                  <BadgeTwoTone />
                )
              }
              label={
                !profile?.crediblCertification?.certified
                  ? "Not Certified"
                  : "Certified"
              }
              variant="outlined"
              color={
                !profile?.crediblCertification?.certified ? "error" : "success"
              }
              size="large"
              sx={{
                border: 0,
              }}
            />
          </Tooltip>
        }
        avatar={
          <AvatarWrapper
            src={"/static/images/logo/credibl-certified.png"}
            variant="rounded"
          />
        }
      />
      {!profile?.crediblCertification?.certified && (
        <LoadingButton
          size="small"
          fullWidth
          sx={{
            whiteSpace: "nowrap",
          }}
          onClick={handleUpdate}
          loading={loading}
        >
          Click here to check & verify
        </LoadingButton>
      )}

      {/* {!viewAsPublic && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>CPEW Certification Verify Request</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <DialogContentText>
                Please enter your Certificate ID to verify your Credibl
                Certification
              </DialogContentText>
              <TextField
                name="certificateId"
                placeholder={"eg. 12AX34XA"}
                value={formInput?.certificateId}
                onChange={(e) =>
                  setFormInput({
                    ...formInput,
                    certificateId: e.target.value,
                  })
                }
                label="Certificate ID"
                fullWidth
              />{" "}
              {currentUser?.userId !== profile?._id && (
                <TextField
                  name="email"
                  value={formInput?.email}
                  onChange={(e) =>
                    setFormInput({
                      ...formInput,
                      email: e.target.value,
                    })
                  }
                  defaultValue={profile?.email}
                  label="Certificate Holder Email"
                  fullWidth
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              loading={loading}
              onClick={handleUpdate}
              variant="contained"
              color="success"
            >
              Verify Now
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )} */}
    </Card>
  );
}
