import React from 'react'
import {Box} from '@mui/material'
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
export default function Handler() {
  return (
    <Box sx={{ textAlign: "center" }}>
          <DragIndicatorIcon
            sx={{ transform: "rotate(-90deg)", cursor: "all-scroll" }}
          />
        </Box>
  )
}
