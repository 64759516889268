import React, { useContext } from "react";
import {
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Stack,
  ListItem,
  ListItemText,
  List,
  alpha,
  useTheme,
  IconButton,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import Label from "src/components/Label";
import { GlobalContext } from "src/contexts/GlobalContext";
import { EditTwoTone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ProfilePublish from "../../addEdit/ProfilePublish";
import { ProfileContext } from "src/contexts/ProfileContext";

export default function AboutDetails() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const { profile, viewAsPublic } = useContext(ProfileContext);

  return (
    <div>
      <Card elevation={0}>
        {!viewAsPublic && <ProfilePublish />}
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              {t("Personal Details")}
            </Typography>
            <Typography variant="subtitle2">
              {t("Informations related to personal details")}
            </Typography>
          </Box>
          <Box>
            {currentUser?.userId === profile?._id && !viewAsPublic && (
              <IconButton
                size="small"
                color="secondary"
                component={Link}
                to={`/dashboard/account/profile/edit`}
              >
                <EditTwoTone />
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Card
              sx={{
                border: "1px solid #e0e0e0",
                background: alpha(theme.colors.alpha.black[70], 0.05),
              }}
            >
              <List>
                {[
                  {
                    label: "EthixFirst ID",
                    value: profile?.cleanExitUserId,
                  },
                  {
                    label: "Personal Email",
                    value: profile?.email,
                  },
                  {
                    label: "Contact Number",
                    value: profile?.phoneNumber,
                  },
                  {
                    label: "Location",
                    value:
                      [
                        profile?.address?.city,
                        profile?.address?.state,
                        profile?.address?.country,
                        profile?.address?.pincode,
                      ]
                        .filter((item) => item)
                        .join(", ") || "-",
                  },
                  {
                    label: "Complete Address",
                    value: profile?.address?.address1 || "-",
                  },
                ]?.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "h6",
                        color: "textSecondary",
                      }}
                      primary={item?.label}
                    />
                    <b>{item?.value || "-"}</b>
                  </ListItem>
                ))}
                {[
                  {
                    label: "Account Status",
                    color: profile?.isLocked ? "error" : "success",
                    value: profile?.isLocked ? "Deactivated" : "Active",
                  },
                  {
                    label: "Account Verification",
                    color: profile?.verified ? "success" : "error",
                    value: profile?.verified ? "Verified" : "Unverified",
                  },
                  {
                    label: "Phone Verification",
                    color: profile?.phoneVerified ? "success" : "error",
                    value: profile?.phoneVerified ? "Verified" : "Unverified",
                  },
                  {
                    label: "Email Verification",
                    color: profile?.isVerified ? "success" : "error",
                    value: profile?.isVerified ? "Verified" : "Unverified",
                  },
                  {
                    label: "EthixFirst Certification",
                    color: profile?.crediblCertification?.certified
                      ? "success"
                      : "error",
                    value: profile?.crediblCertification?.certified
                      ? "Certified"
                      : "Not Certified Yet",
                  },
                  // TODO: Undo Aadhaar Verification
                  // {
                  //   label: "Aadhaar Verification",
                  //   color: profile?.cleanExitVerifications?.aadhaar
                  //     ? "success"
                  //     : "warning",
                  //   value: profile?.cleanExitVerifications?.aadhaar
                  //     ? "Verified"
                  //     : "Verification Pending",
                  // },
                  {
                    label: "PAN Verification",
                    color: profile?.cleanExitVerifications?.pan
                      ? "success"
                      : "warning",
                    value: profile?.cleanExitVerifications?.pan
                      ? "Verified"
                      : "Verification Pending",
                  },
                ]?.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "h6",
                        color: "textSecondary",
                      }}
                      primary={item?.label}
                    />
                    <Label color={item?.color}>
                      <b>{item?.value}</b>
                    </Label>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
