import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Avatar,
  styled,
  Stack,
  Chip,
  Grid,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import { useEffect, useState } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import EmptyHere from "src/components/EmptyHere";
import CampaignBulkActions from "./components/CampaignBulkActions";
import AddRemoveFormInCampaign from "./components/AddRemoveFormInCampaign";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { RefreshOutlined } from "@mui/icons-material";

export default function Campaign() {
  let { campaignId } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/events/${campaignId}`);
        if (resp?.status === 200) {
          setEvent(resp?.data?.data || {});
          setCampaigns(resp?.data?.campaigns || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    })();
  }, [campaignId]);

  const handleResendEmails = async (_campaignId) => {
    try {
      const resp = await axiosInstance.post(
        `/campaigns/${_campaignId}/refresh`
      );
      if (resp?.status === 200) {
        const _campaigns = campaigns.map((item) => {
          if (item._id === _campaignId) {
            return resp?.data?.data;
          }
          return item;
        });
        setCampaigns(_campaigns);

        Notify.success("Request Successfull.");
      }
    } catch (error) {
      console.error(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack my={2}>
      {event ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    src={
                      event?.organisation?.avatar
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          (event?.organisation?.avatar + "?" + Math.random())
                        : ""
                    }
                  />
                }
                titleTypographyProps={{ variant: "h4" }}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                title={event?.organisation?.title || ""}
                subheader={event?.organisation?.companyWebsite || ""}
                sx={{ mx: 1 }}
              />
              <Box p={3}>
                <Typography
                  variant="h1"
                  sx={{
                    pb: 1,
                  }}
                >
                  {event?.title || ""}{" "}
                </Typography>
                <Box pt={1}>
                  {event?.tags?.map((value) => {
                    return (
                      <ChipWrapper
                        key={value}
                        color="secondary"
                        label={value}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Divider />

              <Box mx={2}>
                <AddRemoveFormInCampaign event={event} setEvent={setEvent} />
              </Box>
            </Card>
            <Card sx={{ my: 2, backgroundColor: "transparent" }} elevation={0}>
              <CardHeader title="Email History" />
              <Divider />
              {campaigns?.map((campaign, idx) => (
                <Card sx={{ my: 2 }} key={idx}>
                  <CardHeader
                    subheader={`Send at ${fDateTimeSuffix(
                      new Date(campaign?.createdAt)
                    )}`}
                    action={
                      <Stack direction="row" spacing={1} alignItems="center">
                        {campaign?.customerRelations?.filter(
                          (item) => item?.status === "pending"
                        )?.length > 0 && (
                          <>
                            {new Date(campaign?.createdAt).getTime() + 300000 <
                            new Date().getTime() ? (
                              <Tooltip title="Resend Emails (if long pending)">
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    handleResendEmails(campaign?._id)
                                  }
                                >
                                  <RefreshOutlined />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Retry in{" "}
                                {Math.ceil(
                                  (new Date(campaign?.createdAt).getTime() +
                                    300000 -
                                    new Date().getTime()) /
                                    (1000 * 60)
                                )}{" "}
                                minutes
                              </Typography>
                            )}
                          </>
                        )}
                      </Stack>
                    }
                  />
                  <Divider />
                  <CardContent>
                    {campaign?.customerRelations?.map((item, index) => (
                      <List key={index}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              alt={""}
                              src={
                                item?.user?.profileImage
                                  ? process.env.REACT_APP_DO_SPACE_URL +
                                    (item?.user?.profileImage +
                                      "?" +
                                      Math.random())
                                  : null
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${item?.user?.firstName} ${
                              item?.user?.lastName || ""
                            }`}
                            secondary={item?.user?.email}
                          />
                          <Chip
                            color={
                              item?.status === "pending"
                                ? "warning"
                                : item?.status === "delivered"
                                ? "success"
                                : item?.status === "failed"
                                ? "error"
                                : "info"
                            }
                            size="small"
                            label={item?.status}
                          />
                        </ListItem>
                      </List>
                    ))}
                  </CardContent>
                </Card>
              ))}
              {campaigns?.length === 0 && (
                <Card sx={{ my: 2 }}>
                  <EmptyHere title={"No history found"} />
                </Card>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <CampaignBulkActions event={event} />
          </Grid>
        </Grid>
      ) : (
        <EmptyHere title={"No event found"} />
      )}
    </Stack>
  );
}

const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      margin: ${theme.spacing(0.5)};
      padding: ${theme.spacing(1)};
      height: 28px;
      line-height: 28px;
      font-weight: bold;
`
);
