import {
  Stack,
  Grid,
  Box,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import HeaderStats from "./components/HeaderStats";
import SummaryStats from "./components/SummaryStats";
import RatingStats from "./components/RatingStats";
import ActivityLogs from "./components/ActivityLogs";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import RevenueStats from "./components/RevenueStats";
import { GlobalContext } from "src/contexts/GlobalContext";
import OrderStats from "../Orders/components/OrderStats";
import AccountsStats from "./components/AccountsStats";
import SuspenseLoader from "src/components/SuspenseLoader";

function DashboardAnalytics() {
  const { currentUser } = useContext(GlobalContext);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/dashboard`);
        if (response.status === 200) {
          setStats(response.data || null);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack my={2} spacing={3}>
      <HeaderStats stats={stats} />
      {currentUser?.accountType === "admin" && (
        <RevenueStats stats={stats?.orders || {}} />
      )}
      {currentUser?.orgId && (
        <Stack ml={-2}>
          <Card>
            <CardHeader
              title="Total Quota and Orders placed"
              action={
                <Typography variant="h5">
                  In the month of{" "}
                  {new Date().toLocaleString("default", {
                    month: "long",
                  }) +
                    " " +
                    new Date().getFullYear()}
                </Typography>
              }
            />
            <CardContent>
              <OrderStats stats={stats?.orders || {}} whichPage="dashboard" />
            </CardContent>
          </Card>
        </Stack>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} md={7}>
          <SummaryStats stats={stats} />
          <ActivityLogs />
        </Grid>
        <Grid item xs={12} md={5}>
          <Box p={{ xs: 2, md: 4 }}>
            <RatingStats stats={stats?.ratings || {}} />
            <Divider sx={{ my: 4 }} />
            <AccountsStats stats={stats} />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default DashboardAnalytics;
