import { Grid, Box, Card, Typography, Avatar, useTheme } from "@mui/material";

import {
  AccountBoxTwoTone,
  AddReactionTwoTone,
  AutoGraph,
  StarTwoTone,
} from "@mui/icons-material";

function Block2({ stats }) {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      {[
        {
          title: "Accounts",
          subtitle: `List of Users Account`,
          value: stats?.newAccounts || 0,
          icon: <AccountBoxTwoTone fontSize="large" />,
          color: `${theme.colors.info.main}`,
          bgColor: `${theme.colors.gradients.blue4}`,
        },
        {
          title: "Organisations",
          subtitle: `List of Organisations`,
          value: stats?.newOrganisations || 0,
          icon: <StarTwoTone fontSize="large" />,
          color: `${theme.colors.success.main}`,
          bgColor: `${theme.colors.gradients.green2}`,
        },
        {
          title: "Ratings",
          subtitle: `Ratings This Month`,
          value: stats?.ratingsTotal || 0,
          icon: <AutoGraph fontSize="large" />,
          color: `${theme.colors.error.main}`,
          bgColor: `${theme.colors.gradients.orange1}`,
        },
      ]?.map((item, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Card
            sx={{
              p: 2.5,
              background: `${item.bgColor}`,
            }}
          >
            <Box
              pb={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  gutterBottom
                  component="div"
                  variant="h6"
                  sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`,
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`,
                  }}
                >
                  {item.value}
                </Typography>
              </Box>
              <Avatar
                variant="rounded"
                sx={{
                  width: `${theme.spacing(7)}`,
                  height: `${theme.spacing(7)}`,
                  background: `${theme.colors.alpha.trueWhite[100]}`,
                  color: `${item.color}`,
                }}
              >
                {item.icon}
              </Avatar>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                noWrap
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`,
                }}
              >
                {item.subtitle}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Block2;
