import React from "react";
import SubmitManualForm from "src/sections/ManualForms/SubmitManualForm";

export default function SubmitPoshForm() {
  return (
    <div>
      <SubmitManualForm />
    </div>
  );
}
