  import { Notify } from "notiflix";
  import { useContext, useEffect, useState } from "react";
  import { useSearchParams } from "react-router-dom";
  import axiosInstance from "src/utils/axiosInstance";
  import { GlobalContext } from "src/contexts/GlobalContext";
  
  export default function ExamReceiver({
    form,
    receiver,
    setReceiver,
    organisation,
    setOrganisation,
    setHasAccessToSubmit,
  }) {
    const [searchParams] = useSearchParams();
    const { currentUser } = useContext(GlobalContext);
    const receiverId = searchParams.get("receiverId");
    const orgId = searchParams.get("orgId");
    const receiverTypeParam = searchParams.get("receiverType");
    const [loading, setLoading] = useState(false);
    const [inputFieldFor, setInputFieldFor] = useState("");
  
    const handleGetReceiverUser = async (userId) => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/users/${userId}`);
        if (resp?.status === 200) {
          setReceiver(resp?.data);
          setLoading(false);
          return resp?.data;
        }
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
        return null;
      }
    };
    const handleGetOrg = async (orgId) => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/organisations/${orgId}`);
        if (resp?.status === 200) {
          setOrganisation(resp?.data?.data || {});
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    };
  
    useEffect(() => {
      if (!form) return;
      (async () => {
        if (orgId) {
          await handleGetOrg(orgId);
        }
        if (receiverId) {
          await handleGetReceiverUser(receiverId);
        }
  
        // if form type = exam
        if (form?.type === "exam") {
          setReceiver(currentUser);
        } else if (form?.receivers?.includes("organisation")) {
          if (!orgId) {
            Notify.failure("Organisation ID is missing");
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
            setHasAccessToSubmit(false);
            return;
          }
        }
        // if receivers has employee
        else if (
          form?.receivers?.includes("employee") ||
          receiverTypeParam === "employee"
        ) {
          if (!receiverId) {
            setInputFieldFor("select-employee");
          }
          if (currentUser?.orgId) {
            setOrganisation({
              _id: currentUser?.orgId,
              title: currentUser?.orgName,
            });
          } else if (!orgId) {
            Notify.failure("Organisation ID is missing");
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
            setHasAccessToSubmit(false);
            return;
          }
        } else if (
          form?.receivers?.includes("user") &&
          form?.type !== "community"
        ) {
          // if (!receiverId) {
          //   Notify.failure("User ID is missing");
          //   setTimeout(() => {
          //     window.location.href = "/";
          //   }, 1000);
          //   setHasAccessToSubmit(false);
          //   return;
          // }
          if (
            !receiverId &&
            receiverTypeParam &&
            !["employee", "organisation"].includes(receiverTypeParam)
          ) {
            setInputFieldFor("select-user-relation");
          }
        }
  
        setHasAccessToSubmit(true);
      })();
  
      return () => {
        setReceiver(null);
        setOrganisation(null);
        setInputFieldFor("");
      };
    }, [form, receiverId, orgId]);
  
    return null;
  }
  