import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Box,
  Avatar,
  IconButton,
  CardContent,
  Stack,
  TextField,
  styled,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Alert,
  Button,
  Divider,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import {
  AddTwoTone,
  PhotoCameraTwoTone,
  SaveTwoTone,
  UploadTwoTone,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";
import { Link, useSearchParams } from "react-router-dom";
import { Confirm, Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { DO_SPACES_FOLDERS } from "src/utils/constants";
import CountriesWithStates from "src/utils/countries/states.json";
import CountriesWithCities from "src/utils/countries/cities.json";
import EditSocialLinks from "./components/EditSocialLinks";
import EditPortfolio from "./components/EditPortfolio";
import AddToPlatform from "../profile/addEdit/AddToPlatform";

export default function AddEditUser({ page }) {
  const navigate = useNavigate();
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const organisationParam = searchParams.get("organisation");
  const roleParam = searchParams.get("role"); // triager, owner, moderator, user
  const [errors, setErrors] = useState(null);
  const [organisationId, setOrganisationId] = useState("");
  const [loading, setLoading] = useState(true);
  const [isWait, setIsWait] = useState(false);
  const [formInput, setFormInput] = useState({
    role: roleParam,
    meta: {},
    address: {},
  });
  const [employeeFormInput, setEmployeeFormInput] = useState({
    role: roleParam,
  });
  const [portfolioFormInputs, setPortfolioFormInputs] = useState({});
  const [profileId, setProfileId] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [userInputFields, setUserInputFields] = useState([]);

  // permission and localisation
  const [selectedCity, setSelectedCity] = useState("");

  // edited inputs
  const [editedFormInput, setEditedFormInput] = useState({});
  const [editedEmployeeFormInput, setEditedEmployeeFormInput] = useState({});
  const [editedPortfolioFormInputs, setEditedPortfolioFormInputs] = useState(
    {}
  );

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  useEffect(() => {
    (async () => {
      const _departments = await getTagsByType("departments");
      setDepartments(_departments);
      const _designations = await getTagsByType("designations");
      setDesignations(_designations);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [zoneTags, setZoneTags] = useState({
    type: "",
    tags: [],
  });
  useEffect(() => {
    if (!selectedCity) return;
    (async () => {
      const resp = await axiosInstance.get("/tags/type", {
        params: {
          type: selectedCity,
        },
      });
      if (resp?.status === 200) {
        setZoneTags(
          resp?.data || {
            type: selectedCity,
            tags: [],
          }
        );
      }
    })();
  }, [selectedCity]);

  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    (async () => {
      const _industries = await getTagsByType("industries");
      setIndustries(_industries);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleParam]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (page !== "profile" && !userId && !roleParam) {
        Notify.failure("Invalid link");
        navigate("/dashboard");
      }

      const _userId = page === "profile" ? currentUser?.userId : userId;
      setProfileId(_userId);

      setOrganisationId(organisationParam || currentUser?.orgId || null);

      if (_userId) {
        try {
          const resp = await axiosInstance.get(`/users/${_userId}`, {
            params: {
              userId: _userId,
            },
          });
          if (resp.status === 200) {
            const { roles, portfolio, ..._data } = resp.data;
            if (roles?.length > 0) {
              setEmployeeFormInput(roles?.[0]);
            }
            if (portfolio) {
              setPortfolioFormInputs(portfolio);
            }
            setFormInput(resp.data || {});
          }
        } catch (error) {
          setFormInput({});
          setEmployeeFormInput({});
          console.log(error);
          Notify.failure(
            error.response?.data?.message ||
              error.response?.statusText ||
              "an error occured"
          );
          setTimeout(() => {
            navigate("/dashboard/users");
          }, 1000);
        }
      }

      if (
        (organisationParam || currentUser?.orgId) &&
        roleParam !== "triager"
      ) {
        try {
          const resp = await axiosInstance.get("/tags", {
            params: {
              organisation: organisationParam || currentUser?.orgId,
            },
          });
          if (resp.status === 200 && resp.data?.length > 0) {
            const _fields = resp?.data?.find(
              (item) => item?.type === "users"
            )?.tags;
            setUserInputFields(_fields || []);

            const _departments = resp?.data?.find(
              (item) => item?.type === "departments"
            )?.tags;
            setDepartments(_departments || []);

            const _designations = resp?.data?.find(
              (item) => item?.type === "designations"
            )?.tags;
            setDesignations(_designations || []);
          }
        } catch (error) {
          setUserInputFields([]);
          console.log(error);
        }
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeInput = (e) => {
    if (profileId) {
      setEditedFormInput({
        ...editedFormInput,
        [e.target.name]: e.target.value,
      });
    }
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };
  const handleChangeEmployeeInput = (e) => {
    if (profileId) {
      setEditedEmployeeFormInput({
        ...editedEmployeeFormInput,
        [e.target.name]: e.target.value,
      });
    }

    setEmployeeFormInput({
      ...employeeFormInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateUser = async () => {
    try {
      if (
        !formInput?.firstName ||
        !formInput?.lastName ||
        !formInput?.email ||
        !formInput?.phoneNumber ||
        !formInput?.role
      ) {
        Notify.failure("Please fill all required fields");
        return;
      }

      setIsWait(true);
      if (profileImage) {
        const formData = new FormData();
        formData.append("files", profileImage);
        formData.append("fileCategory", DO_SPACES_FOLDERS.PROFILE_IMAGE);
        if (formInput?._id) {
          formData.append("userId", formInput?._id);
        }
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp.status === 200 && imageResp.data) {
          formInput.profileImage = imageResp.data?.[0];
          setFormInput(formInput);
        }
      }

      formInput.accountType =
        employeeFormInput?.role === "moderator"
          ? "user"
          : employeeFormInput?.role;

      formInput.role = employeeFormInput?.role;
      formInput.isVerified = true;

      if (organisationId) {
        formInput.organisation = organisationId;
        employeeFormInput.organisation = organisationId;
      }

      const resp = await axiosInstance.post("/auth/create", formInput);
      const _user = resp?.data;
      if (resp.status === 200) {
        // if (
        //   _user?.firstName !== formInput?.firstName ||
        //   _user?.lastName !== formInput?.lastName ||
        //   _user?.email !== formInput?.email ||
        //   _user?.phoneNumber !== formInput?.phoneNumber
        // ) {
        //   setErrors({
        //     message:
        //       "User with same Personal Email already exists in our Database, please use another email or Load User's Profile by clicking on the 'Load Profile' button",
        //     color: "error",
        //     data: resp.data,
        //   });
        //   return;
        // }
      }

      if (employeeFormInput?.organisation) {
        if (!employeeFormInput?.companyEmail) {
          employeeFormInput.companyEmail = _user?.email;
        }
        await axiosInstance.post("/employees", {
          user: _user?._id,
          ...employeeFormInput,
        });
      }

      if (
        currentUser?.accountType === "admin" &&
        Object.keys(portfolioFormInputs)?.length > 0
      ) {
        await axiosInstance.put(
          `/portfolios/${_user?._id}/categories`,
          portfolioFormInputs
        );
      }

      setIsWait(false);
      setFormInput({});
      setEmployeeFormInput({});
      setEditedFormInput({});
      setEditedEmployeeFormInput({});
      setProfileImage(null);
      setCoverImage(null);

      Notify.success("Added successfully");
      navigate(`/dashboard/users/${resp.data?._id}`);
    } catch (error) {
      setIsWait(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleUpdateUser = async () => {
    try {
      if (
        !formInput?.firstName ||
        !formInput?.lastName ||
        !formInput?.email ||
        !formInput?.phoneNumber
      ) {
        Notify.failure("Please fill all required fields");
        return;
      }

      setIsWait(true);
      if (profileImage) {
        const formData = new FormData();
        formData.append("files", profileImage);
        formData.append("fileCategory", DO_SPACES_FOLDERS.PROFILE_IMAGE);
        if (formInput?._id) {
          formData.append("userId", formInput?._id);
        }
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp.status === 200 && imageResp.data) {
          editedFormInput.profileImage = imageResp.data?.[0];
          setEditedFormInput(editedFormInput);
          setFormInput({
            ...formInput,
            profileImage: imageResp.data?.[0],
          });
        }
      }

      if (coverImage) {
        const formData = new FormData();
        formData.append("files", coverImage);
        formData.append("fileCategory", DO_SPACES_FOLDERS.COVER_IMAGE);
        if (formInput?._id) {
          formData.append("userId", formInput?._id);
        }
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp.status === 200 && imageResp.data) {
          editedFormInput.coverImage = imageResp.data?.[0];
          setEditedFormInput(editedFormInput);
          setFormInput({
            ...formInput,
            coverImage: imageResp.data?.[0],
          });
        }
      }

      if (editedEmployeeFormInput?.role) {
        editedFormInput.accountType =
          editedEmployeeFormInput?.role === "moderator"
            ? "user"
            : editedEmployeeFormInput?.role;

        editedFormInput.role = editedEmployeeFormInput?.role;
      }
      if (
        Object.keys(editedFormInput).length > 0 ||
        profileImage ||
        coverImage
      ) {
        await axiosInstance.put(`/users/${formInput?._id}`, editedFormInput);
        if (page === "profile" && profileImage) {
          const _user = JSON.parse(localStorage.getItem("user"));
          _user.profileImage = editedFormInput.profileImage;
          localStorage.setItem("user", JSON.stringify(_user));
        }
        setProfileImage(null);
        setCoverImage(null);
      }
      if (
        Object.keys(editedEmployeeFormInput).length > 0 &&
        employeeFormInput?._id
      ) {
        const empResp = await axiosInstance.put(
          `/employees/${employeeFormInput?._id}`,
          editedEmployeeFormInput
        );
        if (roleParam !== empResp?.data?.role) {
          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            role: empResp?.data?.role,
          });
        }
      }
      if (
        currentUser?.accountType === "admin" &&
        Object.keys(editedPortfolioFormInputs)?.length > 0
      ) {
        await axiosInstance.put(
          `/portfolios/${formInput?._id}/categories`,
          editedPortfolioFormInputs
        );
      }

      setEditedFormInput({});
      setEditedEmployeeFormInput({});
      setEditedPortfolioFormInputs([]);
      setIsWait(false);
      Notify.success("Updated successfully");
    } catch (error) {
      setIsWait(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleActivateDeactivateAccount = async () => {
    try {
      const resp = await axiosInstance.delete(`/users/${formInput?._id}`);
      if (resp.status === 200) {
        Notify.success("Account Status Updated");
        if (currentUser?.userId === formInput?._id) {
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.reload();
        } else {
          if (formInput?.isLocked) window.location.reload();
          else navigate("/dashboard/users");
        }
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleSendResetPasswordLink = async () => {
    try {
      const resp = await axiosInstance.post("/auth/reset-password-email", {
        email: formInput?.email,
      });
      if (resp?.status === 200) {
        Notify.success("Reset Password Link Sent");
      }
    } catch (error) {
      console.error(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <AvatarWrapper>
              <Avatar
                variant="rounded"
                alt={formInput?.firstName}
                src={
                  profileImage
                    ? URL.createObjectURL(profileImage)
                    : formInput?.profileImage
                    ? process.env.REACT_APP_DO_SPACE_URL +
                      (formInput?.profileImage + "?" + Math.random())
                    : ""
                }
              />
              <ButtonUploadWrapper>
                <Input
                  accept="image/png, image/jpeg, image/jpg"
                  id="icon-button-file"
                  name="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    setProfileImage(e.target.files[0]);
                  }}
                  disabled={isWait}
                />
                <label htmlFor="icon-button-file">
                  <IconButton component="span" color="primary">
                    <UploadTwoTone />
                  </IconButton>
                </label>
              </ButtonUploadWrapper>
            </AvatarWrapper>
          </Box>
          <Box>
            <FormGroup>
              {organisationId && ["user", "moderator"].includes(roleParam) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        editedEmployeeFormInput?.role
                          ? editedEmployeeFormInput?.role === "moderator"
                          : roleParam === "moderator"
                      }
                      onChange={(e) => {
                        const _inputForm = { ...employeeFormInput };
                        _inputForm.role = e.target.checked
                          ? "moderator"
                          : "user";
                        setEmployeeFormInput(_inputForm);
                        if (profileId) {
                          setEditedEmployeeFormInput({
                            ...editedEmployeeFormInput,
                            role: e.target.checked ? "moderator" : "user",
                          });
                        }
                      }}
                    />
                  }
                  label="Add as Moderator"
                />
              )}
            </FormGroup>

            {profileId && (
              <Stack spacing={2} direction={"row"}>
                {/* {currentUser?.role === "user" && (
                  <Button
                    variant={editFullProfile ? "contained" : "outlined"}
                    color="warning"
                    onClick={() => {
                      setEditFullProfile(!editFullProfile);
                    }}
                    size="small"
                  >
                    {editFullProfile ? "Hide " : "Edit "}
                    Full Profile
                  </Button>
                )} */}
                <Button
                  startIcon={<PhotoCameraTwoTone />}
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = "image/*";
                    input.onchange = async (e) => {
                      setCoverImage(e.target.files[0]);
                    };
                    document.body.appendChild(input);
                    input.click();
                    document.body.removeChild(input);
                  }}
                >
                  Upload Cover Image
                </Button>
              </Stack>
            )}
            {coverImage && coverImage?.name && (
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                {coverImage.name}
              </Typography>
            )}
          </Box>
        </Box>

        {errors && (
          <Alert
            sx={{
              position: "relative",
              bottom: "20px",
            }}
            severity={errors?.color}
            action={
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setFormInput({
                    ...formInput,
                    firstName: errors?.data?.firstName,
                    lastName: errors?.data?.lastName,
                    email: errors?.data?.email,
                    phoneNumber: errors?.data?.phoneNumber,
                    meta: {
                      ...formInput?.meta,
                    },
                    address: {
                      ...formInput?.address,
                    },
                  });
                  setIsWait(false);
                }}
              >
                Load Profile
              </Button>
            }
          >
            {errors?.message}
          </Alert>
        )}
        <Stack spacing={2}>
          {organisationId && roleParam !== "triager" && (
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Employee ID"
                name="employeeId"
                value={employeeFormInput?.employeeId || ""}
                onChange={(e) => {
                  if (profileId) {
                    setEditedEmployeeFormInput({
                      ...editedEmployeeFormInput,
                      [e.target.name]: e.target.value?.toUpperCase(),
                    });
                  }
                  setEmployeeFormInput({
                    ...employeeFormInput,
                    [e.target.name]: e.target.value?.toUpperCase(),
                  });
                }}
              />
              <TextField
                fullWidth
                label="Organisation Email"
                name="companyEmail"
                value={employeeFormInput?.companyEmail || ""}
                onChange={(e) => {
                  if (profileId) {
                    setEditedEmployeeFormInput({
                      ...editedEmployeeFormInput,
                      [e.target.name]: e.target.value,
                    });
                  }
                  setEmployeeFormInput({
                    ...employeeFormInput,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </Stack>
          )}
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formInput?.firstName || ""}
              onChange={handleChangeInput}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formInput?.lastName || ""}
              onChange={handleChangeInput}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Headline"
              placeholder="What defines you best? (e.g. Software Engineer, IT, Sales Manager, Chartered Accountant, Chef, etc.)"
              value={formInput?.meta?.headline || ""}
              onChange={(e) => {
                if (profileId) {
                  setEditedFormInput({
                    ...editedFormInput,
                    meta: {
                      ...formInput.meta,
                      headline: e.target.value,
                    },
                  });
                }

                setFormInput({
                  ...formInput,
                  meta: {
                    ...formInput.meta,
                    headline: e.target.value,
                  },
                });
              }}
            />

            <Autocomplete
              fullWidth
              multiple
              options={industries || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter Industry Type *"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
              onChange={(e, value) => {
                if (profileId) {
                  setEditedFormInput({
                    ...editedFormInput,
                    industry: value,
                  });
                }

                setFormInput({
                  ...formInput,
                  industry: value,
                });
              }}
              value={formInput?.industry || []}
            />
          </Stack>
          <EditPortfolio
            profileId={profileId}
            portfolioFormInputs={portfolioFormInputs}
            setPortfolioFormInputs={setPortfolioFormInputs}
            editedPortfolioFormInputs={editedPortfolioFormInputs}
            setEditedPortfolioFormInputs={setEditedPortfolioFormInputs}
          />
          <TextField
            fullWidth
            label="Bio (About)"
            name="about"
            value={formInput?.meta?.about || ""}
            onChange={(e) => {
              if (e.target.value.length > 500) {
                Notify.warning("Bio should not be more than 200 characters");
                return;
              }
              if (profileId) {
                setEditedFormInput({
                  ...editedFormInput,
                  meta: {
                    ...formInput.meta,
                    about: e.target.value,
                  },
                });
              }

              setFormInput({
                ...formInput,
                meta: {
                  ...formInput.meta,
                  about: e.target.value,
                },
              });
            }}
            multiline
            rows={4}
          />

          {userInputFields?.map((field, idx) => (
            <TextField
              fullWidth
              key={idx}
              label={field}
              name={field}
              value={employeeFormInput?.[field] || ""}
              onChange={(e) => {
                if (profileId) {
                  setEditedEmployeeFormInput({
                    ...editedEmployeeFormInput,
                    [field]: e.target.value,
                  });
                }
                setEmployeeFormInput({
                  ...employeeFormInput,
                  [field]: e.target.value,
                });
              }}
            />
          ))}
        </Stack>

        {currentUser?.accountType === "admin" &&
          formInput?._id &&
          !formInput?.isLocked && (
            <AddToPlatform formInput={formInput} setFormInput={setFormInput} />
          )}

        <Divider sx={{ mb: 5, mt: 10 }}>Contact Info</Divider>
        <Stack spacing={2}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Personal Email *"
              name="email"
              value={formInput?.email || ""}
              disabled={profileId && currentUser?.accountType !== "admin"}
              onChange={handleChangeInput}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              disabled={profileId && currentUser?.accountType !== "admin"}
              value={formInput?.phoneNumber || ""}
              onChange={handleChangeInput}
            />
          </Stack>
          {organisationId && ["user", "moderator"]?.includes(roleParam) && (
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Designation</InputLabel>
                <Select
                  fullWidth
                  label="Designation"
                  name="designation"
                  value={employeeFormInput?.designation || ""}
                  onChange={handleChangeEmployeeInput}
                >
                  {currentUser?.accountType === "admin" && (
                    <MenuItem
                      style={{ color: "#4454CC" }}
                      component={Link}
                      to="/dashboard/management?type=designations"
                      target="_blank"
                    >
                      <AddTwoTone fontSize="small" />
                      Create New Designation
                    </MenuItem>
                  )}
                  {designations?.map((item, idx) => (
                    <MenuItem key={idx} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Department</InputLabel>
                <Select
                  fullWidth
                  label="Department"
                  name="department"
                  value={employeeFormInput?.department || ""}
                  onChange={handleChangeEmployeeInput}
                >
                  {currentUser?.accountType === "admin" && (
                    <MenuItem
                      component={Link}
                      style={{ color: "#4454CC" }}
                      to="/dashboard/management?type=departments"
                      target="_blank"
                    >
                      <AddTwoTone fontSize="small" />
                      Create New Department
                    </MenuItem>
                  )}
                  {departments?.map((item, idx) => (
                    <MenuItem key={idx} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Autocomplete
              fullWidth
              autoHighlight
              options={CountriesWithStates?.map((item) => item?.name)}
              value={formInput?.address?.country || "India"}
              onChange={(e, value) => {
                if (profileId) {
                  setEditedFormInput({
                    ...editedFormInput,
                    address: {
                      ...formInput.address,
                      country: value,
                    },
                  });
                }

                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    country: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              autoHighlight
              options={
                CountriesWithStates?.find(
                  (item) =>
                    item?.name === (formInput?.address?.country || "India")
                )?.states?.map((item) => item?.name) || []
              }
              value={formInput?.address?.state || ""}
              onChange={(e, value) => {
                if (profileId) {
                  setEditedFormInput({
                    ...editedFormInput,
                    address: {
                      ...formInput.address,
                      state: value,
                    },
                  });
                }

                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    state: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select State"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              autoHighlight
              options={
                CountriesWithCities?.find(
                  (item) =>
                    item?.country === (formInput?.address?.country || "India")
                )?.cities || []
              }
              value={formInput?.address?.city || ""}
              onChange={(e, value) => {
                if (profileId) {
                  setEditedFormInput({
                    ...editedFormInput,
                    address: {
                      ...formInput.address,
                      city: value,
                    },
                  });
                }

                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    city: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select City"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <TextField
              fullWidth
              label="Pincode"
              name="pincode"
              placeholder="Enter Pincode/Zipcode"
              value={formInput?.address?.pincode || ""}
              onChange={(e) => {
                if (profileId) {
                  setEditedFormInput({
                    ...editedFormInput,
                    address: {
                      ...formInput.address,
                      pincode: e.target.value,
                    },
                  });
                }

                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    pincode: e.target.value,
                  },
                });
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Complete Address"
              value={formInput?.address?.address1 || ""}
              placeholder="eg. 123, Street, City, Country"
              onChange={(e) => {
                if (profileId) {
                  setEditedFormInput({
                    ...editedFormInput,
                    address: {
                      ...formInput.address,
                      address1: e.target.value,
                    },
                  });
                }
                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    address1: e.target.value,
                  },
                });
              }}
              multiline
              rows={3}
            />
          </Stack>
        </Stack>
        {currentUser?.accountType === "admin" && roleParam === "misc" && (
          <>
            <Divider sx={{ mb: 5, mt: 10 }}>Permissions & Localisation</Divider>
            <Stack spacing={2}>
              <Stack>
                <Autocomplete
                  fullWidth
                  autoHighlight
                  multiple
                  options={industries || []}
                  value={formInput?.industries || []}
                  onChange={(e, value) => {
                    if (profileId) {
                      setEditedFormInput({
                        ...editedFormInput,
                        industries: value,
                      });
                    }

                    setFormInput({
                      ...formInput,
                      industries: value,
                    });
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Select Industries"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                      <FormHelperText>
                        This account will access associated organizations within
                        selected industries.
                      </FormHelperText>
                    </>
                  )}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Autocomplete
                  sx={{ width: "30%" }}
                  freeSolo
                  options={
                    CountriesWithCities?.find(
                      (item) =>
                        item?.country ===
                        (formInput?.address?.country || "India")
                    )?.cities || []
                  }
                  value={selectedCity || ""}
                  onChange={(e, value) => {
                    setSelectedCity(value);
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        placeholder="Search City to Select Zones"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />

                      <FormHelperText>
                        It'll not be used as filter for Zone access.
                      </FormHelperText>
                    </>
                  )}
                />{" "}
                <Autocomplete
                  fullWidth
                  autoHighlight
                  multiple
                  options={zoneTags?.tags || []}
                  value={formInput?.zones || []}
                  onChange={(e, value) => {
                    if (profileId) {
                      setEditedFormInput({
                        ...editedFormInput,
                        zones: value,
                      });
                    }

                    setFormInput({
                      ...formInput,
                      zones: value,
                    });
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Select Zones"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />

                      <FormHelperText>
                        This account can access associated organizations within
                        the selected Zones
                      </FormHelperText>
                    </>
                  )}
                />
              </Stack>
            </Stack>
          </>
        )}
        <Divider sx={{ mb: 5, mt: 12 }}>KYC verification</Divider>
        <Stack spacing={2}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <TextField
              fullWidth
              name="aadhaarNumber"
              label={"Aadhaar Number"}
              value={formInput?.aadhaarNumber || ""}
              onChange={handleChangeInput}
            />
            <TextField
              fullWidth
              name="panNumber"
              label={"PAN Number"}
              value={formInput?.panNumber || ""}
              onChange={handleChangeInput}
            />
          </Stack>
        </Stack>
        <Divider sx={{ mb: 5, mt: 12 }}>Social Links</Divider>
        <EditSocialLinks
          formInput={formInput}
          setFormInput={setFormInput}
          editedFormInput={editedFormInput}
          setEditedFormInput={setEditedFormInput}
          profileId={profileId}
        />
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }}>
            {(() => {
              if (!formInput?._id) return null;
              if (
                currentUser?.userId === formInput?._id ||
                currentUser?.accountType === "admin"
              ) {
                return (
                  <Stack direction={"row"} spacing={2}>
                    <LoadingButton
                      variant={formInput?.isLocked ? "contained" : "outlined"}
                      color={formInput?.isLocked ? "success" : "error"}
                      onClick={() => {
                        Confirm.show(
                          "Confirmation",
                          `Are you sure you want to ${
                            formInput?.isLocked ? "activate" : "deactivate"
                          } this account?`,
                          "Confirm",
                          "Cancel",
                          () => handleActivateDeactivateAccount(),
                          () => {},
                          {
                            width: "500px",
                            messageMaxLength: 1000,
                          }
                        );
                      }}
                    >
                      {formInput?.isLocked ? "Activate " : "Deactivate "}
                      Account
                    </LoadingButton>
                    {currentUser?.accountType === "admin" && (
                      <LoadingButton
                        variant={"outlined"}
                        color={"info"}
                        disabled={formInput?.isLocked}
                        onClick={() => {
                          Confirm.show(
                            "Confirmation",
                            "Are you sure you want to continue?",
                            "Confirm",
                            "Cancel",
                            () => handleSendResetPasswordLink(),
                            () => {},
                            {
                              width: "500px",
                              messageMaxLength: 1000,
                            }
                          );
                        }}
                      >
                        Send reset password link
                      </LoadingButton>
                    )}
                  </Stack>
                );
              } else return null;
            })()}
          </Box>
          <LoadingButton
            variant="outlined"
            startIcon={<SaveTwoTone />}
            onClick={() => {
              !profileId ? handleCreateUser() : handleUpdateUser();
            }}
            loading={loading || isWait}
            disabled={
              profileId &&
              !profileImage &&
              !coverImage &&
              !(
                Object.keys(editedEmployeeFormInput).length > 0 ||
                Object.keys(editedFormInput).length > 0 ||
                Object.keys(editedPortfolioFormInputs).length > 0
              )
            }
          >
            {profileId ? "Update" : "Create"}
          </LoadingButton>
        </Box>
      </CardContent>
    </Card>
  );
}

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);
