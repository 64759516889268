import React, { useEffect, useState } from "react";
import TagManager from "./components/TagManager";
import UserCertificateGroups from "./components/CertificateGroups";
import EmployeeInputFields from "./components/EmployeeInputFields";
import { useSearchParams } from "react-router-dom";
import InputFields from "./components/InputFields";
import ZonesByCity from "./components/ZonesByCity";

export default function Management() {
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");
  const [tabValue, setTabValue] = useState("");

  useEffect(() => {
    if (tabParam) {
      setTabValue(tabParam);
    }
  }, [tabParam]);

  switch (tabValue) {
    case "certificates":
      return <UserCertificateGroups />;
    case "tags":
      return <TagManager />;
    case "fields":
      return <InputFields />;
    case "zones":
      return <ZonesByCity />;
    case "input-fields":
      return <EmployeeInputFields />;
    default:
      return null;
  }
}
