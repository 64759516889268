import { Dialog, DialogContent, DialogTitle, capitalize } from "@mui/material";
import React from "react";
import AadhaarVerification from "./AadhaarVerification";
import PanVerification from "./PanVerification";

export default function CleanExitVerifyDialog({ open, handleClose }) {
  return (
    <div>
      <Dialog
        open={open ? true : false}
        onClose={() => {
          handleClose(null);
        }}
      >
        <DialogTitle>
          Please Enter below details to verify your account:{" "}
          {capitalize(open || "")} Card
        </DialogTitle>
        <DialogContent>
          {open === "aadhaar" ? (
            <AadhaarVerification open={open} handleClose={handleClose} />
          ) : (
            <PanVerification open={open} handleClose={handleClose} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
