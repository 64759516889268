import { Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  Typography,
  Stack,
  Paper,
  InputBase,
  IconButton,
  CardContent,
  Divider,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT_5 } from "src/utils/constants";

export default function SelectUserRelation({
  orgId,
  handleSelectUser,
  filterOptions,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser } = useContext(GlobalContext);
  const pageParam = searchParams.get("page");
  const [users, setUsers] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    if (!orgId) return;

    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT_5, skip: (page - 1) * PAGE_LIMIT_5 },
        };

        if (filterObj) {
          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        if (orgId) {
          reqParams.params = {
            ...reqParams.params,
            ...filterOptions,
            organisation: orgId,
            isActive: true,
          };
        }

        const resp = await axiosInstance.get(`/customer-relations`, reqParams);
        if (resp?.status === 200) {
          setUsers(resp?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    })();
  }, [orgId, page, filterObj]);

  return (
    <Box>
      <CardContent>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (e.target.value.length) {
                  setFilterObj({ ...filterObj, search: searchString });
                } else {
                  delete filterObj.search;
                  setFilterObj({ ...filterObj, search: null });
                }
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }
            }}
            onChange={(e) => {
              if (e.target.value?.length > 0) {
                setSearchString(e.target.value);
              } else {
                if (filterObj?.search) {
                  setFilterObj({
                    ...filterObj,
                    search: null,
                  });
                }
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              m: 0.2,
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              setFilterObj({
                ...filterObj,
                search: searchString,
              });
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          >
            <Search />
          </IconButton>
        </Paper>
      </CardContent>
      <Divider />
      <List>
        {users?.data?.filter((item) => item?.user?._id !== currentUser?.userId)
          ?.length > 0 ? (
          users?.data
            ?.filter((item) => item?.user?._id !== currentUser?.userId)
            ?.map((item, index) => (
              <ListItem key={index}>
                <ListItemButton onClick={() => handleSelectUser(item?.user)}>
                  <ListItemAvatar>
                    <Avatar
                      src={
                        process.env.REACT_APP_DO_SPACE_URL +
                        item?.user?.profileImage +
                        `?${Math.random()}}`
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h4">
                        {item?.user?.firstName +
                          " " +
                          (item?.user?.lastName || "")}
                      </Typography>
                    }
                    secondary={<small>{item?.user?.cleanExitUserId}</small>}
                  />
                </ListItemButton>
              </ListItem>
            ))
        ) : (
          <EmptyHere
            title={`No ${filterOptions?.relation || "user"} found`}
            subtitle={" "}
          />
        )}

        {!loading &&
          users?.data?.filter((item) => item?.user?._id !== currentUser?.userId)
            ?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={users?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
      </List>
    </Box>
  );
}
