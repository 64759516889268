// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { alpha, useTheme } from "@mui/material/styles";
import { Button, Card, Tooltip, Typography } from "@mui/material";
import { bgGradient } from "src/components/bgGradient";
import { EmailOutlined } from "@mui/icons-material";
import { MAX_SCORE } from "src/utils/constants";
import ScoreCircularBar from "src/content/dashboards/common/ScoreCircularBar";

export default function OrgHeader({ profile }) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        // height: 280,
        position: "relative",
      }}
    >
      <Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.action.disabled, 0.7),
            imgUrl: profile?.coverImage
              ? process.env.REACT_APP_DO_SPACE_URL +
                profile?.coverImage +
                `?${Math.random()}`
              : "/static/images/placeholders/covers/7.jpg",
          }),
          height: 1,
          color: "common.white",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            sx={{
              px: 5,
              py: 3,
            }}
          >
            <Avatar
              src={
                process.env.REACT_APP_DO_SPACE_URL +
                profile?.avatar +
                `?${Math.random()}`
              }
              alt={profile?.title + " "}
              sx={{
                width: { xs: 100, sm: 150 },
                height: { xs: 100, sm: 150 },
                border: `solid 2px ${theme.palette.common.white}`,
              }}
            />

            <Stack ml={3}>
              <ListItemText
                primary={
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography variant="h1">{profile?.title || ""}</Typography>
                  </Stack>
                }
                secondary={
                  <>
                    <Tooltip title={"EthixFirst ID"}>
                      <Typography
                        sx={{
                          mt: 0.5,
                          color: "white",
                          component: "span",
                          typography: "h4",
                          opacity: 0.68,
                        }}
                      >
                        {profile?.cleanExitUserId}
                      </Typography>
                    </Tooltip>
                    <Button
                      size="small"
                      color="warning"
                      component="a"
                      href={`mailto:${profile?.email || profile?.companyEmail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                      startIcon={<EmailOutlined />}
                      sx={{ mt: 2 }}
                    >
                      Contact Us{" "}
                    </Button>
                  </>
                }
              />
            </Stack>
          </Stack>
          {/* <Stack m={2}>
            <Box
              sx={{
                position: "relative",
                width: "280px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <ScoreCircularBar
                userProfile={{
                  score: profile?.companyScore,
                }}
              />
            </Box>

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography component={"span"}>
                <Typography variant="h1" component={"span"}>
                  <b>{profile?.companyScore || MAX_SCORE}</b>
                </Typography>
                {!(
                  profile?.companyScore === MAX_SCORE ||
                  profile?.companyScore === 0
                ) && <sub>/{MAX_SCORE}</sub>}
              </Typography>
              <Typography mt={1}>Company Ethics Score</Typography>
            </Box>
          </Stack> */}
        </Stack>
      </Box>
    </Card>
  );
}
