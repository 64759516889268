import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import EditIcon from "@mui/icons-material/Edit";
import { PAGE_LIMIT } from "src/utils/constants";
import {
  ArrowForwardIos,
  CorporateFareTwoTone,
  InfoOutlined,
} from "@mui/icons-material";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function Organisations() {
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const claimedParam = searchParams.get("claimed");
  const [filterObj, setFilterObj] = React.useState({
    isClaimed: claimedParam === "false" ? undefined : true,
  });
  const [loading, setLoading] = React.useState(false);

  const [templates, setTemplates] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = React.useState("");

  const handlePageChange = (event, value) => {
    setPage(value + 1);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value + 1,
    });
  };

  useEffect(() => {
    if (searchParams) {
      setPage(parseInt(searchParams.get("page")) || 1);
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }
      const resp = await axiosInstance.get("/organisations", reqParams);
      if (resp?.status === 200) {
        setTemplates(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
      }
      setLoading(false);
    })();
  }, [page, filterObj]);

  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box fullWidth>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here..."
              inputProps={{ "aria-label": "search here" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (e.target.value.length) {
                    setFilterObj({ ...filterObj, search: searchString });
                  } else {
                    delete filterObj.search;
                    setFilterObj({ ...filterObj, search: null });
                  }
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }
              }}
              onChange={(e) => {
                if (e.target.value?.length > 0) {
                  setSearchString(e.target.value);
                } else {
                  if (filterObj?.search) {
                    setFilterObj({
                      ...filterObj,
                      search: null,
                    });
                  }
                }
              }}
            />
            <IconButton
              type="button"
              sx={{
                p: "10px",
                m: 0.2,
                bgcolor: "primary.main",
                color: "white",
              }}
              onClick={() => {
                setFilterObj({
                  ...filterObj,
                  search: searchString,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterObj?.isClaimed}
                onChange={(e) => {
                  setFilterObj({
                    ...filterObj,
                    isClaimed: e.target.checked ? true : undefined,
                  });
                  if (e.target.checked) {
                    searchParams?.append("claimed", e.target.checked);
                    searchParams?.delete("page");
                  } else {
                    searchParams?.delete("claimed");
                  }
                  setSearchParams(searchParams);
                }}
              />
            }
            label={
              <Typography>
                Show only claimed Organisations
                <Tooltip title="Organisations that have been claimed by their owners">
                  <sup>
                    <InfoOutlined
                      sx={{ ml: 1, fontSize: "0.9rem" }}
                      color="info"
                    />
                  </sup>
                </Tooltip>
              </Typography>
            }
          />
        </Box>
        <Box display={"flex"}>
          {currentUser?.accountType === "admin" && (
            <Button
              variant="contained"
              component={Link}
              to="/dashboard/organisations/create"
              startIcon={<CorporateFareTwoTone />}
            >
              Create New Organisation
            </Button>
          )}
        </Box>
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Organisation Name</TableCell>
                <TableCell>Organisation Email</TableCell>
                <TableCell>Organisation Phone</TableCell>
                <TableCell>Organisation Website</TableCell>
                <TableCell>Industry</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        row?.avatar
                          ? process.env.REACT_APP_DO_SPACE_URL +
                            row?.avatar +
                            `?${Math.random()}`
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      textDecoration: !row?.isLocked ? "none" : "line-through",
                      color: !row?.isLocked ? "inherit" : "red",
                    }}
                  >
                    {row?.title}
                  </TableCell>
                  <TableCell>{row?.companyEmail || "-"}</TableCell>
                  <TableCell>{row?.companyPhone || "-"}</TableCell>
                  <TableCell>{row?.companyWebsite || "-"}</TableCell>
                  <TableCell>
                    {row?.industry ? row?.industry?.join(", ") : "-"}
                  </TableCell>
                  <TableCell align="right">
                    <Box display={"flex"} justifyContent="flex-end">
                      {currentUser?.accountType === "admin" &&
                        currentUser?.orgId === row?._id && (
                          <IconButton
                            size="small"
                            component={Link}
                            to={`/dashboard/organisations/${row?._id}/edit`}
                            color="warning"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      <Link
                        to={`/dashboard/organisations/${row?._id}`}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <IconButton
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      </Link>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

              {templates?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No organisation found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {templates?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalPages}
              rowsPerPage={PAGE_LIMIT}
              page={page - 1}
              onPageChange={handlePageChange}
            />
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
