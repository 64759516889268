import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { currencyFormatter } from "src/utils/helpers";
import { useState } from "react";
import EmptyHere from "src/components/EmptyHere";
import { fDateTimeSuffix } from "src/utils/date-fns";

export default function PricingPlanCard({ item }) {
  const FormsList = ({ forms }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => setOpen(true)}
        >
          [forms list]
        </span>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
          <DialogTitle>List of Forms to Rate</DialogTitle>
          <DialogContent>
            {forms?.length > 0 ? (
              <List>
                {forms?.map((item, index) => (
                  <ListItem key={index}>
                    <CheckCircleOutline sx={{ mr: 2 }} color="success" />
                    <ListItemText primary={<b>{item?.title}</b>} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <EmptyHere title={"No Forms found"} />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <Card>
      <CardContent>
        <Stack
          spacing={2}
          mb={3}
          direction="row"
          justifyContent="space-between"
        >
          <Stack spacing={1}>
            <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
              {item?.title}
            </Typography>
            <Typography variant="subtitle2">
              last updated on {fDateTimeSuffix(item?.updatedAt)}
            </Typography>
          </Stack>
          <Stack alignItems={"flex-end"}>
            <Typography variant="h4">
              {currencyFormatter((item?.amountToPay || 0) / item?.quantity)} per
              user
              {item?.duration === "lifetime"
                ? ""
                : item?.duration === "monthly"
                ? "/month"
                : "/year"}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={4} direction={"row"}>
          {item?.type === "plan" &&
            item?.services?.includes("forms") &&
            item?.items?.length > 0 && (
              <Stack spacing={1} direction="row" alignItems="center">
                <CheckCircleOutline sx={{ mr: 1 }} color="success" />
                You've got{" "}
                <b style={{ marginRight: 8 }}>
                  {item?.items?.length || 0} Premium Forms{" "}
                </b>{" "}
                to Rate. <FormsList forms={item?.items} />
              </Stack>
            )}
        </Stack>
      </CardContent>
    </Card>
  );
}
