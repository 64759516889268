import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PRICING_CATEGORIES } from "src/utils/constants";
import { ArrowForwardIos, EditTwoTone } from "@mui/icons-material";
import Label from "src/components/Label";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { currencyFormatter } from "src/utils/helpers";

export default function Pricings() {
  const [loading, setLoading] = useState(false);

  const [pricings, setPricings] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const resp = await axiosInstance.get("/pricing");
      if (resp?.status === 200) {
        setPricings(resp?.data);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Box mx={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Box></Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="outlined"
            component={Link}
            to={"/dashboard/pricing/new"}
            color="warning"
            startIcon={<Add />}
          >
            Add New Pricing Plan
          </Button>{" "}
          <Button
            component={Link}
            to={"/pricing"}
            endIcon={<ArrowForwardIos />}
          >
            Plans Preview
          </Button>
        </Stack>
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Category/Services</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricings?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      maxWidth: "300px",
                    }}
                  >
                    {row?.title || "-"}
                  </TableCell>
                  <TableCell>
                    <Label
                      color={row?.type === "fixed" ? "success" : "warning"}
                    >
                      <b>{row?.type?.toUpperCase()}</b>
                    </Label>
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    {row?.type === "fixed" ? (
                      PRICING_CATEGORIES?.[row?.category]?.label?.toUpperCase()
                    ) : (
                      <>
                        <Typography key={idx} variant="body2">
                          {row?.services?.join(", ")?.toUpperCase() || "-"}
                        </Typography>
                        {/* <Typography key={idx} variant="body2">
                          <b>{row?.quota?.users || "0"}</b>{" "}
                          {PRICING_CATEGORIES?.users?.label}
                        </Typography>
                        <Typography key={idx} variant="body2">
                          <b>{row?.items?.length || "No"}</b> Forms included
                        </Typography> */}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <b>{currencyFormatter(row?.amount || 0)}</b>{" "}
                    {row?.type === "plan" ? "/user" : ""}
                  </TableCell>
                  <TableCell>
                    {row?.duration === "lifetime"
                      ? "-"
                      : row?.duration?.toUpperCase() || "-"}
                  </TableCell>
                  <TableCell align="right">
                    <Box display={"flex"} justifyContent="flex-end">
                      <IconButton
                        size="small"
                        component={Link}
                        to={`/dashboard/pricing/${row?._id}`}
                        color="warning"
                      >
                        <EditTwoTone />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

              {pricings?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Pricing Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
