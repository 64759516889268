import { CloudUpload, Image } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";
import { fileBytesToSize } from "src/utils/helpers";

export default function UploadButton({ category, handleGetDocuments }) {
  const { currentUser } = useContext(GlobalContext);
  const { profile } = useContext(ProfileContext);
  const inputFile = useRef(null);
  const [open, setOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    setFilesToUpload([]);
  }, []);

  const handleUpload = async () => {
    try {
      setIsWait(true);
      const formData = new FormData();
      if (filesToUpload?.length > 0) {
        for (let i = 0; i < filesToUpload?.length; i++) {
          formData.append("files", filesToUpload[i]);
        }
      }
      formData.append("category", category);
      formData.append("fileCategory", "DOCUMENTS");
      formData.append("user", profile?._id);
      const resp = await axiosInstance.post("/documents", formData);
      if (resp.status === 201) {
        Notify.success("Uploaded successfully");
        handleGetDocuments();
        setOpen(false);
        setFilesToUpload([]);
      }

      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Button
        size="small"
        color="secondary"
        sx={{
          border: 1,
          borderColor: "divider",
        }}
        onClick={() => inputFile.current.click()}
        startIcon={<CloudUpload />}
      >
        Upload
        <input
          type="file"
          onChange={(e) => {
            setFilesToUpload(e.target.files);
            setOpen(true);
          }}
          style={{ display: "none" }}
          ref={inputFile}
          accept={category === "images" ? "image/*" : "*"}
          multiple
        />
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Upload {filesToUpload?.length} files to {category}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that you can't delete files after uploading them. Make
            sure you upload the right files.
          </DialogContentText>
          <Divider sx={{ my: 2 }} />
          <List dense>
            {Array.from(filesToUpload).map((file, idx) => {
              return (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <Image />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    secondary={fileBytesToSize(file.size)}
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setFilesToUpload([]);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isWait}
            onClick={handleUpload}
          >
            Upload Now
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
