import { FileDownloadTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import QuestionInputField from "../common/QuestionInputField";
import { useParams } from "react-router";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function FormPreview() {
  const { formId } = useParams();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!formId) {
      Notify.failure("Form not found");
      return;
    }
    handleGetForm(formId);
  }, [formId]);

  const handleGetForm = async (formId) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/forms/${formId}`);
      if (resp?.status === 200) {
        setForm(resp?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack spacing={2} my={2}>
      <Stack spacing={2}>
        <Card
          sx={{
            backgroundColor: "#289BCC",
            color: "#fff",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h2" component="div">
                {form?.title || ""}
              </Typography>
            }
            subheader={form?.description || ""}
            subheaderTypographyProps={{
              color: "white",
            }}
          />
          {form?.organisation && (
            <CardActions sx={{ mx: 1 }}>
              <Divider />
              <Stack direction="row" spacing={2}>
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  Organisation:
                  <Button size="small" color="inherit">
                    {form?.organisation?.title || "Organisation Name"}
                  </Button>
                </Box>
              </Stack>
            </CardActions>
          )}
        </Card>
        {form?.formGroups?.map((group) => (
          <Card key={group?._id}>
            <CardHeader
              title={
                <Typography variant="h3" component="div">
                  {group?.title || ""}
                </Typography>
              }
              subheader={group?.description || ""}
              action={(() => {
                const gtw = group.questionsGroup
                  ?.flatMap((qGroup) =>
                    qGroup.questions?.flatMap((question) => {
                      // if question type is in radio, select, or rating, the get greatest weightage of all options
                      if (
                        question.type === "radio" ||
                        question.type === "select" ||
                        question.type === "rating"
                      ) {
                        return Math.max(
                          ...question.options?.map((option) => option.weightage)
                        );
                      }
                      // if question type is in checkbox, then get sum of all weightages
                      else if (question.type === "checkbox") {
                        return question.options?.reduce(
                          (totalWeightage, option) =>
                            totalWeightage + option.weightage,
                          0
                        );
                      } else {
                        return question.weightage || 0;
                      }
                    })
                  )
                  .reduce((total, weightage) => total + weightage, 0);
                if (gtw === 0) return;
                return (
                  <Tooltip
                    title={"Dimension Weightage: " + gtw}
                    placement="right"
                  >
                    <Typography variant="body2" fontWeight={900}>
                      GTW: {gtw}
                    </Typography>
                  </Tooltip>
                );
              })()}
            />
            <Divider />
            <CardContent>
              <Stack spacing={2}>
                {group?.questionsGroup?.map((questionGroup) => (
                  <Card key={questionGroup?._id}>
                    <CardHeader
                      title={
                        <Typography variant="h4" component="div">
                          {questionGroup?.title || ""}
                        </Typography>
                      }
                      subheader={questionGroup?.description || ""}
                      action={(() => {
                        const tw = questionGroup.questions
                          ?.flatMap((question) => {
                            if (
                              question.type === "radio" ||
                              question.type === "select" ||
                              question.type === "rating"
                            ) {
                              return Math.max(
                                ...question?.options?.map(
                                  (option) => option.weightage
                                )
                              );
                            } else if (question.type === "checkbox") {
                              return question?.options?.map(
                                (option) => option.weightage
                              );
                            } else {
                              return question.weightage || 0;
                            }
                          })
                          .reduce((acc, curr) => acc + curr, 0);
                        if (tw === 0) return;
                        return (
                          <Tooltip
                            title={"Parameter Weightage: " + tw}
                            placement="right"
                          >
                            <Typography variant="body2" fontWeight={600}>
                              TW: {tw}
                            </Typography>
                          </Tooltip>
                        );
                      })()}
                    />
                    <Divider />
                    <CardContent>
                      <Stack spacing={2}>
                        {questionGroup?.questions?.map((question, index) => (
                          <Card key={question?._id} elevation={0}>
                            <CardHeader
                              title={
                                <Typography variant="h5" component="div">
                                  {index + 1}. {question?.title || ""}
                                  {question?.required && (
                                    <span
                                      style={{
                                        color: "red",
                                        marginInline: 3,
                                      }}
                                    >
                                      *
                                    </span>
                                  )}
                                </Typography>
                              }
                              subheader={
                                <>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ mx: 1.5 }}
                                  >
                                    {question?.description || ""}
                                  </Typography>
                                  <Stack direction={"column"} spacing={1}>
                                    {question?.files?.map((file, index) => {
                                      const ext = file.split(".").pop();
                                      if (
                                        ext === "jpg" ||
                                        ext === "jpeg" ||
                                        ext === "png" ||
                                        ext === "gif" ||
                                        ext === "svg"
                                      ) {
                                        return (
                                          <CardMedia
                                            key={index}
                                            component="img"
                                            sx={{
                                              width: "100%",
                                              position: "relative",
                                              height: "200px",
                                              objectFit: "contain",
                                            }}
                                            image={
                                              process.env
                                                .REACT_APP_DO_SPACE_URL +
                                              file +
                                              "?" +
                                              new Date().getTime()
                                            }
                                            alt={question?.placeholder}
                                          />
                                        );
                                      } else {
                                        return (
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            startIcon={<FileDownloadTwoTone />}
                                            component={"a"}
                                            href={
                                              process.env
                                                .REACT_APP_DO_SPACE_URL + file
                                            }
                                            target="_blank"
                                            fullWidth
                                          >
                                            File {index + 1} (
                                            {file.split(".").pop()})
                                          </Button>
                                        );
                                      }
                                    })}
                                  </Stack>
                                </>
                              }
                              action={(() => {
                                if (
                                  ![
                                    "radio",
                                    "checkbox",
                                    "select",
                                    "rating",
                                  ].includes(question?.type)
                                ) {
                                  return;
                                }
                                let weightage = 0;

                                if (
                                  question?.type === "radio" ||
                                  question?.type === "select" ||
                                  question?.type === "rating"
                                ) {
                                  weightage = Math.max(
                                    ...question?.options?.map(
                                      (option) => option.weightage
                                    )
                                  );
                                } else if (question?.type === "checkbox") {
                                  weightage = question?.options?.reduce(
                                    (totalWeightage, option) =>
                                      totalWeightage + option.weightage,
                                    0
                                  );
                                } else {
                                  weightage = question.weightage || 0;
                                }
                                if (weightage === 0) return;

                                return (
                                  <Tooltip
                                    title={"Weightage: " + weightage}
                                    placement="right"
                                  >
                                    <Typography variant="body2">
                                      W: {weightage}
                                    </Typography>
                                  </Tooltip>
                                );
                              })()}
                            />
                            <CardContent sx={{ py: 0, mx: 1.5 }}>
                              <QuestionInputField
                                question={question}
                                handleFormInput={() => {}}
                                formInput={{}}
                                setFormInput={() => {}}
                              />
                            </CardContent>
                          </Card>
                        ))}
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            </CardContent>
            {group?.contextualForms?.length > 0 && (
              <Fragment>
                <Divider sx={{ mt: 3 }}>Contextual Form</Divider>
                <Stack
                  spacing={2}
                  sx={{
                    background: "#f6f6f6",
                    p: 2,
                    px: 3,
                  }}
                >
                  {group?.contextualForms?.map((cForm, index) => (
                    <Card
                      key={cForm?._id}
                      elevation={0}
                      sx={{
                        background: "#f6f6f6",
                      }}
                    >
                      <CardHeader
                        title={
                          <Typography variant="h5" component="div">
                            {index + 1}. {cForm?.title || ""}
                            {cForm?.required && (
                              <span
                                style={{
                                  color: "red",
                                  marginInline: 3,
                                }}
                              >
                                *
                              </span>
                            )}
                          </Typography>
                        }
                        subheader={
                          <Typography variant="subtitle1" sx={{ mx: 1.5 }}>
                            {cForm?.description || ""}
                          </Typography>
                        }
                      />
                      <CardContent sx={{ py: 0, mx: 1.5 }}>
                        <QuestionInputField
                          question={cForm}
                          handleFormInput={() => {
                            console.log("handleFormInput");
                          }}
                          formInput={{}}
                          setFormInput={() => {
                            console.log("setFormInput");
                          }}
                        />
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              </Fragment>
            )}
          </Card>
        ))}
      </Stack>
      {form?.misconductForms?.length > 0 && (
        <Fragment>
          <Divider sx={{ mt: 6 }}>Misconduct Form</Divider>
          <Stack
            spacing={2}
            sx={{
              background: "#f5f5f5",
              p: 2,
              px: 3,
            }}
          >
            {form?.misconductForms?.map((misForm, index) => (
              <Card key={misForm?._id}>
                <CardHeader
                  title={
                    <Typography variant="h5" component="div">
                      {index + 1}. {misForm?.title || ""}
                      {misForm?.required && (
                        <span
                          style={{
                            color: "red",
                            marginInline: 3,
                          }}
                        >
                          *
                        </span>
                      )}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="subtitle1" sx={{ mx: 1.5 }}>
                      {misForm?.description || ""}
                    </Typography>
                  }
                />
                <CardContent sx={{ py: 0, mx: 1.5 }}>
                  <QuestionInputField
                    question={misForm}
                    handleFormInput={() => {
                      console.log("1");
                    }}
                    formInput={{}}
                    setFormInput={() => {
                      console.log("2");
                    }}
                  />
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
}

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
    z-index: 99;
    position: sticky;
    top: 0;
    width: 100%;
`
);
