import { RecordVoiceOverTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import axiosInstance from "src/utils/axiosInstance";

export default function NewCleanExit({ user }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({});

  const handleFormInputs = (e) => {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (
        formInputs?.message &&
        (formInputs?.message?.length < 10 || formInputs?.message?.length > 500)
      ) {
        Notify.failure("Message must be between 10 and 500 characters");
        setLoading(false);
        return;
      }

      const resp = await axiosInstance.post("/cleanexits", {
        ...formInputs,
        user: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
      });
      if (resp?.status === 201) {
        Notify.success("Request sent successfully");
        setLoading(false);
        setOpen(false);
        navigate("/dashboard/cleanexits/" + resp.data.cleanExitId);
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Button
        startIcon={<RecordVoiceOverTwoTone />}
        onClick={() => setOpen(true)}
      >
        Send Request for clean exit
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Request for clean exit</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary">
            Submit your request for clean exit. We will initiate the process
            with your employer and keep you updated.
          </Typography>
          <TextField
            fullWidth
            label="Message (optional)"
            multiline
            rows={4}
            sx={{ my: 2 }}
            variant="outlined"
            name="message"
            onChange={handleFormInputs}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
