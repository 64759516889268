import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, memo, useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import QuestionInputField from "../../common/QuestionInputField";
import { checkIfAllQuestionsAnsweredInFormGroup } from "src/utils/helpers";
import SubHeader from "src/components/SubHeader";

const QCard = ({
  question,
  showNextSection,
  parentNextStep,
  setCurrentQuestion,
  ...rest
}) => {
  useEffect(() => {
    setCurrentQuestion(rest?.currentStep);
  }, [rest?.currentStep]);
  const hasValue = () => {
    if (!question?.required) {
      return true;
    }
    return (
      (rest?.formInput[question?.name] || question?.answer) !== null &&
      (rest?.formInput[question?.name] || question?.answer) !== undefined &&
      (rest?.formInput[question?.name] || question?.answer) !== ""
    );
  };
  return (
    <Card
      // elevation={0}
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <CardHeader
        title={
          <Typography variant="h5" component="div">
            {rest.currentStep}. {question?.title || ""}
            {question?.required && (
              <span
                style={{
                  color: "red",
                  marginInline: 3,
                }}
              >
                *
              </span>
            )}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle1" sx={{ mx: 1.5 }}>
            {question?.description || ""}
          </Typography>
        }
        sx={{
          px: 4,
        }}
      />
      <CardContent sx={{ px: 4, py: 3, pt: 2, flex: 1 }}>
        <QuestionInputField
          question={question}
          handleFormInput={rest.handleFormInput}
          formInput={rest.formInput}
          setFormInput={rest.setFormInput}
          disabled={rest.disabled}
        />
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end", p: 2.5 }}>
        {rest.currentStep !== rest.totalSteps ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!hasValue()}
            onClick={rest.nextStep}
            sx={{
              px: 4,
              py: 1,
              borderRadius: 0.5,
              "&.Mui-disabled": {
                border: "1px solid",
              },
            }}
          >
            Next
          </Button>
        ) : (
          <>
            {showNextSection ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={!hasValue()}
                onClick={parentNextStep}
              >
                Next
              </Button>
            ) : (
              rest.showNextGroup && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!hasValue()}
                  onClick={rest.nextGroup}
                >
                  Next Section
                </Button>
              )
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

const QCardStepWized = ({
  questionGroup,
  showNextSection,
  parentNextStep,
  ...rest
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 3, pb: 0 }}>
        <SubHeader>
          {currentQuestion}/{questionGroup?.questions?.length}
        </SubHeader>
      </Box>
      <StepWizard key={"questionGroup"}>
        {questionGroup?.questions?.map((question, index) => (
          <QCard
            key={question?._id}
            question={question}
            showNextSection={showNextSection}
            parentNextStep={parentNextStep}
            handleFormInput={rest.handleFormInput}
            formInput={rest.formInput}
            setFormInput={rest.setFormInput}
            disabled={rest.disabled}
            showNextGroup={rest.showNextGroup}
            nextGroup={rest.nextGroup}
            setCurrentQuestion={setCurrentQuestion}
          />
        ))}
      </StepWizard>
    </>
  );
};

const QGroupCard = ({ questionGroup, ...rest }) => {
  useEffect(() => {
    console.log("re-render");
  }, []);
  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            {questionGroup?.title || ""}
          </Typography>
        }
        subheader={questionGroup?.description || ""}
        sx={{
          px: 4,
          ".MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        action={
          <SubHeader>
            {rest.currentStep}/{rest.totalSteps}
          </SubHeader>
        }
      />
      <Divider />
      <CardContent
        sx={{
          flex: 1,
          p: 0,
          ":last-child": {
            pb: 0,
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            height: "100%",
            ">div": {
              height: "100%",
              "+div": {
                height: "auto",
              },
              ">div": {
                height: "100%",
                ">div": {
                  height: "100%",
                },
              },
            },
          }}
        >
          <QCardStepWized
            questionGroup={questionGroup}
            showNextSection={rest.currentStep !== rest.totalSteps}
            parentNextStep={rest.nextStep}
            handleFormInput={rest.handleFormInput}
            formInput={rest.formInput}
            setFormInput={rest.setFormInput}
            disabled={rest.disabled}
            showNextGroup={rest.showNextGroup}
            nextGroup={rest.nextGroup}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

function SingleExamGroup({
  group,
  handleFormInput,
  formInput,
  setFormInput,
  disabled = false,
  ...rest
}) {
  const [contextualForms, setContextualForms] = useState([]);

  useEffect(() => {
    const _isAllAnswered = checkIfAllQuestionsAnsweredInFormGroup(
      group,
      formInput
    );
    if (_isAllAnswered) {
      // calculate weightage
      let _finalScore = 0;

      let _group = JSON.parse(JSON.stringify(group));
      const answers = Object.entries(formInput).map(([key, value]) => ({
        key,
        value,
      }));

      if (_group?.questionsGroup?.length > 0) {
        _group?.questionsGroup?.forEach((question) => {
          if (question?.questions?.length > 0) {
            question?.questions?.forEach((item) => {
              item.answer = answers?.find(
                (pair) => pair.key === item.name
              )?.value;

              if (item?.options && item?.options?.length > 0) {
                switch (item?.type) {
                  case "checkbox":
                    item?.options?.map((option) => {
                      if (
                        item?.answer?.includes(option?.label) ||
                        item?.answer?.includes(option?.value)
                      ) {
                        _finalScore = _finalScore + option?.weightage;
                      }
                    });
                    break;
                  case "radio":
                  case "select":
                    item?.options?.map((option) => {
                      if (
                        option?.label === item?.answer ||
                        option?.value === item?.answer
                      ) {
                        _finalScore = _finalScore + option?.weightage;
                      }
                    });
                    break;
                  case "rating":
                    const answerIndex = item?.answer;
                    if (answerIndex || answerIndex >= 0) {
                      _finalScore = _finalScore + answerIndex;
                    }
                    break;

                  default:
                }
              }
            });
          }
        });
      }

      _group?.contextualForms?.forEach((cForm) => {
        if (cForm?.min <= _finalScore && _finalScore <= cForm?.max) {
          // add to contextual forms if not already added
          if (!contextualForms?.find((form) => form?._id === cForm?._id)) {
            setContextualForms((prevState) => [...prevState, cForm]);
          }
        } else {
          // remove from contextual forms if already added
          if (contextualForms?.find((form) => form?._id === cForm?._id)) {
            setContextualForms((prevState) =>
              prevState.filter((form) => form?._id !== cForm?._id)
            );
            // remove answers from formInput if removed from contextual forms
            setFormInput((prevState) => {
              const newState = { ...prevState };
              delete newState[cForm?.name];
              return newState;
            });
          }
        }
      });
    }
  }, [formInput]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={
          <Typography variant="h3" component="div">
            {group?.title || ""}
          </Typography>
        }
        subheader={group?.description || ""}
        sx={{
          p: 4,
          ".MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        action={
          <SubHeader>
            {rest.currentStep}/{rest.totalSteps}
          </SubHeader>
        }
      />
      <Divider />
      <CardContent
        sx={{
          flex: 1,
          p: 0,
          ":last-child": {
            pb: 0,
          },
        }}
      >
        <Stack
          spacing={2}
          sx={{
            height: "100%",
            ">div": {
              height: "100%",
              "+div": {
                height: "auto",
              },
              ">div": {
                height: "100%",
                ">div": {
                  height: "100%",
                },
              },
            },
          }}
        >
          <StepWizard key={"group"}>
            {group?.questionsGroup?.map((questionGroup) => (
              <QGroupCard
                key={questionGroup?._id}
                questionGroup={questionGroup}
                handleFormInput={handleFormInput}
                formInput={formInput}
                setFormInput={setFormInput}
                disabled={disabled || false}
                showNextGroup={rest.currentStep !== rest.totalSteps}
                nextGroup={rest.nextStep}
              />
            ))}
          </StepWizard>
        </Stack>
      </CardContent>
      {contextualForms?.length > 0 && (
        <Fragment>
          <Divider sx={{ mt: 3 }}>Contextual Form</Divider>
          <Stack
            spacing={2}
            sx={{
              background: "#f6f6f6",
              p: 2,
              px: 3,
            }}
          >
            {contextualForms?.map((cForm, index) => (
              <Card
                key={cForm?._id}
                elevation={0}
                sx={{
                  background: "#f6f6f6",
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h5" component="div">
                      {index + 1}. {cForm?.title || ""}
                      {cForm?.required && (
                        <span
                          style={{
                            color: "red",
                            marginInline: 3,
                          }}
                        >
                          *
                        </span>
                      )}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="subtitle1" sx={{ mx: 1.5 }}>
                      {cForm?.description || ""}
                    </Typography>
                  }
                />
                <CardContent sx={{ py: 0, mx: 1.5 }}>
                  <QuestionInputField
                    question={cForm}
                    handleFormInput={handleFormInput}
                    formInput={formInput}
                    setFormInput={setFormInput}
                    disabled={disabled || false}
                  />
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Fragment>
      )}
    </Card>
  );
}

export default SingleExamGroup;
