import { Navigate } from "react-router";
import ChangePassword from "src/content/dashboards/Account/ChangePassword";
import Organisations from "src/content/dashboards/organisations";
import UsersList from "src/content/dashboards/users";
import OrgProfile from "src/content/dashboards/organisations/OrgProfile";
import CreateOrg from "src/content/dashboards/organisations/createOrg";
import ActivityLogs from "src/content/dashboards/Analytics/components/ActivityLogs";
import Forms from "src/content/dashboards/forms";
import JoinRequests from "src/content/dashboards/Requests";
import UserProfile from "src/content/dashboards/profile/UserProfile";
import Dashboard from "src/content/dashboards";
import NewRequest from "src/content/dashboards/Requests/NewRequest";
import SubmitForm from "src/content/dashboards/forms/SubmitForm";
import { ProfileProvider } from "src/contexts/ProfileContext";
import RatingFormDetails from "src/content/dashboards/ratings/RatingFormDetails";
import Groups from "src/content/dashboards/Groups";
import CreateEditGroup from "src/content/dashboards/Groups/CreateEditGroup";
import Management from "src/content/dashboards/Management";
import GroupInfo from "src/content/dashboards/Groups/GroupInfo";
import AddEditUser from "src/content/dashboards/users/AddEditUser";
import About from "src/content/dashboards/profile/about/About";
import Ratings from "src/content/dashboards/ratings";
import UsersBulkUpload from "src/content/dashboards/users/UsersBulkUpload";
import CleanExit from "src/content/dashboards/CleanExit/CleanExit";
import CleanExits from "src/content/dashboards/CleanExit";
import Pricing from "src/content/dashboards/Pricing";
import AddEditPricing from "src/content/dashboards/Pricing/AddEditPricing";
import Orders from "src/content/dashboards/Orders";
import InvoiceDetail from "src/content/pages/Invoice/InvoiceDetail";
import SearchPeople from "src/content/dashboards/SearchPeople";
import FormPreview from "src/content/dashboards/forms/FormPreview";
import EditProfileStepper from "src/content/dashboards/users/EditProfileStepper";
import AllDocuments from "src/content/dashboards/Documents";
import OrderDetails from "src/content/dashboards/Orders/OrderDetails";
import Memberships from "src/content/dashboards/Membership";
import OrgCheckins from "src/content/dashboards/Checkins";
import Events from "src/content/dashboards/Events";
import AddEditEvent from "src/content/dashboards/Events/AddEditEvent";
import Event from "src/content/dashboards/Events/Event";
import EventActions from "src/content/dashboards/Events/EventActions";
import CustomerRelations from "src/content/dashboards/CustomerRelations";
import AddUpdateCustomer from "src/content/dashboards/CustomerRelations/AddUpdateCustomer";
import Campaigns from "src/content/dashboards/Campaigns";
import AddEditCampaign from "src/content/dashboards/Campaigns/AddEditCampaign";
import CampaignActions from "src/content/dashboards/Campaigns/CampaignActions";
import Campaign from "src/content/dashboards/Campaigns/Campaign";
import Explore from "src/content/dashboards/Explore";
import EventsHistory from "src/content/dashboards/Events/EventsHistory";
import CustomerRelationManager from "src/content/dashboards/CustomerRelations/CustomerRelationManager";
import CustomerBulkUpload from "src/content/dashboards/CustomerRelations/CustomerBulkUpload";
import SubmitExam from "src/content/dashboards/exams/SubmitExam";
import Cpew from "src/content/dashboards/Cpew";
import DigitalInternship from "src/content/dashboards/DigitalInternship";
import ManualForms from "src/content/dashboards/ManualForms";
import CareerProtection from "src/content/dashboards/CareerProtection";
import SubmitPoshForm from "src/content/dashboards/ManualForms/SubmitPoshForm";
import SubmittedPoshForm from "src/content/dashboards/ManualForms/SumittedPoshForm";
import MisconductInsights from "src/content/dashboards/MisconductInsight";
import HonestCV from "src/content/dashboards/HonestCV";
import Committees from "src/content/dashboards/Committees";
import BuyCareerProtection from "src/content/dashboards/CareerProtection/BuyCareerProtection";
import AddEditManualForm from "src/content/dashboards/ManualForms/AddEditManualForm";
import NewInvitation from "src/content/dashboards/Invitations/NewInvitaion";
import Invitations from "src/content/dashboards/Invitations";
import OrganisationAssets from "src/content/dashboards/organisations/OrganisationAssets";
import ManageAccounts from "src/content/dashboards/ManageAccounts";

const dashboardsRoutes = [
  {
    path: "",
    element: <Dashboard />,
  },
  {
    path: "explore",
    element: <Explore />,
  },
  {
    path: "logs",
    element: <ActivityLogs />,
  },
  {
    path: "cleanexits",
    children: [
      {
        path: "",
        element: <CleanExits />,
      },
      {
        path: ":cleanExitId",
        element: <CleanExit />,
      },
    ],
  },
  {
    path: "pricing",
    children: [
      { path: "", element: <Pricing /> },
      { path: "new", element: <AddEditPricing /> },
      { path: ":priceId", element: <AddEditPricing /> },
    ],
  },
  {
    path: "orders",
    children: [
      { path: "", element: <Orders /> },
      { path: ":orderId", element: <OrderDetails /> },
      { path: "invoices/:invoiceId", element: <InvoiceDetail /> },
    ],
  },
  {
    path: "requests",
    children: [
      {
        path: "",
        element: <JoinRequests />,
      },
      {
        path: "new",
        element: <NewRequest />,
      },
    ],
  },
  {
    path: "invitations",
    children: [
      {
        path: "",
        element: <Invitations />,
      },
      {
        path: "new",
        element: <NewInvitation />,
      },
    ],
  },
  {
    path: "management",
    element: <Management />,
  },
  {
    path: "documents",
    element: <AllDocuments />,
  },
  {
    path: "memberships",
    element: <Memberships />,
  },
  {
    path: "groups",
    children: [
      {
        path: "",
        element: <Groups />,
      },
      {
        path: "new",
        element: <CreateEditGroup />,
      },
      {
        path: ":groupId",
        element: <GroupInfo />,
      },
      {
        path: ":groupId/edit",
        element: <CreateEditGroup />,
      },
    ],
  },

  {
    path: "forms",
    children: [
      { path: "", element: <Forms /> },
      { path: ":formId", element: <SubmitForm /> },
      { path: ":formId/preview", element: <FormPreview /> },
    ],
  },
  {
    path: "exams",
    children: [
      { path: "", element: <Forms formType={"exam"} /> },
      { path: "submitted", element: <Ratings formType={"exam"} /> },
      { path: ":formId", element: <SubmitExam formType={"exam"} /> },
    ],
  },
  {
    path: "ratings",
    children: [
      {
        path: "",
        element: <Ratings />,
      },
      {
        path: ":ratingId",
        element: <RatingFormDetails />,
      },
    ],
  },
  {
    path: "checkins",
    children: [
      {
        path: "",
        element: <OrgCheckins />,
      },
    ],
  },
  {
    path: "campaigns",
    children: [
      {
        path: "",
        element: <Campaigns />,
      },
      {
        path: "create",
        element: <AddEditCampaign />,
      },
      {
        path: ":campaignId",
        element: <Campaign />,
      },
      {
        path: ":campaignId/:action",
        element: <CampaignActions />,
      },
    ],
  },
  {
    path: "events",
    children: [
      {
        path: "",
        element: <Events />,
      },
      {
        path: "create",
        element: <AddEditEvent />,
      },
      {
        path: "history",
        element: <EventsHistory />,
      },
      {
        path: ":eventId",
        element: <Event />,
      },
      {
        path: ":eventId/:action",
        element: <EventActions />,
      },
    ],
  },
  {
    path: "customer-relations",
    children: [
      {
        path: "",
        element: <CustomerRelations />,
      },
      {
        path: "create",
        element: <AddUpdateCustomer />,
      },
      {
        path: "bulk",
        element: <CustomerBulkUpload />,
      },
      {
        path: "users/:userId",
        element: <CustomerRelationManager />,
      },
      {
        path: ":customerId/edit",
        element: <AddUpdateCustomer />,
      },
    ],
  },
  {
    path: "assets",
    element: <OrganisationAssets />,
  },
  {
    path: "organisations",
    children: [
      {
        path: "",
        element: <Organisations />,
      },
      {
        path: "profile",
        element: <OrgProfile />,
      },
      {
        path: "create",
        element: <CreateOrg />,
      },
      {
        path: ":organisationId",
        element: <OrgProfile />,
      },
      {
        path: ":organisationId/assets",
        element: <OrganisationAssets />,
      },
      {
        path: ":organisationId/edit",
        element: <CreateOrg />,
      },
    ],
  },
  {
    path: "profile",
    children: [
      {
        path: "",
        element: <UserProfile />,
      },
      {
        path: "search",
        element: <SearchPeople />,
      },
    ],
  },
  {
    path: "cpew",
    children: [
      {
        path: "",
        element: <Cpew />,
      },
    ],
  },
  {
    path: "digital-internship",
    children: [
      {
        path: "",
        element: <DigitalInternship />,
      },
    ],
  },
  {
    path: "misconduct-insights",
    element: <MisconductInsights />,
  },

  {
    path: "honest-cv",
    element: <HonestCV />,
  },

  {
    path: "manualforms",
    children: [
      {
        path: "",
        element: <></>,
      },
      {
        path: ":formType",
        element: <AddEditManualForm />,
      },
    ],
  },
  {
    path: "committees",
    children: [
      {
        path: "",
        element: <Committees />,
      },
      {
        path: ":committeeId",
        children: [
          {
            path: "",
            element: <ManualForms />,
          },
          {
            path: "submit",
            element: <SubmitPoshForm />,
          },
          {
            path: ":submissionId",
            element: <SubmittedPoshForm />,
          },
        ],
      },
    ],
  },

  {
    path: "career-protection",
    children: [
      {
        path: "",
        element: <CareerProtection />,
      },
      {
        path: ":organisationId",
        element: <BuyCareerProtection />,
      },
    ],
  },
  {
    path: "users",
    children: [
      {
        path: "",
        element: <UsersList whichPage="dashboard" />,
      },
      {
        path: "new",
        element: <AddEditUser />,
      },
      {
        path: "bulk",
        element: <UsersBulkUpload />,
      },
      {
        path: ":userId",
        element: (
          <ProfileProvider>
            <UserProfile />
          </ProfileProvider>
        ),
      },
      { path: ":userId/edit", element: <AddEditUser /> },
    ],
  },
  {
    path: "account",
    children: [
      {
        path: "settings",
        element: (
          <ProfileProvider>
            <About page="settings" />
          </ProfileProvider>
        ),
      },
      {
        path: "profile/edit",
        element: <AddEditUser page="profile" />,
      },
      {
        path: "profile/edit/complete",
        element: (
          <ProfileProvider>
            <EditProfileStepper page="profile" />
          </ProfileProvider>
        ),
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },

  {
    path: "accounts",
    children: [
      {
        path: "",
        element: <Navigate to="/dashboard/accounts/users" />,
      },
      {
        path: ":accountType",
        element: <ManageAccounts />,
      },
    ],
  },
];

export default dashboardsRoutes;
