import {
  SaveTwoTone,
  SecurityTwoTone,
  WarningAmberRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  alpha,
  Autocomplete,
  Box,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";
import QuestionInputFieldNew from "src/content/dashboards/common/QuestionInputFieldNew";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";

export default function SubmitManualForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { committeeId } = useParams();
  const { currentUser, handleCheckPaymentOnly } = useContext(GlobalContext);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(true);
  const [isWait, setIsWait] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [hasCareerProtection, setHasCareerProtection] = useState(false);

  const [formInput, setFormInput] = useState({ other: {} });

  const handleFormInput = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const getForm = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/manualforms/${committeeId}`);
      if (resp?.status === 200) {
        setForm(resp.data?.data || {});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    (async () => {
      const resp = await axiosInstance.get(`/organisations/all`, {
        params: {
          isClaimed: true,
        },
      });
      if (resp?.status === 200) {
        setOrganisations(resp?.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    getForm();
  }, []);

  const handleCheckCareerProtection = async (orgId) => {
    try {
      if (!orgId) return false;
      setIsWait(true);
      const hasPaid = await handleCheckPaymentOnly({
        item: "joinOrganisations",
        itemId: orgId,
      });
      setIsWait(false);
      return hasPaid;
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  const handleSubmitForm = async () => {
    try {
      const { other, ...answers } = formInput || {};
      const { organisation, organisationName } = other || {};

      if (
        form?.organisationRequired &&
        ((organisation === "other" && !organisationName) || !organisation)
      ) {
        Notify.failure("Please select or Enter an organisation");
        return;
      }

      const reqObj = {
        manualform: form?._id,
        answers,
        organisation,
        organisationName,
        committeeId: committeeId,
      };

      if (reqObj?.organisation === "other") {
        delete reqObj.organisation;
      } else {
        delete reqObj.organisationName;
      }

      if (form?.organisationRequired && !organisation && !organisationName) {
        Notify.failure("Please select or Enter an organisation");
        return;
      }

      // check if any required field is empty
      const requiredFields = form?.questions?.filter(
        (question) => question?.required
      );

      const emptyFields = requiredFields.filter(
        (field) => !formInput[field?.name]
      );

      if (emptyFields.length > 0) {
        Notify.failure("Please fill all required fields");
        return;
      }

      setIsWait(true);

      const resp = await axiosInstance.post("/submissions", reqObj);
      if (resp?.status === 201) {
        if (form?.careerProtectionRequired && !hasCareerProtection) {
          // after successful payment redirect to
          let redirectLink = `/dashboard/committees/${committeeId}/${resp?.data?.data?._id}`;
          if (resp?.data?.data?.organisation) {
            navigate(
              `/dashboard/career-protection/${resp?.data?.data?.organisation}?redirectLink=${redirectLink}`
            );
          } else {
            navigate(
              `/dashboard/career-protection/open?meta=${JSON.stringify({
                organisationName: organisationName,
              })}&redirectLink=${redirectLink}`
            );
          }
          return;
        }
        Notify.success("Form submitted successfully");
        setTimeout(() => {
          navigate(
            `/dashboard/committees/${committeeId}/${resp?.data?.data?._id}`
          );
        }, 1000);

        setIsWait(false);
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleSelectOrganisation = async (e, v) => {
    if (v?._id === "other") {
      setFormInput({
        ...formInput,
        other: {
          ...formInput?.other,
          organisation: "other",
          organisationName: "",
        },
      });
      setHasCareerProtection(false);
      return;
    } else {
      setFormInput({
        ...formInput,
        other: {
          organisation: v?._id,
          organisationName: "",
        },
      });
    }

    // check if user has career protection
    const checkCareerProtection = await handleCheckCareerProtection(v?._id);
    setHasCareerProtection(checkCareerProtection);
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box my={2} mb={6}>
      <Card>
        <CardContent sx={{ p: 4 }}>
          <h1 style={{ marginTop: 0 }}>{form?.title || "Complaint form"} </h1>
          {form?.warning && (
            <Alert
              sx={{
                my: 4,
                background: alpha(theme.palette.warning.main, 0.05),
              }}
              icon={<WarningAmberRounded />}
              severity="warning"
              variant="outlined"
            >
              {form?.warning}
            </Alert>
          )}
          <Stack spacing={4} my={3} mb={4}>
            <Stack direction="row" spacing={2}>
              {form?.organisationRequired && (
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      mb: 1,
                    }}
                  >
                    Select Organisation
                    <Typography
                      sx={{ color: theme.palette.error.main }}
                      variant="span"
                    >
                      {" "}
                      *
                    </Typography>
                  </Typography>

                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={[
                      ...(organisations || []),
                      { _id: "other", title: "Other" },
                    ]}
                    getOptionLabel={(option) => option?.title}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        borderRadius: 0,
                        backgroundColor: theme.palette.background.default,
                        "&.Mui-focused": {
                          backgroundColor: theme.palette.background.default,
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        // variant="filled"
                        {...params}
                        // InputProps={{
                        //   sx: {
                        //     borderRadius: 0,
                        //     backgroundColor: theme.palette.background.default,
                        //     "&.Mui-focused": {
                        //       backgroundColor: theme.palette.background.default,
                        //     },
                        //   },
                        // }}
                      />
                    )}
                    onChange={handleSelectOrganisation}
                  />
                </Box>
              )}
              {form?.organisationRequired &&
                formInput?.other?.organisation === "other" && (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        mb: 1,
                      }}
                    >
                      Organisation Name
                      <Typography
                        sx={{ color: theme.palette.error.main }}
                        variant="span"
                      >
                        {" "}
                        *
                      </Typography>
                    </Typography>

                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={"Enter organisation name"}
                      value={formInput?.other?.organisationName}
                      name="organisationName"
                      onBlur={async (e) => {
                        if (e.target.value) {
                          const checkCareerProtection =
                            await handleCheckCareerProtection(e.target.value);
                          setHasCareerProtection(checkCareerProtection);
                        }
                      }}
                      onChange={(e) => {
                        setFormInput({
                          ...formInput,
                          other: {
                            ...formInput?.other,
                            organisationName: e.target.value,
                          },
                        });
                      }}
                    />
                  </Box>
                )}
            </Stack>
            {form?.questions?.map((question, index) => (
              <QuestionInputFieldNew
                key={index}
                question={question}
                formInput={formInput}
                setFormInput={setFormInput}
                handleFormInput={handleFormInput}
                // disabled={disabled || false}
              />
            ))}

            {form?.alert && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{
                  background: alpha(theme.palette.error.main, 0.01),
                }}
              >
                {form?.alert}
              </Alert>
            )}

            {form?.careerProtectionRequired && (
              <Alert severity="warning" variant="outlined">
                Please note Career Protection is required to submit this form.
                You will be redirected to add Career Protection after submitting
                this form.
              </Alert>
            )}
          </Stack>
          <Stack my={2} direction={"row"} justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              color="primary"
              size="large"
              sx={{ px: 12 }}
              disabled={isWait}
              onClick={() => {
                Confirm.show(
                  "Confirmation",
                  "Make sure you have filled all the fields correctly. Once submitted, you will not be able to edit the form. Are you sure you want to submit?",
                  "Confirm",
                  "Cancel",
                  () => handleSubmitForm(),
                  () => {},
                  {
                    width: "500px",
                    messageMaxLength: 1000,
                  }
                );
                // .notiflix-confirm, add style to class
                const notiflixConfirm = document.querySelector(
                  ".notiflix-confirm-content"
                );

                // Add styles to the element
                if (notiflixConfirm) {
                  notiflixConfirm.style.padding = "32px";
                }
              }}
              startIcon={
                form?.careerProtectionRequired && !hasCareerProtection ? (
                  <SecurityTwoTone />
                ) : (
                  <SaveTwoTone />
                )
              }
            >
              {form?.careerProtectionRequired && !hasCareerProtection
                ? "Get Career Protection & Proceed"
                : "Submit Now"}
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
