import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ScoreCircularBar from "../../common/ScoreCircularBar";
import {
  AccountCircleOutlined,
  CloudDownloadOutlined,
  HelpOutline,
  KeyboardArrowDown,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import { ProfileContext } from "src/contexts/ProfileContext";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Link } from "react-router-dom";
import { ProfileStregthList } from "../components/ProfileStregth";
import ProfilePublish from "../addEdit/ProfilePublish";
import { MAX_SCORE } from "src/utils/constants";

const scoreList = [
  {
    label: "Exceptional",
    value: "751 - 1000",
    color: "success.main",
  },
  {
    label: "Above Average",
    value: "501 - 750",
    color: "info.main",
  },

  {
    label: "Average",
    value: "251 - 500",
    color: "warning.main",
  },
  {
    label: "Needs Improvement",
    value: "<250",
    color: "error.main",
  },
];

export const UserSummaryActions = ({ profile, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box onClick={handleClick}>{children}</Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ProfilePublish sx={{ borderRadius: "10px" }} />

        <MenuList>
          <MenuItem
            component={Link}
            to={`/reports/user/${profile?._id}`}
            onClick={handleClose}
            target="_blank"
          >
            <ListItemIcon>
              <AccountCircleOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Profile Summary</ListItemText>
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/reports/user/${profile?._id}?print=true`}
            onClick={handleClose}
            target="_blank"
          >
            <ListItemIcon>
              <CloudDownloadOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download PDF</ListItemText>
          </MenuItem>{" "}
          <MenuItem
            component={Link}
            to={`/${profile?.cleanExitUserId}`}
            onClick={handleClose}
            target="_blank"
          >
            <ListItemIcon>
              <RemoveRedEyeOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>View as Public </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default function UserSummary() {
  const { profile, percentComplete, inCompleteFields, viewAsPublic } =
    useContext(ProfileContext);
  const { currentUser } = useContext(GlobalContext);

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title={`Hi, I'm ${profile?.firstName} ${profile?.lastName}`}
        action={(() => {
          return (
            <Stack direction={"row"} spacing={0}>
              {currentUser?.userId === profile?._id &&
                percentComplete < 100 &&
                !viewAsPublic && (
                  <Tooltip
                    title={
                      <ProfileStregthList inCompleteFields={inCompleteFields} />
                    }
                  >
                    <Button
                      color={
                        percentComplete < 50
                          ? "error"
                          : percentComplete < 80
                          ? "warning"
                          : "primary"
                      }
                      variant={"outlined"}
                      size="small"
                      component={Link}
                      to={
                        currentUser?.userId === profile?._id
                          ? `/dashboard/account/profile/edit`
                          : `/dashboard/users/${profile?._id}/edit`
                      }
                    >
                      {"Profile Strength: " + percentComplete + "%"}
                    </Button>
                  </Tooltip>
                )}
              {!viewAsPublic && (
                <UserSummaryActions profile={profile}>
                  <Button
                    color="primary"
                    size="small"
                    endIcon={<KeyboardArrowDown />}
                  >
                    Actions
                  </Button>
                </UserSummaryActions>
              )}
            </Stack>
          );
        })()}
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid
            item
            xs={6.5}
            sx={{
              p: 2,
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography component={"span"}>
                <Typography variant="h1" component={"span"}>
                  <b>{profile?.employeeScore || MAX_SCORE}</b>
                </Typography>
                {!(
                  profile?.employeeScore === MAX_SCORE ||
                  profile?.employeeScore === 0
                ) && <sub>/{MAX_SCORE}</sub>}
              </Typography>
              <Typography variant="subtitle1" fontSize={"1rem"} mt={1}>
                {!profile?.employeeRatingsCount ||
                profile?.employeeRatingsCount === 0 ? (
                  "Total average rating across all organisations"
                ) : (
                  <>
                    Average score accumulated, out of <b>{MAX_SCORE} </b> from{" "}
                    <b>{profile?.employeeRatingsCount || 0} Feedbacks</b> across{" "}
                    <b>
                      {profile?.employeeRatingOrganisationsCount || 0}{" "}
                      organisations
                    </b>
                    .
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5.5}>
            <Box sx={{ position: "relative" }}>
              <Tooltip
                title={
                  <Stack spacing={1}>
                    {scoreList.map((item, index) => (
                      <Typography key={index} color={item?.color}>
                        {"  "} {item?.label} ({item?.value})
                      </Typography>
                    ))}
                  </Stack>
                }
                placement="left-end"
              >
                <HelpOutline
                  fontSize="small"
                  color="secondary"
                  sx={{ position: "absolute", top: "0", right: "0" }}
                />
              </Tooltip>
            </Box>

            <ScoreCircularBar />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
