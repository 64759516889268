import {
  ArrowForwardIos,
  CalculateOutlined,
  CardMembershipTwoTone,
  CheckCircleOutline,
  TimerOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";
import { calculateTimeRemain } from "src/utils/helpers";

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
`
);

export default function ExamInstructions({ item, userId }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const examId = searchParams.get("exam");
  const { handleCheckPayment } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log(examId, item?._id);
    if (examId && item?._id === examId) {
      setOpen(true);
    }
  }, [examId]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<ArrowForwardIos />}
        onClick={() => setOpen(true)}
      >
        Select
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent sx={{ my: 3 }}>
            <Box>
              <Typography sx={{ mb: 1 }} variant="h1">
                {item?.title}
              </Typography>
              <Typography variant="h4" color={"text.secondary"}>
                {t(
                  "Enhanced Online Exam Features for a Seamless Assessment Experience"
                )}
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={7}>
                  <List>
                    {[
                      "Strictly timed exams with a visible countdown for each question.",
                      "Randomized questions for unique assessments, including multiple-choice questions.",
                      "Instant scoring with answers after submitting the test.",
                      "Advanced anti-cheating measures to maintain exam integrity.",
                      "Immediate results after submitting the test for quick feedback.",
                    ].map((item, idx) => (
                      <ListItem key={idx}>
                        <AvatarSuccess sx={{ mr: 2 }}>{idx + 1}</AvatarSuccess>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={5}>
                  <Card>
                    <CardContent>
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        spacing={2}
                        mt={2}
                      >
                        {item?.duration && item?.duration > 0 ? (
                          <Button
                            color="secondary"
                            startIcon={<TimerOutlined color="info" />}
                          >
                            <Typography variant="h3">
                              <Typography variant="h4" component="span">
                                Total Duration:
                              </Typography>{" "}
                              {calculateTimeRemain(item?.duration * 60)}
                            </Typography>
                          </Button>
                        ) : (
                          ""
                        )}
                        {item?.attempts && item?.attempts > 0 ? (
                          <Button
                            color="secondary"
                            startIcon={<CalculateOutlined color="error" />}
                          >
                            <Typography variant="h3">
                              <Typography variant="h4" component="span">
                                Maximum Attempts allowed:
                              </Typography>{" "}
                              {item?.attempts}
                            </Typography>
                          </Button>
                        ) : (
                          ""
                        )}
                        {item?.passingPercentage &&
                        item?.passingPercentage > 0 ? (
                          <Button
                            color="secondary"
                            startIcon={<CheckCircleOutline color="success" />}
                          >
                            <Typography variant="h3">
                              <Typography variant="h4" component="span">
                                Minimum Passing Percentage:
                              </Typography>{" "}
                              {item?.passingPercentage}%
                            </Typography>
                          </Button>
                        ) : (
                          ""
                        )}

                        {item?.certificate?.certificateId ? (
                          <Button
                            color="secondary"
                            startIcon={
                              <CardMembershipTwoTone color="warning" />
                            }
                          >
                            <Typography variant="h3">
                              <Typography variant="h4">
                                Get <b>"{item?.certificate?.title || ""}"</b>{" "}
                                certificate
                              </Typography>
                            </Typography>
                          </Button>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ my: 2 }}>
              <Alert severity="warning">
                <Typography variant="h4">
                  Please note that you will not be able to return to this
                  section once you proceed.
                </Typography>
                <Typography variant="h6">
                  Do not refresh the page, reload, navigate or close the browser
                  window. Doing so will result in an automatic submission of
                  your exam. Best of luck!
                </Typography>
              </Alert>
            </Box>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={async () => {
                if (item?.premium && item?.amount > 0) {
                  const hasPaid = await handleCheckPayment(
                    {
                      itemId: item?._id,
                      item: "exams",
                    },
                    {
                      redirectLink: `/dashboard/exams/${item?._id}?receiverId=${userId}`,
                      itemId: item?._id,
                    }
                  );
                  if (hasPaid) {
                    window.open(
                      `/exams/${item?._id}?receiverId=${userId}`,
                      "_blank"
                    );
                    // window.location.href = `/exams/${item?._id}?receiverId=${userId}`;
                  }
                } else {
                  window.open(
                    `/exams/${item?._id}?receiverId=${userId}`,
                    "_blank"
                  );
                  // window.location.href = `/exams/${item?._id}?receiverId=${userId}`;
                }
              }}
            >
              {t("Start Exam")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
