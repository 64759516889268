import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AddEditCampaign from "./AddEditCampaign";
import AddRemoveFormInCampaign from "./components/AddRemoveFormInCampaign";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import SendCampaign from "./components/SendCampaign";

export default function CampaignActions() {
  let { campaignId, action } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/events/${campaignId}`);
        if (resp?.status === 200) {
          setEvent(resp?.data?.data || {});
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    })();
  }, [campaignId]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <>
      {
        {
          edit: <AddEditCampaign event={event} setEvent={setEvent} />,
          forms: <AddRemoveFormInCampaign event={event} setEvent={setEvent} />,
          send: <SendCampaign event={event} />,
        }[action]
      }
    </>
  );
}
