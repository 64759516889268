import { Fragment, useContext, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import { GlobalContext } from "src/contexts/GlobalContext";
import {
  PeopleAltTwoTone,
  PlaceTwoTone,
  SettingsTwoTone,
  VpnKeyTwoTone,
} from "@mui/icons-material";
import SwitchAccount from "src/content/dashboards/Account/SwitchAccount";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(0, 0.5)};
    height: ${theme.spacing(6)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     top: 0,
//     right: 0,
//   },
// }));

function HeaderUserbox() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { logout, currentUser } = useContext(GlobalContext);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {/* <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="dot"
          color="success"
          invisible={badgeVisible}
        > */}
        <Avatar
          variant="rounded"
          alt={currentUser?.firstName}
          src={
            currentUser?.profileImage
              ? process.env.REACT_APP_DO_SPACE_URL +
                currentUser?.profileImage +
                `?${Math.random()}`
              : "static/images/avatars/2.png"
          }
        />
        {/* </StyledBadge> */}
        <Box
          component="span"
          sx={{
            display: { xs: "none", md: "inline-block" },
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {currentUser?.firstName + " " + currentUser?.lastName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {currentUser?.email}
            </UserBoxDescription>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1,
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 280,
          }}
          display="flex"
        >
          <Avatar
            variant="rounded"
            alt={currentUser?.firstName}
            src={
              currentUser?.profileImage
                ? process.env.REACT_APP_DO_SPACE_URL +
                  currentUser?.profileImage +
                  `?${Math.random()}`
                : "static/images/avatars/2.png"
            }
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {currentUser?.firstName + " " + currentUser?.lastName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {currentUser?.email}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <List sx={{ p: 1 }} component="nav">
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={`/dashboard`}
            component={NavLink}
          >
            <PeopleAltTwoTone fontSize="small" />
            <ListItemText primary={t("Dashboard")} />
          </ListItem>
          {currentUser?.role === "user" && (
            <ListItem
              onClick={() => {
                handleClose();
              }}
              button
              to={`/dashboard/checkins`}
              component={NavLink}
            >
              <PlaceTwoTone fontSize="small" />
              <ListItemText primary={t("Checkin History")} />
            </ListItem>
          )}
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={"/dashboard/account/change-password"}
            component={NavLink}
          >
            <VpnKeyTwoTone fontSize="small" />
            <ListItemText primary={t("Change Password")} />
          </ListItem>
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={"/dashboard/account/settings"}
            component={NavLink}
          >
            <SettingsTwoTone fontSize="small" />
            <ListItemText primary={t("Settings")} />
          </ListItem>
        </List>
        <Divider />
        <Box m={1}>
          {["admin", "triager", "owner"]?.includes(
            currentUser?.accountType
          ) ? null : (
            <Fragment>
              <SwitchAccount />
              <Divider />
            </Fragment>
          )}
          <Button
            color="primary"
            sx={{
              justifyContent: "flex-start",
            }}
            fullWidth
            onClick={handleLogout}
          >
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {t("Sign out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
