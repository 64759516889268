import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { GlobalContext } from "src/contexts/GlobalContext";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function Logout() {
  const [done, setDone] = useState(false);

  const { logout } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      setDone(false);
      await logout();
      setDone(true);
    })();
  }, []);

  return done ? <Navigate to="/auth/login" replace /> : <SuspenseLoader />;
}
