import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import ScoreCircularBar from "../../common/ScoreCircularBar";
import { EmojiEventsOutlined } from "@mui/icons-material";
import { ProfileContext } from "src/contexts/ProfileContext";
import { MAX_SCORE } from "src/utils/constants";

export default function ScoreSection() {
  const { profile } = useContext(ProfileContext);

  const scoreList = [
    {
      label: "Exceptional",
      value:
        "751 to 1000: Demonstrates exceptional wisdom and professional ethics.",
      color: "success.main",
    },
    {
      label: "Above Average",
      value:
        "501 to 750: Displays commendable ethics and a positive behavioral attitude.",
      color: "info.main",
    },

    {
      label: "Average",
      value:
        "Represents standard ethical adherence, with room for enhancement.",
      color: "warning.main",
    },
    {
      label: "Needs Improvement",
      value:
        "<250: Indicates areas that could benefit from additional focus on ethical behavior and conduct.",
      color: "error.main",
    },
  ];
  return (
    <Card
      elevation={0}
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: (theme) => theme.palette.info.dark,
          color: "white",
          p: 1,
        }}
        title={
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <EmojiEventsOutlined />
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              EthixFirst Scoring
            </Typography>
          </Stack>
        }
      />
      <CardContent>
        <Typography color={"warning.main"} fontStyle={"italic"}>
          EthixFirst Score is calculated based on certain criterias and is a
          measure of the companys' overall performance (0 - 1000).
        </Typography>
        <Stack mt={4} direction={"row"} alignItems={"center"}>
          <Stack
            sx={{
              width: "35%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "400px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <ScoreCircularBar />
            </Box>

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography component={"span"}>
                <Typography variant="h1" component={"span"}>
                  <b>{profile?.employeeScore || MAX_SCORE}</b>
                </Typography>
                {!(
                  profile?.employeeScore === MAX_SCORE ||
                  profile?.employeeScore === 0
                ) && <sub>/{MAX_SCORE}</sub>}
              </Typography>
              <Typography variant="subtitle1" mt={1}>
                {!profile?.employeeRatingsCount ||
                profile?.employeeRatingsCount === 0 ? (
                  "Total average rating across all organisations"
                ) : (
                  <>
                    Accumulated{" "}
                    <b>{profile?.employeeRatingsCount || 0} Feedbacks</b> across
                    <br />{" "}
                    <b>
                      {profile?.employeeRatingOrganisationsCount || 0}{" "}
                      Organisations
                    </b>
                    .
                  </>
                )}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              p: 3,
              position: "relative",
              border: (theme) => `1px solid ${theme.palette.grey[300]}`,
              width: "65%",
            }}
          >
            <Typography color={"secondary.main"} fontStyle={"italic"} mb={3}>
              <b>EthixFirst Rating Description</b>
            </Typography>
            <Grid container>
              <Grid item xs={3}>
                {scoreList.map((item, index) => (
                  <Typography key={index} sx={{ mb: 2 }} color={item?.color}>
                    <b>
                      {index + 1}. {item?.label} :
                    </b>
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={9}>
                {scoreList.map((item, index) => (
                  <Typography key={index} sx={{ mb: 2 }}>
                    {item?.value || "-"}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
