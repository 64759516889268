import {
  alpha,
  Box,
  Card,
  Grid,
  Avatar,
  CardMedia,
  Typography,
  Button,
  styled,
  useTheme,
  Divider,
  Chip,
  Stack,
  Paper,
  InputBase,
  IconButton,
  Pagination,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import {
  AddTwoTone,
  ArrowForwardIos,
  ArrowForwardIosOutlined,
  PlaceTwoTone,
  Search,
} from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PAGE_LIMIT } from "src/utils/constants";
import axiosInstance from "src/utils/axiosInstance";
import { format } from "date-fns";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import EmptyHere from "src/components/EmptyHere";

export default function EventsHistory({ organisationId }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [loading, setLoading] = useState(true);
  const [filterObj, setFilterObj] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [events, setEvents] = useState({});
  const [organisation, setOrganisation] = useState(null);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    setOrganisation(organisationId || currentUser?.orgId);
  }, [currentUser, organisationId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
          eventHistory: "true",
        };
      }

      if (organisation) {
        reqParams.params.organisation = organisation;
      }

      const resp = await axiosInstance.get("/customer-relations", reqParams);
      if (resp?.status === 200) {
        setEvents(resp?.data || {});
      }
      setLoading(false);
    })();
  }, [page, filterObj]);

  return (
    <Box>
      {loading ? (
        <SuspenseLoader />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: 3, pb: 2 }}
          >
            <Stack direction="row" spacing={2}>
              <Box fullWidth>
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 500,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search here..."
                    inputProps={{ "aria-label": "search here" }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        if (e.target.value.length) {
                          setFilterObj({ ...filterObj, search: searchString });
                        } else {
                          setFilterObj({ ...filterObj, search: null });
                        }
                        setPage(1);
                        setSearchParams({
                          ...Object.fromEntries(searchParams.entries()),
                          page: 1,
                        });
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      if (e.target.value?.length > 0) {
                        setSearchString(e.target.value);
                      } else {
                        setFilterObj({
                          ...filterObj,
                          search: null,
                        });
                      }
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{
                      p: "10px",
                      m: 0.2,
                      bgcolor: "primary.main",
                      color: "white",
                    }}
                    onClick={() => {
                      setFilterObj({
                        ...filterObj,
                        search: searchString,
                      });
                      setPage(1);
                      setSearchParams({
                        ...Object.fromEntries(searchParams.entries()),
                        page: 1,
                      });
                    }}
                  >
                    <Search />
                  </IconButton>
                </Paper>
              </Box>
              {currentUser?.role === "user" && (
                <Button
                  size="sm"
                  component={Link}
                  to={`/dashboard/events`}
                  endIcon={<ArrowForwardIos fontSize="small" />}
                >
                  View upcoming events
                </Button>
              )}
            </Stack>
          </Stack>
          <Grid container spacing={4}>
            {events?.data?.map((item, index) => (
              <Grid item xs={12} md={4} sm={6} key={index}>
                <Card
                  sx={{
                    transition: `${theme.transitions.create([
                      "box-shadow",
                      "transform",
                      "border-radius",
                    ])}`,
                    transform: "translateY(0px)",

                    "&:hover": {
                      transform: `translateY(-${theme.spacing(1)})`,
                      boxShadow: `0 2rem 8rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.05
                      )}, 
                    0 0.6rem 1.6rem ${alpha(
                      theme.colors.alpha.black[100],
                      0.15
                    )}, 
                    0 0.2rem 0.2rem ${alpha(
                      theme.colors.alpha.black[100],
                      0.1
                    )}`,
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      p: 1,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={
                        item?.event?.coverImage
                          ? process.env.REACT_APP_DO_SPACE_URL +
                            (item?.event?.coverImage + "?" + Math.random())
                          : "/static/images/placeholders/illustrations/wireframe.svg"
                      }
                      alt=""
                      sx={{
                        borderRadius: `${theme.general.borderRadiusLg}`,
                        background: "#9AC977",
                      }}
                    />
                    <CardActions
                      sx={{
                        bottom: "auto",
                        top: `${theme.spacing(2)}`,
                        right: "auto",
                        left: `${theme.spacing(2)}`,
                      }}
                    >
                      <Stack spacing={1}>
                        <LabelWrapper
                          sx={{
                            background: `${theme.colors.alpha.trueWhite[100]}`,
                            color: `${theme.colors.alpha.black[100]}`,
                          }}
                        >
                          {item?.event?.startDateTime
                            ? format(
                                new Date(item?.event?.startDateTime),
                                "hh:mm a"
                              )
                            : ""}{" "}
                          -{" "}
                          {item?.event?.endDateTime
                            ? format(
                                new Date(item?.event?.endDateTime),
                                "hh:mm a"
                              )
                            : ""}
                        </LabelWrapper>
                      </Stack>
                    </CardActions>
                  </Box>
                  <Stack sx={{ px: 3 }} spacing={2}>
                    <Stack
                      direction="row"
                      pt={2}
                      spacing={3}
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AccessTimeTwoToneIcon
                          fontSize="small"
                          color="success"
                          sx={{
                            opacity: 0.7,
                            mr: 0.5,
                          }}
                        />
                        {item?.event?.startDateTime &&
                          format(
                            new Date(item?.event?.startDateTime),
                            "dd MMM"
                          )}{" "}
                        -{" "}
                        {item?.event?.endDateTime &&
                          format(new Date(item?.event?.endDateTime), "dd MMM")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PlaceTwoTone
                          color={"info"}
                          fontSize="small"
                          sx={{
                            opacity: 0.7,
                            mr: 0.5,
                          }}
                        />
                        {item?.event?.isVirtual ? (
                          "Virtual"
                        ) : (
                          <>
                            {item?.event?.address?.city}
                            {!!item?.event?.address?.city && ","}{" "}
                            {item?.event?.address?.state ||
                              item?.event?.address?.country ||
                              ""}
                          </>
                        )}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "1.3rem",
                      }}
                      component={Link}
                      to={`/dashboard/events/${item?.event?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {item?.event?.title}
                    </Typography>

                    <Box pt={1}>
                      {item?.event?.tags?.map((value) => {
                        return (
                          <ChipWrapper
                            key={value}
                            color="secondary"
                            label={value}
                            sx={{
                              background: theme.colors.alpha.black[10],
                            }}
                          />
                        );
                      })}
                    </Box>
                    <Divider />
                    <Stack spacing={1} direction={"row"}>
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: 50,
                          height: 50,
                          boxShadow: "none",
                          borderWidth: 5,
                          background: `${theme.colors.alpha.black[90]}`,
                          border: `${theme.colors.alpha.white[100]} solid 3px`,
                        }}
                        src={
                          item?.organisation?.avatar
                            ? process.env.REACT_APP_DO_SPACE_URL +
                              (item?.organisation?.avatar + "?" + Math.random())
                            : ""
                        }
                      />
                      <Box>
                        <Typography variant="h4">
                          {item?.organisation?.title || ""}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            mt: 0.5,
                          }}
                        >
                          {item?.organisation?.companyWebsite || ""}
                        </Typography>
                      </Box>
                    </Stack>

                    <Divider />
                    <Box pb={2}>
                      <Button
                        fullWidth
                        variant="outlined"
                        endIcon={<ArrowForwardIosOutlined />}
                        component={Link}
                        to={`/dashboard/events/${item?.event?._id}`}
                      >
                        {t("View details")}
                      </Button>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            ))}
            {events?.data?.length === 0 && (
              <EmptyHere title={"No Event found"} />
            )}
          </Grid>

          {!loading && events?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={events?.totalPages || 0}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
    display: flex;
  `
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(12)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      margin: ${theme.spacing(0.5)};
      height: 20px;
      line-height: 28px;
      font-weight: bold;
`
);
