import {
  Autocomplete,
  Avatar,
  Box,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { Notify } from "notiflix";
import {
  CheckTwoTone,
  EditTwoTone,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { GlobalContext } from "src/contexts/GlobalContext";
import CustomerRelationMeta from "../CustomerRelations/components/CustomerRelationMeta";

export default function OrgCheckins({ organisationId }) {
  const { getTagsByType, currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const statusParam = searchParams.get("status");
  const typeParam = searchParams.get("type");
  const [filterObj, setFilterObj] = useState({});
  const [loading, setLoading] = useState(false);

  const [applications, setApplications] = useState([]);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [customerRelations, setCustomerRelations] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
          status: statusParam,
          type: "checkin",
        };
      }

      if (organisationId) {
        reqParams.params.organisation = organisationId;
      }

      const resp = await axiosInstance.get("/customer-relations", reqParams);
      if (resp?.status === 200) {
        setApplications(resp?.data);
      }
      setLoading(false);
    })();
  }, [page, filterObj, typeParam, statusParam]);

  const handleUpdateCustomerRelation = async (id, data) => {
    try {
      const resp = await axiosInstance.put(`/customer-relations/${id}`, data);
      if (resp?.status === 200) {
        setApplications((prev) => ({
          ...prev,
          data: prev?.data?.map((item) =>
            item?._id === id ? { ...item, ...data } : item
          ),
        }));

        Notify.success("Updated successfully");
      }

      return "done";
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return "done";
    }
  };
  const CustomerRelation = ({ row }) => {
    const [edit, setEdit] = useState(false);
    const [selectedCustomerRelation, setSelectedCustomerRelation] = useState(
      row?.relation || ""
    );

    return !edit ? (
      <TableCell>
        {capitalize(row?.relation || "-")}
        {(currentUser?.accountType === "admin" ||
          currentUser?.orgId === row?.organisation?._id) && (
          <EditTwoTone
            fontSize="0.7rem"
            color="text.secondary"
            sx={{ ml: 1, cursor: "pointer" }}
            onClick={() => setEdit(true)}
          />
        )}
      </TableCell>
    ) : (
      <TableCell>
        <Stack direction="row" spacing={1}>
          <Autocomplete
            fullWidth
            size="small"
            options={customerRelations || []}
            getOptionLabel={(option) => capitalize(option + "")}
            value={selectedCustomerRelation}
            onChange={(e, value) => {
              setSelectedCustomerRelation(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <IconButton
            size="small"
            color="success"
            onClick={async () => {
              await handleUpdateCustomerRelation(row?._id, {
                relation: selectedCustomerRelation,
              });
              setEdit(false);
            }}
          >
            <CheckTwoTone />
          </IconButton>
        </Stack>
      </TableCell>
    );
  };

  const CustomTableRow = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell>
            <Box
              display={"flex"}
              alignItems={"center"}
              component={Link}
              to={`/dashboard/users/${row?.user?._id}`}
              style={{ textDecoration: "none" }}
            >
              <Avatar
                src={
                  row?.user?.profileImage
                    ? process.env.REACT_APP_DO_SPACE_URL +
                      (row?.user?.profileImage + "?" + Math.random())
                    : null
                }
                sx={{ width: "36px", mr: 1, height: "36px" }}
              >
                {row?.user?.first_name?.toString()?.slice(0, 1)}
              </Avatar>
              <Box>
                <Typography
                  component="h5"
                  sx={{
                    color: "rgb(38, 38, 38)",
                    margin: "0px",
                    wordBreak: "break-word",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  {row?.user?.firstName || "-"} {row?.user?.lastName || ""}
                </Typography>
                <Typography
                  component="h6"
                  sx={{
                    color: "rgb(88, 96, 105)",
                    margin: "0px",
                    wordBreak: "break-word",
                    cursor: "pointer",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {row?.user?.email}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            {" "}
            <Box
              display={"flex"}
              alignItems={"center"}
              component={Link}
              to={`/dashboard/organisations/${row?.organisation?._id}`}
              style={{ textDecoration: "none" }}
            >
              <Avatar
                src={
                  row?.organisation?.avatar
                    ? process.env.REACT_APP_DO_SPACE_URL +
                      (row?.organisation?.avatar + "?" + Math.random())
                    : null
                }
                sx={{ width: "36px", mr: 1, height: "36px" }}
              >
                {row?.organisation?.title?.toString()?.slice(0, 1)}
              </Avatar>
              <Box>
                <Typography
                  component="h5"
                  sx={{
                    color: "rgb(38, 38, 38)",
                    margin: "0px",
                    wordBreak: "break-word",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  {row?.organisation?.title || "-"}{" "}
                </Typography>
                <Typography
                  component="h6"
                  sx={{
                    color: "rgb(88, 96, 105)",
                    margin: "0px",
                    wordBreak: "break-word",
                    cursor: "pointer",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {row?.organisation?.companyEmail || "-"}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <CustomerRelation row={row} />
          <TableCell align="right">{fDateTimeSuffix(row?.createdAt)}</TableCell>
          {currentUser?.role !== "user" && (
            <TableCell align="right">
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </TableCell>
          )}
        </TableRow>
        <CustomerRelationMeta row={row} open={open} />
      </Fragment>
    );
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 500,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search here..."
            inputProps={{ "aria-label": "search here" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                if (e.target.value.length) {
                  setFilterObj({ ...filterObj, search: searchString });
                } else {
                  setFilterObj({ ...filterObj, search: null });
                }
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              if (e.target.value?.length > 0) {
                setSearchString(e.target.value);
              } else {
                setFilterObj({
                  ...filterObj,
                  search: null,
                });
              }
            }}
          />
          <IconButton
            type="button"
            sx={{
              p: "10px",
              m: 0.2,
              bgcolor: "primary.main",
              color: "white",
            }}
            onClick={() => {
              setFilterObj({
                ...filterObj,
                search: searchString,
              });
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Stack>
      <Box sx={{ my: 2 }}>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Relation</TableCell>
                <TableCell align="right">Date</TableCell>
                {currentUser?.role !== "user" && (
                  <TableCell align="right">Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {applications?.data?.map((row, idx) => (
                <CustomTableRow key={idx} row={row} />
              ))}

              {applications?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    It's empty here
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!loading && applications?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={applications?.totalPages || 1}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
