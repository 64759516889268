import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Stack,
  Button,
  Typography,
  CardMedia,
  TextField,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Divider,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import {
  AddCircleOutlineOutlined,
  AddTwoTone,
  ContentCopy,
  DeleteOutlined,
  DeleteTwoTone,
  SaveOutlined,
  SaveTwoTone,
} from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { LoadingButton } from "@mui/lab";
import { Confirm, Notify } from "notiflix";

import axiosInstance from "src/utils/axiosInstance";
import {
  DO_SPACES_FOLDERS,
  InputTypes,
  InputTypesObject,
} from "src/utils/constants";
import { generateRandomString } from "src/utils/helpers";
import { FormContext } from "src/contexts/FormContext";

export default function CreateUpdateQuestion({
  questionGroup,
  handler,
  mode = "create",
  weightage,
}) {
  const {
    form,
    setForm,
    handleGetForm,
    selectedFormGroup,
    setSelectedFormGroup,
    selectedQuestionGroup,
    setSelectedQuestionGroup,
    setSelectedQuestion,
    setCurrentSelectedItemType,
    setSelectedOption,
    setAddOpen,
  } = useContext(FormContext);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({
    title: "",
    placeholder: "",
    description: "",
    required: true,
    type: InputTypes[0]?.value,
    options: [],
  });
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    if (mode && mode === "update") {
      setFormInput({
        ...questionGroup,
      });
      setOpen(true);
      if (questionGroup?.description && questionGroup.description) {
        setDescriptionVisible(true);
      }
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFormInput({
      title: "",
      placeholder: "",
      description: "",
      required: true,
      type: InputTypes[0]?.value,
      options: [],
    });
    setAddOpen(null);
  };

  const handleSubmit = async () => {
    try {
      if (!formInput?.title) throw new Error("Question title is required");
      if (!formInput?.type) throw new Error("Question input type is required");
      setIsWait(true);

      if (filesToUpload) {
        const formData = new FormData();
        Array.from(filesToUpload).forEach((file) => {
          formData.append("files", file);
        });
        formData.append("formId", form?._id);
        formData.append("fileCategory", DO_SPACES_FOLDERS.FORMS);
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp.status === 200) {
          formInput.files = imageResp?.data;
        }
      }

      // generate form input name
      formInput.name = await generateRandomString(8);

      // append formId and formGroupId
      formInput.formId = form?._id;
      formInput.formGroupId = selectedFormGroup?._id;

      const resp = await axiosInstance.post(
        `/forms/question-group/${questionGroup._id}`,
        formInput
      );
      if (resp?.status === 200) {
        const question = resp?.data;

        const newForm = { ...form };
        const newFormGroups = [...newForm?.formGroups];
        const groupIndex = newFormGroups.findIndex(
          (item) => item._id === selectedFormGroup._id
        );
        const newQuestionsGroup = [
          ...newFormGroups[groupIndex]?.questionsGroup,
        ];
        const questionGroupIndex = newQuestionsGroup.findIndex(
          (item) => item._id === questionGroup._id
        );
        const newFormQuestions = [
          ...newQuestionsGroup[questionGroupIndex]?.questions,
        ];
        newFormQuestions.push(question);

        newQuestionsGroup[questionGroupIndex].questions = newFormQuestions;
        newFormGroups[groupIndex].questionsGroup = newQuestionsGroup;
        setForm(newForm);
        setSelectedFormGroup(newFormGroups[groupIndex]);
        setSelectedQuestionGroup(newQuestionsGroup[questionGroupIndex]);
        setSelectedQuestion(question);
        setCurrentSelectedItemType("question");
        setSelectedOption(null);

        setIsWait(false);
        Notify.success("Successfully");
        handleClose();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleUpdateQuestion = async () => {
    try {
      if (!formInput?.title) throw new Error("Question title is required");
      setIsWait(true);

      const resp = await axiosInstance.put(
        `/forms/questions/${formInput._id}`,
        formInput
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);
        setIsWait(false);
        Notify.success("Successfully");
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleCopyQuestion = async () => {
    try {
      setIsWait(true);
      formInput.name = await generateRandomString(8);
      delete formInput?._id;
      const resp = await axiosInstance.post(
        `/forms/question-group/${selectedQuestionGroup._id}`,
        formInput
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);
        setSelectedQuestion(null);
        setSelectedOption(null);
        setCurrentSelectedItemType(null);

        setIsWait(false);
        Notify.success("Successfully");
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleConfirmDeleteQuestion = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(
        `/forms/question-group/${selectedQuestionGroup?._id}/${questionGroup?._id}`
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);
        setSelectedQuestion(null);
        setSelectedOption(null);
        setCurrentSelectedItemType(null);

        setIsWait(false);
        Notify.success("Successfully");
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleDeleteQuestion = async () => {
    Confirm.show(
      "Confirmation",
      "Are you sure you want to delete?",
      "Confirm",
      "Cancel",
      () => handleConfirmDeleteQuestion(),
      () => {},
      {
        width: "500px",
        messageMaxLength: 1000,
      }
    );
  };

  if (!open && mode === "new") {
    return (
      <div>
        <Button
          variant="text"
          startIcon={<AddTwoTone fontSize="small" />}
          onClick={handleClickOpen}
        >
          Add New Question
        </Button>
      </div>
    );
  }
  return (
    <Stack spacing={2} direction={"row"}>
      <Card sx={{ border: "1px solid #eee", width: "100%" }}>
        {mode === "update" && handler}
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <TextField
                    fullWidth
                    name="title"
                    multiline
                    minRows={1}
                    hiddenLabel
                    autoFocus
                    InputProps={{
                      sx: {
                        backgroundColor: "rgba(240, 235, 248, 0.4)",
                        borderRadius: 0,
                        "&:hover": {
                          backgroundColor: "rgba(240, 235, 248, 0.6)",
                        },
                      },
                    }}
                    sx={{ backgroundColor: "transparent", borderRadius: 0 }}
                    placeholder={"Write your Question here"}
                    variant="filled"
                    value={formInput.title}
                    onChange={handleChange}
                  />
                  {weightage > 0 && (
                    <Typography variant="subtitle2" textAlign={"right"}>
                      w:<b style={{ marginLeft: 5 }}>{weightage}</b>
                    </Typography>
                  )}
                </Stack>
                {descriptionVisible && (
                  <Box my={1}>
                    <TextField
                      fullWidth
                      hiddenLabel
                      name="description"
                      placeholder={
                        "Write a description/subtitle/clarification here (optional)"
                      }
                      multiline
                      minRows={1}
                      InputProps={{
                        sx: {
                          backgroundColor: "transparent",
                          borderRadius: 0,
                          borderColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      variant="filled"
                      value={formInput.description}
                      onChange={handleChange}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>

          <Box>
            {filesToUpload &&
              Array.from(filesToUpload).map((file, index) => (
                <Box sx={{ my: 2 }}>
                  {index + 1}.{" "}
                  <Typography variant="caption">{file?.name}</Typography>
                  {file?.type?.includes("image") && (
                    <CardMedia
                      component="img"
                      src={URL.createObjectURL(file)}
                      alt="preview"
                      sx={{
                        width: "100%",
                        position: "relative",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Box>
              ))}
          </Box>
          <Box>
            {formInput?.files &&
              formInput.files.map((file, index) => (
                <Box sx={{ my: 2 }}>
                  {index + 1}.{" "}
                  <CardMedia
                    component="img"
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    sx={{
                      width: "100%",
                      position: "relative",
                      height: "150px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ))}
          </Box>
          <Box sx={{ mt: 2, mb: 1 }}>
            <Grid container>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="el-type-label">Select Input Type</InputLabel>
                  <Select
                    labelId="el-type-label"
                    id="el-type"
                    label="Select Input Type"
                    value={formInput.type}
                    onChange={(e) =>
                      setFormInput({ ...formInput, type: e.target.value })
                    }
                  >
                    {InputTypes &&
                      InputTypes.map((el, key) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {["radio", "checkbox", "select", "rating"].includes(
              formInput?.type
            ) && (
              <>
                {formInput?.options?.map((item, index) => (
                  <Stack key={index} spacing={1}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{ mt: 1 }}
                    >
                      {InputTypesObject[formInput?.type].icon}
                      <Grid container>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            name="label"
                            size="small"
                            sx={{
                              ".MuiFilledInput-underline": {
                                "&:before": {
                                  borderColor: "transparent",
                                },
                              },
                              ".MuiFilledInput-input": {
                                paddingTop: "8px",
                                paddingLeft: 0,
                              },
                            }}
                            InputProps={{
                              sx: {
                                backgroundColor: "transparent",
                                borderRadius: 0,
                                borderColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                            placeholder={"Option"}
                            variant="filled"
                            value={item?.label}
                            onChange={(e) => {
                              const newOptions = [...formInput?.options];
                              newOptions[index].label = e.target.value;
                              setFormInput({
                                ...formInput,
                                options: newOptions,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}>
                          <TextField
                            fullWidth
                            name="label"
                            size="small"
                            placeholder={"Weightage"}
                            variant="filled"
                            InputProps={{
                              sx: {
                                backgroundColor: "transparent",
                                borderRadius: 0,
                                borderColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              },
                            }}
                            sx={{
                              ".MuiFilledInput-underline": {
                                "&:before": {
                                  borderColor: "transparent",
                                },
                              },
                              ".MuiFilledInput-input": {
                                paddingTop: "8px",
                                paddingLeft: 0,
                              },
                            }}
                            value={item?.weightage}
                            onChange={(e) => {
                              const newOptions = [...formInput?.options];
                              newOptions[index].weightage = e.target.value;
                              setFormInput({
                                ...formInput,
                                options: newOptions,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>

                      <IconButton
                        onClick={() => {
                          const newOptions = [...formInput?.options];
                          newOptions.splice(index, 1);
                          setFormInput({
                            ...formInput,
                            options: newOptions,
                          });
                        }}
                        size="small"
                      >
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
                <Box sx={{ mt: 1 }}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    {InputTypesObject[formInput?.type].icon}
                    <Button
                      sx={{
                        px: 0,
                        borderRadius: 0,
                        borderBottom: "1px solid transparent",
                        "&:hover": {
                          background: "transparent",
                          borderBottom: "1px solid grey",
                        },
                      }}
                      size="small"
                      // endIcon={<AddTwoTone fontSize="small" />}
                      onClick={async () => {
                        const newOptions = [...formInput?.options];
                        newOptions.push({
                          label: "Option " + (newOptions.length + 1),
                          value: await generateRandomString(8),
                          weightage: formInput?.type === "rating" ? 1 : 0,
                        });
                        setFormInput({
                          ...formInput,
                          options: newOptions,
                        });
                      }}
                    >
                      Add Option
                    </Button>
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <IconButton
            size="small"
            mx={2}
            disabled={isWait}
            onClick={handleCopyQuestion}
          >
            <ContentCopy />
          </IconButton>

          <IconButton
            size="small"
            mx={2}
            disabled={isWait}
            onClick={handleDeleteQuestion}
          >
            <DeleteOutlined />
          </IconButton>
          <Box
            sx={{
              width: "1px",
              height: "30px",
              background: "grey",
              mx: 2,
            }}
          ></Box>
          <FormControlLabel
            control={
              <Switch
                name="required"
                checked={formInput.required}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    [e.target.name]: e.target.checked,
                  });
                }}
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Switch
                name="required"
                checked={descriptionVisible}
                onChange={(e) => {
                  setDescriptionVisible(!descriptionVisible);
                }}
              />
            }
            label="Description"
          />
          <Box sx={{ marginLeft: "auto!important" }}>
            {mode !== "update" ? (
              <Button
                color="secondary"
                sx={{ borderRadius: "4px" }}
                size="small"
                onClick={handleClose}
              >
                Cancel
              </Button>
            ) : //    <LoadingButton
            //    onClick={handleDeleteQuestion}
            //    variant="contained"
            //    disableElevation
            //    loading={isWait}
            //  >
            //    Delete
            //  </LoadingButton>
            null}
            {mode !== "update" ? (
              <LoadingButton
                onClick={handleSubmit}
                variant="outlined"
                color="secondary"
                disableElevation
                loading={isWait}
                sx={{ borderRadius: "4px" }}
                size="small"
              >
                Submit
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={handleUpdateQuestion}
                variant="outlined"
                color="secondary"
                disableElevation
                size="small"
                sx={{ borderRadius: "4px" }}
                loading={isWait}
              >
                Save
              </LoadingButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <Card sx={{ border: "1px solid #eee", maxHeight: "200px" }}>
        <CardContent sx={{ p: "10px" }}>
          <Box my={1}>
            <IconButton
              size="small"
              disabled={isWait}
              onClick={() => setAddOpen("parameter")}
            >
              <AddCircleOutlineOutlined />
            </IconButton>
          </Box>
          <Box my={1}>
            <IconButton
              size="small"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.accept = "image/*";
                input.multiple = true;
                input.onchange = async (e) => {
                  const files = e.target.files;
                  setFilesToUpload(files);
                };
                // Append the created input element to the DOM
                document.body.appendChild(input);
                // Trigger the click event on the input element
                input.click();
                // Remove the input element after the event is triggered
                document.body.removeChild(input);
              }}
            >
              <ImageOutlinedIcon />
            </IconButton>
          </Box>
          <Box my={1}>
            <IconButton
              size="small"
              disabled={isWait}
              onClick={handleCopyQuestion}
            >
              <ContentCopy />
            </IconButton>
          </Box>
          <Box my={1}>
            <IconButton
              size="small"
              disabled={isWait}
              onClick={handleDeleteQuestion}
            >
              <DeleteOutlined />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}
