import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";

const EXAM_CERTIFICATE_TYPES = [
  {
    label: "CPEW Certification",
    value: "ethixfirst",
  },
  {
    label: "Other Certification",
    value: "other",
  },
];

export default function ExamSection({
  formInput,
  setFormInput,
  handleInputChange,
}) {
  const { currentUser } = useContext(GlobalContext);
  const [addCertificate, setAddCertificate] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (formInput?.type === "exam") {
      setAddCertificate(formInput?.certificate?.certificateId ? true : false);
    } else {
      const _formInput = { ...formInput };
      delete _formInput?.certificate;
      setFormInput(_formInput);
      setAddCertificate(false);
    }
  }, [formInput?.type]);

  useEffect(() => {
    if (
      currentUser?.orgId ||
      formInput?.type !== "exam" ||
      certificates?.length > 0
    )
      return;

    // fetch certificates from api
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get("/metadata/certificates");
        if (resp?.status === 200) {
          setCertificates(resp?.data?.certificates || []);
          setEmailTemplates(resp?.data?.emailTemplates || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure("Failed to fetch certificates");
      }
    })();
  }, [formInput?.type]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <TextField
          label="Passing Percentage (0-100)"
          name="passingPercentage"
          placeholder="Minimum Passing Percentage Required"
          fullWidth
          value={formInput?.passingPercentage || ""}
          onChange={handleInputChange}
          type="number"
        />
        <TextField
          label="Duration)"
          name="duration"
          placeholder="Enter zero (0) for unlimited duration"
          fullWidth
          value={formInput?.duration || ""}
          onChange={handleInputChange}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">Minutes</InputAdornment>
            ),
          }}
        />
        <TextField
          label="Max Attempts"
          placeholder="Enter zero (0) for unlimited attempts"
          name="attempts"
          fullWidth
          value={formInput?.attempts || ""}
          onChange={handleInputChange}
          type="number"
        />
      </Stack>
      {!currentUser?.orgId && (
        <Stack direction="row" spacing={2}>
          <Box width={1}>
            <FormControlLabel
              control={<Checkbox />}
              checked={addCertificate}
              label="Add Certification after Completion of Exam"
              onChange={(e) => {
                const _formInput = { ...formInput };
                if (!e.target.checked) {
                  delete _formInput?.certificate;
                } else {
                  _formInput.certificate = {};
                }
                setFormInput(_formInput);
                setAddCertificate(e.target.checked);
              }}
            />
          </Box>
        </Stack>
      )}

      {addCertificate && !loading && (
        <Stack direction="row" spacing={2}>
          <Autocomplete
            fullWidth
            options={EXAM_CERTIFICATE_TYPES || []}
            getOptionLabel={(option) => option?.label || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Certificate Type *"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            onChange={(e, value) =>
              setFormInput({
                ...formInput,
                certificate: {
                  ...formInput?.certificate,
                  type: value?.value,
                },
              })
            }
            value={
              formInput?.certificate?.type
                ? EXAM_CERTIFICATE_TYPES.find(
                    (type) => type?.value === formInput?.certificate?.type
                  )
                : ""
            }
          />
          <Autocomplete
            fullWidth
            options={certificates || []}
            getOptionLabel={(option) => option?.title || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Certificate *"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            loading={loading}
            onChange={(e, value) =>
              setFormInput({
                ...formInput,
                certificate: {
                  ...formInput?.certificate,
                  title: value?.title,
                  certificateId: value?._id,
                },
              })
            }
            value={
              formInput?.certificate?.certificateId
                ? certificates.find(
                    (certificate) =>
                      certificate?._id === formInput?.certificate?.certificateId
                  )
                : ""
            }
          />
          <Autocomplete
            fullWidth
            options={emailTemplates || []}
            getOptionLabel={(option) => option?.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Email Template *"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            loading={loading}
            onChange={(e, value) =>
              setFormInput({
                ...formInput,
                certificate: {
                  ...formInput?.certificate,
                  emailTemplateId: value?._id,
                },
              })
            }
            value={
              formInput?.certificate?.emailTemplateId
                ? emailTemplates.find(
                    (template) =>
                      template?._id === formInput?.certificate?.emailTemplateId
                  )
                : ""
            }
          />
        </Stack>
      )}
    </>
  );
}
