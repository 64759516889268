import { useTheme } from "@emotion/react";
import {
  Alert,
  AlertTitle,
  Box,
  capitalize,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { PAGE_LIMIT } from "src/utils/constants";
import { isValidEmail, isValidPhoneNumber } from "src/utils/helpers";
import { read, utils } from "xlsx";

export default function InviteViaCsv({ users, setUsers }) {
  const theme = useTheme();
  const inputFile = useRef(null);
  const dropArea = useRef(null);
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const [placeholderToColumn, setPlaceholderToColumn] = useState({});
  const [sheetColumns, setSheetColumns] = useState([]);
  const [sheetRows, setSheetRows] = useState([]);
  const [page, setPage] = useState(1);
  const [customerRelations, setCustomerRelations] = useState([]);
  const [relation, setRelation] = useState("");

  useEffect(() => {
    if (!currentUser?.orgId) return;
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(placeholderToColumn).length > 0) {
      setUsers([]);
      const _users = sheetRows.map((row) => {
        const _newUser = {};
        Object.keys(placeholderToColumn).forEach((placeholder) => {
          console.log(
            placeholder,
            placeholderToColumn[placeholder],
            row[placeholderToColumn[placeholder]]
          );
          _newUser[placeholder] = row[placeholderToColumn[placeholder]];
        });

        return _newUser;
      });
      setUsers(_users);
    }
  }, [placeholderToColumn]);

  const processFiles = async (e, files) => {
    e.preventDefault();
    setUsers([]);
    setSheetColumns([]);
    setSheetRows([]);
    setPlaceholderToColumn({});
    setRelation("");

    dropArea.current.style.backgroundColor = "#fff";
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const [columns] = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 1,
          });
          setSheetColumns(columns || []);

          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setSheetRows(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const mapColumnAndRows = (item) =>
    sheetColumns.length > 0 ? (
      <FormControl
        size="small"
        sx={{
          minWidth: "180px",
        }}
      >
        <InputLabel>{item?.label}</InputLabel>
        <Select
          label={item?.label}
          value={placeholderToColumn[item?.value] || ""}
          onChange={(e) => {
            setPlaceholderToColumn({
              ...placeholderToColumn,
              [item?.value]: e.target.value,
            });
          }}
        >
          {sheetColumns?.map((column, index) => (
            <MenuItem key={index} value={column}>
              {column}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <Typography variant="h6" color={"textSecondary"}>
        {item?.label}
      </Typography>
    );

  return (
    <div>
      <Box my={2}>
        <div>
          <div className="file-upload">
            <div
              className="drop"
              ref={dropArea}
              onClick={() => inputFile.current.click()}
              onDrop={(e) => processFiles(e, e.dataTransfer.files)}
              onDragOver={(e) => {
                e.preventDefault();
                dropArea.current.style.backgroundColor = "#ddd";
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                dropArea.current.style.backgroundColor = "#fff";
              }}
              style={{
                cursor: "pointer",
                padding: sheetColumns?.length > 0 ? "30px" : "70px",
              }}
            >
              <div className="info">
                {"Drag & drop files or click to browse."}
                <br />
                {"CSV, XLS or XLSX only!"}
              </div>
            </div>

            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => processFiles(e, inputFile.current.files)}
              ref={inputFile}
              style={{ display: "none" }}
            />
          </div>
        </div>
        {sheetColumns?.length === 0 && (
          <Alert severity="warning" sx={{ my: 2 }}>
            Please note: First Name, Last Name, Email and Phone Number are
            mandatory fields.
          </Alert>
        )}
      </Box>
      <TableContainer elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {mapColumnAndRows({
                  label: "First Name *",
                  value: "firstName",
                })}
              </TableCell>
              <TableCell>
                {mapColumnAndRows({
                  label: "Last Name *",
                  value: "lastName",
                })}
              </TableCell>
              <TableCell>
                {mapColumnAndRows({
                  label: "Email *",
                  value: "email",
                })}
              </TableCell>
              <TableCell>
                {mapColumnAndRows({
                  label: "Phone Number *",
                  value: "phoneNumber",
                })}
              </TableCell>
              {currentUser?.orgId && (
                <>
                  <TableCell>
                    {mapColumnAndRows({
                      label: "Employee ID",
                      value: "employeeId",
                    })}
                  </TableCell>
                  <TableCell>
                    {sheetColumns?.length > 0 ? (
                      <FormControl
                        size="small"
                        sx={{
                          minWidth: "180px",
                        }}
                      >
                        <InputLabel>Relation</InputLabel>
                        <Select
                          label={"Relation"}
                          value={relation || ""}
                          onChange={(e) => {
                            if (e.target.value === "none") {
                              setRelation("");
                              setUsers(
                                users.map((user) => ({
                                  ...user,
                                  relation: "",
                                }))
                              );
                              return;
                            }

                            setRelation(e.target.value);

                            setUsers(
                              users.map((user) => ({
                                ...user,
                                relation: e.target.value,
                              }))
                            );
                          }}
                        >
                          {["employee", ...customerRelations, "none"]?.map(
                            (column, index) => (
                              <MenuItem key={index} value={column}>
                                {capitalize(column || "")}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography variant="h6" color={"textSecondary"}>
                        Relation
                      </Typography>
                    )}
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          {users?.length > 0 && (
            <TableBody>
              {users
                ?.slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT)
                ?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item?.firstName || "-"}</TableCell>
                    <TableCell>{item?.lastName || ""} </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "600",
                        color: isValidEmail(item?.email)
                          ? ""
                          : theme.palette.error.main,
                      }}
                    >
                      {item?.email || "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isValidPhoneNumber(item?.phoneNumber)
                          ? ""
                          : theme.palette.error.main,
                      }}
                    >
                      {item?.phoneNumber || "-"}
                      {item?.phoneNumber &&
                        !isValidPhoneNumber(item?.phoneNumber) && (
                          <Typography>
                            <small> (Invalid phone number)</small>
                          </Typography>
                        )}
                    </TableCell>
                    {currentUser?.orgId && (
                      <>
                        <TableCell>{item?.employeeId || "-"}</TableCell>
                        <TableCell>
                          {capitalize(item?.relation || "-")}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {users?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[PAGE_LIMIT]}
          component="div"
          count={users?.length}
          rowsPerPage={PAGE_LIMIT}
          page={page - 1}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={() => {}}
        />
      )}

      {!["firstName", "lastName", "email", "phoneNumber"]?.every((key) =>
        Object.keys(placeholderToColumn).includes(key)
      ) && (
        <>
          {sheetColumns?.length > 0 ? (
            <Alert severity="warning" sx={{ p: 2 }}>
              {sheetRows?.length > 0 && (
                <AlertTitle>
                  <b style={{ fontSize: "1rem" }}>
                    {`Please map the columns to the fields above
                before inviting the users.`}
                  </b>
                </AlertTitle>
              )}
              {` ${sheetRows?.length || "No "} users found!`}
            </Alert>
          ) : (
            !users?.length && (
              <Typography p={2} variant="h6" color={"textSecondary"}>
                {`No users uploaded yet.`}
              </Typography>
            )
          )}
        </>
      )}
    </div>
  );
}
