import {
  Box,
  Card,
  Avatar,
  Typography,
  Container,
  CardContent,
  Divider,
  alpha,
  Stack,
  CardHeader,
  Button,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";
import { Confirm, Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useParams } from "react-router";
import { GlobalContext } from "src/contexts/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

export default function AcceptInvitation() {
  const { currentUser } = useContext(GlobalContext);
  const { invitationId } = useParams();
  const [invitation, setInvitation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isWait, setIsWait] = useState(false);
  const [wrongPerson, setWrongPerson] = useState(false);

  useEffect(() => {
    const { userId, email } = currentUser || {};
    const { _id: invitedUserId } = invitation?.user || {};
    const invitedEmail = invitation?.email;

    if (userId) {
      const isWrongPerson =
        (invitedUserId && invitedUserId !== userId) ||
        (!invitedUserId && invitedEmail !== email);

      setWrongPerson(isWrongPerson);
    }
  }, [currentUser, invitation]);

  const getInvitationDetails = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/invitations/${invitationId}`);

      if (resp?.status === 200) {
        setInvitation(resp?.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (!invitationId) {
      Notify.failure("Invalid invitation link");
    }

    // Fetch invitation details
    getInvitationDetails();
  }, [invitationId]);

  const handleConfirmChangeUpdateStatus = async (status) => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.put(`/invitations/${invitation?._id}`, {
        status,
      });
      if (resp?.status === 200) {
        Notify.success(`Invitation ${status} successfully`);
        window.location.href = "/dashboard/invitations";
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleChangeUpdateStatus = async (status) => {
    Confirm.show(
      "Confirmation",
      "Are you sure you want to continue?",
      "Confirm",
      "Cancel",
      () => handleConfirmChangeUpdateStatus(status),
      () => {},
      {
        width: "500px",
        messageMaxLength: 1000,
      }
    );
  };

  const ActionsCard = () => {
    if (invitation?.status !== "pending")
      return (
        <Typography variant="h5" color="error">
          This invitation is no longer valid
        </Typography>
      );
    else if (wrongPerson)
      return (
        <Typography variant="h5" color="error">
          You are not the intended recipient of this invitation
        </Typography>
      );
    else if (!currentUser?.userId)
      return (
        <Stack spacing={2}>
          <Typography variant="h5" color="error">
            You need to login to accept or decline the invitation
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/auth/login?redirect_url=${window.location.pathname}&phone=${invitation?.phoneNumber}`}
          >
            Login Now
          </Button>
        </Stack>
      );
    return (
      <Stack spacing={2}>
        <LoadingButton
          variant="contained"
          color="success"
          size="large"
          loading={isWait}
          onClick={() => handleChangeUpdateStatus("accepted")}
        >
          Accept Invitation
        </LoadingButton>
        <LoadingButton
          color="secondary"
          loading={isWait}
          onClick={() => handleChangeUpdateStatus("rejected")}
        >
          Decline
        </LoadingButton>
      </Stack>
    );
  };

  return loading ? (
    <SuspenseLoader />
  ) : !invitation ? (
    <Typography variant="h2" color="error" textAlign={"center"} my={6}>
      Invitation not found
    </Typography>
  ) : (
    <div>
      <Container maxWidth="md">
        <Card
          sx={{
            position: "relative",
            m: 3,
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              background: (theme) =>
                `${alpha(theme.colors.secondary.main, 0.1)}`,
              p: 5,
              pb: 12,
            }}
          >
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 80,
                      height: 80,
                    }}
                    src={
                      invitation?.organisation?.avatar
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          (invitation?.organisation?.avatar +
                            "?" +
                            Math.random())
                        : `/static/images/logo/logo1.png`
                    }
                  />
                }
                title={
                  <Box>
                    <Typography variant="h3">
                      {invitation?.organisation?.title ||
                        process.env.REACT_APP_NAME}
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {invitation?.organisation?.companyWebsite || ""}
                    </Typography>
                  </Box>
                }
              />
            </Stack>

            <Divider sx={{ my: 4 }} />
            <Stack spacing={2} my={8} textAlign={"center"}>
              <Typography fontSize={"1.5rem"}>
                <b style={{ marginRight: 10 }}>
                  @
                  {invitation?.organisation?.title ||
                    process.env.REACT_APP_NAME}{" "}
                </b>
                has invited you to join their organisation{" "}
                {invitation?.meta?.relation && (
                  <>
                    {" "}
                    as
                    <br />
                    <strong>{invitation?.meta?.relation}</strong>
                  </>
                )}
              </Typography>
              <Typography color={"textSecondary"}>
                You can accept or decline the invitation by clicking the
                respective button below
              </Typography>
            </Stack>
            <Box my={4} />
            <Box textAlign={"center"} mb={4}>
              <ActionsCard />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
