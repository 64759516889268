// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { alpha, useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import {
  Card,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import { bgGradient } from "src/components/bgGradient";
import {
  AccountBoxTwoTone,
  BadgeTwoTone,
  GppBadTwoTone,
  GroupWorkTwoTone,
  StarsTwoTone,
  VerifiedTwoTone,
} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";
import Label from "src/components/Label";
import CustomTooltip from "src/components/CustomTooltip/CustomTooltip";
import VertificationDetails from "../../verification/VertificationDetails";

export default function ProfileCover() {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");
  const { currentUser } = useContext(GlobalContext);
  const { profile, viewAsPublic } = useContext(ProfileContext);
  const [verifyCount, setVerifyCount] = useState(0);
  const [currentTab, setCurrentTab] = useState(tabParam || "overview");
  const [tabs, setTabs] = useState([
    {
      value: "overview",
      label: "Overview",
      icon: <GroupWorkTwoTone />,
    },
  ]);

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
    setSearchParams({ tab: value });
  };

  useEffect(() => {
    if (["owner", "moderator"]?.includes(currentUser?.role)) {
      setTabs([
        ...tabs,
        // {
        //   value: "forms",
        //   label: "Rating Forms",
        //   icon: <AssignmentIndTwoTone />,
        // },
      ]);
    }

    if (!viewAsPublic) {
      setTabs([
        ...tabs,
        // {
        //   value: "ratings",
        //   label: "Ratings",
        //   icon: <StarsTwoTone />,
        // },
        {
          value: "certificates",
          label: "Certifications",
          icon: <BadgeTwoTone />,
        },
        {
          value: "about",
          label: "About",
          icon: <AccountBoxTwoTone />,
        },
      ]);
    }
  }, [currentUser, viewAsPublic]);

  useEffect(() => {
    setCurrentTab(tabParam || "overview");
  }, [tabParam]);

  useEffect(() => {
    let count = 0;
    if (profile?.cleanExitVerifications?.aadhaar) {
      count++;
    }
    if (profile?.cleanExitVerifications?.pan) {
      count++;
    }
    setVerifyCount(count);
  }, [profile]);

  return (
    <Card
      sx={{
        height: 280,
        position: "relative",
      }}
    >
      <Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.action.disabled, 0.7),
            imgUrl: profile?.coverImage
              ? process.env.REACT_APP_DO_SPACE_URL +
                profile?.coverImage +
                `?${Math.random()}`
              : "/static/images/placeholders/covers/8.jpg",
          }),
          height: 1,
          color: "common.white",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            left: { md: 24 },
            bottom: { md: 24 },
            zIndex: { md: 10 },
            pt: { xs: 6, md: 0 },
            position: { md: "absolute" },
          }}
        >
          <Avatar
            src={
              process.env.REACT_APP_DO_SPACE_URL +
              profile?.profileImage +
              `?${Math.random()}`
            }
            alt={capitalize(profile?.firstName?.charAt(0))}
            sx={{
              mx: "auto",
              width: { xs: 90, md: 180 },
              height: { xs: 90, md: 180 },
              border: `solid 2px ${theme.palette.common.white}`,
            }}
          />
          <Stack
            direction={{
              xs: "row",
              md: "column",
            }}
            sx={{
              px: 5,
              py: 4,
            }}
          >
            <ListItemText
              primary={
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography variant="h1">
                    {capitalize(
                      profile?.firstName + " " + (profile?.lastName || "")
                    )}
                  </Typography>
                  {((viewAsPublic &&
                    // TODO: Undo Aadhaar Verification

                    // profile?.cleanExitVerifications?.aadhaar ||
                    profile?.cleanExitVerifications?.pan) ||
                    !viewAsPublic) && (
                    <CustomTooltip
                      title={
                        <VertificationDetails
                          docType={"pan"}
                          cleanExitVerifications={
                            profile?.cleanExitVerifications || {}
                          }
                        />
                      }
                      placement="right-start"
                    >
                      {verifyCount === 0 ? (
                        <GppBadTwoTone color="error" />
                      ) : (
                        <VerifiedTwoTone
                          color={verifyCount === 2 ? "success" : "warning"}
                        />
                      )}
                    </CustomTooltip>
                  )}
                </Stack>
              }
              secondary={
                <Tooltip title={"EthixFirst ID"}>
                  <Typography
                    sx={{
                      mt: 0.5,
                      color: "white",
                      component: "span",
                      typography: "h4",
                      opacity: 0.68,
                    }}
                  >
                    {profile?.cleanExitUserId}
                  </Typography>
                </Tooltip>
              }
            />
            <Box>
              {profile?.meta?.headline && (
                <Label color="info">{profile?.meta?.headline || ""}</Label>
              )}
            </Box>
          </Stack>
        </Stack>{" "}
      </Box>

      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{
          position: "absolute",
          zIndex: 9,
          bgcolor: "background.paper",
          width: 1,
          bottom: 0,
          alignItems: "center",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabsChange}
          sx={{
            pr: { md: 3 },

            ".MuiTab-root": {
              "&.Mui-selected": {
                color: `${theme.colors.alpha.black[100]}`,
              },
              "&.Mui-selected:hover": {
                color: `${theme.colors.alpha.black[100]}`,
              },
            },
            "& .MuiTab-root:hover": {
              color: `${theme.colors.alpha.black[100]}`,
            },
            "& .MuiTabs-indicator": {
              minHeight: "2px",
              height: "2px",
              boxShadow: "none",
              bottom: "0px",
              background: "none",
              border: 0,
              zIndex: 5,
              color: "white",

              "&:after": {
                position: "absolute",
                left: "50%",
                width: "28px",
                content: '" "',
                background: `${theme.colors.primary.main}`,
                borderRadius: "inherit",
                height: "100%",
              },
            },
          }}
        >
          {tabs?.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              icon={tab.icon}
              label={tab.label}
              iconPosition="start"
            />
          ))}
        </Tabs>
      </Stack>
    </Card>
  );
}
