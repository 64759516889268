import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  Typography,
  IconButton,
  Stack,
  CardHeader,
  CardContent,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { ProfileContext } from "src/contexts/ProfileContext";
import UserCertificateGroups from "./UserCertificateGroups";
import IosSwitch from "src/components/IosSwitch";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";

export const CertificateCard = ({ item }) => {
  return (
    <Card
      sx={{
        border: item?.isDeleted === true && "1px solid red",
      }}
    >
      <Box
        sx={{
          height: "20vh",
          overflow: "hidden",
        }}
      >
        <CardMedia
          component="img"
          image={item?.certificateUrl}
          alt="."
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
                data-toggle="tooltip"
                title={item?.title}
              >
                {item?.title}
              </Typography>

              <Typography color="GrayText" fontSize={"0.7rem"}>
                {item?.organisation?.title || ""}
              </Typography>
            </Box>
          </Box>

          <Box display={"flex"}>
            <Box sx={{ textAlign: "right" }}>
              <IconButton
                variant="outlined"
                size="small"
                label={""}
                to={item?.link}
                component={Link}
                color="primary"
                target="_blank"
              >
                <NavigateNextIcon style={{ color: "#3f51b5" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default function Certificates() {
  const { profile, setProfile } = useContext(ProfileContext);
  const [viewGroups, setViewGroups] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setViewGroups(profile?.certificateGroups?.length > 0);
  }, [profile?.certifications]);

  useEffect(() => {
    (async () => {
      try {
        if (
          profile &&
          (profile?.certifications?.length > 0 ||
            profile?.certificateGroups?.length > 0)
        ) {
          setLoading(false);
          return;
        }
        setLoading(true);
        const resp = await axiosInstance.get(
          `/users/${profile?._id}/certificates`
        );
        if (resp?.status === 200) {
          setProfile({
            ...profile,
            certificateGroups: resp?.data?.certificateGroups || [],
            certifications: resp?.data?.certifications || [],
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card
      elevation={0}
      sx={{
        background: (theme) => `${theme.colors.gradients.blue4}`,
      }}
    >
      {profile?.certifications?.length > 0 ? (
        <>
          <CardContent
            sx={{
              background: (theme) => `${theme.colors.gradients.blue4}`,
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent={"space-between"}
            >
              <Stack spacing={1}>
                <Typography variant="h4" color={"white"}>
                  Certificates
                </Typography>
                <Typography variant="body2" color={"white"}>
                  {profile?.firstName || "-"} {profile?.lastName || ""} has{" "}
                  {profile?.certifications?.length || 0} Certificates. Toggle
                  switch to check all certificates.
                </Typography>
              </Stack>
              {profile?.certificateGroups?.length > 0 && (
                <IosSwitch
                  sx={{ m: 1 }}
                  checked={!viewGroups}
                  onChange={(e) => {
                    setViewGroups(!e.target.checked);
                  }}
                />
              )}
            </Stack>
          </CardContent>
          <CardContent>
            {viewGroups ? (
              <UserCertificateGroups />
            ) : (
              <Grid container spacing={3}>
                {profile?.certifications?.map((item, idx) => (
                  <Grid item xs={3} key={idx}>
                    <CertificateCard item={item} />
                  </Grid>
                ))}
              </Grid>
            )}
          </CardContent>
        </>
      ) : (
        <CardHeader
          title={
            <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h5"
                component="h5"
                gutterBottom
                color="white"
              >
                {"No Certificate found."}
              </Typography>
            </Box>
          }
        />
      )}
    </Card>
  );
}
