import { capitalize } from "@mui/material";
import { MAX_SCORE } from "./constants";

export const getRoleTitle = (accountType, role) => {
  // admin, triager, owner, moderator, user
  let _role = "user";

  if (accountType === "admin" || accountType === "triager") {
    _role = accountType;
  } else if (accountType === "user") {
    _role = role || "user";
  }

  return _role;
};

export function isUrlValid(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(str);
}

export const generateRandomString = async (length = 8) => {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

export const calculateFinalScore = (ratings, formType = "rating") => {
  if (formType === "exam") {
    const score = ratings?.score ?? 0;
    const weightage = ratings?.weightage ?? 0;

    const marks = weightage > 0 ? score / weightage : 0;
    const percentage = (marks || 0) * 100 || 0;

    let label = "Pass";
    let color = "success";
    if (percentage < ratings?.passingPercentage) {
      label = "Fail";
      color = "error";
    }

    return {
      score: Math.ceil(percentage),
      color,
      label,
    };
  }

  const score = ratings?.score ?? 0;
  const weightage = ratings?.weightage ?? 0;

  const _score = weightage > 0 ? score / weightage : 0;
  const totalScore = ratings?.finalScore
    ? ratings?.finalScore
    : (_score || 0) * MAX_SCORE || MAX_SCORE;

  let label = "Exceptional";
  let color = "success";
  if (totalScore < 250) {
    color = "secondary";
    label = "Needs Improvement";
  } else if (totalScore < 500) {
    color = "error";
    label = "Average";
  } else if (totalScore < 750) {
    color = "warning";
    label = "Above Average";
  } else {
    color = "success";
    label = "Exceptional";
  }

  return {
    score: Math.ceil(totalScore),
    color,
    label,
  };
};

export const splitCamelCaseToWords = (input) => {
  if (!input) return "";
  const _input = input.replace(/([a-z])([A-Z])/g, "$1 $2");
  return capitalize(_input || "");
};

export const checkIfAllQuestionsAnsweredInFormGroup = (group, formInput) => {
  let isAllAnswered = true;
  if (group?.questionsGroup?.length > 0) {
    isAllAnswered = !group.questionsGroup.some((questionGroup) => {
      return questionGroup.questions.some((question) => {
        if (["radio", "checkbox", "select", "rating"].includes(question.type)) {
          if (question.options.length > 0) {
            return !formInput[question.name];
          }
        }
        return false;
      });
    });
  }

  return isAllAnswered;
};

// currency number formatter for INR currency
export const currencyFormatter = (amount) => {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return formatter.format(amount);
};

export function calculateTimeRemain(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const _seconds = seconds - hours * 3600 - minutes * 60;

  let _time = "";
  if (hours > 0) {
    _time += `${hours}h `;
  }
  if (minutes > 0) {
    _time += `${minutes}m `;
  }
  if (_seconds > 0 || _time === "") {
    _time += `${_seconds?.toFixed(0)}s`;
  }
  return _time;
}

export function fileBytesToSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}

export function extractDomain(url) {
  const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/g;
  const matches = url.match(domainRegex);
  if (matches && matches.length > 0) {
    return matches[0].replace(/^https?:\/\//, ""); // Remove protocol if needed
  }
  return "";
}

export function isValidEmail(email) {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

export function getImageUrl(image) {
  return image?.startsWith("http")
    ? image
    : image
    ? `${process.env.REACT_APP_DO_SPACE_URL}${image}?${Math.random()}`
    : "";
}

export const refineText = (text) => {
  // remove special characters and not spaces
  return text?.replace(/[^a-zA-Z0-9\s]/g, " ");
};

export const generateSlugFromText = (text) => {
  return text
    ?.toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, " ")
    .replace(/\s+/g, "-");
};

export const isValidPhoneNumber = (phone) => {
  // length is 10 and starts with 6, 7, 8, 9
  return /^[6-9]\d{9}$/.test(phone);
};

export const isValidCleanExitId = (cleanExitId) => {
  return cleanExitId?.length === 10;
};
