import {
  Box,
  Card,
  Typography,
  ListItemText,
  styled,
  useTheme,
  Container,
  CardContent,
  Stack,
  Divider,
  ListItemButton,
  alpha,
  Button,
  Grid,
  CardActionArea,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  DialogTitle,
  CardHeader,
  FormHelperText,
  Autocomplete,
  capitalize,
} from "@mui/material";

import {
  ArrowForwardIos,
  EditTwoTone,
  GitHub,
  GppGood,
  LinkedIn,
  Public,
  Twitter,
} from "@mui/icons-material";
import { Fragment, useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useParams } from "react-router";
import CreateEditLinkRef from "./CreateEditLinkRef";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import OrgHeader from "./components/OrgHeader";

function LinkReferences() {
  const theme = useTheme();
  const { accountId, accountType } = useParams();
  const { currentUser, setOpenLoginDialog, getTagsByType } =
    useContext(GlobalContext);

  const [isWait, setIsWait] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState();
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [isCheckedIn, setIsCheckedIn] = useState(true);
  const [vertificationCode, setVertificationCode] = useState("");
  const [receivers, setReceivers] = useState([]);
  const [selectedReceiverType, setSelectedReceiverType] = useState("");

  const getExternalLinks = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(
        `/portfolios/${accountId}/${accountType}`
      );
      if (resp?.status === 200) {
        setProfileData(resp?.data);
        setSelectedReceiverType("");
      }
      setLoading(false);
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    // check if loggedIn
    if (!currentUser) {
      setOpenLoginDialog(true);
      return;
    }

    setVertificationCode("");
    if (accountType === "org" && currentUser?.orgId !== accountId) {
      checkIfCheckedIn();
      return;
    }
    (async () => {
      getExternalLinks();
      const _receivers = await getTagsByType("customerRelations");
      setReceivers(_receivers);
    })();
  }, [accountType]);

  const checkIfCheckedIn = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/customer-relations/check`, {
        params: {
          organisationId: accountId,
        },
      });
      if (resp?.status === 200) {
        if (resp?.data?.checkedIn) {
          setIsCheckedIn(true);
          setOpenOtpDialog(false);
          getExternalLinks();
        } else {
          setIsCheckedIn(false);
          setOpenOtpDialog(true);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const markAsCheckedIn = async () => {
    try {
      const resp = await axiosInstance.post(`/customer-relations`, {
        organisationId: accountId,
        type: "checkin",
        relation: "customer",
      });
      if (resp?.status === 200) {
        Notify.success("Checked In Successfully");
        getExternalLinks();
        setOpenOtpDialog(false);
        setIsCheckedIn(true);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleVerification = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.post(`/metadata/codes`, {
        code: vertificationCode,
      });
      if (resp?.status === 200) {
        markAsCheckedIn();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : !isCheckedIn ? (
    <>
      <Container maxWidth="md">
        <Card
          sx={{
            position: "relative",
            textAlign: "center",
            m: 3,
            p: 6,
          }}
        >
          <CardHeader
            title="Please check-in to view the profile"
            subheader="You will be able to view the profile once you check-in only"
          ></CardHeader>

          <Button
            onClick={() => setOpenOtpDialog(true)}
            size="large"
            variant="contained"
            startIcon={<GppGood />}
          >
            Check In Now
          </Button>
        </Card>
      </Container>
      <Dialog open={openOtpDialog}>
        <DialogTitle>Check In</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter Verification Code here to check-in and view the
            profile.
          </DialogContentText>
          <TextField
            sx={{ my: 2 }}
            autoFocus
            placeholder="Please enter 6 digit verification code here"
            type="code"
            fullWidth
            variant="outlined"
            value={vertificationCode}
            onChange={(e) => setVertificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenOtpDialog(false)}>Cancel</Button>
          <LoadingButton
            onClick={handleVerification}
            color="success"
            variant="contained"
            loading={isWait}
          >
            Verify Now
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Container maxWidth="lg">
      <Card
        sx={{
          position: "relative",
          m: 3,
        }}
      >
        <OrgHeader profile={profileData} />

        <CardContent>
          {(currentUser?.orgId === profileData?._id ||
            profileData?.ratingLinks?.length > 0) && (
            <>
              {currentUser?.orgId === profileData?._id ? (
                <Stack
                  spacing={2}
                  sx={{ mb: 3 }}
                  direction={"row"}
                  justifyContent={"center"}
                >
                  <FormHelperText sx={{ mt: 2 }}>
                    Add Rating Link for:
                  </FormHelperText>
                  <Autocomplete
                    fullWidth
                    options={["employee", "organisation", ...receivers]?.filter(
                      (item) =>
                        !profileData?.ratingLinks?.find(
                          (group) => group?.name === item
                        )
                    )}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => capitalize(option)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Receiver Type"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(e, value) => {
                      if (!value) return;
                      if (
                        profileData?.ratingLinks?.find(
                          (group) => group?.name === value
                        )
                      ) {
                        return;
                      }

                      setSelectedReceiverType(value);
                      setProfileData({
                        ...profileData,
                        ratingLinks: [
                          {
                            name: value,
                            links: [],
                          },
                          ...(profileData?.ratingLinks || []),
                        ],
                      });
                    }}
                    value={selectedReceiverType || ""}
                  />{" "}
                </Stack>
              ) : (
                ""
              )}
              {profileData?.ratingLinks?.map((group, idx) =>
                group?.links?.length > 0 ||
                currentUser?.orgId === profileData?._id ? (
                  <Box key={idx}>
                    <Divider sx={{ my: 3 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        Rate our {group?.name || ""}
                        {currentUser?.orgId === profileData?._id && (
                          <CreateEditLinkRef
                            group={group}
                            getExternalLinks={getExternalLinks}
                            ratingLinks={profileData?.ratingLinks}
                          />
                        )}
                      </Box>
                    </Divider>
                    <Box p={3}>
                      <Stack spacing={2}>
                        {group?.links?.map((item, idx) => (
                          <Fragment key={idx}>
                            <Box
                              component={"a"}
                              href={`/dashboard/forms/${
                                item?._id
                              }?&receiverType=${group?.name}${
                                accountType === "org"
                                  ? `&orgId=${accountId}`
                                  : ""
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <ListItemButtonWrapper
                                sx={{
                                  display: { xs: "block", sm: "flex" },
                                  py: 2,
                                  px: 2.5,
                                }}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography color={"white"} variant="h4">
                                      {item?.title}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      noWrap
                                      variant="subtitle2"
                                      color={"grey.300"}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      {item?.description?.length > 120
                                        ? item?.description?.substring(0, 120) +
                                          "..."
                                        : item?.description}
                                    </Typography>
                                  }
                                />
                                <ArrowForwardIos
                                  fontSize="small"
                                  sx={{
                                    color: "white",
                                  }}
                                />
                              </ListItemButtonWrapper>
                            </Box>
                          </Fragment>
                        ))}
                      </Stack>
                    </Box>
                  </Box>
                ) : (
                  ""
                )
              )}
            </>
          )}
          <Divider sx={{ my: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Social Media
              {currentUser?.orgId === profileData?._id ? (
                <Link
                  to={
                    accountType === "user"
                      ? `/dashboard/account/profile/edit`
                      : `/dashboard/organisations/${profileData?._id}/edit`
                  }
                >
                  <EditTwoTone sx={{ mx: 2 }} fontSize="small" />
                </Link>
              ) : (
                ""
              )}
            </Box>
          </Divider>

          <Box p={3}>
            <Grid container spacing={3}>
              {[
                {
                  label: "Website",
                  value: profileData?.meta?.website,
                  icon: <Public fontSize="large" />,
                  link: "",
                  color: "blue3",
                },
                {
                  label: "LinkedIn",
                  value: profileData?.meta?.linkedin,
                  icon: <LinkedIn fontSize="large" />,
                  link: "https://linkedin.com/",
                  color: "blue4",
                },
                {
                  label: "GitHub",
                  value: profileData?.meta?.github,
                  icon: <GitHub fontSize="large" />,
                  link: "https://github.com/",
                  color: "purple3",
                },
                {
                  label: "Twitter",
                  value: profileData?.meta?.twitter,
                  icon: <Twitter fontSize="large" />,
                  link: "https://twitter.com/",
                  color: "black2",
                },
              ]?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={index}
                  component={item?.value ? "a" : "span"}
                  href={item?.link + item?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      background: `${theme.colors.gradients[item.color]}`,
                      color: `${
                        theme.colors.alpha.trueWhite[item.value ? 100 : 70]
                      }`,
                    }}
                  >
                    <CardActionAreaWrapper sx={{ p: 2 }}>
                      {item?.icon}
                      <Typography gutterBottom variant="h4">
                        {item?.value || "-"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: `${theme.colors.alpha.trueWhite[70]}`,
                        }}
                        noWrap
                      >
                        {item?.label}
                      </Typography>
                    </CardActionAreaWrapper>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default LinkReferences;

const ListItemButtonWrapper = styled(ListItemButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(["transform", "box-shadow"])};
    transform: scale(1);
    position: relative;
    z-index: 6;
    background: ${theme.colors.gradients.blue4};
    border-radius: ${theme.general.borderRadius};

    &:hover {
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        transform: scale(1.08);
    }
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
         .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: ${theme.colors.primary.main};
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: .05;
          }
        }
  `
);
