import React, { useContext, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import EditExperieceDialog from "../addEdit/EditExperience";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import Label from "src/components/Label";
import { getCustomDateDifference } from "src/utils/date-fns";
import { Link } from "react-router-dom";
import { MonthNames } from "src/utils/commonConstants";

export default function UserExperiences() {
  const { currentUser } = useContext(GlobalContext);
  const { profile, portfolio, handleGetPortfolio, viewAsPublic } =
    useContext(ProfileContext);
  const [hoverOn, setHoverOn] = useState("");

  return (
    <Stack spacing={3}>
      <Card elevation={0}>
        <CardHeader
          title={
            <Typography variant="h3" component="h3">
              Experience
            </Typography>
          }
          action={
            currentUser?.userId === profile?._id &&
            !viewAsPublic && (
              <EditExperieceDialog handleGetPortfolio={handleGetPortfolio} />
            )
          }
        />
        <CardContent>
          <Timeline>
            {portfolio?.filter((item) => item?.category === "experience")
              ?.length ? (
              portfolio
                ?.filter((item) => item?.category === "experience")
                ?.sort((a, b) => {
                  const a_end =
                    a?.end_year + MonthNames.indexOf(a?.end_month) / 12;
                  const b_end =
                    b?.end_year + MonthNames.indexOf(b?.end_month) / 12;
                  return b_end - a_end || b?.start_year - a?.start_year;
                })
                ?.map((item, idx) => (
                  <TimelineItem
                    key={idx}
                    sx={{
                      p: 0,
                    }}
                    onMouseOver={() => setHoverOn("exp-" + idx)}
                    onMouseLeave={() => setHoverOn("")}
                  >
                    <TimelineOppositeContent
                      sx={{
                        width: "150px",
                        flex: "none",
                        whiteSpace: "nowrap",
                      }}
                      color="text.secondary"
                    >
                      <Typography>
                        <strong>
                          {item?.currently_working ? (
                            "Present"
                          ) : (
                            <>
                              {item?.end_month} {item?.end_year}
                            </>
                          )}
                        </strong>
                      </Typography>
                      {item?.start_month} {item?.start_year}
                      {item && item?.start_month && item?.start_year && (
                        <p>
                          <b>
                            (
                            {item
                              ? getCustomDateDifference(
                                  item?.start_year,
                                  item?.start_month,
                                  item?.end_year,
                                  item?.end_month
                                ) || ""
                              : ""}
                            )
                          </b>
                        </p>
                      )}
                    </TimelineOppositeContent>
                    <TimelineSeparator
                      sx={{
                        position: "relative",
                        mx: 2,
                      }}
                    >
                      <TimelineDot
                        sx={{
                          marginTop: 0,
                          top: (theme) => theme.spacing(3),
                        }}
                        variant="outlined"
                        color="primary"
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        pb: 4,
                      }}
                    >
                      <Card elevation={hoverOn !== "exp-" + idx && 0}>
                        <CardContent>
                          <Stack
                            spacing={2}
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="h4">
                              {item?.title || "-"}
                            </Typography>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            >
                              {hoverOn === "exp-" + idx &&
                                (currentUser?.accountType === "admin" ||
                                  currentUser?.userId === profile?._id) &&
                                !viewAsPublic && (
                                  <EditExperieceDialog
                                    portfolioItem={item}
                                    handleGetPortfolio={handleGetPortfolio}
                                  />
                                )}
                              {item?.currently_working && (
                                <Label color="success">
                                  <b>{item?.currently_working && "Current"}</b>
                                </Label>
                              )}
                            </Stack>
                          </Stack>
                          <Typography>
                            <Typography
                              variant="h6"
                              component={Link}
                              to={`/dashboard/organisations/${item?.organisation?._id}`}
                              target="_blank"
                              sx={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {item?.organisation?.avatar ? (
                                <Chip
                                  avatar={
                                    <Avatar
                                      alt={""}
                                      src={
                                        process.env.REACT_APP_DO_SPACE_URL +
                                          item?.organisation?.avatar ||
                                        "" + `?${Math.random()}`
                                      }
                                    />
                                  }
                                  sx={{
                                    border: "none",
                                  }}
                                  label={
                                    <Typography variant="h6" pr>
                                      {item?.organisation?.title || ""}
                                    </Typography>
                                  }
                                  variant="outlined"
                                />
                              ) : (
                                item?.organisation?.title || ""
                              )}{" "}
                              •{" "}
                            </Typography>
                            {item?.employment_type || ""}
                          </Typography>
                          {item?.location && (
                            <Typography variant="subtitle2" component="p">
                              {item?.location}
                            </Typography>
                          )}
                          {item?.description && (
                            <Typography variant="body1" color="text.secondary">
                              {item?.description}
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </TimelineContent>
                  </TimelineItem>
                ))
            ) : (
              <EmptyHere py={1} title={"No experience added yet"} />
            )}
          </Timeline>
        </CardContent>
      </Card>
    </Stack>
  );
}
