import React, { useState } from "react";
import {
  EditTwoTone,
  MailTwoTone,
  RemoveRedEye,
  PhoneCallback,
  EmailTwoTone,
  SendTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function CampaignBulkActions({ event }) {
  const [open, setOpen] = useState(false);

  return (
    <Stack spacing={2}>
      <Card sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0%",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              margin: "0px 0px 24px",
              color: "rgb(88, 96, 105)",
              textTransform: "uppercase",
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            Contact Person Details
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {[
            {
              icon: <PhoneCallback />,
              text: event?.contactPhone,
            },
            {
              icon: <EmailTwoTone />,
              text: event?.contactEmail,
            },
          ].map(
            (item, idx) =>
              item?.text && (
                <Box sx={{ display: "flex", marginBottom: "16px" }} key={idx}>
                  <Box
                    sx={{
                      display: "flex",
                      flex: "1 1 0%",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      filter: "none",
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: "12px",
                        position: "relative",
                        cursor: "default",
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        WebkitBoxPack: "center",
                        justifyContent: "center",
                        margin: "auto 0px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          color: "rgb(38, 38, 38)",
                          margin: "0px",
                          wordBreak: "break-word",
                          fontSize: "14px",
                          lineHeight: "18px",
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )
          )}
        </Box>
      </Card>
      <Card>
        <CardHeader title="Actions" />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Tooltip
              title={event?.forms?.length === 0 ? "No forms added yet" : ""}
              arrow
            >
              <Box>
                <Button
                  fullWidth
                  color="warning"
                  variant="contained"
                  endIcon={<SendTwoTone />}
                  component={Link}
                  to={`/dashboard/campaigns/${event?._id}/send`}
                  disabled={event?.forms?.length === 0}
                >
                  Send Emails
                </Button>
              </Box>
            </Tooltip>
            <Stack direction="row" spacing={2}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={<EditTwoTone />}
                component={Link}
                to={`/dashboard/campaigns/${event?._id}/edit`}
              >
                Edit Info
              </Button>
              <Button
                fullWidth
                color="info"
                variant="outlined"
                startIcon={<RemoveRedEye />}
                onClick={() => setOpen(true)}
              >
                Email Preview
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={2}>
            <MailTwoTone />
            <span>{event?.title || "Email Template"}</span>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: event?.description?.replace(
                /link_here/g,
                `<a href="${process.env.REACT_APP_BASE_URL}/dashboard/forms/${event?.forms?.[0]?.formId?._id}?orgId=${event?.organisation?._id}">LINK</a>`
              ),
            }}
          />
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
