import { Add, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "src/contexts/FormContext";
import axiosInstance from "src/utils/axiosInstance";

export default function UpdateFormGroupDialog() {
  const {
    form,
    handleGetForm,
    selectedFormGroup,
    setSelectedFormGroup,
    setSelectedQuestionGroup,
    setSelectedQuestion,
    setSelectedOption,
    setCurrentSelectedItemType,
  } = useContext(FormContext);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);

  useEffect(() => {
    setFormInput({
      _id: selectedFormGroup?._id,
      title: selectedFormGroup?.title,
      description: selectedFormGroup?.description,
    });
    setIsWait(false);
  }, [selectedFormGroup]);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    try {
      if (!formInput?.title) throw new Error("Form title is required");
      if (!formInput?.description)
        throw new Error("Form description is required");
      setIsWait(true);
      const resp = await axiosInstance.put(
        `/forms/${form?._id}/${selectedFormGroup?._id}`,
        formInput
      );
      if (resp?.status === 200) {
        const data = await handleGetForm(form?._id);
        if (data && data?.formGroups?.length > 0) {
          setSelectedFormGroup({
            ...selectedFormGroup,
            title: formInput?.title,
            description: formInput?.description,
          });
          setSelectedQuestionGroup(null);
          setSelectedQuestion(null);
          setSelectedOption(null);
          setCurrentSelectedItemType(null);
        }
        setIsWait(false);
        handleClose();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleDelete = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(
        `/forms/${form?._id}/${selectedFormGroup?._id}`
      );
      if (resp?.status === 200) {
        await handleGetForm(form?._id);
        setSelectedFormGroup(null);
        setSelectedQuestionGroup(null);
        setSelectedQuestion(null);
        setSelectedOption(null);
        setCurrentSelectedItemType(null);
      }
      setIsWait(false);
      handleClose();
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <IconButton size="small" mx={2} onClick={handleClickOpen}>
        <Edit fontSize="small" />
      </IconButton>

      {open && (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
          <DialogTitle>Update form dimension</DialogTitle>
          <DialogContent>
            <Stack sx={{ mt: 2 }} spacing={2}>
              <TextField
                autoFocus
                label="Form Dimension Title"
                fullWidth
                name="title"
                value={formInput?.title || ""}
                onChange={handleInputChange}
              />

              <TextField
                label="Form Dimension Subtitle/Description"
                name="description"
                value={formInput?.description || ""}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              style={{ width: "100%" }}
            >
              {" "}
              <LoadingButton
                onClick={handleDelete}
                color="error"
                loading={isWait}
              >
                Delete
              </LoadingButton>
              <Stack direction="row" spacing={2}>
                <Button onClick={handleClose} disabled={isWait}>
                  Cancel
                </Button>
                <LoadingButton
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  loading={isWait}
                >
                  Update Dimension
                </LoadingButton>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
