import {
  AttachFileTwoTone,
  Campaign,
  CheckBoxTwoTone,
  CheckCircleOutline,
  ContactSupportOutlined,
  DateRangeTwoTone,
  DoDisturbOnOutlined,
  DoNotDisturbOutlined,
  DriveFileRenameOutlineTwoTone,
  Event,
  GppBadOutlined,
  GroupsOutlined,
  HowToRegOutlined,
  ListTwoTone,
  PublicTwoTone,
  RadioButtonCheckedTwoTone,
  StarBorderPurple500TwoTone,
  TextFieldsTwoTone,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";

export const PAGE_LIMIT = 10;
export const PAGE_LIMIT_5 = 5;
export const PAGE_LIMIT_15 = 15;
export const MAX_SCORE = 1000;
export const MAX_AMOUNT_LIMIT = 500000; // 5 Lakh
export const PRIMARY_FONT = "Poppins";

export const RuppeeSign = "₹";

export const DO_SPACES_FOLDERS = {
  PROFILE_IMAGE: "profileimage",
  COVER_IMAGE: "coverimage",
  ORGANISATION: "organisation",
  FORMS: "forms",
  USERS: "users",
  IDCARDS: "idcards",
  EVENTS: "events",
  DOCUMENTS: "documents",
  OTHERS: "others",
};

export const ISAC_PLATFORMS = ["ethixcards", "copconnect"];

export const FORM_CATEGORIES = {
  POSH: "posh",
  OTHER: "other",
};

export const ORG_ASSET_TYPES = {
  property: {
    title: "Property",
    value: "property",
  },
  vehicle: {
    title: "Vehicle",
    value: "vehicle",
  },
  "electronic-device": {
    title: "Electronic Device",
    value: "electronic-device",
  },
  other: {
    title: "Other",
    value: "other",
  },
};

export const INVITATION_STATUS = [
  { label: "All Requests", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" },
  { label: "Left Organisation", value: "left" },
  { label: "Expired", value: "expired" },
];

export const EVENT_STATUS = {
  draft: {
    color: "warning",
    label: "Draft",
    description: "Draft",
    value: "draft",
  },
  published: {
    color: "success",
    label: "Published",
    description: "Published",
    value: "published",
  },
  completed: {
    color: "info",
    label: "Completed",
    description: "Completed",
    value: "completed",
  },
  cancelled: {
    color: "error",
    label: "Cancelled",
    description: "Cancelled",
    value: "cancelled",
  },
  review: {
    color: "warning",
    label: "Review",
    description: "Under Review",
    value: "review",
  },
  rejected: {
    color: "error",
    label: "Rejected",
    description: "Publish request rejected",
    value: "rejected",
  },
};

export const EVENT_AUDIENCE = [
  {
    label: "Public Event",
    value: "public",
    description: "Anyone can join this event.",
    icon: PublicTwoTone,
  },
  {
    label: "Internal Event",
    value: "internal",
    description:
      "Users having any relation with the organisation and organisation's employees can join this event.",
    icon: GroupsOutlined,
  },
];

export const MEMBERSHIPS = {
  premium: {
    label: "Premium",
    value: "premium",
    color: "success",
  },
  other: {
    label: "-",
    value: "other",
    color: "secondary",
  },
};

export const FORM_RATING_COMBINATIONS = [
  {
    label: "Organisation to Employee",
    access: "organisation",
    receivers: ["employee"],
    value: "organisation-employee",
  },
  {
    label: "Organisation to User",
    access: "organisation",
    receivers: ["user"],
    value: "organisation-user",
  },
  {
    label: "Employee to Organisation",
    access: "employee",
    receivers: ["organisation"],
    value: "employee-organisation",
  },
  {
    label: "User to Organisation",
    access: "user",
    receivers: ["organisation"],
    value: "user-organisation",
  },
  {
    label: "User to Employee",
    access: "user",
    receivers: ["employee"],
    value: "user-employee",
  },
  // {
  //   label: "Employee to User",
  //   access: "employee",
  //   receivers: ["user"],
  //   value: "employee-user",
  // },
  // {
  //   label: "Employee to Employee",
  //   access: "employee",
  //   receivers: ["employee"],
  //   value: "employee-employee",
  // },
  {
    label: "User to User",
    access: "user",
    receivers: ["user"],
    value: "user-user",
  },
  // {
  //   label: "Organisation to Organisation",
  //   access: "organisation",
  //   receivers: ["organisation"],
  //   value: "organisation-organisation",
  // },
];

export const FORM_TYPES = {
  rating: {
    label: "Rating",
    value: "rating",
    color: "primary",
    text: "Used for Employee Ratings, Feedbacks, etc. The final Score will be added to the Receiver's EthixFirst Score.",
  },
  community: {
    label: "Community",
    value: "community",
    color: "info",
    text: "Used for forms like Feedbacks, Survey, etc.",
  },
  exam: {
    label: "Exam",
    value: "exam",
    color: "warning",
    text: "Used for Test/Exams.",
  },
  other: {
    label: "Other",
    value: "other",
    color: "secondary",
    text: "Special purpose use like dispute form, etc. (Only Admin can see the Submissions)",
  },
};

export const FORM_TYPES_FOR_COMPANY = {
  rating: {
    label: "Rating",
    value: "rating",
    text: "Used for Feedbacks, Survey etc.",
  },
  exam: {
    label: "Exam",
    value: "exam",
    text: "Used for Test/Exams.",
  },
};

export const DOCUMENT_CATEGORIES = ["images", "documents", "consents"];

export const FORM_ACCESS_OBJECT = {
  organisations: {
    label: "Organisation",
    value: "organisation",
    text: "Only organisation can submit this form.",
    color: "info",
  },
  employees: {
    label: "Employee",
    value: "employee",
    text: "Only an employee can submit this form.",
    color: "error",
  },
  users: {
    label: "User",
    value: "user",
    text: "Only a user or an employee can submit this form.",
    color: "warning",
  },
};

export const invitationStatus = {
  pending: { label: "Pending", color: "warning" },
  accepted: { label: "Accepted", color: "success" },
  rejected: { label: "Rejected", color: "error" },
  left: { label: "Left Organisation", color: "info" },
};

export const InputTypesObject = {
  text: {
    label: "Text Input",
    value: "text",
    icon: <DriveFileRenameOutlineTwoTone fontSize="sm" />,
  },
  textarea: {
    label: "Textarea",
    value: "textarea",
    icon: <TextFieldsTwoTone fontSize="sm" />,
  },
  select: {
    label: "Select",
    value: "select",
    icon: <ListTwoTone color="" fontSize="medium" />,
  },
  checkbox: {
    label: "Checkbox",
    value: "checkbox",
    icon: <CheckBoxOutlineBlankOutlinedIcon fontSize="medium" />,
  },
  radio: {
    label: "Radio",
    value: "radio",
    icon: <RadioButtonCheckedTwoTone fontSize="medium" />,
  },
  rating: {
    label: "Rating",
    value: "rating",
    icon: <StarBorderPurple500TwoTone fontSize="medium" />,
  },
  file: {
    label: "File",
    value: "file",
    icon: <AttachFileTwoTone fontSize="sm" />,
  },
  date: {
    label: "Date",
    value: "date",
    icon: <DateRangeTwoTone fontSize="sm" />,
  },
};

export const InputTypes = [
  {
    label: "Text Input",
    value: "text",
    icon: DriveFileRenameOutlineTwoTone,
  },
  {
    label: "Textarea",
    value: "textarea",
    icon: TextFieldsTwoTone,
  },
  {
    label: "Select",
    value: "select",
    icon: ListTwoTone,
  },
  {
    label: "Checkbox",
    value: "checkbox",
    icon: CheckBoxTwoTone,
  },
  {
    label: "Radio",
    value: "radio",
    icon: RadioButtonCheckedTwoTone,
  },
  {
    label: "Rating",
    value: "rating",
    icon: StarBorderPurple500TwoTone,
  },
  {
    label: "File",
    value: "file",
    icon: AttachFileTwoTone,
  },
  {
    label: "Date",
    value: "date",
    icon: DateRangeTwoTone,
  },
];

export const InputFields = [
  {
    label: "Text Input",
    value: "text",
    icon: DriveFileRenameOutlineTwoTone,
  },
  // {
  //   label: "Textarea",
  //   value: "textarea",
  //   icon: TextFieldsTwoTone,
  // },
  {
    label: "File",
    value: "file",
    icon: AttachFileTwoTone,
  },
  {
    label: "Date",
    value: "date",
    icon: DateRangeTwoTone,
  },
];

export const TAGS_TYPE = [
  {
    label: "Industries",
    value: "industries",
  },
  {
    label: "Departments",
    value: "departments",
  },
  {
    label: "Designations",
    value: "designations",
  },
  {
    label: "Skills",
    value: "skills",
  },
  {
    label: "Certifications",
    value: "certifications",
  },
  {
    label: "Rate For (Receiver)",
    value: "receivers",
  },
  {
    label: "User Relations",
    value: "customerRelations",
  },
  {
    label: "NSD Empanelments",
    value: "nsdEmpanelments",
  },
];

export const INPUT_FIELDS_TYPE = [
  {
    label: "Industries",
    value: "industries",
  },
  // {
  //   label: "Receivers",
  //   value: "receivers",
  // },
  {
    label: "User Relations",
    value: "customerRelations",
  },
];

// enum: ["attempted", "pending", "completed", "failed", "cancelled"],
export const ORDER_STATUS = {
  attempted: {
    color: "secondary",
    label: "Attempted",
  },
  pending: {
    color: "warning",
    label: "Pending",
  },
  completed: {
    color: "success",
    label: "Completed",
  },
  failed: {
    color: "error",
    label: "Failed",
  },
  cancelled: {
    color: "error",
    label: "Cancelled",
  },
};

export const FORM_STATUS_COLOR = {
  draft: {
    color: "warning",
    label: "Draft",
  },
  published: {
    color: "success",
    label: "Published",
  },
  archived: {
    color: "error",
    label: "Archived",
  },
};

export const FIXED_PRICING_CATEGORIES = {
  verifications: {
    label: "ID verifications",
    value: "verifications",
  },
  joinOrganisations: {
    label: "Join Organisations",
    value: "joinOrganisations",
  },
};
export const PLAN_PRICING_CATEGORIES = {
  forms: {
    label: "Forms: Submit Ratings",
    value: "forms",
  },
  // exams: {
  //   label: "Take Exams",
  //   value: "exams",
  // },
  // users: {
  //   label: "Profile Views",
  //   value: "users",
  // },
  // joinOrganisations: {
  //   label: "Join Organisations",
  //   value: "joinOrganisations",
  // },
};

export const FEATURES_ACCESS = {
  checkIn: {
    name: "Check In",
    value: "checkIn",
    description:
      "A visitor can check in to the organisation. Eg. Restaurant, Hotel, Society Management, etc.",
    link: "/dashboard/checkins",
    icon: HowToRegOutlined,
    color: "success",
  },
  events: {
    name: "Events",
    value: "events",
    description:
      "Organisation can create & manage events. Eg. like Edu. Institutes, Music company",
    link: "/dashboard/events",
    icon: Event,
    color: "info",
  },
  customerRelations: {
    name: "User Relations",
    value: "customerRelations",
    description:
      "Organisation can manage customer or client relations Eg: like a bank, insurance company, food delivery company etc.",
    link: "/dashboard/customer-relations",
    icon: GroupsOutlined,
    color: "warning",
  },
  campaigns: {
    name: "Bulk Emails",
    value: "campaigns",
    description:
      "Create & manage bulk emailing service to send Forms to users. Eg. like a survey, feedback, etc.",
    link: "/dashboard/campaigns",
    icon: Campaign,
    color: "error",
  },
};

export const PRICING_CATEGORIES = {
  forms: {
    label: "Submit Ratings",
    value: "forms",
  },
  exams: {
    label: "Exams",
    value: "exams",
  },
  verifications: {
    label: "ID verifications",
    value: "verifications",
  },
  users: {
    label: "Profile Views",
    value: "users",
  },
  joinOrganisations: {
    label: "Join Organisations",
    value: "joinOrganisations",
  },
};

export const RATING_STATUS_COLOR = {
  active: {
    color: "success",
    label: "show current ratings only",
  },
  deprecated: {
    color: "secondary",
    label: "show older ratings only",
  },
  // deleted: {
  //   color: "error",
  //   label: "Deleted",
  // },
  // archived: {
  //   color: "info",
  //   label: "Archived",
  // },
};

export const MISCONDUCT_FORM_STATUS_COLOR = {
  none: {
    color: "secondary",
    label: "Not Reported",
    textColor: "gray",
  },
  open: {
    color: "success",
    label: "Open",
    textColor: "green",
  },
  closed: {
    color: "info",
    label: "Closed",
    textColor: "blue",
  },
  "in-review": {
    color: "warning",
    label: "In Review",
    textColor: "orange",
  },
  rejected: {
    color: "error",
    label: "Rejected",
    textColor: "red",
  },
  deprecated: {
    color: "secondary",
    label: "Deprecated",
    textColor: "gray",
  },
};

export const GENERATL_RESTRICTED_PATHS = [
  "/dashboard",
  "/forms",
  "/reports",
  "/pricing",
];

export const PUBLIC_PATHS = ["/auth", "/status", "/confirm"];

export const OWNER_RESTRICTED_PATHS = ["/dashboard/organisations"];

export const TRIAGER_RESTRICTED_PATHS = [];

export const USER_RESTRICTED_PATHS = [
  "/dashboard/users",
  "/dashboard/organisations",
];

export const EMAIL_STATUS = {
  PENDING: "PENDING",
  SENT: "SENT",
  FAILED: "FAILED",
  "NOT-SENT": "NOT SENT",
};

export const DURATION_IN_NUMBERS = {
  monthly: { value: 1, days: 30 },
  quarterly: { value: 3, days: 90 },
  halfYearly: { value: 6, days: 180 },
  yearly: { value: 12, days: 365 },
};

export const MANUAL_REPORT_STATUS = {
  open: {
    color: "primary",
    label: "Open",
  },
  "in-review": {
    color: "warning",
    label: "In Review",
  },
  closed: {
    color: "success",
    label: "Closed",
  },
};

export const CLEAN_EXIT_STATUS = {
  open: {
    color: "primary",
    label: "Open",
    logs: ["Employee's cleanexit is requested by the Organization."],
  },
  "in-review": {
    color: "warning",
    label: "In Review",
    logs: ["Employee's cleanexit is under Review"],
  },
  outstanding: {
    color: "success",
    label: "Outstanding",
    icon: CheckCircleOutline,
    score: 10,
    logs: [
      "Exceptional departure with exceptional achievements and impact.",
      "Highly commendable performance, exceeding all expectations.",
      "Leaves a lasting positive legacy within the organisation.",
    ],
  },

  satisfactory: {
    color: "info",
    label: "Satisfactory",
    icon: ThumbUpAltOutlined,
    score: 8,
    logs: [
      "Departure that meets the standard expectations and requirements.",
      "Met the job requirements and performed adequately.",
      "Maintains a neutral but acceptable impression upon departure.",
    ],
  },

  inconspicuous: {
    color: "warning",
    label: "Inconspicuous",
    icon: ContactSupportOutlined,
    score: 6,
    logs: [
      "Departure that goes unnoticed or without leaving a significant mark.",
      "Presence and performance didn't stand out in any particular way.",
      "Didn't significantly impact the organisation upon leaving.",
    ],
  },

  contentious: {
    color: "error",
    label: "Contentious",
    icon: GppBadOutlined,
    score: 4,
    logs: [
      "Departure that involves disputes, conflicts, or disagreements.",
      "Departure was marked by conflicts or disagreements.",
      "May have caused disruptions or concerns within the organisation.",
    ],
  },

  terminated: {
    color: "black",
    hexColor: "#000000",
    label: "Terminated",
    icon: DoNotDisturbOutlined,
    score: 2,
    logs: [
      "Employee is involuntarily dismissed or let go from the organisation.",
      "Ended the employment relationship due to policy violations or poor performance.",
      "Ends the employment relationship abruptly and often negatively.",
    ],
  },

  closed: {
    color: "secondary",
    label: "Closed",
    icon: DoDisturbOnOutlined,
    score: 0,
    logs: [
      "Employee's cleanexit Application is Closed without any action.",
      "Reasons for closing the application might be: Employee is unreachable, Employee has left the organisation, Employer has withdrawn the application, etc.",
      "Please contact the organisation for more details.",
    ],
  },
};

export const POSH_COMPLAINT_STATUS = {
  draft: {
    icon: "📌",
    label: "Draft",
  },
  open: {
    icon: "📄",
    label: "Open",
  },
  "in-review": {
    icon: "⏳",
    label: "In Review",
  },
  closed: {
    icon: "✅",
    label: "Closed",
  },
};
