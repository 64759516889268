import React, { useContext } from "react";
import Scrollbar from "src/components/Scrollbar";
import Logo from "src/components/Logo";
import { Box, Card, Drawer, Stack, styled, useTheme } from "@mui/material";
import SidebarMenu from "./SidebarMenu";
import { GlobalContext } from "src/contexts/GlobalContext";
import SidebarTopSection from "./SidebarTopSection";
import NewFormDialog from "src/content/dashboards/FormBuilder/NewFormDialog";

const SidebarWrapper = styled(Card)(
  ({ theme }) => `
    width: ${theme.sidebar.width};
    background: ${theme.sidebar.background};
    height: 100%;
    
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        position: fixed;
        height: calc(100% - ${theme.spacing(4)});
        margin: ${theme.spacing(2, 0, 2, 2)};
        z-index: 10;
        border-radius: ${theme.general.borderRadius};
    }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        align-items: center;
        margin: ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar, currentUser } =
    useContext(GlobalContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
        }}
      >
        <TopSection>
          <Stack
            direction={"column"}
            spacing={4}
            justifyContent={"space-around"}
            height={"100%"}
          >
            <Logo />
            <Box>
              <SidebarTopSection />
              {currentUser?.role === "admin" && <NewFormDialog />}
            </Box>
          </Stack>
        </TopSection>
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Scrollbar>
            <Box sx={{ mb: 25 }}>
              <SidebarMenu />
            </Box>
          </Scrollbar>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          display: { lg: "none", xs: "inline-block" },
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Logo />
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
