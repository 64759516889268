import {
  Box,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Avatar,
  Paper,
  Stack,
  InputBase,
  Autocomplete,
  TextField,
  LinearProgress,
  Pagination,
  capitalize,
} from "@mui/material";

import EmptyHere from "src/components/EmptyHere";
import { AttachFile, LaunchTwoTone, Search } from "@mui/icons-material";
import { fDate } from "src/utils/date-fns";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Link, useSearchParams } from "react-router-dom";
import { fileBytesToSize } from "src/utils/helpers";
import { DOCUMENT_CATEGORIES, PAGE_LIMIT } from "src/utils/constants";

export default function AllDocuments() {
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const categoryParam = searchParams.get("category");
  const [filterObj, setFilterObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [organisations, setOrganisations] = useState([]);
  const [searchString, setSearchString] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    (async () => {
      if (currentUser?.accountType !== "admin") return;
      const resp = await axiosInstance.get(`/organisations/all`, {
        params: {
          isClaimed: true,
        },
      });
      if (resp?.status === 200) {
        setOrganisations(resp?.data);
      }
    })();
  }, [currentUser]);

  const handleGetDocuments = async (reqParams) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get("/documents", reqParams);
      if (resp.status === 200) {
        setDocuments(resp.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (categoryParam) {
      setFilterObj({
        ...filterObj,
        category: categoryParam,
      });
    }
  }, [categoryParam]);

  useEffect(() => {
    const reqParams = {
      params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
    };
    if (filterObj) {
      reqParams.params = {
        ...reqParams.params,
        ...filterObj,
      };
    }

    handleGetDocuments(reqParams);
  }, [page, filterObj]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Stack direction="row" spacing={2}>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here..."
              inputProps={{ "aria-label": "search here" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (e.target.value.length) {
                    setFilterObj({ ...filterObj, search: searchString });
                  } else {
                    setFilterObj({ ...filterObj, search: null });
                  }
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }
              }}
              onChange={(e) => {
                e.preventDefault();
                if (e.target.value?.length > 0) {
                  setSearchString(e.target.value);
                } else {
                  setFilterObj({
                    ...filterObj,
                    search: null,
                  });
                }
              }}
            />
            <IconButton
              type="button"
              sx={{
                p: "10px",
                m: 0.2,
                bgcolor: "primary.main",
                color: "white",
              }}
              onClick={() => {
                setFilterObj({
                  ...filterObj,
                  search: searchString,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            >
              <Search />
            </IconButton>
          </Paper>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {currentUser?.accountType === "admin" && (
            <Autocomplete
              disablePortal
              size="small"
              id="org-box"
              options={organisations || []}
              getOptionLabel={(option) => option?.title}
              sx={{ width: 200, ml: 2 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Organisation" />
              )}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  organisation: v?._id,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            />
          )}

          <Autocomplete
            disablePortal
            size="small"
            id="org-box"
            options={DOCUMENT_CATEGORIES || []}
            getOptionLabel={(option) => capitalize(option || "")}
            sx={{ width: 250, mr: 2 }}
            value={filterObj?.status || ""}
            renderInput={(params) => (
              <TextField {...params} label="Document Type" />
            )}
            onChange={(e, v) => {
              setFilterObj({ ...filterObj, category: v });
              if (v) {
                searchParams.set("category", v);
                setSearchParams(searchParams);
              } else {
                searchParams.delete("category");
                setSearchParams(searchParams);
              }
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          />
        </Stack>
      </Stack>
      <Box>
        {loading && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("File")}</TableCell>
                <TableCell>{t("User")}</TableCell>
                <TableCell>{t("Owner")}</TableCell>
                {currentUser?.accountType === "admin" && (
                  <TableCell>{t("Organisation")}</TableCell>
                )}
                <TableCell>{t("Info")}</TableCell>
                <TableCell>{t("Date Uploaded")}</TableCell>
                <TableCell align="right">{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents?.data?.map((item, idx) => (
                <TableRow hover key={idx}>
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      component="a"
                      href={
                        process.env.REACT_APP_DO_SPACE_URL +
                        item?.url +
                        `?${new Date().getTime()}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        maxWidth: "300px",
                      }}
                    >
                      <AttachFile />
                      <Typography
                        sx={{
                          pl: 1,
                        }}
                        variant="h6"
                        title={item?.title}
                      >
                        {(item?.title || "-")?.length > 30
                          ? item?.title?.slice(0, 30) + "..."
                          : item?.title || "-"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      component={Link}
                      to={`/dashboard/users/${item?.user?._id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Avatar
                        src={
                          item?.user?.profileImage
                            ? process.env.REACT_APP_DO_SPACE_URL +
                              (item?.user?.profileImage + "?" + Math.random())
                            : null
                        }
                        sx={{ width: "36px", mr: 1, height: "36px" }}
                      >
                        {item?.user?.first_name?.toString()?.slice(0, 1)}
                      </Avatar>
                      <Box>
                        <Typography
                          component="h5"
                          sx={{
                            color: "rgb(38, 38, 38)",
                            margin: "0px",
                            wordBreak: "break-word",
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          {item?.user?.firstName || "-"}{" "}
                          {item?.user?.lastName || ""}
                        </Typography>
                        <Typography
                          component="h6"
                          sx={{
                            color: "rgb(88, 96, 105)",
                            margin: "0px",
                            wordBreak: "break-word",
                            cursor: "pointer",
                            fontSize: "12px",
                            lineHeight: "16px",
                          }}
                        >
                          {item?.user?.email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>{" "}
                  <TableCell>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      component={Link}
                      to={`/dashboard/users/${item?.uploadedBy?._id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Avatar
                        src={
                          item?.uploadedBy?.profileImage
                            ? process.env.REACT_APP_DO_SPACE_URL +
                              (item?.uploadedBy?.profileImage +
                                "?" +
                                Math.random())
                            : null
                        }
                        sx={{ width: "36px", mr: 1, height: "36px" }}
                      >
                        {item?.uploadedBy?.first_name?.toString()?.slice(0, 1)}
                      </Avatar>
                      <Box>
                        <Typography
                          component="h5"
                          sx={{
                            color: "rgb(38, 38, 38)",
                            margin: "0px",
                            wordBreak: "break-word",
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          {item?.uploadedBy?.firstName || "-"}{" "}
                          {item?.uploadedBy?.lastName || ""}
                        </Typography>
                        <Typography
                          component="h6"
                          sx={{
                            color: "rgb(88, 96, 105)",
                            margin: "0px",
                            wordBreak: "break-word",
                            cursor: "pointer",
                            fontSize: "12px",
                            lineHeight: "16px",
                          }}
                        >
                          {item?.uploadedBy?.email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {currentUser?.accountType === "admin" && (
                    <TableCell>
                      {item?.organisation?.title ? (
                        <Box display={"flex"} alignItems={"center"}>
                          <Avatar
                            src={
                              item?.organisation?.avatar
                                ? process.env.REACT_APP_DO_SPACE_URL +
                                  (item?.organisation?.avatar +
                                    "?" +
                                    Math.random())
                                : null
                            }
                            sx={{ width: "36px", mr: 1, height: "36px" }}
                          >
                            {item?.organisation?.title?.toString()?.slice(0, 1)}
                          </Avatar>
                          <Box>
                            <Typography
                              component="h5"
                              sx={{
                                color: "rgb(38, 38, 38)",
                                margin: "0px",
                                wordBreak: "break-word",
                                fontSize: "14px",
                                lineHeight: "18px",
                              }}
                            >
                              {item?.organisation?.title || "-"}{" "}
                            </Typography>
                            <Typography
                              component="h6"
                              sx={{
                                color: "rgb(88, 96, 105)",
                                margin: "0px",
                                wordBreak: "break-word",
                                cursor: "pointer",
                                fontSize: "12px",
                                lineHeight: "16px",
                              }}
                            >
                              {item?.organisation?.companyEmail || "-"}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    <Box>
                      <Typography
                        component="h5"
                        sx={{
                          color: "rgb(38, 38, 38)",
                          margin: "0px",
                          wordBreak: "break-word",
                          fontSize: "14px",
                          lineHeight: "18px",
                        }}
                      >
                        {(item?.category || "-")?.toUpperCase()}
                      </Typography>
                      <Typography
                        component="h6"
                        sx={{
                          color: "rgb(88, 96, 105)",
                          margin: "0px",
                          wordBreak: "break-word",
                          cursor: "pointer",
                          fontSize: "12px",
                          lineHeight: "16px",
                        }}
                      >
                        {fileBytesToSize(item?.size || 0)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" noWrap>
                      {fDate(item?.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title={t("View")} arrow>
                      <IconButton
                        sx={{
                          "&:hover": {
                            background: (theme) => theme.colors.primary.lighter,
                          },
                          color: (theme) => theme.palette.primary.main,
                        }}
                        color="inherit"
                        size="small"
                        component="a"
                        href={
                          process.env.REACT_APP_DO_SPACE_URL +
                          item?.url +
                          `?${new Date().getTime()}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LaunchTwoTone fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {documents?.data?.length === 0 && (
            <EmptyHere
              title={"No Documents uploaded"}
              subtitle={"Upload your documents to view them here"}
            />
          )}
          {!loading && documents?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={documents?.totalPages || 0}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}
