import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { FORM_RATING_COMBINATIONS } from "src/utils/constants";

export default function AccessibilitySection({ formInput, setFormInput }) {
  const { currentUser } = useContext(GlobalContext);
  const [formRelations, setFormRelations] = useState([]);

  useEffect(() => {
    const formsNotForOrg = ["user-user"];
    if (currentUser?.orgId) {
      setFormRelations(
        FORM_RATING_COMBINATIONS?.filter(
          (d) => !formsNotForOrg.includes(d?.value)
        )
      );
      return;
    }
    setFormRelations(FORM_RATING_COMBINATIONS);
  }, [FORM_RATING_COMBINATIONS, currentUser]);

  return (
    formInput?.type === "rating" && (
      <Stack direction="row" spacing={2}>
        <FormControl width={1} fullWidth>
          <FormHelperText sx={{ fontSize: 14, mb: 1 }}>
            Select submitter and receiver
          </FormHelperText>
          <Select
            value={
              formRelations?.find(
                (d) =>
                  d?.value ===
                  `${formInput?.access}-${formInput?.receivers?.[0]}`
              ) || {}
            }
            onChange={(e) => {
              const { access, receivers } = e.target.value;
              setFormInput({ ...formInput, access, receivers });
            }}
          >
            {formRelations?.map((d, i) => (
              <MenuItem key={i} value={d}>
                {d?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {["admin"]?.includes(currentUser?.accountType) && (
          <FormControlLabel
            sx={{ width: 1 }}
            control={<Checkbox />}
            checked={formInput?.includeScore || false}
            label="Add this Form's rating to Receiver's score "
            onChange={(e) => {
              const _formInput = { ...formInput };
              _formInput.includeScore = e.target.checked;
              setFormInput(_formInput);
            }}
          />
        )}
      </Stack>
    )
  );
}
