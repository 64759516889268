import { SaveTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CountriesWithStates from "src/utils/countries/states.json";
import CountriesWithCities from "src/utils/countries/cities.json";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";

export default function AddParticipant({ event }) {
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);

  const handleFormInput = (e) => {
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };

  const handleAddParticipant = async () => {
    try {
      if (!formInput?.firstName || !formInput?.lastName || !formInput?.email) {
        Notify.failure("Please fill all required fields");
        return;
      }

      setIsWait(true);
      formInput.isVerified = true;
      const userResp = await axiosInstance.post(`/auth/create`, formInput);
      const _user = userResp?.data;

      if (!_user) {
        Notify.failure("Unable to add participant");
        return;
      }

      const resp = await axiosInstance.post(`/customer-relations`, {
        user: _user?._id,
        event: event?._id,
        relation: "participant",
        type: "manual",
      });
      if (resp?.status === 200) {
        Notify.success("Participant added successfully");
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Card sx={{ m: 2, my: 6 }} elevation={0}>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="First Name*"
                name="firstName"
                variant="outlined"
                value={formInput?.firstName || ""}
                onChange={handleFormInput}
              />
              <TextField
                fullWidth
                label="Last Name*"
                name="lastName"
                variant="outlined"
                value={formInput?.lastName || ""}
                onChange={handleFormInput}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Personal Email*"
                name="email"
                variant="outlined"
                value={formInput?.email || ""}
                onChange={handleFormInput}
              />
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                variant="outlined"
                value={formInput?.phoneNumber || ""}
                onChange={handleFormInput}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                autoHighlight
                options={CountriesWithStates?.map((item) => item?.name)}
                value={formInput?.address?.country || "India"}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...(formInput.address || {}),
                      country: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                autoHighlight
                options={
                  CountriesWithStates?.find(
                    (item) =>
                      item?.name === (formInput?.address?.country || "India")
                  )?.states?.map((item) => item?.name) || []
                }
                value={formInput?.address?.state || ""}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...(formInput.address || {}),
                      state: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                autoHighlight
                options={
                  CountriesWithCities?.find(
                    (item) =>
                      item?.country === (formInput?.address?.country || "India")
                  )?.cities || []
                }
                value={formInput?.address?.city || ""}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...(formInput.address || {}),
                      city: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                label="Pincode"
                name="pincode"
                placeholder="Enter Pincode/Zipcode"
                value={formInput?.address?.pincode || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...(formInput.address || {}),
                      pincode: e.target.value,
                    },
                  });
                }}
              />
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Complete Address"
                value={formInput?.address?.address1 || ""}
                placeholder="eg. 123, Street, City, Country"
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...(formInput.address || {}),
                      address1: e.target.value,
                    },
                  });
                }}
                multiline
                rows={3}
              />
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack></Stack>
              <LoadingButton
                variant="contained"
                startIcon={<SaveTwoTone />}
                onClick={handleAddParticipant}
                loading={isWait}
              >
                Add Participant
              </LoadingButton>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
