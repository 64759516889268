import {
  AddCircleTwoTone,
  AddTwoTone,
  EditTwoTone,
  SecurityTwoTone,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import EmptyHere from "src/components/EmptyHere";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(["color", "transform"])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(["transform"])};
        }

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

const CardBorderBottom = styled(Card)(
  () => `
        border-bottom: transparent 5px solid;
  `
);

export default function Groups() {
  const theme = useTheme();
  const { type } = useParams();
  const { t } = useTranslation();

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get("/groups", {
          params: {},
        });
        if (resp?.status === 200) {
          setGroups(resp?.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Box sx={{ my: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              position: "relative",
              height: "100%",
              border: "1px dashed",
              borderColor: `${theme.colors.alpha.black[30]}`,
            }}
          >
            <Link
              to={`/dashboard/groups/new`}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  p: 2,
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <AddCircleTwoTone sx={{ fontSize: "3rem" }} />
                <Typography variant="h4" sx={{ mt: 2 }}>
                  {t("Create a new group")}
                </Typography>
              </Box>
            </Link>
          </Card>
        </Grid>
        {groups?.data?.length > 0 ? (
          groups?.data?.map((item, idx) => (
            <Grid item xs={12} md={4} key={idx}>
              <CardBorderBottom
                sx={{
                  borderBottomColor: `${theme.colors.primary.main}`,
                }}
              >
                <Box p={2}>
                  <Link
                    to={`/dashboard/groups/${item?._id}`}
                    color="text.primary"
                    underline="none"
                    sx={{
                      transition: `${theme.transitions.create(["color"])}`,
                      fontSize: `${theme.typography.pxToRem(20)}`,

                      "&:hover": {
                        color: `${theme.colors.primary.main}`,
                      },
                    }}
                    style={{ textDecoration: "none" }}
                    variant="h3"
                  >
                    {item?.title}
                  </Link>
                  {type === "members" && (
                    <Box py={2} px={1} display="flex">
                      {item?.members?.length > 0 ? (
                        <>
                          {item?.members?.slice(0, 5)?.map((member, idx) => (
                            <Avatar
                              key={idx}
                              variant="rounded"
                              sx={{
                                fontSize: `${theme.typography.pxToRem(12)}`,
                                fontWeight: "normal",
                                background: `#${Math.floor(
                                  Math.random() * 16777215
                                ).toString(16)}`,
                                color: `#fff`,
                                borderRadius: `${theme.general.borderRadiusSm}`,
                                width: 34,
                                height: 34,
                                mr: 0.5,
                              }}
                              component={Link}
                              to={`/dashboard/users/${member?.user}`}
                              target="_blank"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              {member?.firstName?.charAt(0)}
                              {member?.lastName?.charAt(0)}
                            </Avatar>
                          ))}
                          {item?.members?.length > 5 && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: 34,
                                height: 34,
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              component={Link}
                              to={`/dashboard/groups/${type}/${item?._id}?tab=members`}
                            >
                              +{item?.members?.length - 5}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary", height: 34 }}
                        >
                          {t("No members yet")}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {type === "organisations" && (
                    <Box py={2} px={1} display="flex">
                      {item?.organisations?.length > 0 ? (
                        <>
                          {item?.organisations?.slice(0, 5)?.map((org, idx) => (
                            <Avatar
                              key={idx}
                              variant="rounded"
                              sx={{
                                fontSize: `${theme.typography.pxToRem(12)}`,
                                fontWeight: "normal",
                                background: `#${Math.floor(
                                  Math.random() * 16777215
                                ).toString(16)}`,
                                color: `#fff`,
                                borderRadius: `${theme.general.borderRadiusSm}`,
                                width: 34,
                                height: 34,
                                mr: 0.5,
                              }}
                              component={Link}
                              to={`/dashboard/organisations/${org?._id}`}
                              target="_blank"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              {org?.title?.charAt(0)}
                              {org?.title?.charAt(0)}
                            </Avatar>
                          ))}
                          {item?.organisations?.length > 5 && (
                            <Typography
                              variant="body2"
                              sx={{
                                width: 34,
                                height: 34,
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                textDecoration: "none",
                              }}
                              component={Link}
                              to={`/dashboard/groups/${type}/${item?._id}?tab=organisations`}
                            >
                              +{item?.organisations?.length - 5}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary", height: 34 }}
                        >
                          {t("No Organisations yet")}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
                <Divider />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={1}
                  sx={{
                    background: `${theme.colors.alpha.black[5]}`,
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <IconButtonWrapper
                      size="small"
                      sx={{
                        mr: 1.5,
                        boxShadow: `${theme.colors.shadows.primary}`,
                        background: `${theme.colors.primary.main}`,
                        color: `${theme.palette.getContrastText(
                          theme.colors.primary.main
                        )}`,

                        "&:hover": {
                          background: `${theme.colors.primary.main}`,
                          color: `${theme.palette.getContrastText(
                            theme.colors.primary.main
                          )}`,
                        },
                      }}
                      component={Link}
                      to={`/dashboard/groups/${type}/${item?._id}?tab=organisations`}
                    >
                      <AddTwoTone fontSize="small" />
                    </IconButtonWrapper>
                    <IconButtonWrapper
                      size="small"
                      sx={{
                        mr: 1.5,
                        boxShadow: `${theme.colors.shadows.primary}`,
                        background: `${theme.colors.secondary.main}`,
                        color: `${theme.palette.getContrastText(
                          theme.colors.secondary.main
                        )}`,

                        "&:hover": {
                          background: `${theme.colors.secondary.main}`,
                          color: `${theme.palette.getContrastText(
                            theme.colors.secondary.main
                          )}`,
                        },
                      }}
                      component={Link}
                      to={`/dashboard/groups/${type}/${item?._id}/edit`}
                    >
                      <EditTwoTone fontSize="small" />
                    </IconButtonWrapper>
                  </Box>
                  <Button
                    size="small"
                    component={Link}
                    to={`/dashboard/groups/${type}/${item?._id}?tab=permissions`}
                    startIcon={<SecurityTwoTone fontSize="small" />}
                  >
                    Permissions
                  </Button>
                </Box>
              </CardBorderBottom>
            </Grid>
          ))
        ) : (
          <EmptyHere title={t("No groups found")} />
        )}
      </Grid>
    </Box>
  );
}
