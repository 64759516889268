import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { RuppeeSign } from "src/utils/constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function DigitalInterships() {
  const { handlePurchase } = useContext(GlobalContext);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedForms, setSelectedForms] = useState([]);
  const [formInput, setFormInput] = useState({});

  const internships = [
    "CCIO - CopConnect Social Internship",
    "ICPT - Penetration Testing",
    "ICFI - Digital Forensics",
    "ICBBR - Bug Bounty Hunting",
    "ICOSA - Open Source Intelligence",
  ];

  const getForms = async () => {
    try {
      setLoading(true);
      let ids = process.env.REACT_APP_DIGITAL_INTERNSHIPS;
      ids = [ids];
      const reqParams = {
        params: {
          ids: ids,
        },
      };
      const resp = await axiosInstance.get("/forms/ids", reqParams);
      if (resp?.status === 200) {
        setForms(resp?.data || []);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    // fetch forms
    getForms();
  }, []);

  const handleBuyNow = async () => {
    if (!formInput?.startDate || !formInput?.endDate) {
      Notify.failure("Please select start and end date for the internship");
      return;
    }

    if (new Date(formInput?.startDate) > new Date(formInput?.endDate)) {
      Notify.failure("Internship end date should be greater than start date");
      return;
    }

    await handlePurchase(
      {
        priceId: null,
        item: "forms",
        itemId: forms?.data?.[0]?._id,
        quantity: selectedForms?.length || 1,
        manualItems: selectedForms,
        duration: null,
        meta: {
          startDate: formInput?.startDate,
          endDate: formInput?.endDate,
        },
      },
      {
        // redirectLink: "/dashboard/digital-internship",
      }
    );
  };

  return (
    <Stack spacing={3} py={3}>
      <Typography variant="h1">Digital Internships Dashboard</Typography>
      {loading ? (
        <SuspenseLoader height="35vh" />
      ) : (
        <>
          <Card sx={{ p: 2.5 }}>
            <Typography variant="h2" mb={2} fontSize={"large"}>
              Available Internships
            </Typography>
            <Stack spacing={1}>
              {internships?.map((item, idx) => (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  key={idx}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "Medium",
                        fontWeight: "500",
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      sx={{ borderRadius: 0.5 }}
                      color={
                        selectedForms?.findIndex(
                          (each) => each?.title === item
                        ) > -1
                          ? "success"
                          : "inherit"
                      }
                      variant="contained"
                      onClick={() => {
                        if (
                          selectedForms?.findIndex(
                            (each) => each?.title === item
                          ) > -1
                        ) {
                          setSelectedForms(
                            selectedForms.filter((each) => each?.title !== item)
                          );
                        } else {
                          setSelectedForms([
                            ...selectedForms,
                            {
                              title: item,
                              quantity: 1,
                              amount: forms?.data?.[0]?.amount || 0,
                            },
                          ]);
                        }
                      }}
                    >
                      {selectedForms?.findIndex(
                        (each) => each?.title === item
                      ) > -1
                        ? "Selected"
                        : "Select"}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Card>
          <Card sx={{ p: 2.5 }}>
            <Typography variant="h2" mb={2} fontSize={"large"}>
              Your Selection
            </Typography>
            <Stack spacing={1}>
              {selectedForms?.length === 0 ? (
                <Typography
                  sx={{
                    fontSize: "Medium",
                    fontWeight: "500",
                  }}
                >
                  No internships selected yet.
                </Typography>
              ) : (
                selectedForms.map((item, idx) => (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    key={idx}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "Medium",
                          fontWeight: "500",
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "Medium",
                          fontWeight: "700",
                        }}
                      >
                        {RuppeeSign} {item?.amount}
                      </Typography>
                    </Box>
                  </Box>
                ))
              )}
            </Stack>
            <Divider sx={{ my: 1.5 }} />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "Medium",
                    fontWeight: "900",
                  }}
                >
                  Total Cost:
                </Typography>
              </Box>
              <Box>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <Typography
                    sx={{
                      fontWeight: "900",
                    }}
                    variant="h3"
                    color={"green"}
                  >
                    {RuppeeSign}{" "}
                    {(selectedForms.reduce((acc, item) => acc + item?.amount, 0)
                      ? selectedForms.reduce(
                          (acc, item) => acc + item?.amount,
                          0
                        )
                      : selectedForms.reduce(
                          (acc, item) => acc + item?.amount,
                          0
                        )) || "0"}
                  </Typography>
                </Stack>
              </Box>
            </Box>
            <Stack direction={"row"} spacing={2} mt={2}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "small",
                    fontWeight: "700",
                    mb: 0.5,
                  }}
                >
                  Select starting date *
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{
                      width: "100%",
                    }}
                    value={
                      formInput?.startDate
                        ? new Date(formInput?.startDate)
                        : null
                    }
                    onChange={(newValue) => {
                      setFormInput({
                        ...formInput,
                        startDate: newValue,
                      });
                    }}
                    format="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                </LocalizationProvider>
              </Box>{" "}
              <Box>
                <Typography
                  sx={{
                    fontSize: "small",
                    fontWeight: "700",
                    mb: 0.5,
                  }}
                >
                  Select ending date *
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{
                      width: "100%",
                    }}
                    value={
                      formInput?.endDate ? new Date(formInput?.endDate) : null
                    }
                    onChange={(newValue) => {
                      setFormInput({
                        ...formInput,
                        endDate: newValue,
                      });
                    }}
                    format="dd/MM/yyyy"
                    minDate={
                      formInput?.startDate
                        ? new Date(formInput?.startDate)
                        : new Date()
                    }
                  />
                </LocalizationProvider>
              </Box>
              <Box width={"100%"} height={"100%"}>
                <Box mb={2.8} />
                <Button
                  sx={{ borderRadius: 0.5 }}
                  color="success"
                  fullWidth
                  size="large"
                  variant="contained"
                  disabled={
                    selectedForms.length === 0 ||
                    selectedForms?.reduce(
                      (acc, item) => acc + item?.amount,
                      0
                    ) === 0 ||
                    !formInput?.startDate ||
                    !formInput?.endDate
                  }
                  onClick={handleBuyNow}
                >
                  Start Internships
                </Button>
              </Box>
            </Stack>
          </Card>
        </>
      )}
      <Card sx={{ p: 2.5 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Active Internships
        </Typography>
        <Typography
          sx={{
            fontSize: "Medium",
            fontWeight: "500",
          }}
        >
          No active internships at the moment.
        </Typography>
      </Card>
      <Card sx={{ p: 2.5 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Internship Insights
        </Typography>
        <Box
          component={"ul"}
          sx={{
            px: 2,
          }}
        >
          {[
            "Gain practical experience in cutting-edge cybersecurity domains",
            "Flexible 30-90 day duration to fit your schedule",
            "Self-paced online training included with each internship",
            "Earn valuable certificates to boost your resume",
            "Opportunity to work on real-world projects",
          ].map((each) => (
            <li>
              <Typography
                sx={{
                  fontSize: "Medium",
                  fontWeight: "500",
                }}
              >
                {each}
              </Typography>
            </li>
          ))}
        </Box>
      </Card>
    </Stack>
  );
}

export default DigitalInterships;
