import {
  BusinessTwoTone,
  CalculateOutlined,
  CardMembershipOutlined,
  CardMembershipTwoTone,
  CheckCircleOutline,
  TimerOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fDate } from "src/utils/date-fns";
import { GlobalContext } from "src/contexts/GlobalContext";
import ExamInstructions from "./components/ExamInstructions";
import { calculateTimeRemain } from "src/utils/helpers";
import EmptyHere from "src/components/EmptyHere";

export default function Exams({ items }) {
  const { currentUser } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const userParam = searchParams.get("user");
  const [userId, setUserId] = useState(userParam);

  useEffect(() => {
    let _userId = userParam;
    if (currentUser?.role === "user") {
      _userId = currentUser?.userId;
    }
    setUserId(_userId);
  }, [userParam, currentUser]);

  return (
    <Card elevation={0}>
      {items?.length > 0 ? (
        <List disablePadding>
          {items?.map((item, idx) => (
            <Fragment key={idx}>
              <ListItem
                sx={{
                  display: { xs: "block", md: "flex" },
                  py: 3,
                }}
              >
                <ListItemAvatar sx={{ mr: 3 }}>
                  <CardMedia
                    image={
                      item?.coverImage
                        ? `${process.env.REACT_APP_DO_SPACE_URL}${
                            item?.coverImage
                          }?${Math.random()}`
                        : "/static/images/placeholders/covers/exam.png"
                    }
                    title={item?.title}
                    sx={{
                      width: 200,
                      height: 200,
                      borderRadius: 1,
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        variant="h4"
                        color="textSecondary"
                        alignItems={"center"}
                        display="flex"
                        sx={{ height: 40 }}
                      >
                        {item?.organisation && (
                          <>
                            <BusinessTwoTone fontSize="small" sx={{ mr: 1 }} />
                            {item?.organisation?.title || "-"}
                          </>
                        )}
                      </Typography>
                      <Typography variant="h2">{item?.title}</Typography>
                    </>
                  }
                  primaryTypographyProps={{ variant: "h3" }}
                  secondary={
                    <>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                          last updated:{" "}
                          {fDate(
                            item?.updatedAt || item?.createdAt || new Date()
                          )}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        mt={2}
                      >
                        {item?.duration && item?.duration > 0 ? (
                          <Button
                            size="sm"
                            color="secondary"
                            startIcon={
                              <TimerOutlined color="info" fontSize="small" />
                            }
                          >
                            Total Duration:{" "}
                            {calculateTimeRemain(item?.duration * 60)}{" "}
                          </Button>
                        ) : (
                          ""
                        )}
                        {item?.attempts && item?.attempts > 0 ? (
                          <Button
                            size="sm"
                            color="secondary"
                            startIcon={
                              <CalculateOutlined
                                color="error"
                                fontSize="small"
                              />
                            }
                          >
                            Max Attempts: {item?.attempts}
                          </Button>
                        ) : (
                          ""
                        )}
                        {item?.passingPercentage &&
                        item?.passingPercentage > 0 ? (
                          <Button
                            size="sm"
                            color="secondary"
                            startIcon={
                              <CheckCircleOutline
                                color="success"
                                fontSize="small"
                              />
                            }
                          >
                            Min Passing Percentage: {item?.passingPercentage}%
                          </Button>
                        ) : (
                          ""
                        )}
                      </Stack>
                      {item?.certificate?.certificateId && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          mt={2}
                        >
                          <Typography
                            size="sm"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <CardMembershipTwoTone
                              color="warning"
                              fontSize="small"
                              sx={{ mx: 1 }}
                            />
                            <Typography>
                              Get <b>"{item?.certificate?.title || ""}"</b>{" "}
                              certificate
                            </Typography>
                          </Typography>
                        </Stack>
                      )}
                    </>
                  }
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    sx: {
                      pt: 1,
                    },
                  }}
                />

                <Box
                  mx={2}
                  sx={{
                    position: "absolute",
                    right: 5,
                    top: 20,
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {item?.certificate?.certificateId && (
                      <Chip
                        icon={<CardMembershipOutlined fontSize="small" />}
                        color="info"
                        variant="outlined"
                        label={<h3>Certification</h3>}
                      />
                    )}
                    {item?.premium && (
                      <Chip
                        color="success"
                        label={
                          <h3>
                            {item?.amount ? `₹ ${item?.amount}.00` : "Free"}
                          </h3>
                        }
                      />
                    )}
                  </Stack>
                </Box>
                <Box
                  mx={2}
                  sx={{
                    position: "absolute",
                    right: 10,
                    bottom: 30,
                  }}
                >
                  <ExamInstructions item={item} userId={userId} />
                </Box>
              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
        </List>
      ) : (
        <CardContent>
          <EmptyHere title="No Exams Found" />
        </CardContent>
      )}
    </Card>
  );
}
