import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { PAGE_LIMIT } from "src/utils/constants";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import UserAccounts from "./components/UserAccounts";

export default function ManageUsers() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [filterObj, setFilterObj] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const reqParams = {
          params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
        };

        if (filterObj) {
          reqParams.params = {
            ...reqParams.params,
            ...filterObj,
            isMasterAccount: true,
          };
        }

        const resp = await axiosInstance.get("/users/managed", reqParams);
        if (resp?.status === 200) {
          setUsers(resp?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page, filterObj]);

  return (
    <div>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            p: 2,
          }}
        >
          <Box fullWidth>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here..."
                inputProps={{ "aria-label": "search here" }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (e.target.value.length) {
                      setFilterObj({ ...filterObj, search: searchString });
                    } else {
                      delete filterObj.search;
                      setFilterObj({ ...filterObj, search: null });
                    }
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      page: 1,
                    });
                  }
                }}
                onChange={(e) => {
                  if (e.target.value?.length > 0) {
                    setSearchString(e.target.value);
                  } else {
                    if (filterObj?.search) {
                      setFilterObj({
                        ...filterObj,
                        search: null,
                      });
                    }
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{
                  m: 0.2,
                  bgcolor: "primary.main",
                  color: "white",
                }}
                onClick={() => {
                  setFilterObj({
                    ...filterObj,
                    search: searchString,
                  });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              >
                <Search />
              </IconButton>
            </Paper>
          </Box>

          <Stack spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterObj?.linkedAccountsOnly}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterObj({ ...filterObj, linkedAccountsOnly: true });
                    } else {
                      delete filterObj.linkedAccountsOnly;
                      setFilterObj({ ...filterObj, linkedAccountsOnly: null });
                    }
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      page: 1,
                    });
                  }}
                />
              }
              label="Show linked accounts only"
            />
          </Stack>
        </Stack>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ mb: 4 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Aadhaar </TableCell>
                  <TableCell>PAN </TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell align="right">Linked Accounts</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <LinearProgress sx={{ m: 3 }} />
                ) : (
                  <>
                    {users?.data?.map((row) => (
                      <UserAccounts
                        key={row._id}
                        row={row}
                        handleUpdateList={(linkedAccounts) => {
                          const updatedUsers = users?.data?.map((user) => {
                            if (user._id === row._id) {
                              return { ...user, linkedAccounts };
                            }
                            return user;
                          });
                          setUsers({ ...users, data: updatedUsers });
                        }}
                      />
                    ))}
                    {users?.data?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No users found
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>

            {!loading && users?.data?.length > 0 && (
              <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
                <Stack spacing={2}>
                  <Pagination
                    count={users?.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </Box>
            )}
          </TableContainer>
        </Box>
      </Card>
    </div>
  );
}
