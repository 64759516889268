import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import router from "src/router";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import { useEffect, useState } from "react";
import {
  GENERATL_RESTRICTED_PATHS,
  OWNER_RESTRICTED_PATHS,
  PUBLIC_PATHS,
  TRIAGER_RESTRICTED_PATHS,
  USER_RESTRICTED_PATHS,
} from "./utils/constants";
import { Notify } from "notiflix";
import SuspenseLoader from "./components/SuspenseLoader";
import BuyProductDialog from "./content/dashboards/common/BuyProductDialog";
import LoginDialog from "./content/pages/Auth/Login/LoginDialog";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  const content = useRoutes(router);

  const [isWait, setIsWait] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!PUBLIC_PATHS.some((a) => location.pathname.includes(a))) {
        const _user = JSON.parse(localStorage.getItem("user"));
        if (GENERATL_RESTRICTED_PATHS?.includes(location.pathname) && !_user) {
          navigate("/auth/login");
          return;
        }
        const role = _user?.role;
        if (
          (role === "owner" &&
            OWNER_RESTRICTED_PATHS.includes(location.pathname)) ||
          (role === "triager" &&
            TRIAGER_RESTRICTED_PATHS.includes(location.pathname)) ||
          (role === "user" && USER_RESTRICTED_PATHS.includes(location.pathname))
        ) {
          Notify.failure("Access Denied");
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          navigate("/auth/login");
          return;
        } else {
          setIsWait(false);
        }
      } else {
        setIsWait(false);
      }
    })();
  }, [location.pathname]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {isWait ? <SuspenseLoader /> : content}

        {/* GLOBAL CONTENTS */}
        <BuyProductDialog />
        <AuthProvider>
          <LoginDialog />
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
