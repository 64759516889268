import React, { Fragment, useContext, useEffect, useState } from "react";

import {
  Box,
  Grid,
  List,
  ListItemButton,
  Card,
  Divider,
  Typography,
  ListItemText,
  styled,
  useTheme,
  Stack,
} from "@mui/material";

import { ProfileContext } from "src/contexts/ProfileContext";
import { ArrowForwardIos } from "@mui/icons-material";
import { CertificateCard } from "./Certificates";

const BoxComposed = styled(Box)(
  () => `
    position: relative;
  `
);

const BoxComposedContent = styled(Box)(
  () => `
    position: relative;
    z-index: 7;
  `
);

const BoxComposedImage = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    filter: grayscale(80%);
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const BoxComposedBg = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const SingleCertificateGroup = ({ group }) => {
  const theme = useTheme();
  const { profile } = useContext(ProfileContext);
  const [certificates, setCertificates] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [hasCertificates, setHasCertificates] = useState(true);

  useEffect(() => {
    const certifications = profile?.certifications || [];

    function hasCertification(group) {
      if (
        group?.certificates?.some((cert) =>
          certifications.some(
            (item) => item.certificateId === cert.certificateId
          )
        )
      ) {
        setCertificates(group?.certificates);
        setSelectedGroupId(group?._id);
        return true;
      }

      if (group?.children?.some((child) => hasCertification(child))) {
        return true;
      }

      return false;
    }

    if (
      group &&
      (group?.children?.length > 0 || group?.certificates?.length > 0)
    ) {
      setHasCertificates(hasCertification(group));
    } else {
      setHasCertificates(false);
    }
  }, [profile?.certifications]);

  return (
    hasCertificates && (
      <Card
        sx={{
          maxHeight: "370px",
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={3}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              maxHeight: "370px",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                overflow: "scroll",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                py={5}
              >
                <Typography
                  variant="h2"
                  color={
                    selectedGroupId === group?._id ? "blue" : "text.primary"
                  }
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color:
                        group?.children?.length > 0 ? "blue" : "text.primary",
                    },
                  }}
                  onClick={() => {
                    if (group?.certificates?.length > 0) {
                      setCertificates(group?.certificates);
                      setSelectedGroupId(group?._id);
                    }
                  }}
                >
                  {group?.title}
                </Typography>
                <Typography
                  sx={{
                    pt: 1,
                  }}
                  variant="subtitle2"
                  textAlign="center"
                >
                  {group?.description || ""}.
                </Typography>
              </Box>
              <List component="div" disablePadding>
                {group?.children?.map((item, idx) => (
                  <Fragment key={idx}>
                    <Divider />
                    <ListItemButton
                      sx={{
                        pl: 4,
                      }}
                      onClick={() => {
                        setCertificates(item?.certificates || []);
                        setSelectedGroupId(item?._id);
                      }}
                      selected={selectedGroupId === item?._id}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            color={
                              selectedGroupId === item?._id
                                ? "blue"
                                : "text.primary"
                            }
                            variant="h5"
                          >
                            {item.title}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography noWrap variant="subtitle2">
                              {item.description}
                            </Typography>
                          </>
                        }
                      />
                      <ArrowForwardIos />
                    </ListItemButton>
                  </Fragment>
                ))}
              </List>
            </Stack>
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              maxHeight: "370px",
              overflow: "scroll",
            }}
          >
            <BoxComposed
              display="flex"
              alignItems="center"
              sx={{
                width: "100%",
                position: "relative",
                minHeight: "100%",
                background: `${theme.colors.gradients.orange3}`,
              }}
            >
              <BoxComposedBg
                sx={{
                  opacity: 0.5,
                  background: `${theme.colors.gradients.black1}`,
                }}
              />
              <BoxComposedBg
                sx={{
                  opacity: 0.5,
                  background: `${theme.colors.gradients.blue2}`,
                }}
              />
              <BoxComposedBg
                sx={{
                  opacity: 0.5,
                  background: `${theme.colors.gradients.blue5}`,
                }}
              />
              <BoxComposedImage
                sx={{
                  opacity: 0.3,
                  backgroundImage:
                    'url("/static/images/placeholders/covers/2.jpg")',
                }}
              />
              <BoxComposedContent
                display="flex"
                flexGrow={1}
                alignItems="center"
                flexDirection="column"
                p={3}
              >
                <Grid container spacing={4}>
                  {certificates?.length > 0 &&
                    certificates?.map((item, idx) => {
                      const cert = profile?.certifications?.find(
                        (cert) => cert.certificateId === item?.certificateId
                      );
                      if (!cert) return;
                      return (
                        <Grid item xs={4} key={idx}>
                          <CertificateCard item={cert} />
                        </Grid>
                      );
                    })}
                </Grid>
              </BoxComposedContent>
            </BoxComposed>
          </Grid>
        </Grid>
      </Card>
    )
  );
};

export default function UserCertificateGroups() {
  const { profile } = useContext(ProfileContext);

  return (
    profile?.certificateGroups?.length && (
      <Stack spacing={3}>
        {profile?.certificateGroups?.map((item, idx) => (
          <SingleCertificateGroup key={idx} group={item} />
        ))}
      </Stack>
    )
  );
}
