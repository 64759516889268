import { CardContent, Stack, Switch, Typography, styled } from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext } from "react";
import IOSSwitch from "src/components/IosSwitch";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";

export default function ProfilePublish({ ...rest }) {
  const { profile, setProfile, percentComplete, inCompleteFields } =
    useContext(ProfileContext);
  const { currentUser } = useContext(GlobalContext);

  const handleEditProfile = async () => {
    try {
      const resp = await axiosInstance.post("/users/profile", {
        ...profile,
        isPublic: !profile?.isPublic,
      });
      if (resp?.status === 200) {
        setProfile({
          ...profile,
          isPublic: !profile?.isPublic,
        });
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };
  if (currentUser?.userId !== profile?._id) return <></>;
  return percentComplete >= 75 ? (
    <CardContent
      sx={{
        background: (theme) => `${theme.colors.gradients.blue4}`,
        borderRadius: rest?.sx?.borderRadius || "10px 10px 0 0",
        ...rest?.sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography variant="h4" color={"white"}>
            Make Profile {profile?.isPublic ? "Private" : "Public"}
          </Typography>
          <Typography variant="body2" color={"white"}>
            Your profile {profile?.isPublic ? "is " : "will be "}
            visible to the Public
          </Typography>
        </Stack>
        <IOSSwitch
          sx={{ m: 1 }}
          checked={profile?.isPublic || false}
          onChange={() => {
            handleEditProfile();
          }}
        />
      </Stack>
    </CardContent>
  ) : (
    <></>
  );
}
