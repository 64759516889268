import { AddCircleOutlineTwoTone, EditTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";
import { MonthNames } from "src/utils/commonConstants";

export default function EditExperieceDialog({ portfolioItem }) {
  const { portfolio, setPortfolio, handleSavePortfolio } =
    useContext(ProfileContext);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    if (portfolioItem) {
      const pItem = { ...portfolioItem };
      if (pItem?.organisation) {
        pItem.organisation = pItem?.organisation?.title;
        setFormInput(pItem);
      }
    } else {
      setFormInput({});
    }
  }, [portfolioItem]);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsWait(false);
    if (!portfolioItem) setFormInput({});
  };

  const handleSubmit = async () => {
    setIsWait(true);
    formInput.category = "experience";
    const resp = await handleSavePortfolio(formInput);
    if (resp) {
      setFormInput({});
      handleClose();
      Notify.success("Successful");
    }
    setIsWait(false);
  };

  const handleDelete = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.delete(
        `/portfolios/${portfolioItem._id}`
      );
      if (resp.status === 200) {
        setFormInput({});
        handleClose();
        const newPortfolio = portfolio.filter(
          (item) => item._id !== portfolioItem._id
        );
        setPortfolio(newPortfolio);
      }
      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        sx={{
          p: 0,
          m: 0,
        }}
      >
        {portfolioItem?._id ? (
          <EditTwoTone fontSize="small" />
        ) : (
          <AddCircleOutlineTwoTone />
        )}
      </IconButton>

      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Fill out the form below to {formInput?._id ? "edit" : "add"}{" "}
          experience
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <TextField
              label="Title *"
              placeholder="e.g. Software Engineer"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />
            <FormControl fullWidth>
              <InputLabel>Employment Type *</InputLabel>
              <Select
                value={formInput?.employment_type || ""}
                label="Employment Type"
                name="employment_type"
                onChange={handleInputChange}
              >
                {[
                  "Full Time",
                  "Part Time",
                  "Internship",
                  "Freelance",
                  "Self Employed",
                  "Other",
                ].map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              disablePortal
              freeSolo
              options={organisations || []}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organisation Name *"
                  onChange={async (e) => {
                    const reqParams = { params: { search: e.target.value } };
                    const resp = await axiosInstance.get(
                      `/organisations/all`,
                      reqParams
                    );
                    if (resp?.status === 200) {
                      setOrganisations(resp?.data);
                    }
                  }}
                />
              )}
              noOptionsText="No organisations found"
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  organisation: value?.title,
                });
              }}
              inputValue={formInput?.organisation || ""}
              onInputChange={(event, newInputValue) => {
                setFormInput({
                  ...formInput,
                  organisation: newInputValue,
                });
              }}
            />
            <TextField
              label="Location"
              placeholder="e.g. Hyderabad, India"
              fullWidth
              name="location"
              value={formInput?.location || ""}
              onChange={handleInputChange}
            />
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Start Month *</InputLabel>
                <Select
                  value={formInput?.start_month || ""}
                  label="Start Month"
                  name="start_month"
                  onChange={handleInputChange}
                >
                  {MonthNames.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Start Year *</InputLabel>
                <Select
                  value={formInput?.start_year || ""}
                  label="Start Year"
                  name="start_year"
                  onChange={handleInputChange}
                >
                  {Array.from(
                    { length: 70 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formInput?.currently_working}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        currently_working: e.target.checked,
                      })
                    }
                  />
                }
                label="I am currently working in this role"
              />
            </Stack>
            {!formInput?.currently_working && (
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel>End Month</InputLabel>
                  <Select
                    value={formInput?.end_month || ""}
                    label="End Month"
                    name="end_month"
                    onChange={handleInputChange}
                  >
                    {MonthNames.map((item, idx) => (
                      <MenuItem value={item} key={idx}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>End Year</InputLabel>
                  <Select
                    value={formInput?.end_year || ""}
                    label="End Year"
                    name="end_year"
                    onChange={handleInputChange}
                  >
                    {Array.from(
                      { length: 70 },
                      (_, i) => new Date().getFullYear() - i
                    ).map((item, idx) => (
                      <MenuItem value={item} key={idx}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )}

            <TextField
              placeholder="e.g. I worked on the frontend of the application"
              fullWidth
              name="description"
              value={formInput?.description || ""}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput?.highlighted}
                  onChange={(e) =>
                    setFormInput({
                      ...formInput,
                      highlighted: e.target.checked,
                    })
                  }
                />
              }
              label="Highlight this experience"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
            }}
            justifyContent="space-between"
          >
            <Box>
              {portfolioItem?._id && (
                <Button
                  color="error"
                  onClick={() => {
                    Confirm.show(
                      "Confirmation",
                      "Are you sure you want to delete?",
                      "Confirm",
                      "Cancel",
                      () => handleDelete(),
                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      }
                    );
                  }}
                  disabled={isWait}
                >
                  Delete
                </Button>
              )}
            </Box>

            <Box>
              <Button onClick={handleClose} disabled={isWait}>
                Cancel
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                loading={isWait}
                variant="contained"
                color="primary"
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
