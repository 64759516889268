import { CircularProgress, Stack, Typography } from "@mui/material";

function SuspenseLoader({ text, hideSpinner, height = "75vh" }) {
  return (
    <Stack
      style={{
        position: "relative",
        width: "100%",
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {hideSpinner ? null : <CircularProgress sx={{ mb: 1 }} />}
      <Typography>{text || "Please wait..."}</Typography>
    </Stack>
  );
}

export default SuspenseLoader;
