import {
  Box,
  Typography,
  Button,
  styled,
  Stack,
  capitalize,
  Avatar,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  LanguageTwoTone,
  SportsScoreTwoTone,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useContext, useState } from "react";
import { FormContext } from "src/contexts/FormContext";
import { fDateTimeSuffix } from "src/utils/date-fns";
import axiosInstance from "src/utils/axiosInstance";
import Notiflix, { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";
import Label from "src/components/Label";
import { FORM_ACCESS_OBJECT, FORM_STATUS_COLOR } from "src/utils/constants";
import { Link } from "react-router-dom";
import NewFormDialog from "../NewFormDialog";

const RootWrapper = styled(Box)(
  () => `
    padding: 12px;
    position: relative;
    width: 100%;
`
);

function FormHeader() {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  const [readMore, setReadMore] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [status, setStatus] = useState(form?.status || "draft");

  const handlePublish = async (status) => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.put(`/forms/${form?._id}`, {
        status: status,
      });
      if (resp?.status === 200) {
        setIsWait(false);
        Notify.success("Successfully");
        setStatus(status);
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const DescriptionReadMore = ({ description }) => {
    const linkName = readMore ? t("Read Less") : t("Read More");
    return (
      <Typography variant="subtitle2">
        {readMore ? description : `${description.slice(0, 40)}...`}
        <Button
          size="small"
          color="primary"
          onClick={() => {
            setReadMore(!readMore);
          }}
        >
          {linkName}
        </Button>
      </Typography>
    );
  };

  return (
    <RootWrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          {form?.type === "exam" && form?.coverImage && (
            <Avatar
              sx={{
                height: 50,
                width: 50,
              }}
              variant="rounded"
              src={
                process.env.REACT_APP_DO_SPACE_URL +
                form?.coverImage +
                `?${Math.random()}`
              }
              alt=""
            />
          )}

          <Typography variant="h3" component="h3" alignItems={"center"}>
            <Stack direction="row" spacing={1} alignItems="center">
              {form?.includeScore && (
                <Tooltip title="This Form's Score will be included in User's Score.">
                  <SportsScoreTwoTone sx={{ mr: 1 }} />
                </Tooltip>
              )}
              <Typography variant="h3" component="h3">
                {form?.title || ""}
              </Typography>
              {form?.premium && (
                <Label sx={{ mx: 1 }} color={"success"}>
                  Premium ({form?.amount}.00 INR)
                </Label>
              )}
            </Stack>
            <Typography variant="subtitle2">
              {form?.description?.length > 40 ? (
                <DescriptionReadMore description={form?.description} />
              ) : (
                form?.description
              )}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <NewFormDialog form={form} />
          <Button
            variant="contained"
            size="small"
            color="warning"
            startIcon={<VisibilityOutlined />}
            component={Link}
            to={`/dashboard/forms/${form?._id}/preview`}
            target="_blank"
          >
            Preview
          </Button>
          <LoadingButton
            sx={{
              whiteSpace: "nowrap",
              px: 6,
            }}
            loading={isWait}
            variant="contained"
            size="small"
            color={FORM_STATUS_COLOR[status || "draft"]?.color}
            startIcon={<LanguageTwoTone />}
            onClick={() => {
              let _status = "published";
              if (["draft", "archived"]?.includes(status))
                _status = "published";
              else if (status === "published") _status = "draft";

              Notiflix.Confirm.show(
                "Confirmation",
                `Are you sure you want to ${_status} this form?`,
                "Confirm",
                "Cancel",
                () => handlePublish(_status),
                () => {},
                {
                  width: "500px",
                  messageMaxLength: 1000,
                }
              );
            }}
          >
            {["draft", "archived"]?.includes(status) ? "Publish" : "Unpublish"}
          </LoadingButton>
        </Stack>
      </Box>

      <Stack direction="row" spacing={1} alignItems="center" mt={2}>
        <Typography variant="subtitle2">
          <Label color={FORM_STATUS_COLOR[status || "draft"]?.color}>
            <b>{capitalize(status || "N/A")?.toUpperCase()}</b>
          </Label>
        </Typography>{" "}
        <Typography>•</Typography>
        <Typography variant="subtitle2">
          <Typography>
            <b>{form?.type?.toUpperCase()}</b>
          </Typography>
        </Typography>
        {form?.organisation && (
          <>
            <Typography>•</Typography>
            <Typography>{form?.organisation?.title || ""}</Typography>
          </>
        )}
        {form?.industry && (
          <>
            <Typography>•</Typography>
            <Typography>{form?.industry}</Typography>
          </>
        )}
        {form?.access && (
          <>
            <Typography>•</Typography>
            <Typography>
              {FORM_ACCESS_OBJECT[form?.access || ""]?.text}
            </Typography>
          </>
        )}
        <Typography>•</Typography>
        <Typography>{fDateTimeSuffix(form?.createdAt)}</Typography>
      </Stack>
      {form?.type === "exam" && (
        <Stack direction="row" spacing={1} alignItems="center" mt={2}>
          <Typography>
            Duration:{" "}
            <b>
              {form?.duration === 0 ? "Unlimited" : form?.duration + " min"}{" "}
            </b>
          </Typography>
          <Typography>•</Typography>
          <Typography>
            Max Attempts: <b>{form?.attempts || "Unlimited"}</b>
          </Typography>
          <Typography>•</Typography>
          <Typography>
            Passing Percentile: <b>{form?.passingPercentage || 0}%</b>
          </Typography>
        </Stack>
      )}
    </RootWrapper>
  );
}

export default FormHeader;
