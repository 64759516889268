import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import EditEducationDialog from "../addEdit/EditEducations";
import { ProfileContext } from "src/contexts/ProfileContext";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { getCustomDateDifference } from "src/utils/date-fns";
import { MonthNames } from "src/utils/commonConstants";
import Label from "src/components/Label";

export default function UserEducations() {
  const { currentUser } = useContext(GlobalContext);
  const { profile, portfolio, handleGetPortfolio, viewAsPublic } =
    useContext(ProfileContext);
  const [hoverOn, setHoverOn] = useState("");

  return (
    <Stack spacing={3}>
      <Card elevation={0}>
        <CardHeader
          title={
            <Typography variant="h3" component="h3">
              Education
            </Typography>
          }
          action={
            currentUser?.userId === profile?._id &&
            !viewAsPublic && (
              <EditEducationDialog handleGetPortfolio={handleGetPortfolio} />
            )
          }
        />
        <CardContent>
          <Timeline>
            {portfolio?.filter((item) => item?.category === "education")
              ?.length ? (
              portfolio
                ?.filter((item) => item?.category === "education")
                ?.sort((a, b) => {
                  const a_end =
                    a?.end_year + MonthNames.indexOf(a?.end_month) / 12;
                  const b_end =
                    b?.end_year + MonthNames.indexOf(b?.end_month) / 12;
                  return b_end - a_end || b?.start_year - a?.start_year;
                })
                ?.map((item, idx) => (
                  <TimelineItem
                    key={idx}
                    sx={{
                      p: 0,
                    }}
                    onMouseOver={() => setHoverOn("edu-" + idx)}
                    onMouseLeave={() => setHoverOn("")}
                  >
                    <TimelineOppositeContent
                      sx={{
                        width: "150px",
                        flex: "none",
                        whiteSpace: "nowrap",
                      }}
                      color="text.secondary"
                    >
                      <Typography>
                        {!item?.end_month ? (
                          <Label color="success">
                            <strong>Present</strong>
                          </Label>
                        ) : (
                          <>
                            {item?.end_month} {item?.end_year}
                          </>
                        )}
                      </Typography>
                      {item?.start_month} {item?.start_year}
                      {item && item?.start_month && item?.start_year && (
                        <p>
                          <b>
                            (
                            {item
                              ? getCustomDateDifference(
                                  item?.start_year,
                                  item?.start_month,
                                  item?.end_year,
                                  item?.end_month
                                ) || ""
                              : ""}
                            )
                          </b>
                        </p>
                      )}
                    </TimelineOppositeContent>
                    <TimelineSeparator
                      sx={{
                        position: "relative",
                        mx: 2,
                      }}
                    >
                      <TimelineDot
                        sx={{
                          marginTop: 0,
                          top: (theme) => theme.spacing(3),
                        }}
                        variant="outlined"
                        color="primary"
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        pb: 4,
                      }}
                    >
                      <Card elevation={hoverOn !== "edu-" + idx && 0}>
                        <CardContent>
                          <Stack
                            spacing={2}
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="h4">
                              {item?.degree}, {item?.field_of_study || ""}
                            </Typography>
                            {hoverOn === "edu-" + idx &&
                              (currentUser?.accountType === "admin" ||
                                currentUser?.userId === profile?._id) &&
                              !viewAsPublic && (
                                <EditEducationDialog
                                  portfolioItem={item}
                                  handleGetPortfolio={handleGetPortfolio}
                                />
                              )}
                          </Stack>
                          <Typography variant="subtitle1">
                            <b>{item?.title}</b>
                          </Typography>
                          {item?.grade && (
                            <Typography variant="subtitle2" component="p">
                              Grade: {item?.grade}
                            </Typography>
                          )}
                          {item?.description && (
                            <Typography variant="body1" color="text.secondary">
                              {item?.description}
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </TimelineContent>
                  </TimelineItem>
                ))
            ) : (
              <EmptyHere py={1} title={"No education added yet"} />
            )}
          </Timeline>
        </CardContent>
      </Card>
    </Stack>
  );
}
