import { HelpTwoTone } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function PricingSection({
  formInput,
  setFormInput,
  handleInputChange,
}) {
  const { currentUser } = useContext(GlobalContext);
  return (
    ["admin"]?.includes(currentUser?.accountType) && (
      <>
        {" "}
        <Stack direction="row" spacing={2}>
          <>
            <Box width={1}>
              <FormControlLabel
                checked={formInput?.premium || false}
                control={<Checkbox />}
                label="Premium"
                onChange={(e) => {
                  const _formInput = { ...formInput };
                  if (!e.target.checked) {
                    delete _formInput?.amount;
                    delete _formInput?.quota;
                    _formInput.premium = false;
                  } else {
                    _formInput.premium = true;
                    _formInput.amount = 0;
                    _formInput.quota = 0;
                  }
                  setFormInput(_formInput);
                }}
              />{" "}
            </Box>
          </>

          <Box width={1}>
            {formInput?.premium && (
              <>
                <TextField
                  label="Amount (in INR)"
                  name="amount"
                  sx={{ width: "100%" }}
                  value={formInput?.amount || ""}
                  onChange={handleInputChange}
                  type="number"
                  InputProps={{
                    endAdornment: formInput?.type !== "exam" && (
                      <InputAdornment position="end">
                        per user/month
                        <Tooltip title="This amount will be charged according to Submissions (ie. submitting 1 Rating per Month). Buyer can decide how many Rating submission he want at checkout.">
                          <HelpTwoTone
                            sx={{ mx: 1 }}
                            fontSize="small"
                            color="info"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </Box>
        </Stack>
      </>
    )
  );
}
