import {
  Button,
  TextField,
  Card,
  Box,
  Stack,
  styled,
  Avatar,
  IconButton,
  Autocomplete,
  Divider,
  Typography,
  CardHeader,
  Chip,
  Switch,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBackIos, SaveTwoTone, UploadTwoTone } from "@mui/icons-material";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Link, useSearchParams } from "react-router-dom";
import { DO_SPACES_FOLDERS, EVENT_AUDIENCE } from "src/utils/constants";
import CountriesWithStates from "src/utils/countries/states.json";
import CountriesWithCities from "src/utils/countries/cities.json";
import SuspenseLoader from "src/components/SuspenseLoader";
import QuillTextarea from "src/components/ReactQuill";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function AddEditEvent() {
  const navigator = useNavigate();
  let { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const organisationParam = searchParams.get("organisation");
  const { t } = useTranslation();
  const [formInput, setFormInput] = useState({
    address: {},
    audience: "internal",
  });
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [newTag, setNewTag] = useState("");

  const handleInputChange = (e) => {
    setFormInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (eventId) {
      (async () => {
        try {
          const resp = await axiosInstance.get(`/events/${eventId}`);
          if (resp?.status === 200) {
            setFormInput(resp?.data?.data);
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
          Notify.failure(
            error.response?.data?.message ||
              error.response?.statusText ||
              "an error occured"
          );
        }
      })();
    }
    setLoading(false);
  }, [eventId]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formInput?.title) return Notify.warning("Tot;e is required");
      if (!formInput?.contactEmail)
        return Notify.warning("Contact email is required");
      if (!formInput?.contactPhone)
        return Notify.warning("Contact phone is required");
      if (!formInput?.description)
        return Notify.warning("Description is required");
      if (!formInput?.startDateTime)
        return Notify.warning("Start date and time is required");
      if (!formInput?.endDateTime)
        return Notify.warning("End date and time is required");
      if (formInput?.startDateTime > formInput?.endDateTime) {
        return Notify.warning(
          "END date and time should be greater than Start date and time"
        );
      }

      setIsSubmitting(true);

      if (coverImage) {
        const formData = new FormData();
        formData.append("files", coverImage);
        formData.append("fileCategory", DO_SPACES_FOLDERS.EVENTS);
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp.status === 200 && imageResp.data) {
          formInput.coverImage = imageResp.data?.[0];
          setFormInput((prev) => ({
            ...prev,
            coverImage: imageResp.data?.[0],
          }));
        }
      }
      // console.log(formInput);
      formInput.type = "event";

      if (eventId) {
        const resp = await axiosInstance.put(`/events/${eventId}`, {
          ...formInput,
        });
        if (resp?.status === 200) {
          Notify.success(resp?.data?.message || "success");
        }
      } else {
        if (organisationParam) {
          formInput.organisation = organisationParam;
        }

        const resp = await axiosInstance.post(`/events`, formInput);
        if (resp?.status === 201) {
          Notify.success("Created successfully");
          setFormInput({});
          navigator(`/dashboard/events/${resp?.data?.data?._id}`);
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card sx={{ my: 2 }}>
      <CardHeader
        sx={{
          p: 3,
        }}
        title={
          <Typography variant="h4" gutterBottom>
            Manage Event
          </Typography>
        }
      />
      <Divider />
      <Stack sx={{ p: 2 }} spacing={2}>
        <Stack spacing={2}>
          <Box sx={{ mb: 4 }}>
            <AvatarWrapper>
              <Avatar
                variant="rounded"
                alt={formInput?.title}
                src={
                  coverImage
                    ? URL.createObjectURL(coverImage)
                    : formInput?.coverImage
                    ? process.env.REACT_APP_DO_SPACE_URL +
                      (formInput?.coverImage + "?" + Math.random())
                    : ""
                }
              />
              <ButtonUploadWrapper>
                <Input
                  accept="image/png, image/jpeg, image/jpg"
                  id="icon-button-file"
                  name="icon-button-file"
                  type="file"
                  onChange={(e) => {
                    setCoverImage(e.target.files[0]);
                  }}
                />
                <label htmlFor="icon-button-file">
                  <IconButton component="span" color="primary">
                    <UploadTwoTone />
                  </IconButton>
                </label>
              </ButtonUploadWrapper>
            </AvatarWrapper>
          </Box>
          <TextField
            fullWidth
            label={"Event Title*"}
            name="title"
            onChange={handleInputChange}
            value={formInput?.title || ""}
          />
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label={t("Contact Email*")}
              name="contactEmail"
              onChange={handleInputChange}
              value={formInput?.contactEmail || ""}
            />

            <TextField
              fullWidth
              label={t("Contact Phone*")}
              name="contactPhone"
              onChange={handleInputChange}
              value={formInput?.contactPhone || ""}
            />
          </Stack>
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={4} direction={"row"} alignItems={"center"}>
            <DateTimePicker
              sx={{ width: "100%" }}
              label="Start Date & Time*"
              value={
                formInput?.startDateTime
                  ? new Date(formInput?.startDateTime)
                  : null
              }
              onChange={(newValue) => {
                setFormInput({
                  ...formInput,
                  startDateTime: newValue,
                });
              }}
            />

            <Typography>-</Typography>
            <DateTimePicker
              sx={{ width: "100%" }}
              label="End Date & Time*"
              value={
                formInput?.endDateTime ? new Date(formInput?.endDateTime) : null
              }
              onChange={(newValue) => {
                setFormInput({
                  ...formInput,
                  endDateTime: newValue,
                });
              }}
            />
          </Stack>
        </LocalizationProvider>
        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
          <FormControlLabel
            sx={{ width: "100%" }}
            control={<Switch />}
            label="Is it a virtual event?"
            onChange={(e) => {
              setFormInput({
                ...formInput,
                isVirtual: e.target.checked,
              });
            }}
            checked={formInput?.isVirtual || false}
          />{" "}
          <Autocomplete
            fullWidth
            autoHighlight
            options={EVENT_AUDIENCE}
            value={EVENT_AUDIENCE.find(
              (item) => item?.value === formInput?.audience
            )}
            getOptionLabel={(option) => option?.label || ""}
            onChange={(e, value) => {
              setFormInput({
                ...formInput,
                audience: value?.value,
              });
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label="Select event type"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
                <FormHelperText>
                  {
                    EVENT_AUDIENCE.find(
                      (item) => item?.value === formInput?.audience
                    )?.description
                  }
                </FormHelperText>
              </>
            )}
          />
        </Stack>
        {!formInput?.isVirtual && (
          <>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                autoHighlight
                options={CountriesWithStates?.map((item) => item?.name)}
                value={formInput?.address?.country || "India"}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...formInput.address,
                      country: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                autoHighlight
                options={
                  CountriesWithStates?.find(
                    (item) =>
                      item?.name === (formInput?.address?.country || "India")
                  )?.states?.map((item) => item?.name) || []
                }
                value={formInput?.address?.state || ""}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...formInput.address,
                      state: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                autoHighlight
                options={
                  CountriesWithCities?.find(
                    (item) =>
                      item?.country === (formInput?.address?.country || "India")
                  )?.cities || []
                }
                value={formInput?.address?.city || ""}
                onChange={(e, value) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...formInput.address,
                      city: value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                label="Pincode"
                name="pincode"
                placeholder="Enter Pincode/Zipcode"
                value={formInput?.address?.pincode || ""}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    address: {
                      ...formInput.address,
                      pincode: e.target.value,
                    },
                  });
                }}
              />
            </Stack>
            <TextField
              fullWidth
              label={t("Location's Direction (Complete Address)")}
              onChange={(e) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    completeAddress: e.target.value,
                  },
                });
              }}
              value={formInput?.address?.completeAddress || ""}
              multiline
              rows={3}
            />
          </>
        )}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            sx={{ mb: 2, width: "25%" }}
            placeholder="Add and press enter to add tags"
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                setFormInput({
                  ...formInput,
                  tags: [...(formInput?.tags || []), newTag],
                });
                setNewTag("");
              }
            }}
            value={newTag}
          />
          <Box sx={{ width: "75%" }}>
            {formInput?.tags?.length > 0 ? (
              formInput?.tags?.map((tag) => (
                <ChipWrapper
                  key={tag}
                  label={tag}
                  onDelete={() => {
                    setFormInput({
                      ...formInput,
                      tags: formInput?.tags?.filter((item) => item !== tag),
                    });
                  }}
                />
              ))
            ) : (
              <Typography variant="body2" color="text.secondary" gutterBottom>
                No tags added yet
              </Typography>
            )}
          </Box>
        </Stack>
        <QuillTextarea
          placeholder="Write something about the Event*"
          onChange={(value) => {
            setFormInput({
              ...formInput,
              description: value,
            });
          }}
          value={formInput?.description || ""}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box>
            {formInput?._id && (
              <Button
                startIcon={<ArrowBackIos />}
                variant="text"
                component={Link}
                to={`/dashboard/events/${eventId}`}
              >
                {t("Back to Event")}
              </Button>
            )}
          </Box>
          <LoadingButton
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={<SaveTwoTone />}
            disabled={isSubmitting}
            type="submit"
            size="large"
            variant="contained"
            onClick={handleFormSubmit}
          >
            {t(formInput?._id ? "Update" : "Create")}
          </LoadingButton>
        </Box>
      </Stack>
    </Card>
  );
}

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `
    position: relative;
    overflow: visible;
    display: inline-block;
    .MuiAvatar-root {
      width: ${theme.spacing(32)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      font-weight: bold;
      margin: ${theme.spacing(0.5)};
`
);
