import { DeleteTwoTone, SaveTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TagsAutocomplete from "../common/TagsAutocomplete";
import { useNavigate, useParams } from "react-router";
import { Confirm, Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";
import axiosInstance from "src/utils/axiosInstance";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { useSearchParams } from "react-router-dom";

export default function CreateEditGroup() {
  let { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");
  const navigate = useNavigate();
  const [formInput, setFormInput] = useState({});
  const [newTags, setNewTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!groupId) return;
    (async () => {
      try {
        const resp = await axiosInstance.get("/groups/" + groupId);
        if (resp?.status === 200) {
          setFormInput(resp?.data);
        }
      } catch (error) {
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [groupId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (!formInput?.title) {
        return Notify.failure("Please enter a title");
      }
      setLoading(true);

      if (groupId) {
        const resp = await axiosInstance.put("/groups/" + groupId, formInput);
        if (resp?.status === 200) {
          Notify.success("Group updated successfully");
          setLoading(false);
        }
        return;
      }

      if (!typeParam) return Notify.failure("Group type is missing");

      formInput.type = typeParam;
      const resp = await axiosInstance.post("/groups", formInput);
      if (resp?.status === 201) {
        Notify.success("Group created successfully");

        setLoading(false);
        navigate(
          "/dashboard/groups/organisations/" +
            resp?.data?._id +
            "/edit?tab=" +
            typeParam
        );
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleDelete = async () => {
    try {
      const resp = await axiosInstance.delete("/groups/" + groupId);
      if (resp?.status === 200) {
        Notify.success("Group deleted successfully");
        navigate("/dashboard/groups?type=" + typeParam);
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <CardHeader
          title={<h2>New Group</h2>}
          subheader={
            groupId && (
              <Stack direction="row" spacing={2}>
                <Typography variant="body2" color="text.secondary">
                  Group ID: {formInput?.groupId}
                </Typography>
                <Typography>•</Typography>
                {formInput?.organisation && (
                  <>
                    <Typography variant="body2" color="text.secondary">
                      {formInput?.organisation?.title || "N/A"}
                    </Typography>
                    <Typography>•</Typography>
                  </>
                )}
                <Typography variant="body2" color="text.secondary">
                  {formInput?.createdBy
                    ? formInput?.createdBy?.firstName +
                      " " +
                      formInput?.createdBy?.lastName
                    : "N/A"}
                </Typography>
                <Typography>•</Typography>
                <Typography variant="body2" color="text.secondary">
                  {fDateTimeSuffix(formInput?.createdAt)}
                </Typography>
              </Stack>
            )
          }
          action={
            <Stack direction="row" spacing={2}>
              {groupId && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteTwoTone />}
                  size="small"
                  onClick={() => {
                    Confirm.show(
                      "Confirmation",
                      "Are you sure you want to delete?",
                      "Confirm",
                      "Cancel",
                      () => handleDelete(),
                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      }
                    );
                  }}
                >
                  Delete
                </Button>
              )}

              <LoadingButton
                variant="contained"
                color="success"
                startIcon={<SaveTwoTone />}
                size="small"
                onClick={handleSave}
                loading={loading}
              >
                {groupId ? "Update" : "Save"}
              </LoadingButton>
            </Stack>
          }
        />
        <Divider />
        <CardContent>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <TextField
              label="Group Title"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />
            <TagsAutocomplete
              totalItems={["tag1", "tag2", "tag3"]}
              formInput={formInput}
              setFormInput={setFormInput}
              newTags={newTags}
              setNewTags={setNewTags}
            />

            <TextField
              label="Group Description"
              fullWidth
              name="description"
              value={formInput?.description || ""}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
