import React, { useContext, useEffect, useState } from "react";
import { Card, Divider, Grid, Stack } from "@mui/material";
import { ProfileContext } from "src/contexts/ProfileContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import AboutNavigation from "./components/AboutNavigation";
import AboutDetails from "./components/AboutDetails";
import RatingConsentForm from "./components/RatingConsentForm";
import PhotosGallery from "./components/PhotosGallery";
import UserDocuments from "./components/UserDocuments";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import { useSearchParams } from "react-router-dom";
import { ListOfEmployementHistory } from "../overview/EmploymentHistory";
import UserExperiences from "../overview/Experiences";
import UserEducations from "../overview/Educations";
import UserProjects from "../overview/Projects";
import UserCertificates from "../overview/UserCertificates";
import { AboutSection, SocialSection } from "../components/ProfileSections";
import CleanExitVerification from "../../verification/CleanExitVerification";
import ProfileStregth from "../components/ProfileStregth";
import { GlobalContext } from "src/contexts/GlobalContext";
import CleanExitIdCard from "../../common/CleanExitIdCard";

export default function About({ page }) {
  const { currentUser } = useContext(GlobalContext);
  const { loading, profile, viewAsPublic } = useContext(ProfileContext);
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("details");
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (searchParams.get("about_tab")) {
      setSelectedTab(searchParams.get("about_tab") || "details");
    }
  }, [searchParams]);

  const handleGetDocuments = async () => {
    try {
      const resp = await axiosInstance.get("/documents", {
        params: {
          user: profile?._id,
        },
      });
      if (resp.status === 200) {
        setDocuments(resp.data);
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (!profile) return;
    handleGetDocuments();
  }, [profile]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack my={page === "settings" && 2} spacing={2} mb={6}>
      <AboutNavigation
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        documents={documents}
      />
      {selectedTab === "details" && (
        <>
          <Grid container>
            <Grid item xs={12} md={3.8}>
              <Stack spacing={2}>
                <CleanExitIdCard idCard={profile?.idCard} />
                {!viewAsPublic && <CleanExitVerification />}

                {currentUser?.userId === profile?._id && !viewAsPublic && (
                  <ProfileStregth />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={0.2} />
            <Grid item xs={12} md={8}>
              <Stack spacing={2}>
                <AboutDetails />
                <AboutSection />
                <SocialSection />
              </Stack>
            </Grid>
          </Grid>
          <ListOfEmployementHistory whichPage="about" />
          <Card elevation={0}>
            <UserExperiences />
            <Divider />
            <UserEducations />
            <Divider />
            <UserProjects />
            <Divider />
            <UserCertificates />
          </Card>
        </>
      )}
      {selectedTab === "consent" && <RatingConsentForm documents={documents} />}
      {selectedTab === "photos" && (
        <PhotosGallery
          documents={documents}
          handleGetDocuments={handleGetDocuments}
        />
      )}
      {selectedTab === "documents" && (
        <UserDocuments
          documents={documents}
          handleGetDocuments={handleGetDocuments}
        />
      )}
    </Stack>
  );
}
