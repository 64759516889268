import {
  Box,
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  alpha,
  useTheme,
  Stack,
  CardHeader,
  Divider,
  Button,
  Chip,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import QrCodeLinker from "../../common/QrCodeLinker";
import BulkActions from "./BulkActions";
import { CopyAll, DoneTwoTone } from "@mui/icons-material";
import { Notify } from "notiflix";
import { fDate } from "src/utils/date-fns";
import { FEATURES_ACCESS } from "src/utils/constants";

export default function OrgInfo({ profile }) {
  const { currentUser } = useContext(GlobalContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [activeFeaturesList, setActiveFeaturesList] = useState([]);

  useEffect(() => {
    if (profile?.data?.featuresAccess) {
      setActiveFeaturesList(
        Object.entries(profile?.data?.featuresAccess || {})
          ?.filter(([key, value]) => value)
          ?.map(([key, value]) => key)
      );
    }
  }, [profile]);

  return (
    <Card sx={{ p: 3 }}>
      {currentUser?.accountType === "admin" && (
        <Card
          elevation={0}
          sx={{
            background: `${alpha(theme.colors.alpha.black[100], 0.05)}`,
            width: "100%",
          }}
        >
          <CardHeader
            title="Features Access"
            action={
              activeFeaturesList?.length > 0 ? (
                activeFeaturesList?.map((key) => (
                  <Chip
                    color={"success"}
                    variant={"filled"}
                    icon={<DoneTwoTone />}
                    label={<b>{FEATURES_ACCESS?.[key]?.name || key}</b>}
                    key={key}
                    sx={{ m: 0.5 }}
                  />
                ))
              ) : (
                <Typography variant="subtitle1" color="textSecondary">
                  No Features Access
                </Typography>
              )
            }
          />
        </Card>
      )}
      <Stack spacing={2} my={2} direction={{ xs: "column", md: "row" }}>
        <Card
          elevation={0}
          sx={{
            background: `${alpha(theme.colors.alpha.black[100], 0.05)}`,
            width: "100%",
            maxWidth: { xs: "100%", md: "50%" },
          }}
        >
          <CardHeader title="Organisation Details" />
          <Divider />
          <List dense>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={`${t("Industry Type(s)")}:`}
                secondary={profile?.data?.industry?.join(", ") || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={`${t("Organisation Email")}:`}
                secondary={profile?.data?.companyEmail || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={`${t("Organisation Contact")}:`}
                secondary={profile?.data?.companyPhone || "-"}
              />
            </ListItem>{" "}
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={`${t("Location")}:`}
                secondary={
                  [
                    profile?.data?.address?.city,
                    profile?.data?.address?.state,
                    profile?.data?.address?.country,
                    profile?.data?.address?.pincode,
                  ]
                    .filter((item) => item)
                    .join(", ") || "-"
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                }}
                primary={`${t("Complete Address")}:`}
                secondary={profile?.data?.companyAddress || "-"}
              />
            </ListItem>
            {profile?.customFields?.map((field, index) => (
              <ListItem key={index}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: "h5",
                  }}
                  primary={`${field?.label}:`}
                  secondary={
                    {
                      text: profile?.data?.meta?.[field?.name]?.value || "-",
                      date: fDate(
                        profile?.data?.meta?.[field?.name]?.value || new Date()
                      ),
                      file: (
                        <Button
                          size="small"
                          component={"a"}
                          sx={{ px: 0 }}
                          href={
                            process.env.REACT_APP_DO_SPACE_URL +
                            profile?.data?.meta?.[field?.name]?.value
                          }
                          target="_blank"
                        >
                          {profile?.data?.meta?.[field?.name]?.value
                            ?.split("/")
                            ?.pop()}
                        </Button>
                      ),
                    }[field?.type]
                  }
                />
              </ListItem>
            ))}
          </List>
        </Card>
        <Stack
          spacing={2}
          direction="column"
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", md: "50%" },
          }}
        >
          {" "}
          {profile?.owner && (
            <Card
              elevation={0}
              sx={{
                background: `${alpha(theme.colors.alpha.black[100], 0.05)}`,
                width: "100%",
                height: "100%",
              }}
            >
              <CardHeader title="Owner Details" />
              <Divider />
              <List dense>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h5",
                    }}
                    primary={`${t("Name")}:`}
                  />
                  <Typography variant="subtitle1" textAlign={"end"}>
                    {profile?.owner?.firstName || "-"}{" "}
                    {profile?.owner?.lastName || ""}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h5",
                    }}
                    primary={`${t("Email")}:`}
                  />
                  <Typography variant="subtitle1" textAlign={"end"}>
                    {profile?.owner?.email || "-"}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "h5",
                    }}
                    primary={`${t("Contact")}:`}
                  />
                  <Typography variant="subtitle1" textAlign={"end"} ri>
                    {profile?.owner?.phoneNumber || "-"}
                  </Typography>
                </ListItem>
              </List>
            </Card>
          )}
          {!profile?.data?.isLocked && (
            <Box>
              <Card
                elevation={0}
                sx={{
                  background: `${alpha(theme.colors.alpha.black[100], 0.05)}`,
                  width: "100%",
                  height: "100%",
                }}
              >
                <CardHeader
                  title="QR Code & Link"
                  action={
                    <Button
                      size="small"
                      startIcon={<CopyAll />}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_BASE_URL}/links/${profile?.data?._id}/org`
                        );
                        Notify.success("Link Copied");
                      }}
                    >
                      Copy Link
                    </Button>
                  }
                />
                <Divider />
                <QrCodeLinker
                  accountType={"org"}
                  accountId={profile?.data?._id}
                />
              </Card>
            </Box>
          )}
        </Stack>
      </Stack>
      {(currentUser?.accountType === "admin" ||
        currentUser?.orgId === profile?.data?._id) && (
        <BulkActions
          organisationId={profile?.data?._id}
          profile={profile}
          whichPage={"organisation"}
        />
      )}
    </Card>
  );
}
