import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Container,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import { fDate, fTime } from "src/utils/date-fns";
import Logo from "src/components/Logo";
import { useParams } from "react-router";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import EmptyHere from "src/components/EmptyHere";
import Label from "src/components/Label";
import { ORDER_STATUS } from "src/utils/constants";
import { ReactPrint } from "src/components/ReactToPrint";

const BoxWrapper = styled(Box)(
  ({ theme }) => `
  border-radius: ${theme.general.borderRadius};
`
);

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
`
);

const LogoWrapper = styled(Box)(
  () => `
    width: '52px'
`
);

export default function InvoiceDetail() {
  const { t } = useTranslation();
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!invoiceId) {
      Notify.failure("Invoice not found");
    }

    (async () => {
      try {
        setIsLoading(true);
        const resp = await axiosInstance.get(`/orders/invoice/${invoiceId}`);
        if (resp?.status === 200) setInvoice(resp?.data || {});
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [invoiceId]);

  const [print, setPrint] = useState(false);
  const handleClickPrint = () => {
    setPrint(true);
    setTimeout(() => {
      setPrint(false);
    }, 1000);
  };

  return loading ? (
    <SuspenseLoader />
  ) : invoice && invoice?._id ? (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" my={2}>
        <Button
          variant="outlined"
          startIcon={<DownloadTwoToneIcon />}
          onClick={handleClickPrint}
        >
          {t("Download PDF")}
        </Button>
      </Box>

      <ReactPrint print={print}>
        <Card sx={{ p: 4, mb: 3 }} id="rating-section-print" elevation={0}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <Typography sx={{ py: 2 }} variant="h4"></Typography>
              <Typography>
                <Typography variant="h5" fontWeight="normal">
                  319A, Logix Technova,
                </Typography>
                <Typography variant="h5" gutterBottom fontWeight="normal">
                  Sector 132, Noida, 201301
                </Typography>
                <Typography variant="h5" fontWeight="normal">
                  Uttar Pradesh, India
                </Typography>
                <Typography variant="h5" mt={1}>
                  Email: <b>support@cleanexit.org</b>
                </Typography>
              </Typography>
            </Box>
            <Box textAlign="right">
              <Typography variant="h1" gutterBottom>
                {t("Invoice")}
              </Typography>
              <Typography variant="h3" color="text.secondary">
                #{invoice?.invoiceId?.toUpperCase()}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" gutterBottom>
                {t("Invoice for")}:
              </Typography>
              <Typography sx={{ pb: 2 }} variant="h3">
                {invoice?.user?.firstName ? (
                  <>
                    {invoice?.user?.firstName || "-"}{" "}
                    {invoice?.user?.lastName || ""}
                  </>
                ) : (
                  <>{invoice?.organisation?.title || "-"}</>
                )}
              </Typography>
              <Typography variant="h5" fontWeight="normal">
                Email:{" "}
                {invoice?.user?.email ||
                  invoice?.organisation?.companyEmail ||
                  "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} textAlign="right">
              <Grid container spacing={8} justifyContent={"flex-end"}>
                <Grid item>
                  <Typography variant="subtitle2" gutterBottom>
                    {t("Issued on")}:
                  </Typography>
                  <Typography sx={{ pb: 2 }} variant="body1">
                    {invoice?.createdAt ? (
                      <>
                        <b>{fDate(invoice?.createdAt)}</b>
                        <br />
                        {fTime(invoice?.createdAt)}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" gutterBottom>
                    {t("Expires on")}:
                  </Typography>
                  <Typography sx={{ pb: 2 }} variant="body1">
                    {invoice?.expiresAt ? (
                      <>
                        <b>{fDate(invoice?.expiresAt)}</b>
                        <br />
                        {fTime(invoice?.expiresAt)}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <BoxWrapper textAlign="right" mt={1} py={3}>
                <Label
                  color={ORDER_STATUS[invoice?.status]?.color}
                  sx={{
                    fontSize: "1rem",
                  }}
                >
                  <b>{ORDER_STATUS[invoice?.status]?.label?.toUpperCase()}</b>
                </Label>
              </BoxWrapper>
            </Grid>
          </Grid>
          <Box sx={{ my: 8 }}></Box>
          <TableWrapper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 300 }}>{t("Item")}</TableCell>
                    <TableCell>{t("Qty")}</TableCell>
                    <TableCell>{t("Amount (INR)")}</TableCell>
                    <TableCell>{t("Total")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: 300 }}>
                      <Typography fontSize={"1rem"}>
                        <b>{invoice?.title || ""}</b>
                        {invoice?.manualItems?.length > 0 &&
                          invoice?.manualItems.map((item, index) => (
                            <Typography
                              key={index}
                              variant="subtitle1"
                              color="text.secondary"
                            >
                              <small>
                                {index + 1}. {item?.title || "-"}
                              </small>
                            </Typography>
                          ))}
                        {invoice?.meta?.startDate && (
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            <small>
                              Start date:{" "}
                              <b>{fDate(invoice?.meta?.startDate)}</b>
                            </small>
                          </Typography>
                        )}
                        {invoice?.meta?.endDate && (
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            <small>
                              End date: <b>{fDate(invoice?.meta?.endDate)}</b>
                            </small>
                          </Typography>
                        )}
                      </Typography>
                      {(invoice?.meta?.organisationName ||
                        invoice?.organisation?.title) && (
                        <Typography variant="subtitle1" color="text.secondary">
                          {invoice?.organisation?.title ||
                            invoice?.meta?.organisationName ||
                            "-"}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>{invoice?.quantity || 1}</TableCell>
                    <TableCell>
                      {invoice?.amountPaid || invoice?.amountToPay
                        ? Number(
                            (invoice?.amountPaid || invoice?.amountToPay) /
                              (invoice?.quantity || 1)
                          ).toFixed(2)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      Rs.{" "}
                      {invoice?.amountPaid || invoice?.amountToPay
                        ? Number(
                            invoice?.amountPaid || invoice?.amountToPay
                          ).toFixed(2)
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={0} />
                    <TableCell colSpan={4} align="right">
                      <Typography
                        gutterBottom
                        variant="caption"
                        color="text.secondary"
                        fontWeight="bold"
                      >
                        {t("Total")}:
                      </Typography>
                      <Typography variant="h3" fontWeight="bold">
                        Rs.{" "}
                        {invoice?.amountPaid || invoice?.amountToPay
                          ? Number(
                              invoice?.amountPaid || invoice?.amountToPay
                            ).toFixed(2)
                          : "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TableWrapper>
          <Box sx={{ mt: 16 }}>
            <Typography variant="h6">
              {t("Need Help or Have Questions?")}
            </Typography>
            <Typography variant="subtitle1">
              If you have any questions, concerns, or need further assistance
              regarding this invoice,
              <br /> feel free to reach out. We're here to help!
            </Typography>
          </Box>
        </Card>
      </ReactPrint>
    </Container>
  ) : (
    <EmptyHere
      title="Invoice not found"
      subtitle={`Invoice with ID ${invoiceId?.toUpperCase()} not found`}
    />
  );
}
