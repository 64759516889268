import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  capitalize,
} from "@mui/material";
import { Notify } from "notiflix";
import { useContext, useEffect, useState } from "react";
import EmptyHere from "src/components/EmptyHere";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { InputFields, InputTypesObject } from "src/utils/constants";

export default function CustomerRelationFields() {
  const { getTagsByType } = useContext(GlobalContext);
  const [industryTags, setIndustryTags] = useState({
    type: "",
    inputs: [],
  });
  const [loading, setLoading] = useState(false);
  const [customerRelations, setCustomerRelations] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [formInput, setFormInput] = useState({
    label: "",
    type: "text",
    value: "",
  });

  useEffect(() => {
    if (!selectedIndustry) return;
    (async () => {
      const resp = await axiosInstance.get("/tags/type", {
        params: {
          type: selectedIndustry,
        },
      });
      if (resp?.status === 200) {
        setIndustryTags(
          resp?.data || {
            type: selectedIndustry,
            inputs: [],
          }
        );
      }
    })();
  }, [selectedIndustry]);

  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  const handleSubmit = async (reqData) => {
    try {
      // reqObj = {action: "add/remove", type, inputString, tag: "tag to be removed"}
      setLoading(true);

      const resp = await axiosInstance.put(`/tags`, reqData);
      if (resp.status === 200) {
        setIndustryTags(resp?.data?.data);
        setLoading(false);
      }
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Autocomplete
        autoHighlight
        sx={{ width: "40%" }}
        options={customerRelations || []}
        getOptionLabel={(option) => capitalize(option || "")}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Customer Relation"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(e, value) => setSelectedIndustry(value)}
        value={selectedIndustry}
      />

      <Alert severity="warning" sx={{ my: 2 }}>
        These Input fields will be used while adding a new Customer.
      </Alert>

      {selectedIndustry && (
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={4}>
            <TextField
              fullWidth
              label={"Enter field label"}
              type="text"
              variant="outlined"
              size="small"
              value={formInput?.label}
              onChange={(e) => {
                setFormInput({ ...formInput, label: e.target.value });
              }}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="field-type">Select Input Type</InputLabel>
              <Select
                labelId="field-type"
                value={formInput?.type}
                label="Select Input Type"
                onChange={(e) => {
                  setFormInput({ ...formInput, type: e.target.value });
                }}
              >
                {InputFields?.map((item, idx) => (
                  <MenuItem value={item?.value} key={idx}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LoadingButton
              size="small"
              color="error"
              variant="contained"
              onClick={async () => {
                const name = formInput?.label
                  ?.toLowerCase()
                  .split(" ")
                  .join("-");
                const _formInput = {
                  ...formInput,
                  name,
                };

                if (
                  industryTags?.inputs?.some(
                    (item) => item?.name === _formInput?.name
                  )
                ) {
                  Notify.warning("This Field already exists");
                  return;
                }
                const _tags = [...industryTags?.inputs, _formInput];
                const _industryTags = {
                  ...industryTags,
                  inputs: _tags,
                };
                setIndustryTags(_industryTags);

                const done = await handleSubmit(_industryTags);
                if (done) {
                  setFormInput({
                    name: "",
                    label: "",
                    type: "text",
                    value: "",
                  });
                }
              }}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Stack>

          <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name (ID)</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Input Type</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              {industryTags?.inputs?.length > 0 ? (
                <TableBody>
                  {industryTags?.inputs?.map((item, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item?.name}
                      </TableCell>
                      <TableCell>
                        <b>{item?.label}</b>
                      </TableCell>
                      <TableCell>
                        {(() => {
                          const _icon = InputTypesObject[item?.type]?.icon;
                          return _icon ? _icon : null;
                        })()}
                        <Typography sx={{ mx: 1 }} component={"span"}>
                          {item?.type}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="error"
                          onClick={() => {
                            const _tags = industryTags?.inputs?.filter(
                              (i) => i?.name !== item?.name
                            );
                            const _industryTags = {
                              ...industryTags,
                              inputs: _tags,
                            };
                            setIndustryTags(_industryTags);
                            handleSubmit(_industryTags);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <EmptyHere title={"No Fields added yet"} subtitle={""} />
              )}
            </Table>
          </TableContainer>
        </CardContent>
      )}
    </Box>
  );
}
