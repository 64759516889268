import {
  DriveFolderUploadOutlined,
  ForwardToInboxOutlined,
  GroupAddOutlined,
} from "@mui/icons-material";
import {
  Box,
  capitalize,
  Card,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Confirm, Notify } from "notiflix";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";
import InviteViaCsv from "./components/InviteViaCsv";
import InviteViaInput from "./components/InviteViaInput";
import axiosInstance from "src/utils/axiosInstance";
import { LoadingButton } from "@mui/lab";

export default function NewInvitation() {
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const organisationParam = searchParams.get("organisation");
  const [uploadType, setUploadType] = useState("upload-users");
  const [users, setUsers] = useState([]);
  const [isWait, setIsWait] = useState(false);
  const [response, setResponse] = useState(null);

  const handleSubmit = async () => {
    try {
      setIsWait(true);
      setResponse(null);

      const allUsers = [];
      for (const user of users) {
        allUsers.push({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          meta: {
            employeeId: user.employeeId,
            relation: user.relation,
          },
        });
      }

      const reqObj = {
        users: JSON.stringify(allUsers),
      };
      if (organisationParam) {
        reqObj.organisation = organisationParam;
      }

      const resp = await axiosInstance.post("/invitations/bulk", reqObj);
      if (resp?.status === 200) {
        setIsWait(false);
        setResponse(resp.data);
        if (resp.data?.errors?.length === 0) {
          Notify.success("Invitations sent successfully");
          navigate("/dashboard/invitations");
        }
      }
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Box my={2.5}>
      <Stack spacing={2} mb={4}>
        <Typography variant="h1" component="h1" gutterBottom>
          Invite User to Join
        </Typography>
        <Typography variant="h6" gutterBottom color={"textSecondary"}>
          {`Invite users to join ${
            currentUser?.orgId ? "your organisation" : ""
          } by adding or uploading a CSV, XLS or XLSX file.`}
        </Typography>
      </Stack>

      <Box my={3}>
        <Card variant="outlined" sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" component="h2" gutterBottom mb={3}>
              {uploadType === "add-users" ? "Add " : "Upload "} Users to invite
            </Typography>
            <Stack spacing={2}>
              <ToggleButtonGroup
                value={uploadType}
                exclusive
                onChange={(e, value) => {
                  setUploadType(value || uploadType);
                }}
              >
                <ToggleButton value="add-users">
                  <GroupAddOutlined />
                </ToggleButton>
                <ToggleButton value="upload-users">
                  <DriveFolderUploadOutlined />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>
          <Divider sx={{ mb: 2 }} />

          {uploadType === "add-users" && (
            <InviteViaInput users={users} setUsers={setUsers} />
          )}
          {uploadType === "upload-users" && (
            <InviteViaCsv users={users} setUsers={setUsers} />
          )}

          <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
            <Box>
              {response && response?.errors?.length > 0 && (
                <Stack spacing={1}>
                  <Typography variant="h6" color="error">
                    Some users were not invited
                  </Typography>
                  <Stack spacing={1}>
                    {response.errors.map((error, index) => (
                      <Typography key={index} variant="body2" color="error">
                        -{" "}
                        {capitalize(
                          error?.reason || error?.message || "Unknown error"
                        )}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              )}
            </Box>
            <LoadingButton
              variant="contained"
              color="success"
              onClick={() => {
                Confirm.show(
                  "Confirmation",
                  "Make sure all the details are correct before sending the invites",
                  "Confirm",
                  "Cancel",
                  () => handleSubmit(),
                  () => {},
                  {
                    width: "500px",
                    messageMaxLength: 1000,
                  }
                );
              }}
              startIcon={<ForwardToInboxOutlined />}
              disabled={!users.length}
              loading={isWait}
            >
              Send Invites Now
            </LoadingButton>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
}
