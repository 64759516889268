import { AddTwoTone, BlockOutlined, SaveTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CountriesWithStates from "src/utils/countries/states.json";
import CountriesWithCities from "src/utils/countries/cities.json";
import Notiflix, { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { GlobalContext } from "src/contexts/GlobalContext";
import InputFieldsSwitch from "../common/InputFieldsSwitch";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function AddUpdateCustomer() {
  const navigator = useNavigate();
  const { customerId } = useParams();
  const [searchParams] = useSearchParams();
  const organisationParam = searchParams.get("organisation");
  const { getTagsByType } = useContext(GlobalContext);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [errors, setErrors] = useState(null);
  const [customerRelations, setCustomerRelations] = useState([]);
  const [selectedCustomerRelation, setSelectedCustomerRelation] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFormInput = (e) => {
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!customerId) {
      setLoading(false);
      return;
    }
    (async () => {
      const resp = await axiosInstance.get(`/customer-relations/${customerId}`);
      if (resp?.status === 200) {
        const _customerRelation = resp?.data?.data;
        setFormInput({
          firstName: _customerRelation?.user?.firstName,
          lastName: _customerRelation?.user?.lastName,
          email: _customerRelation?.user?.email,
          phoneNumber: _customerRelation?.user?.phoneNumber,
          ..._customerRelation,
        });
        setSelectedCustomerRelation(_customerRelation?.relation);
      }
    })();
    setLoading(false);
  }, [customerId]);

  useEffect(() => {
    setLoading(true);
    if (!selectedCustomerRelation) {
      setLoading(false);
      return;
    }
    (async () => {
      const resp = await axiosInstance.get("/tags/type", {
        params: {
          type: selectedCustomerRelation,
        },
      });
      if (resp?.status === 200) {
        setCustomFields(resp?.data?.inputs || []);
      }
    })();
    setLoading(false);
  }, [selectedCustomerRelation]);

  const handleAddCustomer = async () => {
    try {
      if (!formInput?.firstName || !formInput?.lastName || !formInput?.email) {
        Notify.failure("Please fill all required fields");
        return;
      }
      setIsWait(true);

      // map metas (file, text etc)
      const _meta = {};
      if (formInput?.meta) {
        const mappedFormInputMeta = Object.entries(formInput?.meta)?.map(
          async ([key, value]) => {
            let _value = value;
            // if value is file
            if (value instanceof File) {
              try {
                const _fileName = key + "." + value?.name?.split(".")?.pop();
                const modifiedFile = new File([value], _fileName, {
                  type: value.type,
                });
                const formData = new FormData();
                formData.append("files", modifiedFile);
                const resp = await axiosInstance.post(
                  "/users/upload-media",
                  formData,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                );
                if (resp?.status === 200 && resp?.data) {
                  _value = resp?.data?.[0];
                }
              } catch (error) {
                console.log(error);
                throw new Error(error);
              }
            }

            let isCustomeField = customFields?.find(
              (item) => item?.name === key
            );
            if (isCustomeField) {
              // if it is custom field
              if (typeof _value === "object") {
                // old value
                _meta[key] = _value;
              } else {
                // new value
                _meta[key] = {
                  ...isCustomeField,
                  value: _value,
                };
              }
            } else {
              // if it is simple meta key:value
              _meta[key] = _value;
            }
          }
        );
        // Wait for all promises to resolve
        await Promise.all(mappedFormInputMeta);
      }
      /// attach meta to formInput
      setFormInput((prev) => ({ ...prev, meta: _meta }));

      if (!!customerId) {
        const resp = await axiosInstance.put(
          `/customer-relations/${customerId}`,
          {
            relation: formInput?.relation,
            meta: _meta,
            address: formInput?.address,
          }
        );
        if (resp?.status === 200) {
          Notify.success("Updated successfully");
        }
        setIsWait(false);
        return;
      }

      if (organisationParam) {
        formInput.organisation = organisationParam;
      }
      formInput.isVerified = true;
      const userResp = await axiosInstance.post(`/auth/create`, formInput);
      const _user = userResp?.data;

      // 200 means user already exists
      // 201 means user created successfully
      if (userResp.status === 200) {
        // if (
        //   _user?.firstName !== formInput?.firstName ||
        //   _user?.lastName !== formInput?.lastName ||
        //   _user?.phoneNumber !== formInput?.phoneNumber
        // ) {
        // setErrors({
        //   message:
        //     "User already exists in our Database, please use another email or Load User's Profile by clicking on the 'Load Profile' button",
        //   subtitle:
        //     "Same personal email but different First Name, Last Name, Phone Number",
        //   color: "error",
        //   data: userResp?.data,
        // });
        // setIsWait(false);
        // return;
        // }
      }

      const resp = await axiosInstance.post(`/customer-relations`, {
        user: _user?._id,
        relation: formInput?.relation,
        type: "manual",
        meta: _meta,
        address: formInput?.address,
        organisation: formInput?.organisation,
      });
      if (resp?.status === 200) {
        Notify.success("Added successfully");
        navigator(
          `/dashboard/customer-relations` +
            (organisationParam ? `?organisation=${organisationParam}` : "")
        );
      }
      setIsWait(false);
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const checkIfCustomerExists = async () => {
    try {
      if (!formInput?.email) return;
      // check if email is valid
      if (!formInput?.email?.includes("@")) return;

      const resp = await axiosInstance.post("/users/check", {
        email: formInput?.email,
      });
      if (resp?.status === 200 && resp?.data) {
        setErrors({
          message:
            "User already exists in our Database, please use another email or Load User's Profile by clicking on the 'Load Profile' button",
          subtitle:
            "Same personal email but different First Name, Last Name, Phone Number",
          color: "error",
          data: resp?.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivateDeactivate = async () => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.put(
        `/customer-relations/${customerId}`,
        {
          isActive: !formInput?.isActive,
        }
      );
      if (resp?.status === 200) {
        Notify.success("Updated successfully");

        // navigate back
        navigator(
          `/dashboard/customer-relations` +
            (organisationParam ? `?organisation=${organisationParam}` : "")
        );
      }
      setIsWait(false);
    } catch (error) {
      console.log(error);
      setIsWait(false);
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card sx={{ my: 2 }} elevation={0}>
      <CardHeader
        title="Add New"
        subheader="Add new customer, client, vendor, supplier etc."
      />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            Contact Person
          </Divider>
          {errors && (
            <Alert
              sx={{
                position: "relative",
                bottom: "20px",
              }}
              severity={errors?.color}
              action={
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setFormInput({
                      ...formInput,
                      firstName: errors?.data?.firstName,
                      lastName: errors?.data?.lastName,
                      email: errors?.data?.email,
                      phoneNumber: errors?.data?.phoneNumber,
                    });
                    setErrors(null);
                  }}
                >
                  Load Profile
                </Button>
              }
            >
              <AlertTitle>{errors?.message}</AlertTitle>
              {errors?.subtitle}
            </Alert>
          )}

          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Personal Email*"
              name="email"
              variant="outlined"
              value={formInput?.email || ""}
              onChange={handleFormInput}
              disabled={!!customerId}
              onBlur={checkIfCustomerExists}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              variant="outlined"
              value={formInput?.phoneNumber || ""}
              onChange={handleFormInput}
              disabled={!!customerId}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="First Name*"
              name="firstName"
              variant="outlined"
              value={formInput?.firstName || ""}
              onChange={handleFormInput}
              disabled={!!customerId}
            />
            <TextField
              fullWidth
              label="Last Name*"
              name="lastName"
              variant="outlined"
              value={formInput?.lastName || ""}
              onChange={handleFormInput}
              disabled={!!customerId}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Autocomplete
              fullWidth
              autoHighlight
              options={CountriesWithStates?.map((item) => item?.name)}
              value={formInput?.address?.country || "India"}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput.address || {}),
                    country: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              autoHighlight
              options={
                CountriesWithStates?.find(
                  (item) =>
                    item?.name === (formInput?.address?.country || "India")
                )?.states?.map((item) => item?.name) || []
              }
              value={formInput?.address?.state || ""}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput.address || {}),
                    state: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select State"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              autoHighlight
              options={
                CountriesWithCities?.find(
                  (item) =>
                    item?.country === (formInput?.address?.country || "India")
                )?.cities || []
              }
              value={formInput?.address?.city || ""}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput.address || {}),
                    city: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select City"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <TextField
              fullWidth
              label="Pincode"
              name="pincode"
              placeholder="Enter Pincode/Zipcode"
              value={formInput?.address?.pincode || ""}
              onChange={(e) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput.address || {}),
                    pincode: e.target.value,
                  },
                });
              }}
            />
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Complete Address"
              value={formInput?.address?.address1 || ""}
              placeholder="eg. 123, Street, City, Country"
              onChange={(e) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...(formInput.address || {}),
                    address1: e.target.value,
                  },
                });
              }}
              multiline
              rows={3}
            />
          </Stack>
        </Stack>
        <Divider textAlign="left" sx={{ my: 4 }}>
          Relation Details
        </Divider>
        <Stack spacing={2} direction={{ xs: "column", md: "row" }} mb={2}>
          <Autocomplete
            fullWidth
            options={customerRelations || []}
            value={formInput?.relation || ""}
            onChange={(e, value) => {
              setSelectedCustomerRelation(value);
              setFormInput({
                ...formInput,
                relation: value,
                meta: {},
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Relation Type"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Stack width={1}></Stack>
        </Stack>
        <Stack spacing={2}>
          {customFields?.length > 0 && (
            <Grid container spacing={2}>
              {customFields?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={item?.type === "file" ? 12 : 6}
                  key={index}
                >
                  <InputFieldsSwitch
                    question={item}
                    formInput={formInput?.meta || {}}
                    handleInputChange={({ key, value }) => {
                      setFormInput({
                        ...formInput,
                        meta: {
                          ...(formInput?.meta || {}),
                          [key]: value,
                        },
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          my={2}
          justifyContent="space-between"
        >
          {customerId ? (
            <LoadingButton
              startIcon={
                formInput?.isActive ? <BlockOutlined /> : <AddTwoTone />
              }
              variant={formInput?.isActive ? "text" : "contained"}
              onClick={() =>
                Notiflix.Confirm.show(
                  "Confirmation",
                  "Are you sure you want to continue?",
                  "Confirm",
                  "Cancel",
                  () => handleActivateDeactivate(),
                  () => {},
                  {
                    width: "500px",
                    messageMaxLength: 1000,
                  }
                )
              }
              loading={isWait}
              color={formInput?.isActive ? "error" : "success"}
            >
              Mark as {formInput?.isActive ? "Inactive" : "Active"}
            </LoadingButton>
          ) : (
            <Stack></Stack>
          )}
          <LoadingButton
            variant="contained"
            startIcon={<SaveTwoTone />}
            onClick={handleAddCustomer}
            loading={isWait}
            disabled={!formInput?.isActive && !!customerId}
          >
            Submit
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
}
