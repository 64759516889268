import { AddTwoTone, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  capitalize,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { PAGE_LIMIT } from "src/utils/constants";
import { isValidEmail, isValidPhoneNumber } from "src/utils/helpers";

export default function InviteViaInput({ users, setUsers }) {
  const theme = useTheme();
  const [formInput, setFormInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    employeeId: "",
    relation: "",
  });
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const [customerRelations, setCustomerRelations] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!currentUser?.orgId) return;
    (async () => {
      const crs = await getTagsByType("customerRelations");
      setCustomerRelations(crs);
    })();
  }, []);

  const handleAddUser = () => {
    if (
      users?.find(
        (user) =>
          user.email === formInput.email ||
          user.phoneNumber === formInput.phoneNumber
      )
    ) {
      Notify.warning("User already added to the list");
      return;
    }

    if (!isValidEmail(formInput.email)) {
      Notify.failure("Email is invalid");
      return;
    }

    if (!isValidPhoneNumber(formInput.phoneNumber)) {
      Notify.failure("Phone number is invalid");
      return;
    }

    setUsers([...users, { ...formInput, relation: formInput.relation || "" }]);
    setFormInput({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      employeeId: "",
      relation: "",
    });
  };

  return (
    <Stack spacing={2}>
      <Card variant="outlined" elevation={0} sx={{ p: 1 }}>
        <Box
          sx={{
            p: 2,
            background: theme.palette.background.default,
            borderRadius: 1,
          }}
        >
          <Stack spacing={2} m={2}>
            <Stack spacing={2} direction={"row"}>
              <TextField
                fullWidth
                label="First Name *"
                variant="outlined"
                value={formInput.firstName}
                onChange={(e) =>
                  setFormInput({ ...formInput, firstName: e.target.value })
                }
                sx={{ background: theme.palette.background.paper }}
              />
              <TextField
                fullWidth
                label="Last Name *"
                variant="outlined"
                value={formInput.lastName}
                onChange={(e) =>
                  setFormInput({ ...formInput, lastName: e.target.value })
                }
                sx={{ background: theme.palette.background.paper }}
              />
              <TextField
                fullWidth
                label="Email *"
                variant="outlined"
                value={formInput.email}
                type="email"
                onChange={(e) =>
                  setFormInput({ ...formInput, email: e.target.value })
                }
                sx={{ background: theme.palette.background.paper }}
              />
            </Stack>
            <Stack spacing={2} direction={"row"}>
              <TextField
                fullWidth
                label="Phone Number *"
                variant="outlined"
                value={formInput.phoneNumber}
                onChange={(e) =>
                  setFormInput({ ...formInput, phoneNumber: e.target.value })
                }
                sx={{ background: theme.palette.background.paper }}
              />
              <TextField
                fullWidth
                label="Employee ID"
                variant="outlined"
                value={formInput.employeeId}
                onChange={(e) =>
                  setFormInput({ ...formInput, employeeId: e.target.value })
                }
                sx={{ background: theme.palette.background.paper }}
              />
              <FormControl
                fullWidth
                sx={{ background: theme.palette.background.paper }}
              >
                <InputLabel>Relation</InputLabel>
                <Select
                  label={"Relation"}
                  value={formInput?.relation || ""}
                  onChange={(e) => {
                    setFormInput({
                      ...formInput,
                      relation: e.target.value,
                    });
                  }}
                >
                  {["employee", ...customerRelations]?.map((column, index) => (
                    <MenuItem key={index} value={column}>
                      {capitalize(column || "")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddTwoTone />}
              disabled={
                !["firstName", "lastName", "email", "phoneNumber"].every(
                  (key) => formInput[key]
                )
              }
              onClick={handleAddUser}
            >
              Add {formInput.firstName || ""} to list
            </Button>
          </Box>
        </Box>
      </Card>

      <TableContainer elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              {currentUser?.orgId && (
                <>
                  <TableCell>Employee ID</TableCell>
                  <TableCell>Relation</TableCell>
                </>
              )}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          {users?.length > 0 ? (
            <TableBody>
              {users
                ?.slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT)
                ?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item?.firstName || "-"}</TableCell>
                    <TableCell>{item?.lastName || ""} </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "600",
                        color: isValidEmail(item?.email)
                          ? ""
                          : theme.palette.error.main,
                      }}
                    >
                      {item?.email || "-"}
                      {item?.email && !isValidEmail(item?.email) && (
                        <Typography>
                          <small> (Invalid email)</small>
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isValidPhoneNumber(item?.phoneNumber)
                          ? ""
                          : theme.palette.error.main,
                      }}
                    >
                      {item?.phoneNumber || "-"}
                      {item?.phoneNumber &&
                        !isValidPhoneNumber(item?.phoneNumber) && (
                          <Typography>
                            <small> (Invalid phone number)</small>
                          </Typography>
                        )}
                    </TableCell>
                    {currentUser?.orgId && (
                      <>
                        <TableCell>{item?.employeeId || "-"}</TableCell>
                        <TableCell>{item?.relation || "-"}</TableCell>
                      </>
                    )}
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setUsers(users.filter((_, i) => i !== index));
                        }}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <Typography variant="h6" color={"textSecondary"} p={2}>
              No users added yet
            </Typography>
          )}
        </Table>
      </TableContainer>
      {users?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[PAGE_LIMIT]}
          component="div"
          count={users?.length}
          rowsPerPage={PAGE_LIMIT}
          page={page - 1}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={() => {}}
        />
      )}
    </Stack>
  );
}
