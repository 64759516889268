import {
  Box,
  Grid,
  Button,
  Stack,
  Paper,
  InputBase,
  IconButton,
  Pagination,
  Typography,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";

import { AddTwoTone, ArrowForwardIos, Search } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { EVENT_STATUS, PAGE_LIMIT } from "src/utils/constants";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import EmptyHere from "src/components/EmptyHere";
import EventCard from "./components/EventCard";
import EventTableCard from "./components/EventTableCard";

export default function Events({ organisationId }) {
  const { currentUser } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [loading, setLoading] = useState(true);
  const [filterObj, setFilterObj] = useState({});
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [events, setEvents] = useState({});
  const [organisation, setOrganisation] = useState(null);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    setOrganisation(organisationId || currentUser?.orgId);
  }, [currentUser, organisationId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
          type: "event",
        };
      }

      if (organisation || organisationId) {
        reqParams.params.organisation = organisation || organisationId;
      }

      const resp = await axiosInstance.get("/events", reqParams);
      if (resp?.status === 200) {
        setEvents(resp?.data || {});
      }
      setLoading(false);
    })();
  }, [page, filterObj]);

  return (
    <Box>
      {loading ? (
        <SuspenseLoader />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: 3, pb: 2 }}
          >
            <Stack direction="row" spacing={2}>
              <Box fullWidth>
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 500,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search here..."
                    inputProps={{ "aria-label": "search here" }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        if (e.target.value.length) {
                          setFilterObj({ ...filterObj, search: searchString });
                        } else {
                          setFilterObj({ ...filterObj, search: null });
                        }
                        setPage(1);
                        setSearchParams({
                          ...Object.fromEntries(searchParams.entries()),
                          page: 1,
                        });
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      if (e.target.value?.length > 0) {
                        setSearchString(e.target.value);
                      } else {
                        setFilterObj({
                          ...filterObj,
                          search: null,
                        });
                      }
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{
                      p: "10px",
                      m: 0.2,
                      bgcolor: "primary.main",
                      color: "white",
                    }}
                    onClick={() => {
                      setFilterObj({
                        ...filterObj,
                        search: searchString,
                      });
                      setPage(1);
                      setSearchParams({
                        ...Object.fromEntries(searchParams.entries()),
                        page: 1,
                      });
                    }}
                  >
                    <Search />
                  </IconButton>
                </Paper>

                {currentUser?.accountType === "admin" &&
                  events?.reviewEventsCount > 0 && (
                    <FormControlLabel
                      sx={{ mx: 0.1 }}
                      control={
                        <Checkbox
                          size="small"
                          checked={filterObj?.status === "review"}
                          onChange={(e) => {
                            setFilterObj({
                              ...filterObj,
                              status: e.target.checked ? "review" : null,
                            });
                          }}
                        />
                      }
                      label={
                        <Typography>
                          Show under review events only. Currently there are{" "}
                          {events?.reviewEventsCount} event(s) waiting for
                          response.
                        </Typography>
                      }
                    />
                  )}
              </Box>
              {currentUser?.role === "user" && (
                <Button
                  size="sm"
                  component={Link}
                  to={`/dashboard/events/history`}
                  endIcon={<ArrowForwardIos fontSize="small" />}
                >
                  Event history
                </Button>
              )}
            </Stack>
            <Stack direction="row" spacing={2}>
              {(organisation || currentUser?.orgId) && (
                <Button
                  startIcon={<AddTwoTone />}
                  component={Link}
                  to={`/dashboard/events/create${
                    currentUser.accountType === "admin"
                      ? `?organisation=${organisation}`
                      : ""
                  }`}
                  variant="outlined"
                  color="primary"
                >
                  Create new event
                </Button>
              )}
              {(currentUser?.accountType === "admin" || currentUser?.orgId) && (
                <Autocomplete
                  disablePortal
                  size="small"
                  id="org-box"
                  options={Object.values(EVENT_STATUS || [])}
                  getOptionLabel={(option) => option?.description}
                  sx={{ width: 200, ml: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Status" />
                  )}
                  onChange={(e, v) => {
                    setFilterObj({
                      ...filterObj,
                      status: v?.value,
                    });
                  }}
                />
              )}
            </Stack>
          </Stack>
          {currentUser?.accountType === "admin" ? (
            <Stack spacing={2}>
              {events?.data?.map((item, index) => (
                <EventTableCard event={item} key={index} />
              ))}
            </Stack>
          ) : (
            <Grid container spacing={4}>
              {events?.data?.map((item, index) => (
                <Grid item xs={12} md={4} sm={6} key={index}>
                  <EventCard event={item} />
                </Grid>
              ))}
            </Grid>
          )}
          {events?.data?.length === 0 && <EmptyHere title={"No Event found"} />}

          {!loading && events?.data?.length > 0 && (
            <Box sx={{ m: 3, display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={2}>
                <Pagination
                  count={events?.totalPages || 0}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
