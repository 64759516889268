import { Autocomplete, Stack, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function EditPortfolio({
  profileId,
  portfolioFormInputs,
  setPortfolioFormInputs,
  editedPortfolioFormInputs,
  setEditedPortfolioFormInputs,
}) {
  const { currentUser, getTagsByType } = useContext(GlobalContext);

  const [skills, setSkills] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [nsdEmpanelments, setNsdEmpanelments] = useState([]);

  useEffect(() => {
    (async () => {
      const _skills = await getTagsByType("skills");
      setSkills(_skills);
      const _certifications = await getTagsByType("certifications");
      setCertifications(_certifications);
      const _nsdEmpanelments = await getTagsByType("nsdEmpanelments");
      setNsdEmpanelments(_nsdEmpanelments);
    })();
  }, []);

  return currentUser?.accountType !== "admin" ? null : (
    <>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Autocomplete
          fullWidth
          multiple
          options={certifications || []}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Certifications"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
          onChange={(e, value) => {
            if (profileId) {
              setEditedPortfolioFormInputs({
                ...editedPortfolioFormInputs,
                certifications: value,
              });
            }

            setPortfolioFormInputs({
              ...portfolioFormInputs,
              certifications: value,
            });
          }}
          value={portfolioFormInputs?.certifications || []}
        />
        <Autocomplete
          fullWidth
          multiple
          options={nsdEmpanelments || []}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select NSD Empanelments"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
          onChange={(e, value) => {
            if (profileId) {
              setEditedPortfolioFormInputs({
                ...editedPortfolioFormInputs,
                nsdEmpanelments: value,
              });
            }

            setPortfolioFormInputs({
              ...portfolioFormInputs,
              nsdEmpanelments: value,
            });
          }}
          value={portfolioFormInputs?.nsdEmpanelments || []}
        />
      </Stack>
      <Autocomplete
        fullWidth
        multiple
        options={skills || []}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Skills"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(e, value) => {
          if (profileId) {
            setEditedPortfolioFormInputs({
              ...editedPortfolioFormInputs,
              skills: value,
            });
          }

          setPortfolioFormInputs({
            ...portfolioFormInputs,
            skills: value,
          });
        }}
        value={portfolioFormInputs?.skills || []}
      />
    </>
  );
}
