import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ProfileContext } from "src/contexts/ProfileContext";
import axiosInstance from "src/utils/axiosInstance";

export default function AadhaarVerification({ handleClose }) {
  const { currentUser } = useContext(GlobalContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const [formInputs, setFormInputs] = useState({});
  const [captcha, setCaptcha] = useState({});
  const [optData, setOtpData] = useState({});
  const [isWait, setIsWait] = useState("");
  const [consent, setConsent] = useState(false);
  const [startOtpTimer, setStartOtpTimer] = useState(0);
  const [nameDialog, setNameDialog] = useState(false);

  const getCaptcha = async () => {
    try {
      setIsWait("captcha");
      const resp = await axiosInstance.get("/gridlines/captcha");
      if (resp.status === 200) {
        setCaptcha(resp.data?.data || {});
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    } finally {
      setIsWait("");
    }
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  useEffect(() => {
    if (startOtpTimer > 0) {
      setTimeout(() => {
        setStartOtpTimer(startOtpTimer - 1);
      }, 1000);
    }
  }, [startOtpTimer]);

  const handleClickSendOtp = async () => {
    try {
      if (
        !formInputs?.aadhaar_number ||
        formInputs?.aadhaar_number?.length !== 12
      )
        throw new Error("Please enter valid Aadhaar Number");
      if (!formInputs?.captcha) throw new Error("Please enter captcha");
      setIsWait("otp");
      setStartOtpTimer(60);
      const resp = await axiosInstance.post("/gridlines/otp", {
        aadhaar_number: formInputs?.aadhaar_number,
        captcha: formInputs?.captcha,
        transactionId: captcha?.data?.transaction_id,
      });
      if (resp.status === 200) {
        setOtpData(resp.data?.data || {});

        setTimeout(() => {
          setStartOtpTimer(0);
        }, 1000 * 60); // 60 seconds
      }
    } catch (error) {
      console.log(error.response);
      Notify.failure(
        (error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured") + "Invalid Captcha"
      );
    } finally {
      setIsWait("");
    }
  };

  const handleVerify = async () => {
    try {
      if (!consent) throw new Error("Please check the consent box to proceed");
      if (formInputs?.otp?.length !== 6)
        throw new Error("Please enter valid OTP");

      setIsWait("aadhaar");
      const response = await axiosInstance.post(`/gridlines/aadhaar`, {
        otp: formInputs?.otp,
        transactionId: optData?.data?.transaction_id,
        userId: ["admin", "triager"].includes(currentUser?.accountType)
          ? profile?._id
          : null,
      });
      if (
        response.status === 200 &&
        response.data?.data?.data?.code === "1002"
      ) {
        Notify.success("Aadhaar Verified Successfully");
        setProfile({
          ...profile,
          cleanExitVerifications: {
            ...profile.cleanExitVerifications,
            aadhaar: true,
          },
        });
        setFormInputs({});
        setOtpData({});
        setConsent(false);
        setStartOtpTimer(0);
        handleClose(null);
        if (response?.data?.nameDialog) {
          setNameDialog(true);
        }
      }
      setIsWait("");
    } catch (error) {
      console.log(error);
      setIsWait("");
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <>
      <Stack spacing={4} my={2}>
        <TextField
          autoFocus
          label={"Aadhaar Card Number"}
          fullWidth
          placeholder={"eg. 123456789012"}
          value={formInputs?.aadhaar_number || ""}
          onChange={(e) => {
            let _value = e.target.value;
            _value = _value.replace(/[^a-zA-Z0-9]/g, "");
            if (_value.length > 12) {
              Notify.warning("Aadhaar Number should be 12 digits");
              return;
            }
            setFormInputs({ ...formInputs, aadhaar_number: _value });
          }}
        />
        <Stack direction="row" alignItems="center">
          {isWait === "captcha" ? (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              loading captcha...
            </Typography>
          ) : (
            <img
              src={"data:image/jpeg;base64," + captcha?.data?.captcha_base64}
              alt=""
              width={200}
              height={40}
              style={{
                border: "1px solid #ccc",
              }}
            />
          )}
          <Button onClick={getCaptcha}>
            <Refresh />
          </Button>
        </Stack>
        <TextField
          variant="standard"
          label={"Enter Captcha"}
          value={formInputs?.captcha || ""}
          sx={{
            width: "50%",
          }}
          onChange={(e) => {
            setFormInputs({ ...formInputs, captcha: e.target.value });
          }}
        />
        <LoadingButton
          variant="contained"
          onClick={handleClickSendOtp}
          disabled={startOtpTimer !== 0}
          loading={isWait === "otp"}
        >
          {startOtpTimer > 0
            ? `Resend OTP, after  ${startOtpTimer}
            seconds`
            : "Send OTP"}
        </LoadingButton>
        {optData?.data?.code === "1001" && (
          <>
            <TextField
              variant="filled"
              label={"Enter OTP"}
              value={formInputs?.otp || ""}
              onChange={(e) => {
                setFormInputs({ ...formInputs, otp: e.target.value });
              }}
            />

            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Checkbox
                  checked={consent}
                  onChange={(e) => setConsent(e.target.checked)}
                  size="small"
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", ml: 0.5 }}
                >
                  I hereby confirm that the above detail(s) is/are correct
                </Typography>
              }
            />
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose(null);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="success"
                onClick={handleVerify}
                loading={isWait === "aadhaar"}
              >
                Verify Now
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Stack>
      <Dialog open={nameDialog} onClose={() => setNameDialog(false)}>
        <DialogTitle>
          <Typography variant="h2">Name Updated</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" fontSize={"1.1rem"}>
            It seems, your Name in your Aadhaar Card is different from the Name
            in your Profile. We have updated your Name in your Profile to the
            Name in your Aadhaar Card.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNameDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
