import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SuspenseLoader from "src/components/SuspenseLoader";
import AcceptInvitation from "src/content/dashboards/Invitations/AcceptInvitation";
import PricingPlans from "src/content/dashboards/Pricing/PricingPlans";
import ProfileReport from "src/content/dashboards/Reports/ProfileReport";
import OrgPublicProfile from "src/content/dashboards/organisations/OrgPublicProfile";
import InvoiceDetail from "src/content/pages/Invoice/InvoiceDetail";
import LinkReferences from "src/content/pages/LinkRef";
import UserPublicProfile from "src/content/pages/Profile/User";
import { ProfileProvider } from "src/contexts/ProfileContext";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import("src/content/pages/Status/Status404"))
);

const baseRoutes = [
  {
    path: "",
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: "invitation/:invitationId",
    element: <AcceptInvitation />,
  },
  {
    path: "links/:accountId/:accountType",
    element: <LinkReferences />,
  },
  {
    path: "org/:organisationId",
    element: <OrgPublicProfile />,
  },
  {
    path: "invoices/:invoiceId",
    element: <InvoiceDetail />,
  },
  {
    path: "invoices/:invoiceId",
    element: <InvoiceDetail />,
  },
  {
    path: "reports/user/:userId",
    element: (
      <ProfileProvider>
        <ProfileReport />
      </ProfileProvider>
    ),
  },
  {
    path: "status",
    children: [
      { path: "", element: <Navigate to="404" replace /> },
      { path: "", element: <Status404 /> },
    ],
  },
  {
    path: "pricing",
    children: [{ path: "", element: <PricingPlans /> }],
  },
  {
    path: ":cleanExitUserId",
    element: (
      <ProfileProvider viewAsPublic={true}>
        <UserPublicProfile />
      </ProfileProvider>
    ),
  },
  { path: "*", element: <Status404 /> },
];

export default baseRoutes;
