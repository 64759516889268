import {
  Box,
  Card,
  Typography,
  Button,
  Divider,
  styled,
  Grid,
  Stack,
  Avatar,
  alpha,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import Label from "src/components/Label";
import { MAX_SCORE } from "src/utils/constants";
import { Link } from "react-router-dom";
import ScoreCircularBar from "../../common/ScoreCircularBar";
import { Security } from "@mui/icons-material";
import { useContext } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";

const BoxComposed = styled(Box)(
  () => `
    position: relative;
  `
);

const BoxComposedContent = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 7;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
    
  `
);

const BoxComposedImage = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    filter: grayscale(80%);
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const BoxComposedBg = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

export default function UserProfilePreview({ profile }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);

  return (
    <Card sx={{ my: 2, width: "100%" }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={5}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            pt={6}
            pb={5}
            px={5}
            sx={{
              textAlign: "center",
            }}
          >
            <Box>
              <Avatar
                sx={{
                  mx: "auto",
                  mb: 1.5,
                  width: 120,
                  height: 120,
                  border: `${theme.colors.alpha.white[100]} solid 3px`,
                  boxShadow: `0 0 0 3px ${theme.colors.primary.main}`,
                }}
                src={
                  process.env.REACT_APP_DO_SPACE_URL +
                  profile?.profileImage +
                  `?${Math.random()}`
                }
              />
              <Typography gutterBottom variant="h3">
                {profile?.firstName + " " + (profile?.lastName || "")}
              </Typography>

              <Box py={1}>
                {profile?.meta?.headline && (
                  <Label color="info">{profile?.meta?.headline || ""}</Label>
                )}
              </Box>

              {profile?.meta?.about && (
                <Typography variant="subtitle2">
                  {profile?.meta?.about}
                </Typography>
              )}
              <Divider
                sx={{
                  mt: 3,
                }}
              />
              <Stack
                sx={{
                  my: 3,
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
              >
                <Box>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    textAlign="center"
                    gutterBottom
                  >
                    {t("Aadhaar Card")}
                  </Typography>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {profile?.cleanExitVerifications?.aadhaar ? (
                      <Label color="success">Verified</Label>
                    ) : (
                      <Label color="error">Not Verified</Label>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    textAlign="center"
                    gutterBottom
                  >
                    {t("PAN Card")}
                  </Typography>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {profile?.cleanExitVerifications?.pan ? (
                      <Label color="success">Verified</Label>
                    ) : (
                      <Label color="error">Not Verified</Label>
                    )}
                  </Typography>
                </Box>{" "}
              </Stack>
              <Divider />
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                {/* {profile?.viewAccount && ( */}
                <Button
                  disableRipple={false}
                  variant="outlined"
                  sx={{
                    mt: 5,
                  }}
                  startIcon={<AccountBoxTwoToneIcon />}
                  size="large"
                  color="primary"
                  component={Link}
                  to={`/dashboard/users/${profile?._id}`}
                  target="_blank"
                  // disabled={!profile?.viewAccount}
                >
                  View complete profile
                </Button>
                {/* )} */}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={7} p={1}>
          <BoxComposed
            display="flex"
            alignItems="center"
            sx={{
              position: "relative",
              minHeight: "100%",
              background: `${theme.colors.gradients.black1}`,
            }}
          >
            <BoxComposedBg
              sx={{
                opacity: 0.5,
                background: `${theme.colors.gradients.blue2}`,
              }}
            />
            <BoxComposedImage
              sx={{
                opacity: 0.5,
              }}
            />
            <BoxComposedContent
              display="flex"
              flexGrow={1}
              alignItems="center"
              flexDirection="column"
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "60%",
                }}
              >
                {profile?.viewAccount ? (
                  <ScoreCircularBar userProfile={profile} />
                ) : (
                  <Button
                    variant="text"
                    sx={{
                      my: 5,
                    }}
                    startIcon={<Security />}
                    size="large"
                    color="warning"
                  >
                    {"It is a Private account"}
                  </Button>
                )}
              </Box>
              <Box>
                <Typography
                  lineHeight="1.4"
                  sx={{
                    pt: 3,
                    pb: 2,
                    fontSize: `${theme.typography.pxToRem(62)}`,
                  }}
                  variant="h1"
                >
                  <b>{profile?.employeeScore || MAX_SCORE}</b>
                </Typography>
                <Typography variant="h4">Score (~1000)</Typography>
              </Box>
              <Button
                variant="contained"
                color="success"
                component={Link}
                size="large"
                sx={{
                  minWidth: 200,
                  position: "relative",
                  mt: 10,
                }}
                to={`/dashboard/forms?receiverType=user&receiverId=${
                  profile?._id
                }${currentUser?.orgId ? `&orgId=${currentUser?.orgId}` : ""}`}
              >
                Add Rating
              </Button>
            </BoxComposedContent>
          </BoxComposed>
        </Grid>
      </Grid>
    </Card>
  );
}
