import {
  CloudDownload,
  KeyboardArrowDown,
  Refresh,
  VisibilityOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useState } from "react";
import { useParams } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";

export default function CleanExitIdCard({ idCard }) {
  const { userId } = useParams();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [isWait, setIsWait] = useState("");
  const [certificate, setCertificate] = useState(null);

  const handleDownloadPdf = async () => {
    try {
      setIsWait("pdf");

      const reqObj = { userId: userId || null };
      if (idCard?.url) {
        reqObj.action = "download";
      }

      const resp = await axiosInstance.post(
        `/users/generate-certificates?format=pdf`,
        reqObj,
        {
          responseType: "blob",
        }
      );
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `certificate.pdf`;
        link.dispatchEvent(new MouseEvent("click"));
        setTimeout(() => {
          // for Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
        }, 60);
      }
    } catch (error) {
      setIsWait("");
      console.log(error?.response);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "Failed to generate certificate"
      );
    } finally {
      setIsWait("");
    }
  };

  const handleDownloadSvg = async () => {
    try {
      setIsWait("view");
      const reqObj = { userId: userId || null };
      const resp = await axiosInstance.post(
        `/users/generate-certificates?format=svg`,
        reqObj
      );
      if (resp.status === 200) {
        setShow(true);
        const _svg = resp.data?.data;
        setCertificate(
          `data:image/svg+xml;base64,${btoa(
            unescape(encodeURIComponent(_svg))
          )}`
        );
      }
    } catch (error) {
      setIsWait("");
      console.log(error?.response);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "Failed to generate certificate"
      );
    } finally {
      setIsWait("");
    }
  };

  return (
    <Box>
      <Card sx={{ p: 0.3, zIndex: 900 }} elevation={0}>
        <CardMedia
          sx={{
            borderRadius: 1,
            objectFit: "fill",
            position: "relative",
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
          component="img"
          image={
            idCard?.url
              ? process.env.REACT_APP_DO_SPACE_URL +
                idCard?.url +
                `?${Math.random()}`
              : "/static/images/logo/id-preview.png"
          }
          alt=""
        />
      </Card>
      <Card
        sx={{
          top: open ? -10 : -55,
          transition: "top 0.5s ease-in-out",
          position: "relative",
          borderRadius: "0px 0px 10px 10px",
          height: 70,
          pt: 2,
          zIndex: 2,
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "center",
            px: 1,
          }}
          mt={open ? 0.3 : 0}
          spacing={1}
        >
          <LoadingButton
            variant="contained"
            color="warning"
            size="small"
            fullWidth
            startIcon={<VisibilityOutlined />}
            onClick={() => {
              if (idCard?.url) {
                setShow(true);
              } else {
                handleDownloadSvg();
              }
            }}
            loading={isWait === "view"}
          >
            {idCard?.url ? "View" : "Generate"}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="success"
            size="small"
            fullWidth
            startIcon={<CloudDownload />}
            onClick={handleDownloadPdf}
            loading={isWait === "pdf"}
          >
            Download
          </LoadingButton>
          {idCard?.url && (
            <Tooltip title="Regenerate Certificate">
              <IconButton color="info" size="small" onClick={handleDownloadSvg}>
                <Refresh />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <IconButton
          sx={{
            width: "100%",
            height: 20,
          }}
          onClick={() => setOpen(!open)}
        >
          {
            <KeyboardArrowDown
              sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            />
          }
        </IconButton>
      </Card>
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        fullWidth
        maxWidth="md"
      >
        {isWait === "view" ? (
          <SuspenseLoader text={"Generating Certificate. Please wait..."} />
        ) : (
          <Card
            sx={{
              p: 0.4,
            }}
          >
            <img
              src={
                idCard?.url
                  ? process.env.REACT_APP_DO_SPACE_URL +
                    idCard?.url +
                    `?${Math.random()}`
                  : certificate || "/static/images/logo/id-preview.png"
              }
              alt="idcard"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Card>
        )}
      </Dialog>
    </Box>
  );
}
