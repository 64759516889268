import {
  Box,
  Card,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  styled,
  Stack,
  IconButton,
  LinearProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TextField,
  Autocomplete,
  capitalize,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";

import { useTranslation } from "react-i18next";
import { Notify } from "notiflix";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "src/utils/axiosInstance";

import { PAGE_LIMIT } from "src/utils/constants";
import { Link, useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";
import { ArrowForwardIos } from "@mui/icons-material";
import UserProfilePreview from "./components/UserProfilePreview";
import { isValidCleanExitId, isValidEmail } from "src/utils/helpers";

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`
);

const ButtonSearch = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`
);

function SearchPeople() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("search");
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [searchString, setSearchString] = useState(searchParam || "");
  const [msg, setMsg] = useState("");
  const [users, setUsers] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [portfolioFilterObj, setPortfolioFilterObj] = useState({});

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value,
    });
  };

  useEffect(() => {
    if (searchParam?.length > 0) {
      setSearchString(searchParam);
      setFilterObj({
        ...filterObj,
        search: searchParam,
      });
      // handleGetUserById({
      //   params: { search: searchParam },
      // });
    } else {
      setSearchString("");
      const _filterObj = { ...filterObj };
      delete _filterObj.search;
      setFilterObj(_filterObj);
      setProfile(null);
      setUsers(null);
      setMsg("");
    }
  }, [searchParam, pageParam]);

  const [skills, setSkills] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [nsdEmpanelments, setNsdEmpanelments] = useState([]);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    (async () => {
      const _industries = await getTagsByType("industries");
      setIndustries(_industries);
      const _skills = await getTagsByType("skills");
      setSkills(_skills);
      const _certifications = await getTagsByType("certifications");
      setCertifications(_certifications);
      const _nsdEmpanelments = await getTagsByType("nsdEmpanelments");
      setNsdEmpanelments(_nsdEmpanelments);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const reqParams = {
          params: {
            whichPage: "search-people",
            limit: PAGE_LIMIT,
            skip: (page - 1) * PAGE_LIMIT,
          },
        };

        if (Object.keys(filterObj).length > 0) {
          // remove empty array
          Object.keys(filterObj).forEach((key) => {
            if (Array.isArray(filterObj[key]) && filterObj[key].length === 0) {
              delete filterObj[key];
            }
          });

          reqParams.params = { ...reqParams.params, ...filterObj };
        }

        if (Object.keys(portfolioFilterObj).length > 0) {
          // remove empty array
          Object.keys(portfolioFilterObj).forEach((key) => {
            if (
              Array.isArray(portfolioFilterObj[key]) &&
              portfolioFilterObj[key].length === 0
            ) {
              delete portfolioFilterObj[key];
            }
          });
          if (Object.keys(portfolioFilterObj).length > 0) {
            reqParams.params = {
              ...reqParams.params,
              portfolio: JSON.stringify(portfolioFilterObj),
            };
          }
        }

        handleGetUserById(reqParams);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, [page, filterObj, portfolioFilterObj]);

  const handleGetUserById = async (reqParams) => {
    try {
      // don't sent request if only limit and skip is there
      const { limit, skip, whichPage, ...rest } = reqParams.params || {};

      if (Object.keys(rest)?.length === 0) {
        return;
      }

      if (
        !["admin", "misc", "triager"]?.includes(currentUser?.accountType) &&
        !(isValidEmail(rest?.search) || isValidCleanExitId(rest?.search))
      ) {
        setMsg("Please enter a valid EthixFirst ID or Email");
        setProfile(null);
        return;
      }

      setUsers(null);
      setProfile(null);
      setMsg("");
      setLoading(true);
      const resp = await axiosInstance.get(`/users/profile`, reqParams);
      if (resp?.status === 200 && resp?.data) {
        if (Array.isArray(resp.data?.data)) {
          setUsers(resp?.data);
          setPage(page || 1);
          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: page || 1,
          });
          setProfile(null);
        } else {
          setProfile(resp?.data);
          setUsers(null);
        }
      } else {
        setMsg("No Profile Found");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setMsg("No Profile Found");
    }
  };

  return (
    <Box my={2}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={2}>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInputWrapper
              type="text"
              placeholder={t(
                `Search ${
                  currentUser?.orgId ? "by EthixFirst ID or Email" : "here"
                }`
              )}
              value={searchString || ""}
              onChange={(e) => {
                setSearchString(e.target.value);

                if (e.target.value?.length === 0) {
                  delete filterObj.search;
                  setFilterObj(filterObj);
                  setProfile(null);
                  setUsers(null);
                  setMsg("");
                  searchParams.delete("search");
                  setSearchParams(searchParams);
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  setSearchParams({
                    search: searchString,
                  });
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <ButtonSearch
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSearchParams({
                        search: searchString,
                      });
                    }}
                  >
                    {t("Search")}
                  </ButtonSearch>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              }
            />
          </FormControl>

          {["admin", "misc"]?.includes(currentUser?.accountType) && (
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                multiple
                options={industries || []}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Industry *"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(e, value) => {
                  setFilterObj({
                    ...filterObj,
                    industry: value,
                  });
                }}
                value={filterObj?.industry || []}
              />
              <Autocomplete
                fullWidth
                multiple
                options={skills || []}
                getOptionLabel={(option) => capitalize(option || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Skills"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(e, value) => {
                  setPortfolioFilterObj({
                    ...portfolioFilterObj,
                    skills: value,
                  });
                }}
                value={portfolioFilterObj?.skills || []}
              />
              <Autocomplete
                fullWidth
                multiple
                options={certifications || []}
                getOptionLabel={(option) => capitalize(option || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Certifications"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(e, value) => {
                  setPortfolioFilterObj({
                    ...portfolioFilterObj,
                    certifications: value,
                  });
                }}
                value={portfolioFilterObj?.certifications || []}
              />
              <Autocomplete
                fullWidth
                multiple
                options={nsdEmpanelments || []}
                getOptionLabel={(option) => capitalize(option || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by NSD Empanelments"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(e, value) => {
                  setPortfolioFilterObj({
                    ...portfolioFilterObj,
                    nsdEmpanelments: value,
                  });
                }}
                value={portfolioFilterObj?.nsdEmpanelments || []}
              />
            </Stack>
          )}
        </Stack>
      </Card>
      {loading ? (
        <LinearProgress sx={{ my: 2 }} />
      ) : (
        <>
          {(profile || users) && (
            <>
              {!users && profile && <UserProfilePreview profile={profile} />}
              {!profile && users?.data?.length > 0 && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>EthixFirst ID</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.data?.map((row) => (
                        <TableRow
                          sx={{
                            color: row?.isLocked && "red",
                            textDecoration: row?.isLocked && "line-through",
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={row?._id}
                        >
                          <TableCell>{row?.cleanExitUserId} </TableCell>
                          <TableCell>
                            <Typography variant="h6">
                              {row?.firstName + " " + row?.lastName || ""}
                            </Typography>
                          </TableCell>
                          <TableCell>{row?.email}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              variant="outlined"
                              color="primary"
                              size="small"
                              sx={{ mr: 1 }}
                              onClick={() => {
                                setSearchParams({
                                  search: row?.cleanExitUserId,
                                });
                              }}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  {!loading && users?.data?.length > 0 && (
                    <Box
                      sx={{
                        m: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Stack spacing={2}>
                        <Pagination
                          count={users?.totalPages}
                          page={page}
                          onChange={handlePageChange}
                          color="primary"
                        />
                      </Stack>
                    </Box>
                  )}
                </TableContainer>
              )}
            </>
          )}
          {/* {msg && !profile && !users && ( */}
          <Card sx={{ p: 3, my: 2, textAlign: "center" }}>
            <Stack spacing={2} my={2}>
              <Typography
                variant="h4"
                color="error"
                m={2}
                sx={{ textAlign: "center" }}
              >
                {msg}
              </Typography>
              <Typography variant="body2">
                Didn't find whom you are looking for?
              </Typography>
              <Typography variant="h4" fontSize={"1.1rem"} color="textPrimary">
                You can still rate a person by entering their details.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={"/dashboard/forms?showRatingButton=true"}
              >
                Rate a Person
              </Button>

              <Typography variant="body2" color="textSecondary">
                Click on the button above and then select a form to give Rating.
              </Typography>
            </Stack>
          </Card>
          {/* )} */}
        </>
      )}
    </Box>
  );
}

export default SearchPeople;
