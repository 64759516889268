import {
  BookOutlined,
  CachedOutlined,
  ReportProblemOutlined,
  ThumbUpAltOutlined,
  TrackChangesOutlined,
  TrendingDownRounded,
  WarningAmberRounded,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Card, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import ChartCard from "src/components/ChartCard";
import ProgressCard from "src/components/ProgressCard";

const data = [
  {
    title: "Total Incidents",
    content: "5",
    caption: "Last 12 months",
    icon: <WarningAmberRounded color="warning" sx={{ fontSize: 28 }} />,
  },
  {
    title: "Cumulative Impact",
    content: "-55 points",
    caption: "On ethical score",
    icon: <TrendingDownRounded color="error" sx={{ fontSize: 28 }} />,
  },
  {
    title: "Learning Modules",
    content: "4/5",
    caption: "Completed",
    icon: <BookOutlined color="success" sx={{ fontSize: 28 }} />,
  },
];

const footerData = [
  {
    title: "Next Review",
    content: "30 days",
    caption: "Until next performance review",
    icon: <TrackChangesOutlined color="primary" sx={{ fontSize: 28 }} />,
  },
  {
    title: "Improvement Trend",
    content: "+15 points",
    caption: "Last 3 months",
    icon: <CachedOutlined color="success" sx={{ fontSize: 28 }} />,
  },
  {
    title: "Positive Actions",
    content: "12",
    caption: "Recognized this quarter",
    icon: <ThumbUpAltOutlined color="warning" sx={{ fontSize: 28 }} />,
  },
];

const getChartData = (theme) => [
  {
    type: "donut",
    title: "Incident Types",
    data: {
      chartOptions: {
        chart: {
          background: "transparent",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "55%",
            },
          },
        },
        colors: [
          theme.palette.primary.main,
          theme.palette.warning.main,
          theme.palette.success.main,
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return "";
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: theme.colors.alpha.black[50],
            opacity: 0.5,
          },
        },
        fill: {
          opacity: 1,
        },
        labels: ["Policy Violation", "Behavioral", "Compliance Issue"],
        legend: {
          labels: {
            colors: theme.colors.alpha.trueWhite[100],
          },
          show: false,
        },
        stroke: {
          width: 0,
        },
        theme: {
          mode: theme.palette.mode,
        },
      },
      chartSeries: [2, 1, 2],
    },
  },
  {
    type: "donut",
    title: "Incident Severity",
    data: {
      chartOptions: {
        chart: {
          background: "transparent",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "55%",
            },
          },
        },
        colors: [
          theme.palette.primary.main,
          theme.palette.warning.main,
          theme.palette.success.main,
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return "";
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: theme.colors.alpha.black[50],
            opacity: 0.5,
          },
        },
        fill: {
          opacity: 1,
        },
        labels: ["Minor", "Major", "Moderate"],
        legend: {
          labels: {
            colors: theme.colors.alpha.trueWhite[100],
          },
          show: false,
        },
        stroke: {
          width: 0,
        },
        theme: {
          mode: theme.palette.mode,
        },
      },
      chartSeries: [2, 1, 2],
    },
  },
];

const getImpactData = (theme) => ({
  type: "line",
  title: "Ethical Score Impact Over Time",
  data: {
    chartOptions: {
      chart: {
        background: "transparent",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: [theme.colors.primary.main],
      labels: [
        "Jul 2023",
        "Sep 2023",
        "Nov 2023",
        "Jan 2024",
        "Mar 2024",
        "May 2024",
      ],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        strokeDashArray: 5,
        borderColor: theme.colors.alpha.black[10],
      },
      legend: {
        show: false,
      },
      markers: {
        hover: {
          sizeOffset: 2,
        },
        shape: "circle",
        size: 6,
        strokeWidth: 3,
        strokeOpacity: 1,
        strokeColors: [theme.colors.primary.main],
        colors: [theme.colors.alpha.white[100]],
      },
      stroke: {
        curve: "smooth",
        lineCap: "butt",
        width: 3,
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      yaxis: {
        tickAmount: 3,
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
    },
    chartSeries: [
      {
        name: "Score",
        data: [85, 65, 60, 45, 50, 65],
      },
    ],
  },
});

const getLearningProgressData = (theme) => {
  return {
    type: "bar",
    title: "Learning Progress",
    data: {
      chartSeries: [
        {
          data: [100, 75, 90, 60, 80],
        },
      ],
      chartOptions: {
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "Ethics Refresher",
            "Conflict Resolution",
            "Policy Compliance",
            "Communication Skills",
            "Time Management",
          ],
        },
      },
    },
  };
};

function MisconductInsights() {
  const theme = useTheme();
  const chartData = getChartData(theme);
  const impactData = getImpactData(theme);
  const learningProgressData = getLearningProgressData(theme);
  return (
    <Stack spacing={3} p={3}>
      <Typography variant="h1">Misconduct History Insights</Typography>
      <Box>
        <Grid container spacing={2}>
          {data.map((each) => (
            <Grid item xs={12} lg={4}>
              <ProgressCard
                title={each.title}
                content={each.content}
                caption={each.caption}
                Icon={each.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {chartData.map((each) => (
            <Grid item xs={12} lg={6}>
              <ChartCard {...each} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <ChartCard {...impactData} height={280} />
      <IncidentHistory />
      <ChartCard {...learningProgressData} height={280} />
      <Box>
        <Grid container spacing={2}>
          {footerData.map((each) => (
            <Grid item xs={12} lg={4}>
              <ProgressCard
                title={each.title}
                content={each.content}
                caption={each.caption}
                Icon={each.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}

export default MisconductInsights;

function createData(date, type, severity, description, impact, status) {
  return { date, type, severity, description, impact, status };
}

const rows = [
  createData(
    "2023-03-15",
    "Policy Violation",
    "Moderate",
    "Inappropriate use of company resources",
    "-15 pts",
    "Resolved"
  ),
  createData(
    "2023-01-10",
    "Compliance Issue",
    "Minor",
    "Late submission of ethical compliance report",
    "-5 pts",
    "Resolved"
  ),
  createData(
    "2022-11-05",
    "Behavioral",
    "Major",
    "Disrespectful communication with team member",
    "-20 pts",
    "Resolved"
  ),
  createData(
    "2022-09-20",
    "Policy Violation",
    "Minor",
    "Missed mandatory ethics training session",
    "-5 pts",
    "Resolved"
  ),
  createData(
    "2022-07-03",
    "Compliance Issue",
    "Moderate",
    "Failure to disclose potential conflict of interest",
    "-10 pts",
    "Resolved"
  ),
];

export function IncidentHistory() {
  return (
    <Card sx={{ p: 2.5 }}>
      <Typography variant="h2" mb={2} fontSize={"large"}>
        Incident History
      </Typography>
      <Box>
        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>DATE</TableCell>
                <TableCell>TYPE</TableCell>
                <TableCell>SEVERITY</TableCell>
                <TableCell>DESCRIPTION</TableCell>
                <TableCell align="right">IMPACT</TableCell>
                <TableCell align="right">STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.severity}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell align="right">{row.impact}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
}
