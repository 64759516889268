import { FolderOpen, FolderRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useEffect, useState } from "react";
import IOSSwitch from "src/components/IosSwitch";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";

export default function UserCertificateGroups() {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [slug, setSlug] = useState("");
  const [groups, setGroups] = useState([]);
  const [isWait, setIsWait] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get("/tags/type", {
          params: {
            type: "certificate-groups",
          },
        });
        if (resp?.status === 200) {
          setGroups(resp?.data?.groups || []);
          setSlug(resp?.data?.description || "");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
  }, []);

  const handleSelectGroup = async (groupId) => {
    const _programs = groups?.find((item) => item?._id === groupId) || null;
    setSelectedGroup(_programs);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsWait(true);

      const resp = await axiosInstance.put(`/tags`, {
        description: slug,
        type: "certificate-groups",
      });
      if (resp?.status === 200) {
        Notify.success(resp?.data?.message || "Saved");
      }
      setIsWait(false);
    } catch (error) {
      console.error(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Card sx={{ my: 2 }}>
      <CardHeader
        title={
          <Typography variant="h4" gutterBottom>
            Certificates by Group in User Profile
          </Typography>
        }
        subheader={
          <Typography variant="subtitle1">
            Toggle the switch to show/hide the certificate group in user
            profile.
          </Typography>
        }
      />
      <Divider />

      {loading ? (
        <SuspenseLoader height="50vh" />
      ) : (
        <CardContent>
          {" "}
          <Stack
            spacing={2}
            my={2}
            alignItems="center"
            direction={"row"}
            justifyContent={"center"}
          >
            <TextField
              fullWidth
              variant="filled"
              color="warning"
              placeholder="Toggle the switch to create slug to show/hide the certificate group in user profile."
              value={slug?.slice(0, -1)}
              focused
              size="small"
              inputProps={{
                style: { fontSize: 18, fontWeight: 500, textAlign: "center" },
              }}
            />
            <LoadingButton
              variant="contained"
              color="warning"
              size="large"
              onClick={handleFormSubmit}
              loading={isWait}
            >
              Save
            </LoadingButton>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card>
                <List
                  subheader={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography variant="h6">Groups</Typography>
                      </Stack>

                      <Typography variant="subtitle1">
                        List of all groups
                      </Typography>
                    </>
                  }
                >
                  <Divider sx={{ my: 1 }} />
                  {groups?.length === 0 && (
                    <Typography variant="body1" mt={2} textAlign={"center"}>
                      No groups found.
                    </Typography>
                  )}
                  {groups?.map((item, idx) => (
                    <ListItemButton
                      key={idx}
                      sx={{
                        my: 2,
                        border:
                          selectedGroup?._id === item?._id
                            ? "1px solid orange"
                            : "none",
                      }}
                      onClick={() => handleSelectGroup(item._id)}
                    >
                      <ListItemIcon>
                        {selectedGroup?._id === item?._id ? (
                          <FolderOpen color="warning" />
                        ) : (
                          <FolderRounded color="warning" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={item?.title}
                        secondary={item?.description}
                      />
                      <IOSSwitch
                        onChange={() => {
                          if (slug?.includes(item?.slug)) {
                            let _slug = slug?.replace(item?.slug + "-", "");
                            item?.children?.forEach((child) => {
                              _slug = _slug?.replace(child?.slug + "-", "");
                            });
                            setSlug(_slug);
                          } else {
                            setSlug(slug + item?.slug + "-");
                          }
                        }}
                        checked={slug?.includes(item?.slug)}
                      />
                    </ListItemButton>
                  ))}
                </List>
                <Divider sx={{ mt: 2 }} />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <List
                  subheader={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography variant="h6">
                          {selectedGroup?.title}
                        </Typography>
                      </Stack>

                      <Typography variant="subtitle1">
                        {selectedGroup?.description}
                      </Typography>
                    </>
                  }
                >
                  <Divider sx={{ my: 1 }} />
                  {(!selectedGroup?.children ||
                    selectedGroup?.children?.length === 0) && (
                    <Typography
                      m={4}
                      variant="body1"
                      textAlign={"center"}
                      color="text.secondary"
                    >
                      No Programs yet.
                    </Typography>
                  )}
                  {selectedGroup?.children?.map((item, idx) => (
                    <ListItemButton
                      key={idx}
                      sx={{
                        my: 2,
                      }}
                    >
                      <ListItemIcon>
                        <FolderRounded color="warning" />
                      </ListItemIcon>
                      <ListItemText
                        primary={item?.title}
                        secondary={item?.description}
                      />
                      <IOSSwitch
                        onChange={() => {
                          if (slug?.includes(item?.slug)) {
                            const _slug = slug?.replace(item?.slug + "-", "");
                            setSlug(_slug);
                          } else {
                            // add group slug if not exist in slug
                            if (!slug?.includes(selectedGroup?.slug)) {
                              setSlug(
                                slug +
                                  selectedGroup?.slug +
                                  "-" +
                                  item?.slug +
                                  "-"
                              );
                            } else {
                              setSlug(slug + item?.slug + "-");
                            }
                          }
                        }}
                        checked={slug?.includes(item?.slug)}
                      />
                    </ListItemButton>
                  ))}
                </List>
                <Divider sx={{ mt: 2 }} />
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
}
