import React, { useContext, useState } from "react";

import { Box, Stack, Tooltip, Typography, IconButton } from "@mui/material";
import { FormContext } from "src/contexts/FormContext";
import CreateUpdateQuestion from "./CreateUpdateQuestion";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import DeleteTwoTone from "@mui/icons-material/DeleteTwoTone";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { generateRandomString } from "src/utils/helpers";

function EditableMisconduct({
  selectedMisconductForm,
  setSelectedMisconductForm,
  question,
  handler,
  questionTypeIcon,
}) {
  const {
    form,
    handleGetForm,
    selectedQuestion,
    setSelectedQuestion,
    selectedQuestionGroup,
    currentSelectedItemType,
    setSelectedOption,
    setCurrentSelectedItemType,
  } = useContext(FormContext);
  const [isWait, setIsWait] = useState(false);

  // const handleCopyQuestion = async () => {
  //   try {

  //     setIsWait(true);
  //     question.name = await generateRandomString(8);
  //     delete question._id;
  //   const resp = await axiosInstance.post(
  //     `/forms/question-group/${selectedQuestionGroup._id}`,
  //     question
  //   );
  //   if (resp?.status === 200) {
  //     await handleGetForm(form?._id);
  //     setSelectedQuestion(null);
  //     setSelectedOption(null);
  //     setCurrentSelectedItemType(null);

  //     setIsWait(false);
  //     Notify.success("Successfully");
  //   }
  // } catch (error) {
  //   setIsWait(false);
  //   Notify.failure(
  //     error.response?.data?.message ||
  //       error.response?.statusText ||
  //       error.message ||
  //       "an error occured"
  //   );
  // }
  // }

  // const handleDeleteQuestion = async () => {
  //   try {
  //     const confirm = window.confirm("Are you sure you want to delete?");
  //     if (!confirm) return;
  //     setIsWait(true);
  //     const resp = await axiosInstance.delete(
  //       `/forms/misconduct/${form?._id}/${question?._id}`,
  //       question
  //     );
  //     if (resp?.status === 200) {
  //       await handleGetForm(form?._id);

  //       setIsWait(false);
  //       Notify.success("Successfully");
  //     }
  //   } catch (error) {
  //     setIsWait(false);
  //     Notify.failure(
  //       error.response?.data?.message ||
  //         error.response?.statusText ||
  //         error.message ||
  //         "an error occured"
  //     );
  //   }
  // };

  const isEditable =
    selectedMisconductForm && selectedMisconductForm._id === question._id;

  // if (!isEditable) {
  //   return (
  //     <Stack
  //       direction="row"
  //       alignItems="center"
  //       spacing={1}
  //       justifyContent={"space-between"}
  //     >
  //       <Box sx={{ display: "flex", alignItems: "center" }}>
  //         {questionTypeIcon}
  //         <Typography variant="h6" mx={1}>
  //           {question?.title}
  //         </Typography>
  //       </Box>
  //       <Box>
  //       {/* <IconButton size="small" mx={2} disabled={isWait} color="primary" onClick={handleCopyQuestion}>
  //           <ContentCopyIcon fontSize="small" />
  //         </IconButton> */}

  //       <IconButton size="small" mx={2} disabled={isWait} color="error" onClick={handleDeleteQuestion}>
  //         <DeleteTwoTone fontSize="small" />
  //       </IconButton>
  //       </Box>
  //     </Stack>
  //   )
  // }

  return (
    <CreateUpdateQuestion
      handler={handler}
      mode={"update"}
      selectedMisconductForm={question}
      setSelectedMisconductForm={setSelectedMisconductForm}
    />
  );
}

export default EditableMisconduct;
