import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import {
  FORM_ACCESS_OBJECT,
  FORM_TYPES,
  PAGE_LIMIT,
} from "src/utils/constants";
import { GlobalContext } from "src/contexts/GlobalContext";
import {
  ArrowForwardIos,
  Close,
  EditTwoTone,
  FilterList,
  HighlightOffOutlined,
  RemoveOutlined,
  SportsScoreTwoTone,
  StarBorderOutlined,
  SwapVert,
  VisibilityOutlined,
} from "@mui/icons-material";
import Label from "src/components/Label";
import Exams from "../exams";
import NewFormDialog from "../FormBuilder/NewFormDialog";
import SuspenseLoader from "src/components/SuspenseLoader";
import RateFormButton from "./components/RateFormButton";

export default function Forms({ user, formType }) {
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const receiverTypeParam = searchParams.get("receiverType");
  const userParam = searchParams.get("receiverId");
  const statusParam = searchParams.get("status");
  const formParam = searchParams.get("type");
  const orgId = searchParams.get("orgId");
  const showRatingButtonParam = searchParams.get("showRatingButton");

  const [filterObj, setFilterObj] = useState({
    status: statusParam || null,
  });
  const [loading, setLoading] = useState(true);
  const [receiverId, setReceiverId] = useState(userParam || user);
  const [forms, setForms] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [allowRating, setAllowRating] = useState(false);

  useEffect(() => {
    if (showRatingButtonParam === "true") {
      setAllowRating(true);
    }
  }, [showRatingButtonParam]);

  useEffect(() => {
    if (receiverTypeParam || userParam || orgId) {
      setAllowRating(true);
    }
  }, [currentUser, receiverTypeParam, userParam, orgId]);

  const handlePageChange = (event, value) => {
    setPage(value + 1);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value + 1,
    });
  };

  useEffect(() => {
    setPage(parseInt(pageParam) || 1);
    if (userParam) {
      setReceiverId(userParam);
    }
    if (statusParam) {
      setFilterObj({ ...filterObj, status: statusParam });
    }
    if (formParam) {
      setFilterObj({ ...filterObj, type: formParam });
    }
  }, [searchParams]);

  useEffect(() => {
    let _userId = userParam || user;
    // if (currentUser?.role === "user") {
    //   _userId = currentUser?.userId;
    // }
    setReceiverId(_userId);

    (async () => {
      setLoading(true);
      const reqParams = {
        params: { limit: PAGE_LIMIT, skip: (page - 1) * PAGE_LIMIT },
      };

      if (filterObj) {
        reqParams.params = { ...reqParams.params, ...filterObj };
      }

      const _formType = formParam || formType;
      if (_formType) {
        reqParams.params = { ...reqParams.params, type: _formType };
      }

      if (currentUser?.role === "user" && formType !== "exam") {
        // reqParams.params = { ...reqParams.params, type: "community" };
      }

      if (_userId) {
        reqParams.params.status = statusParam || "published";
      }

      if (receiverId) {
        reqParams.params.receiverId = receiverId;
      }

      if (receiverTypeParam) {
        reqParams.params.receiverType = receiverTypeParam;
      }

      const resp = await axiosInstance.get("/forms", reqParams);
      if (resp?.status === 200) {
        setForms(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
        setOrganisations(resp?.data?.organisations || []);
      }
      setLoading(false);
    })();
  }, [
    page,
    filterObj,
    userParam,
    formParam,
    formType,
    receiverId,
    statusParam,
  ]);

  useEffect(() => {
    (async () => {
      const _industries = await getTagsByType("industries");
      setIndustries(_industries);
    })();
  }, []);

  return (
    <Box mx={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 2 }}
      >
        <Stack direction="row" spacing={2}>
          <Box fullWidth>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here..."
                inputProps={{ "aria-label": "search here" }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (e.target.value.length) {
                      setFilterObj({ ...filterObj, search: searchString });
                    } else {
                      delete filterObj.search;
                      setFilterObj({ ...filterObj, search: null });
                    }
                    setPage(1);
                    setSearchParams({
                      ...Object.fromEntries(searchParams.entries()),
                      page: 1,
                    });
                  }
                }}
                onChange={(e) => {
                  if (e.target.value?.length > 0) {
                    setSearchString(e.target.value);
                  } else {
                    if (filterObj?.search) {
                      setFilterObj({
                        ...filterObj,
                        search: null,
                      });
                    }
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{
                  p: "10px",
                  m: 0.2,
                  bgcolor: "primary.main",
                  color: "white",
                }}
                onClick={() => {
                  setFilterObj({
                    ...filterObj,
                    search: searchString,
                  });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            {(currentUser?.accountType === "admin" || currentUser?.orgId) &&
              !receiverId && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFilterObj({ ...filterObj, type: "community" });
                        } else {
                          delete filterObj.type;
                          setFilterObj({ ...filterObj });
                        }
                        searchParams?.delete("page");
                        setSearchParams(searchParams);
                      }}
                    />
                  }
                  label={<Typography>Show community forms only</Typography>}
                />
              )}
          </Box>

          {formType === "exam" ? (
            <Button
              color="primary"
              size="small"
              endIcon={<ArrowForwardIos />}
              component={Link}
              to={`/dashboard/exams/submitted`}
            >
              Submitted Exams
            </Button>
          ) : (
            currentUser?.role === "user" && (
              <Button
                size="sm"
                component={Link}
                to={`/dashboard/ratings`}
                endIcon={<ArrowForwardIos fontSize="small" />}
              >
                Rating history
              </Button>
            )
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          {!showFilter && currentUser?.role !== "user" && !receiverId && (
            <NewFormDialog page={"forms"} formType={formType} />
          )}
          {currentUser?.accountType === "admin" && formType !== "exam" && (
            <Autocomplete
              disablePortal
              size="small"
              options={Object.keys(FORM_TYPES) || []}
              getOptionLabel={(option) => capitalize(option || "")}
              sx={{ width: 250 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Type" />
              )}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  type: v,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            />
          )}
          <Button
            color="secondary"
            startIcon={showFilter ? <Close /> : <FilterList />}
            onClick={() => setShowFilter(!showFilter)}
            size="small"
          >
            {showFilter ? "Hide" : "Filter"}
          </Button>
        </Stack>
      </Stack>
      {showFilter && (
        <Stack direction={"row"} spacing={2}>
          {["admin", "triager"].includes(currentUser?.accountType) && (
            <>
              <Autocomplete
                disablePortal
                size="small"
                options={[
                  { _id: "null", title: "EthixFirst" },
                  ...(organisations || []),
                ]}
                sx={{ width: 250 }}
                getOptionLabel={(option) => option?.title || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Select Organisation" />
                )}
                onChange={(e, v) => {
                  setFilterObj({
                    ...filterObj,
                    organisation: v?._id,
                  });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              />
              <Autocomplete
                disablePortal
                size="small"
                options={industries || []}
                sx={{ width: 250 }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Industry" />
                )}
                onChange={(e, v) => {
                  setFilterObj({
                    ...filterObj,
                    industry: v,
                  });
                  setPage(1);
                  setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    page: 1,
                  });
                }}
              />
            </>
          )}
          {currentUser?.role !== "user" && (
            <Autocomplete
              disablePortal
              size="small"
              options={Object.keys(FORM_ACCESS_OBJECT)}
              sx={{ width: 250 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Access" />
              )}
              onChange={(e, v) => {
                setFilterObj({
                  ...filterObj,
                  access: v,
                });
                setPage(1);
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  page: 1,
                });
              }}
            />
          )}{" "}
          <Autocomplete
            disablePortal
            size="small"
            options={["published", "draft"]}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField {...params} label="Filter by Status" />
            )}
            onChange={(e, v) => {
              setFilterObj({
                ...filterObj,
                status: v,
              });
              setPage(1);
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                page: 1,
              });
            }}
          />
          <FormControlLabel
            checked={filterObj?.premium || false}
            control={<Switch color="success" />}
            label="Premium "
            labelPlacement="start"
            onChange={(e) => {
              const _filterObj = { ...filterObj };
              if (e.target.checked) {
                _filterObj.premium = true;
              } else {
                delete _filterObj.premium;
              }
              setFilterObj(_filterObj);
            }}
          />
        </Stack>
      )}

      <Box my={3}>
        <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Stack mb={3}>
              <Typography variant="h2" component="h2" gutterBottom>
                {formType === "exam" ? "Exams Dashboard" : "Rating Forms"}
              </Typography>
              {formType !== "exam" && (
                <Typography variant="subtitle1" component="p">
                  You can rate a person or organisation by clicking on the "Rate
                  a Person / Organisation" button.
                </Typography>
              )}
            </Stack>

            {formType !== "exam" &&
              !["triager", "admin"].includes(currentUser?.accountType) && (
                <Button
                  startIcon={
                    allowRating ? (
                      <HighlightOffOutlined />
                    ) : (
                      <StarBorderOutlined />
                    )
                  }
                  color={allowRating ? "secondary" : "primary"}
                  variant={allowRating ? "text" : "contained"}
                  onClick={() => {
                    setAllowRating(!allowRating);
                  }}
                >
                  {allowRating ? "cancel" : "Rate a Person / Organisation"}
                </Button>
              )}
          </Stack>
          <Divider />
          {loading ? (
            <SuspenseLoader />
          ) : (
            <Box sx={{ my: 2 }}>
              {currentUser?.role === "user" && formType === "exam" ? (
                <Exams user={user} formType={formType} items={forms} />
              ) : (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Premium</TableCell>

                        {!user && (
                          <>
                            {currentUser?.accountType === "admin" && (
                              <>
                                <TableCell>
                                  Created By
                                  <Typography>
                                    <small>(Organisation)</small>
                                  </Typography>
                                </TableCell>
                                <TableCell>Industry</TableCell>
                              </>
                            )}
                            {currentUser?.accountType !== "user" && (
                              <>
                                <TableCell title="Who can submit this form!">
                                  Form Type
                                  <Typography>
                                    <small>accessibility</small>
                                  </Typography>
                                </TableCell>
                                <TableCell>Status</TableCell>
                              </>
                            )}
                          </>
                        )}

                        <TableCell
                          align="right"
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Tooltip
                            title={`Click to see ${
                              filterObj?.sort === "createdAt"
                                ? "latest"
                                : "oldest"
                            } first`}
                          >
                            <Button
                              endIcon={<SwapVert />}
                              color="secondary"
                              onClick={() => {
                                setFilterObj({
                                  ...filterObj,
                                  sort:
                                    filterObj?.sort === "createdAt"
                                      ? "-createdAt"
                                      : "createdAt",
                                });
                                setPage(1);
                                setSearchParams({
                                  ...Object.fromEntries(searchParams.entries()),
                                  page: 1,
                                });
                              }}
                            >
                              Actions
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {forms?.map((row, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ maxWidth: "300px" }}
                            title={row?.title}
                          >
                            <Stack direction="row">
                              <Chip
                                avatar={
                                  row?.coverImage && (
                                    <Avatar
                                      variant="rounded"
                                      alt=""
                                      src={
                                        process.env.REACT_APP_DO_SPACE_URL +
                                        row?.coverImage +
                                        `?${Math.random()}`
                                      }
                                    />
                                  )
                                }
                                label={<b>{row?.title}</b>}
                                sx={{
                                  border: 0,
                                }}
                                variant="outlined"
                              />
                              {row?.includeScore && (
                                <Tooltip title="This Form's Score will be included in User's Score.">
                                  <sup>
                                    <small>
                                      <SportsScoreTwoTone
                                        sx={{
                                          fontSize: "1rem",
                                        }}
                                      />
                                    </small>
                                  </sup>
                                </Tooltip>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Label color={row?.premium ? "warning" : "success"}>
                              {row?.premium ? (
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Premium
                                </b>
                              ) : (
                                "FREE"
                              )}
                            </Label>
                          </TableCell>
                          {!user && (
                            <>
                              {currentUser?.accountType === "admin" && (
                                <TableCell>
                                  {row?.user?.firstName || "-"}{" "}
                                  {row?.user?.lastName || ""}
                                  <Typography variant="caption" component={"p"}>
                                    <small>
                                      - {row?.organisation?.title || ""}
                                    </small>
                                  </Typography>
                                </TableCell>
                              )}
                              {currentUser?.accountType === "admin" && (
                                <TableCell
                                  sx={{
                                    maxWidth: 200,
                                    // ellipsis: true,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  title={
                                    row?.industry?.length > 0
                                      ? row?.industry
                                      : ""
                                  }
                                >
                                  {row?.industry?.length > 0
                                    ? row?.industry?.join(", ")
                                    : "-"}
                                </TableCell>
                              )}

                              {currentUser?.accountType !== "user" && (
                                <>
                                  <TableCell>
                                    <Label
                                      color={
                                        FORM_TYPES[row?.type]?.color || "info"
                                      }
                                    >
                                      {FORM_TYPES[row?.type]?.label}
                                    </Label>
                                    <Typography
                                      variant="subtitle1"
                                      mx={1}
                                      component={"p"}
                                    >
                                      <small>
                                        {/* {
                                      FORM_RATING_COMBINATIONS?.find(
                                        (item) =>
                                          item?.value ===
                                          `${row?.access}-${row?.receivers?.[0]}`
                                      )?.label
                                    } */}
                                        {row?.organisation && (
                                          <Typography
                                            mx={1}
                                            component={"span"}
                                            color={"red"}
                                          >
                                            <small>- Internal</small>
                                          </Typography>
                                        )}
                                      </small>
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {capitalize(row?.status || "-")}
                                  </TableCell>
                                </>
                              )}
                            </>
                          )}
                          <TableCell align="right">
                            <Box display={"flex"} justifyContent="flex-end">
                              {row?.type === "exam" &&
                              currentUser?.accountType !== "admin" &&
                              row?.organisation !== currentUser?.orgId ? (
                                <></>
                              ) : (
                                <Tooltip title="Preview">
                                  <IconButton
                                    color="info"
                                    component={Link}
                                    to={`/dashboard/forms/${row?._id}/preview`}
                                  >
                                    <VisibilityOutlined />
                                  </IconButton>
                                </Tooltip>
                              )}

                              {allowRating && (
                                <RateFormButton
                                  receiverId={receiverId}
                                  item={row}
                                />
                              )}

                              {(currentUser?.accountType === "admin" ||
                                (currentUser?.orgId &&
                                  currentUser?.orgId ===
                                    row?.organisation?._id)) && (
                                <>
                                  <IconButton
                                    size="small"
                                    component={Link}
                                    to={`/forms/${row?._id}`}
                                    color="warning"
                                  >
                                    <EditTwoTone />
                                  </IconButton>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                      {forms?.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            No {formType || "forms"} found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {forms?.length > 0 && (
                    <TablePagination
                      rowsPerPageOptions={[]}
                      component="div"
                      count={totalPages}
                      rowsPerPage={PAGE_LIMIT}
                      page={page - 1}
                      onPageChange={handlePageChange}
                    />
                  )}
                </TableContainer>
              )}
            </Box>
          )}
        </Card>
      </Box>
    </Box>
  );
}
