import {
  Box,
  Card,
  Typography,
  Button,
  CardActionArea,
  CardMedia,
  useTheme,
  IconButton,
  Stack,
  CardContent,
  TextField,
} from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Link, useParams } from "react-router-dom";

import { useContext, useState } from "react";
import { Confirm, Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { CloudDownloadTwoTone, Grading } from "@mui/icons-material";
import { fDate, fTime } from "src/utils/date-fns";
import { LoadingButton } from "@mui/lab";
import { GlobalContext } from "src/contexts/GlobalContext";
import { MANUAL_REPORT_STATUS } from "src/utils/constants";

function ManualFormSubmissionLogs({
  data,
  setData,
  submissionId,
  getSubmissions,
}) {
  const { currentUser } = useContext(GlobalContext);
  const theme = useTheme();
  const [isWait, setIsWait] = useState(false);
  const [message, setMessage] = useState("");

  const handleChangeStatus = async (status, message) => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.put(`/submissions/${submissionId}`, {
        status,
        message:
          message ||
          `Status changed to ${
            MANUAL_REPORT_STATUS[status]?.label?.toUpperCase() || status
          }`,
      });
      if (resp?.status === 200) {
        setData({
          ...data,
          status: status,
        });
        Notify.success("Status changed successfully");
      }
      setIsWait(false);
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleSubmit = async (status) => {
    try {
      setIsWait(true);
      const resp = await axiosInstance.post(
        `/submissions/${submissionId}/comment`,
        {
          message,
        }
      );
      if (resp?.status === 200) {
        getSubmissions();
      }
      setIsWait(false);

      if (status) {
        handleChangeStatus(
          status,
          `Submission marked as ${
            MANUAL_REPORT_STATUS[status]?.label?.toUpperCase() || status
          }`
        );
      }
    } catch (error) {
      console.log(error);
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <Box my={2}>
      <Timeline>
        {data &&
          data?.logs?.map((item, idx) => (
            <TimelineItem
              key={idx}
              sx={{
                p: 0,
              }}
            >
              <TimelineOppositeContent
                sx={{
                  width: "200px",
                  flex: "none",
                  whiteSpace: "nowrap",
                }}
                color="text.secondary"
              >
                <Typography>
                  <strong>{fDate(item?.createdAt)}</strong>
                </Typography>
                {fTime(item?.createdAt)}
              </TimelineOppositeContent>
              <TimelineSeparator
                sx={{
                  position: "relative",
                  mx: 2,
                }}
              >
                <TimelineDot
                  sx={{
                    marginTop: 0,
                    top: theme.spacing(1.2),
                  }}
                  variant="outlined"
                  color="primary"
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  pb: 4,
                }}
              >
                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  {item?.message && (
                    <Typography variant="subtitle1">{item?.message}</Typography>
                  )}
                  {item?.by && (
                    <>
                      <Typography
                        component={Link}
                        to={`/dashboard/users/${item?.by?._id}`}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        by-{" "}
                        <b>
                          {item?.by?.firstName} {item?.by?.lastName}
                        </b>
                      </Typography>
                    </>
                  )}
                </Stack>

                {item?.linktext && (
                  <Button
                    size="small"
                    sx={{ my: 2 }}
                    variant="contained"
                    component={item?.link ? Link : "span"}
                    to={item?.link ? item?.link : ""}
                    color={item?.linkcolor || "warning"}
                  >
                    {item?.linktext}
                  </Button>
                )}
                {item?.media && item?.media?.length > 0 && (
                  <Box display="flex" mt={2} alignItems="flex-start">
                    {item?.media?.map((media, indx) => (
                      <Card
                        key={indx}
                        sx={{
                          mr: 2,
                          width: media?.type !== "image" && 64,
                          height: media?.type !== "image" && 64,
                        }}
                      >
                        <CardActionArea
                          component="a"
                          href={
                            process.env.REACT_APP_DO_SPACE_URL +
                            media?.url +
                            `?${Math.random()}`
                          }
                          target="_blank"
                        >
                          {media?.type?.includes("image") ? (
                            <CardMedia
                              component="img"
                              height="64"
                              image={
                                process.env.REACT_APP_DO_SPACE_URL +
                                media?.url +
                                `?${Math.random()}`
                              }
                              alt={media?.type}
                            />
                          ) : (
                            <IconButton
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{
                                height: 64,
                                width: 64,
                              }}
                            >
                              <CloudDownloadTwoTone />
                            </IconButton>
                          )}
                        </CardActionArea>
                      </Card>
                    ))}
                  </Box>
                )}
              </TimelineContent>
            </TimelineItem>
          ))}

        {data?.status === "open" && currentUser?.role !== "user" && (
          <Box>
            <LoadingButton
              fullWidth
              variant="outlined"
              sx={{
                border: "1px dashed",
              }}
              loading={isWait}
              color="warning"
              startIcon={<Grading />}
              onClick={() => {
                Confirm.show(
                  "Confirmation",
                  "Are you sure you want to start reviewing the complaint?",
                  "Confirm",
                  "Cancel",
                  () => handleChangeStatus("in-review", null),
                  () => {},
                  {
                    width: "500px",
                    messageMaxLength: 1000,
                  }
                );
              }}
            >
              Start Reviewing the complaint
            </LoadingButton>
          </Box>
        )}

        {data?.status === "in-review" && (
          <Card variant="outlined">
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4">
                  Add Comment to the Complaint
                </Typography>
              </Stack>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label="Message"
                  placeholder="Write your message here... "
                  multiline
                  rows={4}
                  variant="outlined"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={2}
                mt={2}
              >
                <Box flexGrow={1}>
                  {currentUser?.accountType !== "user" && (
                    <LoadingButton
                      onClick={() => {
                        Confirm.show(
                          "Confirmation",
                          "Are you sure you want to mark the complaint as resolved?",
                          "Confirm",
                          "Cancel",
                          () => handleSubmit("closed"),

                          () => {},
                          {
                            width: "500px",
                            messageMaxLength: 1000,
                          }
                        );
                      }}
                      loading={isWait}
                      disabled={!message}
                      color="secondary"
                    >
                      Mark as Resolved with this Comment
                    </LoadingButton>
                  )}
                </Box>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    Confirm.show(
                      "Confirmation",
                      "Are you sure to add comment?",
                      "Confirm",
                      "Cancel",
                      () => handleSubmit(""),

                      () => {},
                      {
                        width: "500px",
                        messageMaxLength: 1000,
                      }
                    );
                  }}
                  loading={isWait}
                  disabled={!message}
                >
                  Add Comment
                </LoadingButton>
              </Stack>
            </CardContent>
          </Card>
        )}
      </Timeline>
    </Box>
  );
}

export default ManualFormSubmissionLogs;
