import "swiper/css";
import "swiper/css/pagination";

import ExploreEvents from "./components/ExploreEvents";
import ExploreCompanies from "./components/ExploreCompanies";
import { Stack } from "@mui/material";

export default () => {
  return (
    <>
      <Stack spacing={3} mb={20}>
        <ExploreEvents />
        <ExploreCompanies />
      </Stack>
    </>
  );
};
