import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AddEditEvent from "./AddEditEvent";
import AddRemoveFormInEvent from "./components/AddRemoveFormInEvent";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import SuspenseLoader from "src/components/SuspenseLoader";
import AddParticipant from "./components/AddParticipant";
import { Card, CardHeader, Divider, Tab, Tabs } from "@mui/material";
import SearchParticipant from "./components/SearchAndAddParticipant";
import EventRegistrations from "./components/EventRegistrations";

export default function EventActions() {
  let { eventId, action } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/events/${eventId}`);
        if (resp?.status === 200) {
          setEvent(resp?.data?.data || {});
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    })();
  }, [eventId]);

  const [tabValue, setTabValue] = useState("search");

  return loading ? (
    <SuspenseLoader />
  ) : (
    <>
      {
        {
          edit: <AddEditEvent event={event} setEvent={setEvent} />,
          forms: <AddRemoveFormInEvent event={event} setEvent={setEvent} />,
          registrations: (
            <EventRegistrations event={event} setEvent={setEvent} />
          ),
          "add-participant": (
            <Card sx={{ my: 2 }}>
              <CardHeader title="Add Participant(s)" />
              <Divider />
              <Tabs
                onChange={(e, val) => setTabValue(val)}
                value={tabValue}
                variant="fullWidth"
                sx={{
                  m: 1,
                  "& .MuiTabs-indicator": {
                    borderRadius: "5px 5px 0 0",
                  },
                }}
              >
                {[
                  {
                    label: "Search Participant",
                    value: "search",
                  },
                  {
                    label: "Create New Participant",
                    value: "new",
                  },
                  // {
                  //   label: "Bulk Upload Participants",
                  //   value: "bulk",
                  // },
                ].map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              {
                {
                  search: <SearchParticipant event={event} />,
                  new: <AddParticipant event={event} />,
                  bulk: <AddParticipant event={event} />,
                }[tabValue]
              }
            </Card>
          ),
        }[action]
      }
    </>
  );
}
