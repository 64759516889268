import {
  Box,
  CardMedia,
  Typography,
  Card,
  CardHeader,
  Divider,
  Avatar,
  Button,
  styled,
  Stack,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";
import { Notify } from "notiflix";
import { useContext, useEffect, useState } from "react";
import SuspenseLoader from "src/components/SuspenseLoader";
import { format } from "date-fns";
import {
  AccessTimeTwoTone,
  ArrowForwardIos,
  DateRangeTwoTone,
  EmailTwoTone,
  OpenInNew,
  PhoneCallback,
  PlaceTwoTone,
} from "@mui/icons-material";
import ShareEvent from "./components/ShareEvent";
import QrCodeShare from "./components/QrCodeShare";
import { GlobalContext } from "src/contexts/GlobalContext";
import EventBulkActions from "./components/EventBulkActions";
import { dateDifferenceFromNow } from "src/utils/date-fns";
import { EVENT_AUDIENCE, EVENT_STATUS } from "src/utils/constants";
import { Link } from "react-router-dom";
import EmptyHere from "src/components/EmptyHere";
import JoinEventDialog from "./components/JoinEventDialog";

export default function Event() {
  const { currentUser } = useContext(GlobalContext);
  let { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [registration, setRegistration] = useState(null);
  const [formResponses, setFormResponses] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/events/${eventId}`);
        if (resp?.status === 200) {
          setEvent(resp?.data?.data || {});
          setRegistration(resp?.data?.registration || null);
          setFormResponses(resp?.data?.formResponses || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            error.message ||
            "an error occured"
        );
      }
    })();
  }, [eventId]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack my={2}>
      {event ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    src={
                      event?.organisation?.avatar
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          (event?.organisation?.avatar + "?" + Math.random())
                        : ""
                    }
                  />
                }
                titleTypographyProps={{ variant: "h4" }}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                title={event?.organisation?.title || ""}
                subheader={event?.organisation?.companyWebsite || ""}
                action={
                  currentUser?.role !== "user" && (
                    <Chip
                      sx={{ fontWeight: "bold" }}
                      color={EVENT_STATUS[event?.status || "draft"]?.color}
                      label={
                        EVENT_STATUS[event?.status || "draft"]?.description
                      }
                    />
                  )
                }
                sx={{ mx: 1 }}
              />
              <CardMedia
                sx={{
                  minHeight: 350,
                  mx: 3,
                  borderRadius: "0.5rem",
                }}
                image={
                  event?.coverImage
                    ? process.env.REACT_APP_DO_SPACE_URL +
                      (event?.coverImage + "?" + Math.random())
                    : "/static/images/placeholders/illustrations/wireframe.svg"
                }
                title={event?.title?.charAt(0)}
              />
              <Box p={3}>
                <Typography
                  variant="h1"
                  sx={{
                    pb: 1,
                  }}
                >
                  {event?.title || ""}{" "}
                </Typography>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DateRangeTwoTone
                      fontSize="small"
                      sx={{
                        opacity: 0.7,
                        mr: 0.5,
                      }}
                      color="primary"
                    />
                    {event?.startDateTime &&
                      format(new Date(event?.startDateTime), "dd MMM")}{" "}
                    -{" "}
                    {event?.startDateTime &&
                      format(new Date(event?.endDateTime), "dd MMM")}
                  </Typography>{" "}
                  {new Date(event?.endDateTime) > new Date() && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AccessTimeTwoTone
                        fontSize="small"
                        sx={{
                          opacity: 0.7,
                          mr: 0.5,
                        }}
                        color="warning"
                      />
                      {format(new Date(event?.startDateTime), "hh:mm a")} -{" "}
                      {format(new Date(event?.endDateTime), "hh:mm a")}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PlaceTwoTone
                      fontSize="small"
                      sx={{
                        opacity: 0.7,
                        mr: 0.5,
                      }}
                      color="success"
                    />
                    {event?.isVirtual ? (
                      "Virtual"
                    ) : (
                      <>
                        {event?.address?.city}
                        {!!event?.address?.city && ","}{" "}
                        {event?.address?.state || event?.address?.country || ""}
                      </>
                    )}
                  </Typography>
                  {(currentUser?.accountType === "admin" ||
                    currentUser?.orgId === event?.organisation?._id) && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {(() => {
                        const audience = EVENT_AUDIENCE.find(
                          (item) => item.value === event?.audience
                        );
                        if (!audience) return null;
                        return (
                          <>
                            <audience.icon
                              fontSize="small"
                              sx={{
                                opacity: 0.7,
                                mr: 0.5,
                              }}
                              color="info"
                            />
                            {audience?.label}
                          </>
                        );
                      })()}
                    </Typography>
                  )}
                </Stack>
                <Box pt={1}>
                  {event?.tags?.map((value) => {
                    return (
                      <ChipWrapper
                        key={value}
                        color="secondary"
                        label={value}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Divider />

              {(() => {
                if (
                  currentUser?.role === "user" &&
                  (!registration || event?.forms?.length === 0)
                )
                  return null;
                else if (
                  registration &&
                  event?.forms?.length > 0 &&
                  !event?.forms?.some((item) =>
                    item?.relations?.includes(registration?.relation)
                  )
                )
                  return null;
                return (
                  <>
                    <Card sx={{ m: 3 }}>
                      <CardHeader
                        title="Forms"
                        subheader="Forms available for this event"
                        action={
                          (currentUser?.accountType === "admin" ||
                            currentUser?.orgId === event?.organisation?._id) &&
                          event?.forms?.length > 0 && (
                            <Button
                              component={Link}
                              to={`/dashboard/ratings?eventId=${event?._id}`}
                              endIcon={<OpenInNew />}
                            >
                              Check Responses
                            </Button>
                          )
                        }
                      />
                      <Table>
                        <TableBody>
                          {event?.forms?.length > 0 ? (
                            event?.forms?.map((item, index) => {
                              if (
                                registration &&
                                !item?.relations?.includes(
                                  registration?.relation
                                )
                              )
                                return null;
                              return (
                                <TableRow key={index}>
                                  <TableCell width={"20px"}>
                                    {index + 1}.
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h4">
                                      {item?.formId?.title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    {new Date(item?.expiresAt) > new Date() &&
                                      currentUser?.role === "user" && (
                                        <>
                                          {formResponses?.find(
                                            (response) =>
                                              response?.formId ===
                                              item?.formId?._id
                                          ) ? (
                                            <Button
                                              color="info"
                                              size="small"
                                              endIcon={<ArrowForwardIos />}
                                              component={"a"}
                                              href={`/dashboard/ratings/${
                                                formResponses?.find(
                                                  (response) =>
                                                    response?.formId ===
                                                    item?.formId?._id
                                                )?._id
                                              }`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ textDecoration: "none" }}
                                            >
                                              View Response
                                            </Button>
                                          ) : (
                                            <Button
                                              color="success"
                                              variant="outlined"
                                              size="small"
                                              endIcon={<OpenInNew />}
                                              component={"a"}
                                              href={`/dashboard/forms/${item?.formId?._id}?orgId=${event?.organisation?._id}&eventId=${event?._id}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ textDecoration: "none" }}
                                            >
                                              Submit Now
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    {(new Date(item?.expiresAt) < new Date() ||
                                      currentUser?.role !== "user") && (
                                      <Typography>
                                        Access expire
                                        {new Date(item?.expiresAt) < new Date()
                                          ? "d"
                                          : "s"}{" "}
                                        {dateDifferenceFromNow(item?.expiresAt)}
                                      </Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <EmptyHere title={"No form added yet"} />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Card>
                    <Divider />
                  </>
                );
              })()}

              <Box px={3} pb={2}>
                <Typography variant="h4" fontWeight="normal">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: event?.description || "",
                    }}
                  />
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={2}>
              {new Date(event?.endDateTime) > new Date() &&
                new Date(event?.startDateTime) < new Date() && (
                  <QrCodeShare
                    link={
                      process.env.REACT_APP_BASE_URL +
                      "/dashboard/events/" +
                      eventId
                    }
                  />
                )}
              {currentUser?.role === "user" && (
                <JoinEventDialog event={event} registration={registration} />
              )}
              {(currentUser?.accountType === "admin" ||
                currentUser?.orgId === event?.organisation?._id) && (
                <EventBulkActions event={event} setEvent={setEvent} />
              )}
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 1 0%",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      margin: "0px 0px 24px",
                      color: "rgb(88, 96, 105)",
                      textTransform: "uppercase",
                      fontSize: "14px",
                      lineHeight: "18px",
                    }}
                  >
                    Have any Question or need help?
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  {[
                    {
                      icon: <PhoneCallback />,
                      text: event?.contactPhone,
                    },
                    {
                      icon: <EmailTwoTone />,
                      text: event?.contactEmail,
                    },
                  ].map(
                    (item, idx) =>
                      item?.text && (
                        <Box
                          sx={{ display: "flex", marginBottom: "16px" }}
                          key={idx}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flex: "1 1 0%",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              filter: "none",
                            }}
                          >
                            <Box
                              sx={{
                                marginRight: "12px",
                                position: "relative",
                                cursor: "default",
                              }}
                            >
                              {item.icon}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                WebkitBoxPack: "center",
                                justifyContent: "center",
                                margin: "auto 0px",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgb(38, 38, 38)",
                                  margin: "0px",
                                  wordBreak: "break-word",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                }}
                              >
                                {item.text}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )
                  )}
                  <Box>
                    {event?.contactPhone && (
                      <Button
                        variant="contained"
                        component="a"
                        href={`tel:${event?.contactPhone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mr: 1, color: "#fff", borderRadius: 0.5 }}
                        startIcon={<PhoneCallback width={16} height={16} />}
                      >
                        Call
                      </Button>
                    )}
                    {event?.contactEmail && (
                      <Button
                        variant="contained"
                        component="a"
                        href={`mailto:${event?.contactEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: "#fff", borderRadius: 0.5 }}
                        startIcon={<EmailTwoTone width={16} height={16} />}
                      >
                        Email
                      </Button>
                    )}
                  </Box>
                </Box>
              </Card>

              <ShareEvent
                title={event?.title}
                link={
                  process.env.REACT_APP_BASE_URL +
                  "/dashboard/events/" +
                  eventId
                }
              />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <EmptyHere title={"No event found"} />
      )}
    </Stack>
  );
}

const ChipWrapper = styled(Chip)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      margin: ${theme.spacing(0.5)};
      padding: ${theme.spacing(1)};
      height: 28px;
      line-height: 28px;
      font-weight: bold;
`
);
