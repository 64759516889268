import React from "react";

import CreateUpdateQuestion from "./CreateUpdateQuestion";

function EditableQuestion({ handler, question, weightage }) {
  return (
    <CreateUpdateQuestion
      handler={handler}
      mode={"update"}
      questionGroup={question}
      weightage={weightage}
    />
  );
}

export default EditableQuestion;
