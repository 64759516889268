import { Stack } from "@mui/material";
import RatingsNav from "./components/RatingsNav";
import CompanyRatingCard from "./components/CompanyRatingCard";
import { useState } from "react";
import CommulativeRatingCard from "./components/CommulativeRatingCard";

export default function CompanyRatings() {
  const [tab, setTab] = useState("org-ratings");
  return (
    <Stack spacing={2}>
      <RatingsNav tab={tab} setTab={setTab} />
      {
        {
          "org-ratings": <CompanyRatingCard />,
          "commulative-ratings": <CommulativeRatingCard />,
        }[tab]
      }
    </Stack>
  );
}
