import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Fragment,
} from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { read, utils } from "xlsx";
import { LoadingButton } from "@mui/lab";
import { Confirm, Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function UsersBulkUpload() {
  const navigate = useNavigate();
  const { currentUser } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const organisationParam = searchParams.get("organisation");
  const theme = useTheme();
  const inputFile = useRef(null);
  const dropArea = useRef(null);
  const [loading, setLoading] = useState(false);
  const [sheetColumns, setSheetColumns] = useState([]);
  const [sheetRows, setSheetRows] = useState([]);
  const [page, setPage] = useState(0);
  const [step, setStep] = useState(1);
  const [placeholderToColumn, setPlaceholderToColumn] = useState({});
  const [isDone, setIsDone] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [organisationId, setOrganisationId] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [userInputs, setUserInputs] = useState([]);

  useEffect(() => {
    const _org = organisationParam || currentUser?.orgId || null;
    setOrganisationId(_org);
    if (_org) {
      setRoleType("user");
    }
    (async () => {
      try {
        const reqParams = {
          type: "users",
        };
        const resp = await axiosInstance.get("/tags", { params: reqParams });
        setUserInputs(resp.data?.[0]?.tags || []);
      } catch (error) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processFiles = async (e, files) => {
    e.preventDefault();

    dropArea.current.style.backgroundColor = "#fff";
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const [columns] = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 1,
          });
          setSheetColumns(columns || []);

          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setSheetRows(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async () => {
    try {
      setIsDone(false);
      setLoading(true);
      const users = [];

      sheetRows.forEach((row) => {
        const user = {};
        Object.keys(placeholderToColumn).forEach((placeholder) => {
          // if place holder is an object
          let _value;
          if (typeof placeholderToColumn[placeholder] === "object") {
            let nestedValue = {};
            Object.keys(placeholderToColumn?.[placeholder]).forEach(
              (nestedPlaceholder) => {
                nestedValue[nestedPlaceholder] =
                  row[placeholderToColumn?.[placeholder]?.[nestedPlaceholder]];
              }
            );
            _value = nestedValue;
          } else {
            _value = row[placeholderToColumn[placeholder]];
          }
          if (
            placeholder === "profileImage" &&
            typeof _value === "string" &&
            _value?.includes("https://drive.google.com/file/d/")
          ) {
            const _url = _value?.split("https://drive.google.com/file/d/")?.[1];
            const _id = _url?.split("/")?.[0];
            _value = `https://drive.google.com/uc?export=view&id=${_id}`;
          }
          user[placeholder] = _value;
        });

        if (organisationId) {
          user.role = roleType || null;
          user.organisation = organisationId;
        }
        users.push(user);
      });
      // upload users to server
      if (!users.length) {
        Notify.failure("No users found in the sheet");
        setLoading(false);
        return;
      }
      const resp = await axiosInstance.post("/users/bulk", {
        users,
      });
      if (resp.status === 200) {
        Notify.success("Users added successfully");
        setLoading(false);
        setIsDone(true);
        navigate("/dashboard/users");
      }
    } catch (error) {
      setLoading(false);
      setIsDone(false);
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };
  return (
    <Box sx={{ my: 2 }}>
      <div>
        <div className="file-upload">
          <div
            className="drop"
            ref={dropArea}
            onClick={() => inputFile.current.click()}
            onDrop={(e) => processFiles(e, e.dataTransfer.files)}
            onDragOver={(e) => {
              e.preventDefault();
              dropArea.current.style.backgroundColor = "#ddd";
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              dropArea.current.style.backgroundColor = "#fff";
            }}
            style={{
              cursor: "pointer",
              padding: sheetColumns?.length > 0 ? "30px" : "300px",
            }}
          >
            <div className="info">
              {"Drag & drop files or click to browse."}
              <br />
              {"CSV, XLS or XLSX only!"}
            </div>
          </div>

          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => processFiles(e, inputFile.current.files)}
            ref={inputFile}
            style={{ display: "none" }}
          />
        </div>
        <Alert
          severity="warning"
          sx={{ display: "flex", justifyContent: "center", my: 1 }}
        >
          <Typography variant="h5">
            Please make sure that the File must have First Name, Last Name, and
            Email columns.
          </Typography>
        </Alert>
        {step === 1 && sheetColumns?.length > 0 && (
          <Box sx={{ width: "100%", my: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {sheetColumns?.map((item, idx) => (
                        <TableCell key={idx}>{item} </TableCell>
                      ))}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sheetRows
                      ?.slice(page * 10, page * 10 + 10)
                      ?.map((row, idx) => {
                        return (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {sheetColumns?.map((item, index) => (
                              <TableCell key={index}>
                                {row[item] || "N/A"}
                              </TableCell>
                            ))}

                            <TableCell component="th" scope="row">
                              <DeleteIcon
                                color="error"
                                sx={{
                                  cursor: "pointer",
                                  top: 7,
                                  position: "relative",
                                }}
                                onClick={() => {
                                  Confirm.show(
                                    "Confirmation",
                                    "Are you sure you want to remove?",
                                    "Confirm",
                                    "Cancel",
                                    () => {
                                      const newSheetRows = sheetRows.filter(
                                        (item, index) => index !== idx
                                      );
                                      setSheetRows(newSheetRows);
                                    },
                                    () => {},
                                    {
                                      width: "500px",
                                      messageMaxLength: 1000,
                                    }
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                sx={{ my: 2 }}
                count={sheetRows?.length}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </Paper>
            <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
              <FormControl disabled={!organisationId}>
                <FormLabel id="user_role">Please select User Type</FormLabel>
                <RadioGroup
                  row
                  value={roleType}
                  onChange={(e) => setRoleType(e.target.value)}
                >
                  {!organisationId && (
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="None"
                    />
                  )}
                  <FormControlLabel
                    value="user"
                    control={<Radio />}
                    label="Employees"
                  />
                  <FormControlLabel
                    value="moderator"
                    control={<Radio />}
                    label="Moderators"
                  />
                  {currentUser?.accoutType === "admin" && (
                    <FormControlLabel
                      value="triager"
                      control={<Radio />}
                      label="Triagers"
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Stack>
          </Box>
        )}
        {step === 2 && (
          <Card sx={{ p: 2, my: 2 }}>
            <Box sx={{ width: "100%", my: 2 }}>
              <Grid container spacing={0}>
                {organisationId && roleType !== "triager" && (
                  <>
                    <Grid item xs={12} sm={4} justifyContent="flex-end">
                      <Box alignSelf="center">
                        <b>Employee ID:</b>
                      </Box>
                    </Grid>

                    <Grid
                      sx={{ mb: `${theme.spacing(2)}` }}
                      item
                      xs={12}
                      sm={8}
                      md={6}
                    >
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="employeeId">
                          Select Employee ID column
                        </InputLabel>
                        <Select
                          labelId="employeeId"
                          id="employeeId"
                          required
                          label="Select Employee ID column"
                          value={placeholderToColumn["employeeId"]}
                          onChange={(e) => {
                            setPlaceholderToColumn({
                              ...placeholderToColumn,
                              employeeId: e.target.value,
                            });
                          }}
                        >
                          {sheetColumns?.map((column, idx) => (
                            <MenuItem value={column} key={idx}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} justifyContent="flex-end">
                      <Box alignSelf="center">
                        <b>Organisation Email:</b>
                      </Box>
                    </Grid>

                    <Grid
                      sx={{ mb: `${theme.spacing(2)}` }}
                      item
                      xs={12}
                      sm={8}
                      md={6}
                    >
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="companyEmail">
                          Select Organisation Email
                        </InputLabel>
                        <Select
                          labelId="companyEmail"
                          id="companyEmail"
                          required
                          label="Select Organisation Email"
                          value={placeholderToColumn["companyEmail"]}
                          onChange={(e) => {
                            setPlaceholderToColumn({
                              ...placeholderToColumn,
                              companyEmail: e.target.value,
                            });
                          }}
                        >
                          {sheetColumns?.map((column, idx) => (
                            <MenuItem value={column} key={idx}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {[
                  {
                    title: "First Name*",
                    placeholder: "Select First Name Column",
                    value: placeholderToColumn?.["firstName"],
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        firstName: e.target.value,
                      });
                    },
                  },
                  {
                    title: "Last Name*",
                    placeholder: "Select Last Name Column",
                    value: placeholderToColumn?.["lastName"],
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        lastName: e.target.value,
                      });
                    },
                  },
                  {
                    title: "Email*",
                    placeholder: "Select Email Column",
                    value: placeholderToColumn?.["email"],
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        email: e.target.value,
                      });
                    },
                  },
                  {
                    title: "Phone Number",
                    placeholder: "Select Phone Number Column",
                    value: placeholderToColumn?.["phoneNumber"],
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        phoneNumber: e.target.value,
                      });
                    },
                  },
                  {
                    title: "Profile Image (Optional)",
                    placeholder: "Select Image Column",
                    value: placeholderToColumn?.["profileImage"],
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        profileImage: e.target.value,
                      });
                    },
                  },
                  {
                    title: "Complete Address",
                    placeholder: "Select Address Column",
                    value: placeholderToColumn?.["address"]?.["address1"] || "",
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        address: {
                          ...(placeholderToColumn?.address || {}),
                          address1: e.target.value,
                        },
                      });
                    },
                  },
                  {
                    title: "City",
                    placeholder: "Select City Column",
                    value: placeholderToColumn?.["address"]?.["city"] || "",
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        address: {
                          ...(placeholderToColumn?.address || {}),
                          city: e.target.value,
                        },
                      });
                    },
                  },
                  {
                    title: "State",
                    placeholder: "Select State Column",
                    value: placeholderToColumn?.["address"]?.["state"] || "",
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        address: {
                          ...(placeholderToColumn?.address || {}),
                          state: e.target.value,
                        },
                      });
                    },
                  },
                  {
                    title: "Pincode",
                    placeholder: "Select Pincode Column",
                    value: placeholderToColumn?.["address"]?.["pincode"] || "",
                    onChange: (e) => {
                      setPlaceholderToColumn({
                        ...placeholderToColumn,
                        address: {
                          ...(placeholderToColumn?.address || {}),
                          pincode: e.target.value,
                        },
                      });
                    },
                  },
                ]?.map((_address, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={4} justifyContent="flex-end">
                      <Box alignSelf="center">
                        <b>{_address?.title} :</b>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{ mb: `${theme.spacing(2)}` }}
                      item
                      xs={12}
                      sm={8}
                      md={6}
                    >
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="address">
                          {_address?.placeholder}
                        </InputLabel>
                        <Select
                          labelId="address"
                          id="address"
                          required
                          label={_address?.placeholder}
                          value={_address.value || ""}
                          onChange={_address?.onChange}
                        >
                          {sheetColumns?.map((column, idx) => (
                            <MenuItem value={column} key={idx}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Fragment>
                ))}
                {userInputs?.map((tag, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={4} justifyContent="flex-end">
                      <Box alignSelf="center">
                        <b>{tag} :</b>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{ mb: `${theme.spacing(2)}` }}
                      item
                      xs={12}
                      sm={8}
                      md={6}
                    >
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="image">Select {tag} Column</InputLabel>
                        <Select
                          labelId={tag}
                          id={tag}
                          label={`Select ${tag} Column`}
                          value={placeholderToColumn[tag]}
                          onChange={(e) => {
                            setPlaceholderToColumn({
                              ...placeholderToColumn,
                              [tag]: e.target.value,
                            });
                          }}
                        >
                          {sheetColumns?.map((column, idx) => (
                            <MenuItem value={column} key={idx}>
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Fragment>
                ))}
                <Grid item xs={12} sm={4} justifyContent="flex-end">
                  <Box alignSelf="center">
                    <b>CopConnect :</b>
                  </Box>
                  <small>
                    Select a column that has <b>(yes/no)</b> values to decide
                    whether the user should be added to CopConnect or not.
                  </small>
                </Grid>
                <Grid
                  sx={{ mb: `${theme.spacing(2)}` }}
                  item
                  xs={12}
                  sm={8}
                  md={6}
                >
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="address">
                      Select CopConnect Column
                    </InputLabel>
                    <Select
                      labelId="address"
                      id="address"
                      required
                      label={"Select CopConnect Column"}
                      value={placeholderToColumn["copconnect"]}
                      onChange={(e) => {
                        setPlaceholderToColumn({
                          ...placeholderToColumn,
                          copconnect: e.target.value,
                        });
                      }}
                    >
                      {sheetColumns?.map((column, idx) => (
                        <MenuItem value={column} key={idx}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Card>
        )}
        {isDone && (
          <Alert
            severity="success"
            sx={{ display: "flex", justifyContent: "center", my: 1 }}
          >
            <Typography variant="h5">
              {sheetRows?.length} Users Added Successfully.
            </Typography>
          </Alert>
        )}
        {sheetColumns?.length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={step === 1}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }}>
              {loading && (
                <Alert severity="warning">
                  Please wait... while we are uploading the users. This may take
                  a while. Please do not close the browser or refresh the page.
                </Alert>
              )}
            </Box>

            <LoadingButton
              onClick={step === 2 ? handleSubmit : handleNext}
              variant="contained"
              loading={loading}
            >
              {step === 2 ? "Submit" : "Next"}
            </LoadingButton>
          </Box>
        )}
      </div>
    </Box>
  );
}
