import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function EmptyHere({ title, subtitle, action, ...rest }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        py: rest?.py || 5,
        px: rest?.px || 2.5,
        flexGrow: 1,
        textAlign: "center",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: `${theme.colors.alpha.black[70]}`,
          mb: 0.8,
          fontSize: "1.2rem",
        }}
      >
        {title || "It's empty here :("}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: `${theme.colors.alpha.black[50]}`,
          mb: 2,
        }}
      >
        {subtitle || "There is no data to display"}
      </Typography>
      {action && (
        <Button
          component={Link}
          to={action?.link}
          variant="contained"
          endIcon={<ArrowForwardIos />}
        >
          {action?.text}
        </Button>
      )}
    </Box>
  );
}
