import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useState } from "react";
import { FormContext } from "src/contexts/FormContext";
import axiosInstance from "src/utils/axiosInstance";

export default function NewQuestionGroupDialog() {
  const {
    form,
    setForm,
    selectedFormGroup,
    setSelectedQuestionGroup,
    setSelectedQuestion,
    setCurrentSelectedItemType,
    setSelectedOption,
  } = useContext(FormContext);
  const [open, setOpen] = useState(false);
  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormInput({});
  };

  const handleSubmit = async () => {
    try {
      if (!formInput?.title) throw new Error("Form title is required");
      if (!formInput?.description)
        throw new Error("Form description is required");
      if (!form?._id) throw new Error("Form ID is missing");
      if (!selectedFormGroup?._id) throw new Error("Form Group ID is missing");

      setIsWait(true);

      const resp = await axiosInstance.post(
        `/forms/${form?._id}/${selectedFormGroup?._id}`,
        formInput
      );
      if (resp?.status === 201) {
        // append the new form group to the form
        const newForm = { ...form };
        const newFormGroups = [...newForm?.formGroups];
        const groupIndex = newFormGroups.findIndex(
          (item) => item._id === selectedFormGroup._id
        );
        const newQuestionsGroup = [
          ...newFormGroups[groupIndex]?.questionsGroup,
        ];
        newQuestionsGroup.push(resp?.data);
        newFormGroups[groupIndex].questionsGroup = newQuestionsGroup;
        newForm.formGroups = newFormGroups;
        setForm(newForm);
        setFormInput({});
        setIsWait(false);

        // auto select the new form group
        setSelectedQuestionGroup(resp?.data);
        setCurrentSelectedItemType("question-group");
        setSelectedQuestion(null);
        setSelectedOption(null);

        Notify.success("Form Group Added Successfully");
        handleClose();
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Button
        sx={{
          m: 2,
          border: "1px dashed",
          borderRadius: "4px",
          px: 4
        }}
        variant="outlined"
        size="sm"
        color="warning"
        onClick={handleClickOpen}
      >
        Add New Parameter
      </Button>

      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Fill out the form below to create a new parameter
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              mt: 2,
            }}
            spacing={2}
          >
            <TextField
              autoFocus
              label="Parameter Title"
              fullWidth
              name="title"
              value={formInput?.title || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="Parameter Description/Subtitle"
              name="description"
              value={formInput?.description || ""}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isWait}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            loading={isWait}
          >
            Add Parameter
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
