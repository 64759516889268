import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import SelectEmployee from "../../common/SelectEmployee";
import { GlobalContext } from "src/contexts/GlobalContext";
import SelectUserRelation from "../../common/SelectUserRelation";
import { UploadTwoTone } from "@mui/icons-material";
import { DO_SPACES_FOLDERS } from "src/utils/constants";

export default function FormReceiver({
  form,
  receiver,
  setReceiver,
  organisation,
  setOrganisation,
  setHasAccessToSubmit,
  openSubmission,
  setOpenSubmission,
}) {
  const [searchParams] = useSearchParams();
  const { currentUser } = useContext(GlobalContext);
  const receiverId = searchParams.get("receiverId");
  const orgId = searchParams.get("orgId");
  const receiverTypeParam = searchParams.get("receiverType");
  const [loading, setLoading] = useState(false);
  const [inputFieldFor, setInputFieldFor] = useState("");

  const handleGetReceiverUser = async (userId) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/users/${userId}`);
      if (resp?.status === 200) {
        setReceiver(resp?.data);
        setLoading(false);
        return resp?.data;
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
      return null;
    }
  };
  const handleGetOrg = async (orgId) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/organisations/${orgId}`);
      if (resp?.status === 200) {
        setOrganisation(resp?.data?.data || {});
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  useEffect(() => {
    if (!form) return;
    (async () => {
      if (orgId) {
        await handleGetOrg(orgId);
      }
      if (receiverId) {
        await handleGetReceiverUser(receiverId);
      }

      // if form type = exam
      if (form?.type === "exam") {
        setReceiver(currentUser);
      } else if (form?.receivers?.includes("organisation")) {
        if (!orgId) {
          setOpenSubmission({
            open: true,
            receiver: "organisation",
            formInputs: {},
          });
        }
      } else if (
        form?.receivers?.includes("employee") ||
        receiverTypeParam === "employee"
      ) {
        if (!receiverId) {
          setInputFieldFor("select-employee");
        }
        if (currentUser?.orgId) {
          setOrganisation({
            _id: currentUser?.orgId,
            title: currentUser?.orgName,
          });
        } else if (!orgId) {
          setOpenSubmission({
            open: true,
            receiver: "organisation",
            formInputs: {},
          });
        }
      } else if (
        form?.receivers?.includes("user") &&
        form?.type !== "community"
      ) {
        if (
          !receiverId &&
          receiverTypeParam &&
          !["employee", "organisation"].includes(receiverTypeParam)
        ) {
          setInputFieldFor("select-user-relation");
        }
      }

      if (
        (form?.receivers?.includes("user") ||
          form?.receivers?.includes("employee")) &&
        !receiverId
      ) {
        setOpenSubmission({
          open: true,
          receiver: "user",
          formInputs: {},
        });
      }

      setHasAccessToSubmit(true);
    })();

    return () => {
      setReceiver(null);
      setOrganisation(null);
      setInputFieldFor("");
    };
  }, [form, receiverId, orgId]);

  const handleUploadProfileImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("fileCategory", DO_SPACES_FOLDERS.OTHERS);
      const imageResp = await axiosInstance.post(
        "/users/upload-media",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (imageResp.status === 200 && imageResp.data) {
        setOpenSubmission({
          ...openSubmission,
          formInputs: {
            ...openSubmission.formInputs,
            profileImage: imageResp.data?.[0],
          },
        });
      }
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const organisationInputForm = () => (
    <Card>
      <CardHeader
        title="Fill Organisation Details"
        subheader="Please fill the organisation details to submit this form"
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <TextField
              label="Organisation Name *"
              fullWidth
              value={openSubmission?.formInputs?.title || ""}
              onChange={(e) => {
                setOpenSubmission({
                  ...openSubmission,
                  formInputs: {
                    ...openSubmission.formInputs,
                    title: e.target.value,
                  },
                });
              }}
            />
            <TextField
              label="Website *"
              fullWidth
              value={openSubmission?.formInputs?.companyWebsite || ""}
              onChange={(e) => {
                setOpenSubmission({
                  ...openSubmission,
                  formInputs: {
                    ...openSubmission.formInputs,
                    companyWebsite: e.target.value,
                  },
                });
              }}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextField
              label="Email"
              fullWidth
              value={openSubmission?.formInputs?.companyEmail || ""}
              onChange={(e) => {
                setOpenSubmission({
                  ...openSubmission,
                  formInputs: {
                    ...openSubmission.formInputs,
                    companyEmail: e.target.value,
                  },
                });
              }}
            />
            <TextField
              label="Contact"
              fullWidth
              value={openSubmission?.formInputs?.companyPhone || ""}
              onChange={(e) => {
                setOpenSubmission({
                  ...openSubmission,
                  formInputs: {
                    ...openSubmission.formInputs,
                    companyPhone: e.target.value,
                  },
                });
              }}
            />
          </Stack>
          <TextField
            label="Company Address"
            fullWidth
            multiline
            rows={2}
            value={openSubmission?.formInputs?.companyAddress || ""}
            onChange={(e) => {
              setOpenSubmission({
                ...openSubmission,
                formInputs: {
                  ...openSubmission.formInputs,
                  companyAddress: e.target.value,
                },
              });
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
  const userInputForm = () => (
    <Card>
      <CardHeader
        title="Fill Receiver personal details"
        subheader="Please fill the receiver details to submit this form"
      />

      <CardContent>
        <Stack spacing={4} direction={"row"}>
          <Stack>
            <Stack spacing={2} direction="row">
              <AvatarWrapper>
                <Avatar
                  variant="rounded"
                  alt={""}
                  src={
                    openSubmission?.formInputs?.profileImage
                      ? process.env.REACT_APP_DO_SPACE_URL +
                        (openSubmission?.formInputs?.profileImage +
                          "?" +
                          Math.random())
                      : ""
                  }
                />
                <ButtonUploadWrapper>
                  <Input
                    accept="image/png, image/jpeg, image/jpg"
                    id="icon-button-file"
                    name="icon-button-file"
                    type="file"
                    onChange={(e) =>
                      handleUploadProfileImage(e.target.files[0])
                    }
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton component="span" color="primary">
                      <UploadTwoTone />
                    </IconButton>
                  </label>
                </ButtonUploadWrapper>
              </AvatarWrapper>
            </Stack>
          </Stack>
          <Stack spacing={2} width={"100%"}>
            <Stack spacing={2} direction="row">
              <TextField
                label="First Name *"
                fullWidth
                value={openSubmission?.formInputs?.firstName || ""}
                onChange={(e) => {
                  setOpenSubmission({
                    ...openSubmission,
                    formInputs: {
                      ...openSubmission.formInputs,
                      firstName: e.target.value,
                    },
                  });
                }}
              />
              <TextField
                label="Last Name"
                fullWidth
                value={openSubmission?.formInputs?.lastName || ""}
                onChange={(e) => {
                  setOpenSubmission({
                    ...openSubmission,
                    formInputs: {
                      ...openSubmission.formInputs,
                      lastName: e.target.value,
                    },
                  });
                }}
              />
            </Stack>
            <Stack spacing={2} direction="row">
              <TextField
                label="Email *"
                fullWidth
                value={openSubmission?.formInputs?.email || ""}
                onChange={(e) => {
                  setOpenSubmission({
                    ...openSubmission,
                    formInputs: {
                      ...openSubmission.formInputs,
                      email: e.target.value,
                    },
                  });
                }}
              />
              <TextField
                label="Contact *"
                fullWidth
                value={openSubmission?.formInputs?.phoneNumber || ""}
                onChange={(e) => {
                  setOpenSubmission({
                    ...openSubmission,
                    formInputs: {
                      ...openSubmission.formInputs,
                      phoneNumber: e.target.value,
                    },
                  });
                }}
              />
              <TextField
                label="PAN card number"
                fullWidth
                value={openSubmission?.formInputs?.pan || ""}
                onChange={(e) => {
                  setOpenSubmission({
                    ...openSubmission,
                    formInputs: {
                      ...openSubmission.formInputs,
                      pan: e.target.value,
                    },
                  });
                }}
              />
            </Stack>

            <TextField
              label="Complete Address"
              fullWidth
              multiline
              rows={2}
              value={openSubmission?.formInputs?.address || ""}
              onChange={(e) => {
                setOpenSubmission({
                  ...openSubmission,
                  formInputs: {
                    ...openSubmission.formInputs,
                    address: e.target.value,
                  },
                });
              }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

  if (openSubmission?.open && openSubmission?.receiver === "organisation") {
    return organisationInputForm();
  } else if (openSubmission?.open && openSubmission?.receiver === "user") {
    return userInputForm();
  } else if (openSubmission?.open && openSubmission?.receiver === "employee") {
    return (
      <Stack spacing={2}>
        {userInputForm()}
        <Divider />
        {organisationInputForm()}
      </Stack>
    );
  }

  return (
    (receiver || organisation) && (
      <Card>
        <CardContent>
          {loading && <CircularProgress size={"1.5rem"} />}

          <Stack direction="row" alignItems="center" spacing={2}>
            {receiver && (
              <Typography>
                Receiver:{" "}
                <b>
                  {(receiver?.firstName || "N/A") +
                    " " +
                    (receiver?.lastName || "")}
                </b>
              </Typography>
            )}
            {organisation && (
              <Typography>
                Organisation: <b>{organisation?.title || ""}</b>
              </Typography>
            )}
          </Stack>
        </CardContent>
        {/* select-employee */}
        {inputFieldFor === "select-employee" && (
          <Dialog
            open={inputFieldFor === "select-employee"}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Select a User</DialogTitle>
            <SelectEmployee
              orgId={orgId || currentUser?.orgId}
              handleSelectUser={(user) => {
                setReceiver(user);
                setInputFieldFor("");
              }}
            />
          </Dialog>
        )}
        {/* select-user-relation */}
        {inputFieldFor === "select-user-relation" && (
          <Dialog
            open={inputFieldFor === "select-user-relation"}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Select a User</DialogTitle>
            <SelectUserRelation
              orgId={orgId || currentUser?.orgId}
              filterOptions={{
                relation: receiverTypeParam,
              }}
              handleSelectUser={(user) => {
                setReceiver(user);
                setInputFieldFor("");
              }}
            />
          </Dialog>
        )}
      </Card>
    )
  );
}

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);
