import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";

export default function DisputeSection({ formInput, setFormInput }) {
  const { currentUser } = useContext(GlobalContext);
  const [addDispute, setAddDispute] = useState(false);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDisputeForm, setSelectedDisputeForm] = useState(null);

  useEffect(() => {
    if (["rating"]?.includes(formInput?.type)) {
      if (formInput?.disputeForm) {
        setAddDispute(true);
        setSelectedDisputeForm(formInput?.disputeForm);
      } else {
        setAddDispute(false);
      }
    } else {
      const _formInput = { ...formInput };
      delete _formInput?.disputeForm;
      setFormInput(_formInput);
      setAddDispute(false);
    }
  }, [formInput?.type]);

  useEffect(() => {
    if (currentUser?.orgId || forms?.length > 0) return;

    // fetch forms from api
    (async () => {
      try {
        setLoading(true);
        const resp = await axiosInstance.get("/forms/all", {
          params: {
            type: "other",
          },
        });
        if (resp?.status === 200) {
          setForms(resp?.data?.data || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Notify.failure("Failed to fetch forms");
      }
    })();
  }, [addDispute]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Box width={1}>
          <FormControlLabel
            control={<Checkbox />}
            checked={addDispute}
            label="Allow Receiver to Raise a Dispute?"
            onChange={(e) => {
              const _formInput = { ...formInput };
              if (!e.target.checked) {
                delete _formInput?.disputeForm;
              } else {
                _formInput.disputeForm = selectedDisputeForm?._id || null;
              }
              setFormInput(_formInput);
              setAddDispute(e.target.checked);
            }}
          />
        </Box>
        {addDispute && (
          <Autocomplete
            fullWidth
            options={forms || []}
            getOptionLabel={(option) => option?.title || ""}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Dispute Form *"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            onChange={(e, value) => {
              setFormInput({
                ...formInput,
                disputeForm: value?._id,
              });
              setSelectedDisputeForm(value?._id);
            }}
            value={
              forms?.find((form) => form?._id === selectedDisputeForm) || null
            }
          />
        )}
      </Stack>
    </>
  );
}
