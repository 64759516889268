import { Box, Card, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Notify } from "notiflix";
import {
  CopyAllTwoTone,
  Email,
  Facebook,
  LinkedIn,
  Twitter,
} from "@mui/icons-material";

function ShareEvent({ title, link }) {
  const handleCopy = async () => {
    await navigator.clipboard.writeText(link);
    Notify.success("Link copied to clipboard");
  };
  return (
    <Card sx={{ p: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 0%" }}>
        <Typography
          variant="h5"
          sx={{
            margin: "0px 0px 24px",
            color: "rgb(88, 96, 105)",
            textTransform: "uppercase",
            fontSize: "14px",
            lineHeight: "18px",
          }}
        >
          share
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box>
          <Box
            sx={{
              flexWrap: "wrap",
              marginBottom: "-8px",
              display: "flex",
              flexDirection: "row",
              WebkitBoxPack: "start",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <ShareLinkComponent
              link={`http://www.facebook.com/share.php?u=${link}&amp;title=${title}`}
              title={"Facebook"}
              Icon={Facebook}
              fill={"#fff"}
              borderColor={"rgb(225, 228, 232)"}
            />
            <ShareLinkComponent
              link={`http://twitter.com/intent/tweet?text=${title}&amp;url=${link}`}
              title={"Twitter"}
              Icon={Twitter}
              fill={"#fff"}
              borderColor={"rgb(225, 228, 232)"}
            />
            <ShareLinkComponent
              link={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${link}&amp;title=${title}&amp;summary=Pack Pantry Bags&amp;source=LinkedIn`}
              title={"LinkedIn"}
              Icon={LinkedIn}
              fill={"#fff"}
              borderColor={"rgb(225, 228, 232)"}
            />
            <ShareLinkComponent
              link={`mailto:?subject=${title}&amp;body=Event Link: ${link}`}
              title={"Email"}
              Icon={Email}
              fill={"#fff"}
              borderColor={"rgb(225, 228, 232)"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              WebkitBoxPack: "start",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              cursor: "copy",
            }}
          >
            <Box
              sx={{
                position: "relative",
                color: "rgb(246, 146, 30)",
                padding: "9px 36px 9px 16px",
                borderRadius: "6px",
                fontSize: "14px",
                lineHeight: "18px",
                border: "1px solid rgba(246, 146, 30, 0.12)",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "100%",
              }}
              onClick={handleCopy}
            >
              {link}
              <CopyAllTwoTone
                style={{
                  display: "block",
                  position: "absolute",
                  right: "12px",
                  top: "10px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default React.memo(ShareEvent);

const ShareLinkComponent = ({
  link,
  Icon,
  title,
  fill,
  borderColor,
  iconFill,
}) => {
  return (
    <React.Fragment>
      <Link
        href={link}
        target="_blank"
        data-testid="btn-social-email"
        sx={{
          display: "flex",
          flexDirection: "column",
          WebkitBoxPack: "center",
          justifyContent: "center",
          WebkitBoxAlign: "center",
          alignItems: "center",
          margin: "0px 24px 24px 0px",
        }}
        style={{
          textDecoration: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            WebkitBoxPack: "center",
            justifyContent: "center",
            WebkitBoxAlign: "center",
            alignItems: "center",
            width: "48px",
            height: "48px",
            minHeight: "48px",
            minWidth: "48px",
            border: `1px solid ${borderColor}`,
            background: fill,
            borderRadius: "50%",
            marginBottom: "8px",
          }}
        >
          <Icon fill={iconFill} />
        </Box>
        <Typography
          component="h6"
          sx={{
            color: "rgb(38, 38, 38)",
            fontSize: "12px",
            lineHeight: "16px",
          }}
        >
          {title}
        </Typography>
      </Link>
    </React.Fragment>
  );
};
