import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import EmptyHere from "src/components/EmptyHere";
import { useParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import SuspenseLoader from "src/components/SuspenseLoader";
import PricingPlanCard from "./components/PricingPlanCard";
import OrderStats from "./components/OrderStats";
import { Notify } from "notiflix";
import Ratings from "../ratings";

export default function OrderDetails() {
  const { orderId } = useParams();
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!orderId) {
        Notify.failure("Order ID is missing");
        setLoading(false);
        return;
      }

      const resp = await axiosInstance.get(`/orders/${orderId}`);
      if (resp?.status === 200) {
        setOrder(resp?.data);
      }
      setLoading(false);
    })();
  }, [orderId]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Stack my={2} spacing={2}>
      {!order ? (
        <EmptyHere
          title={`No Order found`}
          subtitle={"Purchase a Plan to place and order"}
          action={{
            text: "Purchase a Plan",
            link: "/pricing",
          }}
        />
      ) : (
        <PricingPlanCard item={order} />
      )}
      <Stack ml={-2}>
        <OrderStats stats={order?.stats} />
      </Stack>
      {order?._id && <Ratings orderId={order?._id} />}
    </Stack>
  );
}
