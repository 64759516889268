import React from "react";
import Logo from "src/components/Logo";
import { Box, Button, Stack, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const TopSection = styled(Box)(
  ({ theme }) => `
    align-items: center;
`
);

function Sidebar() {
  return (
    <TopSection>
      <Stack
        direction={"column"}
        justifyContent={"space-around"}
        height={"100%"}
      >
        <Logo />
        <Box>
          <Button
            sx={{
              my: 2,
              border: "1px dashed",
              borderRadius: "10px",
            }}
            fullWidth
            variant="outlined"
            size="sm"
            color="warning"
            LinkComponent={Link}
            to="/dashboard/forms"
            startIcon={
              <ArrowBack sx={{ color: "warning.main" }} fontSize="small" />
            }
          >
            Back to Home
          </Button>
        </Box>
      </Stack>
    </TopSection>
  );
}

export default Sidebar;
