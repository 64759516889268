import {
  Button,
  TextField,
  Card,
  Box,
  Stack,
  styled,
  Avatar,
  IconButton,
  Autocomplete,
  Divider,
  InputAdornment,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ArrowBackIos,
  GitHub,
  HelpOutline,
  LinkedIn,
  Public,
  SaveTwoTone,
  Twitter,
  UploadTwoTone,
} from "@mui/icons-material";
import { Confirm, Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { DO_SPACES_FOLDERS, FEATURES_ACCESS } from "src/utils/constants";
import { GlobalContext } from "src/contexts/GlobalContext";
import CountriesWithStates from "src/utils/countries/states.json";
import CountriesWithCities from "src/utils/countries/cities.json";
import SuspenseLoader from "src/components/SuspenseLoader";
import InputFieldsSwitch from "../common/InputFieldsSwitch";

export default function CreateOrg() {
  const navigator = useNavigate();
  let { organisationId } = useParams();
  const { currentUser, getTagsByType } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [formInput, setFormInput] = useState({
    address: {},
  });
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [customFields, setCustomFields] = useState([]);

  const handleInputChange = (e) => {
    setFormInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    (async () => {
      const _industries = await getTagsByType("industries");
      setIndustries(_industries);
    })();
  }, []);

  const [zoneTags, setZoneTags] = useState({
    type: "",
    tags: [],
  });
  useEffect(() => {
    if (!formInput?.address?.city) return;
    (async () => {
      const resp = await axiosInstance.get("/tags/type", {
        params: {
          type: formInput?.address?.city,
        },
      });
      if (resp?.status === 200) {
        setZoneTags(
          resp?.data || {
            type: formInput?.address?.city,
            tags: [],
          }
        );
      }
    })();
  }, [formInput?.address?.city]);

  useEffect(() => {
    if (organisationId) {
      (async () => {
        try {
          const resp = await axiosInstance.get(
            `/organisations/${organisationId}`
          );
          if (resp?.status === 200) {
            setFormInput(resp?.data?.data);
            setCustomFields(resp?.data?.customFields || []);
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
          Notify.failure(
            error.response?.data?.message ||
              error.response?.statusText ||
              "an error occured"
          );
        }
      })();
    }
    setLoading(false);
  }, [organisationId]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formInput?.title)
        return Notify.warning("Organisation name is required");
      if (!formInput?.companyEmail)
        return Notify.warning("Organisation email is required");
      if (!formInput?.companyPhone)
        return Notify.warning("Organisation phone is required");
      if (!organisationId) {
        if (!formInput?.industry)
          return Notify.warning("Organisation industry is required");
      }
      setIsSubmitting(true);

      if (profileImage) {
        const formData = new FormData();
        formData.append("files", profileImage);
        formData.append("fileCategory", DO_SPACES_FOLDERS.ORGANISATION);
        formData.append("orgId", formInput?._id);
        const imageResp = await axiosInstance.post(
          "/users/upload-media",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if (imageResp.status === 200 && imageResp.data) {
          formInput.avatar = imageResp.data?.[0];
          setFormInput((prev) => ({ ...prev, avatar: imageResp.data?.[0] }));
        }
      }
      // map metas (file, text etc)
      const _meta = {};
      if (formInput?.meta) {
        const mappedFormInputMeta = Object.entries(formInput?.meta)?.map(
          async ([key, value]) => {
            let _value = value;
            // if value is file
            if (value instanceof File) {
              try {
                const _fileName = key + "." + value?.name?.split(".")?.pop();
                const modifiedFile = new File([value], _fileName, {
                  type: value.type,
                });
                const formData = new FormData();
                formData.append("files", modifiedFile);
                const resp = await axiosInstance.post(
                  "/users/upload-media",
                  formData,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                );
                if (resp?.status === 200 && resp?.data) {
                  _value = resp?.data?.[0];
                }
              } catch (error) {
                console.log(error);
                throw new Error(error);
              }
            }

            let isCustomeField = customFields?.find(
              (item) => item?.name === key
            );
            if (isCustomeField) {
              // if it is custom field
              if (typeof _value === "object") {
                // old value
                _meta[key] = _value;
              } else {
                // new value
                _meta[key] = {
                  ...isCustomeField,
                  value: _value,
                };
              }
            } else {
              // if it is simple meta key:value
              _meta[key] = _value;
            }
          }
        );
        // Wait for all promises to resolve
        await Promise.all(mappedFormInputMeta);
      }
      /// attach meta to formInput
      setFormInput((prev) => ({ ...prev, meta: _meta }));

      if (organisationId) {
        const resp = await axiosInstance.put(
          `/organisations/${organisationId}`,
          {
            ...formInput,
            meta: _meta,
          }
        );
        if (resp?.status === 200) {
          Notify.success(resp?.data?.message || "success");
        }
      } else {
        const resp = await axiosInstance.post(`/organisations`, formInput);
        if (resp?.status === 201) {
          Notify.success("Organisation created successfully");
          setFormInput({});
          navigator(`/dashboard/organisations/${resp?.data?._id}`);
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleActivateDeactivateAccount = async () => {
    try {
      const resp = await axiosInstance.put(`/organisations/${organisationId}`, {
        isLocked: !formInput?.isLocked,
      });
      if (resp?.status === 200) {
        Notify.success(resp?.data?.message || "success");
        setFormInput((prev) => ({
          ...prev,
          isLocked: !formInput?.isLocked,
        }));
      }
    } catch (error) {
      console.error(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Card sx={{ my: 2 }}>
      <Box sx={{ p: 2 }}>
        <Stack spacing={2}>
          {formInput?._id && (
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <AvatarWrapper>
                <Avatar
                  variant="rounded"
                  alt={formInput?.firstName}
                  src={
                    profileImage
                      ? URL.createObjectURL(profileImage)
                      : formInput?.avatar
                      ? process.env.REACT_APP_DO_SPACE_URL +
                        (formInput?.avatar + "?" + Math.random())
                      : ""
                  }
                />
                <ButtonUploadWrapper>
                  <Input
                    accept="image/png, image/jpeg, image/jpg"
                    id="icon-button-file"
                    name="icon-button-file"
                    type="file"
                    onChange={(e) => {
                      setProfileImage(e.target.files[0]);
                    }}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton component="span" color="primary">
                      <UploadTwoTone />
                    </IconButton>
                  </label>
                </ButtonUploadWrapper>
              </AvatarWrapper>
            </Box>
          )}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label={t("Organisation Name*")}
              name="title"
              onChange={handleInputChange}
              value={formInput?.title || ""}
            />
            <TextField
              fullWidth
              label={t("Organisation Email address*")}
              name="companyEmail"
              onChange={handleInputChange}
              type="email"
              value={formInput?.companyEmail || ""}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label={t("Organisation Contact Number*")}
              name="companyPhone"
              onChange={handleInputChange}
              value={formInput?.companyPhone || ""}
            />

            <TextField
              fullWidth
              label={t("Organisation Website")}
              name="companyWebsite"
              onChange={handleInputChange}
              value={formInput?.companyWebsite || ""}
            />
          </Stack>

          <TextField
            fullWidth
            placeholder="Write something about the organisation"
            label={t("About Organisation")}
            name="description"
            onChange={handleInputChange}
            value={formInput?.description || ""}
            rows={4}
            multiline
          />
          <Autocomplete
            fullWidth
            multiple
            options={industries || []}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter Industry Type *"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            onChange={(e, value) =>
              setFormInput({ ...formInput, industry: value })
            }
            value={formInput?.industry || []}
          />
        </Stack>

        <Divider sx={{ mb: 5, mt: 8 }}>Contact Info</Divider>
        <Stack spacing={2}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Autocomplete
              fullWidth
              autoHighlight
              options={CountriesWithStates?.map((item) => item?.name)}
              value={formInput?.address?.country || "India"}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    country: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              autoHighlight
              options={
                CountriesWithStates?.find(
                  (item) =>
                    item?.name === (formInput?.address?.country || "India")
                )?.states?.map((item) => item?.name) || []
              }
              value={formInput?.address?.state || ""}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    state: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select State"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              autoHighlight
              options={
                CountriesWithCities?.find(
                  (item) =>
                    item?.country === (formInput?.address?.country || "India")
                )?.cities || []
              }
              value={formInput?.address?.city || ""}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    city: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select City"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              fullWidth
              autoHighlight
              options={zoneTags?.tags || []}
              value={formInput?.address?.zone || ""}
              onChange={(e, value) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    zone: value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Zones"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <TextField
              fullWidth
              label="Pincode"
              name="pincode"
              placeholder="Enter Pincode/Zipcode"
              value={formInput?.address?.pincode || ""}
              onChange={(e) => {
                setFormInput({
                  ...formInput,
                  address: {
                    ...formInput.address,
                    pincode: e.target.value,
                  },
                });
              }}
            />
          </Stack>
          <TextField
            fullWidth
            label={t("Organisation's Complete Address")}
            name="companyAddress"
            onChange={handleInputChange}
            value={formInput?.companyAddress || ""}
            multiline
            rows={3}
          />
        </Stack>
        {currentUser?.accountType === "admin" && (
          <>
            <Divider sx={{ mb: 5, mt: 12 }}>
              Features Access
              <Tooltip
                title={
                  "These Features will be available (visible) to this organisation (under Management side menu)"
                }
              >
                <HelpOutline sx={{ ml: 1, fontSize: 16 }} />
              </Tooltip>
            </Divider>
            <Grid container spacing={2}>
              {Object.values(FEATURES_ACCESS)?.map((item, index) => (
                <Grid item xs={3} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formInput?.featuresAccess?.[item?.value] || ""}
                        onChange={(e) => {
                          setFormInput({
                            ...formInput,
                            featuresAccess: {
                              ...(formInput?.featuresAccess || {}),
                              [item?.value]: e.target.checked,
                            },
                          });
                        }}
                      />
                    }
                    label={
                      <>
                        <Typography>
                          {item?.name}
                          <Tooltip title={item?.description}>
                            <HelpOutline sx={{ ml: 1, fontSize: 14 }} />
                          </Tooltip>
                        </Typography>
                      </>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {customFields?.length > 0 && (
          <>
            <Divider sx={{ mb: 5, mt: 12 }}>Important Info</Divider>
            <Grid container spacing={2}>
              {customFields?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={item?.type === "file" ? 12 : 6}
                  key={index}
                >
                  <InputFieldsSwitch
                    question={item}
                    formInput={formInput?.meta || {}}
                    handleInputChange={({ key, value }) => {
                      setFormInput({
                        ...formInput,
                        meta: {
                          ...formInput.meta,
                          [key]: value,
                        },
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <Divider sx={{ mb: 5, mt: 12 }}>Social Links</Divider>
        <Stack spacing={2}>
          <TextField
            placeholder="Personal Website. e.g. https://www.site.domain"
            fullWidth
            name="website"
            value={formInput?.meta?.website || ""}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                meta: {
                  ...formInput.meta,
                  website: e.target.value,
                },
              });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Public />
                </InputAdornment>
              ),
            }}
          />{" "}
          <TextField
            placeholder="username"
            fullWidth
            name="linkedin"
            value={formInput?.meta?.linkedin || ""}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                meta: {
                  ...formInput.meta,
                  linkedin: e.target.value,
                },
              });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Button startIcon={<LinkedIn />}>http://linkedin.com/</Button>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            placeholder="username"
            fullWidth
            name="github"
            value={formInput?.meta?.github || ""}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                meta: {
                  ...formInput.meta,
                  github: e.target.value,
                },
              });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Button startIcon={<GitHub />}>https://github.com/</Button>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            placeholder="username"
            fullWidth
            name="twitter"
            value={formInput?.meta?.twitter || ""}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                meta: {
                  ...formInput.meta,
                  twitter: e.target.value,
                },
              });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Button startIcon={<Twitter />}>https://twitter.com/</Button>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            {formInput?._id && (
              <Button
                startIcon={<ArrowBackIos />}
                variant="text"
                component={Link}
                to={`/dashboard/organisations/${organisationId}`}
              >
                {t("Back to Profile")}
              </Button>
            )}
            {currentUser?.accountType === "admin" && (
              <LoadingButton
                variant={formInput?.isLocked ? "contained" : "outlined"}
                color={formInput?.isLocked ? "success" : "error"}
                onClick={() => {
                  Confirm.show(
                    "Confirmation",
                    "Are you sure you want to continue?",
                    "Confirm",
                    "Cancel",
                    () => handleActivateDeactivateAccount(),
                    () => {},
                    {
                      width: "500px",
                      messageMaxLength: 1000,
                    }
                  );
                }}
              >
                {formInput?.isLocked ? "Activate " : "Deactivate "}
                Organisation
              </LoadingButton>
            )}
          </Stack>
          <LoadingButton
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={<SaveTwoTone />}
            disabled={isSubmitting || loading || formInput?.isLocked}
            type="submit"
            size="large"
            variant="contained"
            onClick={handleFormSubmit}
          >
            {t(formInput?._id ? "Update Organisation" : "Create Orgnisation")}
          </LoadingButton>
        </Box>
      </Box>
    </Card>
  );
}

const Input = styled("input")({
  display: "none",
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);
