import {
  Forward,
  OpenInNew,
  SecurityTwoTone,
  YouTube,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext } from "react";
import HonestCVImage from "../../assets/honestcv.jpeg";
import CareerGrowthImage from "../../assets/careergrowth.jpeg";
import CppImage from "../../assets/cpp.jpeg";
import { Link } from "react-router-dom";
import { GlobalContext } from "src/contexts/GlobalContext";

const ArrowForward = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        left: "70%",
        transform: "translateX(-50%)",
      }}
    >
      <Box
        sx={{
          position: "relative",
          color: (theme) => theme.palette.primary.main,
          left: "5px",
        }}
      >
        <Forward
          sx={{
            width: "50px",
            height: "50px",
          }}
        />
      </Box>
    </Box>
  );
};

const AdFirstStep = ({ currentUser }) => {
  return (
    <Grid item xs={3.9}>
      <CardWrapper>
        {/* <FormControl sx={{ my: 2 }}>
          <RadioGroup>
            <FormControlLabel
              value="female"
              control={<Radio size="small" color="success" />}
              label={
                <Typography fontSize={"0.9rem"}>
                  Complete this step first
                </Typography>
              }
              checked={true}
            />
            <FormControlLabel
              value="male"
              control={<Radio size="small" />}
              label={
                <Typography fontSize={"0.9rem"}>Then the next step.</Typography>
              }
              checked={true}
            />
            <FormControlLabel
              value="other"
              control={<Radio size="small" />}
              label={
                <Typography fontSize={"0.9rem"}>
                  And complete the last step
                </Typography>
              }
              disabled
            />
          </RadioGroup>
        </FormControl> */}
        <Stack spacing={1} justifyContent={"space-evenly"} height={"100%"}>
          <Typography variant="h3" fontSize={"1.2rem"}>
            Empower Your Career with Honest CV
          </Typography>
          <Typography variant="subtitle1" fontSize={"0.9rem"}>
            Elevate your job prospects with our Honest CV Initiative. Watch our
            free course series on YouTube, designed to guide you in creating a
            CV that reflects your true skills and experiences.
          </Typography>
          <Box
            mt={1}
            sx={{
              background: "#170230",
              borderRadius: "22px",
            }}
          >
            <a
              href="https://www.youtube.com/watch?v=UXfazYR5wPk&list=PLNuLrShV_r-s8mORl0gaESTUkiBaA5ywD"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                width={"100%"}
                src={HonestCVImage}
                style={{ borderRadius: "20px 20px 0 0", cursor: "pointer" }}
                component={"a"}
              />
              <Button
                color="error"
                startIcon={<YouTube />}
                fullWidth
                sx={{
                  backgroundColor: "#FFE7EC",
                  borderRadius: "0 0 20px 20px",
                  "&:hover": {
                    backgroundColor: "#FFE0EC",
                  },
                }}
              >
                Watch it on YouTube
              </Button>
            </a>
          </Box>
          <Typography variant="subtitle1" fontSize={"0.8rem"} mt={1}>
            Complete the course and pass our free exam to earn your Honest CV
            Certification, showcasing your commitment to integrity to potential
            employers.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 1, background: (theme) => theme.palette.primary.main }}
            endIcon={<OpenInNew />}
            fullWidth
            component={"a"}
            href={`/dashboard/exams?exam=65c4567a8892731a72d0b887`}
            rel="noopener noreferrer"
            target="_blank"
            disabled={!currentUser || currentUser?.accountType !== "user"}
          >
            Go to Exam
          </Button>
          <Typography variant="subtitle1" fontSize={"0.7rem"}>
            Watch the courses and take your certification exam today.
          </Typography>
        </Stack>
      </CardWrapper>
    </Grid>
  );
};

const AdSecondStep = ({ currentUser }) => {
  return (
    <Grid item xs={3.9}>
      <CardWrapper>
        <Stack justifyContent={"space-evenly"} height={"100%"} spacing={1}>
          <Typography variant="h3" fontSize={"1.3rem"}>
            Certified Professional Ethics at Workplace (CPEW)
          </Typography>
          <Typography
            variant="body2"
            fontSize={"0.8rem"}
            color={"text.secondary"}
            mt={1}
          >
            Professional Ethics Certification shows the employer that you are
            less likely to engage in misconducts, frauds and policy violations
            resulting in reputation or financial loss to the company. Get faster
            job interviews with CPEW.
          </Typography>
          <Box mt={1}>
            <img
              alt=""
              width={"100%"}
              src={CareerGrowthImage}
              style={{ borderRadius: "20px" }}
            />
          </Box>
          <Typography variant="subtitle1" fontSize={"0.9rem"} mt={1}>
            Get guaranteed Digital Internship for 30 days in Cyber security, AI
            and emerging technologies with CPEW Certification.
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 1, background: (theme) => theme.palette.primary.main }}
              endIcon={<OpenInNew />}
              fullWidth
              component={"a"}
              href={`/dashboard/exams?exam=652aa8d17d825c8a870ef354`}
              rel="noopener noreferrer"
              target="_blank"
              disabled={!currentUser || currentUser?.accountType !== "user"}
            >
              CPEW Certification
            </Button>
            <Typography variant="subtitle1" fontSize={"0.7rem"}>
              Watch the courses and take your certification exam today.
            </Typography>
          </Box>
        </Stack>
      </CardWrapper>
    </Grid>
  );
};

const AdThirdStep = () => {
  return (
    <Grid item xs={3.9}>
      <CardWrapper>
        {/* <Box sx={{ width: "100%" }} my={3}>
          <List
            sx={{
              m: 0,
              mt: 1,
              p: 0,
            }}
          >
            {[
              {
                title: "This is the first step",
                subtitle: "Step number 1",
              },
              {
                title: "This is the second step",
                subtitle: "Step number 2",
              },
              {
                title: "This is the third step",
                subtitle: "Step number 3",
              },
            ].map((step, index) => (
              <ListItem
                key={index}
                sx={{
                  bgcolor: "#B9B9B9",
                  mt: 0.7,
                  borderRadius: "10px",
                  px: 1,
                  py: 0.2,
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      fontSize={"0.9rem"}
                    >
                      {step.title}
                    </Typography>
                  }
                  secondary={
                    <Typography fontSize={"0.9rem"} variant="subtitle1">
                      {step.subtitle}
                    </Typography>
                  }
                />
                <ArrowForwardIos fontSize="1rem" />
              </ListItem>
            ))}
          </List>
        </Box> */}

        <Stack justifyContent={"space-evenly"} height={"100%"} spacing={1}>
          <Typography variant="h3">Career Protection Plan (CPP)</Typography>
          <Typography
            variant="body2"
            fontSize={"0.9rem"}
            color={"text.secondary"}
          >
            The Career Protection Plan (CPP) from Clean Exit is linked to your
            current job role in a company and shields you from various unethical
            practices and wrong rating. Do not join a company for an internship
            or a job without the Career Protection Plan!
          </Typography>
          <Box mt={1}>
            <img
              alt=""
              width={"100%"}
              src={CppImage}
              style={{ borderRadius: "20px" }}
            />
          </Box>
          <Typography variant="subtitle1" fontSize={"0.9rem"} mt={1}>
            Rate your managers, get protection from negative ratings and
            non-issuance of experience letter by companies.
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 1,
                background: (theme) => theme.palette.primary.main,
              }}
              startIcon={<SecurityTwoTone />}
              fullWidth
              component={Link}
              to={`/dashboard/career-protection/open`}
            >
              Get Career Protection
            </Button>

            <Typography variant="subtitle1" fontSize={"0.7rem"} mt={1}>
              Buy the Career Protection Plan for your internship / job today.
            </Typography>
          </Box>
        </Stack>
      </CardWrapper>
    </Grid>
  );
};

// 1. Upload Joining letter
// 2. Employee id

export default function AdBanner1({ children }) {
  const { currentUser } = useContext(GlobalContext);
  return (
    <Box>
      {children}
      <Box sx={{ position: "relative", width: "100%", pb: 2, mt: 1 }}>
        <Box>
          {/* <img
            alt="404"
            width={"100%"}
            height={"310px"}
            src="/static/images/placeholders/illustrations/bannerbg1.svg"
          /> */}
          <Box
            sx={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Grid container>
              <AdFirstStep currentUser={currentUser} />
              <Grid item xs={0.15}>
                <ArrowForward />
              </Grid>
              <AdSecondStep currentUser={currentUser} />
              <Grid item xs={0.15}>
                <ArrowForward />
              </Grid>
              <AdThirdStep currentUser={currentUser} />
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
  // background: #D9D9D9;
  padding: ${theme.spacing(2)};
  height: 100%;
`
);
