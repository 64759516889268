import { AddTwoTone, UploadTwoTone } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useRef } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import { fDate } from "src/utils/date-fns";
import { getImageUrl } from "src/utils/helpers";

export default function DocumentsUpload({
  signatureImage,
  setSignatureImage,
  aadhaarImage,
  setAadhaarImage,
  dob,
  setDob,
}) {
  const inputFile = useRef(null);
  const { profile } = useContext(ProfileContext);

  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={4}>
        {profile?.profileImage && (
          <Grid item xs={12} sm={3}>
            <Avatar
              src={getImageUrl(profile?.profileImage)}
              alt={profile?.firstName + " " + (profile?.lastName || "")}
              sx={{
                mx: "auto",
                my: 2,
                width: 120,
                height: 120,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={9}>
          <Stack direction={"row"} alignItems={"center"} mt={2}>
            <Typography variant="caption" sx={{ mr: 2, whiteSpace: "nowrap" }}>
              Full Name
            </Typography>
            <Divider style={{ width: "100%" }} />
          </Stack>
          <Typography variant="h3" noWrap>
            {profile?.firstName || "-"} {profile?.lastName || ""}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} mt={2}>
            <Typography variant="caption" sx={{ mr: 2, whiteSpace: "nowrap" }}>
              EthixFirst ID
            </Typography>
            <Divider style={{ width: "85%" }} />
          </Stack>
          <Typography variant="h4" noWrap>
            {profile?.cleanExitUserId || "-"}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={5}>
          <Button
            sx={{
              color: "primary.main",
              border: "1px dashed",
              borderColor: "primary.main",
              "&:hover": {
                color: "primary.dark",
                borderColor: "primary.dark",
              },
              p: 2,
              whiteSpace: "nowrap",
            }}
            fullWidth
            onClick={() => inputFile.current.click()}
            startIcon={<AddTwoTone />}
          >
            Upload Signature
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onloadend = () => {
                  setSignatureImage(reader.result);
                };
                reader.readAsDataURL(file);
              }}
              multiple={false}
              style={{ display: "none" }}
              ref={inputFile}
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              backgroundColor: "background.default",
              borderRadius: "10px",
              p: 1,
            }}
          >
            <img
              src={signatureImage}
              alt="Signature preview"
              style={{
                width: "200px",
                maxHeight: "auto",
                marginRight: "20px",
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={5}>
          <Button
            component="label"
            sx={{
              whiteSpace: "nowrap",
              color: "primary.main",
              border: "1px dashed",
              borderColor: "primary.main",
              "&:hover": {
                color: "primary.dark",
                borderColor: "primary.dark",
              },
              p: 2,
              whiteSpace: "nowrap",
            }}
            fullWidth
            startIcon={<UploadTwoTone />}
          >
            Upload Govt ID (format: png, jpg, jpeg)
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onloadend = () => {
                  setAadhaarImage(reader.result);
                };
                reader.readAsDataURL(file);
              }}
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              backgroundColor: "background.default",
              borderRadius: "10px",
              p: 1,
            }}
          >
            <img
              src={aadhaarImage}
              alt="Aadhaar preview"
              style={{
                width: "200px",
                maxHeight: "auto",
                marginRight: "20px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              sx={{ minWidth: "200px" }}
              label="Select DOB *"
              value={dob}
              onChange={(newValue) => {
                setDob(newValue);
              }}
              format="dd/MM/yyyy"
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={7}>
          {dob ? (
            <Typography variant="h4">{fDate(dob, "dd/MM/yyyy")}</Typography>
          ) : (
            <Typography variant="caption" sx={{ mr: 2, whiteSpace: "nowrap" }}>
              Date of Birth (dd/mm/yyyy)
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
