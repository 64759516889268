import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Paper,
  Box,
  TextField,
  Stack,
  Autocomplete,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axiosInstance from "src/utils/axiosInstance";
import { ORG_ASSET_TYPES } from "src/utils/constants";
import { GlobalContext } from "src/contexts/GlobalContext";
import { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";

export default function AddEditAssets({
  setAddEdit,
  handleGetAssets,
  editFormInput,
  setEditFormInput,
}) {
  const [searchParam] = useSearchParams();
  const organisationParam = searchParam.get("organisation");
  const { currentUser } = useContext(GlobalContext);
  const [formInput, setFormInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState(false);
  const [userInput, setUserInput] = useState({});

  const handleFormChange = (e) => {
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (editFormInput) {
      const { user, ..._editFormInput } = editFormInput;
      setFormInput(_editFormInput);
      setUserInput(user);
      handleSearchEmployee();
    }
  }, [editFormInput]);

  const handleSearchEmployee = async (searchString) => {
    try {
      setLoading(true);
      const reqParams = {
        params: {},
      };

      if (searchString) {
        reqParams.params = { ...reqParams.params, search: searchString };
      }

      if (currentUser?.orgId) {
        reqParams.params = {
          ...reqParams.params,
          organisation: currentUser?.orgId,
          isActive: true,
        };
      }

      const resp = await axiosInstance.get(`/organisations/users`, reqParams);
      if (resp?.status === 200) {
        setUsers(resp?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleAddAsset = async () => {
    try {
      if (!formInput?.title || !formInput?.description) {
        Notify.failure("Please fill all required fields");
        return;
      }

      if (!newUser && !formInput?.assignedTo) {
        Notify.failure("Please select assigned To user");
        return;
      }

      if (
        newUser &&
        (!userInput?.firstName || !userInput?.email || !userInput?.phoneNumber)
      ) {
        Notify.failure("Please fill all required fields for new user");
        return;
      }

      setLoading(true);
      let resp;

      if (formInput?._id) {
        resp = await axiosInstance.put(
          `/organisations/assets/${formInput?._id}`,
          {
            ...formInput,
          }
        );
      } else {
        resp = await axiosInstance.post(
          `/organisations/${currentUser?.orgId || organisationParam}/assets`,
          {
            ...formInput,
            user: newUser ? userInput : {},
            organisation: currentUser?.orgId || organisationParam,
          }
        );
      }
      if (resp?.status === 201) {
        Notify.success("Asset added successfully");
      } else if (resp?.status === 200) {
        Notify.success("Asset updated successfully");
      }
      setFormInput(null);
      setEditFormInput(null);
      setUserInput(null);
      handleGetAssets();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h2" mb={2} fontSize={"large"}>
          Add New Asset
        </Typography>
        <Stack spacing={2}>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              Asset Name *
            </Typography>
            <TextField
              name="title"
              value={formInput?.title || ""}
              onChange={handleFormChange}
              fullWidth
              size="small"
              placeholder="eg Office Desk, Laptop, etc"
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              Asset Description *
            </Typography>
            <TextField
              name="description"
              value={formInput?.description || ""}
              onChange={handleFormChange}
              placeholder="eg Standard office desk with 3 drawers, Macbook Pro 2020, etc"
              fullWidth
              size="small"
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              Asset Type *
            </Typography>
            <FormControl size="small" fullWidth>
              <Select
                value={formInput?.assetType || ""}
                name="assetType"
                placeholder="Select Asset Type"
                onChange={handleFormChange}
              >
                {Object.entries(ORG_ASSET_TYPES).map(([key, value], idx) => (
                  <MenuItem value={key} key={idx}>
                    {value?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              Assigned To *{" "}
              {formInput?.assignedTo?.firstName && (
                <Typography
                  component={"span"}
                  sx={{ fontWeight: "800", mx: 2 }}
                >
                  ({formInput?.assignedTo?.firstName}{" "}
                  {formInput?.assignedTo?.lastName || ""})
                </Typography>
              )}
            </Typography>
            {!newUser ? (
              <Autocomplete
                size="small"
                options={users?.data || []}
                getOptionLabel={(option) =>
                  `${option?.firstName} ${option?.lastName}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search User"
                  />
                )}
                value={users?.data?.find(
                  (item) => item?._id === formInput?.assignedTo?._id
                )}
                onChange={(e, value) => {
                  setFormInput({ ...formInput, assignedTo: value?._id });
                }}
                onInputChange={(e, value) => {
                  handleSearchEmployee(value);
                }}
              />
            ) : (
              <>
                <Stack spacing={1} direction={"row"}>
                  <TextField
                    name="firstName"
                    value={userInput?.firstName || ""}
                    onChange={(e) =>
                      setUserInput({ ...userInput, firstName: e.target.value })
                    }
                    placeholder="First Name *"
                    fullWidth
                    size="small"
                  />
                  <TextField
                    name="lastName"
                    value={userInput?.lastName || ""}
                    onChange={(e) =>
                      setUserInput({ ...userInput, lastName: e.target.value })
                    }
                    placeholder="Last Name"
                    fullWidth
                    size="small"
                  />
                  <TextField
                    name="email"
                    value={userInput?.email || ""}
                    onChange={(e) =>
                      setUserInput({ ...userInput, email: e.target.value })
                    }
                    placeholder="Email *"
                    fullWidth
                    size="small"
                  />
                  <TextField
                    name="phoneNumber"
                    value={userInput?.phoneNumber || ""}
                    onChange={(e) =>
                      setUserInput({
                        ...userInput,
                        phoneNumber: e.target.value,
                      })
                    }
                    placeholder="Phone Number *"
                    fullWidth
                    size="small"
                  />
                </Stack>
              </>
            )}
            <FormHelperText>
              Didn't find the user?
              <Typography
                component={"span"}
                color={"primary"}
                mx={1}
                sx={{
                  cursor: "pointer",
                  fontWeight: "600",
                  opacity: 0.8,
                }}
                variant="body2"
                onClick={() => setNewUser(!newUser)}
              >
                {!newUser ? "Add new" : "Cancel"}
              </Typography>
            </FormHelperText>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              Assignment Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{
                  width: "100%",
                }}
                value={
                  formInput?.assignedAt ? new Date(formInput?.assignedAt) : null
                }
                onChange={(newValue) => {
                  setFormInput({ ...formInput, assignedAt: newValue });
                }}
                format="dd/MM/yyyy"
                maxDate={new Date()}
              />
            </LocalizationProvider>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "small",
                fontWeight: "600",
                mb: 0.5,
              }}
            >
              Value (INR)
            </Typography>
            <TextField
              name="amount"
              value={formInput?.amount || ""}
              onChange={handleFormChange}
              fullWidth
              size="small"
              type="number"
            />
          </Box>
        </Stack>
        <Stack
          sx={{ mt: 2 }}
          direction={"row"}
          justifyContent={"flex-end"}
          spacing={1}
        >
          <Stack></Stack>
          <Stack direction={"row"} spacing={1}>
            <Button
              variant="outlined"
              onClick={() => {
                setAddEdit(false);
                setFormInput({});
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              variant="contained"
              onClick={handleAddAsset}
            >
              {formInput?._id ? "Update " : "Add "}
              Asset
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </div>
  );
}
