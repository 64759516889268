import {
  Card,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  capitalize,
} from "@mui/material";
import React, { Fragment, useContext } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";
import { GlobalContext } from "src/contexts/GlobalContext";

export default function PortfolioTags() {
  const { profile } = useContext(ProfileContext);
  const { currentUser } = useContext(GlobalContext);

  return !["admin", "misc"]?.includes(currentUser?.accountType) ||
    Object.entries(profile?.portfolio || {})?.length === 0 ? null : (
    <Card>
      <List>
        {Object.entries(profile?.portfolio || {})?.map(([key, value], idx) =>
          !value?.length ? null : (
            <Fragment key={idx}>
              <ListItem>
                <ListItemText
                  primary={capitalize(key || "")}
                  primaryTypographyProps={{ variant: "h4" }}
                />

                <Stack
                  maxWidth={"80%"}
                  direction="row"
                  flexWrap="wrap"
                  justifyContent={"flex-end"}
                >
                  {value?.map((tag, index) => (
                    <Chip
                      key={index}
                      label={<b>{capitalize(tag || "")}</b>}
                      color={
                        index % 3 ? "info" : index % 2 ? "success" : "warning"
                      }
                      variant="outlined"
                      sx={{ m: 0.5 }}
                    />
                  ))}
                </Stack>
              </ListItem>
              <Divider />
            </Fragment>
          )
        )}
      </List>
    </Card>
  );
}
