import { ChangeCircleOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  capitalize,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { Fragment, useContext, useEffect, useState } from "react";
import EmptyHere from "src/components/EmptyHere";
import Label from "src/components/Label";
import SuspenseLoader from "src/components/SuspenseLoader";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";

export default function SwitchAccount() {
  const { currentUser } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isWait, setIsWait] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggle = () => setOpen(!open);

  useEffect(() => {
    if (open) {
      (async () => {
        try {
          setLoading(true);
          const resp = await axiosInstance.get("/auth/select");
          setAccounts(resp.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          Notify.failure(
            error.response?.data?.message ||
              error.response?.statusText ||
              "an error occured"
          );
        }
      })();
    }
  }, [open]);

  const handleSwitchAccount = async (item, action) => {
    try {
      setIsWait(true);
      let resp;
      if (action === "myprofile") {
        resp = await axiosInstance.delete("/auth/select");
      } else {
        resp = await axiosInstance.post("/auth/select", {
          organisationId: item?.organisation?._id,
          roleId: item?._id,
        });
      }
      if (resp.status === 200) {
        localStorage.setItem("user", JSON.stringify(resp.data?.user));
        localStorage.setItem(
          "accessToken",
          JSON.stringify(resp.data?.accessToken)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(resp.data?.refreshToken)
        );
        setTimeout(() => {
          setIsWait(false);
          window.location.replace("/");
        }, 1000);
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <>
      <Button
        color="primary"
        sx={{ justifyContent: "flex-start" }}
        fullWidth
        onClick={toggle}
      >
        <ChangeCircleOutlined sx={{ mr: 1 }} />
        Switch Account
      </Button>
      <Dialog open={open} onClose={toggle} fullWidth maxWidth="md">
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6">
              List of Organisations you have access to.
            </Typography>
            {currentUser?.orgId && currentUser?.role === "moderator" && (
              <LoadingButton
                loading={isWait}
                onClick={() => {
                  handleSwitchAccount({}, "myprofile");
                }}
              >
                switch back to your main account
              </LoadingButton>
            )}
          </Stack>
        </DialogTitle>
        {loading ? (
          <SuspenseLoader />
        ) : (
          <DialogContent>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {accounts?.data?.length > 0 ? (
                accounts?.data?.map((item, index) => (
                  <Fragment key={index}>
                    <Divider sx={{ my: 2 }} />
                    <ListItem
                      alignItems="flex-start"
                      secondaryAction={
                        <LoadingButton
                          variant={
                            currentUser?.orgId === item?.organisation?._id
                              ? "contained"
                              : "outlined"
                          }
                          color={
                            currentUser?.orgId === item?.organisation?._id
                              ? "success"
                              : "primary"
                          }
                          loading={isWait}
                          onClick={() => {
                            handleSwitchAccount(item, "switch");
                          }}
                        >
                          {currentUser?.orgId === item?.organisation?._id
                            ? "Logged In"
                            : "Click to Login"}
                        </LoadingButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={item?.organisation?.title || ""}
                          src={item?.organisation?.avatar || ""}
                        >
                          {item?.organisation?.title?.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.organisation?.title || ""}
                        secondary={
                          <React.Fragment>
                            <Label color={"info"}>
                              {capitalize(item?.role || "")}
                            </Label>
                            {" — " +
                              (item?.user?.firstName || "") +
                              " " +
                              (item?.user?.lastName || "") +
                              ", you are a " +
                              item?.role +
                              " in this organisation."}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </Fragment>
                ))
              ) : (
                <EmptyHere
                  title={"You have no access to any organisation yet."}
                />
              )}
            </List>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
