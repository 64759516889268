import React, { useRef, useState } from "react";
import {
  Button,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FileDownloadTwoTone } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function QuestionInputField({
  question,
  handleFormInput,
  formInput,
  setFormInput,
  disabled,
}) {
  const inputFile = useRef(null);
  const dropArea = useRef(null);
  const [hValue, setHValue] = useState(-1);

  switch (question?.type) {
    case "text":
      return (
        <TextField
          fullWidth
          disabled={disabled}
          variant="outlined"
          placeholder={question?.placeholder || ""}
          name={question?.name}
          required={question?.required}
          onChange={handleFormInput}
          value={formInput[question?.name] || question?.answer || ""}
        />
      );
    case "textarea":
      return (
        <TextField
          fullWidth
          multiline
          rows={4}
          disabled={disabled}
          variant="outlined"
          placeholder={question?.placeholder || ""}
          name={question?.name}
          required={question?.required}
          onChange={handleFormInput}
          value={formInput[question?.name] || question?.answer || ""}
        />
      );
    case "checkbox":
      return (
        <FormGroup>
          {question?.options?.map((option, index) => (
            <FormControlLabel
              key={index}
              required={question?.required}
              control={
                <Checkbox
                  sx={{ width: "max-content" }}
                  disabled={disabled}
                  name={question?.name}
                  checked={
                    question?.answer
                      ? question?.answer?.includes(option?.label)
                      : formInput[question?.name]
                      ? formInput[question?.name]?.includes(option?.label)
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormInput({
                        ...formInput,
                        [question?.name]: [
                          ...(formInput[question?.name] || []),
                          option?.label,
                        ],
                      });
                    } else {
                      setFormInput({
                        ...formInput,
                        [question?.name]: [
                          ...(formInput[question?.name] || [])?.filter(
                            (item) => item !== option?.label
                          ),
                        ],
                      });
                    }
                  }}
                />
              }
              label={option?.label || ""}
            />
          ))}
        </FormGroup>
      );
    case "radio":
      return (
        <FormControl disabled={disabled}>
          {question?.options?.map((option, index) => (
            <RadioGroup
              key={index}
              disabled={disabled}
              name={question?.name}
              required={question?.required}
              onChange={handleFormInput}
              value={formInput[question?.name] || question?.answer || ""}
            >
              <FormControlLabel
                control={
                  <Radio
                    sx={{ width: "max-content" }}
                    value={option?.label || ""}
                  />
                }
                label={option?.label || ""}
              />
            </RadioGroup>
          ))}
        </FormControl>
      );
    case "select":
      return (
        <FormControl fullWidth>
          <InputLabel>{question?.placeholder || ""}</InputLabel>
          <Select
            disabled={disabled}
            label={question?.placeholder || ""}
            name={question?.name}
            required={question?.required}
            onChange={handleFormInput}
            value={formInput[question?.name] || question?.answer || ""}
          >
            {question?.options?.map((option) => (
              <MenuItem value={option?.label || ""} key={option._id}>
                {option?.label || ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "rating":
      return (
        <Tooltip
          title={
            hValue !== -1 ? question?.options[hValue - 1]?.label || "" : ""
          }
        >
          <Rating
            sx={{ width: "max-content" }}
            disabled={disabled}
            name={question?.name}
            defaultValue={-1}
            max={question?.options?.length || 5}
            size="large"
            onChangeActive={(event, newHover) => {
              setHValue(newHover);
            }}
            onChange={(e) => {
              setFormInput({
                ...formInput,
                [question?.name]: Number(e.target.value),
              });
            }}
            value={formInput[question?.name] || question?.answer || ""}
          />
        </Tooltip>
      );
    case "file":
      const processFiles = async (e, files) => {
        e.preventDefault();

        dropArea.current.style.backgroundColor = "#fff";
        if (files.length) {
          setFormInput({
            ...formInput,
            [question?.name]: files[0],
          });
        }
      };

      return (
        <>
          {/* QUESTION FILE/IMAGES  */}
          <Stack direction={"column"} spacing={1} sx={{ mt: 2 }}>
            {question?.files?.map((file, index) => {
              if (!file) return null;
              const ext = file?.split(".")?.pop();

              if (
                ext === "jpg" ||
                ext === "jpeg" ||
                ext === "png" ||
                ext === "gif" ||
                ext === "svg"
              ) {
                return (
                  <CardMedia
                    component="img"
                    key={index}
                    image={
                      process.env.REACT_APP_DO_SPACE_URL +
                      file +
                      "?" +
                      new Date().getTime()
                    }
                    alt={question?.placeholder + "_failed_to_load"}
                    sx={{
                      width: "100%",
                      position: "relative",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                );
              } else {
                return (
                  <Button
                    size="small"
                    key={index}
                    variant="outlined"
                    startIcon={<FileDownloadTwoTone />}
                    component={"a"}
                    href={process.env.REACT_APP_DO_SPACE_URL + file}
                    target="_blank"
                    fullWidth
                  >
                    File {index + 1} ({file.split(".").pop()})
                  </Button>
                );
              }
            })}
          </Stack>
          {!question?.answer && (
            <div className="file-upload">
              <div
                className="drop"
                ref={dropArea}
                onClick={() => inputFile.current.click()}
                onDrop={(e) => processFiles(e, e.dataTransfer.files)}
                onDragOver={(e) => {
                  e.preventDefault();
                  dropArea.current.style.backgroundColor = "#ddd";
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  dropArea.current.style.backgroundColor = "#fff";
                }}
                style={{
                  cursor: "pointer",
                  padding: "30px",
                }}
              >
                <div className="info">
                  {"Drag & drop files or click to browse."}
                  <br />
                  {"(max size: 10MB)"}
                </div>
              </div>

              <input
                type="file"
                accept="*"
                multiple={false}
                onChange={(e) => processFiles(e, inputFile.current.files)}
                ref={inputFile}
                style={{ display: "none" }}
              />
            </div>
          )}{" "}
          {/* RESPONSE  */}
          {question?.answer && (
            <Typography variant="body1" sx={{ mb: 1 }}>
              Response:
            </Typography>
          )}
          <Stack direction={"column"} spacing={1} sx={{ mt: 2 }}>
            {(() => {
              let file = null;
              if (question?.answer) {
                file = question?.answer;
              } else {
                file = formInput[question?.name];
              }
              if (!file) return null;
              const ext =
                typeof file === "string"
                  ? file?.split(".")?.pop()
                  : file?.name?.split(".")?.pop();
              if (
                ext === "jpg" ||
                ext === "jpeg" ||
                ext === "png" ||
                ext === "gif" ||
                ext === "svg"
              ) {
                return (
                  <CardMedia
                    component="img"
                    image={
                      typeof file === "string"
                        ? process.env.REACT_APP_DO_SPACE_URL +
                          file +
                          "?" +
                          new Date().getTime()
                        : URL.createObjectURL(file)
                    }
                    alt={question?.placeholder + "_failed_to_load"}
                    sx={{
                      width: "100%",
                      position: "relative",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                );
              } else {
                return (
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<FileDownloadTwoTone />}
                    component={"a"}
                    href={process.env.REACT_APP_DO_SPACE_URL + file}
                    target="_blank"
                    fullWidth
                  >
                    {typeof file === "string" ? "Download File" : file?.name}
                  </Button>
                );
              }
            })()}
          </Stack>
        </>
      );
    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            name={question?.name}
            required={question?.required}
            label={question?.placeholder || ""}
            disabled={disabled}
            value={
              new Date(formInput[question?.name] || question?.answer) || ""
            }
            onChange={(newValue) => {
              setFormInput({
                ...formInput,
                [question?.name]: newValue,
              });
            }}
            format="dd/MM/yyyy"
          />
        </LocalizationProvider>
      );
    default:
      return null;
  }
}
