import {
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  List,
  ListItemText,
  Checkbox,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import {
  Search,
  AddCircleOutlineTwoTone,
  SaveTwoTone,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "src/utils/axiosInstance";
import { PAGE_LIMIT } from "src/utils/constants";
import { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";

export default function CreateEditLinkRef({
  group,
  ratingLinks,
  getExternalLinks,
  sx,
}) {
  const { accountId, accountType } = useParams();
  const [open, setOpen] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [filterObj, setFilterObj] = useState({
    status: "published",
  });
  const [loading, setLoading] = useState(false);

  const [forms, setForms] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(parseInt(pageParam) || 1);
  const [searchString, setSearchString] = useState("");
  const [selectedForms, setSelectedForms] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsWait(false);
    setSelectedForms([]);
    setSearchString("");
    setPage(1);

    // delete all search params
    setSearchParams({});
  };

  const handlePageChange = (event, value) => {
    setPage(value + 1);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value + 1,
    });
  };

  useEffect(() => {
    if (!open) return;
    setSelectedForms(group?.links?.map((link) => link?._id) || []);

    (async () => {
      setLoading(true);
      const reqParams = {
        params: {
          limit: PAGE_LIMIT,
          skip: (page - 1) * PAGE_LIMIT,
        },
      };

      if (filterObj) {
        reqParams.params = {
          ...reqParams.params,
          ...filterObj,
          type: ["rating"],
        };
      }

      if (group?.name) {
        reqParams.params = {
          ...reqParams.params,
          receiver: group?.name,
        };
      }

      reqParams.params.source = "qrcode-page";

      const resp = await axiosInstance.get("/forms", reqParams);
      if (resp?.status === 200) {
        setForms(resp?.data?.data);
        setTotalPages(resp?.data?.totalPages || 1);
      }
      setLoading(false);
    })();
  }, [page, filterObj, group, open]);

  const handleSubmit = async () => {
    try {
      setIsWait(true);

      const _ratingLinks = [...ratingLinks];

      const updatedRatingLinks = _ratingLinks?.map((_group) => {
        if (_group?.name === group?.name) {
          return {
            ..._group,
            links: selectedForms,
          };
        }
        return _group;
      });

      const resp = await axiosInstance.put(
        `/portfolios/${accountId}/${accountType}`,
        {
          ratingLinks: updatedRatingLinks,
        }
      );
      if (resp?.status === 200) {
        getExternalLinks();
        Notify.success("Successful");
        handleClose();
      }

      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        sx={{
          ...sx,
        }}
      >
        <AddCircleOutlineTwoTone />
      </IconButton>

      {open && (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
          <DialogTitle>Select form to add to Rating Links</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 500,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search here..."
                    inputProps={{ "aria-label": "search here" }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        if (e.target.value.length) {
                          setFilterObj({ ...filterObj, search: searchString });
                        } else {
                          delete filterObj.search;
                          setFilterObj({ ...filterObj, search: null });
                        }
                        setPage(1);
                        setSearchParams({
                          ...Object.fromEntries(searchParams.entries()),
                          page: 1,
                        });
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value?.length > 0) {
                        setSearchString(e.target.value);
                      } else {
                        if (filterObj?.search) {
                          setFilterObj({
                            ...filterObj,
                            search: null,
                          });
                        }
                      }
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{
                      p: "10px",
                      m: 0.2,
                      bgcolor: "primary.main",
                      color: "white",
                    }}
                    onClick={() => {
                      setFilterObj({
                        ...filterObj,
                        search: searchString,
                      });
                      setPage(1);
                      setSearchParams({
                        ...Object.fromEntries(searchParams.entries()),
                        page: 1,
                      });
                    }}
                  >
                    <Search />
                  </IconButton>
                </Paper>
                <LoadingButton
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                  startIcon={<SaveTwoTone />}
                  loading={isWait}
                  // disabled={selectedForms?.length === 0}
                >
                  Save
                </LoadingButton>
              </Stack>
              {loading && <LinearProgress />}

              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {forms?.map((item, idx) => (
                  <ListItemButton
                    key={idx}
                    role={undefined}
                    onClick={() => {
                      if (selectedForms.includes(item?._id)) {
                        setSelectedForms(
                          selectedForms.filter((formId) => formId !== item?._id)
                        );
                      } else {
                        setSelectedForms([...selectedForms, item?._id]);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": item?._id }}
                        checked={selectedForms?.includes(item?._id)}
                      />
                    </ListItemIcon>
                    <ListItemText id={item?._id} primary={item?.title} />
                  </ListItemButton>
                ))}
              </List>

              {forms?.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={totalPages}
                  rowsPerPage={PAGE_LIMIT}
                  page={page - 1}
                  onPageChange={handlePageChange}
                />
              )}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
