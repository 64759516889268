import {
  differenceInMinutes,
  format,
  formatDistance,
  formatDistanceToNow,
} from "date-fns";

import ceil from "lodash/ceil";
import { MonthNames } from "./commonConstants";
export const getDateDifference = (date) => {
  let diff = differenceInMinutes(new Date(), new Date(date));
  if (diff < 60) return diff + " minutes ago";
  diff = ceil(diff / 60);
  if (diff < 24) return `${diff} hour${diff === 0 ? "" : "s"} ago`;
  diff = ceil(diff / 24);
  if (diff < 30) return `${diff} day${diff === 0 ? "" : "s"} ago`;
  diff = ceil(diff / 30);
  if (diff < 12) return `${diff} month${diff === 0 ? "" : "s"} ago`;
  diff = diff / 12;
  return `${diff.toFixed(1)} year${ceil(diff) === 0 ? "" : "s"} ago`;
};

export const dateDifferenceFromNow = (date) => {
  if (!date) return "-";
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
  });
};
export const getUniqueArrayBy = (array, key) => {
  return [...new Map(array?.map((item) => [item[key], item])).values()];
};

export const getUniqueArrayValues = (array, key) => {
  return [...new Set(array?.map((item) => item[key]))];
};

// ----------------------------------------------------------------------

export function fDate(date, formatStr = "dd MMM yyyy") {
  if (!date) return "";
  // if it is not a value datetime string, then return "-"
  if (isNaN(new Date(date))) return "-";

  return format(new Date(date), formatStr);
}

export function fTime(date) {
  if (!date) return "";
  return format(new Date(date), "hh:mm a");
}

export function fDateTime(date) {
  if (!date) return "";
  return format(new Date(date), "dd MMM, yyyy HH:mm");
}

export function fDateTimeSuffix(date) {
  if (!date) return "";
  return format(new Date(date), "dd MMM yyyy, hh:mm a");
}

export function fToNow(date) {
  if (!date) return "";
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getLocaleDate(date) {
  return date ? new Date(date)?.toString() : "-";
}

export function getCustomDateDifference(
  start_year,
  start_month,
  end_year,
  end_month
) {
  let endDate;
  let startDate;
  if (!end_month || !end_year) endDate = new Date();
  else endDate = new Date(end_year, MonthNames.indexOf(end_month));

  if (!start_month || !start_year) startDate = new Date();
  else startDate = new Date(start_year, MonthNames.indexOf(start_month));
  // return formatDistanceStrict(startDate, endDate, {
  //   unit: "year | month",
  // });

  // years and months
  const years = endDate.getFullYear() - startDate.getFullYear();
  const months = endDate.getMonth() - startDate.getMonth();
  const yearsAndMonths = [];
  if (years > 0) {
    yearsAndMonths.push(`${years}y`);
  }
  if (months > 0) {
    yearsAndMonths.push(`${months}m`);
  }
  return yearsAndMonths.join(", ");
}
