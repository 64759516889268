import {
  Card,
  Box,
  Typography,
  Divider,
  IconButton,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";

import UploadButton from "../../../Documents/components/UploadButton";
import EmptyHere from "src/components/EmptyHere";
import { LaunchTwoTone, PictureAsPdfTwoTone } from "@mui/icons-material";
import { fDate } from "src/utils/date-fns";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "src/contexts/GlobalContext";
import { useContext } from "react";
import { ProfileContext } from "src/contexts/ProfileContext";

function UserDocuments({ documents, handleGetDocuments }) {
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const { profile } = useContext(ProfileContext);

  return (
    <Card
      sx={{
        border: 1,
        borderColor: "grey.400",
      }}
    >
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            Files & Documents
          </Typography>
          <Typography variant="subtitle2">
            Please be cautious when uploading documents, as you can't delete
            them later
          </Typography>
        </Box>
        <Box>
          {(currentUser?.accountType === "admin" ||
            profile?.employementHistory?.some((relation) =>
              relation?.relations?.some((r) => r?.isActive)
            ) ||
            currentUser?.userId === profile?._id) && (
            <UploadButton
              category="documents"
              handleGetDocuments={handleGetDocuments}
            />
          )}
        </Box>
      </Box>
      <Divider />
      <CardContent>
        {documents?.data?.filter((item) => item?.category === "documents")
          ?.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("File")}</TableCell>
                  <TableCell>
                    {currentUser?.role === "user" ? t("Owner") : t("User")}
                  </TableCell>
                  {currentUser?.role !== "user" && (
                    <TableCell>{t("Owner")}</TableCell>
                  )}
                  {["admin", "misc"]?.includes(currentUser?.role) && (
                    <TableCell>{t("Organisation")}</TableCell>
                  )}
                  <TableCell>{t("Date Created")}</TableCell>
                  <TableCell align="right">{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents?.data
                  ?.filter((item) => item.category === "documents")
                  ?.map((item, idx) => (
                    <TableRow hover key={idx}>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          component="a"
                          href={
                            process.env.REACT_APP_DO_SPACE_URL +
                            item?.url +
                            `?${new Date().getTime()}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <PictureAsPdfTwoTone />
                          <Typography
                            sx={{
                              pl: 1,
                            }}
                            variant="h6"
                          >
                            {item?.title || "-"}
                          </Typography>
                        </Box>
                      </TableCell>
                      {currentUser?.role !== "user" && (
                        <TableCell>
                          {item?.user?.firstName || "-"}{" "}
                          {item?.user?.lastName || ""}
                        </TableCell>
                      )}
                      <TableCell>
                        {item?.uploadedBy?.firstName || "-"}{" "}
                        {item?.uploadedBy?.lastName || ""}
                      </TableCell>
                      {["admin", "misc"]?.includes(currentUser?.role) && (
                        <TableCell>
                          {item?.organisation?.title || "-"}{" "}
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant="h6" noWrap>
                          {fDate(item?.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={t("View")} arrow>
                          <IconButton
                            sx={{
                              "&:hover": {
                                background: (theme) =>
                                  theme.colors.primary.lighter,
                              },
                              color: (theme) => theme.palette.primary.main,
                            }}
                            color="inherit"
                            size="small"
                            component="a"
                            href={
                              process.env.REACT_APP_DO_SPACE_URL +
                              item?.url +
                              `?${new Date().getTime()}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LaunchTwoTone fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyHere
            title={"No Documents uploaded"}
            subtitle={"Upload your documents to view them here"}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default UserDocuments;
