import {
  AddTwoTone,
  ArrowDropDownCircleSharp,
  CloudDownloadTwoTone,
} from "@mui/icons-material";
import {
  LoadingButton,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Label from "src/components/Label";
import { GlobalContext } from "src/contexts/GlobalContext";
import axiosInstance from "src/utils/axiosInstance";
import { CLEAN_EXIT_STATUS } from "src/utils/constants";

export default function CleanExitComment({ cleanExitId }) {
  const inputFile = useRef(null);
  const { currentUser } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({});
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFormInputs({});
    setFiles([]);
  }, []);

  const handleFormInputs = (e) => {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!formInputs?.title) {
        Notify.failure("Title is required");
        setLoading(false);
        return;
      }
      if (
        formInputs?.message?.length < 10 ||
        formInputs?.message?.length > 500
      ) {
        Notify.failure("Message must be between 10 and 500 characters");
        setLoading(false);
        return;
      }

      const formData = new FormData();
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      }

      // add formInputs to formData
      for (const key in formInputs) {
        formData.append(key, formInputs[key]);
      }

      const resp = await axiosInstance.post(
        `/cleanexits/${cleanExitId}/logs`,
        formData
      );
      if (resp?.status === 201) {
        Notify.success("Request sent successfully");
        setLoading(false);
        setOpen(false);
        window.location.replace(
          "/dashboard/cleanexits/" + resp.data.cleanExitId
        );
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const ChangeStatusButton = () => {
    const [openS, setOpenS] = useState(false);
    return !["admin", "triager"].includes(currentUser?.accountType) ? null : (
      <Box>
        <Button
          startIcon={<ArrowDropDownCircleSharp />}
          onClick={() => setOpenS(true)}
          size="small"
          variant="outlined"
        >
          Add Final Review
        </Button>
        <Dialog
          open={openS}
          onClose={() => setOpenS(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Select Status</DialogTitle>
          <Alert severity="info" sx={{ mt: 2 }}>
            <Typography variant="body2" color="textSecondary">
              Note: Select the final status of the clean exit request. Remember,
              you cannot change the status once it is set.
            </Typography>
          </Alert>
          <DialogContent>
            <List dense>
              {Object.keys(CLEAN_EXIT_STATUS || {})?.length > 0 &&
                Object.keys(CLEAN_EXIT_STATUS || {})?.map((status, indx) => {
                  if (["open", "in-review"].includes(status)) return null;
                  return (
                    <ListItem key={indx} sx={{ mb: 1 }}>
                      <Button
                        sx={{
                          color: `${CLEAN_EXIT_STATUS[status]?.color}.main`,
                        }}
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          setFormInputs({ ...formInputs, status });
                        }}
                      >
                        {CLEAN_EXIT_STATUS[status]?.label}
                      </Button>
                    </ListItem>
                  );
                })}
            </List>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };

  return (
    <div>
      <TimelineItem
        sx={{
          border: "1px dashed",
          borderRadius: 1,
          cursor: "pointer",
          borderColor: "primary.main",
          mt: 3,
        }}
        onClick={() => setOpen(true)}
      >
        <TimelineOppositeContent
          sx={{
            width: "190px",
            flex: "none",
          }}
        ></TimelineOppositeContent>
        <TimelineSeparator
          sx={{
            position: "relative",
            mb: 3,
          }}
        >
          <TimelineDot variant="outlined" color="primary">
            <AddTwoTone color="primary" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ ml: 3 }}>
          <Typography color={"primary"} variant={"h6"}>
            Add Comment / Message / Attachment
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">
              Add Comment for clean exit ({cleanExitId})
            </Typography>
            <ChangeStatusButton />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              name="title"
              onChange={handleFormInputs}
            />{" "}
            <TextField
              fullWidth
              label="Description / Subtitle (optional)"
              variant="outlined"
              name="description"
              onChange={handleFormInputs}
            />{" "}
            <TextField
              fullWidth
              label="Message (optional)"
              placeholder="Write your message, reason, inquiry, issue, query, information, etc. here... "
              multiline
              rows={4}
              variant="outlined"
              name="message"
              onChange={handleFormInputs}
            />
            <Button
              sx={{
                color: "primary.main",
                border: "1px dashed",
                borderColor: "primary.main",
                "&:hover": {
                  color: "primary.dark",
                  borderColor: "primary.dark",
                },
              }}
              onClick={() => inputFile.current.click()}
              startIcon={<AddTwoTone />}
            >
              Add Attachment (optional)
              <input
                type="file"
                onChange={(e) => setFiles(e.target.files)}
                multiple
                style={{ display: "none" }}
                ref={inputFile}
              />
            </Button>
          </Stack>
          <Divider sx={{ mt: 4 }}>Preview</Divider>

          <Card
            sx={{
              border: "1px solid",
              borderColor: "primary.main",
            }}
          >
            <CardContent>
              {!(
                formInputs?.title ||
                formInputs?.description ||
                formInputs?.message ||
                formInputs?.linktext ||
                files?.length > 0
              ) && (
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                >
                  Nothing to preview
                </Typography>
              )}
              {formInputs?.title && (
                <Typography variant="h4" gutterBottom>
                  {formInputs?.title}
                </Typography>
              )}

              {formInputs?.description && (
                <Typography variant="body1" color="text.secondary">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formInputs?.description,
                    }}
                  />
                </Typography>
              )}
              {formInputs?.message && (
                <Typography variant="subtitle1">
                  Comment: {formInputs?.message}
                </Typography>
              )}

              {formInputs?.linktext && (
                <Button
                  size="small"
                  variant="contained"
                  component={formInputs?.link ? Link : "span"}
                  to={formInputs?.link ? formInputs?.link : ""}
                  color={formInputs?.linkcolor || "warning"}
                >
                  {formInputs?.linktext}
                </Button>
              )}
              {formInputs?.status && (
                <Typography variant="subtitle1">
                  Status:{" "}
                  <Label color={CLEAN_EXIT_STATUS[formInputs?.status]?.color}>
                    <strong>{formInputs?.status?.toUpperCase()}</strong>
                  </Label>
                </Typography>
              )}
              <Stack direction="row" spacing={2}>
                {
                  // show preview of files
                  files?.length > 0 &&
                    Array.from(files)?.map((file, indx) => {
                      const _url = URL.createObjectURL(file);
                      return (
                        <Tooltip title={file?.name} key={indx}>
                          <Box>
                            {file?.type?.includes("image") ? (
                              <CardMedia
                                component="img"
                                sx={{
                                  width: 70,
                                  height: 70,
                                  borderRadius: 1,
                                  border: "1px solid",
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(_url, "_blank");
                                }}
                                image={_url}
                                alt={file?.name}
                              />
                            ) : (
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                  const link = document.createElement("a");
                                  link.href = _url;
                                  link.setAttribute("download", file?.name);
                                  document.body.appendChild(link);
                                  link.click();
                                  link.parentNode.removeChild(link);
                                }}
                                sx={{
                                  height: 64,
                                  width: 64,
                                  border: "1px solid",
                                  borderRadius: 1,
                                  borderColor: "primary.main",
                                }}
                              >
                                <CloudDownloadTwoTone />
                              </IconButton>
                            )}
                            <Typography>
                              {file?.name?.substring(0, 8)}
                            </Typography>
                          </Box>
                        </Tooltip>
                      );
                    })
                }
              </Stack>
            </CardContent>
          </Card>
          {formInputs?.status && (
            <Box>
              <Alert severity="warning" sx={{ mt: 2 }}>
                <AlertTitle>
                  Final status of the clean exit request will be set to{" "}
                  <Label color={CLEAN_EXIT_STATUS[formInputs?.status]?.color}>
                    <strong>{formInputs?.status?.toUpperCase()}</strong>
                  </Label>
                  .
                </AlertTitle>
                <Typography variant="body2" color="textSecondary">
                  Once you submit this review, you will not be able to change it
                  later.
                </Typography>
              </Alert>
              <Alert severity="info" sx={{ mt: 2 }}>
                <AlertTitle>
                  <Label color={CLEAN_EXIT_STATUS[formInputs?.status]?.color}>
                    <strong>{formInputs?.status?.toUpperCase()} :</strong>
                  </Label>
                </AlertTitle>
                {CLEAN_EXIT_STATUS[formInputs?.status]?.logs?.map(
                  (log, indx) => (
                    <Typography variant="body2" color="textSecondary">
                      {indx + 1}. {log}
                    </Typography>
                  )
                )}
              </Alert>
            </Box>
          )}
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
