import {
  Box,
  Typography,
  ListItem,
  styled,
  IconButton,
  Stack,
  Card,
  List,
  Avatar,
  Divider,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ProfileContext } from "src/contexts/ProfileContext";
import { useContext, useEffect, useState } from "react";
import { fDateTimeSuffix } from "src/utils/date-fns";
import { OpenInNewTwoTone } from "@mui/icons-material";
import { RATING_STATUS_COLOR } from "src/utils/constants";
import Label from "src/components/Label";
import { GlobalContext } from "src/contexts/GlobalContext";
import { calculateFinalScore } from "src/utils/helpers";
import EmptyHere from "src/components/EmptyHere";
import { useTranslation } from "react-i18next";
import SuspenseLoader from "src/components/SuspenseLoader";

export default function CommulativeRatingCard() {
  const { t } = useTranslation();
  const { currentUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { profile, ratings, handleGetUserRatings } = useContext(ProfileContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await handleGetUserRatings(profile, {
        type: "rating",
        includeScore: true,
        scoreType: "individual",
      });
      setLoading(false);
    })();
  }, [profile]);

  return loading ? (
    <SuspenseLoader />
  ) : (
    <>
      <Card elevation={0}>
        {ratings?.length > 0 ? (
          ratings?.map((item, idx) => (
            <List component="div" key={idx} disablePadding>
              <ListItem component="div">
                <BoxItemWrapper
                  className={`wrapper-${
                    RATING_STATUS_COLOR[item?.status || "secondary"]?.color
                  }`}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 2,
                    }}
                  >
                    <DotLegend
                      style={{
                        background: (theme) =>
                          `${
                            theme.colors[
                              RATING_STATUS_COLOR[item?.status || "secondary"]
                                ?.color
                            ]?.main
                          }`,
                      }}
                    />

                    <Typography
                      variant="h4"
                      fontSize={"1.2rem"}
                      sx={{
                        color:
                          item?.status === "active" ? "text.primary" : "grey",
                      }}
                    >
                      {item?.title}
                    </Typography>
                  </Typography>
                  <Stack
                    variant="h6"
                    sx={{ pl: 2.3, py: 1 }}
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <Chip
                      avatar={
                        item?.submitBy?.profileImage ? (
                          <Avatar
                            variant="rounded"
                            alt=""
                            src={
                              process.env.REACT_APP_DO_SPACE_URL +
                              item?.submitBy?.profileImage +
                              `?${Math.random()}`
                            }
                          />
                        ) : (
                          ""
                        )
                      }
                      label={
                        <Link
                          to={
                            currentUser?.role === "user"
                              ? `/${item?.submitBy?.cleanExitUserId}`
                              : `/dashboard/users/${item?.submitBy?._id}`
                          }
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight={600}
                            sx={{
                              ml: item?.submitBy?.profileImage ? 1 : 0,
                              color:
                                item?.status === "active" ? "inherit" : "grey",
                            }}
                          >
                            {item?.submitBy?.firstName}{" "}
                            {item?.submitBy?.lastName}
                          </Typography>
                        </Link>
                      }
                      sx={{
                        border: 0,
                      }}
                      variant="outlined"
                    />

                    <Typography>•</Typography>

                    {item?.organisation && (
                      <>
                        <Chip
                          avatar={
                            item?.organisation?.avatar ? (
                              <Avatar
                                variant="rounded"
                                alt=""
                                src={
                                  process.env.REACT_APP_DO_SPACE_URL +
                                  item?.organisation?.avatar +
                                  `?${Math.random()}`
                                }
                              />
                            ) : (
                              ""
                            )
                          }
                          label={
                            <Typography
                              variant="h5"
                              fontWeight={600}
                              sx={{
                                ml: item?.organisation?.avatar ? 1 : 0,
                                color:
                                  item?.status === "active"
                                    ? "inherit"
                                    : "grey",
                              }}
                            >
                              {item?.organisation?.title}{" "}
                            </Typography>
                          }
                          sx={{
                            border: 0,
                          }}
                          variant="outlined"
                        />

                        <Typography>•</Typography>
                      </>
                    )}
                    <Typography>{fDateTimeSuffix(item?.createdAt)}</Typography>
                    {item?.status === "deprecated" && (
                      <Label height={18}>- old rating</Label>
                    )}
                  </Stack>
                </BoxItemWrapper>

                <Box
                  sx={{
                    px: 2,
                    width: 300,
                    textAlign: "right",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={3}
                    justifyContent={"flex-end"}
                  >
                    <span>
                      <Typography
                        variant="h3"
                        sx={{
                          color:
                            item?.status === "active" ? "text.primary" : "grey",
                        }}
                      >
                        {
                          calculateFinalScore({
                            score: item?.score,
                            weightage: item?.weightage,
                          })?.score
                        }
                      </Typography>
                      <small>Score</small>
                    </span>
                    {currentUser?.role !== "user" && (
                      <IconButton
                        size="small"
                        component={Link}
                        to={`/dashboard/ratings/${item?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color={
                          item?.status === "active" ? "default" : "secondary"
                        }
                      >
                        <OpenInNewTwoTone fontSize="small" />
                      </IconButton>
                    )}
                  </Stack>
                </Box>
              </ListItem>

              <Divider />
            </List>
          ))
        ) : (
          <EmptyHere title={t("No Organisation Ratings")} />
        )}
      </Card>
    </>
  );
}

const DotLegend = styled("span")(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    display: inline-block;
    margin-right: ${theme.spacing(1)};
    margin-top: -${theme.spacing(0.1)};
`
);

const BoxItemWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    position: relative;
    padding: ${theme.spacing(2)};
    width: 100%;
    
    &::before {
      content: '.';
      background: ${theme.colors.success.main};
      color: ${theme.colors.success.main};
      border-radius: ${theme.general.borderRadius};
      position: absolute;
      text-align: center;
      width: 6px;
      left: 0;
      height: 100%;
      top: 0;
    }
    
    &.wrapper-info {
      &:before {
        background: ${theme.colors.info.main};
        color: ${theme.colors.info.main};
      }
    }
        
    &.wrapper-warning {
      &:before {
        background: ${theme.colors.warning.main};
        color: ${theme.colors.warning.main};
      }
    }

    &.wrapper-error {
      &:before {
        background: ${theme.colors.error.main};
        color: ${theme.colors.error.main};
      }
    }

    &.wrapper-secondary {
      &:before {
        background: ${theme.colors.secondary.main};
        color: ${theme.colors.secondary.main};
      }
    }
`
);
