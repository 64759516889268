import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { ProfileContext } from "src/contexts/ProfileContext";
import ConsentText from "./ConsentText";
import { Notify } from "notiflix";
import axiosInstance from "src/utils/axiosInstance";
import { LoadingButton } from "@mui/lab";
import DocumentsUpload from "./DocumentsUpload";
import GenerateConsent from "./GenerateConsent";

export default function AccountVerificationWizard() {
  const [open, setOpen] = useState(false);
  const { profile } = useContext(ProfileContext);
  const [step, setStep] = useState(0);
  const [isWait, setIsWait] = useState(false);

  const [signatureImage, setSignatureImage] = useState(null);
  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [dob, setDob] = useState(null);

  useEffect(() => {
    if (!profile) return;
    setOpen(!profile?.verified);
    if (profile?.ratingConsented) {
      setStep(1);
    }
  }, [profile]);

  const handleSaveConsent = async () => {
    try {
      const response = await axiosInstance.post("/users/profile", {
        ratingConsented: true,
      });
      if (response.status === 200) {
        setIsWait(false);
        setStep(1);
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  const handleSaveForm = async (pdfData) => {
    try {
      if (!pdfData) {
        Notify.failure("An error occured, please try again");
        setIsWait(false);
        return;
      }

      const formData = new FormData();
      const htmlContent = pdfData?.innerHTML;
      const htmlBlob = new Blob([htmlContent], { type: "text/html" });

      formData.append("document", htmlBlob);
      const resp = await axiosInstance.post(
        "/documents/rating-consent",
        formData
      );

      if (resp?.status === 201) {
        window.location.reload();
      }

      setIsWait(false);
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.data?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
    }
  };

  return (
    open && (
      <Dialog
        open={open}
        onClose={() => {
          if (step === 0 || step === 2) return;
          setOpen(false);
        }}
        maxWidth="xl"
      >
        <AppBar position="sticky">
          <Toolbar>
            <CardHeader
              title={
                <Typography variant="h2">Account verification form</Typography>
              }
              subheader={
                <Typography variant="h4" component="div">
                  You're required to fill out this form before continuing
                </Typography>
              }
            />
          </Toolbar>
        </AppBar>
        <Container
          maxWidth="lg"
          sx={{
            maxHeight: "50%",
            overflow: "scroll",
            minWidth: 900,
          }}
        >
          {step === 0 && <ConsentText />}
          {step === 1 && (
            <DocumentsUpload
              setSignatureImage={setSignatureImage}
              setAadhaarImage={setAadhaarImage}
              setDob={setDob}
              signatureImage={signatureImage}
              aadhaarImage={aadhaarImage}
              dob={dob}
            />
          )}
          {step === 2 && (
            <Box position={"relative"}>
              <GenerateConsent
                dob={dob}
                signatureImage={signatureImage}
                aadhaarImage={aadhaarImage}
                handleSaveForm={handleSaveForm}
              />
            </Box>
          )}
        </Container>

        <Divider />
        <DialogActions position={"relative"}>
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              minHeight: "40px",
              width: "100%",
              px: 2,
            }}
          >
            {step === 0 && (
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                height={"100%"}
              >
                <Typography variant="subtitle1">
                  By continuing, you agree to the terms and conditions
                </Typography>
                <LoadingButton loading={isWait}>
                  <Typography variant="h4" onClick={handleSaveConsent}>
                    Continue
                  </Typography>
                </LoadingButton>
              </Stack>
            )}
            {step === 1 && (
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => setOpen(false)}
                >
                  <small>Skip for now</small>
                </Button>
                <LoadingButton
                  loading={isWait}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    if (!signatureImage || !aadhaarImage || !dob) {
                      Notify.failure("Please upload all documents");
                      return;
                    }
                    setStep(2);
                    setIsWait(true);
                  }}
                >
                  Save and continue
                </LoadingButton>
              </Stack>
            )}
            {step === 2 && (
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"flex-end"}
                alignItems={"center"}
                width={"100%"}
              >
                <Button
                  variant="contained"
                  color="success"
                  startIcon={
                    <CircularProgress size={"0.8rem"} color="inherit" />
                  }
                >
                  Saving...
                </Button>
              </Stack>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    )
  );
}
