import { Box, Card, Typography } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";

export default function QrCodeShare({ link }) {
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Box
          width={1}
          sx={{
            textAlign: "center",
          }}
        >
          <QRCode
            value={link}
            style={{
              width: 160,
              height: 160,
              position: "relative",
            }}
          />{" "}
        </Box>

        <Typography textAlign={"center"}>
          <small>Share this event with your friends</small>
        </Typography>
      </Card>
    </>
  );
}
