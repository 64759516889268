import { Notify } from "notiflix";
import { useState, createContext, useEffect } from "react";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axiosInstance";

export const ProfileContext = createContext({});

export const ProfileProvider = ({ viewAsPublic, children }) => {
  const { userId, cleanExitUserId } = useParams();

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [portfolio, setPortfolio] = useState(null);
  const [ratings, setRatings] = useState([]);
  const [verificationDetails, setVerificationDetails] = useState({});

  const [percentComplete, setPercentComplete] = useState(0);
  const [inCompleteFields, setInCompleteFields] = useState([]);
  useEffect(() => {
    setInCompleteFields([]);
    setPercentComplete(0);
    let stregthCount = 0;

    const SocialMediaList = [
      {
        value: profile?.meta?.website,
        message: "Add your website",
      },
      {
        value: profile?.meta?.linkedin,
        message: "Add Linkedin",
      },
      {
        value: profile?.meta?.github,
        message: "Add Github",
      },
      {
        value: profile?.meta?.twitter,
        message: "Add Twitter",
      },
    ];

    SocialMediaList.some((item) => item?.value?.length > 0)
      ? stregthCount++
      : setInCompleteFields((prev) => [...prev, "Add your Social Media Links"]);

    const profileCompleteList = [
      {
        value: profile?.address?.city,
        message: "Add your City",
      },
      {
        value: profile?.phoneNumber,
        message: "Add your Phone Number",
      },
      {
        value: profile?.meta?.headline,
        message: "Add your headline in your profile",
      },
      {
        value: profile?.meta?.about,
        message: "Add Bio",
      },
      // TODO: Undo Aadhaar Verification

      // {
      //   value: profile?.cleanExitVerifications?.aadhaar,
      //   message: "Verify Aadhaar Card",
      // },
      {
        value: profile?.cleanExitVerifications?.pan,
        message: "Verify PAN Card",
      },
      {
        value: profile?.crediblCertification?.certified,
        message: "Verify your Certification",
      },
      {
        value: profile?.isVerified,
        message: "Verify your Email Address",
      },
    ];

    const profileCompleteCount = profileCompleteList.reduce((acc, _item) => {
      const item = _item;
      if (item?.value || item?.value?.length > 0) {
        return acc + 1;
      } else {
        setInCompleteFields((prev) => [...prev, item?.message]);
      }
      return acc;
    }, 0);
    stregthCount = stregthCount + profileCompleteCount;

    if (portfolio) {
      portfolio?.some(
        (item) => item?.category === "experience" && item?.highlighted
      )
        ? stregthCount++
        : setInCompleteFields((prev) => [
            ...prev,
            "Add your Experience to highlight",
          ]);

      portfolio?.some(
        (item) => item?.category === "education" && item?.highlighted
      )
        ? stregthCount++
        : setInCompleteFields((prev) => [
            ...prev,
            "Add your Education to highlight",
          ]);

      portfolio?.some((item) => item?.category === "projects")
        ? stregthCount++
        : setInCompleteFields((prev) => [...prev, "Add your Projects"]);
    }

    const maxCount = profileCompleteList.length + 4;
    const score = Math.round(
      ((stregthCount === 0 ? 1 : stregthCount) / maxCount) * 100
    );
    setPercentComplete(score || 10);
  }, [profile, portfolio]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let resp;
        if (cleanExitUserId) {
          resp = await axiosInstance.get(`/users/public/${cleanExitUserId}`);
          if (resp?.status === 200) {
            setProfile(resp?.data?.profile);
            setPortfolio(resp?.data?.portfolio);
            setRatings(resp?.data?.ratings);
          }
          setLoading(false);
          return;
        }

        if (userId) {
          resp = await axiosInstance.get(`/users/${userId}`);
        } else {
          resp = await axiosInstance.get("/users/profile");
        }
        if (resp.status === 200) {
          setProfile(resp.data);
          if (resp?.data?._id) {
            await handleGetPortfolio(resp.data);
            await handleGetUserRatings(resp.data, {
              scoreType: "employee",
              includeScore: true,
              type: "rating",
            });
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        Notify.failure(
          error.response?.data?.message ||
            error.response?.statusText ||
            "an error occured"
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleGetPortfolio = async (_user) => {
    try {
      const response = await axiosInstance.get(
        `/portfolios/users/${_user?._id}`
      );
      if (response.status === 200) {
        setPortfolio(response?.data);
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  const handleGetUserRatings = async (_user, reqObj) => {
    try {
      const response = await axiosInstance.get(`/ratings/users/${_user?._id}`, {
        params: reqObj,
      });
      if (response.status === 200) {
        setRatings(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return [];
    }
  };
  const handleSavePortfolio = async (formInput) => {
    try {
      if (formInput?.category === "experience") {
        if (!formInput?.title) throw new Error("Job title is required");
        if (!formInput?.employment_type)
          throw new Error("Employment type is required");
        if (!formInput?.organisation)
          throw new Error("Organisation Name is required");
        if (!formInput?.start_month) throw new Error("Start Month is required");
        if (!formInput?.start_year) throw new Error("Start Year is required");
        if (
          formInput?.end_year &&
          formInput?.start_year &&
          formInput?.end_year < formInput?.start_year
        )
          throw new Error("End year cannot be less than start year");

        if (
          !formInput?.currently_working &&
          (!formInput?.end_month || !formInput?.end_year)
        ) {
          throw new Error(
            "Are you currently working here? If not, please add end month and year"
          );
        }
      }
      if (formInput?.category === "education") {
        if (!formInput?.title) throw new Error("Project title is required");
        if (!formInput?.degree) throw new Error("Degree is required");
        if (!formInput?.field_of_study)
          throw new Error("Field of study is required");
        if (!formInput?.start_month) throw new Error("Start Month is required");
        if (!formInput?.start_year) throw new Error("Start Year is required");
        if (
          formInput?.end_year &&
          formInput?.start_year &&
          formInput?.end_year < formInput?.start_year
        )
          throw new Error("End year cannot be less than start year");
      }

      if (formInput?._id) {
        const resp = await axiosInstance.put(
          `/portfolios/${formInput?._id}`,
          formInput
        );
        if (resp?.status === 200) {
          await handleGetPortfolio(resp?.data?.user);
          return true;
        }
      } else {
        const resp = await axiosInstance.post("/portfolios", formInput);
        if (resp?.status === 201) {
          await handleGetPortfolio({
            _id: resp?.data?.user,
          });
        }
      }
      return true;
    } catch (error) {
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );
      return false;
    }
  };

  const handleGetVerificationDetails = async (userId) => {
    try {
      if (Object.keys(verificationDetails).length > 0) return true;

      const resp = await axiosInstance.post(`/users/${userId}/verifications`);
      if (resp.status === 200) {
        setVerificationDetails(resp?.data || {});
        return true;
      }
    } catch (error) {
      console.log(error);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
      return false;
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        // state
        loading,
        profile,
        portfolio,
        ratings,
        verificationDetails,
        percentComplete,
        inCompleteFields,
        viewAsPublic: viewAsPublic || false,

        setProfile,
        setPortfolio,

        // methods
        handleGetPortfolio,
        handleGetUserRatings,
        handleSavePortfolio,
        handleGetVerificationDetails,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
