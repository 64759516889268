import { Box, alpha, darken, lighten, List, styled } from "@mui/material";
import { useLocation } from "react-router-dom";

import SidebarMenuItem from "./item";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/contexts/GlobalContext";
import { menuItems } from "../../../items";
import { FEATURES_ACCESS } from "src/utils/constants";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    margin-bottom: ${theme.spacing(0)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemIconColor};
      padding: ${theme.spacing(1, 3.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;

      .MuiListItem-root {
        padding: 0px ${theme.spacing(1)};
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1, 2)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(3.5)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              border-radius: ${theme.general.borderRadiusSm};
              font-size: ${theme.typography.pxToRem(10)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            transition: ${theme.transitions.create(["all"])};
            border-radius: ${theme.general.borderRadius};
            background: ${lighten(theme.sidebar.menuItemBgActive, 0.1)};
            box-shadow: 0px 1px 2px 0 ${alpha(
              darken(theme.sidebar.menuItemIconColor, 0.2),
              0.46
            )};
            font-size: ${theme.typography.pxToRem(18)};
            margin-right: ${theme.spacing(1.5)};
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${
              theme.palette.mode === "dark"
                ? lighten(theme.sidebar.menuItemBgActive, 0.8)
                : theme.sidebar.menuItemIconColor
            };
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(20)};
          }
          
          .MuiButton-StartIcon {
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active,
          &:hover {
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemColorActive};
            }
          }

          &.Mui-active {
            background-color: ${alpha(theme.sidebar.menuItemBgActive, 0.8)};
            box-shadow: 0px 1px 2px 0 ${alpha(
              darken(theme.sidebar.menuItemIconColor, 0.2),
              0.46
            )};
            color: ${theme.sidebar.menuItemColorActive};
            font-weight: bold;


            .MuiButton-startIcon {
                background: ${theme.colors.primary.main};
                color: ${theme.palette.primary.contrastText};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(6)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1.5, 0)};
          }

          .MuiListItem-root {
            padding: ${theme.spacing(0)};

            .MuiButton-root {
              padding: ${theme.spacing(0.7, 2, 0.7, 7)};

              .MuiBadge-root {
                right: ${theme.spacing(2.5)};
              }

              &.Mui-active,
              &:hover {
                box-shadow: none;
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({ items, path }) => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({ ev, path, item }) => {
  const key = item.name;

  // const exactMatch = item.link
  //   ? !!matchPath(
  //       {
  //         path: item.link,
  //         end: true,
  //       },
  //       path
  //     )
  //   : false;
  const exactMatch =
    item?.name === "Dashboard" || item?.name === "Profile"
      ? path === "/dashboard"
      : path?.includes(item?.link);
  // const exactMatch = item?.link?.includes(path);

  if (item.items) {
    //   const partialMatch = item.link
    //     ? !!matchPath(
    //         {
    //           path: item.link,
    //           end: false,
    //         },
    //         path
    //       )
    //     : false;

    // check if any children match
    const partialMatch = item?.items?.some((child) =>
      path?.includes(child?.link)
    );

    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        pro={item.pro}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
        pro={item.pro}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();

  const { currentUser, globalMetadata } = useContext(GlobalContext);
  const [sidebarMenus, setSidebarMenus] = useState([]);

  useEffect(() => {
    (async () => {
      let _menuItems = menuItems[currentUser?.role];
      if (
        ["owner", "moderator"].includes(currentUser?.role) &&
        globalMetadata?.featuresAccess &&
        globalMetadata?.featuresAccess?.length > 0 &&
        _menuItems &&
        _menuItems?.length > 0
      ) {
        const updatedSidebarItems = _menuItems?.map((section) => {
          const sectionItems = section.items || [];

          // add features access to management section
          const managementItem = sectionItems.find(
            (item) => item?.name === "Management"
          );

          if (managementItem) {
            const managementItems = managementItem?.items || [];
            globalMetadata?.featuresAccess
              ?.filter((feature) => feature !== "customerRelations")
              ?.forEach((feature) => {
                const featureDetails = FEATURES_ACCESS[feature];
                const exists = managementItems.find(
                  (item) => item?.name === featureDetails?.name
                );
                if (!exists) {
                  managementItems.push({
                    name: featureDetails.name,
                    icon: featureDetails.icon,
                    link: featureDetails.link,
                  });
                }
              });
          }

          // add features access to Accounts section
          const accountsItem = sectionItems.find(
            (item) => item?.name === "Accounts"
          );

          if (accountsItem) {
            const accountsItems = accountsItem?.items || [];
            globalMetadata?.featuresAccess
              ?.filter((feature) => feature === "customerRelations")
              ?.forEach((feature) => {
                const featureDetails = FEATURES_ACCESS[feature];
                const exists = accountsItems.find(
                  (item) => item?.name === featureDetails?.name
                );
                if (!exists) {
                  accountsItems.push({
                    name: featureDetails.name,
                    icon: featureDetails.icon,
                    link: featureDetails.link,
                  });
                }
              });
          }
          return { ...section, items: sectionItems };
        });
        setSidebarMenus(updatedSidebarItems);
      } else {
        setSidebarMenus(_menuItems);
      }
    })();
  }, [currentUser, globalMetadata]);

  return (
    <Box sx={{ height: "100vh", overflowY: "auto", position: "relative" }}>
      {sidebarMenus.map((section) => (
        <MenuWrapper key={section.heading}>
          <List component="div">
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname + location.search,
            })}
          </List>
        </MenuWrapper>
      ))}
    </Box>
  );
}

export default SidebarMenu;
