import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Notify } from "notiflix";
import React, {
  Fragment,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import axiosInstance from "src/utils/axiosInstance";
import { GlobalContext } from "src/contexts/GlobalContext";
import QuestionInputField from "../common/QuestionInputField";
import StepWizard from "react-step-wizard";
import SingleExamGroup from "./components/SingleExamGroup";
import ExamReceiver from "./components/ExamReceiver";
import NewExamOngoing from "./components/NewExamOngoing";
import SubHeader from "src/components/SubHeader";

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
    z-index: 99;
    position: sticky;
    top: 0;
    width: 100%;
`
);

const MemoAvatar = memo(({ src, title }) => {
  return (
    <Avatar
      sx={{ width: 64, height: 64, mx: "auto" }}
      src={process.env.REACT_APP_DO_SPACE_URL + src + `?${Math.random()}`}
      variant={"rounded"}
    >
      {title}
    </Avatar>
  );
});

export default function SubmitExam() {
  const { formId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { currentUser, handleCheckPayment } = useContext(GlobalContext);
  const receiverId = searchParams.get("receiverId");
  const orgId = searchParams.get("orgId");
  const eventId = searchParams.get("eventId");
  const receiverTypeParam = searchParams.get("receiverType");
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);

  const [hasAccessToSubmit, setHasAccessToSubmit] = useState(false);
  // receiver (user, org)
  const [receiver, setReceiver] = useState(null);
  const [organisation, setOrganisation] = useState(null);

  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [finalScore, setFinalScore] = useState(0);
  const [totalWeightage, setTotalWeightage] = useState(0);
  const time = useRef(0); // in seconds

  const handleFormInput = (e) => {
    setFormInput({ ...formInput, [e.target.name]: e.target.value });
  };

  const handleGetForm = async (formId) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/forms/${formId}`);
      if (resp?.status === 200) {
        setForm(resp?.data);
        setLoading(false);
        const totalWeightage = resp?.data?.formGroups
          ?.flatMap((group) =>
            group.questionsGroup?.flatMap((qGroup) =>
              qGroup.questions?.flatMap((question) => {
                // if question type is in radio, select, or rating, the get greatest weightage of all options
                if (
                  question.type === "radio" ||
                  question.type === "select" ||
                  question.type === "rating"
                ) {
                  return Math.max(
                    ...question.options?.map((option) => option.weightage)
                  );
                }
                // if question type is in checkbox, then get sum of all weightages
                else if (question.type === "checkbox") {
                  return question.options?.reduce(
                    (totalWeightage, option) =>
                      totalWeightage + option.weightage,
                    0
                  );
                } else {
                  return question.weightage || 0;
                }
              })
            )
          )
          .reduce((total, weightage) => total + weightage, 0);

        setTotalWeightage(totalWeightage);
      }
    } catch (error) {
      setLoading(false);
      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );

      return null;
    }
  };

  useEffect(() => {
    handleGetForm(formId);

    if (form?.type === "exam" && !receiverId) {
      Notify.failure("User ID is required for exam");
      navigate("/dashboard/exams");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, receiverId]);

  useEffect(() => {
    let _finalScore = 0;

    let nestedFormData = JSON.parse(JSON.stringify(form));
    const answers = Object.entries(formInput).map(([key, value]) => ({
      key,
      value,
    }));

    nestedFormData?.formGroups?.forEach((formGroup) => {
      if (formGroup?.questionsGroup?.length > 0) {
        formGroup?.questionsGroup?.forEach((question) => {
          if (question?.questions?.length > 0) {
            question?.questions?.forEach((item) => {
              item.answer = answers?.find(
                (pair) => pair.key === item.name
              )?.value;

              if (item?.options && item?.options?.length > 0) {
                switch (item?.type) {
                  case "checkbox":
                    // eslint-disable-next-line array-callback-return
                    item?.options?.map((option) => {
                      if (
                        item?.answer?.includes(option?.label) ||
                        item?.answer?.includes(option?.value)
                      ) {
                        _finalScore = _finalScore + option?.weightage;
                      }
                    });
                    break;
                  case "radio":
                  case "select":
                    // eslint-disable-next-line array-callback-return
                    item?.options?.map((option) => {
                      if (
                        option?.label === item?.answer ||
                        option?.value === item?.answer
                      ) {
                        _finalScore = _finalScore + option?.weightage;
                      }
                    });
                    break;
                  case "rating":
                    const answerIndex = item?.answer;
                    if (answerIndex || answerIndex >= 0) {
                      _finalScore = _finalScore + answerIndex;
                    }
                    break;

                  default:
                }
              }
            });
          }
        });
      }
    });
    setFinalScore(_finalScore || 0);
  }, [formInput]);

  useEffect(() => {
    if (form) {
      let countQuestions = 0;
      form?.formGroups?.forEach((group) => {
        group?.questionsGroup?.forEach((qGroup) => {
          countQuestions += qGroup?.questions?.length;
        });
      });
      if (countQuestions > 0) {
        setTotalQuestions(countQuestions);
      }
    }
  }, [form]);

  const handleFormSubmit = async (action) => {
    try {
      setIsWait(true);
      // if(form?.type === "exam" && !receiverUser)

      const mappedFormInput = Object.entries(formInput).map(
        async ([key, value]) => {
          let _value = value;
          // if value is file
          if (value instanceof File) {
            try {
              const _fileName = key + "." + value?.name?.split(".")?.pop();
              const modifiedFile = new File([value], _fileName, {
                type: value.type,
              });
              const formData = new FormData();
              formData.append("files", modifiedFile);
              const resp = await axiosInstance.post(
                "/users/upload-media",
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );
              if (resp?.status === 200 && resp?.data) {
                _value = resp?.data?.[0];
              }
            } catch (error) {
              console.log(error);
              throw new Error(error);
            }
          }
          return {
            key,
            value: _value,
          };
        }
      );
      // Wait for all promises to resolve
      const answers = await Promise.all(mappedFormInput);
      let nestedFormData = JSON.parse(JSON.stringify(form));

      // append answer key to each question
      nestedFormData?.formGroups?.forEach((formGroup) => {
        if (formGroup?.questionsGroup?.length > 0) {
          formGroup?.questionsGroup?.forEach((question) => {
            if (question?.questions?.length > 0) {
              question?.questions?.forEach((item) => {
                const _ans = answers?.find(
                  (pair) => pair.key === item.name
                )?.value;
                if (item?.required && !_ans) {
                  if (
                    ["radio", "checkbox", "select", "rating"].includes(
                      item.type
                    )
                  ) {
                    if (item.options?.length && form?.type !== "exam") {
                      throw new Error(`${item?.title} is required`);
                    }
                  } else {
                    if (form?.type !== "exam") {
                      throw new Error(`${item?.title} is required`);
                    }
                  }
                }
                item.answer = _ans;
              });
            }
          });
          formGroup?.contextualForms?.forEach((contextualForm) => {
            const _ans = answers?.find(
              (pair) => pair.key === contextualForm.name
            )?.value;
            contextualForm.answer = _ans;
          });
        }
      });
      nestedFormData?.misconductForms?.forEach((misconductForm) => {
        misconductForm.answer = answers?.find(
          (pair) => pair.key === misconductForm.name
        )?.value;
      });

      const _receiver = receiver?._id || receiverId;

      if (_receiver === currentUser?.userId && form?.type !== "exam") {
        Notify.failure("You can't rate yourself");
        setIsWait(false);
        return;
      }

      const _receiverType = ["user", "organisation", "employee"].includes(
        receiverTypeParam
      )
        ? receiverTypeParam
        : "user";

      const resp = await axiosInstance.post("/ratings", {
        receiverType: _receiverType,
        user: _receiver,
        organisation: orgId,
        form: JSON.stringify(nestedFormData),
        formId: form?._id,
        eventId: eventId,
        score: finalScore,
        weightage: totalWeightage,
        duration:
          form?.type === "exam" ? ((time?.current || 0) / 60)?.toFixed(2) : 0,
      });
      if (resp?.status === 201) {
        setIsWait(false);
        Notify.success("Submitted successfully");
        navigate(`/dashboard/ratings/${resp?.data?._id}`);
      }
    } catch (error) {
      setIsWait(false);

      if (error?.response?.status === 402) {
        const redirectLink = `/dashboard/forms/${
          form?._id
        }?receiverId=${receiverId}${orgId ? "&orgId=" + orgId : ""}`;
        const hasPaid = await handleCheckPayment(
          {
            itemId: form?._id,
            item: "forms",
            receiverId: receiverId,
          },
          {
            redirectLink: redirectLink,
            itemId: form?._id,
          }
        );
        if (hasPaid) {
          window.location.href = redirectLink;
        }
        return null;
      }

      Notify.failure(
        error.response?.data?.message ||
          error.response?.statusText ||
          error.message ||
          "an error occured"
      );

      return null;
    }
  };

  const setTime = (_time) => {
    console.log(_time);
    time.current = _time;
  };

  return loading ? (
    <SuspenseLoader />
  ) : (
    <Fragment>
      <Stack spacing={2}>
        <RootWrapper>
          <Card
            sx={{
              backgroundColor: "#2cacf4",
              color: "#fff",
              borderRadius: 0,
            }}
          >
            <CardHeader
              sx={{
                ".MuiCardHeader-action": {
                  alignSelf: "center",
                },
              }}
              avatar={
                <MemoAvatar title={form?.title[0]} src={form?.coverImage} />
              }
              title={
                <Typography variant="h2" component="div">
                  {form?.title || ""}
                </Typography>
              }
              subheader={
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                >
                  {form?.description || ""}
                  <SubHeader
                    sx={{
                      backgroundColor: theme.palette.grey[300],
                      borderColor: theme.palette.grey[500],
                      mt: 0.5,
                    }}
                  >
                    Total Questions: {totalQuestions}
                  </SubHeader>
                </Box>
              }
              subheaderTypographyProps={
                {
                  color: "white",
                } || {}
              }
              action={
                <Stack flexDirection={"row"} columnGap={1}>
                  {form?.duration > 0 && (
                    <NewExamOngoing
                      duration={form?.duration}
                      handleFormSubmit={handleFormSubmit}
                      // time={time.current}
                      setTime={setTime}
                    />
                  )}
                  <LoadingButton
                    variant="contained"
                    color="error"
                    sx={{ px: 4 }}
                    onClick={handleFormSubmit}
                    loading={isWait}
                    disabled={!hasAccessToSubmit}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              }
            />
          </Card>
        </RootWrapper>
        <Box
          display={"flex"}
          flexDirection={"column"}
          minHeight={"calc(100vh - 96px - 18px)"}
          alignItems={"center"}
          // justifyContent={"center"}
          py={2}
        >
          <Box width={"90%"} minHeight={`calc(100vh - 96px - 72px)`}>
            <Stack
              spacing={2}
              sx={{
                height: "100%",
                ">div": {
                  height: "100%",
                  "+div": {
                    height: "auto",
                  },
                  ">div": {
                    height: "100%",
                    ">div": {
                      height: "100%",
                    },
                  },
                },
              }}
            >
              <ExamReceiver
                form={form}
                receiver={receiver}
                setReceiver={setReceiver}
                organisation={organisation}
                setOrganisation={setOrganisation}
                setHasAccessToSubmit={setHasAccessToSubmit}
              />
              <StepWizard style={{ color: "red" }}>
                {form?.formGroups?.map((group) => (
                  <SingleExamGroup
                    key={group?._id}
                    group={group}
                    handleFormInput={handleFormInput}
                    formInput={formInput}
                    setFormInput={setFormInput}
                    disabled={isWait || !hasAccessToSubmit}
                  />
                ))}
              </StepWizard>

              <Stack spacing={2} my={2}>
                {form?.misconductForms?.length > 0 && (
                  <Fragment>
                    <Alert severity="warning">
                      <Typography variant="h4" component="div">
                        This form is not Mandoatory.
                      </Typography>
                      <Typography variant="subtitle1" component="div">
                        Please Fill this Cyber Misconduct Form only if you have
                        any dispute with the Employee. Like Bullying,
                        Harrashment, Fraud, Voilence, etc.
                        <br /> If you don't have any dispute with the Employee,
                        then you can skip this section.
                      </Typography>
                    </Alert>
                    <Stack
                      spacing={2}
                      sx={{
                        py: 2,
                      }}
                    >
                      {form?.misconductForms?.map((misForm, index) => (
                        <Card key={misForm?._id}>
                          <CardHeader
                            title={
                              <Typography variant="h5" component="div">
                                {index + 1}. {misForm?.title || ""}
                                {misForm?.required && (
                                  <span
                                    style={{
                                      color: "red",
                                      marginInline: 3,
                                    }}
                                  >
                                    *
                                  </span>
                                )}
                              </Typography>
                            }
                            subheader={
                              <Typography variant="subtitle1" sx={{ mx: 1.5 }}>
                                {misForm?.description || ""}
                              </Typography>
                            }
                          />
                          <CardContent sx={{ py: 0, mx: 1.5 }}>
                            <QuestionInputField
                              question={misForm}
                              handleFormInput={handleFormInput}
                              formInput={formInput}
                              setFormInput={setFormInput}
                              disabled={
                                isWait || (form?.type === "exam" && !receiverId)
                              }
                            />
                          </CardContent>
                        </Card>
                      ))}
                    </Stack>
                  </Fragment>
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Fragment>
  );
}
