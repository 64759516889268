import {
  AddCircleOutline,
  AddTwoTone,
  CloseOutlined,
  DriveFileRenameOutlineRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Notify } from "notiflix";
import React, { Fragment, useEffect, useState } from "react";
import { InputTypes } from "src/utils/constants";
import QuestionInputField from "../../common/QuestionInputField";
import { generateRandomString } from "src/utils/helpers";

export default function NewFieldDrawer({ formData, formInputs, handleSubmit }) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isWait, setIsWait] = useState(false);

  const [formInput, setFormInput] = useState({
    position: 0,
    min: 0,
    max: 0,
    name: "",
    title: "",
    placeholder: "",
    required: false,
    type: "text",
    options: [],
  });

  useEffect(() => {
    if (formData) {
      setFormInput(formData);
    }
  }, [formData]);

  const handleSave = async () => {
    try {
      if (!formInput?.title || !formInput?.type) {
        Notify.warning("Please fill all required fields");
        return;
      }

      if (["select", "radio", "checkbox"]?.includes(formInput?.type)) {
        if (formInput?.options?.length === 0) {
          Notify.warning("Please add atleast one option");
          return;
        }
      }

      if (
        !formInput?._id &&
        formInputs?.questions?.some(
          (item) =>
            item?.title?.toLowerCase() === formInput?.title?.toLowerCase()
        )
      ) {
        Notify.warning("Field with same title already exists");
        return;
      }

      let formFields;
      if (formInput?._id) {
        const _questions = formInputs?.questions.map((item) => {
          if (item._id === formInput._id) {
            return formInput;
          }
          return item;
        });
        formFields = { ...(formInputs || {}), questions: _questions };
      } else {
        const _questions = [...(formInputs?.questions || []), formInput];
        formFields = { ...(formInputs || {}), questions: _questions };
      }

      const done = await handleSubmit(formFields);
      if (done) {
        setIsWait(false);
        setFormInput({
          position: 0,
          min: 0,
          max: 0,
          name: "",
          title: "",
          placeholder: "",
          required: false,
          type: "text",
          options: [],
        });
        setIsOpen(false);
      } else {
        setIsWait(false);
      }
    } catch (error) {
      setIsWait(false);
      Notify.failure(
        error?.message ||
          error.response?.data?.message ||
          error.response?.statusText ||
          "an error occured"
      );
    }
  };

  return (
    <div>
      {formInput?._id ? (
        <IconButton size="secondary" onClick={() => setIsOpen(true)}>
          <DriveFileRenameOutlineRounded fontSize="small" />
        </IconButton>
      ) : (
        <Stack
          my={6}
          direction={"row"}
          sx={{
            width: "100%",
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button
            onClick={() => setIsOpen(true)}
            startIcon={<AddCircleOutline />}
            sx={{
              width: "20%",
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
              borderStyle: "dashed",
              p: 2,
            }}
            variant="outlined"
          >
            <Typography>Add New Field</Typography>
          </Button>
        </Stack>
      )}
      {isOpen && (
        <Drawer
          anchor={"right"}
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            if (!formInput?._id) {
              setFormInput({
                placeholder: "",
                type: "text",
                value: "",
              });
            }
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: 800,
              p: 2,
            }}
          >
            <CardContent>
              <Box mb={3}>
                <Typography variant="h5" component="h3" gutterBottom>
                  Add/Edit Form Fields
                </Typography>
              </Box>
              <Stack spacing={2}>
                <FormControl fullWidth>
                  <InputLabel id="field-type">Select Input Type</InputLabel>
                  <Select
                    labelId="field-type"
                    value={formInput?.type}
                    label="Select Input Type*"
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        type: e.target.value,
                        options: !["select", "radio", "checkbox"]?.includes(
                          e.target.value
                        )
                          ? []
                          : formInput?.options,
                      });
                    }}
                  >
                    {InputTypes?.map((item, idx) => (
                      <MenuItem value={item?.value} key={idx}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  label={"Enter question title *"}
                  type="text"
                  variant="outlined"
                  value={formInput?.title}
                  onChange={(e) => {
                    setFormInput({ ...formInput, title: e.target.value });
                  }}
                />

                {!["radio", "checkbox", "file"]?.includes(formInput?.type) && (
                  <TextField
                    fullWidth
                    label={"Enter question placeholder"}
                    type="text"
                    variant="outlined"
                    value={formInput?.placeholder}
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        placeholder: e.target.value,
                      });
                    }}
                  />
                )}

                <Stack
                  direction={"row"}
                  spacing={2}
                  pb={3}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle1">Input mandatory?</Typography>
                  <Switch
                    checked={formInput?.required || false}
                    name="required"
                    color="primary"
                    onChange={(e) => {
                      setFormInput({
                        ...formInput,
                        required: e.target.checked,
                      });
                    }}
                  />
                </Stack>
                {formInput?.options?.map((item, index) => (
                  <Stack key={index} spacing={1}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        fullWidth
                        name="label"
                        size="small"
                        sx={{
                          ".MuiFilledInput-underline": {
                            "&:before": {
                              borderColor: "transparent",
                            },
                          },
                          ".MuiFilledInput-input": {
                            paddingTop: "8px",
                            paddingLeft: 0,
                          },
                        }}
                        InputProps={{
                          sx: {
                            backgroundColor: "transparent",
                            borderRadius: 0,
                            borderColor: "transparent",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          },
                        }}
                        placeholder={"Option"}
                        variant="filled"
                        value={item?.label}
                        onChange={(e) => {
                          const newOptions = [...formInput?.options];
                          newOptions[index].label = e.target.value;
                          setFormInput({
                            ...formInput,
                            options: newOptions,
                          });
                        }}
                      />

                      <IconButton
                        onClick={() => {
                          const newOptions = [...formInput?.options];
                          newOptions.splice(index, 1);
                          setFormInput({
                            ...formInput,
                            options: newOptions,
                          });
                        }}
                        size="small"
                      >
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
                {["select", "radio", "checkbox"]?.includes(formInput?.type) && (
                  <LoadingButton
                    variant="text"
                    startIcon={<AddTwoTone fontSize="small" />}
                    onClick={async () => {
                      const _option = formInput?.options || [];
                      _option.push({
                        position: _option.length + 1,
                        label: "New Option " + (_option.length + 1),
                        value: await generateRandomString(8),
                      });
                      setFormInput({
                        ...formInput,
                        options: _option,
                      });
                    }}
                    size="small"
                  >
                    Add New Option
                  </LoadingButton>
                )}
              </Stack>
              <LoadingButton
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleSave}
                loading={isWait}
              >
                Save
              </LoadingButton>
            </CardContent>
          </Box>
          {formInput?.type && (
            <Fragment>
              <Divider>Preview</Divider>
              <Box p={2}>
                <Card>
                  <CardHeader
                    title={
                      formInput?.title && (
                        <Typography variant="h5" component="div">
                          {formInput?.title || ""}
                          {formInput?.required ? " *" : ""}
                        </Typography>
                      )
                    }
                  />
                  <CardContent sx={{ py: 0 }}>
                    <QuestionInputField
                      question={formInput}
                      handleFormInput={() => {}}
                      formInput={{}}
                      setFormInput={() => {}}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Fragment>
          )}
        </Drawer>
      )}
    </div>
  );
}
